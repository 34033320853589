import * as actions from '../reducers/referralCodeReducer';
import { takeLatest, put } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import { showModal } from 'erisxkit/client';
import { REFERRAL_CODE_SENT } from '../constants/modalTypes';

function* onEmailSent() {
  yield put(
    showModal(REFERRAL_CODE_SENT, {
      size: 'small',
    }),
  );
}

function* onCodeApplied() {
  localStorage.removeItem('referralCode');
}

export default function* watch() {
  yield takeLatest(
    actions.fetchReferralCode.TRIGGER,
    createSaga(actions.fetchReferralCode, actions.fetchReferralCode._PREFIX),
  );
  yield takeLatest(
    actions.setReferralCode.TRIGGER,
    createSaga(actions.setReferralCode, actions.setReferralCode._PREFIX),
  );
  yield takeLatest(
    actions.sendReferralCode.TRIGGER,
    createSaga(actions.sendReferralCode, actions.sendReferralCode._PREFIX),
  );
  yield takeLatest(
    actions.getPromotionReferrals.TRIGGER,
    createSaga(
      actions.getPromotionReferrals,
      actions.getPromotionReferrals._PREFIX,
    ),
  );
  yield takeLatest(actions.sendReferralCode.SUCCESS, onEmailSent);
  yield takeLatest(actions.setReferralCode.SUCCESS, onCodeApplied);
}
