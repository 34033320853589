import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import CryptoDeposit from '../../components/FundTransfers/CryptoDeposit';
import { CRYPTO_DEPOSIT_CONFIRM } from '../../constants/modalTypes';
import { confirmTransferDetails } from '../../reducers/fundTransfersReducer';
import {
  getCryptoAssetTypesWithFiltering,
  getAssetOptionsWithFundsDesignation,
} from '../../reducers/assetTypesReducer';
import { getFundTransfer } from '../../reducers/uiReducer';
import { trackEvent } from '../../common/tracking';
import gaCategories from '../../constants/ga-categories';

const mapStateToProps = (state) => ({
  ...state,
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
  cryptoAssetTypesOptions: getAssetOptionsWithFundsDesignation(
    getCryptoAssetTypesWithFiltering,
  )(state),
  fundTransfer: getFundTransfer(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      confirmTransferDetails,
    },
    dispatch,
  );

class CryptoDepositContainer extends Component {
  state = { assetType: this.props.asset };

  componentDidMount = () => {
    trackEvent(gaCategories.DEPOSIT_CRYPTO, 'depositCryptoTab');
  };

  handleChange = (e, { name, value }) => {
    let change;

    // for options without fundsDesignation, fd will safely be undefined
    const [asset, fd] = value.split('/');

    if (!_.isEmpty(fd)) {
      change = {
        [name]: asset,
        fundsDesignation: fd,
      };
    } else {
      change = { [name]: value };
    }
    this.setState(change);
  };

  confirmDeposit = () => {
    this.props.showModal(CRYPTO_DEPOSIT_CONFIRM);
    this.props.confirmTransferDetails(this.state);
  };

  render = () => {
    const assetTypesLoading = _.get(this.props, 'assetTypesLoading');

    const data = {
      accountCode: this.props.accountCode,
      firmCode: this.props.firmCode,
      currencyOptions: this.props.cryptoAssetTypesOptions,
      onCurrencyChange: this.onCurrencyChange,
      assetType: this.state.assetType,
      fundsDesignation: this.state.fundsDesignation,
      confirmDeposit: this.confirmDeposit,
      handleChange: this.handleChange,
      assetTypesLoading,
    };
    return <CryptoDeposit {...data} />;
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CryptoDepositContainer);
