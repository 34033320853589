import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Icon } from 'semantic-ui-react';

const SecureFieldAdornment = (
  <InputAdornment position="end">
    <Icon disabled name="lock" />
  </InputAdornment>
);

export default SecureFieldAdornment;
