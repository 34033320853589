import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import {
  ContractView,
  PositionSummary,
} from '../ts/models/Positions/PositionSummary';

/** Action names */
export const POSITION_SUMMARY = 'POSITION_SUMMARY';
/** Actions */
export const fetchPositionSummary = createRoutine(POSITION_SUMMARY);

/** Initial State */
type MarginPositionsState = {
  contractViews: ContractView[];
  count: number;
};

const initialState: MarginPositionsState = {
  contractViews: [],
  count: 0,
};

/** Reducer */
export default handleActions<MarginPositionsState, any>(
  {
    [fetchPositionSummary.SUCCESS]: (
      state: MarginPositionsState,
      { payload }: { payload: PositionSummary },
    ) =>
      ({
        ...state,
        ...payload,
        count: get(payload, 'contractView', []).length,
      }) as MarginPositionsState,
  },
  initialState,
);

//* Selectors */
export const getMarginPositions = (state: any): ContractView[] =>
  get(state, 'marginPositions.contractView', []);

export const getMarginPositionsCount = (state: any): ContractView[] =>
  get(state, 'marginPositions.count', 0);
