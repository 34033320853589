import React, { useCallback, useMemo } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import { Wrapper } from './ClearingRequests.styled';
import EMPTable8 from '../../../common/EMPTable8/EMPTable8';
import clearingRequestsMetadata from '../../../metadata/clearingRequestsMetadata';
import { getActiveOrFirstAccountFirmCode } from '../../../reducers/accountsReducer';
import { FCM_CLEARING_API_ENDPOINT } from '../../../api';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  fetchFcmClearing,
  getFcmClearingRequestCount,
  getFcmMemberClearingRequests,
} from '../../../reducers/fcmClearingReducer';

const ClearingRequests = () => {
  const dispatch = useAppDispatch();
  const firmCode = useAppSelector(getActiveOrFirstAccountFirmCode);
  const data = useAppSelector(getFcmMemberClearingRequests);
  const count = useAppSelector(getFcmClearingRequestCount);
  const loading = useAppSelector((state) =>
    createLoadingSelector([FCM_CLEARING_API_ENDPOINT])(state),
  );

  const fetchData = useCallback(
    () =>
      dispatch(
        fetchFcmClearing({
          urlParams: {
            firmCode,
          },
        }),
      ),
    [dispatch, firmCode],
  );

  const sortBy = useMemo(() => {
    return { sorting: [{ id: 'createdAt', desc: true }] };
  }, []);

  return (
    <Wrapper>
      <EMPTable8
        title="clearingRequests"
        data={data}
        columns={clearingRequestsMetadata}
        fetchData={fetchData}
        count={count}
        loading={loading}
        loadingText="Fetching Clearing Requests"
        noDataText="No Clearing Requests to show"
        minimumRows={5}
        initialState={sortBy}
        local
        showActions
      />
    </Wrapper>
  );
};

export default ClearingRequests;
