import React, { Fragment } from 'react';
import { Header, Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import BchMessage from '../../common/components/BchMessage';

const AddCryptoAddress = ({
  data,
  assetTypesOptions,
  loading,
  handleChange,
  confirm,
  valid,
}) => (
  <Fragment>
    <Header>Add Crypto Address</Header>
    <section>
      <Form className="inline-block">
        <Form.Group>
          <Form.Input
            placeholder="Label/Nickname"
            type="text"
            name="label"
            onChange={handleChange}
            value={data.label}
            data-cy="table-add-crypto-address-label"
          />
          <Form.Dropdown
            placeholder="Currency Type"
            fluid
            selection
            options={assetTypesOptions}
            loading={loading}
            name="assetType"
            onChange={handleChange}
            value={data.assetType}
            fluid
            data-cy="table-add-crypto-address-currency"
          />
          <Form.Input
            placeholder="Address"
            type="text"
            name="address"
            value={data.address}
            onChange={handleChange}
            data-cy="table-add-crypto-address-address"
          />
          <Button
            content="Add"
            className="tertiary"
            onClick={confirm}
            disabled={!valid}
            data-cy="table-add-crypto-address-submit"
          />
        </Form.Group>
      </Form>
      <BchMessage assetType={data.assetType} inline />
    </section>
  </Fragment>
);

AddCryptoAddress.propTypes = {
  assetTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  confirm: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  valid: PropTypes.bool.isRequired,
};

AddCryptoAddress.defaultProps = {
  loading: false,
  data: {},
};

export default AddCryptoAddress;
