import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';

//* actions */
export const createTransferRequest = createRoutine(
  'CREATE_MEMBER_TRANSFER_REQUEST',
);

//* Reducer */
export default handleActions(
  {
    [createTransferRequest.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);
