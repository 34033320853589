import React from 'react';
import {
  ApprovedParticipantTableRow,
  ParticipantRow,
  isCAR,
  isCGM,
  isParticipant,
} from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import ParticipantActions from './ParticipantActions';
import CgmActions from './CgmActions';
import CarActions from './CarActions';

const Actions: React.FC<{
  participant: ParticipantRow;
  rowData: ApprovedParticipantTableRow;
}> = ({ rowData, participant }) => {
  if (isParticipant(rowData))
    return <ParticipantActions participant={rowData} />;

  if (isCGM(rowData))
    return <CgmActions cgm={rowData} participant={participant} />;

  if (isCAR(rowData))
    return <CarActions car={rowData} participant={participant} />;

  return null;
};

export default Actions;
