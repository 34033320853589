import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import LoginContainer from '../containers/LoginContainer';
import WelcomeContainer from '../containers/onBoarding/WelcomeContainer';
import RegistrationSteps from '../containers/onBoarding/RegistrationSteps';
import Restricted from '../components/Restricted';
import Pending from '../components/Pending';
import Login from '../common/Login';
import Auth from '../services/Auth';
import AppLayout from '../common/AppLayout';
import history from '../constants/history';
import routes from '../routes/routes';
import LoadingComponent from '../common/components/xtable/LoadingComponent';
import AcuantReactCamera from '../common/containers/AcuantReactCamera';
import Logout from '../common/Logout';
import SignupRedirect from '../components/Redirect';
import ModalContainer from '../containers/ModalContainer';
import ConfirmSignup from '../common/ConfirmSignup';
import { AuthMessages } from '../components/onBoarding/AuthMessages';
import * as qs from 'query-string';
import { ThemeWrapper } from '../hooks/useTheme';
import { SpotDecomPage } from '../utils/spotDecomSplash';

const auth = new Auth();
const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
    return true;
  }
  return false;
};

const withLayoutHideVerticalNav = (ComponentComp, props) => (
  <AppLayout auth={auth} hideVerticalNav>
    <ComponentComp auth={auth} {...props} />
  </AppLayout>
);
const withLayout = (ComponentComp, props) => (
  <AppLayout auth={auth}>
    <ComponentComp auth={auth} {...props} />
  </AppLayout>
);

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Fragment>
          <LoadingComponent className="logo-loader-center" size="200" />
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        </Fragment>
      )
    }
  />
);

const getLogoClass = (simple, small) => {
  if (small) {
    return 'small-logo';
  } else if (simple && !small) {
    return 'logo';
  }

  return '';
};

export default () => {
  return (
    <ThemeWrapper>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path="/"
            exact
            component={() => <LoginContainer {...auth} />}
          />
          <Route
            path="/welcome"
            exact
            component={(props) =>
              withLayoutHideVerticalNav(WelcomeContainer, props)
            }
          />
          <Route
            path="/register"
            exact
            component={(props) =>
              withLayoutHideVerticalNav(RegistrationSteps, props)
            }
          />
          <Route
            path="/restricted"
            exact
            component={() => withLayoutHideVerticalNav(Restricted)}
          />
          <Route
            path="/pending"
            exact
            component={() => withLayoutHideVerticalNav(Pending)}
          />
          <Route
            path="/error"
            exact
            component={(props) => (
              <AuthMessages {...qs.parse(props.location.search)} />
            )}
          />
          <Route path="/logout" exact component={() => <Logout {...auth} />} />
          <Route
            path="/capture"
            exact
            component={() => (
              <Fragment>
                <ModalContainer />
                <AcuantReactCamera />
              </Fragment>
            )}
          />
          <Route
            path="/signup"
            exact
            component={(props) => (
              <SignupRedirect params={qs.parse(props.location.search)} />
            )}
          />
          <Route
            path="/confirm-signup"
            exact
            component={() => <ConfirmSignup />}
          />
          <Route
            path="/login"
            render={(props) => {
              if (handleAuthentication(props)) {
                return <Login {...props} />;
              }
              return <LoginContainer {...auth} />;
            }}
          />
          <Route path="/spot-decom" component={() => <SpotDecomPage />} />
          {routes.map((route) => (
            <PrivateRoute
              path={route.path}
              key={route.path}
              exact={route.exact}
              component={(props) => withLayout(route.component, props)}
            />
          ))}
        </Switch>
      </ConnectedRouter>
    </ThemeWrapper>
  );
};
