import { Column, ColumnDef, GroupColumnDef } from '@tanstack/react-table';
import get from 'lodash/get';
import { objectOf } from 'prop-types';
import { ColumnMetadata, LocalStoragePrefs } from '../types';

/*
    -desc: Receives the name of a EMPTable8 table and returns its LocalStoragePrefs
    @param - tableName: Name of the EMPTable8 table
    returns:  LocalStoragePrefs object (maps column name to its width)
*/
const getTableLocalStoragePrefs = (
  tableName: string,
): LocalStoragePrefs | null => {
  const erisx_user: string | null = localStorage.getItem('erisx_user');
  // Keep the XTable name

  const storedPrefs = localStorage.getItem('XTable') || '';
  if (storedPrefs) {
    const columns: LocalStoragePrefs = get(JSON.parse(storedPrefs), [
      erisx_user || '',
      tableName,
    ]);
    return columns;
  }

  return null;
};

const isGroupedColumnDef = <T>(col: ColumnDef<T>): col is GroupColumnDef<T> =>
  col && get(col, 'columns') !== undefined;

export const columnsWithWidths = <T>(
  metadata: ColumnDef<T>[],
  tableName: string,
): ColumnDef<T>[] => {
  const prefs = getTableLocalStoragePrefs(tableName);

  return metadata.map((column) => {
    // Check if it's a group
    if (isGroupedColumnDef(column)) {
      const newColumns = column.columns?.map((groupCol) => {
        const newMeta = { ...groupCol };
        if (!newMeta.size) {
          const size: number | null = get(prefs, [groupCol.id || ''], null);
          if (size) newMeta.size = size;
        }
        return newMeta;
      });
      column.columns = newColumns;
      return column;
    } else {
      const newMeta = { ...column };
      if (!newMeta.size) {
        const size: number | null = get(prefs, [column.id || ''], null);
        if (size) newMeta.size = size;
      }
      return newMeta;
    }
  });
};
