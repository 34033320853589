import React from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';
import styled from 'styled-components';
import { Segment, Header, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { UISegment } from '../styles/styled';

const StyledSegment = styled(UISegment)`
  flex-grow: 1;
`;

// FIXME: possibility for bad, bad performance issues for large PDFs
const DailyStatements = ({ loading, onChange, handleExport, accounts }) => (
  <StyledSegment className="daily-statements">
    <Header
      content="Daily Statements"
      subheader="Download a daily statement for a specific account."
    />
    <Form loading={loading}>
      <Form.Dropdown
        label="Account"
        placeholder="Select or type an account."
        name="accountId"
        selection
        search
        closeOnChange
        onChange={onChange}
        defaultValue={get(accounts, ['0', 'accountId'])}
        options={accounts.map((account) => ({
          value: get(account, 'accountId'),
          text: get(account, 'label'),
          key: get(account, 'accountId'),
        }))}
      />
      <Form.Field>
        <label htmlFor="date">Date</label>
        <DateTime
          htmlProps={{ readonly: true }}
          className="ui input fluid"
          name="date"
          id="date"
          closeOnSelect
          timeFormat={false}
          initialValue={moment().subtract(1, 'day')}
          onChange={(currentDate) =>
            onChange(null, {
              name: 'date',
              value: moment(currentDate._d).format('YYYY-MM-DD'),
            })
          }
          isValidDate={(currentDate) =>
            currentDate.isBefore(moment().subtract(1, 'day'))
          }
        />
      </Form.Field>
      <Form.Button
        secondary
        content="Export Statement"
        name="exportButton"
        onClick={handleExport}
      />
    </Form>
  </StyledSegment>
);

DailyStatements.propTypes = {
  handleExport: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object),
};

DailyStatements.defaultProps = {
  loading: false,
  accounts: [],
};

export default DailyStatements;
