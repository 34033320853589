import * as actions from '../reducers/authorizedUsersReducer';
import { takeLatest, put } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import { hideModal } from 'erisxkit/client';
import {
  FUNDING_PASSWORD,
  REMOVE_AUTHORIZED_USER,
  ADD_AUTHORIZED_USER,
} from '../constants/modalTypes';

function* onRemoveUserFinished() {
  yield put(hideModal(FUNDING_PASSWORD));
  yield put(hideModal(REMOVE_AUTHORIZED_USER));
}

function* onUserAuthFinished() {
  yield put(hideModal(FUNDING_PASSWORD));
  yield put(hideModal(ADD_AUTHORIZED_USER));
}

export default function* watch() {
  yield takeLatest(
    actions.fetchAuthorizedUsers.TRIGGER,
    createSaga(
      actions.fetchAuthorizedUsers,
      actions.fetchAuthorizedUsers._PREFIX,
    ),
  );
  yield takeLatest(
    actions.addAuthorizedUser.TRIGGER,
    createSaga(
      actions.addAuthorizedUser,
      actions.addAuthorizedUser._PREFIX,
      '',
      'New User has been added successfully!',
    ),
  );
  yield takeLatest(
    actions.removeAuthorizedUser.TRIGGER,
    createSaga(
      actions.removeAuthorizedUser,
      actions.removeAuthorizedUser._PREFIX,
      '',
      'User successfully removed',
    ),
  );
  yield takeLatest(
    actions.fetchInvestmentMember.TRIGGER,
    createSaga(
      actions.fetchInvestmentMember,
      actions.fetchInvestmentMember._PREFIX,
    ),
  );
  yield takeLatest(actions.addAuthorizedUser.SUCCESS, onUserAuthFinished);
  yield takeLatest(actions.removeAuthorizedUser.SUCCESS, onRemoveUserFinished);
  yield takeLatest(actions.removeAuthorizedUser.FAILURE, onRemoveUserFinished);
}
