import React, { useMemo } from 'react';
import { reduxForm,  InjectedFormProps } from 'redux-form';
import * as rules from '../../../common/forms/rules';
import { FormField } from '../shared/FormField';
import { Form } from 'semantic-ui-react';
import { FieldGrid, Segment } from '../shared/styles';
import {
  ACCOUNT_TYPE_OPTIONS,
  CUSTOMER_ORDER_CAPACITY_OPTIONS,
  SIDE_OPTIONS,
} from '../shared/constants';
import FormDropdown from '../shared/FormDropdown';
import { isFuturesBlockTradesRegistration } from '../shared/utils';
import FormDatePicker from '../shared/FormDatePicker';

export const SINGLE_SIDE_BLOCK_TRADE_FORM_NAME = 'singleSideBlockTrade';

export type SingleSideBlockTradeFormValues = {
  contractSymbol: string;
  price: string;
  quantity: string;
  negotiatedTime: string;
  side: 'SELL' | 'BUY';
  clOrdId: string;
  accountLabel?: string;
  exchangeMemberId?: string;
  tphId?: string;
  customerAccountRef?: string;
  clearingMemberId?: string;
  senderSubId: string;
  custOrderCapacity?: string;
  accountType?: string;
  cgm?: string;
  contraAccountLabel?: string;
  contraTphId?: string;
  contraClearingMemberId?: string;
};

type IProps = {
  onSubmit: () => void;
};

export type SingleSideBlockTradeFormProps = InjectedFormProps<
  SingleSideBlockTradeFormValues,
  IProps
> &
  IProps;

const SingleSideBlockTradeForm: React.FC<
  InjectedFormProps<SingleSideBlockTradeFormValues, IProps> & IProps
> = ({ onSubmit, handleSubmit }: SingleSideBlockTradeFormProps) => {
  const isFutures = useMemo(() => isFuturesBlockTradesRegistration(), []);

  return (
    <Segment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldGrid>
          <FormField id="contractSymbol" label="Contract Symbol" />
          <FormField id="price" label="Price" validate={[rules.number]} />
          <FormField id="quantity" label="Quantity" validate={[rules.number]} />
          <FormDatePicker id="negotiatedTime" label="Negotiated Time" />
          <FormDropdown id="side" label="Side" options={SIDE_OPTIONS} />
          <FormField id="clOrdId" label="CL Ord ID" />
          {!isFutures && <FormField id="accountLabel" label="Account Label" />}
          {isFutures && (
            <>
              <FormField id="exchangeMemberId" label="Exchange Member ID" />
              <FormField id="tphId" label="TPH ID" />
              <FormField id="customerAccountRef" label="Customer Account Ref" />
              <FormField id="clearingMemberId" label="Clearing Member ID" />
            </>
          )}
          <FormField
            id="senderSubId"
            label="Sender Sub ID"
            validate={[rules.minLength(3), rules.maxLength(18)]}
          />
          {isFutures && (
            <>
              <FormDropdown
                id="custOrderCapacity"
                label="Customer Order Capacity"
                options={CUSTOMER_ORDER_CAPACITY_OPTIONS}
              />
              <FormDropdown
                id="accountType"
                label="Account Type"
                options={ACCOUNT_TYPE_OPTIONS}
              />
            </>
          )}

          <FormField id="cgm" label="CGM" optional />
          {!isFutures && (
            <FormField id="contraAccountLabel" label="Contra Account Label" />
          )}
          {isFutures && (
            <>
              <FormField id="contraTphId" label="Contra TPH ID" />
              <FormField
                id="contraClearingMemberId"
                label="Contra Clearing Member ID"
              />
            </>
          )}
        </FieldGrid>
      </Form>
    </Segment>
  );
};

export default reduxForm<SingleSideBlockTradeFormValues, IProps>({
  form: SINGLE_SIDE_BLOCK_TRADE_FORM_NAME,
  destroyOnUnmount: false,
})(SingleSideBlockTradeForm);
