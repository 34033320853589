import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'erisxkit/client';
import TextButtons from '../../../common/TextButtons';
import { CLEARING_REQUEST_ACTION } from '../../../constants/modalTypes';
import {
  ClearingRequestAction,
  FCMClearingResponse,
} from '../../../ts/models/FCMClearing.model';

const ClearingRequestActions: React.FC<{ request: FCMClearingResponse }> = ({
  request,
}) => {
  const dispatch = useDispatch();
  const isPending = request?.status === 'pending';

  const onActionClick = useCallback(
    (request: FCMClearingResponse, action: ClearingRequestAction) => {
      dispatch(
        showModal(CLEARING_REQUEST_ACTION, {
          request,
          action,
        }),
      );
    },
    [dispatch],
  );

  const buttons = useMemo(() => {
    return [
      {
        text: 'Approve',
        onClick: () => onActionClick(request, 'approve'),
        fontSize: '14',
      },
      {
        text: 'Reject',
        onClick: () => onActionClick(request, 'reject'),
        fontSize: '14',
        danger: true,
      },
    ];
  }, [request, onActionClick]);

  return isPending ? <TextButtons buttons={buttons} /> : null;
};

export default ClearingRequestActions;
