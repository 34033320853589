export const PENDING_PII = 'pending_pii';
export const PENDING_PII_REVIEW = 'pending_pii_review';
export const KBA1 = 'kba1';
export const KBA2 = 'kba2';
export const FACE_ID_IMAGES = 'face_id_images';
export const ID_IMAGES = 'id_images';
export const IMAGES_SUBMITTED = 'images_submitted';
export const PENDING_REVIEW = 'pending_review';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const COMPLIANCE = 'compliance';
export const COMPLIANCE_REVIEW = 'compliance_review';
export const COMPLIANCE_SUBMITTED = 'compliance_submitted';
export const COMPLIANCE_APPROVED = 'compliance_approved';
export const PII_SUBMITTED = 'pii_submitted';
