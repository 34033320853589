import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Header } from 'semantic-ui-react';
import {
  createLoadingSelector,
  fundsDesignation,
  NON_SEG,
  SEG,
} from 'erisxkit/client';
import { USD } from '../constants/assetTypes';
import { getActiveAccount } from '../reducers/activeAccountSelectors';
import {
  bankDetails,
  getDepositBankDetails,
} from '../reducers/fundTransfersReducer';
import BankInformation from '../components/BankInformation';

class BankInformationContainer extends Component {
  componentDidMount() {
    this.getBankDetails();
  }

  getBankDetails = () => {
    const { account } = this.props;

    if (!isEmpty(account)) {
      const fundsDesignations =
        account.fundsDesignation === fundsDesignation[SEG].value
          ? [fundsDesignation[NON_SEG].value, fundsDesignation[SEG].value]
          : [fundsDesignation[NON_SEG].value];

      const payload = {
        accountId: account.accountId,
        assetType: USD,
        fundsDesignation: fundsDesignations,
      };
      this.props.bankDetails(payload);
    }
  };

  render() {
    const { bankDetailsList, bankDetailsLoading } = this.props;

    return (
      <section className="main-holdings">
        <Header as="h1">
          Banking Information
          <Header.Subheader>
            Cboe Digital Acccounts Information
          </Header.Subheader>
        </Header>
        {bankDetailsList.length &&
          bankDetailsList.map((bankDetail) => (
            <BankInformation
              key={bankDetail.accountNumber}
              bankDetails={bankDetail}
              bankDetailsLoading={bankDetailsLoading}
            />
          ))}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  bankDetailsList: getDepositBankDetails(state),
  bankDetailsLoading: createLoadingSelector(['BANK_DETAILS'])(state),
});

const mapDispatchToProps = {
  bankDetails,
};
BankInformationContainer.propTypes = {
  account: PropTypes.objectOf(PropTypes.any),
  bankDetails: PropTypes.func,
  bankDetailsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  bankDetailsLoading: PropTypes.bool,
};

BankInformationContainer.defaultProps = {
  account: {},
  bankDetails: () => {},
  bankDetailsList: [],
  bankDetailsLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankInformationContainer);
