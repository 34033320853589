import React from 'react';
import classnames from 'classnames';
import useIsFCM from '../../hooks/useIsFCM';

const IconsBG = () => {
  const isFcm = useIsFCM();
  return (
    <section
      className={classnames({
        'erisx-icons-bg': true,
        'fcm-icon': Boolean(isFcm),
      })}
    >
      <aside className="erisx-icon-bg left" />
      <aside className="erisx-icon-bg spacer" />
      <aside className="erisx-icon-bg right" />
    </section>
  );
};

export default IconsBG;
