import styled, { css } from 'styled-components';
import Datetime from 'react-datetime';
import { Popup } from 'semantic-ui-react';
import colors from '../../../constants/colors';

export const Label = styled.p`
  color: ${colors.GRAY_1};
  margin-bottom: 0px;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
export const FilterContainer = styled.div`
  margin-bottom: 10px;
`;

export const filterInputStyle = css<{ error: boolean }>`
  background-color: ${({ theme }) => theme.table.input.bg};
  border-radius: 3px;
  border: ${({ error, theme }) =>
    error ? `1px solid ${colors.RED_ERROR}` : theme.table.input.border};
  color: ${({ theme }) => theme.table.input.color};
  height: 30px;
  padding-left: 10px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Input = styled.input`
  ${filterInputStyle};
`;

export const StyledDateTime = styled(Datetime)`
  & input {
    ${filterInputStyle}
    border: ${({ theme }) => theme.table.input.border};
  }
  .rdtPicker {
    margin-top: 10px;
  }
`;

interface Props {
  active: boolean;
}

export const StyledPopup = styled(Popup)<Props>`
  &&& {
    box-shadow:
      0px 6px 6px -3px rgb(0 0 0 / 20%),
      0px 10px 14px 1px rgb(0 0 0 / 14%),
      0px 4px 18px 3px rgb(0 0 0 / 12%);

    background: ${({ theme }) => theme.table.filter.bg};

    &:before {
      background: ${({ theme }) => theme.table.filter.bg};
    }

    & input {
      color: ${({ active, theme }) =>
        active ? theme.table.accent : theme.table.input.color};
    }
  }
`;
