import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'erisxkit/client';
import {
  TradingParticipant,
  CARRow,
} from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import TextButton from '../../../../common/TextButton';
import { TRADING_PARTICIPANT_LINK_CAR } from '../../../../constants/modalTypes';
import { ApprovedParticipantAction } from './ParticipantActions';

const CarActions: React.FC<{
  car: CARRow;
  participant: TradingParticipant;
}> = ({ car, participant }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CAR, {
        action: ApprovedParticipantAction.UNLINK,
        car,
        participant,
      })
    );
  }, [car, dispatch, participant]);
  return <TextButton text="Unlink CAR" onClick={onClick} fontSize="14" />;
};

export default CarActions;
