import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

// export const SAVE_FILE = 'SAVE_FILE';
export const UPDATE_IMAGE_REQUEST = 'UPDATE_IMAGE_REQUEST';
export const UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS';
export const UPDATE_IMAGE_FAILED = 'UPDATE_IMAGE_FAILED';

export const UPDATE_IMAGE = 'UPDATE_IMAGE';

export const SUBMIT_IMAGES = 'SUBMIT_IMAGES';

export const IMAGES_REQUEST = 'IMAGES_REQUEST';
export const IMAGES_SUCCESS = 'IMAGES_SUCCESS';
export const IMAGES_FAILED = 'IMAGES_FAILED';

export const ID_TYPES_REQUEST = 'ID_TYPES_REQUEST';
export const ID_TYPES_SUCCESS = 'ID_TYPES_SUCCESS';
export const ID_TYPES_FAILED = 'ID_TYPES_FAILED';

//* Initial State */
const initialState = {};

export const updateImage = createRoutine(UPDATE_IMAGE);
export const updateImagesPromiseCreator = promisifyRoutine(updateImage);

//* Reducer */
export default function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case IMAGES_SUCCESS:
      return { ...state, ...action.payload };
    case updateImage.SUCCESS:
      return { ...state, images: action.payload };
    case ID_TYPES_SUCCESS:
      return { ...state, idTypes: action.payload };
    default:
      return state;
  }
}

/** Selectors */
export const fetchIdTypes = (state) =>
  _.get(state, ['file', 'idTypes'], [{ text: 'No ID types found.' }]);
// get images state //
export const getImagesState = (state) => _.get(state, ['file', 'images'], {});

export const submitImages = createRoutine(SUBMIT_IMAGES);

export const submitImagesPromiseCreator = promisifyRoutine(submitImages);

export const images = () => ({
  type: IMAGES_REQUEST,
});

// get available ID types
export const idTypes = () => ({
  type: ID_TYPES_REQUEST,
});
