import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import moment from 'moment';
import { get } from 'lodash';
import { memberDailyStatement } from '../reducers/utilitiesReducer';
import { getAllAccountsList } from '../reducers/accountsReducer';
import DailyStatements from '../components/DailyStatements';

const mapDispatchToProps = {
  memberDailyStatement,
};

const mapStateToProps = (state) => ({
  fileExportLoading: createLoadingSelector(['MEMBER_DAILY_STATEMENT'])(state),
  accounts: getAllAccountsList(state),
});

class DailyStatementsContainer extends Component {
  componentDidMount = () => {
    this.setState({
      accountId: get(this.props, ['accounts', '0', 'accountId'], ''),
      date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    });
  };

  onChange = (e, { name, value }) => this.setState({ [name]: value });

  // return promise for when member file is fetched
  handleExport = () => this.props.memberDailyStatement(this.state);

  render = () => (
    <DailyStatements
      handleExport={this.handleExport}
      onChange={this.onChange}
      loading={this.props.fileExportLoading}
      accounts={this.props.accounts}
    />
  );
}

DailyStatementsContainer.propTypes = {
  memberDailyStatement: PropTypes.func.isRequired,
  fileExportLoading: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object),
};

DailyStatementsContainer.defaultProps = {
  fileExportLoading: false,
  accounts: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyStatementsContainer);
