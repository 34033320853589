import { format } from 'erisxkit/client';
import get from 'lodash/get';
import { USD } from '../constants/assetTypes';
import { PRECISION_USD } from '../constants/precision';
import { FIAT } from '../constants/currencyTypes';
import { formatDateTimeValue, formatDateValue } from '../utils/formatters';
import { formatFiat } from '../utils/methods';

const assetBalances = ({ showFutures = false, showLossProfits = false }) => [
  {
    Header: 'Asset',
    id: 'asset',
    accessor: (row) => (row.assetType ? row.assetType.toUpperCase() : ''),
    className: 'text-right',
    headerClassName: 'text-right',
    width: 80,
  },
  {
    Header: 'FD',
    id: 'fd',
    accessor: 'fd',
    className: 'text-right',
    headerClassName: 'text-right',
    width: 40,
    show: showFutures,
  },
  {
    Header: 'Opening',
    accessor: (row) => format(row.openingBalance),
    id: 'openingBalance',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Spot Movements',
    accessor: (row) => row.spotMovement,
    id: 'spotMovement',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 115,
  },
  {
    Header: 'Ex Fees',
    accessor: (row) => format(row.exchangeFees),
    id: 'exchangeFees',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 70,
  },
  {
    Header: 'Cl Fees',
    accessor: (row) => format(row.clearingFees),
    id: 'clearingFees',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 70,
  },
  {
    Header: 'Other Fees',
    accessor: (row) => format(row.otherFees),
    id: 'otherFees',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 90,
  },
  {
    Header: 'Asset Movements',
    accessor: (row) => row.assetMovement,
    id: 'assetMovement',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 120,
  },
  {
    Header: 'Realized P&L',
    accessor: (row) => row.realizedPAndL,
    id: 'realizedPAndL',
    className: 'text-right mono',
    headerClassName: 'text-right',
    show: showFutures,
  },
  {
    Header: 'Futures Delivery',
    accessor: (row) => row.futuresDelivery,
    id: 'futuresDelivery',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 115,
    show: showFutures,
  },
  {
    Header: 'Closing',
    accessor: (row) => format(row.closingBalance),
    id: 'closingBalance',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Total Equity',
    accessor: (row) => format(row.totalEquity),
    id: 'totalEquity',
    className: 'text-right mono',
    headerClassName: 'text-right',
    show: showFutures,
  },
  {
    Header: 'Reserved Margin',
    accessor: (row) => format(row.reservedMargin),
    id: 'reservedMargin',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 115,
    show: showFutures,
  },
  {
    Header: 'Closed Reserved Margin Loss',
    accessor: (row) => format(row.closedReserveLoss),
    id: 'marginLoss',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
    show: showLossProfits,
  },
  {
    Header: 'Total Excess Deficit',
    accessor: (row) => format(row.totalExcessDeficit),
    id: 'totalExcessDeficit',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
    show: showFutures,
  },
  {
    Header: 'Net Liquidating Value',
    accessor: (row) => format(row.netLiquidatingValue),
    id: 'netLiquidatingValue',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
  },
  {
    Header: 'Ref Price',
    accessor: (row) => format(row.px),
    id: 'px',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
  },
  {
    Header: 'Total USD Value',
    accessor: (row) =>
      format(row.totalUsdValue, {
        type: FIAT,
        currency: USD,
        truncate: PRECISION_USD,
      }),
    id: 'usdValue',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
  },
  {
    Header: 'Settlement Balance',
    accessor: (row) => {
      const isUsd = get(row, 'assetType') === USD;
      const balance = get(row, 'settlementBalance');
      if (isUsd) {
        return formatFiat(balance, USD) || '-';
      }
      return balance || '-';
    },
    id: 'settlementBalance',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 160,
  },
  {
    Header: 'Settlement Time',
    accessor: (row) => {
      const date = get(row, 'settlementTime');
      return date ? formatDateTimeValue(date) : '-';
    },
    id: 'settlementTime',
    className: 'text-right mono',
    headerClassName: 'text-right',
    width: 200,
  },
  {
    Header: 'Reserved OTE',
    accessor: (row) => format(row.reservedOte),
    id: 'reservedOte',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 105,
    show: showFutures,
    resizable: false,
  },
  {
    Header: 'Closed Reserved Margin Profit',
    accessor: (row) => format(row.closedReserveProfit),
    id: 'marginProfit',
    className: 'text-right mono',
    headerClassName: 'text-right',
    minWidth: 130,
    show: showLossProfits,
  },
];

export default assetBalances;
