import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderField, renderDropdown } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import { UISegment } from '../../styles/styled';

const Question = ({ field, questions = [], index }) => (
  <Form key={index} className="security-question" autoComplete="off">
    <Field
      component={renderDropdown}
      className="onboarding-field"
      placeholder="Select Question"
      type="text"
      name={`${field}.questionId`}
      selection
      closeOnChange
      fluid
      options={questions.map((question) => ({
        text: question.question,
        value: question.questionId,
        disabled: question.disabled,
      }))}
      validate={rules.required}
      search={false}
      required
      selectOnBlur={false}
      data-cy={`ob-app-approval-sec-question-${index}`}
    />
    <Field
      fluid
      className="onboarding-field"
      placeholder="Answer"
      name={`${field}.choice`}
      component={renderField}
      validate={rules.required}
      required
      data-cy={`ob-app-approval-sec-answer-${index}-field`}
    />
  </Form>
);

const renderQuestions = ({ fields, questions }) => (
  <div>
    {fields.map((field, index) => (
      <Question field={field} key={index} questions={questions} index={index} />
    ))}
  </div>
);

const Header = styled.div`
  color: ${({ theme }) => theme.text.header};
`;
export const SecurityQuestions = ({
  next,
  loading,
  questions = [],
  invalid,
}) => {
  return (
    <div className="onboarding-container">
      <UISegment className="onboarding-content">
        <Header className="onboarding-header">
          Please answer some security questions.
        </Header>
        <FieldArray
          name="answers"
          component={renderQuestions}
          questions={questions}
        />
        <Button
          primary
          loading={loading}
          onClick={next}
          disabled={invalid}
          data-dd-action-name="Submit Security Questions"
          data-cy="ob-app-approval-submit-security-questions"
        >
          Submit & Continue
        </Button>
        <small className="helper info text-right">*Required</small>
      </UISegment>
    </div>
  );
};

export default reduxForm({
  form: 'security',
  initialValues: {
    answers: [{}, {}, {}],
  },
})(SecurityQuestions);
