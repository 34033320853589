import React, { Fragment } from 'react';
import { Modal, Card } from 'semantic-ui-react';

const SelectMemberModal = ({ members = [], memberClicked }) => (
  <Fragment>
    <Modal.Header>Please Select Member:</Modal.Header>
    <Modal.Content>
      <Card.Group className="vert-flex">
        {members.map((member) => (
          <Card
            id="member-select-card"
            memberId={member.memberId}
            onClick={(e, { memberId }) => memberClicked(memberId)}
          >
            <Card.Content>{member.memberLabel}</Card.Content>
          </Card>
        ))}
      </Card.Group>
    </Modal.Content>
  </Fragment>
);

export default SelectMemberModal;
