import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import PropTypes from 'prop-types';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import { showModal, hideModal, tradingApplications } from 'erisxkit/client';
import {
  storeUser,
  getLoggedInUser,
  fetchPII,
  fetchUserPromiseCreator,
  fetchAuthUserPromiseCreator,
} from '../reducers/userReducer';
import {
  selectMember,
  getSelectedMemberId,
  fetchMembersV2,
} from '../reducers/membersReducer';
import { fetchApiCredentials } from '../reducers/apiCredentialsReducer';
import { arrayToObject } from '../utils/methods';
import {
  fetchAccountsPromiseCreator,
  fetchAccounts,
  getAllAccountsCount,
  selectAccount,
} from '../reducers/accountsReducer';
import { getActiveAccountId } from '../reducers/activeAccountSelectors';
import {
  fetchAssetTypes,
  getAssetTypesCount,
  fetchAssetTypesPromiseCreator,
  lastPrice,
} from '../reducers/assetTypesReducer';
import { setEnvAttr } from '../reducers/envReducer';
import history from '../constants/history';
import { ONBOARDED_STATES } from '../constants/userStates';
import { MEMBER_SELECTION } from '../constants/modalTypes';
import {
  partyList,
  securityList,
  topOfBook,
  marginProducts,
} from '../reducers/orderEntryReducer';
import { datadogRum } from '@datadog/browser-rum';
import { VERSION } from '../../config/version';
import EnvPromise from '../config/env';
import {
  fetchMemberUserTypes,
  getMemberUserTypesCount,
} from '../reducers/memberUserTypesReducer';
import { getUserHasMemberOfType } from '../reducers/userReducer';
import { FCM_MEMBER_TYPE } from '../constants/memberTypes';
import ACCOUNT_ORIGINS from '../constants/accountOrigins';

export const USD_BASE = '/USD';

class InitialDataContainer extends Component {
  state = { valid: true, selectMember: false };

  componentDidMount() {
    const {
      accountsCount,
      assetTypesCount,
      auth,
      user,
      storeUser,
      fetchUserPromiseCreator,
      fetchAuthUserPromiseCreator,
      fetchAssetTypesPromiseCreator,
      fetchAssetTypes,
      fetchPII,
      fetchAccounts,
      selectMember,
      selectedMemberId,
      fetchMembersV2,
      partyList,
      securityList,
      marginProducts,
      lastPrice,
      fetchMemberUserTypes,
      memberUserTypesCount,
      fetchApiCredentials,
      setEnvAttr,
      // topOfBook,
    } = this.props;

    if (auth && !auth.interval) {
      auth.startInterval();
    }

    EnvPromise.then((env) => {
      this.setState({
        clearingWsHost: env.clearingWsHost,
        enableMargin: env.enableMargin,
        isDisableNewMemberUser: env.disableNewMemberUsers,
      });
      setEnvAttr({ enableMargin: env.enableMargin });
      if (env.ddRumApplicationId && env.ddRumApplicationId !== '') {
        datadogRum.init({
          applicationId: env.ddRumApplicationId,
          clientToken: env.ddRumClientToken,
          site: 'datadoghq.com',
          version: VERSION,
          env: env.ddEnv,
          service: env.ddService,
          sampleRate: 100,
          trackInteractions: env.ddRumTrackInteractions
            ? env.ddRumTrackInteractions === 'true'
            : true,
          allowedTracingOrigins: env.ddRumTracingOrigins
            ? env.ddRumTracingOrigins
                .trim()
                .split(',')
                .map((x) => x.trim())
                .filter((x) => x != '')
            : '',
          beforeSend: (event) => {
            if (
              event.resource &&
              event.resource.url &&
              event.resource.url !== ''
            ) {
              // remove the id_token query parameter
              event.resource.url = event.resource.url.replace(
                /id_token=[^&]*/,
                'id_token=masked',
              );
              // remove the access_token query parameter
              event.resource.url = event.resource.url.replace(
                /access_token=[^&]*/,
                'access_token=masked',
              );
              // remove the state query parameter
              event.resource.url = event.resource.url.replace(
                /state=[^&]*/,
                'state=masked',
              );
              // remove the auth0Client query parameter
              event.resource.url = event.resource.url.replace(
                /auth0Client=[^&]*/,
                'auth0Client=masked',
              );
              // remove the nonce query parameter
              event.resource.url = event.resource.url.replace(
                /nonce=[^&]*/,
                'nonce=masked',
              );
            }

            if (
              event.action &&
              event.action.target &&
              event.action.target.name &&
              event.action.target.name !== ''
            ) {
              event.action.target.name = event.action.target.name.replace(
                /\S+@\S+/g,
                'masked_email',
              );
            }

            // Mask a JWT token
            if (event.error) {
              const jwtMask = new RegExp(
                '([A-Za-z0-9-_=]{20,}\\.)([A-Za-z0-9-_=]{3,}\\.)([A-Za-z0-9-_.+/=]{43,})',
                'g',
              );
              if (event.error.message && event.error.message !== '') {
                event.error.message = event.error.message.replace(
                  jwtMask,
                  '$1$2[masked_sig]',
                );
              }
              if (event.error.stack && event.error.stack !== '') {
                event.error.stack = event.error.stack.replace(
                  jwtMask,
                  '$1$2[masked_sig]',
                );
              }
            }
          },
        });
      }
    });

    if (_.isEmpty(user)) {
      try {
        const auth0User = jwtDecode(auth.getSession().accessToken);
        storeUser(auth0User);
      } catch (e) {
        // log the user out if the id_token is not valid.
        console.error('Invalid Jwt token');
        auth.authorize({
          prompt: 'login',
        });
      }
      fetchUserPromiseCreator().then((successPayload) => {
        if (
          _.get(successPayload, 'statusCode', '') === 404 &&
          this.state.isDisableNewMemberUser
        ) {
          history.push('/spot-decom');
        } else {
          fetchMembersV2();
          fetchPII();
          fetchAuthUserPromiseCreator();
          fetchMemberUserTypes();
          fetchApiCredentials();
          // set localStorage item, default to null so that global column widths still work

          datadogRum.setUser({
            id: successPayload.userId,
            name: successPayload.userId,
          });
          if (
            localStorage.getItem('erisx_user') !==
            _.get(successPayload, 'userId', null)
          ) {
            localStorage.setItem(
              'erisx_user',
              _.get(successPayload, 'userId', null),
            );
          }
          if (!_.get(successPayload, 'categories', []).includes('member')) {
            this.setState({ valid: false });
            return;
          }
        }
      });
    } else {
      datadogRum.setUser({
        id: user.userId,
        name: user.userId,
      });
      if (
        (!accountsCount || !assetTypesCount || !memberUserTypesCount) &&
        ONBOARDED_STATES.includes(user.state)
      ) {
        if (!accountsCount) {
          fetchAccounts();
        }
        if (!assetTypesCount) {
          fetchAssetTypes();
        }
        if (!memberUserTypesCount) {
          fetchMemberUserTypes();
        }
      }
    }
  }

  shouldComponentUpdate = () => {
    if (this.state.selectMember) {
      return false;
    }
    return true;
  };

  componentDidUpdate(prevProps) {
    if (
      !ONBOARDED_STATES.includes(_.get(prevProps, 'user.state', '')) &&
      ONBOARDED_STATES.includes(_.get(this.props, 'user.state', ''))
    ) {
      this.getOnboardedUserData(this.props.user);
    }
  }

  useAccount(account) {
    this.props.selectAccount(account.accountId);
    this.props.selectMember(account.memberId);
    localStorage.setItem('erisx_account', account.accountId);
    localStorage.setItem('erisx_member', account.memberId);
  }
  getOnboardedUserData(user) {
    const {
      accountsCount,
      fetchAssetTypesPromiseCreator,
      fetchAccountsPromiseCreator,
      fetchAccounts,
      selectMember,
      selectedMemberId,
      partyList,
      securityList,
      marginProducts,
      lastPrice,
      selectedAccountId,
      selectAccount,
      fetchMemberUserTypes,
    } = this.props;
    // if it's a rsbix user, redirect them to that login
    if (
      user.defaultTradingApp ===
      arrayToObject(tradingApplications, 'value').rsbix.value
    ) {
      window.location.assign(`${this.props.rsbixFrontend}`);
    }
    // only call these if the state has been onboarded for the user.
    const isCurrentUserFCMTypeMember = getUserHasMemberOfType({ user }, [
      FCM_MEMBER_TYPE,
    ]);

    if (isCurrentUserFCMTypeMember) {
      marginProducts();
    } else {
      partyList();
      securityList();
    }

    fetchMemberUserTypes();

    fetchAssetTypesPromiseCreator().then(
      (assetTypes) => {
        lastPrice({
          symbol: assetTypes.map((at) => `${at.symbol}${USD_BASE}`),
          time: moment().format(),
        });
      },
      (fail) => {
        console.log(fail);
      },
    );
    if (!isCurrentUserFCMTypeMember) {
      // connect to web socket
      const socket = socketIOClient(
        `${window.location.protocol.replace('http', 'ws')}//${
          this.state.clearingWsHost
        }`,
        {
          transports: ['websocket'],
        },
      );
      // start listening to socket responses
      socket.on('topOfBook', (data) => {
        this.props.topOfBookSuccess(data);
      });
    }

    fetchAccountsPromiseCreator().then(({ accounts }) => {
      if (!selectedAccountId) {
        // first check if there's a selectedMemberId in the local storage
        const accountId = localStorage.getItem('erisx_account');

        const selectedAccount = _.find(accounts, { accountId });

        if (selectedAccount) {
          selectAccount(selectedAccount.accountId);
          selectMember(selectedAccount.memberId);
          localStorage.setItem('erisx_account', selectedAccount.accountId);
          localStorage.setItem('erisx_member', selectedAccount.memberId);
        } else {
          // check if multiple users are associated with user
          // if only one member is associated with user, select it by default.
          // if there are no members linked to user
          if (!accounts.length) {
            history.push('/pending');
          } else if (accounts.length === 1) {
            this.useAccount(accounts[0]);
          } else if (
            isCurrentUserFCMTypeMember &&
            this.state.enableMargin === 'true'
          ) {
            // FCM Users default the selected (active) account
            // to the first account that is customer
            const customerAcc = accounts.find(
              (acc) => acc.origin === ACCOUNT_ORIGINS.CUSTOMER,
            );
            // If this FCM use
            this.useAccount(customerAcc);
          }
        }
      }
    });
  }

  selectMemberCallback = () => {
    this.setState({ selectMember: false });
  };

  render() {
    if (
      !this.state.valid ||
      (this.props.user.categories && this.props.user.categories[0] !== 'member')
    ) {
      history.push('/restricted');
    }

    if (this.state.selectMember) {
      this.props.showModal(MEMBER_SELECTION, {
        hideCloseButton: true,
        dimmer: 'blurring',
        members: this.props.user.memberLinks,
        size: 'mini',
        selectMemberCallback: this.selectMemberCallback,
      });
    }

    //If DisableNewMemberUser flag is true, and the user doesn't exist, then will show the Splash Page
    if (this.props.user?.statusCode === 404 && this.state.isDisableNewMemberUser) {
      history.push('/spot-decom');
    }

    // if the user isn't onboarded, take them to the register page.  over there, they can see
    // what they need to do to get onboarded.
    // If we don't have a user.state set at all, that means the getClearingUser()
    // request has not returned yet. (e.g. if PII returns first)
    // If user state is in any of the futures

    if (
      this.props.user.state &&
      !ONBOARDED_STATES.includes(this.props.user.state)
    ) {
      if (
        window.location.pathname !== '/welcome' &&
        window.location.pathname !== '/register' &&
        window.location.pathname !== '/pending' &&
        window.location.pathname !== '/capture'
      ) {
        if (
          this.props.user.appState !== 'pending_pii' &&
          this.props.user.state !== 'firm_funding' &&
          this.props.user.state !== 'firm_security'
        ) {
          history.push('/welcome');
        } else {
          history.push('/register');
        }
      }
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchUserPromiseCreator,
      fetchAuthUserPromiseCreator,
      fetchAssetTypesPromiseCreator,
      fetchAccountsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      storeUser,
      fetchAssetTypes,
      fetchPII,
      fetchAccounts,
      selectMember,
      showModal,
      hideModal,
      fetchMembersV2,
      partyList,
      securityList,
      marginProducts,
      topOfBookSuccess: topOfBook.success,
      lastPrice,
      selectAccount,
      fetchMemberUserTypes,
      fetchApiCredentials,
      setEnvAttr,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  accountsCount: getAllAccountsCount(state),
  assetTypesCount: getAssetTypesCount(state),
  selectedMemberId: getSelectedMemberId(state),
  selectedAccountId: getActiveAccountId(state),
  memberUserTypesCount: getMemberUserTypesCount(state),
});

InitialDataContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  fetchAssetTypes: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialDataContainer);
