import React from 'react';
import styled from 'styled-components';
import { Separator } from '../../../styles/styled';
import StyledButton from '../../StyledButton';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${Separator} {
    margin: 12px 0px 12px 0px;
    background-color: ${({ theme }) => theme.table.filter.separator};
  }
`;

const FilterButton = styled(StyledButton)`
  padding: 5px 10px;
  width: 67px;
  font-size: 15px;
`;

type Props = {
  onClear: (columnId: string) => void;
  onApply: (columnId: string) => void;
};

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;
/*
  Clear and Apply buttons for EMPTable8's filters.
  It's agnostic to the table type (a.k.a. local / server side filtering) 
  @prop - onClear: Callback to handle clearing a filter
  @prop - onApply: Callback to handle clearing a filter
*/
const FilterActions = ({ onClear, onApply }: Props) => (
  <Actions>
    <Separator />
    <Buttons>
      <FilterButton text="Clear" onClick={onClear} />
      <FilterButton text="Apply" primary onClick={onApply} />
    </Buttons>
  </Actions>
);

export default FilterActions;
