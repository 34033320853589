import React from 'react';
import { Icon } from 'semantic-ui-react';
import { depositFrequency } from '../constants/depositFrequency';

const ACHDepositsMetadata = (editACHDeposit, deleteACHDeposit) => [
  {
    columns: [
      {
        Header: 'Recurring Schedule',
        id: 'schedule',
        accessor: 'schedule',
        headerClassName: 'pull-left',
        accessor: (d) => (
          <>{depositFrequency[d.schedule.toLowerCase()]?.text}</>
        ),
      },
      {
        Header: 'Next Deposit',
        id: 'nextDate',
        accessor: 'nextDate',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Clearing Account',
        id: 'account',
        accessor: 'account',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Linked Account Label',
        id: 'nickname',
        accessor: 'nickname',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: 'amount',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Actions',
        id: 'actions',
        headerClassName: 'pull-left',
        accessor: (d) => (
          <>
            <Icon onClick={() => editACHDeposit(d)} link name="edit" />
            <Icon
              onClick={() => deleteACHDeposit(d)}
              style={{ marginLeft: '5px' }}
              link
              name="trash"
            />
          </>
        ),
        filterable: false,
        resizable: false,
      },
    ],
  },
];

export default ACHDepositsMetadata;
