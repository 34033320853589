import _ from 'lodash';
import moment from 'moment';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';

export const LINKED_MEMBER_ASSET_ACCOUNTS = 'LINKED_MEMBER_ASSET_ACCOUNTS';
export const CREATE_LINKED_BANK_ACCOUNT_REQUEST =
  'CREATE_LINKED_BANK_ACCOUNT_REQUEST';
export const CREATE_LINKED_BANK_ACCOUNT_SUCCESS =
  'CREATE_LINKED_BANK_ACCOUNT_SUCCESS';
export const CREATE_LINKED_BANK_ACCOUNT_FAILED =
  'CREATE_LINKED_BANK_ACCOUNT_FAILED';

export const DELETE_LINKED_BANK_ACCOUNT_REQUEST =
  'DELETE_LINKED_BANK_ACCOUNT_REQUEST';
export const DELETE_LINKED_BANK_ACCOUNT_SUCCESS =
  'DELETE_LINKED_BANK_ACCOUNT_SUCCESS';
export const DELETE_LINKED_BANK_ACCOUNT_FAILED =
  'DELETE_LINKED_BANK_ACCOUNT_FAILED';

export const CREATE_LINKED_CRYPTO_ADDRESS_REQUEST =
  'CREATE_LINKED_CRYPTO_ADDRESS_REQUEST';
export const CREATE_LINKED_CRYPTO_ADDRESS_SUCCESS =
  'CREATE_LINKED_CRYPTO_ADDRESS_SUCCESS';
export const CREATE_LINKED_CRYPTO_ADDRESS_FAILED =
  'CREATE_LINKED_CRYPTO_ADDRESS_FAILED';

export const DELETE_LINKED_CRYPTO_ADDRESS_REQUEST =
  'DELETE_LINKED_CRYPTO_ADDRESS_REQUEST';
export const DELETE_LINKED_CRYPTO_ADDRESS_SUCCESS =
  'DELETE_LINKED_CRYPTO_ADDRESS_SUCCESS';
export const DELETE_LINKED_CRYPTO_ADDRESS_FAILED =
  'DELETE_LINKED_CRYPTO_ADDRESS_FAILED';

export const PLAID_VERIFY_ACCOUNT_REQUEST = 'PLAID_VERIFY_ACCOUNT_REQUEST';
export const PLAID_VERIFY_ACCOUNT_SUCCESS = 'PLAID_VERIFY_ACCOUNT_SUCCESS';
export const PLAID_VERIFY_ACCOUNT_FAILED = 'PLAID_VERIFY_ACCOUNT_FAILED';

export const LOOKUP_HASH_ID = 'LOOKUP_HASH_ID';
export const IRA_BANK_MSG = 'IRA_BANK_MSG';
export const CREATE_LINKED_MEMBER_BANK_ACCOUNT_IRA_MEMBER =
  'CREATE_LINKED_MEMBER_BANK_ACCOUNT_IRA_MEMBER';

export const lookupHashId = createRoutine(LOOKUP_HASH_ID);
export const lookupHashIdPromiseCreator = promisifyRoutine(lookupHashId);

export const iraBankMsg = createRoutine(IRA_BANK_MSG);
export const iraBankMsgPromiseCreator = promisifyRoutine(iraBankMsg);

export const createLinkedMemberBankAccountIraMember = createRoutine(
  CREATE_LINKED_MEMBER_BANK_ACCOUNT_IRA_MEMBER,
);
export const createLinkedMemberBankAccountIraMemberPromiseCreator =
  promisifyRoutine(createLinkedMemberBankAccountIraMember);

export const fetchLinkedMemberAssetAccounts = createRoutine(
  LINKED_MEMBER_ASSET_ACCOUNTS,
);
export const fetchLinkedMemberAssetAccountsPromiseCreator = promisifyRoutine(
  fetchLinkedMemberAssetAccounts,
);

//* Initial State */
const initialState = {};

//* Reducer */
export default function linkedAccountsReducer(state = initialState, action) {
  let bankAccounts;
  let cryptoAddresses;

  switch (action.type) {
    case fetchLinkedMemberAssetAccounts.SUCCESS:
      bankAccounts = action.payload.assetAccounts.filter(
        (assetAccount) => assetAccount.type === 'bank',
      );
      cryptoAddresses = action.payload.assetAccounts.filter(
        (assetAccount) => assetAccount.type === 'crypto',
      );
      return {
        ...state,
        bankAccounts,
        cryptoAddresses,
      };
    case CREATE_LINKED_CRYPTO_ADDRESS_SUCCESS:
      return {
        ...state,
        cryptoAddresses: [...state.cryptoAddresses, action.payload],
      };
    case PLAID_VERIFY_ACCOUNT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          bankAccounts: [...(state.bankAccounts || []), action.payload],
        };
      }
    default:
      return state;
  }
}

export const getDisplayState = (address) => {
  const { state } = address;
  switch (state) {
    case 'pending':
      return 'Pending Approval';
    case 'approved':
      return 'approved';
    case 'rejected':
      return 'rejected';
    default:
      return 'pending';
  }
};

export const getLinkedAccountLabel = (linkedAccount) => {
  if (
    linkedAccount &&
    (linkedAccount.bankName || linkedAccount.accountNumber)
  ) {
    // Bank Account: "Bank Name - Label - *******AcctNum"
    return [
      _.get(linkedAccount, 'bankName'),
      _.get(linkedAccount, 'label'),
      [_.get(linkedAccount, 'accountNumber')]
        .filter((i) => i)
        .map((i) => `******${i}`)[0],
    ]
      .filter((i) => i)
      .join(' - ');
  }

  // Crypto Address: "Label - Address"
  return [_.get(linkedAccount, 'label'), _.get(linkedAccount, 'address')]
    .filter((i) => i)
    .join(' - ');
};

/* Selectors */
export const getLinkedCryptoAddresses = (state) =>
  _.get(state, ['linkedAccounts', 'cryptoAddresses'], []).map((address) => ({
    ...address,
    state: getDisplayState(address),
  }));
export const getLinkedBankAccounts = (state) =>
  _.chain(state)
    .get(['linkedAccounts', 'bankAccounts'], [])
    .map((account) => ({ ...account, state: getDisplayState(account) }))
    .value();

export const getAllLinkedMemberAssetAccounts = createSelector(
  [getLinkedCryptoAddresses, getLinkedBankAccounts],
  (linkedCrypto, linkedBank) => linkedCrypto.concat(linkedBank),
);

export const createLinkedBankAccount = (payload) => ({
  type: CREATE_LINKED_BANK_ACCOUNT_REQUEST,
  payload,
});

export const deleteLinkedBankAccount = (hashId) => ({
  type: DELETE_LINKED_BANK_ACCOUNT_REQUEST,
  hashId,
});

export const createLinkedCryptoAddress = (payload) => ({
  type: CREATE_LINKED_CRYPTO_ADDRESS_REQUEST,
  payload,
});

export const deleteLinkedCryptoAddress = (hashId) => ({
  type: DELETE_LINKED_CRYPTO_ADDRESS_REQUEST,
  hashId,
});

/**
 * Action generator to check an account.
 *  @param {Object} args         - Contains the following information:
 *                  -> publicToken  - One-time-use, 30-min lifetime token generated by
 *                                    Plaid Link drop-in frontend component
 *                  -> accountLabel - Account description and account number 4-digit mask
 *                  -> accountId    - Hash given by Plaid to identify bank accounts
 *                  -> bankName     - Name of financial institution
 */
export const checkBankAccount = (payload) => ({
  type: PLAID_VERIFY_ACCOUNT_REQUEST,
  payload,
});
