import React, { PureComponent } from 'react';
import Big from 'bignumber.js';
import { Form, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isEqual, get, find, upperFirst } from 'lodash';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import {
  fundsDesignation as fundsDesignationConstants,
  renderDropdown,
  NON_SEG,
  normalization,
  showModal,
  hideModal,
  createLoadingSelector,
} from 'erisxkit/client';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as rules from '../../common/forms/rules';
import AssetTypeField from '../../common/components/AssetTypeField';
import { renderCheckbox, renderField } from '../../common/forms/components';
import AddBankAccountPlaidContainer from '../../containers/LinkedAccounts/AddBankAccountPlaidContainer';
import { CONFIRM_MODAL } from '../../constants/modalTypes';
import Confirm from './Confirm';
import { DEPOSIT } from '../../constants/journalTypes';
import AvailableFundsHeader from './AvailableFundsHeader';
import { trackEvent } from '../../common/tracking';
import gaCategories from '../../constants/ga-categories';
import { USD } from '../../constants/assetTypes';
import {
  formatFiat,
  daysOfMonth,
  mapDepositFrequencyToArrayValueText,
} from '../../utils/methods';
import { userFirstFunded } from '../../common/glowRules';
import { depositFrequency } from '../../constants/depositFrequency';
import WeekDays from './WeekDays';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import hasMoreThanNDigits from '../../utils/hasMoreThanNDigits';
import { DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES } from './constants';
import { grants, subjects } from '../../constants/userPermissions';
import AccessControl from '../../common/AccessControl';

export class AchDeposit extends PureComponent {
  constructor(props) {
    super(props);
    this.limitRule = rules.maxValue(props.achDepositAmountLimit.available);
    this.minDepositRule = rules.minValue(
      props.achDepositAmountLimit.minDeposit,
    );
    this.state = { confirmed: false };
  }

  componentDidMount = () => {
    const {
      currencyOptions,
      initialize,
      change,
      linkedBankAccounts,
      onBankAccountChange,
      activeAccountFD,
    } = this.props;
    // select only given option by default, if applicable
    if (currencyOptions.length === 1) {
      // set default values
      const assetType = currencyOptions[0].value;

      initialize({ assetTypeWithFundsDesignation: assetType });
      change('assetType', assetType);
      change('fundsDesignation', activeAccountFD);
      this.props.fetchLimit(assetType, activeAccountFD);
    }

    change('frequency', 'default');

    if (linkedBankAccounts.length === 1) {
      // set default values
      const linkedBankAccount = linkedBankAccounts[0].value;
      change('linkedBankAccount', linkedBankAccount);
      onBankAccountChange('', linkedBankAccount);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      change,
      linkedBankAccounts,
      onBankAccountChange,
      achDepositAmountLimit,
    } = this.props;
    if (
      linkedBankAccounts.length === 1 &&
      !isEqual(linkedBankAccounts, prevProps.linkedBankAccounts)
    ) {
      // set default values
      const linkedBankAccount = linkedBankAccounts[0].value;
      change('linkedBankAccount', linkedBankAccount);
      onBankAccountChange('', linkedBankAccount);
    }
    if (
      prevProps.achDepositAmountLimit.available !==
      achDepositAmountLimit.available
    ) {
      this.limitRule = rules.maxValue(achDepositAmountLimit.available);
    }
    if (
      prevProps.achDepositAmountLimit.minDeposit !==
      achDepositAmountLimit.minDeposit
    ) {
      this.minDepositRule = rules.minValue(achDepositAmountLimit.minDeposit);
    }
  }

  handleConfirm = (req) => {
    this.setState((prevState) => {
      if (prevState.confirmed === false) {
        const frequency = req.frequency;
        if (frequency !== depositFrequency.default.value) {
          this.props.createFiatDepositSchedule(req);
        } else {
          this.props.createFiatDepositRequest(req);
        }
      }
      return { confirmed: true };
    });
  };

  localDisabledValidate = (frequency, weekDay, day) => {
    if (frequency === 'weekly') {
      return !weekDay;
    }
    if (frequency === 'monthly') {
      return !day;
    }
    return false;
  };

  confirmFiatDeposit = (req) => {
    trackEvent(gaCategories.DEPOSIT_ACH, 'submit');
    if (this.props.isScheduled) {
      this.props.fetchACHDeposits({
        accountId: this.props?.account?.accountId,
      });
    }
    const accountName = get(
      find(this.props.linkedBankAccounts, {
        value: { id: req.linkedBankAccount.id },
      }),
      'text',
      '',
    );
    req.linkedMemberAssetAccountId = req.linkedBankAccount.id;
    this.props.showModal(CONFIRM_MODAL, {
      content: (
        <Confirm
          embargoPeriod={this.props.embargoPeriod}
          accountName={accountName}
          amount={req.amount}
          type="Deposit"
          instantly={get(
            this.props.achDepositAmountLimit,
            'instant.available',
            0,
          )}
          scheduled={this.props.isScheduled}
        />
      ),
      actionsClass: 'ach-actions',
      modalClass: 'ach-modal',
      rejectProps: {
        content: 'Cancel',
        secondary: true,
        onClick: this.props.hideModal,
        size: 'huge',
      },
      confirmProps: {
        size: 'huge',
        content: 'Confirm',
        primary: true,
        onClick: () => this.handleConfirm(req),
      },
      loadingSelector: (state) =>
        createLoadingSelector(['ACCOUNT_SCHEDULES'])(state),
    });
  };

  render = () => {
    const {
      achDepositAmountLimit,
      accountCode,
      change,
      currencyOptions,
      fetchLimit,
      firmCode,
      handleSubmit,
      linkedBankAccounts,
      valid,
      type = {},
      onBankAccountChange,
      user,
      linkedMemberAssetAccountId,
      amount,
      termsAccepted,
      frequency,
      weekDay,
      day,
    } = this.props;
    return (
      <section className="overlay-form ui text container">
        <Header as="h2">
          {`ACH ${upperFirst(type.action)} to account `}{' '}
          <span className="bold">{`${
            firmCode ? `${firmCode}-` : ''
          }${accountCode}`}</span>
          <Header.Subheader>
            <AvailableFundsHeader
              action={DEPOSIT}
              isFiat
              amountLimit={achDepositAmountLimit}
              disclaimer={`You can deposit up to ${formatFiat(
                achDepositAmountLimit.limit,
                USD,
              )} over a ${new Big(
                achDepositAmountLimit.limitPeriod,
              ).toFixed()} day period`}
              linkedBankAccounts={linkedBankAccounts || []}
              onAddBankAccount={() =>
                trackEvent(gaCategories.DEPOSIT_ACH, 'addNewBankAccount')
              }
              frequency={frequency}
            />
          </Header.Subheader>
        </Header>
        <Form onSubmit={handleSubmit(this.confirmFiatDeposit)}>
          <AssetTypeField
            change={change}
            currencyOptions={currencyOptions}
            postChange={fetchLimit}
          />
          <div className="info-bottom">
            <div className="max-width-flex">
              <Form.Field className="firstDiv">
                <Field
                  component={renderDropdown}
                  label="Frequency:"
                  fluid
                  required
                  placeholder=""
                  name="frequency"
                  options={mapDepositFrequencyToArrayValueText(
                    depositFrequency,
                  )}
                  validate={rules.required}
                />
                <label className="info-bottom-label">
                  {(frequency === depositFrequency.bimonthly.value ||
                    frequency === depositFrequency.monthly.value) && (
                    <span>
                      Deposits scheduled on weekends or public holidays will be
                      proccessed the next bussiness day
                    </span>
                  )}
                  {frequency === depositFrequency.weekly.value && (
                    <span>
                      Deposits scheduled on a public holiday will be proccessed
                      the next bussiness day
                    </span>
                  )}
                </label>
              </Form.Field>
              <Form.Field className="lastDiv">
                {(frequency && frequency === depositFrequency.weekly.value && (
                  <Form.Field>
                    <Field
                      component={WeekDays}
                      name="weekDay"
                      weekDay={weekDay}
                      required
                      label="Select day of the week:"
                    />
                  </Form.Field>
                )) ||
                  (frequency === depositFrequency.monthly.value && (
                    <Field
                      component={renderDropdown}
                      label="Day:"
                      name="day"
                      placeholder=""
                      required
                      options={daysOfMonth()}
                      style={{ height: '38px' }}
                    />
                  ))}
              </Form.Field>
            </div>
          </div>

          <div className="max-width-flex">
            <Form.Field className="firstDiv">
              <Field
                component={renderDropdown}
                label="Select Bank Account:"
                fluid
                required
                placeholder="Select bank account to deposit from"
                name="linkedBankAccount"
                options={linkedBankAccounts}
                validate={rules.required}
                onChange={onBankAccountChange}
                disabled={!linkedBankAccounts.length}
                data-cy="deposit-ach-select-bank-acc-dropdown"
              />
              <AccessControl
                allowedPermissions={[
                  `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
                ]}
              >
                <AddBankAccountPlaidContainer
                  glow={
                    !userFirstFunded(user) &&
                    !this.props.linkedBankAccounts.length &&
                    !linkedMemberAssetAccountId
                  }
                  linkedBankAccounts={linkedBankAccounts || []}
                  link
                  onClick={() =>
                    trackEvent(gaCategories.DEPOSIT_ACH, 'addNewBankAccount')
                  }
                />
              </AccessControl>
            </Form.Field>
            <Form.Field className="lastDiv">
              <Field
                component={renderField}
                label="Amount:"
                name="amount"
                placeholder="Enter the amount you would like to deposit"
                data-cy="ach-deposit-amount-field"
                parse={normalization.floatsOnly}
                required
                style={{ height: '38px' }}
                onChange={(event, amount) => {
                  if (
                    hasMoreThanNDigits(
                      amount,
                      DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES,
                    )
                  ) {
                    event.preventDefault();
                  }
                }}
                validate={[
                  rules.required,
                  this.limitRule,
                  this.minDepositRule,
                  rules.positiveNumbers,
                ]}
                className={classNames({
                  'pulse-button':
                    !userFirstFunded(user) &&
                    linkedMemberAssetAccountId &&
                    !amount,
                })}
              />
            </Form.Field>
          </div>
          <div className="ach-confirm">
            <IraAccountDisclaimer />
            <Field
              className={classNames({
                'pulse-button':
                  !userFirstFunded(user) &&
                  amount &&
                  linkedMemberAssetAccountId &&
                  !termsAccepted,
              })}
              component={renderCheckbox}
              validate={rules.required}
              name="termsAccepted"
              label='I authorize Cboe Clear Digital to debit my bank account in the amount entered above. Attempted reversal or reversal of the transaction (including an unsubstantiated claim of an unauthorized withdrawal) can result in your Default under the Cboe Clear Digital Rulebook, and disciplinary action which can include a fine, and/or suspension or termination of your account and trading privileges at Cboe Clear Digital and Cboe Digital Exchange. Once payment is authorized, there cannot be any changes or corrections. To complete the payment process, click the "Deposit" button.'
            />
            <Form.Button
              className={`noMarginRight ${classNames({
                'pulse-button': !userFirstFunded(user) && valid,
              })}`}
              data-cy="deposit-ach-submit-deposit-checkbox"
              primary
              content="Submit Deposit"
              disabled={
                !valid ||
                this.localDisabledValidate(frequency, weekDay, day) ||
                this.state.confirmed
              }
            />
          </div>
        </Form>
      </section>
    );
  };
}

AchDeposit.propTypes = {
  accountCode: PropTypes.string,
  achDepositAmountLimit: PropTypes.objectOf(PropTypes.any),
  change: PropTypes.func.isRequired,
  createFiatDepositSchedule: PropTypes.func.isRequired,
  createFiatDepositRequest: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  fetchLimit: PropTypes.func,
  firmCode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  linkedBankAccounts: PropTypes.arrayOf({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  showModal: PropTypes.func.isRequired,
  type: PropTypes.objectOf(PropTypes.any),
  valid: PropTypes.bool,
  fetchACHDeposits: PropTypes.func.isRequired,
  isScheduled: PropTypes.bool.isRequired,
  activeAccountFD: PropTypes.string,
};

AchDeposit.defaultProps = {
  accountCode: '',
  achDepositAmountLimit: {},
  currencyOptions: [],
  fetchLimit: () => {},
  firmCode: '',
  linkedBankAccounts: [],
  type: {},
  valid: false,
  activeAccountFD: '',
};

const selector = formValueSelector('fiatDeposit');

const AchDepositForm = reduxForm({
  form: 'fiatDeposit',
})(AchDeposit);

export default connect(
  (state) => ({
    linkedMemberAssetAccountId: selector(state, 'linkedBankAccount.id'),
    assetType: selector(state, 'assetType'),
    fundsDesignation: selector(state, 'fundsDesignation'),
    amount: selector(state, 'amount'),
    termsAccepted: selector(state, 'termsAccepted'),
    frequency: selector(state, 'frequency'),
    weekDay: selector(state, 'weekDay'),
    day: selector(state, 'day'),
  }),
  { showModal, hideModal },
)(AchDepositForm);
