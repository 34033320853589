import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import PropTypes from 'prop-types';
import CryptoWithdrawal from '../../components/FundTransfers/CryptoWithdrawal';

import { scaleBaseToMinUnit } from '../../reducers/utilitiesReducer';
import {
  WITHDRAWAL_CONFIRM,
  ADD_LINKED_ACCOUNT,
} from '../../constants/modalTypes';
import { getFundTransfer } from '../../reducers/uiReducer';
import {
  getCryptoAssetTypes,
  getLastPrice,
} from '../../reducers/assetTypesReducer';

import {
  achWithdrawalLimit,
  clearWithdrawalLimit,
  getAchWithdrawalLimit,
  fetchFeeParams,
  getFeeParams,
  ACH_WITHDRAWAL_LIMIT,
} from '../../reducers/fundTransfersReducer';

import {
  fetchLinkedMemberAssetAccounts,
  getLinkedCryptoAddresses,
} from '../../reducers/linkedAccountsReducer';
import { formatAssetTypesForFundsTransfer } from '../../utils/methods';
import {
  getActiveBalanceDetails,
  getSimpleAccountBalancesByAssetType,
} from '../../reducers/balancesReducer';
import EnvPromise from '../../config/env';

const mapStateToProps = (state) => ({
  ...state,
  achWithdrawalLimitData: getAchWithdrawalLimit(state),
  fundBalances: getActiveBalanceDetails(state),
  fundTransfer: getFundTransfer(state),
  linkedCryptoAddresses: getLinkedCryptoAddresses(state),
  linkedCryptoAddressesLoading: createLoadingSelector([
    'LINKED_CRYPTO_ADDRESSES',
  ])(state),
  loadingLimits: createLoadingSelector([ACH_WITHDRAWAL_LIMIT])(state),
  assetTypes: getCryptoAssetTypes(state),
  lastPrices: getLastPrice(state),
  balances: getSimpleAccountBalancesByAssetType(state),
  feeParams: getFeeParams(state),
});

const mapDispatchToProps = {
  achWithdrawalLimit,
  clearWithdrawalLimit,
  showModal,
  scaleBaseToMinUnit,
  fetchLinkedMemberAssetAccounts,
  fetchFeeParams,
};

const initialState = (props) => ({
  assetType: props.asset,
  amount: '',
  destination: '',
  amountError: false,
});

class CryptoWithdrawalContainer extends Component {
  state = initialState(this.props);

  componentDidMount = () => {
    this.props.fetchLinkedMemberAssetAccounts();
    EnvPromise.then(({ disabledAssets }) => this.setState({ disabledAssets }));
  };

  componentWillUnmount() {
    this.props.clearWithdrawalLimit();
  }

  fetchLimit = (assetType, fundsDesignation) => {
    const { accountId } = this.props;
    this.props.achWithdrawalLimit({ accountId, assetType, fundsDesignation });
  };

  fetchFeeParams = (assetType) => {
    const { accountId } = this.props;
    const transactionType = 'withdrawal';
    this.props.fetchFeeParams({ accountId, transactionType, assetType });
  };

  addNew = (assetType) => {
    this.props.showModal(ADD_LINKED_ACCOUNT, {
      type: 'crypto address',
      activeAssetType: assetType,
    });
  };

  confirmWithdrawal = ({ amount, assetType }) => {
    this.props.scaleBaseToMinUnit({ amount, assetType });
    this.props.showModal(WITHDRAWAL_CONFIRM, { type: 'crypto' });
  };

  render = () => {
    const data = {
      accountCode: this.props.accountCode,
      fetchLimit: this.fetchLimit,
      fetchFeeParams: this.fetchFeeParams,
      firmCode: this.props.firmCode,
      achWithdrawalLimit: this.props.achWithdrawalLimitData,
      feeParams: this.props.feeParams,
      balances: this.props.fundBalances,
      hasBalancesGreaterThanZero: this.props.hasBalancesGreaterThanZero,
      currencyOptions: formatAssetTypesForFundsTransfer(this.props),
      onCurrencyChange: this.onCurrencyChange,
      assetType: this.state.assetType,
      confirmWithdrawal: this.confirmWithdrawal,
      handleChange: this.handleChange,
      amountError: this.state.amountError,
      amount: this.state.amount,
      destination: this.state.destination,
      addNew: this.addNew,
      accountLabel: this.props.label,
      linkedCryptoAddresses: this.props.linkedCryptoAddresses,
      linkedCryptoAddressesLoading: this.props.linkedCryptoAddressesLoading,
      lastPrices: this.props.lastPrices,
      disabledAssets: this.state.disabledAssets,
      assetTypes: this.props.assetTypes,
      loadingLimits: this.props.loadingLimits,
    };

    return <CryptoWithdrawal {...data} />;
  };
}

CryptoWithdrawalContainer.propTypes = {
  lastPrice: PropTypes.func.isRequired,
  lastPricePx: PropTypes.string.isRequired,
  lastPriceSymbol: PropTypes.string.isRequired,
};

CryptoWithdrawalContainer.defaultProps = {
  balances: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CryptoWithdrawalContainer);
