import { TableActionName } from './types';
import { ColumnDef, RowData, Table } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { Parser } from 'json2csv';
import getTableActionOptions from '../utils/getTableActionOptions';
import { getColumnDefAccessor } from '../utils/utils';

const exportTable = <T>(table: Table<T>) => {
  // Parse data
  const data = table
    // Use flatRows to get expanded rows if any
    .getRowModel()
    .flatRows.filter((row) => !isEmpty(row.original))
    .map((row) => row.original);

  const fields = table
    // Get Table's ColumnDefs
    ._getColumnDefs()
    // Flatten them out to take into account grouped columns
    .reduce<ColumnDef<T>[]>((acc, curr) => {
      const groupColumns = get(curr, 'columns', []);
      if (groupColumns.length > 0) {
        return [...acc, ...groupColumns];
      }
      return [...acc, curr];
    }, [])
    // Filter out columns manually excluded for export and those without accesorKeys
    .filter((c) => {
      const hasAccessor = getColumnDefAccessor(c);
      return (
        Boolean(c.header) && !get(c, 'meta.noExport', false) && hasAccessor
      );
    })
    // Generate json2csvParser accessible column objects
    .map((c) => {
      const accessor = getColumnDefAccessor(c);
      const value = isFunction(accessor)
        ? (row: T, i: number) => accessor(row, i)
        : accessor;
      const label = c.meta?.exportHeader || c.header;
      return {
        label: String(label),
        value,
        default: '',
      };
    });

  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(data);

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const options = getTableActionOptions(table);
  const filename = get(options, [TableActionName.Export, 'filename'], null);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');
  // Passing the blob downloading url
  a.setAttribute('href', url);
  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', `${filename || 'exported'}.csv`);
  // Performing a download with click
  a.click();
};

export default exportTable;
