import React, { Fragment } from 'react';
import { Header, Button, Divider } from 'semantic-ui-react';
import { XTable } from 'erisxkit/client';
import columns from '../../metadata/apiKeysMetadata';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';

const APISettings = ({
  createApiCredentials,
  handleChange,
  label,
  apiCredentials = [],
  deleteApiCredentials,
}) => (
  <Fragment>
    <Header as="h2">Your API keys</Header>
    <AccessControl
      allowedPermissions={[`${grants.CREATE}:${subjects.API_KEYS}`]}
    >
      <Header>Create New API Key</Header>
      <Button
        id="create-api-credential"
        content="Create New API Key"
        className="tertiary"
        onClick={createApiCredentials}
      />
      <Divider hidden />
    </AccessControl>
    <XTable
      title="apiSettings"
      className="dark"
      showPageSizeOptions={false}
      showPagination={false}
      data={apiCredentials}
      loading={false}
      columns={columns(deleteApiCredentials)}
      minRows={3}
      small={false}
    />
  </Fragment>
);

export default APISettings;
