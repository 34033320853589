import React, { Component } from 'react';
import { get } from 'lodash';
import Webcam from 'react-webcam';
import { Button, Message, Segment } from 'semantic-ui-react';

const webcamTroubleShoot = ({ name }) => {
  switch (name) {
    case 'NotAllowedError':
      return [
        "It seems that you haven't given your browser permission to use the webcam.",
        "Click the camera blocked icon in your browser's address bar.",
      ];
    default:
      return [
        'Please make sure your webcam is connected and installed properly.',
      ];
  }
};

class WebcamCapture extends Component {
  state = {
    error: false,
  };
  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const image = this.webcam.getScreenshot();
    this.props.onCapture(image);
  };

  render() {
    const videoConstraints = {
      facingMode: 'user',
    };

    let height = 600;
    let width = 600;
    if (this.props.orientation === 'portrait') {
      height = 350;
      width = 350;
    }

    const { error } = this.state;

    return (
      <div className="vert-flex">
        {error && (
          <div id="webcam-error">
            <Message positive className="text-center">
              <Message.Header>
                Unfortunately we were not able to detect a camera.
              </Message.Header>
              <Message.List items={webcamTroubleShoot(error)} />
            </Message>
            <Segment>
              <p>You will need a camera to complete onboarding</p>
              <p>Are you able to continue from a mobile or another device?</p>
              <p>
                If not please contact{' '}
                <a href="mailto:digital.onboarding@cboe.com">
                  digital.onboarding@cboe.com
                </a>{' '}
                for assistance
              </p>
            </Segment>
          </div>
        )}
        <Webcam
          audio={false}
          height={height}
          ref={this.setRef}
          screenshotFormat="image/jpeg"
          width={width}
          screenshotWidth={800}
          onUserMedia={(s) => {
            this.setState({
              error: false,
            });
          }}
          onUserMediaError={(e) => {
            this.setState({
              error: e,
            });
          }}
          minScreenshotWidth={800}
          minScreenshotHeight={800}
          videoConstraints={videoConstraints}
          screenshotQuality={1}
        />
        {
          <Button primary disabled={this.state.error} onClick={this.capture}>
            Capture photo{' '}
          </Button>
        }
      </div>
    );
  }
}

export default WebcamCapture;
