import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideModal } from 'erisxkit/client';
import EnvPromise from '../../../config/env';
import Terms from '../../../components/onBoarding/GenericTerms';
import { acceptFuturesUpgrade } from '../../../reducers/userReducer';
import colors from '../../../constants/colors';
import StyledButton from '../../../common/StyledButton';
import { FUTURES_ONBOARDING } from '../../../constants/modalTypes';

const FuturesTerms = styled(Terms)`
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  min-height: 20rem !important;

  & > button {
    width: 100px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ui.segment {
    background-color: ${colors.NAVY};
  }

  .ui.header {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
`;
FuturesTerms.displayName = 'FuturesTerms';

const Button = styled(StyledButton)`
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;
Button.displayName = 'Submit';

const Header = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px !important;
  margin-bottom: 0px;
`;

const Item = styled.li`
  margin: 10px;
`;
const Wrapper = styled.div`
  margin-bottom: 15px;
  padding-left: 35px;
`;

const Disclaimer = () => (
  <Wrapper>
    <p>By clicking Submit I certify under penalties of perjury that: </p>
    <ol>
      <Item>
        The Social Security Number provided during onboarding is correct.
      </Item>
      <Item>
        I have not been notified by IRS that I'm subject to backup witholding
        due to failing to report interest and dividend income.
      </Item>
      <Item>
        I'm a citizen or resident of the United States or a person that is not a
        foreign person.
      </Item>
    </ol>
  </Wrapper>
);

const FuturesDisclaimers = ({ nextStep }) => {
  const [files, setFiles] = useState({});
  const dispatch = useDispatch();

  const onAccept = () => {
    dispatch(acceptFuturesUpgrade());
    nextStep();
  };

  useEffect(() => {
    EnvPromise.then(
      ({
        futuresSpotNaturalPersonAgreementPdfEndpoint,
        futuresAdditionalRiskDisclosurePdfEndpoint,
      }) =>
        setFiles({
          futuresSpotNaturalPersonAgreementPdfEndpoint,
          futuresAdditionalRiskDisclosurePdfEndpoint,
        }),
    );
  }, []);

  const {
    futuresSpotNaturalPersonAgreementPdfEndpoint,
    futuresAdditionalRiskDisclosurePdfEndpoint,
  } = files;

  return (
    <>
      <Header>Setting up your Futures Account</Header>
      <FuturesTerms
        files={[
          {
            file: futuresSpotNaturalPersonAgreementPdfEndpoint,
            fileName: 'Natural Person Spot and Futures Agreement',
            name: 'agreement',
          },
          {
            file: futuresAdditionalRiskDisclosurePdfEndpoint,
            fileName: 'Additional Futures Risk Disclosure',
            name: 'risk',
          },
        ]}
        header="Agreements and Disclaimers"
        subheader="You must check all boxes and click submit to proceed"
        accept={() => onAccept()}
        acceptText="Submit"
        onCancel={() => dispatch(hideModal(FUTURES_ONBOARDING))}
        cancelText="Not at this time"
        disclaimer={<Disclaimer />}
      />
    </>
  );
};

export default FuturesDisclaimers;
