import { createRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';
import moment from 'moment';

import { flatten, set, get, chain, filter, find, isEmpty } from 'lodash';
import Big from 'bignumber.js';
import { getActiveAccountId } from './activeAccountSelectors';
import { sumObj } from '../utils/methods';
import { getAssetTypesByType } from '../reducers/assetTypesReducer';
import { getLatestPrices, removeTFromTestCoins } from './orderEntryReducer';

//* Action Types */
export const BALANCE_DETAILS = 'DESIGNATED_BALANCE_DETAILS';
export const BALANCES = 'BALANCES';
export const ACCOUNTS_BALANCES = 'ACCOUNTS_BALANCES';

export const fetchBalanceDetails = createRoutine(BALANCE_DETAILS);
export const fetchBalances = createRoutine(BALANCES);
export const fetchAccountsBalances = createRoutine(ACCOUNTS_BALANCES);

//* Reducer */
export const balancesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case fetchAccountsBalances.SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const simpleBalancesReducer = handleActions(
  {
    [fetchBalances.SUCCESS]: (state, { payload }) => {
      const date = moment(payload[0].time);
      if (date.isSame(new Date(), 'day')) {
        return {
          ...state,
          curr: payload,
        };
      }
      return {
        ...state,
        prev: payload,
      };
    },
  },
  {},
);

export const getAllBalanceDetails = (state) => state.balanceDetails || {};
export const getAllSimpleBalances =
  (key = 'curr') =>
  (state) =>
    get(state, ['balances', key], []);
export const getAccountIdsBalanceDetails =
  (accountIds = []) =>
  (state) =>
    flatten(accountIds.map((id) => get(state, ['balanceDetails', id]))) || [];
export const getAccountIdsSimpleBalances =
  (accountIds = [], key = 'curr') =>
  (state) =>
    flatten(
      filter(get(state, ['balances', key]), (b) =>
        accountIds.includes(b.accountId),
      ).map((b) => b.balances),
    ) || [];

export const getSimpleAccountBalancesByAssetType = createSelector(
  [getActiveAccountId, getAllSimpleBalances()],
  (accountId, simpleBalanceDetails) =>
    get(find(simpleBalanceDetails, { accountId }), 'balances', []).reduce(
      (obj, bal) => {
        obj[bal.assetType.toLowerCase()] = bal.closingBalance;
        return obj;
      },
      {},
    ),
);

export const getBalanceDetailsByAccountIdAndAssetType = (
  accountId,
  assetType,
) => {
  return createSelector([getAllBalanceDetails], (allBalanceDetails) => {
    const accountBalanceDetails = get(allBalanceDetails, accountId);
    const usdBalanceDetails = accountBalanceDetails?.find(
      (element) => element.assetType === assetType,
    );
    return usdBalanceDetails?.totalExcessDeficit;
  });
};

export const getActiveBalances = createSelector(
  [getActiveAccountId, getAllSimpleBalances()],
  (accountId, balances) =>
    get(
      find(balances, (bal) => bal.accountId === accountId),
      'balances',
      [],
    ),
);

export const getActiveBalanceDetails = createSelector(
  [getActiveAccountId, getAllBalanceDetails],
  (accountId, balanceDetails) => {
    return balanceDetails[accountId] || [];
  },
);

export const getHasAnyBalanceGreaterThanZero = createSelector(
  [getActiveBalances],
  (balances) => balances.some(({ usdValue }) => Big(usdValue).gt(0)),
);

export const hasBalancesGreaterThanZero = createSelector(
  [getActiveBalanceDetails],
  (balances) => {
    return !!balances.filter(({ availableToTrade }) =>
      Big(availableToTrade).gt(0),
    ).length;
  },
);

export const getTotalBalances = (accountIds) =>
  createSelector([getAccountIdsBalanceDetails(accountIds)], (allBalances) => {
    const keysToSkip = ['assetType', 'fd', 'px', 'settlementTime'];
    const summed = allBalances.reduce((t, i) => {
      const settlementDetail = get(i, 'settlementDetail');
      if (settlementDetail) Object.assign(i, settlementDetail);
      if (i && i.assetType) {
        set(
          t,
          [i.assetType, i.fd],
          sumObj(get(t, [i.assetType, i.fd], {}), i, keysToSkip),
        );
        set(t, [...[i.assetType, i.fd], 'accountLabel'], i.accountLabel);
      }
      return t;
    }, {});
    return summed;
  });

export const getTotalSimpleBalances = (accountIds) =>
  createSelector([getAccountIdsSimpleBalances(accountIds)], (allBalances) => {
    const keysToSkip = ['assetType', 'lastPrice'];

    const summed = allBalances.reduce((t, i) => {
      if (i && i.assetType) {
        set(t, i.assetType, sumObj(get(t, [i.assetType], {}), i, keysToSkip));
      }
      return t;
    }, {});
    return summed;
  });

export const getTotalBalancesAllAccounts = (accountIds) =>
  createSelector(
    accountIds.map((accountId) => getTotalBalancesList([accountId])),
    (...balances) => flatten(balances),
  );

export const getTotalBalancesList = (accountIds) =>
  createSelector([getTotalBalances(accountIds)], (totalBalances) =>
    flatten(
      Object.keys(totalBalances).map((assetType) =>
        Object.keys(totalBalances[assetType]).map(
          (fd) => totalBalances[assetType][fd],
        ),
      ),
    ),
  );

export const getSimpleBalances = (accountIds, key) => (state) =>
  get(state, ['balances', key], []);

export const getSimpleBalancesList = (accountIds, key = 'curr') =>
  createSelector(
    [
      getTotalSimpleBalances(accountIds, `balances.${key}`),
      getAssetTypesByType,
    ],
    (totalBalances, assetTypes) =>
      Object.values(totalBalances).sort((a, b) => {
        if (get(assetTypes[a.assetType], 'isFiat')) {
          return 1;
        }
        if (get(assetTypes[b.assetType], 'isFiat')) {
          return -1;
        }
        return b.closingBalance - a.closingBalance;
      }),
  );

export const calculateUSDValue = (bal, px) => Big(bal).times(Big(px));

export const getTotalUsdValue = (accountIds, key) =>
  createSelector(
    [getSimpleBalances(accountIds, key), getLatestPrices, getAssetTypesByType],
    (balances, prices, assetTypes) => {
      if (!balances) {
        return 0;
      }
      const summed = chain(balances)
        .filter((b) => accountIds?.includes(b.accountId))
        .reduce(
          (t, i) =>
            t.plus(
              i.balances.reduce((balanceTotal, assetTypeBalance) => {
                let { closingBalance, assetType } = assetTypeBalance;

                assetType = removeTFromTestCoins(assetType);

                let price;

                if (get(assetTypes[assetType], 'isFiat')) {
                  price = 1;
                } else {
                  price = prices[`${assetType}/USD`];
                }
                return balanceTotal.plus(
                  calculateUSDValue(closingBalance, price),
                );
              }, Big(0)),
            ),
          Big(0),
        )
        .value();
      return summed.toFixed();
    },
  );

export const getPrevTotalUsdValue = (accountIds, key = 'prev') =>
  createSelector([getSimpleBalances(accountIds, key)], (balances) => {
    if (!balances) {
      return 0;
    }
    const summed = chain(balances)
      .filter((b) => accountIds?.includes(b.accountId))
      .reduce(
        (t, i) =>
          t.plus(
            i.balances.reduce((balanceTotal, assetTypeBalance) => {
              let { usdValue } = assetTypeBalance;
              return balanceTotal.plus(usdValue);
            }, Big(0)),
          ),
        Big(0),
      )
      .value();
    return summed.toFixed();
  });

export default balancesReducer;
