import { call, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import api, * as urls from '../api';
import unset from 'lodash/unset';
import get from 'lodash/get';
import replaceUrlParams from '../utils/replaceUrlParams';
import HTTP_METHODS from '../constants/httpMethods';

/** DEPRECATED - USE generateSaga */
export const createSaga = (
  routine,
  type,
  loadingMessage = '',
  successMessage = '',
  loadingOptions = {},
  successOptions = {},
  payloadOptions = {
    method: HTTP_METHODS.POST,
  },
) =>
  function* generator({ payload }) {
    let closeToast;
    if (loadingMessage) {
      closeToast = cogoToast.loading(loadingMessage, { hideAfter: 0 });
    }
    try {
      yield put(routine.request());
      let url = replaceUrlParams(
        urls[`${type}_API_ENDPOINT`],
        get(payload, 'urlParams', {}),
      );
      // remove url params from payload
      unset(payload, 'urlParams');
      let result = yield call(api[payloadOptions.method], url, payload);
      if (typeof payloadOptions.transformResponse === 'function') {
        result = payloadOptions.transformResponse(payload, result);
      }
      loadingMessage && closeToast();
      successMessage && cogoToast.success(successMessage);
      yield put(routine.success(result));
    } catch (e) {
      loadingMessage && closeToast();
      yield put(routine.failure(e.response));
    }
  };
