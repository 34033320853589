import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { connect } from 'react-redux';
import { hideModal, showModal, format } from 'erisxkit/client';
import BigNumber, { ROUND_FLOOR, ROUND_CEIL, ROUND_DOWN } from 'bignumber.js';
import {
  DEFAULT_PHOTO_ERROR,
  TRADE_CONFIRM,
  ORDER_ENTRY,
} from '../../constants/modalTypes';
import {
  newOrderSinglePromise,
  topOfBook,
  getOrderPromise,
  getAllTopOfBook,
  getAllSecurities,
  topOfBookPromise,
  removeTFromTestCoins,
  getQuoteCurrencies,
} from '../../reducers/orderEntryReducer';
import {
  extractBaseCurrency,
  extractQuoteCurrency,
  getSecuritiesListAsOptionsWidget,
  getSpotBaseCurrenciesAsOptions,
  getSpotQuoteCurrenciesAsOptions,
} from '../../reducers/assetTypesReducer';
import OrderEntryWidget from '../../components/OrderEntry/OrderEntryWidget';
import {
  getUserFeesDesignation,
  getLoggedInUser,
  getSelectedPartyId,
} from '../../reducers/userReducer';
import { DEFAULT_FEE_KEY } from '../../constants/fees';
import {
  DEFAULT_PRECISION,
  PRECISION_USD,
  DEFAULT_PRICE_PRECISION,
} from '../../constants/precision';
import ModalOrderEntryWidget from '../../components/OrderEntry/ModalOrderEntryWidget';
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_QUOTE_CURRENCY,
} from '../../constants/currencies';
import { getActiveBalances } from '../../reducers/balancesReducer';

export const BUY = 'BUY';
export const SELL = 'SELL';
export const BUFFER = 0.005;

export const getMessage = ({ side, orderQty, currency, quoteCurrency }) => {
  switch (side) {
    case BUY:
      return `An order will be placed to buy ${orderQty} ${currency} with ${quoteCurrency} based on the best available price.  Please be advised that you may not cancel a trade once confirmed as executed.`;
    case SELL:
      return `An order will be placed to sell ${orderQty} ${currency} in exchange for ${quoteCurrency} based on the best available price. Please be advised that you may not cancel a trade once confirmed as executed.`;
    default:
      return '';
  }
};

export const transactionTypes = {
  BUY: {
    value: BUY,
    text: 'Buy',
    index: 0,
  },
  SELL: {
    value: SELL,
    text: 'Sell',
    index: 1,
  },
};

const activeIndexMap = {
  0: BUY,
  1: SELL,
};

const isBuyOrder = (activeIndex) => activeIndex === transactionTypes.BUY.index;
const isSellOrder = (activeIndex) =>
  activeIndex === transactionTypes.SELL.index;

export const getFeeInBasisPoints = ({ feesDesignation = {}, symbol = '' }) =>
  // the defaultValue is returned if value is NaN, null, or undefined.
  _.defaultTo(feesDesignation[symbol], feesDesignation[DEFAULT_FEE_KEY] || 0);

// gets precision from round lot
// (e.g. 0.01 returns 2, 0.0001 returns 4)
export const getPrecisionFromDecimal = (decimal) => {
  // default to a reasonable fallback
  if (!_.isNumber(decimal)) {
    return DEFAULT_PRECISION;
  }
  // wrap in try/catch in case any issues come up
  try {
    // helps us handle long decimals accordingly
    const bn = new BigNumber(decimal);

    // get precision from number of decimal places
    // if there is no mantissa, then we assume precision is 0 (i.e. ones)
    // todo: support lower precisions (e.g. 10, 100)
    const precision = bn.decimalPlaces();

    return _.defaultTo(precision, DEFAULT_PRECISION);
  } catch {
    return DEFAULT_PRECISION;
  }
};

export const filterQuoteCurrenciesByBaseCurrency = ({
  baseCurrency: currency,
  securities = [],
} = {}) => {
  return securities
    .filter(({ baseCurrency }) => baseCurrency === currency)
    .map((s) => {
      const quoteCurrency = extractQuoteCurrency(s.value);
      return {
        key: quoteCurrency,
        value: quoteCurrency,
        text: quoteCurrency,
      };
    });
};

export const filterBaseCurrenciesByQuoteCurrency = ({
  quoteCurrency: currency,
  securities = [],
} = {}) => {
  return securities
    .filter(({ quoteCurrency }) => quoteCurrency === currency)
    .map((s) => {
      const baseCurrency = extractBaseCurrency(s.value);
      return {
        key: baseCurrency,
        value: baseCurrency,
        text: baseCurrency,
      };
    });
};

const getClosingPriceOfCurrency = (balances = [], currency = '') => {
  const balance = _.head(
    balances.filter((b) => removeTFromTestCoins(b.assetType) === currency),
  );
  const closing = _.get(balance, 'closingBalance', '0');
  return { balance, closing };
};

const validateOrderAmount = (amount, balances, currency = '', side) => {
  const { balance, closing } = getClosingPriceOfCurrency(balances, currency);

  if (!balance && side === BUY) {
    return {
      amountError: true,
      errorText: `No balance available for ${currency}`,
    };
  }

  // Is ordering an amount greater than available closing balance for currency
  if (!balance || BigNumber(amount).gt(BigNumber(closing))) {
    return {
      amountError: true,
      errorText: `The amount entered is greater than your balance of ${currency}`,
    };
  }

  return {
    amountError: false,
    errorText: '',
  };
};

export const validateBuyAmount = (
  newAmount,
  currentBalances = [],
  quoteCurrency = 'USD',
) => {
  // Balances are not available
  if (!currentBalances || currentBalances.length === 0)
    return {
      amountError: true,
      errorText: `Account balances unavailable`,
    };
  // If user is BUYING - we validate against quoted currency
  return validateOrderAmount(newAmount, currentBalances, quoteCurrency, BUY);
};

export const validateSellAmount = (
  newAmount,
  minTradeVol,
  baseCurrency,
  currentBalances,
) => {
  // First we validate the newAmount against minTradeVol:
  const bn = new BigNumber(newAmount);

  if (!bn.isZero() && bn.lt(BigNumber(minTradeVol))) {
    // If user is trying to buy an amount lower than the minTradeVol of the selected asset
    return {
      amountError: true,
      errorText: `Amount must be greater or equal to ${minTradeVol}`,
    };
  }

  // If it passed minTradeVol validations, we
  // validate against balance of the base asset
  return validateOrderAmount(newAmount, currentBalances, baseCurrency, SELL);
};

export const isOrderAmountPrecisionValid = (decimalPlaces, precision) =>
  decimalPlaces === 0 ||
  (decimalPlaces && BigNumber(decimalPlaces).lte(BigNumber(precision)));

const mapStateToProps = (state = {}) => ({
  user: getLoggedInUser(state),
  securityList: getAllSecurities(state),
  // these are all of the securities that can be bought or sold
  securityListOptions: getSecuritiesListAsOptionsWidget(state),
  baseCurrencyOptions: getSpotBaseCurrenciesAsOptions(state),
  quoteCurrencyOptions: getSpotQuoteCurrenciesAsOptions(state),
  topOfBookAll: getAllTopOfBook(state),
  feesDesignation: getUserFeesDesignation(state),
  quoteCurrencies: getQuoteCurrencies(state),
  partyId: getSelectedPartyId(state),
  currentBalances: getActiveBalances(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      topOfBook,
      showModal,
      hideModal,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      newOrderSinglePromise,
      getOrderPromise,
      topOfBookPromise,
    },
    dispatch,
  ),
});

const getInitialState = ({
  securityListOptions,
  quoteCurrencyOptions,
  activeIndex,
}) => ({
  activeIndex: activeIndex || 0,
  marketUnavailable: false,
  precision: DEFAULT_PRECISION,
  pricePrecision: DEFAULT_PRICE_PRECISION,
  quoteCurrency: DEFAULT_QUOTE_CURRENCY,
  symbol: DEFAULT_BASE_CURRENCY,
  // by default only show the securities
  // supported by the default base
  baseCurrencyOptions: filterBaseCurrenciesByQuoteCurrency({
    quoteCurrency: DEFAULT_QUOTE_CURRENCY,
    securities: securityListOptions,
  }),
  quoteCurrencyOptions,
  buyButtonClicked: false,
  amount: null,
  baseCurrency: DEFAULT_BASE_CURRENCY,
  assetType: null,
  amountError: false,
  errorText: '',
});

export class OrderEntryWidgetContainer extends Component {
  state = (() => {
    // use full list of securities from redux
    // as a starting point
    const { securityListOptions, quoteCurrencyOptions, activeIndex } =
      this.props;

    return getInitialState({
      securityListOptions,
      quoteCurrencyOptions,
      activeIndex,
    });
  })();

  componentDidMount = () => {
    let { assetType = '', activeIndex } = this.props;
    if (assetType) {
      // TODO: remove this later
      assetType = removeTFromTestCoins(assetType);

      this.handleChangeBaseCurrency(null, { value: assetType });
    }
  };

  getLimitPrice = () => {
    const { topOfBookAll } = this.props;
    const { pricePrecision, symbol, activeIndex } = this.state;
    if (activeIndexMap[activeIndex] === BUY) {
      return BigNumber(_.get(topOfBookAll, [symbol, 'offers', '0', 'price'], 0))
        .times(1 + BUFFER)
        .toFixed(pricePrecision, ROUND_CEIL);
    }

    if (activeIndexMap[activeIndex] === SELL) {
      return BigNumber(_.get(topOfBookAll, [symbol, 'bids', '0', 'price'], 0))
        .times(1 - BUFFER)
        .toFixed(pricePrecision, ROUND_FLOOR);
    }
  };

  resetForm = () => {
    this.setState(getInitialState(this.props));
  };

  // currently order fees only support usd(c)
  getOrderFees = () => {
    const { amount, activeIndex, symbol } = this.state;
    const feeInBasisPoints = getFeeInBasisPoints({
      feesDesignation: this.props.feesDesignation,
      symbol,
    });

    // when buying fees are proportional to the
    // amount in usd(c) that you are paying
    if (activeIndexMap[activeIndex] === BUY) {
      return new BigNumber(amount)
        .times(feeInBasisPoints)
        .toFixed(PRECISION_USD, ROUND_DOWN);
    }

    // when selling, fees are proportional to the
    // usd(c) value of the asset you're selling
    if (activeIndexMap[activeIndex] === SELL) {
      const limitPrice = this.getLimitPrice(symbol);
      return new BigNumber(amount)
        .times(limitPrice)
        .times(feeInBasisPoints)
        .toFixed(PRECISION_USD, ROUND_DOWN);
    }
  };

  getBestPricesForCurrentSymbol = () => {
    const { topOfBookAll } = this.props;
    const { symbol } = this.state;
    return {
      bestBid: _.get(topOfBookAll, [symbol, 'bids', '0', 'price'], ''),
      bestOffer: _.get(topOfBookAll, [symbol, 'offers', '0', 'price'], ''),
    };
  };
  getOrderQty = () => {
    const { amount, activeIndex, symbol, precision } = this.state;
    if (activeIndexMap[activeIndex] === BUY) {
      const feeInBasisPoints = getFeeInBasisPoints({
        feesDesignation: this.props.feesDesignation,
        symbol,
      });
      const { bestOffer } = this.getBestPricesForCurrentSymbol();

      // 1 - fees = effective percentage of original amount to be used for purchasing
      const effectivePercentage = new BigNumber(1).minus(feeInBasisPoints);
      return new BigNumber(amount)
        .times(effectivePercentage)
        .dividedBy(bestOffer)
        .toFixed(precision, ROUND_DOWN);
    }

    if (activeIndexMap[activeIndex] === SELL) {
      return amount;
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.validateAmount(value);
  };

  handleChangeQuoteCurrency = (e, { value: quoteCurrency }) => {
    const { securityListOptions, securityList } = this.props;
    const assetType = `${this.state.baseCurrency}/${quoteCurrency}`;

    const security = _.find(securityList, { symbol: assetType });
    const currency = _.get(security, 'currency');
    const roundLot = _.get(security, 'roundLot');
    const minTradeVol = _.get(security, 'minTradeVol');
    const minPriceIncrement = _.get(security, 'minPriceIncrement');
    const precision = getPrecisionFromDecimal(roundLot);
    const pricePrecision = getPrecisionFromDecimal(minPriceIncrement);
    // filter securities that can be bought with this quote currency
    const baseCurrencyOptions = filterBaseCurrenciesByQuoteCurrency({
      securities: securityListOptions,
      quoteCurrency,
    });

    this.props.topOfBookPromise({ symbol: assetType }).then(
      (s) => {
        this.setState(
          {
            baseCurrencyOptions,
            quoteCurrency,
            marketUnavailable: !this.isMarketAvailable(assetType),
            assetType,
            symbol: assetType,
            currency,
            precision,
            pricePrecision,
            minTradeVol,
            amountError: false,
            errorText: '',
          },
          () => this.validateAmount(this.state.amount),
        );
      },
      (error) => {
        this.setState({
          marketUnavailable: true,
        });
      },
    );
  };

  isBuyDisabled = () =>
    !this.state.symbol ||
    !this.state.amount ||
    this.state.marketUnavailable ||
    !this.props.partyId ||
    this.state.amountError;

  // indicates a change from buy to sell or vv
  tabChanged = (e, { activeIndex }) => {
    const { securityListOptions } = this.props;
    if (activeIndexMap[activeIndex] === SELL) {
      return this.setState({
        activeIndex,
        // reset to default base currency and all the security list options
        amount: '',
        baseCurrency: DEFAULT_BASE_CURRENCY,
        quoteCurrency: DEFAULT_QUOTE_CURRENCY,
        securityListOptions: securityListOptions,
        baseCurrencyOptions: filterBaseCurrenciesByQuoteCurrency({
          quoteCurrency: DEFAULT_QUOTE_CURRENCY,
          securities: securityListOptions,
        }),
        amountError: false,
        errorText: '',
      });
    }
    if (activeIndexMap[activeIndex] === BUY) {
      return this.setState({
        activeIndex,
        // reset to default base currency and its corresponding security list options
        amount: '',
        baseCurrency: DEFAULT_BASE_CURRENCY,
        quoteCurrency: DEFAULT_QUOTE_CURRENCY,
        baseCurrencyOptions: filterBaseCurrenciesByQuoteCurrency({
          quoteCurrency: DEFAULT_QUOTE_CURRENCY,
          securities: securityListOptions,
        }),
        amountError: false,
        errorText: '',
      });
    }
  };

  handleChangeBaseCurrency = (e, { value }) => {
    const { securityList, securityListOptions } = this.props;
    const assetType = `${value}/${this.state.quoteCurrency}`;

    const security = _.find(securityList, { symbol: assetType });
    const currency = _.get(security, 'currency');
    const roundLot = _.get(security, 'roundLot');
    const minTradeVol = _.get(security, 'minTradeVol');
    const minPriceIncrement = _.get(security, 'minPriceIncrement');
    const precision = getPrecisionFromDecimal(roundLot);
    const pricePrecision = getPrecisionFromDecimal(minPriceIncrement);

    // filter quote currencies that can be used for this base currency
    const quoteCurrencyOptions = filterQuoteCurrenciesByBaseCurrency({
      securities: securityListOptions,
      baseCurrency: value,
    });
    this.props.topOfBookPromise({ symbol: assetType }).then(
      (s) => {
        this.setState(
          {
            baseCurrency: value,
            assetType,
            symbol: assetType,
            currency,
            precision,
            pricePrecision,
            minTradeVol,
            quoteCurrency: this.state.quoteCurrency,
            marketUnavailable: !this.isMarketAvailable(assetType),
            quoteCurrencyOptions,
            amountError: false,
            errorText: '',
          },
          () => this.validateAmount(this.state.amount),
        );
      },
      (error) => {
        this.setState({
          marketUnavailable: true,
        });
      },
    );
  };

  // currently price is in usd(c) so this formatting using fiat is acceptable
  getTotalPrice = () => {
    const { amount, quoteCurrency } = this.state;
    const { bestBid } = this.getBestPricesForCurrentSymbol();
    return format(new BigNumber(amount).times(bestBid), {
      type: 'fiat',
      currency: quoteCurrency,
      truncate: PRECISION_USD,
    });
  };

  isMarketAvailable = (symbol) => {
    const { topOfBookAll } = this.props;
    const bestOffer = _.get(topOfBookAll, [symbol, 'offers', '0', 'price'], '');
    const bestBid = _.get(topOfBookAll, [symbol, 'bids', '0', 'price'], '');

    if (!bestOffer || !bestBid) {
      return false;
    }

    return true;
  };

  submitClicked = () => {
    const { showModal, hideModal } = this.props;
    const { activeIndex, assetType, symbol, amount, quoteCurrency, precision } =
      this.state;
    const side = activeIndexMap[activeIndex];
    hideModal(ORDER_ENTRY);
    if (this.isMarketAvailable(symbol)) {
      showModal(TRADE_CONFIRM, {
        size: 'mini',
        assetType,
        symbol,
        amount: side === BUY ? amount : this.getTotalPrice(),
        currency: this.state.currency,
        orderQty: this.getOrderQty(),
        fees: this.getOrderFees(),
        side,
        quoteCurrency,
        precision,
      });
      this.setState({
        symbol: '',
        amount: '',
        quoteCurrency: DEFAULT_QUOTE_CURRENCY,
        baseCurrency: DEFAULT_BASE_CURRENCY,
        assetType: '',
        buyButtonClicked: true,
      });
    } else {
      this.setState({
        marketUnavailable: true,
      });
    }
  };

  validateAmount = (newAmount) => {
    const { currentBalances } = this.props;
    const {
      quoteCurrency,
      amountError: currentErrorState,
      activeIndex,
      precision,
      amount,
      minTradeVol,
      baseCurrency,
      assetType,
    } = this.state;

    let amountError, errorText;
    // Short circuit if user did not provide an amount (e.g. first time an asset is selected)
    if (!newAmount) {
      this.setState({ amountError: false, errorText: '' });
      return;
    }
    if (isBuyOrder(activeIndex)) {
      // BUY ORDERS
      ({ amountError, errorText } = validateBuyAmount(
        newAmount,
        currentBalances,
        quoteCurrency,
      ));
    } else {
      // SELL ORDERS
      // First we validate that the amount precision (decimals) is valid
      const bn = new BigNumber(newAmount);
      const decimalPlaces = bn.decimalPlaces();
      const isPrecisionValid = isOrderAmountPrecisionValid(
        decimalPlaces,
        precision,
      );
      if (!isPrecisionValid) {
        // If the amount of decimals is greater than the roundLot, we don't show an error
        // We just reset the amount to its previous value (amount).
        this.setState({ amount });
        return;
      }
      ({ amountError, errorText } = validateSellAmount(
        newAmount,
        minTradeVol,
        baseCurrency,
        currentBalances,
      ));
    }
    // Update error state and text
    this.setState({ amountError, errorText });
  };

  render = () => {
    const props = {
      ...this.props,
      activeIndex: this.state.activeIndex,
      marketUnavailable: this.state.marketUnavailable,
      assetType: this.state.assetType,
      amount: this.state.amount,
      symbol: this.state.symbol,
      currency: this.state.currency,
      buyButtonClicked: this.state.buyButtonClicked,
      securityListOptions: this.state.securityListOptions,
      quoteCurrency: this.state.quoteCurrency,
      amountError: this.state.amountError,
      errorText: this.state.errorText,
      getOrderQty: this.getOrderQty,
      isBuyDisabled: this.isBuyDisabled,
      getLimitPrice: this.getLimitPrice,
      handleChange: this.handleChange,
      submitClicked: this.submitClicked,
      tabChanged: this.tabChanged,
      handleChangeQuoteCurrency: this.handleChangeQuoteCurrency,
      quoteCurrencies: this.props.quoteCurrencies,
      quoteCurrencyOptions: this.state.quoteCurrencyOptions,
      baseCurrencyOptions: this.state.baseCurrencyOptions,
      handleChangeBaseCurrency: this.handleChangeBaseCurrency,
      baseCurrency: this.state.baseCurrency,
      resetForm: this.resetForm,
    };
    return this.props.showAsModal ? (
      <ModalOrderEntryWidget {...props} />
    ) : (
      <ModalOrderEntryWidget {...props} className="sidebar-widget" />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderEntryWidgetContainer);
