import React from 'react';
import get from 'lodash/get';
import { isEditableColumn, isValidDepth } from './utils/utils';
import TableInputTypes from '../../ts/enums/TableInputTypes.enum';
import ExpandCell from './ExpandCell';
import { TableInput } from './styled';
import StyledSelect, { StyledSelectOption } from '../StyledSelect';
import { DefaultCellProps } from './types';
import StyledCheckbox from '../StyledCheckbox';

const EditableCell = <T,>({
  columnDef,
  depth,
  name,
  isExpandable,
  row,
  getValue,
  value,
  hasBeenEdited,
  updateTableData,
  onChange,
  inputProps,
  setValue,
  options,
  selectedOption,
}: DefaultCellProps<T>) => {
  const editOptions = get(columnDef, 'meta.editOptions', null);
  // A row is being edited if its index is the same as the one stored in rowInEditMode
  // or if its a subrow of the rowInEdit mode. e.g.: if row 0 is being edited
  // rows with id 0.X should also be edited
  const lowerBound: number | null = get(editOptions, 'depthLowerBound', null);
  const upperBound: number | null = get(editOptions, 'depthUpperBound', null);
  const validDepth: boolean = isValidDepth(depth, lowerBound, upperBound);

  const isEditable: boolean = isEditableColumn(columnDef);
  // If the cell is non-editable, default to its getValue or null if there's nothing to show
  if (!isEditable || !validDepth) return getValue() || null;

  if (editOptions && validDepth) {
    switch (editOptions.component) {
      case TableInputTypes.Input:
        return (
          <>
            {isExpandable && (
              <ExpandCell
                key={`${name}_expand`}
                row={row}
                getValue={getValue}
                depth={get(columnDef, 'meta.expandOptions.depth', 0)}
                hidden
              />
            )}
            <TableInput
              key={`${name}_input`}
              name={name}
              data-cy={`${name}_input`}
              value={value as string}
              autoComplete="off"
              onChange={onChange}
              onBlur={hasBeenEdited ? updateTableData : () => {}}
              changed={hasBeenEdited}
              {...inputProps}
            />
          </>
        );

      case TableInputTypes.Select:
        return (
          <>
            {isExpandable && (
              <ExpandCell
                key={`${name}_expand`}
                row={row}
                getValue={getValue}
                depth={get(columnDef, 'meta.expandOptions.depth', 0)}
                hidden
              />
            )}
            <StyledSelect
              key={`${name}_select`}
              dataCy={`${name}_select`}
              placeholder={get(editOptions, 'placeholder', '')}
              options={options as StyledSelectOption[]}
              value={selectedOption}
              onChange={(option) => {
                setValue(option.value);
              }}
              active={hasBeenEdited}
            />
          </>
        );
      case TableInputTypes.Checkbox:
        const isChecked = get(columnDef, 'meta.isChecked', null);
        return (
          <StyledCheckbox
            checked={isChecked ? isChecked(row) : false}
            onChange={(e) => {
              const checked = get(e, 'target.checked', false);
              setValue(checked);
            }}
          />
        );
      default:
        return getValue() || null;
        break;
    }
  }

  return getValue() || null;
};

export default EditableCell;
