import _ from 'lodash';
import React, { Fragment } from 'react';
import {
  Modal,
  Form,
  Segment,
  Icon,
  Header,
  Button,
  Divider,
} from 'semantic-ui-react';
import { Field, reduxForm, FormSection } from 'redux-form';
import InputWithCopy from '../../common/components/InputWithCopy';
import { renderCheckbox, renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import permissions from '../../constants/apiKeyPermissions';
import styled from 'styled-components';

const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.text.header + '!important'};
`;

const StyledField = styled(Field)`
  .ui.checkbox label {
    color: ${({ theme }) => theme.text.header + '!important'};
  }
`;

const customOnChange =
  (change, permissions) => (e, newValue, prevValue, name) => {
    // write custom logic to select submit_order when view_market_data is selected
    if (name === 'permissions.submit_order') {
      // Add view_market_data permission when adding submit_order permission
      // only if user also has view_market_data enabled
      const marketDataEnabled = permissions.some(
        (p) => p.key === 'view_market_data' && p.show,
      );
      if (marketDataEnabled) change('permissions.view_market_data', true);
    }

    // deselect submit_order when view_market_data is unselected
    if (name === 'permissions.view_market_data' && !newValue) {
      change('permissions.submit_order', false);
    }
  };

const createPermissions = (enabledPermissions, credentials = '', change) =>
  enabledPermissions.map((permission) => (
    <Fragment>
      {permission.show && (
        <Fragment>
          {permission.showCheckbox ? (
            <StyledField
              className={permission.className}
              name={permission.key}
              component={renderCheckbox}
              label={permission.label}
              readOnly={credentials}
              onChange={customOnChange(change, enabledPermissions)}
            />
          ) : (
            <StyledHeader as="h4">{permission.label}</StyledHeader>
          )}

          {permission.children &&
            createPermissions(permission.children, credentials, change)}
        </Fragment>
      )}
    </Fragment>
  ));

const validatePermissions = (values = {}) => {
  if (values.permissions) {
    return _.some(Object.values(values.permissions));
  }
  return false;
};

const CreateApiKeyModal = ({
  apiCredentialsPermissions,
  apiCredentialsPermissionsLoading,
  invalid,
  handleSubmit,
  generateKey,
  credentials,
  loading,
  header,
  change,
  enabledClearingApiCredentials,
  formValues,
}) => (
  <Modal.Content>
    <div className="vert-flex">
      <Icon name="green key" size="large" />
      <Header>{header}</Header>
    </div>
    <Form loading={loading}>
      <Field
        component={renderField}
        id="label"
        placeholder="Label"
        type="text"
        name="label"
        validate={[rules.required]}
        readOnly={credentials}
      />
      <Header content="Permissions" />
      <Segment basic loading={apiCredentialsPermissionsLoading}>
        <FormSection name="permissions">
          {createPermissions(
            permissions(
              apiCredentialsPermissions,
              enabledClearingApiCredentials,
            ),
            credentials,
            change,
          )}
        </FormSection>
      </Segment>
      <Divider hidden />
      {!credentials && (
        <Button
          onClick={generateKey}
          id="create-api-credential"
          primary
          fluid
          disabled={invalid || !validatePermissions(formValues)}
          loading={loading}
        >
          Generate Key
        </Button>
      )}
    </Form>
    <Divider />
    {credentials && (
      <Fragment>
        <Form>
          <InputWithCopy
            label="API Key"
            id="api-key"
            key="api-key"
            className="mono outline"
            value={credentials.apiKey}
          />
          <InputWithCopy
            label="Secret"
            id="secret"
            key="secret"
            className="mono outline"
            value={credentials.secret}
          />
        </Form>
        <Segment>
          <Icon name="yellow warning circle" />
          Please save the above KEY and SECRET in a secure location. Once you
          close this window, they will be hidden and unrecoverable.
        </Segment>
      </Fragment>
    )}
  </Modal.Content>
);

export default reduxForm({
  form: 'create-api-key',
})(CreateApiKeyModal);
