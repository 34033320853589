import { call, takeLatest, put } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import * as actions from '../reducers/kbaReducer';
import { fetchUser } from '../reducers/userReducer';
import api, * as urls from '../api';
import {
  fetchComplianceQuestions,
  submitComplianceQuestions,
  fetchSecurityQuestions,
  submitSecurityQuestions,
  fetchFuturesComplianceQuestions,
  submitFuturesComplianceQuestions,
} from '../reducers/complianceQuestionsReducer';

function* kbaQuestion(arg) {
  try {
    const question = yield call(
      api.post,
      urls.KBA_QUESTION_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: actions.KBA_QUESTION_SUCCESS, payload: question });
    yield put(fetchUser.success({ appState: question.appState }));
  } catch (e) {
    yield put({ type: actions.KBA_QUESTION_FAILED, payload: e.response });
  }
}

function* complianceQuestions() {
  try {
    yield put(fetchComplianceQuestions.request());
    const questions = yield call(
      api.post,
      urls.COMPLIANCE_QUESTIONS_API_ENDPOINT,
    );
    yield put(fetchComplianceQuestions.success(questions));
  } catch (e) {
    yield put(fetchComplianceQuestions.failure(e.response));
  }
}

function* submitComplianceQuestionsSaga(arg) {
  try {
    yield put(submitComplianceQuestions.request());
    const questions = yield call(
      api.post,
      urls.SUBMIT_COMPLIANCE_QUESTIONS_API_ENDPOINT,
      arg.payload,
    );
    yield put(submitComplianceQuestions.success(questions));
  } catch (e) {
    yield put(submitComplianceQuestions.failure(e.response));
  }
}

function* securityQuestions() {
  try {
    yield put(fetchSecurityQuestions.request());
    const questions = yield call(
      api.post,
      urls.SECURITY_QUESTIONS_API_ENDPOINT,
    );
    yield put(fetchSecurityQuestions.success(questions));
  } catch (e) {
    yield put(fetchSecurityQuestions.failure(e.response));
  }
}

function* submitSecurityQuestionsSaga(arg) {
  try {
    yield put(submitSecurityQuestions.request());
    const questions = yield call(
      api.post,
      urls.SUBMIT_SECURITY_QUESTIONS_API_ENDPOINT,
      arg.payload,
    );
    yield put(submitSecurityQuestions.success(questions));
  } catch (e) {
    yield put(submitSecurityQuestions.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(actions.KBA_QUESTION_REQUEST, kbaQuestion);
  yield takeLatest(fetchComplianceQuestions.TRIGGER, complianceQuestions);
  yield takeLatest(
    submitComplianceQuestions.TRIGGER,
    submitComplianceQuestionsSaga,
  );
  yield takeLatest(fetchSecurityQuestions.TRIGGER, securityQuestions);
  yield takeLatest(
    submitSecurityQuestions.TRIGGER,
    submitSecurityQuestionsSaga,
  );
  yield takeLatest(
    fetchFuturesComplianceQuestions.TRIGGER,
    createSaga(
      fetchFuturesComplianceQuestions,
      fetchFuturesComplianceQuestions._PREFIX,
    ),
  );
  yield takeLatest(
    submitFuturesComplianceQuestions.TRIGGER,
    createSaga(
      submitFuturesComplianceQuestions,
      submitFuturesComplianceQuestions._PREFIX,
    ),
  );
}
