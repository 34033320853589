import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {
  DEFAULT_PHONE_FORMAT,
  DEFAULT_PHONE_MASK,
} from '../../constants/formats';

function PhoneInputComponent(props) {
  // other is required to pass the input down
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={DEFAULT_PHONE_FORMAT}
      mask={DEFAULT_PHONE_MASK}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      isNumericString
    />
  );
}

PhoneInputComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhoneInputComponent;
