import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useReactTable,
  ExpandedState,
  getCoreRowModel,
  getFilteredRowModel,
  ColumnSizingTableState,
  ColumnFiltersState,
  PaginationState,
  getExpandedRowModel,
  getPaginationRowModel,
  SortingState,
  getSortedRowModel,
  flexRender,
  HeaderGroup,
  RowData,
  Row,
  ColumnDef,
  InitialTableState,
} from '@tanstack/react-table';
import {
  throttle,
  set,
  isEmpty,
  get,
  isEqual,
  cloneDeep,
  snakeCase,
  min,
} from 'lodash';
import { Loader } from 'semantic-ui-react';
import {
  Resizer,
  Table,
  FilterableTh,
  EditModeButtons,
  SaveChanges,
  EditDisclaimer,
  Dismiss,
  Cell,
  LoadingWrapper,
  NoDataWrapper,
  QuickTools,
  ToolsWrapper,
  TableWrapper,
  Th,
} from './styled';
import Footer from './Footer';
import TableFilter from './Filters/TableFilter';
import { fillToMinimumRows, SORT_DIRECTIONS } from './utils/utils';
import TextButton from '../../common/TextButton';
import colors from '../../constants/colors';
import {
  TableEditState,
  SortFilter,
  FetchOptions,
  TableMeta,
  RowActionButton,
  ExpandableColumn,
  RowId,
  ColumnMetadata,
  TableActionsOptions,
} from './types';
import TextButtons from '../TextButtons';
import {
  removeRowFromData,
  addRowOnTableData,
  editRowOnTableData,
  removeNewlyCreatedRowId,
} from './utils/tableDataHandling';
import TableCellAlignment from '../../ts/enums/TableCellAlignment.enum';
import TableCellTextTransform from '../../ts/enums/TableCellTextTransform.enum';
import { columnsWithWidths } from './utils/localStorage';
import { getDefaultColumn } from './defaultColumn';
import { TableActionName } from './TableActions/types';
import AppliedFilters from './Filters/AppliedFilters';
import TableFilterType from '../../ts/enums/TableFilterType.enum';
import { typeSafeFilterFn } from './Filters/FilterFns';

export type TableProps<T> = {
  /**  Table data rows. Be sure to type accordingly. */
  data: T[];
  /** Metadata for each column*/
  columns: ColumnDef<T>[];
  /** Table name - used to persist preferences */
  title: string;
  /** Show / hide pagination footer */
  paginated?: boolean;
  /** Function that the table will use to fetch data */
  fetchData: Function;
  /** Total count of entries. Necessary if using server side pagination */
  count?: number;
  /** Uses local filtering/sorting. If not passed, server side pagination/filtering will be used */
  local?: boolean;
  /** Tells the table the keys of those columns that are expandable */
  expandableColumns?: ExpandableColumn[];
  /** When setting multiple expansion, tell the table how to get subrows.
   * If NOT provided rows will NOT be expandable   */
  getSubRows?: (row: T, index: number) => T[] | undefined;
  /** Callback to invoke when editing changes are applied */
  onSaveChanges?: (state: TableEditState<Partial<T>>) => void;
  /** Optional disclaimer to show when editing table values */
  editDisclaimer?: string;
  /** Table is fetching */
  loading?: boolean;
  /** Loading text*/
  loadingText?: string;
  /** Text to show if there are no records */
  noDataText?: string;
  /** Minimum amount of rows to show */
  minimumRows?: number;
  /** Toggles table quick tools */
  showActions?: boolean;
  /** Toggles individual quick tool actions */
  actionsToHide?: TableActionName[];
  /** Shows/hides currently applied table filters */
  showActiveFilters?: boolean;
  /** Maps a TableActionName with optional parameters*/
  actionOptions?: TableActionsOptions;
  /** Props to pass a row <tr> element*/
  getTrProps?: (row: Row<T>) => object | {};
  /** Callback triggered when adding a Delete button */
  additionalActions?: (row: Row<T>) => RowActionButton[];
  /** Initial state of table */
  initialState?: InitialTableState;
  /** Cypress Identifier */
  dataCy?: string;
  /** Show/hide Edit button in cell */
  disableEdit?: boolean;
  /** Disabled Edit button in cell based on a functionality*/
  isRowEditable?: (row: Row<T>) => boolean;
};

const DEFAULT_PAGE_SIZE = 10;
const MIN_ROWS_DISPLAY_LOADING = 5;
export const MINIMUM_ROWS = 5;

export function EMPTable8<T>({
  data = [],
  columns,
  title,
  paginated = false,
  fetchData,
  count,
  local = false,
  getSubRows,
  onSaveChanges,
  expandableColumns,
  editDisclaimer,
  loading = false,
  loadingText = 'Loading',
  noDataText = 'No records to show',
  minimumRows = MINIMUM_ROWS,
  showActions = false,
  actionsToHide = [],
  showActiveFilters = false,
  actionOptions = {},
  getTrProps = (row: Row<T>) => {
    return {};
  },
  additionalActions = () => [],
  initialState = undefined,
  dataCy,
  disableEdit = false,
  isRowEditable = () => false,
}: TableProps<T>) {
  // Table internal state
  // Local reference to data prop used when adding/removing rows
  const [localData, setLocalData] = useState<T[]>(data);
  const [rowInEditMode, setRowInEditMode] = useState<number | undefined>();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [rowChanges, setRowChanges] = useState<TableEditState<Partial<T>>>({
    original: {}, // Copy of the row on its original state
    updated: {}, // Object with the row with changes applied
  });
  const [createdRowIds, setCreatedRowIds] = useState<RowId[]>([]);
  // Maps the id of a cell with the value of its input when editing
  const [rowInputValues, setRowInputValues] = useState({});

  const [expanded, setExpanded] = useState<ExpandedState>();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE, // Only default page size currently supported
  });

  // Applied column filters
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    initialState?.columnFilters ?? [],
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [sorting, setSorting] = React.useState<SortingState>(
    initialState?.sorting ?? [],
  );

  const fetchDataOptions: FetchOptions = {
    // Conditionally add pagination parameters
    ...(paginated && { page: pageIndex, limit: pageSize, offset: 0 }),
    filter: local
      ? []
      : columnFilters.reduce(
          (acc: ColumnFiltersState, curr: ColumnFiltersState) => [
            ...acc,
            ...curr.value.map((v) => ({ ...v, attr: snakeCase(v.attr) })),
          ],
          [],
        ),
    sort: sorting.map((s) => ({
      attr: snakeCase(s.id),
      value: s.desc ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC,
    })) as SortFilter[],
  };

  // Resize handler. Throttled to limit amount of calls.
  // High delay value is possible as the table update its internal state regardless of this
  const onResizeColumn = useMemo(
    () =>
      throttle((columns: ColumnSizingTableState) => {
        // It's called with an empty columns objects if there were no changes. Prevent LS update on this scenario.
        if (!isEmpty(columns)) {
          const tables = JSON.parse(localStorage.getItem('XTable') || '{}');
          const erisx_user = localStorage.getItem('erisx_user');
          const prevSettings = get(tables, [erisx_user, title], {});
          const newSettings = { ...prevSettings, ...columns };
          set(tables, [erisx_user, title], newSettings);
          localStorage.setItem('XTable', JSON.stringify(tables));
        }
      }, 1000),
    [title],
  );

  const hasEditableFields = useMemo(
    () => columns.some((c) => get(c, 'meta.editOptions.canEdit', false)),
    [columns],
  );

  const onEditRow = ({
    index,
    depth,
    original,
    toggleExpanded,
    getIsExpanded,
  }: {
    index: number;
    depth: number;
    original: object;
    toggleExpanded: Function;
    getIsExpanded: Function;
  }) => {
    // Stores row index of row that's actively being edited
    setRowInEditMode(index);
    // Table-level edit mode which hides/shows edit buttons.
    setIsInEditMode(true);
    // Stores the key of the row being updated at the highest level of the rowchanges object
    setRowChanges({ original, updated: original }); // TODO: Check which key to use to know which line item is updated
    // Clean inputs
    setRowInputValues({});
    // If row is collapsed, expand to the first level of subrows
    // A depth of 0 defines a non-subrow row
    const isExpanded = getIsExpanded();
    if (!isExpanded && depth === 0) toggleExpanded();
  };

  const resetRowChanges = () => setRowChanges({ original: {}, updated: {} });

  const resetEditState = () => {
    setRowInEditMode(undefined);
    setIsInEditMode(false);
    setLocalData(data);
    resetRowChanges();
    setRowInputValues({});
    setCreatedRowIds([]);
    setExpanded({});
  };

  const isRowInEditMode = (rowId: number) => rowInEditMode === rowId;
  const isNewlyCreatedRow = (rowId: string) => createdRowIds.includes(rowId);

  // Controls default rendering of all table cells
  const defaultColumn = useMemo(
    () =>
      getDefaultColumn({
        loading,
        rowInEditMode,
        expandableColumns,
        rowChanges,
        rowInputValues,
        isNewlyCreatedRow,
      }),
    [
      loading,
      rowInEditMode,
      expandableColumns,
      rowChanges,
      rowInputValues,
      isNewlyCreatedRow,
    ],
  );

  const onSave = () => {
    if (onSaveChanges) {
      onSaveChanges(rowChanges);
    }
    resetEditState();
  };

  const getData = (): T[] => {
    // If table is loading return a dummy, empty data list
    if (loading || isEmpty(data)) {
      const fillAmount =
        minimumRows > MIN_ROWS_DISPLAY_LOADING
          ? MIN_ROWS_DISPLAY_LOADING
          : minimumRows;
      return new Array(fillAmount).fill({}) as T[];
    }
    // If is editing, we return a local copy of the data
    if (isInEditMode) return fillToMinimumRows(localData, minimumRows);
    return fillToMinimumRows(data, minimumRows);
  };

  const columnsFromLocalStorage = columnsWithWidths<T>(columns, title);
  // Create table instance with opts
  const table = useReactTable({
    data: getData(),
    defaultColumn,
    columns: hasEditableFields
      ? ([
          ...columnsFromLocalStorage,
          {
            id: 'Edit',
            header: 'Edit',
            meta: {
              noExport: true,
            },
            cell: ({ row }: { row: Row<T> }) => {
              if (loading || isEmpty(row?.original) || disableEdit) return null;
              // If it is a subrow (depth > 0) hide Edit button
              const isSubrow = row?.depth > 0;
              const isBeingEdited = isRowInEditMode(
                Number(row.id?.split('.')[0]),
              );
              const createColumn = columns.find((c) =>
                get(c, 'meta.createOptions.canCreate', null),
              );

              const buttons: RowActionButton[] = [
                {
                  text: get(createColumn, 'meta.deleteCTA', 'null'),
                  onClick: () => {
                    if (expandableColumns) {
                      const { newData, newRow } = removeRowFromData(
                        localData,
                        row.id,
                        expandableColumns,
                      );
                      setCreatedRowIds(
                        removeNewlyCreatedRowId(createdRowIds, row.id),
                      );
                      setLocalData(newData);
                      setRowChanges({ ...rowChanges, updated: newRow });
                    }
                  },
                  fontSize: '14',
                  danger: true,
                },
              ];
              if (row?.depth < 2)
                buttons.unshift({
                  text: get(createColumn, 'meta.addCTA', 'null'),
                  onClick: () => {
                    if (expandableColumns) {
                      const { newData, newRow, newRowId } = addRowOnTableData(
                        localData,
                        row.id,
                        expandableColumns,
                      );
                      setCreatedRowIds([...createdRowIds, newRowId]);
                      setLocalData(newData);
                      setRowChanges({ ...rowChanges, updated: newRow });
                      if (!row.getIsExpanded()) {
                        row.toggleExpanded();
                      }
                    }
                  },
                  fontSize: '14',
                });

              if (isSubrow && !isBeingEdited) return null;

              if (isSubrow && isBeingEdited && createColumn)
                return (
                  <TextButtons buttons={buttons} key={row.id} id={row.id} />
                );

              const actions: RowActionButton[] = [
                {
                  text: 'Edit',
                  fontSize: '14',
                  onClick: () => onEditRow(row),
                  disabled: isInEditMode || isRowEditable(row),
                },
                ...additionalActions(row),
              ];

              return isBeingEdited && !isSubrow && createColumn ? (
                <TextButton
                  text={get(createColumn, 'meta.createCTA', '')}
                  onClick={() => {
                    if (expandableColumns) {
                      const { newData, newRow, newRowId } = addRowOnTableData(
                        localData,
                        row.id,
                        expandableColumns,
                      );
                      setCreatedRowIds([...createdRowIds, newRowId]);
                      setLocalData(newData);
                      setRowChanges({ ...rowChanges, updated: newRow });
                    }
                  }}
                  fontSize="14"
                />
              ) : (
                <TextButtons buttons={actions} key={row.id} id={row.id} />
              );
            },
          } as ColumnMetadata<T>,
        ] as ColumnDef<T>[])
      : columnsFromLocalStorage,
    columnResizeMode: 'onChange',
    filterFns: {
      defaultFilterFn: (
        row: Row<T>,
        columnId: string,
        filterValue: any,
      ): boolean => {
        const columnDef = columnsFromLocalStorage.find(
          (column) => column.id === columnId,
        );
        const filterType: TableFilterType = get(
          columnDef,
          'meta.filterType',
          null,
        );

        return typeSafeFilterFn(filterType, row, columnId, filterValue);
      },
    },
    state: {
      expanded,
      pagination,
      columnFilters,
      sorting,
    },
    meta: {
      actionOptions,
      updateData: (rowId, cellId, colId, value) => {
        let newUpdatedRow = cloneDeep(rowChanges.updated);

        setRowInputValues({ ...rowInputValues, [cellId]: value });
        if (!isEmpty(expandableColumns)) {
          // If rows can be expanded
          const { newData, newRow } = editRowOnTableData(
            localData,
            rowId,
            colId,
            expandableColumns,
            value,
          );
          newUpdatedRow = newRow;
          setLocalData(newData);
          setRowChanges({
            ...rowChanges,
            updated: newUpdatedRow,
          });
        } else {
          // for single rows
          const newData = cloneDeep(localData);
          // Get the row that's being updated
          const updated = get(newData, rowId, {});
          // Update its corresponding value
          set(updated, colId, value);
          // Update local data to reflect the change
          setLocalData(newData);
          // Update row changes object
          setRowChanges({
            ...rowChanges,
            updated,
          });
        }
      },
    } as TableMeta,
    pageCount: local ? undefined : Math.ceil((count || 1) / pageSize),
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSubRows: (row, index) =>
      getSubRows ? getSubRows(row, index) : undefined,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: paginated ? getPaginationRowModel() : null,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: !local,
    manualFiltering: !local,
    manualSorting: !local,
    initialState: initialState,
  });

  useEffect(() => {
    onResizeColumn(table.getState().columnSizing);
  }, [table.getState().columnSizing]);

  // Handles fetching for server-side filtering table
  useEffect(() => {
    if (!local) {
      fetchData(fetchDataOptions);
    }
  }, [pageIndex, columnFilters, sorting, fetchData]);

  // Handles fetching for local table
  // Does not refetch for page or filter/sort changes
  useEffect(() => {
    if (local) {
      fetchData(fetchDataOptions);
    }
  }, []);

  useEffect(() => {
    if (hasEditableFields) {
      // Use an editable copy of the data list if table is editable
      setLocalData(data);
      // Reset edit state because data has changed.
      resetEditState();
    }
  }, [data]);

  const noData = useMemo(() => isEmpty(data), [data]);

  return (
    <div>
      {(showActions || showActiveFilters) && (
        <ToolsWrapper showFilters={showActiveFilters}>
          {showActiveFilters && <AppliedFilters table={table} local={local} />}
          {showActions && (
            <QuickTools
              hide={actionsToHide}
              table={table}
              options={{
                [TableActionName.Refetch]: {
                  onClick: () => fetchData(fetchDataOptions),
                },
              }}
            />
          )}
        </ToolsWrapper>
      )}
      <TableWrapper>
        {loading && (
          <LoadingWrapper>
            <Loader active content={`${loadingText}...`} />
          </LoadingWrapper>
        )}
        {!loading && noData && (
          <NoDataWrapper>
            <div>{noDataText}</div>
          </NoDataWrapper>
        )}
        <Table data-cy={dataCy}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    hasSubheaders={header?.subHeaders?.length > 0}
                    align={get(header, 'column.columnDef.meta.align')}
                  >
                    {header.column.getCanFilter() ? (
                      <FilterableTh
                        active={
                          header.column.getIsFiltered() ||
                          header.column.getIsSorted()
                        }
                        style={{ width: header.getSize() }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <TableFilter column={header.column} local={local} />
                      </FilterableTh>
                    ) : (
                      <div
                        style={{ width: header.getSize(), padding: '0px 5px' }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                    <Resizer
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                      }}
                    />
                  </Th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} {...getTrProps(row)}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    <Cell
                      align={get(
                        cell,
                        'column.columnDef.meta.align',
                        TableCellAlignment.Left,
                      )}
                      textTransform={get(
                        cell,
                        'column.columnDef.meta.textTransform',
                        TableCellTextTransform.None,
                      )}
                      className={get(
                        cell,
                        'column.columnDef.meta.className',
                        '',
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Cell>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>

      {paginated && (
        <Footer
          prevDisabled={!table.getCanPreviousPage()}
          onClickPrev={() => table.previousPage()}
          nextDisabled={!table.getCanNextPage()}
          onClickNext={() => table.nextPage()}
          currentPage={table.getState().pagination.pageIndex + 1}
          pageCount={table.getPageCount()}
          defaultPage={table.getState().pagination.pageIndex + 1}
          onGoToPage={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            table.setPageIndex(page);
          }}
        />
      )}
      {isInEditMode && (
        <EditModeButtons>
          {editDisclaimer && <EditDisclaimer>{editDisclaimer}</EditDisclaimer>}
          <Dismiss text="Dismiss" onClick={resetEditState} />
          <SaveChanges
            text="Save Changes"
            onClick={onSave}
            disabled={isEqual(rowChanges.original, rowChanges.updated)}
            primary
            data-cy={`${dataCy}-save-btn`}
          />
        </EditModeButtons>
      )}
    </div>
  );
}

export default EMPTable8;
