import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import styled from 'styled-components';
import columns from '../../metadata/ipAuthorizationHistoryMetadata';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { SubHeader } from '../../styles/styled';
import {
  fetchIpAuthorizationHistory,
  getIPAuthorizationHistory,
  IP_AUTHORIZATION_HISTORY,
} from '../../reducers/authorizedIPsReducer';
import { getUserMemberIds } from '../../reducers/userReducer';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`;

const StyledSubHeader = styled(SubHeader)`
  color: ${({ theme }) => theme.text.header + '!important'};
`;

const AuthorizedIPsHistory = () => {
  const dispatch = useDispatch();
  const ips = useSelector(getIPAuthorizationHistory);
  const memberIds = useSelector(getUserMemberIds);

  const loading = useSelector((state) =>
    createLoadingSelector([IP_AUTHORIZATION_HISTORY])(state),
  );

  useEffect(() => {
    dispatch(fetchIpAuthorizationHistory({ memberIds }));
  }, []);

  return (
    <div>
      <HeaderWrapper>
        <StyledSubHeader>IP AUTHORIZATION HISTORY</StyledSubHeader>
      </HeaderWrapper>

      <EMPTable8
        title="authorizedIPsHistory"
        data={ips}
        columns={columns()}
        fetchData={() => {}}
        count={ips?.length || 0}
        loading={loading}
        loadingText="Fetching Balance Details"
        noDataText={loading ? ' ' : 'No Authorized IPs found'}
        minimumRows={3}
      />
    </div>
  );
};

export default AuthorizedIPsHistory;
