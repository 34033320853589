/** Action Names */

export const GET_CUSTOMER_ACCOUNT_REFERENCE = 'GET_CUSTOMER_ACCOUNT_REFERENCE';
export const DELETE_CUSTOMER_ACCOUNT_REFERENCE =
  'DELETE_CUSTOMER_ACCOUNT_REFERENCE';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_LIST =
  'GET_CUSTOMER_ACCOUNT_REFERENCE_LIST';
export const UPDATE_CUSTOMER_ACCOUNT_REFERENCE =
  'UPDATE_CUSTOMER_ACCOUNT_REFERENCE';
export const LINK_CAR_TO_PARTICIPANT = 'LINK_CAR_TO_PARTICIPANT';
export const UNLINK_CAR_TO_PARTICIPANT = 'UNLINK_CAR_TO_PARTICIPANT';
