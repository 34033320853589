import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import cogoToast from 'cogo-toast';

const CHECKING = 'checking';
const SAVINGS = 'savings';
const ELIGIBLE_ACCOUNT_SUBTYPES = [CHECKING, SAVINGS];

// Lightly modified version of react-plaid-link <https://github.com/pbernasconi/react-plaid-link>
class PlaidLink extends Component {
  state = {
    disabledButton: true,
    initializeURL: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
  };

  onScriptLoaded = () => {
    window.linkHandler = window.Plaid.create({
      apiVersion: this.props.apiVersion,
      clientName: this.props.clientName,
      env: this.props.env,
      key: this.props.publicKey,
      onExit: this.props.onExit,
      onLoad: this.handleLinkOnLoad,
      onEvent: this.props.onEvent,
      onSuccess: this.props.onSuccess,
      product: this.props.product,
      selectAccount: this.props.selectAccount,
      token: this.props.token,
      webhook: this.props.webhook,
      accountSubtypes: {
        depository: ELIGIBLE_ACCOUNT_SUBTYPES,
      },
    });

    this.setState({ disabledButton: false });
  };

  handleLinkOnLoad = () => {
    if (this.props.onLoad != null) {
      this.props.onLoad();
    }
  };

  handleOnClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }

    const institution = this.props.institution || null;
    if (window.linkHandler) {
      window.linkHandler.open(institution);
    }
  };

  exit = (configurationObject) => {
    if (window.linkHandler) {
      window.linkHandler.exit(configurationObject);
    }
  };

  render = () => (
    <Fragment>
      <a
        onClick={this.handleOnClick}
        disabled={this.state.disabledButton}
        style={this.props.style}
        className={this.props.className}
        data-cy="deposit-ach-add-relink-bank-account-link"
      >
        {this.props.children}
      </a>
      <Script
        url={this.state.initializeURL}
        onError={() =>
          cogoToast.error('There was an issue loading the Plaid dialog.')
        }
        onLoad={this.onScriptLoaded}
      />
    </Fragment>
  );
}

PlaidLink.propTypes = {
  clientName: PropTypes.string.isRequired,
  env: PropTypes.oneOf(['tartan', 'sandbox', 'development', 'production'])
    .isRequired,
  product: PropTypes.arrayOf(
    PropTypes.oneOf([
      'connect', // legacy product name
      'info', // legacy product name
      'auth',
      'identity',
      'income',
      'transactions',
      'assets',
    ]),
  ).isRequired,
  publicKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // Optional props
  apiVersion: PropTypes.string,
  institution: PropTypes.string,
  token: PropTypes.string, // User's public token, initializes Plaid Link in update mode
  selectAccount: PropTypes.bool,
  onClick: PropTypes.func,
  onExit: PropTypes.func,
  onLoad: PropTypes.func,
  onEvent: PropTypes.func,
  className: PropTypes.string, // button class name(s)
  webhook: PropTypes.string,
  style: PropTypes.arrayOf(PropTypes.string),
};

PlaidLink.defaultProps = {
  apiVersion: 'v2',
  institution: null,
  selectAccount: false,
  onClick: null,
  onExit: null,
  onLoad: null,
  onEvent: null,
  className: '',
  token: null,
  style: {
    padding: '6px 4px',
    outline: 'none',
    background: '#FFFFFF',
    border: '2px solid #F1F1F1',
    borderRadius: '4px',
  },
};

export default PlaidLink;
