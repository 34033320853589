import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Divider, Statistic } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import { formValueSelector } from 'redux-form';
import get from 'lodash/get';
import { getFundsDesignationLabel } from './InternalTransfers';
import StyledButton from '../../common/StyledButton';
import { formatFiat } from '../../utils/methods';
import colors from '../../constants/colors';
import { getAccountDescription } from '../../reducers/accountsReducer';
import { createTransferRequest } from '../../reducers/internalTransfersReducer';
import {
  INTERNAL_TRANSFERS_FORM,
  TRANSFER_FROM_ACCOUNT,
} from '../../constants/internalTransfersConstants';

const Logo = styled.div`
  margin: 0px auto 10px auto;
`;

const Account = styled.p`
  margin: 0px auto 10px auto;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const Type = styled.span`
  margin: 0px auto 10px auto;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  color: ${colors.GRAY_1};
`;

const FundsDesignation = styled.p`
  font-size: 15px;
  font-weight: 400px;
  color: ${colors.GRAY_1};
  font-style: italic;
  text-align: center;
`;

const Transfer = styled(StyledButton)`
  width: 100%;
  margin: 25px 0px 10px 0px;
`;

const Amount = styled.div`
  display: flex;
  justify-content: center;
`;

const AccountDetails = ({ account }) => {
  const name = get(account, 'name', '-');
  const type = getAccountDescription(account);
  const fundsDesignationLabel = getFundsDesignationLabel(
    get(account, 'allowFutures', false),
  );
  return (
    <>
      <Account>
        {name}
        {type && <Type>{` (${type})`}</Type>}
      </Account>
      <FundsDesignation>{fundsDesignationLabel}</FundsDesignation>
    </>
  );
};

const TransferConfirmationModal = ({ handleConfirm, transactionRequestId }) => {
  const dispatch = useDispatch();

  const form = formValueSelector(INTERNAL_TRANSFERS_FORM);
  const fromAccount = useSelector((state) =>
    form(state, TRANSFER_FROM_ACCOUNT),
  );
  const toAccount = useSelector((state) => form(state, 'toAccount'));
  const amount = useSelector((state) => form(state, 'amount'));
  const loading = useSelector((state) =>
    createLoadingSelector(['CREATE_MEMBER_TRANSFER_REQUEST'])(state),
  );

  const onConfirm = () => {
    dispatch(
      createTransferRequest({
        from: {
          accountId: get(fromAccount, 'accountId', ''),
          fundsDesignation: get(fromAccount, 'fundsDesignation', ''),
          symbol: 'USD',
        },
        to: {
          account_id: get(toAccount, 'accountId', ''),
          fundsDesignation: get(toAccount, 'fundsDesignation', ''),
          symbol: 'USD',
        },
        amount,
        transactionRequestId,
      }),
    );
    handleConfirm();
  };

  return (
    <Modal.Content>
      <div className="logo-container">
        <Logo className=" small-logo" alt="ErisX" />
      </div>
      <Divider horizontal>Transferring</Divider>
      <Amount>
        <Statistic size="small">
          <Statistic.Value className="mono">
            {formatFiat(amount, 'USD')}
          </Statistic.Value>
        </Statistic>
      </Amount>
      <Divider horizontal>From</Divider>
      <AccountDetails account={fromAccount} />
      <Divider horizontal>To</Divider>
      <AccountDetails account={toAccount} />
      <Modal.Actions>
        <Transfer
          primary
          text="Confirm Transfer"
          onClick={onConfirm}
          disabled={loading}
          loading={true}
        />
      </Modal.Actions>
    </Modal.Content>
  );
};

TransferConfirmationModal.defaultProps = {
  handleConfirm: () => {},
  transactionRequestId: '',
};

TransferConfirmationModal.propTypes = {
  handleConfirm: PropTypes.func,
  transactionRequestId: PropTypes.string,
};

export default TransferConfirmationModal;
