import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { Header, Dropdown, List } from 'semantic-ui-react';
import styled from 'styled-components';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import { userItem } from '../../containers/ProfileContainer';

const Wrapper = styled.div`
  display: flex;
  padding: 10px !important;
  margin-right: 2px;
  justify-content: flex-start;
  background-color: transparent !important;
  flex-wrap: wrap;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 20px;
`;

const StyledHeader = styled(Header)`
  margin-top: 0px !important;
`;

class Accounts extends PureComponent {
  state = {
    selectedAccount: this.props.accounts[0],
  };
  componentWillReceiveProps = (nextProps) => {
    if (_.isEqual(this.props.accounts, nextProps.accounts)) {
      this.setState({
        selectedAccount: nextProps.accounts[0],
      });
    }
  };
  handleChange = (e, { name, value }) => {
    if (name === 'selectedAccount') {
      value = _.find(this.props.accounts, { accountId: value });
    }
    this.setState({ [name]: value });
  };
  render = () => {
    const { selectedAccount = {} } = this.state;

    const cqgUserId = _.get(this.props.user, 'cqgUserId', '');
    const cqgUsername = _.get(this.props.user, 'cqgUsername', '');
    const emarketAccountNumber = _.get(
      selectedAccount,
      'emarketAccountNumber',
      '',
    );
    return (
      <Fragment>
        <AccessControl
          allowedPermissions={[`${grants.READ}:${subjects.TRADE_PROFILE}`]}
        >
          <Header content="Cboe Digital Clearing Account:" />
          <Wrapper>
            <div className="pii-column">
              <div className="pii-item">
                <StyledDropdown
                  selection
                  defaultValue={_.get(selectedAccount, 'accountId', '')}
                  options={(this.props.accounts || []).map((account) => ({
                    key: account.accountId,
                    value: account.accountId,
                    text: account.label || account.accountId,
                  }))}
                  name="selectedAccount"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="pii-column">
              {userItem('Firm Code', selectedAccount.firmCode)}
            </div>
            <div className="pii-column">
              {userItem('Account Code', selectedAccount.accountCode)}
            </div>
            <div className="pii-column">
              {userItem('Sub Account Code', selectedAccount.subAccountCode)}
            </div>
          </Wrapper>
          <StyledHeader content="Cboe Digital Trading Credentials:" />
          {!cqgUsername && !cqgUserId && !emarketAccountNumber ? (
            <div>Trading is not enabled for this account.</div>
          ) : null}
          <div className="pii-column">
            {cqgUsername && userItem('CQG Username', cqgUsername)}
          </div>
          <div className="pii-column">
            {cqgUserId && userItem('CQG Trader Id', cqgUserId)}
          </div>
          <div className="pii-column">
            {emarketAccountNumber &&
              userItem('Emarket Account Number', emarketAccountNumber)}
          </div>
        </AccessControl>
      </Fragment>
    );
  };
}
export default Accounts;
