import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hideModal, createLoadingSelector } from 'erisxkit/client';
import {
  reset,
  getFormValues,
  isInvalid,
  submit,
  isPristine,
} from 'redux-form';
import { Modal, TabProps } from 'semantic-ui-react';
import moment from 'moment';
import get from 'lodash/get';
import Tabs from '../../../common/Tabs';
import SingleSideBlockTradeForm, {
  SINGLE_SIDE_BLOCK_TRADE_FORM_NAME,
  SingleSideBlockTradeFormValues,
} from '../SingleSideBlockTradeForm';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import TwoSidedBlockTradeForm, {
  TWO_SIDED_BLOCK_TRADE_FORM_NAME,
  TwoSidedBlockTradeFormValues,
} from '../TwoSidedBlockTradeForm';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Actions, Register } from './RegisterBlockTradeModal.styles';
import { isFuturesBlockTradesRegistration } from '../shared/utils';
import {
  SUBMIT_SINGLE_SIDED_BLOCK_TRADE,
  SUBMIT_TWO_SIDED_BLOCK_TRADE,
  fetchBlockTradeRequestsV2,
  submitSingleSidedBlockTradePromise,
  submitTwoSidedBlockTradePromise,
} from '../../../reducers/BlockTrades/blockTradesReducer';
import { getActiveMemberFirmCode } from '../../../reducers/membersReducer';

const SINGLE_SIDE_INDEX = 0;

const getPanes = (onSubmit: () => void) => [
  {
    menuItem: 'Single Sided',
    render: () => <SingleSideBlockTradeForm onSubmit={onSubmit} />,
  },
  {
    menuItem: 'Two Sided',
    render: () => <TwoSidedBlockTradeForm onSubmit={onSubmit} />,
  },
];

const RegisterBlockTradeModal = () => {
  const dispatch = useAppDispatch();
  const [activeIndex, setActiveIndex] = useState(SINGLE_SIDE_INDEX);

  /** Selectors */
  const singleSideFormValues: Partial<SingleSideBlockTradeFormValues> =
    useAppSelector((state) =>
      getFormValues(SINGLE_SIDE_BLOCK_TRADE_FORM_NAME)(state),
    );
  const twoSidedFormValues: Partial<TwoSidedBlockTradeFormValues> =
    useAppSelector((state) =>
      getFormValues(TWO_SIDED_BLOCK_TRADE_FORM_NAME)(state),
    );
  const singleSideFormInvalid = useAppSelector((state) =>
    isInvalid(SINGLE_SIDE_BLOCK_TRADE_FORM_NAME)(state),
  );
  const singleSideFormPristine = useAppSelector((state) =>
    isPristine(SINGLE_SIDE_BLOCK_TRADE_FORM_NAME)(state),
  );
  const twoSidedFormInvalid = useAppSelector((state) =>
    isInvalid(TWO_SIDED_BLOCK_TRADE_FORM_NAME)(state),
  );
  const twoSidedFormPristine = useAppSelector((state) =>
    isPristine(TWO_SIDED_BLOCK_TRADE_FORM_NAME)(state),
  );
  const loadingSingleSidedSubmission = useAppSelector((state) =>
    createLoadingSelector([SUBMIT_SINGLE_SIDED_BLOCK_TRADE])(state),
  );
  const loadingTwoSidedSubmission = useAppSelector((state) =>
    createLoadingSelector([SUBMIT_TWO_SIDED_BLOCK_TRADE])(state),
  );
  const firmCode = useAppSelector(getActiveMemberFirmCode);
  /** Memoized Attributes */
  const isFutures = useMemo(() => isFuturesBlockTradesRegistration(), []);
  const isSingleSided = useMemo(
    () => activeIndex === SINGLE_SIDE_INDEX,
    [activeIndex],
  );

  const registerButtonText = useMemo(
    () =>
      `Register ${isSingleSided ? 'Single Sided' : 'Two Sided'} Block Trade`,
    [isSingleSided],
  );

  const activeFormName = useMemo(
    () =>
      isSingleSided
        ? SINGLE_SIDE_BLOCK_TRADE_FORM_NAME
        : TWO_SIDED_BLOCK_TRADE_FORM_NAME,
    [isSingleSided],
  );

  const registerButtonDisabled = useMemo(() => {
    if (isSingleSided) {
      return singleSideFormPristine || singleSideFormInvalid;
    }
    return twoSidedFormInvalid || twoSidedFormPristine;
  }, [
    isSingleSided,
    singleSideFormInvalid,
    singleSideFormPristine,
    twoSidedFormInvalid,
    twoSidedFormPristine,
  ]);

  /** Memoized Callbacks */
  const registerSingleSide = useCallback(async () => {
    let failed = false;
    const parsed = {
      ...singleSideFormValues,
      negotiatedTime: moment(
        get(singleSideFormValues, 'negotiatedTime'),
      ).valueOf(),
    };
    try {
      await submitSingleSidedBlockTradePromise(parsed, dispatch);
    } catch (error) {
      failed = true;
    } finally {
      if (!failed) {
        dispatch(hideModal());
        dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
      }
    }
  }, [singleSideFormValues, dispatch, firmCode]);

  const registerTwoSided = useCallback(async () => {
    let failed = false;
    const parsed = {
      ...twoSidedFormValues,
      negotiatedTime: moment(
        get(twoSidedFormValues, 'negotiatedTime'),
      ).valueOf(),
    };
    try {
      await submitTwoSidedBlockTradePromise(parsed, dispatch);
    } catch (error) {
      failed = true;
    } finally {
      if (!failed) {
        dispatch(hideModal());
        dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
      }
    }
  }, [dispatch, twoSidedFormValues, firmCode]);
  const onRegisterClicked = useCallback(
    () => (isSingleSided ? registerSingleSide() : registerTwoSided()),
    [isSingleSided, registerSingleSide, registerTwoSided],
  );

  const onSubmit = useCallback(
    () => dispatch(submit(activeFormName)),
    [activeFormName, dispatch],
  );

  /** Pane handling */
  const panes = useMemo(() => getPanes(onRegisterClicked), [onRegisterClicked]);

  const onTabChange = (e: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    if (data?.activeIndex !== undefined) {
      setActiveIndex(Number(data.activeIndex));
    }
  };

  /** Effects */
  useEffect(() => {
    // Since we prevent both form states to be destroyed on unmount
    // in order to keep it after a tab change, we reinitialize on modal mount
    dispatch(reset(SINGLE_SIDE_BLOCK_TRADE_FORM_NAME));
    dispatch(reset(TWO_SIDED_BLOCK_TRADE_FORM_NAME));
  }, [dispatch]);

  return (
    <>
      <Modal.Header>Register Block Trade</Modal.Header>
      <Modal.Content>
        <Tabs
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </Modal.Content>
      <Actions>
        <Register
          primary
          text={registerButtonText}
          onClick={onSubmit}
          disabled={
            registerButtonDisabled ||
            loadingSingleSidedSubmission ||
            loadingTwoSidedSubmission
          }
        />
      </Actions>
    </>
  );
};

export default RegisterBlockTradeModal;
