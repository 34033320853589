import React from 'react';
import { isEmpty } from 'lodash';
import { renderDropdown, rules } from 'erisxkit/client';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { renderField } from '../forms/components';

// NOTE: this field will be disabled if currencyOptions is empty.
// One of the major conditions within the helper method is needing to check balances in the match
//   engine (given the flag to skip this check is falsy). If the match engine can't be reached, no
//   options will be returned, and this field will be disabled.
const AssetTypeField = ({
  change,
  currencyOptions,
  handleChange,
  postChange,
  ...rest
}) => (
  <Field
    component={currencyOptions.length === 1 ? renderField : renderDropdown}
    disabled={isEmpty(currencyOptions)}
    label="Asset Type:"
    name="assetTypeWithFundsDesignation"
    options={currencyOptions.length !== 1 ? currencyOptions : null}
    placeholder="Select currency..."
    readOnly={currencyOptions.length === 1}
    required
    selection
    validate={[rules.required]}
    onChange={(e, nextAssetType) => {
      // split asset type, using blank funds designation if none specified
      const [assetType, fundsDesignation] = nextAssetType.split('/');
      change('assetType', assetType);
      change('fundsDesignation', fundsDesignation);

      // send asset type and funds designation to parent to generate correct refCode
      handleChange(null, { name: 'assetType', value: assetType });
      handleChange(null, { name: 'fundsDesignation', value: fundsDesignation });
      postChange(assetType, fundsDesignation);
    }}
    {...rest}
  />
);

AssetTypeField.propTypes = {
  change: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  handleChange: PropTypes.func,
  postChange: PropTypes.func,
};

AssetTypeField.defaultProps = {
  currencyOptions: [],
  handleChange: () => {},
  postChange: () => {},
};

export default AssetTypeField;
