import React, { useState, useEffect, Fragment, useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Icon, Dropdown, Statistic } from 'semantic-ui-react';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import AccessControl from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import { formatFiat, trade, usesSSO } from '../utils/methods';
import { PII_NAME } from '../constants/ddActionNames';
import history from '../constants/history';
import colors from '../constants/colors';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { sizes } from '../styles/styled';
import { ORIENTATIONS } from '../reducers/uiReducer';
import { DailyChangeStatisticMobile, DailyChange } from './DailyChange';
import Auth from '../services/Auth';
import TradeActionsFCM from './TradeActionsFCM';
import gaCategories from '../constants/ga-categories';
import { MemberUserPermission } from '../ts/types/MemberUserPermission';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
Wrapper.displayName = 'TopNavMobileWrapper';

const NavRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightNav = styled.div`
  display: flex;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  padding: 10px 10px 0px 5px;
  background-color: ${({ theme }) => theme.topnav.bg};
`;

const GiftIcon = styled(Icon)`
  color: ${colors.GREEN};
  align-self: center;
  margin-right: 15px !important;
`;
GiftIcon.displayName = 'GiftIcon';

const MobileUserDropdown = styled(Dropdown)`
  align-self: center;
`;
MobileUserDropdown.displayName = 'MobileUserDropdown';

const MobileStats = styled(Statistic)`
  margin-top: 0px !important;
  justify-content: flex-start;
  padding-bottom: 10px;
  && .label {
    color: ${colors.GRAY_3};
    text-align: right;
    text-transform: capitalize;
    font-weight: 400;
  }
  &&& .value {
    font-size: 1.5rem !important;
    @media (max-width: ${sizes.TINY}) {
      font-size: 1.4rem !important;
    }
    @media (max-width: 320px) {
      font-size: 1rem !important;
    }
  }
`;

const ActionsRow = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const FloatLeft = styled.div`
  display: flex;
  margin-right: auto;
`;
const StyledDailyChange = styled(DailyChange)`
  color: ${({ theme }) => theme.text.header + '!important'};
`;

const StyledHeader = styled.header<{ loading: boolean }>`
  background-color: ${({ theme, loading }) =>
    loading ? theme.body : theme.topnav.bg};
  box-shadow: ${({ theme, loading }) => !loading && theme.topnav.boxShadow};
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // User dropdown
  .user-dropdown {
    display: inline-flex;
    align-items: center;
  }
  img.user-picture {
    border-radius: 100%;
    width: 45px;
    height: 45px;
  }
`;

const getLogoClass = (simple: boolean, small: boolean) => {
  if (small) {
    return 'small-logo';
  } else if (simple && !small) {
    return 'logo';
  }

  return '';
};

export const REPORTS_STATEMENTS_REQUIRED_PERMS: MemberUserPermission[] = [
  'read_futures_positions',
  'read_collateral_positions',
  'read_balances',
  'read_movements',
  'read_trades',
];

const userCanSeeReportsStatements = (userPerms: string[]) =>
  (userPerms || []).some((perm) =>
    REPORTS_STATEMENTS_REQUIRED_PERMS.includes(perm),
  );

const TopNavFCM = (props) => {
  const [topNavSmall, setTopNavSmall] = useState<boolean>(
    props.viewportLessThanMedium,
  );
  const [topNavExtraSmall, setTopNavExtraSmall] = useState<boolean>(
    props.viewportLessThanSmall,
  );
  const [topNavMedium, setTopNavMedium] = useState<boolean>(
    (isTablet && props.orientation === ORIENTATIONS.PORTRAIT) ||
      (props.viewportLessThanLarge && !props.viewportLessThanMedium),
  );

  useEffect(() => {
    setTopNavSmall(props.viewportLessThanMedium);
    setTopNavExtraSmall(props.viewportLessThanSmall);
    setTopNavMedium(
      (isTablet && props.orientation === ORIENTATIONS.PORTRAIT) ||
        (props.viewportLessThanLarge && !props.viewportLessThanMedium),
    );
  }, [
    props.orientation,
    props.viewportLessThanLarge,
    props.viewportLessThanMedium,
    props.viewportLessThanSmall,
  ]);

  const auth = useMemo(() => new Auth(), []);

  const options = (simpleNav: boolean) => {
    const { enableApiCredentials, user, userOptionClick, loading, logout } =
      props;
    return [
      {
        key: 'user-details',
        content: (
          <p>
            <span className="bold" data-dd-action-name={PII_NAME}>
              {_.get(user, 'pii.firstName', user.nickname)}{' '}
              {_.get(user, 'pii.lastName')}
            </span>
            <br />
            {user.email}
          </p>
        ),
      },
      {
        key: 'profile',
        text: 'Profile',
        icon: 'user',
        onClick: () => userOptionClick('profile'),
      },
      {
        key: 'reports',
        text: 'Reports / Statements',
        icon: 'chart bar',
        onClick: () => userOptionClick('reports-statements'),
        hide: !userCanSeeReportsStatements(_.get(user, 'permissions', [])),
      },
      {
        key: 'api-settings',
        text: 'API Settings',
        icon: 'cogs',
        onClick: () => userOptionClick('api-settings'),
        // Should be shown if the user has at least one API Key permission set
        hide:
          enableApiCredentials !== 'true' ||
          _.isEmpty(_.get(user, 'apiKeyPermissions', [])),
      },
      {
        key: 'authorized-ips',
        text: 'Authorized IPs',
        icon: 'globe',
        hide: !props.canAuthorizeIPs,
        onClick: () => userOptionClick(`authorized-ips`),
      },
      {
        // use lowercase string for simplenav prop to silence warnings.
        key: 'sign-out',
        text: 'Sign Out',
        icon: 'sign out',
        onClick: () => logout(auth),
        simplenav: 'true',
      },
    ]
      .filter((option) => (simpleNav || loading ? option.simplenav : true))
      .filter((option) => !option.hide);
  };

  const {
    allAccounts = [],
    accounts,
    loading,
    logoClick,
    simpleNav,
    user,
    currTotalUsdValue,
    prevTotalUsdValue,
    loadingBalances,
    activeAccountId,
  } = props;

  const trigger = (
    <Fragment>
      <img
        className={`user-picture${isMobileOnly ? '-mobile' : ''}`}
        src={_.get(user, 'auth.picture', '')}
        alt="user"
      />
      <Icon name="angle down" className={'large'} />
    </Fragment>
  );

  const totalLabel = activeAccountId ? 'USD Value' : 'Total USD Value';

  return !loading && isMobileOnly ? (
    <Wrapper>
      <NavRow>
        <div
          className="logo-container mobile small-logo"
          onClick={() => logoClick(isMobileOnly)}
        >
          <div role="presentation" className="small-logo" alt="ErisX" />
        </div>
        <RightNav>
          <GiftIcon
            size="large"
            name="gift"
            onClick={() => history.push(`/referrals`)}
          />
          <MobileUserDropdown
            className="user-dropdown"
            trigger={trigger}
            options={options(simpleNav)}
            pointing="top right"
            icon={null}
          />
        </RightNav>
      </NavRow>

      <DataRow>
        {allAccounts?.length ? (
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.BALANCES}`]}
          >
            {props.selectedMemberId && (
              <MobileStats size="tiny" loading>
                <Statistic.Label>{totalLabel}</Statistic.Label>
                <Statistic.Value>
                  {loadingBalances ? (
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width="100%"
                      height={33}
                      key="total-balances-skeleton"
                    />
                  ) : (
                    formatFiat(currTotalUsdValue, 'USD')
                  )}
                </Statistic.Value>
              </MobileStats>
            )}
            <DailyChangeStatisticMobile
              size="tiny"
              id="total-balance-day-mobile"
              loading
              style={{ gridAreaTemplate: 'acc-total' }}
            >
              <Statistic.Label>
                {loadingBalances || prevTotalUsdValue !== '0' ? 'Change' : ''}
              </Statistic.Label>
              <Statistic.Value style={{ textAlign: 'center' }}>
                {loadingBalances ? (
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width="100%"
                    height={33}
                    key="total-balances-skeleton"
                  />
                ) : (
                  <StyledDailyChange
                    currTotalUsdValue={currTotalUsdValue}
                    prevTotalUsdValue={prevTotalUsdValue}
                  ></StyledDailyChange>
                )}
              </Statistic.Value>
            </DailyChangeStatisticMobile>
          </AccessControl>
        ) : null}
      </DataRow>
      <ActionsRow>
        <TradeActionsFCM
          accounts={accounts}
          loading={loading}
          tradeProps={{
            onClick: () => {
              trade(
                auth,
                gaCategories.TRADE,
                'tradeButton',
                props.defaultTradingApp,
                props.tradingFrontendEndpoint,
                props.clientId,
              );
            },
            isExternal: !usesSSO(props.defaultTradingApp),
          }}
        />
      </ActionsRow>
    </Wrapper>
  ) : (
    <StyledHeader
      className={classNames({
        'mobile extra-small': topNavSmall,
        simple: simpleNav,
        medium: topNavMedium,
      })}
      loading={loading}
    >
      <FloatLeft>
        <div
          className={`logo-container ${
            !getLogoClass(simpleNav, topNavSmall) && 'hide'
          }`}
        >
          <div
            role="presentation"
            className={getLogoClass(simpleNav, topNavSmall)}
            alt="ErisX"
            onClick={() => {
              if (!simpleNav) {
                logoClick(topNavSmall);
              }
            }}
          />
        </div>
      </FloatLeft>
      {!simpleNav && (
        <TradeActionsFCM
          accounts={accounts}
          loading={loading}
          tradeProps={{
            onClick: () => {
              trade(
                auth,
                gaCategories.TRADE,
                'tradeButton',
                props.defaultTradingApp,
                props.tradingFrontendEndpoint,
                props.clientId,
              );
            },
            isExternal: !usesSSO(props.defaultTradingApp),
          }}
        />
      )}
      {!loading && (
        <Dropdown
          className="user-dropdown"
          trigger={trigger}
          options={options(simpleNav)}
          pointing="top right"
          icon={null}
          data-cy="top-nav-user-dropdown"
        />
      )}
    </StyledHeader>
  );
};

export default TopNavFCM;
