export const CHOOSE_KBA_REQUEST = 'CHOOSE_KBA_REQUEST';
export const CHOOSE_KBA_SUCCESS = 'CHOOSE_KBA_SUCCESS';
export const CHOOSE_KBA_FAILED = 'CHOOSE_KBA_FAILED';

export const KBA_QUESTION_REQUEST = 'KBA_QUESTION_REQUEST';
export const KBA_QUESTION_SUCCESS = 'KBA_QUESTION_SUCCESS';
export const KBA_QUESTION_FAILED = 'KBA_QUESTION_FAILED';

//* Initial State */
const initialState = {};

//* Reducer */
export default function kbaReducer(state = initialState, action) {
  switch (action.type) {
    case KBA_QUESTION_SUCCESS:
    case CHOOSE_KBA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

/* Selectors */

/**
 * Action generator fetch the kba question from identity mind.
 */
export const fetchKbaQuestion = (question) => ({
  type: KBA_QUESTION_REQUEST,
  payload: question,
});

/**
 * Action generator to store user information obtained from onboarding.
 * @param {Object} choice - Object with question and choice keys.
 */
export const chooseKba = (choice) => ({
  type: CHOOSE_KBA_REQUEST,
  payload: choice,
});
