import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { BalanceDetail } from '../ts/models/Treasury/BalanceDetail.model';
import { USD } from '../constants/assetTypes';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { formatFiat } from '../utils/methods';

const columnHelper = createColumnHelper<BalanceDetail>();

export const fcmBalanceDetailsMetadata = (cdfEnabled: boolean) =>
  [
    columnHelper.accessor('accountLabel', {
      id: 'accountLabel',
      header: 'Account',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
    }),
    columnHelper.accessor('assetType', {
      id: 'assetType',
      header: 'Asset Type',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.openingBalance, USD), {
      id: 'openingBalance',
      header: 'Opening',
      minSize: 90,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.spotMovement, USD), {
      id: 'spotMovement',
      header: 'Spot Movements',
      minSize: 140,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.exchangeFees, USD), {
      id: 'exchangeFees',
      header: 'Ex Fees',
      minSize: 95,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.clearingFees, USD), {
      id: 'clearingFees',
      header: 'CL Fees',
      minSize: 95,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.otherFees, USD), {
      id: 'otherFees',
      header: 'Other Fees',
      minSize: 95,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.assetMovement, USD), {
      id: 'assetMovement',
      header: 'Asset Movements',
      minSize: 145,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.futuresDelivery, USD), {
      id: 'futuresDelivery',
      header: 'Futures Delivery',
      minSize: 140,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.variationMargin, USD), {
      id: 'variationMargin',
      header: 'Variation Margin',
      minSize: 140,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    ...(cdfEnabled
      ? [
          columnHelper.accessor((row) => formatFiat(row.fundingAmount, USD), {
            id: 'fundingAmount',
            header: 'Funding Amount',
            minSize: 140,
            meta: {
              align: TableCellAlignment.Right,
              filterType: TableFilterType.Number,
              className: 'mono',
            },
          }),
        ]
      : []),
    columnHelper.accessor((row) => formatFiat(row.closingBalance, USD), {
      id: 'closingBalance',
      header: 'Closing',
      minSize: 100,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.totalEquity, USD), {
      id: 'totalEquity',
      header: 'Total Equity',
      minSize: 115,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.initialMargin, USD), {
      id: 'initialMargin',
      header: 'Initial Margin',
      minSize: 115,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.totalExcessDeficit, USD), {
      id: 'totalExcessDeficit',
      header: 'Total Excess Deficit',
      minSize: 150,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.netLiquidatingValue, USD), {
      id: 'netLiquidatingValue',
      header: 'Net Liquidating Value',
      minSize: 170,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.px, USD), {
      id: 'px',
      header: 'Ref Price',
      minSize: 100,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Number,
        className: 'mono',
      },
    }),
    columnHelper.accessor((row) => formatFiat(row.totalUsdValue, USD), {
      id: 'totalUsdValue',
      header: 'Total USD Value',
      minSize: 170,
      size: 170,
      meta: {
        align: TableCellAlignment.Right,
        filterType: TableFilterType.Currency,
        className: 'mono',
      },
    }),
  ] as ColumnDef<BalanceDetail>[];
