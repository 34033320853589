import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../constants/colors';
import useIsFCM from '../../hooks/useIsFCM';

const Wrapper = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: 0px;
  margin-right: 0px;
`;
Wrapper.displayName = 'Wrapper';

const Text = styled.p`
  && {
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize};
  }
`;
Text.displayName = 'Text';

const WithdrawDisclaimer = ({ marginTop, marginBottom, fontSize }) => {
  const isFCM = useIsFCM();
  const color = isFCM ? colors.MEDIUM_NAVY : colors.NAVY_LIGHT_TEXT;
  return (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <Text fontSize={fontSize} color={color}>
        Please be advised that you may not stop a fund transfer once confirmed
        below.
      </Text>
    </Wrapper>
  );
};

WithdrawDisclaimer.defaultProps = {
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '14px',
};

WithdrawDisclaimer.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  fontSize: PropTypes.string,
};

export default WithdrawDisclaimer;
