import {
  deleteTradingParticipant,
  disableBlockTrades,
  enableBlockTrades,
  fetchTradingParticipants,
} from '../reducers/participantManagementReducer';
import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watch() {
  yield takeLatest(
    fetchTradingParticipants,
    generateSaga(fetchTradingParticipants, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    deleteTradingParticipant,
    generateSaga(deleteTradingParticipant, {
      method: HTTPMethod.POST,
      success: 'Participant removed successfully',
    }),
  );
  yield takeLatest(
    enableBlockTrades,
    generateSaga(enableBlockTrades, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    disableBlockTrades,
    generateSaga(disableBlockTrades, {
      method: HTTPMethod.POST,
    }),
  );
}
