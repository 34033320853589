import _ from 'lodash';
import { apiKeyPermissions } from 'erisxkit/client';

export default (
  apiCredentialsPermissions = [],
  enabledClearingApiCredentials = [],
) =>
  apiCredentialsPermissions.map((key) => ({
    key,
    label: _.chain(apiKeyPermissions)
      .find({ key })
      .get('label', _.startCase(key))
      .value(),
    value: key,
    showCheckbox: true,
    show: enabledClearingApiCredentials.includes(key),
  }));
