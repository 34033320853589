import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Popper, TextField } from '@material-ui/core';
import colors from '../../constants/colors';

const Item = styled(MenuItem)`
  display: flex;
  padding: 8px 15px 8px 15px;
  align-items: end;
  cursor: pointer;
  &&& {
    background-color: ${(props) => props.isSelected && colors.GREEN};
  }
`;

const MemberName = styled.p`
  margin-right: 5px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &&& {
    color: ${(props) => props.isSelected && colors.NAVY};
  }
`;

const MemberLabel = styled.p`
  color: ${colors.GRAY_1};
  margin-right: 5px;
  margin-bottom: 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Selected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableTextField = styled(TextField)`
  input {
    color: black;
    cursor: pointer;
  }
`;
const StyledPopper = styled(Popper)`
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: unset;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
    width: fit-content;
    max-width: 400px;
  }
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: ${colors.GREEN};
    color: ${colors.MEDIUM_NAVY};
    .account-dropdown-chip {
      background-color: ${colors.NAVY};
      color: ${colors.WHITE};
    }
  }
  .MuiListItem-gutters {
    padding-left: 0px;
  }
  .MuiAutocomplete-option {
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: ${({ theme }) => theme.table.quickActions.menu.bg};
    color: ${({ theme }) => theme.table.quickActions.menu.color};
  }
`;

const Placeholder = styled.div`
  color: ${colors.INPUT_PLACEHOLDER};
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 100%;
  }
`;

const useStyles = makeStyles({
  popper: {
    width: 'fit-content',
  },
  select: {
    '& li:hover': {
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
    '& .MuiListItem-root.Mui-selected': {
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
    '& .MuiListItem-root.Mui-focusVisible': {
      color: `${colors.NAVY}`,
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
  },
  tooltip: {
    fontSize: '1em',
    backgroundColor: `${colors.GRAY_2}`,
    color: `${colors.NAVY}`,
    '& .MuiTooltip-arrow': {
      color: `${colors.GRAY_2}`,
    },
  },
});

const CustomPopper = (props) => (
  <StyledPopper
    {...props}
    style={{ width: 'unset' }} // Unset width that's set inline by MaterialUI
    placement="bottom-start"
  />
);

const MemberDropdown = ({
  members,
  label,
  value,
  onChange,
  input,
  id,
  className,
  placeholder,
  searchable,
  disableUnderline,
}) => {
  // This component can be used in two ways:
  // 1. Using redux-form. In which case we'd receive an input prop so the component is controlled via the redux-form API. A name prop must be provided too.
  // 2. Stand-alone, controlling it with value and onChange prop
  // Internal component state
  const handleChange = (acc) => onChange(acc); // External side effects
  let selectedMember = input ? input.value : value;
  let handleOnChange = input ? input.onChange : handleChange;

  useEffect(() => {
    // Set how the internal state is handled
    if (input) {
      // redux-form
      selectedMember = input.value;
      handleOnChange = input.onChange;
    } else {
      // Stand-alone
      selectedMember = value;
      handleOnChange = handleChange;
    }
  }, [input, value, onChange]);

  const classes = useStyles();

  // We control Tooltip and List open state so we make sure the tooltip never obstructs
  // visibility of the list
  const [showTooltip, setShowTooltip] = useState(false);
  const [showList, setShowList] = useState(false);
  const openTooltip = () => !showList && setShowTooltip(true);
  const openList = () => setShowList(true);
  const hideList = () => setShowList(false);

  return (
    <Wrapper
      className={className}
      onMouseEnter={openTooltip}
      onMouseLeave={() => setShowTooltip(false)}
      onBlur={hideList}
    >
      {label}
      {searchable ? (
        <Tooltip
          open={showTooltip}
          disableFocusListener
          classes={{ tooltip: classes.tooltip }}
          arrow
          title={selectedMember.name == null ? '' : selectedMember.name}
          leaveTouchDelay={400}
        >
          <StyledAutocomplete
            open={showList}
            onOpen={() => setShowTooltip(false)}
            id={id}
            disableClearable
            fullWidth
            value={selectedMember}
            freeSolo
            PopperComponent={CustomPopper}
            onChange={(e, acc) => {
              handleOnChange(acc);
              hideList();
            }}
            getOptionLabel={(option) => option?.name}
            options={members}
            noOptionsText="No members available"
            filterOptions={(opt, state) =>
              state?.inputValue
                ? opt.filter(
                    (mem) =>
                      mem?.name
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      mem?.name
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase()),
                  )
                : opt
            }
            renderInput={(params) => {
              params.inputProps.type = 'search';
              return (
                <ClickableTextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {
                      cursor: 'pointer',
                    },
                    onClick: openList,
                  }}
                />
              );
            }}
            renderValue={(selected) => (
              <Tooltip
                disableFocusListener
                classes={{ tooltip: classes.tooltip }}
                arrow
                title={selectedMember.name == null ? '' : selectedMember.name}
                leaveTouchDelay={400}
              >
                <Selected>{selected.name}</Selected>
              </Tooltip>
            )}
            renderOption={(acc: any) => {
              // Manually keep track of the selected status due to materialui not doing it
              // when select value is an object and not a string
              const isSelected = acc.name === selectedMember.name;
              return (
                <Item
                  value={acc}
                  key={acc.label}
                  isSelected={isSelected}
                  id={`${id}-${acc.name}`}
                >
                  <MemberName isSelected={isSelected}>{acc.name}</MemberName>
                  <MemberLabel>{`(${acc?.firmCode})`}</MemberLabel>
                </Item>
              );
            }}
          />
        </Tooltip>
      ) : (
        <Select
          id={id}
          autoWidth
          disableUnderline={disableUnderline}
          value={selectedMember}
          onChange={(e, acc) => {
            if (e?.target?.value) handleOnChange(e.target.value);
          }}
          displayEmpty
          renderValue={(selected) =>
            selected?.name ? (
              <Tooltip
                disableFocusListener
                classes={{ tooltip: classes.tooltip }}
                arrow
                title={selectedMember.name == null ? '' : selectedMember.name}
                leaveTouchDelay={400}
              >
                <Selected>{selected.name}</Selected>
              </Tooltip>
            ) : (
              <Placeholder>{placeholder}</Placeholder>
            )
          }
          MenuProps={{
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {members.map((acc) => {
            // Manually keep track of the selected status due to materialui not doing it
            // when select value is an object and not a string
            const isSelected = acc.name === selectedMember.name;
            return (
              <Item
                value={acc}
                key={acc.name}
                isSelected={isSelected}
                id={`${id}-${acc.name}`}
              >
                <MemberName isSelected={isSelected}>{acc?.name}</MemberName>
                <MemberLabel>{`(${acc?.firmCode})`}</MemberLabel>
              </Item>
            );
          })}
        </Select>
      )}
    </Wrapper>
  );
};

MemberDropdown.defaultProps = {
  members: [],
  input: null,
  label: null,
  value: {
    memberId: '',
    name: '',
    firmCode: '',
    entitlements: [],
    clearingMemberType: '',
  },
  onChange: () => {},
  className: '',
  placeholder: 'Please select a Member',
  searchable: false,
  disableUnderline: false,
};

MemberDropdown.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      memberId: PropTypes.string,
      name: PropTypes.string,
      firmCode: PropTypes.string,
      entitlements: PropTypes.arrayOf(PropTypes.string),
      exchangeMemberId: PropTypes.string,
      clearingMemberId: PropTypes.string,
      clearingMemberType: PropTypes.string,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        subAccountCode: PropTypes.string,
        iraAcctType: PropTypes.string,
        uiViews: PropTypes.shape({
          seg: PropTypes.shape({
            read: PropTypes.bool.isRequired,
            create: PropTypes.bool.isRequired,
          }),
          memberProperty: PropTypes.shape({
            read: PropTypes.bool.isRequired,
            create: PropTypes.bool.isRequired,
          }),
        }),
      }),
      PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
  }),
  value: PropTypes.shape({
    memberId: PropTypes.string,
    name: PropTypes.string,
    firmCode: PropTypes.string,
    entitlements: PropTypes.arrayOf(PropTypes.string),
    clearingMemberType: PropTypes.string,
  }),
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  disableUnderline: PropTypes.bool,
};

export default MemberDropdown;
