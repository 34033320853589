import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LogoAnimation } from 'erisxkit/client';
import classnames from 'classnames';
import { Message } from 'semantic-ui-react';

class LoadingComponent extends Component {
  state = {
    disabled: this.props.disabled,
    message: this.props.message,
  };
  componentDidMount = () => {
    const { interval } = this.props;
    if (this.props.action) {
      this.interval = setInterval(() => this.props.action(), interval);
    }
    setTimeout(() => {
      this.setState({
        disabled: true,
        message: {
          header:
            'Unfortunately there was a problem processing your information.',
          text: 'Please <a onclick="location.reload()" >refresh the page</a> or try again later. If the problem persists, please contact the support team.',
          negative: true,
        },
      });
      clearInterval(this.interval);
    }, 30000);
  };

  componentWillUnmount() {
    // Clear the interval right before component unmount
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render = () => {
    const { className, loading, ...rest } = this.props;
    const { message } = this.state;

    return (
      <div
        className={classnames('-loading', { '-active': loading }, className)}
        {...rest}
      >
        <div
          className={`-loading-inner${this.state.disabled ? ' disabled' : ''}`}
        >
          <LogoAnimation size={125} />
          <Message
            negative={_.get(message, 'negative', false)}
            className="text-center"
          >
            <Message.Header>{_.get(message, 'header', '')}</Message.Header>
            <p
              dangerouslySetInnerHTML={{ __html: _.get(message, 'text', '') }}
            />
          </Message>
        </div>
      </div>
    );
  };
}

LoadingComponent.propTypes = {
  interval: PropTypes.number,
};

LoadingComponent.defaultProps = {
  interval: 1000,
};

export default LoadingComponent;
