import React, { Fragment } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';

const ConfirmPhoto = ({ image, onConfirm, onReject, type = '' }) => (
  <Fragment>
    <Modal.Content image scrolling>
      <Image wrapped size="medium" id="webcam-confirm" src={image} alt="Id" />
    </Modal.Content>
    <Modal.Actions>
      <Button
        disabled={!image}
        secondary
        onClick={onReject}
        content="Try Again"
        data-dd-action-name={`Photo Try Again ${type}`}
      />
      <Button
        disabled={!image}
        primary
        onClick={() => onConfirm(image)}
        content="Upload & Continue"
        data-dd-action-name={`Photo Upload ${type}`}
        data-cy={`ob-verification-photo-upload-face`}
      />
    </Modal.Actions>
  </Fragment>
);

export default ConfirmPhoto;
