import get from 'lodash/get';

import {
  DeliveryAccountView,
  DeliveryCgmView,
  DeliveryContractView,
} from '../../ts/models/Deliveries/Delivery.model';
import DeliveryTableRow, {
  DeliveryTableRowLS,
} from '../../ts/models/Deliveries/DeliveryTableRow.model';

/**
 * @description From a single DeliveryCgmView we get many DeliveryTableRow objects
 * @param cgmView - DeliveryCgmView to parse
 * @param accountLabel - accountLabel of DeliveryAccountView to which belongs the DeliveryCgmView
 * @param contractView - DeliveryContractView to which belongs the DeliveryCgmView
 * @param summaryType - Defines if it uses long or short summary
 * @returns DeliveryTableRow object corresponding to the passed contractView
 */

const parseDeliveryCgmViews = (
  cgmViews: DeliveryCgmView[],
  accountLabel: string,
  contractView: DeliveryContractView,
  summaryType: DeliveryTableRowLS,
): DeliveryTableRow[] => {
  const { productSymbol, contractSymbol, maturityDate, deliveryType } =
    contractView;

  return cgmViews.map((cgmView) => {
    const summary = get(cgmView, ['summary', summaryType]);
    return {
      productSymbol,
      contractSymbol,
      deliveryType,
      maturityDate,
      account: accountLabel,
      cgm: get(cgmView, ['cgmNumber']),
      deliveryAcct: get(cgmView, ['deliveryAcctId']),
      ls: summaryType,
      qty: get(summary, ['qty']),
      price: get(summary, ['price']),
      deliveryAsset: get(summary, ['deliveryAsset']),
      deliveryRequirement: get(summary, ['deliveryRequirement']),
      deliveryAmount: get(summary, ['deliveryAmount']),
      deliveryBalance: get(summary, ['deliveryBalance']),
      satisfied: get(summary, ['satisfied']),
    };
  });
};

export default parseDeliveryCgmViews;
