import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, Form, Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import Skeleton from '@material-ui/lab/Skeleton';
import isEmpty from 'lodash/isEmpty';
import { hideModal, createLoadingSelector } from 'erisxkit/client';
import StyledButton from '../../common/StyledButton';
import { getAllAccountsList } from '../../reducers/accountsReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import colors from '../../constants/colors';
import StyledCheckbox from '../../common/StyledCheckbox';
import Account from '../../ts/models/Account.model';
import { hideTaxEStatementsModal } from './utils';
import {
  EStatementStatus,
  fetchAllAccountsEStatementsStatus,
  getAllAccountsEstatementStatus,
  fetchEStatementsStatus,
  GET_ALL_ACCOUNTS_ESTATEMENT_STATUS,
  setEStatementStatusPromise,
} from '../../reducers/taxEStatementsReducer';
import { sizes } from '../../styles/styled';
import { isEqual } from 'lodash';

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 23px;
  && {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled.p`
  font-size: 20px;
  text-align: center;
  @media (max-width: ${sizes.TINY}) {
    font-size: 18px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  text-align: center;
`;

const Button = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
`;

const Buttons = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
  & :nth-child(2) {
    margin-left: 30px;
  }
`;

const PromptWrapper = styled.div`
  margin-top: 3.5em;
  margin-bottom: 2em;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.NAVY_3};
`;

const Accounts = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  @media (max-width: ${sizes.TINY}) {
    width: 90%;
  }
`;

const AccountItem = styled.div`
  display: flex;
  padding: 15px;
  padding-left: 25px;
  align-items: center;
  font-size: 18px;

  p {
    padding-top: 2px;
    @media (max-width: ${sizes.TINY}) {
      font-size: 15px;
    }
  }
`;

const Checkbox = styled(Form.Checkbox)`
  height: 17px;
  margin-right: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CheckCircle = styled(Icon)`
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  color: ${({ theme }) => theme.accent} !important;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-left: auto;
  margin-right: auto;
`;
type OptInStatus = {
  optedIn: string[];
  optedOut: string[];
};

const getOptedInStatus = (accounts: EStatementStatus[]): OptInStatus => ({
  optedIn: accounts
    .filter((acc) => acc.taxEstatements)
    .map((acc) => acc.accountId),
  optedOut: accounts
    .filter((acc) => !acc.taxEstatements)
    .map((acc) => acc.accountId),
});

// Makes a diff between the original OptInStatus and current one
// so we only updated the accounts that need to be updated
const getOptedInStatusDiff = (
  initial: OptInStatus,
  current: OptInStatus,
): OptInStatus => {
  return {
    // Accounts that are now optedIn but initially were not
    optedIn: current.optedIn.filter(
      (currAcc) =>
        !initial.optedIn.some((initialAcc) => initialAcc === currAcc),
    ),
    optedOut: current.optedOut.filter(
      (currAcc) =>
        !initial.optedOut.some((initialAcc) => initialAcc === currAcc),
    ),
  };
};

type Props = {
  showAccounts?: boolean;
};

const optInStatusEquals = (
  initial: OptInStatus,
  curr: OptInStatus,
): boolean => {
  if (
    curr.optedIn?.length !== initial.optedIn?.length ||
    curr.optedOut?.length !== initial.optedOut?.length
  ) {
    return false;
  }

  const sameOptedIn = initial.optedIn.every((acc) =>
    curr.optedIn.includes(acc),
  );

  const sameOptedOut = initial.optedOut.every((acc) =>
    curr.optedOut.includes(acc),
  );

  return sameOptedIn && sameOptedOut;
};

const TaxModal = ({ showAccounts = false }: Props) => {
  const dispatch = useDispatch();
  const accounts: Account[] = useSelector(getAllAccountsList);
  const activeAccountId: Account = useSelector(getActiveAccountId);

  const statuses: EStatementStatus[] = useSelector(
    getAllAccountsEstatementStatus,
  );
  const loadingStatuses: boolean = useSelector((state) =>
    createLoadingSelector([GET_ALL_ACCOUNTS_ESTATEMENT_STATUS])(state),
  );
  const [showAccountList, setShowAccountList] = useState(showAccounts);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Store initial Opt in Status of all accounts
  const initialStatus = useMemo(() => getOptedInStatus(statuses), [statuses]);
  // Store new Opt inStatus
  const [optInStatus, setOptInStatus] = useState(initialStatus);

  const addAccount = (acc: Account) =>
    setOptInStatus({
      optedIn: [...optInStatus.optedIn, acc.accountId],
      optedOut: optInStatus.optedOut.filter((a) => a !== acc.accountId),
    });

  const removeAccount = (acc: Account) =>
    setOptInStatus({
      optedIn: optInStatus.optedIn.filter((a) => a !== acc.accountId),
      optedOut: [...optInStatus.optedOut, acc.accountId],
    });

  const isOptedIn = useCallback(
    (acc: Account) => optInStatus.optedIn.some((a) => a === acc.accountId),
    [optInStatus],
  );

  const optIn = () => setShowAccountList(true);

  const hasNewSettings = useMemo(
    () => !optInStatusEquals(initialStatus, optInStatus),
    [optInStatus, initialStatus],
  );

  const isDisabled = () => {
    if (showAccountList) return !hasNewSettings;
    return false;
  };

  useEffect(() => {
    dispatch(fetchAllAccountsEStatementsStatus());
  }, []);

  useEffect(() => {
    setOptInStatus(getOptedInStatus(statuses));
  }, [statuses]);

  const saveOptedInAccounts = async () => {
    const diff = getOptedInStatusDiff(initialStatus, optInStatus);
    try {
      await setEStatementStatusPromise(
        {
          accounts: [
            ...diff.optedIn.map((acc) => ({
              accountId: acc,
              taxEstatements: true,
            })),
            ...diff.optedOut.map((acc) => ({
              accountId: acc,
              taxEstatements: false,
            })),
          ],
        },
        dispatch,
      );
      // If there's an active account - refetch the status for it so the toggle
      // is updated
      if (activeAccountId)
        await dispatch(
          fetchEStatementsStatus({
            urlParams: {
              accountId: activeAccountId,
            },
          }),
        );
      setShowSuccessMessage(true);
      hideTaxEStatementsModal();
    } catch (error) {
      dispatch(hideModal());
    }
  };

  return (
    <>
      <Modal.Content>
        <Title>Opt-in now for Tax e-Statements</Title>
        {!showAccountList && (
          <PromptWrapper>
            <Text>
              Enable Tax e-Statements and go paperless. You will be able to
              retrieve your tax statements from the Reports/Statements section
              in your member portal. The opt-in process will take less than a
              minute.
            </Text>
          </PromptWrapper>
        )}
        {showAccountList && !showSuccessMessage && (
          <>
            <SubTitle>
              Select the accounts you want to enable for Tax e-Statements
            </SubTitle>
            {loadingStatuses && (
              <StyledSkeleton
                variant="rect"
                animation="wave"
                width={'50%'}
                height={120}
              />
            )}
            {!loadingStatuses && (
              <Accounts>
                <Separator />
                {accounts.map((acc: Account) => (
                  <>
                    <AccountItem>
                      <Checkbox
                        checked={isOptedIn(acc)}
                        name={acc.name}
                        onChange={(e, v) => {
                          const isAdding = get(v, 'checked');
                          if (isAdding) {
                            addAccount(acc);
                          } else {
                            removeAccount(acc);
                          }
                        }}
                      />
                      <p>{`${acc.label} ${acc.name}`}</p>
                    </AccountItem>
                    <Separator />
                  </>
                ))}
              </Accounts>
            )}
          </>
        )}
        {showSuccessMessage && (
          <>
            <IconWrapper>
              <CheckCircle name="check circle" size="huge" />
            </IconWrapper>
            <Text>
              You have opt-in to receive your Tax statements electronically!
            </Text>
          </>
        )}
      </Modal.Content>
      <Buttons>
        <Button
          onClick={() => {
            hideTaxEStatementsModal();
            dispatch(hideModal());
          }}
          text={showSuccessMessage ? 'Continue' : 'Not Now'}
        />
        {!showSuccessMessage && (
          <Button
            primary
            onClick={showAccountList ? saveOptedInAccounts : optIn}
            text={showAccountList ? 'SAVE' : 'OPT-IN'}
            disabled={isDisabled()}
          />
        )}
      </Buttons>
    </>
  );
};

export default TaxModal;
