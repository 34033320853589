import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import Routes from '../routes/index';
import { initGA, initGTM } from '../common/tracking';
import EnvPromise from '../config/env';
import createTheme from '../styles/createTheme';

const theme = createTheme();

const AppContainer = () => {
  useEffect(() => {
    EnvPromise.then(({ trackingId, gtmId }) => {
      if (trackingId) {
        initGA(trackingId);
      }
      console.log('APP CONTAINER');
      if (gtmId) {
        console.log('has GTM ID and initializes GTM', gtmId);
        initGTM(gtmId);
      }
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Routes />
      </React.Fragment>
    </ThemeProvider>
  );
};

export default AppContainer;
