import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import StyledButton from './StyledButton';
import history from '../constants/history';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.accent};
  font-size: 3 rem;
`;

const Text = styled.p`
  font-size: 1.2em;
  text-align: center;
  color: ${({ theme }) => theme.text.subheader};
`;

const Button = styled(StyledButton)`
  width: 105px;
  height: 40px;
`;

type Props = {
  header: string;
  message: string;
};

const GoHome = ({ header, message }: Props) => {
  return (
    <Wrapper>
      <div className="logo-container">
        <div className="small-logo" alt="ErisX" />
      </div>
      <Header>{header}</Header>
      <Text>{message}</Text>
      <Button text="Go Home" primary onClick={() => history.push('/home')} />
    </Wrapper>
  );
};

export default GoHome;
