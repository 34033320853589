import React, { useState } from 'react';
import get from 'lodash/get';
import { MenuItem } from '@material-ui/core';
import { SubAction } from './types';
import {
  TableActionButtonProps,
  TableActionTooltip,
  ActionButton,
  SubActionsMenu,
} from './TableActions';

export const TableActionButton = ({
  action,
  options = {},
}: TableActionButtonProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // Manually control tooltip state so it doesnt overlap with menu
  const [showTooltip, setShowTooltip] = useState(false);

  const menuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const subActions: SubAction[] = get(action, 'subActions', []);
  const hasSubActions = subActions.length > 0;
  const openTooltip = () => !menuOpen && setShowTooltip(true);

  return (
    <TableActionTooltip
      title={action.tooltip || ''}
      open={showTooltip}
      onOpen={() => {
        if (menuOpen) setShowTooltip(false);
      }}
    >
      <div
        onMouseEnter={openTooltip}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <ActionButton
          name={action.icon}
          onClick={
            hasSubActions
              ? handleOpenMenu
              : () => {
                  if (options && options[action.name]) {
                    options[action.name]?.onClick?.();
                  } else {
                    action?.onClick?.();
                  }
                }
          }
          key={`table_action_${action.name}`}
        />
        <SubActionsMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(menuOpen)}
          onClose={handleClose}
          disableAutoFocusItem
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {hasSubActions &&
            subActions.map((subAction: SubAction) => (
              <MenuItem
                onClick={() => {
                  if (options && options[subAction.name]) {
                    options[subAction.name]?.onClick?.();
                  } else {
                    subAction?.onClick?.();
                  }
                  handleClose();
                }}
              >
                {subAction.text}
              </MenuItem>
            ))}
        </SubActionsMenu>
      </div>
    </TableActionTooltip>
  );
};
