import React from 'react';
import get from 'lodash/get';
import { getCellSelectOptions, isValidDepth } from './utils/utils';
import TableInputTypes from '../../ts/enums/TableInputTypes.enum';
import ExpandCell from './ExpandCell';
import { TableInput } from './styled';
import StyledSelect, { StyledSelectOption } from '../StyledSelect';
import { ColumnCreationOptions, DefaultCellProps } from './types';

const NewRowCell = <T,>({
  columnDef,
  depth,
  key,
  isExpandable,
  row,
  parentRows,
  getValue,
  value,
  hasBeenEdited,
  updateTableData,
  onChange,
  inputProps,
  setValue,
  options,
  selectedOption,
  showValue,
}: DefaultCellProps<T>) => {
  const createOptions: ColumnCreationOptions<T> = get(
    columnDef,
    'meta.createOptions',
    null,
  );
  const lowerCreateBound = get(createOptions, 'depthLowerBound', null);
  const upperCreateBound = get(createOptions, 'depthUpperBound', null);
  const validDepth = isValidDepth(depth, lowerCreateBound, upperCreateBound);
  if (!validDepth) return null;

  if (createOptions && validDepth) {
    switch (createOptions.component) {
      case TableInputTypes.Input:
        return (
          <>
            {isExpandable && (
              <ExpandCell
                key={`${key}_expand`}
                row={row}
                getValue={getValue}
                depth={get(columnDef, 'meta.expandOptions.depth', 0)}
                hidden
                showValue={showValue}
              />
            )}
            <TableInput
              key={key}
              name={key}
              value={value as string}
              autoComplete="off"
              onChange={onChange}
              onBlur={hasBeenEdited ? updateTableData : () => {}}
              changed={hasBeenEdited}
              {...inputProps}
            />
          </>
        );

      case TableInputTypes.Select:
        return (
          <>
            {isExpandable && (
              <ExpandCell
                key={`${key}_expand`}
                row={row}
                getValue={getValue}
                depth={get(columnDef, 'meta.expandOptions.depth', 0)}
                hidden
                showValue={showValue}
              />
            )}
            <StyledSelect
              key={key}
              placeholder={get(createOptions, 'placeholder', '')}
              options={options as StyledSelectOption[]}
              value={selectedOption}
              onChange={(option) => {
                setValue(option.value);
              }}
              active={hasBeenEdited}
            />
          </>
        );
      default:
        break;
    }
  }

  return getValue() || null;
};

export default NewRowCell;
