import { ChangeEvent } from 'react';

export enum TableActionName {
  ClearFilters = 'clearFilters',
  Filters = 'filter',
  Refetch = 'refetch',
  Export = 'export',
}

export type SubAction = {
  name: TableActionName;
  text: string;
  onClick?: Function;
};

export type TableAction = {
  name: TableActionName;
  icon: string;
  onClick?: Function;
  tooltip?: string;
  subActions?: SubAction[];
};

export type TableActionTooltipProps = {
  title: string;
  children: JSX.Element;
  open?: boolean;
  onOpen?: ((event: ChangeEvent<{}>) => void) | undefined;
};
