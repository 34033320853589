import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Table, RowData } from '@tanstack/react-table';
import colors from '../../../constants/colors';
import { Menu } from '@material-ui/core';
import { TableAction, TableActionName, TableActionTooltipProps } from './types';
import { TableActionButton } from './TableActionButton';
import getTableActions from './getTableActions';

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  color: ${({ theme }) => theme.table.quickActions.color};
`;

export const ActionButton = styled(Icon)<{ active: boolean }>`
  cursor: pointer;
  margin: 0px 10px !important;
  color: ${({ active }) => (active ? colors.GREEN : '')};
`;

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1em',
    backgroundColor: `${colors.GRAY_2}`,
    color: `${colors.NAVY}`,
    '& .MuiTooltip-arrow': {
      color: `${colors.GRAY_2}`,
    },
  },
});

export const TableActionTooltip = ({
  title,
  children,
  open,
  onOpen,
}: TableActionTooltipProps) => {
  const classes = useStyles();
  return (
    <Tooltip
      disableFocusListener
      arrow
      title={title}
      leaveTouchDelay={400}
      classes={{ tooltip: classes.tooltip }}
      open={open}
      onOpen={onOpen}
    >
      {children}
    </Tooltip>
  );
};

export type TableActionButtonProps = {
  /** TableAction with action name, text and onClick callback*/
  action: TableAction;
  /** Maps a TableActionName with Optional overridas such as a custom onClick*/
  options?: Partial<Record<TableActionName, { onClick?: Function }>>;
};

export const SubActionsMenu = styled(Menu)`
  margin-top: 50px;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.table.quickActions.menu.bg};
    color: ${({ theme }) => theme.table.quickActions.menu.color};
  }
  .MuiListItem-button:hover {
    background-color: ${({ theme }) => theme.table.quickActions.menu.bgHover};
  }
`;
type TableActionsProps<T> = {
  /** List of TableActionNames that will be hidden*/
  hide?: TableActionName[];
  /** Table Instance*/
  table: Table<T>;
  /** Maps a TableActionName with optional overrides, such as a custom onClick */
  options?: Partial<Record<TableActionName, { onClick?: Function }>>;
};

const TableActions = <T,>({
  hide = [],
  table,
  options = {},
}: TableActionsProps<T>) => {
  const actions: TableAction[] = useMemo(
    () =>
      getTableActions(table).filter((action) => !hide.includes(action.name)),
    [hide, table],
  );

  return (
    <Wrapper>
      {actions.map((action) => (
        <TableActionButton
          action={action}
          key={`table_action_${action.name}`}
          options={options}
        />
      ))}
    </Wrapper>
  );
};

export default TableActions;
