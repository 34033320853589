import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import TextButton from '../common/TextButton';
import colors from '../constants/colors';
import styled from 'styled-components';
import { AuthorizedBlockTraderRow } from '../ts/models/BlockTrades/PermissionManagement.model';
import InformationTooltip from '../common/InformationTooltip';

const columnHelper = createColumnHelper<AuthorizedBlockTraderRow>();

const StatusCell = styled.div`
  display: flex;
`;

const Tooltip = styled.span`
  margin-left: 2px;
`;

export const permissionManagementMetadata = (
  onDelete: (authorizedTraderEmail: string) => void,
) =>
  [
    columnHelper.accessor('authorizedTraderEmail', {
      id: 'authorizedTrader',
      header: (
        <span>
          Authorized Trader
          <Tooltip>
            <InformationTooltip
              content={
                <React.Fragment>
                  Users that you have given permission to submit block trades on
                  your behalf.
                </React.Fragment>
              }
            />
          </Tooltip>
        </span>
      ),
      minSize: 120,
    }),
    columnHelper.display({
      id: 'action',
      header: 'Action',
      cell: (props) => {
        return props.row?.original?.authorizedTraderEmail ? (
          <StatusCell>
            <TextButton
              text="Delete"
              onClick={() => onDelete(props.row.original.authorizedTraderEmail)}
              fontSize="14"
              color={colors.RED_ERROR}
            />
          </StatusCell>
        ) : null;
      },
    }),
  ] as ColumnDef<AuthorizedBlockTraderRow>[];
