import ACCOUNT_ORIGINS from '../../../constants/accountOrigins';

export const CUSTOMER_ORDER_CAPACITY_OPTIONS = [
  { key: 1, value: 1, text: '1' },
  { key: 2, value: 2, text: '2' },
  { key: 3, value: 3, text: '3' },
  { key: 4, value: 4, text: '4' },
];

export const ACCOUNT_TYPE_OPTIONS = [
  {
    key: ACCOUNT_ORIGINS.CUSTOMER,
    value: ACCOUNT_ORIGINS.CUSTOMER,
    text: 'Customer',
  },
  {
    key: ACCOUNT_ORIGINS.HOUSE,
    value: ACCOUNT_ORIGINS.HOUSE,
    text: 'House',
  },
];

export const SIDE_OPTIONS = [
  {
    value: 'BUY',
    text: 'BUY',
  },
  {
    value: 'SELL',
    text: 'SELL',
  },
];
