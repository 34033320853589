import { takeLatest, put } from 'redux-saga/effects';
import { hideModal } from 'erisxkit/client';
import { createSaga } from '../utils/createSaga';
import { reset } from 'redux-form';
import * as actions from '../reducers/internalTransfersReducer';
import { TRANSFER_CONFIRMATION_MODAL } from '../constants/modalTypes';
import { INTERNAL_TRANSFERS_FORM } from '../constants/internalTransfersConstants';

function* onTransferRequestEnded() {
  yield put(hideModal(TRANSFER_CONFIRMATION_MODAL));
  yield put(reset(INTERNAL_TRANSFERS_FORM));
}

export default function* watch() {
  yield takeLatest(
    actions.createTransferRequest.TRIGGER,
    createSaga(
      actions.createTransferRequest,
      actions.createTransferRequest._PREFIX,
      'Submitting transfer request...',
      'Your Transfer request has been submitted',
    ),
  );
  yield takeLatest(
    actions.createTransferRequest.SUCCESS,
    onTransferRequestEnded,
  );
  yield takeLatest(
    actions.createTransferRequest.FAILURE,
    onTransferRequestEnded,
  );
}
