export const PENDING = 'pending';
export const COMPLIANCE = 'compliance';
export const COMPLIANCE_REVIEW = 'compliance_review';
export const COMPLIANCE_SUBMITTED = 'compliance_submitted';

export const KYC = 'kyc';
export const RESUBMIT_KYC = 'resubmit_kyc';
export const DISCLOSURES_AND_DISCLAIMERS = 'disclosures_and_disclaimers';
export const AGREEMENT = 'agreement';
export const SECURITY = 'security';
export const FUNDING = 'funding';

export const FUNDING_KEY_SKIPPED = 'funding_key_skipped';
export const FUNDING_KEY_STORED = 'funding_key_stored';
export const PENDING_MARC_REVIEW = 'pending_marc_review';
export const MARC_APPROVED = 'marc_approved';
export const MARC_REJECTED = 'marc_rejected';
export const PENDING_PII_REVIEW = 'pending_pii_review';

export const PENDING_ACCOUNT = 'pending_account';
export const ACCOUNT_LINKED = 'account_linked';
export const EMARKET_ASSOCIATED = 'emarket_associated';
export const CX_ASSOCIATED = 'cx_associated';
export const CQG_ASSOCIATED = 'cqg_associated';

export const PII_SUBMITTED = 'pii_submitted';

export const ONBOARDED = 'onboarded';
export const REJECTED = 'rejected';

// firm specific
export const FIRM_SETUP = 'firm_setup';
export const FIRM_FUNDING = 'firm_funding';
export const FIRM_SECURITY = 'firm_security';

//futures
export const FUTURES_UPGRADE_COMPLIANCE = 'futures_upgrade_compliance';
export const FUTURES_UPGRADE_AGREEMENTS =
  'futures_upgrade_agreements_and_disclosures';
export const FUTURES_UPGRADE_PENDING_ACCOUNT =
  'futures_upgrade_pending_account';
export const FUTURES_UPGRADE_PENDING_REVIEW = 'futures_upgrade_pending_review';
export const FUTURES_UPGRADE_ACCOUNT_LINKED = 'futures_upgrade_account_linked';
export const FUTURES_UPGRADE_EMARKET_ASSOCIATED =
  'futures_upgrade_emarket_associated';
export const FUTURES_UPGRADE_UPGRADE_REJECTED = 'futures_upgrade_rejected';

export const ONBOARDED_STATES = [
  ONBOARDED,
  FUTURES_UPGRADE_COMPLIANCE,
  FUTURES_UPGRADE_AGREEMENTS,
  FUTURES_UPGRADE_PENDING_ACCOUNT,
  FUTURES_UPGRADE_PENDING_REVIEW,
  FUTURES_UPGRADE_ACCOUNT_LINKED,
  FUTURES_UPGRADE_EMARKET_ASSOCIATED,
  FUTURES_UPGRADE_UPGRADE_REJECTED,
];
