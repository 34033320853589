import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Modal, List, Header } from 'semantic-ui-react';

export default ({ onConfirm, onRetry, times }) => (
  <Fragment>
    <Modal.Content scrolling>
      <Header>
        We were unable to automatically process your ID. Here are some tips to
        get a better picture:
      </Header>
      <List bulleted>
        <List.Item>Place ID close to device</List.Item>
        <List.Item>Ensure sufficient light</List.Item>
        <List.Item>Hold Device steady</List.Item>
        <List.Item>Make sure all edges of the ID are visible</List.Item>
        <List.Item>
          Make sure there are no glares or shadows on the ID
        </List.Item>
      </List>
      <p>
        {!isMobile ? (
          <Fragment>
            For the quickest onboarding experience, we recommend continuing your
            onboarding on{' '}
            <a href="https://clearing.erisx.com">https://clearing.erisx.com</a>{' '}
            from your mobile device.
          </Fragment>
        ) : (
          ''
        )}
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button secondary onClick={onRetry} content="Try Again" />
      <Button primary onClick={() => onConfirm()} content="Continue Anyway" />
    </Modal.Actions>
  </Fragment>
);
