import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Moment from 'moment';
import { BankAssetAccount } from '../ts/models/AssetAccounts/BankAssetAccount.model';

const columnHelper = createColumnHelper<BankAssetAccount>();

export const bankAccountsMetadata = [
  columnHelper.accessor(
    (row) => get(row, 'label', <span className="italic">(No label)</span>),
    {
      id: 'label',
      header: 'Label',
      minSize: 80,
    },
  ),
  columnHelper.accessor('bankName', {
    id: 'bankName',
    header: 'Bank Name',
    minSize: 60,
  }),
  columnHelper.accessor((row) => `******${row.accountNumber}`, {
    id: 'accountNumber',
    header: 'Account Number (last four digits)',
    minSize: 185,
  }),
  columnHelper.accessor('routingNumber', {
    id: 'routingNumber',
    header: 'Routing Number',
    minSize: 60,
  }),
  columnHelper.accessor(
    (row) =>
      !isEmpty(row['createdAt'])
        ? (new Moment(row['createdAt']).format('LL, LTS Z') as string)
        : '',
    {
      id: 'createdAt',
      header: 'Date Added',
    },
  ),
  columnHelper.accessor(
    ({ state }) => (
      <div className={`status ${state}`}>
        {state?.charAt(0)?.toUpperCase() + state?.slice(1)}
      </div>
    ),
    {
      header: 'Status',
      id: 'status',
      maxSize: 100,
      minSize: 70,
    },
  ),
] as ColumnDef<BankAssetAccount>[];

export default bankAccountsMetadata;
