import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatFiat } from '../../utils/methods';
import { USD } from '../../constants/assetTypes';
import usePriceChange from '../../hooks/usePriceChange';
import { getOnPriceChangeStyle } from '../../components/SimpleAssetBalances';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import colors from '../../constants/colors';
import StyledButton from '../StyledButton';
import { fadeIn } from '../../styles/effects';
import { ellipseDecimalsAfterNDigits } from '../../utils/ellipseDecimalsAfterNDigits';
import EnvPromise from '../../config/env';

const cardStyles = makeStyles({
  root: {
    background: `${colors.MEDIUM_NAVY}`,
    width: '100%',
    height: '100%',
    padding: '0px 5px',
    color: 'unset',
    borderLeft: `5px solid ${colors.GREEN}`,
    '&:last-child': {
      paddingBottom: '0px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const contentStyles = makeStyles({
  root: {
    padding: '0px',
    width: '100%',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
});

const CardContainer = styled.div`
  width: 220px;
  height: 120px;
  margin: 10px 20px 10px 0px;
  @media (max-width: 490px) {
    width: 100%;
  }
`;
CardContainer.displayName = 'CardContainer';

const Symbol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  margin-left: 12px;
  min-width: 52px;
  justify-content: center;
  span {
    &:after {
      background-size: 100%;
      height: 40px;
      width: 40px;
    }
  }
`;
Symbol.displayName = 'Symbol';

const Currency = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin: 0px;
`;
Currency.displayName = 'Currency';

const Values = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 10px;
`;
Values.displayName = 'Values';

const Value = styled.div`
  display: flex;
  flex-direction: column;
`;
Value.displayName = 'Value';

const PriceLabel = styled.p`
  font-size: 15px;
  text-align: left;
  margin-bottom: 0px;
`;
PriceLabel.displayName = 'PriceLabel';

const Price = styled.p`
  font-size: 22px;
  text-align: left;
`;
Price.displayName = 'Price';

const QuantityLabel = styled.p`
  font-size: 13px;
  text-align: left;
  margin-bottom: 0px;
`;
QuantityLabel.displayName = 'QuantityLabel';

const Quantity = styled.p`
  font-size: 14px;
  text-align: left;
`;
Quantity.displayName = 'Quantity';

const TradeOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${fadeIn};
`;
TradeOptionsContainer.displayName = 'TradeOptionsContainer';

const TradeOptionsCurrency = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    display: flex;
    &:after {
      margin-left: 0px;
      margin-bottom: 2px;
      background-size: unset;
    }
  }
`;
TradeOptionsCurrency.displayName = 'TradeOptionsCurrency';

const TradeButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
TradeButtons.displayName = 'TradeButtons';

const TradeButton = styled(StyledButton)`
  width: 45%;
  height: 30px;
`;

const CardInfo = styled.div`
  display: flex;
  ${fadeIn}
`;

const BUY_TAB_INDEX = 0;
const SELL_TAB_INDEX = 1;

const CurrencyCard = ({
  closingBalance,
  price,
  currency,
  assetType,
  onClick,
}) => {
  const card = cardStyles();
  const content = contentStyles();
  const { symbol } = currency;
  const priceChange = usePriceChange(price);
  const priceRef = useRef();
  const [enableTradeWidget, setEnableTradeWidget] = useState('');

  const [showTradeOptions, setShowTradeOptions] = useState(false);
  const isUSD = assetType === 'USD';

  const showOptions = () => {
    if (!isUSD && enableTradeWidget === 'true') setShowTradeOptions(true);
  };
  const hideOptions = () => {
    if (!isUSD && enableTradeWidget === 'true') setShowTradeOptions(false);
  };

  useEffect(() => {
    EnvPromise.then((env) => setEnableTradeWidget(env.enableTradeWidget));
  }, []);

  // remove animation from price tag in case it decreases/increases many times in a row
  const onPriceAnimationEnd = () => {
    priceRef.current.className.replaceAll('flash-price-up', '');
    priceRef.current.className.replaceAll('flash-price-down', '');
  };

  const currencyPrice = price ? formatFiat(price.toString(), USD) : '-';

  return (
    <CardContainer onMouseEnter={showOptions} onMouseLeave={hideOptions}>
      <Card className={card.root} key={assetType} elevation={10}>
        <CardContent className={content.root}>
          {showTradeOptions && (
            <TradeOptionsContainer>
              <TradeOptionsCurrency>
                <span className={`crypto-icon-${symbol}`} />
                <Currency>{`${symbol} - ${currencyPrice}`}</Currency>
              </TradeOptionsCurrency>
              <TradeButtons>
                <TradeButton
                  text="BUY"
                  primary
                  onClick={() => onClick(assetType, BUY_TAB_INDEX)}
                />
                <TradeButton
                  text="SELL"
                  secondary
                  onClick={() => onClick(assetType, SELL_TAB_INDEX)}
                />
              </TradeButtons>
            </TradeOptionsContainer>
          )}
          {!showTradeOptions && (
            <CardInfo>
              <Symbol>
                <span className={`crypto-icon-${symbol}`} />
                <Currency>{symbol}</Currency>
              </Symbol>
              <Values>
                <Value>
                  <PriceLabel>Price</PriceLabel>
                  <Price
                    className={getOnPriceChangeStyle(priceChange)}
                    ref={priceRef}
                    onAnimationEnd={() => onPriceAnimationEnd(priceRef)}
                  >
                    {currencyPrice}
                  </Price>
                </Value>
                <Value>
                  <QuantityLabel>Quantity</QuantityLabel>
                  <Quantity>
                    {ellipseDecimalsAfterNDigits(closingBalance, 4)}
                  </Quantity>
                </Value>
              </Values>
            </CardInfo>
          )}
        </CardContent>
      </Card>
    </CardContainer>
  );
};

CurrencyCard.defaultProps = {
  onClick: () => {},
  currency: {
    symbol: '',
    description: '',
    isFiat: '',
  },
};

CurrencyCard.propTypes = {
  closingBalance: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isFiat: PropTypes.bool.isRequired,
  }),
  onClick: PropTypes.func,
};

export default CurrencyCard;
