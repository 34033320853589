import React, { PureComponent } from 'react';
import { Form, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isEmpty, upperFirst } from 'lodash';
import {
  fundsDesignation as fundsDesignationConstants,
  renderDropdown,
  NON_SEG,
  normalization,
  showModal,
  hideModal,
  createLoadingSelector,
} from 'erisxkit/client';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import * as rules from '../../common/forms/rules';
import { renderField } from '../../common/forms/components';
import AssetTypeField from '../../common/components/AssetTypeField';
import AddBankAccountPlaidContainer from '../../containers/LinkedAccounts/AddBankAccountPlaidContainer';
import { WITHDRAWAL_CONFIRM } from '../../constants/modalTypes';
import payMethods from '../../constants/payMethods';
import AvailableFundsHeader from './AvailableFundsHeader';
import { WITHDRAWAL } from '../../constants/journalTypes';
import {
  ACH_WITHDRAWAL_LIMIT,
  getAchWithdrawalLimit,
} from '../../reducers/fundTransfersReducer';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import WithdrawDisclaimer from '../../common/components/WithdrawDisclaimer';

import decimalPlacesOf from '../../utils/decimalPlacesOf';
import hasMoreThanNDigits from '../../utils/hasMoreThanNDigits';
import { DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES } from './constants';
import { grants, subjects } from '../../constants/userPermissions';
import AccessControl from '../../common/AccessControl';

export class AchWithdrawal extends PureComponent {
  constructor(props) {
    super(props);
    this.limitRule = rules.maxValue(props.achWithdrawalLimit.available);
  }

  componentDidMount = () => {
    const { currencyOptions, initialize, change, activeAccountFD } = this.props;
    // select only given option by default, if applicable
    if (currencyOptions.length === 1) {
      // set default values
      const assetType = currencyOptions[0].value;
      const fundsDesignation = fundsDesignationConstants.find(
        (fd) => fd.value === activeAccountFD,
      )?.value;
      initialize({ assetTypeWithFundsDesignation: assetType });
      change('assetType', assetType);
      change('fundsDesignation', fundsDesignation);
      this.props.fetchLimit(assetType, fundsDesignation);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.achWithdrawalLimit.available !==
      this.props.achWithdrawalLimit.available
    ) {
      this.limitRule = rules.maxValue(this.props.achWithdrawalLimit.available);
    }
  }

  confirmFiatDeposit = (req) => {
    req.linkedMemberAssetAccountId = req.linkedBankAccount.id;
    this.props.showModal(WITHDRAWAL_CONFIRM, {
      type: 'fiat',
      payMethod: payMethods.ACH,
    });
  };

  render = () => {
    const {
      accountCode,
      achWithdrawalLimit,
      change,
      currencyOptions,
      firmCode,
      handleSubmit,
      linkedBankAccounts,
      valid,
      type = {},
      loadingLimits,
    } = this.props;

    const disclaimer = achWithdrawalLimit?.embargoPeriod
      ? `Funds that were deposited via ACH are held for ${achWithdrawalLimit?.embargoPeriod} days before being available for withdraw`
      : `Funds that were deposited via ACH may not be available for withdrawal`;

    return (
      <section className="overlay-form ui text container">
        <Header as="h2">
          {`ACH ${upperFirst(type.action)} from account `}{' '}
          <span className="bold">{`${
            firmCode ? `${firmCode}-` : ''
          }${accountCode}`}</span>
          <Header.Subheader>
            <AvailableFundsHeader
              amountLimit={achWithdrawalLimit}
              action={WITHDRAWAL}
              isFiat
              disclaimer={disclaimer}
              loadingLimits={loadingLimits}
            />
          </Header.Subheader>
        </Header>
        <Form onSubmit={handleSubmit(this.confirmFiatDeposit)}>
          {currencyOptions.length !== 1 && (
            <Form.Field>
              <AssetTypeField
                change={change}
                currencyOptions={currencyOptions}
                postChange={this.props.fetchLimit}
              />
            </Form.Field>
          )}
          <Form.Field>
            <Field
              component={renderDropdown}
              label="Bank Account:"
              placeholder="Select bank account to withdraw to"
              fluid
              required
              name="linkedBankAccount"
              options={linkedBankAccounts}
              validate={rules.required}
              data-cy="ach-withdrawal-account-selector"
              id="ach-withdrawal-account-selector"
            />
            <AccessControl
              allowedPermissions={[
                `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
              ]}
            >
              <AddBankAccountPlaidContainer link />
            </AccessControl>
          </Form.Field>
          <Field
            component={renderField}
            label="Amount:"
            name="amount"
            placeholder="Enter the amount you would like to withdraw"
            parse={normalization.floatsOnly}
            required
            disabled={loadingLimits}
            onChange={(event, amount) => {
              if (
                hasMoreThanNDigits(amount, DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES)
              ) {
                event.preventDefault();
              }
            }}
            validate={[rules.required, this.limitRule, rules.positiveNumbers]}
          />
          <WithdrawDisclaimer />
          <IraAccountDisclaimer />
          <div className="ach-confirm">
            <Form.Button
              primary
              content="Submit Withdrawal"
              disabled={!valid || loadingLimits}
              data-cy="ach-withdrawal-submit-withdrawal"
            />
          </div>
        </Form>
      </section>
    );
  };
}

AchWithdrawal.propTypes = {
  accountCode: PropTypes.string,
  accountsLoading: PropTypes.bool,
  achWithdrawalLimit: PropTypes.objectOf(PropTypes.any),
  assetType: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  bankDetails: PropTypes.func.isRequired,
  bankDetailsList: PropTypes.objectOf(PropTypes.string),
  bankDetailsLoading: PropTypes.bool,
  change: PropTypes.func.isRequired,
  createFiatDepositRequest: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  firmCode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  linkedBankAccounts: PropTypes.arrayOf({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  linkedMemberAssetAccountId: PropTypes.string,
  fundsDesignation: PropTypes.string,
  referenceCode: PropTypes.func.isRequired,
  refCode: PropTypes.string,
  refCodeLoading: PropTypes.bool,
  valid: PropTypes.bool,
  activeAccountFD: PropTypes.string
};

AchWithdrawal.defaultProps = {
  accountCode: '',
  accountsLoading: false,
  achWithdrawalLimit: {},
  assetType: '',
  bankDetailsList: {},
  bankDetailsLoading: false,
  currencyOptions: [],
  firmCode: '',
  linkedBankAccounts: [],
  linkedMemberAssetAccountId: '',
  fundsDesignation: '',
  refCode: '',
  refCodeLoading: false,
  valid: false,
  activeAccountFD: ''
};

const selector = formValueSelector('BankTransfers');

const AchWithdrawalForm = reduxForm({
  form: 'BankTransfers',
})(AchWithdrawal);

export default connect(
  (state) => ({
    achWithdrawalLimit: getAchWithdrawalLimit(state),
    linkedMemberAssetAccountId: selector(state, 'linkedMemberAssetAccountId'),
    assetType: selector(state, 'assetType'),
    fundsDesignation: selector(state, 'fundsDesignation'),
    loadingLimits: createLoadingSelector([ACH_WITHDRAWAL_LIMIT])(state),
  }),
  { showModal, hideModal },
)(AchWithdrawalForm);
