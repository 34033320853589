// todo: define all aspects of theme
import { createMuiTheme } from '@material-ui/core/styles';
import {
  DARK_NAVY,
  GREEN,
  LIGHT_GRAY,
  LIGHT_NAVY,
  MEDIUM_NAVY,
  WHITE,
} from '../constants/layout';

const createTheme = () =>
  createMuiTheme({
    typography: {
      fontFamily: ['Apercu'].join(','),
      h2: {
        fontSize: 36,
      },
      h3: {
        fontSize: 32,
        // h3 tag defaults to uppercase mono
        fontFamily: 'Apercu !important',
        textTransform: 'none',
      },
      h4: {
        fontSize: 24,
        // h4 tag defaults to gray
        color: `${WHITE} !important`,
      },
    },
    palette: {
      primary: {
        light: LIGHT_NAVY,
        main: MEDIUM_NAVY,
        dark: DARK_NAVY,
      },
      secondary: {
        main: GREEN,
      },
      text: {
        primary: WHITE,
        disabled: LIGHT_GRAY,
      },
      background: {
        paper: MEDIUM_NAVY,
      },
      action: {
        selected: GREEN,
        hover: GREEN,
        disabled: LIGHT_GRAY,
      },
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: WHITE,
          '&:hover': {
            // todo: define this opacity somewhere
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            color: MEDIUM_NAVY,
          },
          '&:hover': {
            color: MEDIUM_NAVY,
          },
        },
      },
      MuiSelect: {
        select: {
          // note that for this element the pseudo
          // selector is :focus, not :focused
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          color: WHITE,
        },
      },
      MuiIconButton: {
        root: {
          color: WHITE,
        },
      },
      MuiInputLabel: {
        root: {
          color: WHITE,
          '&$focused:not($error)': {
            color: WHITE,
          },
        },
      },
      MuiInputAdornment: {
        root: {
          color: WHITE,
          '& svg': {
            color: WHITE,
          },
        },
      },
      MuiInput: {
        underline: {
          // light gray outline by default
          '&:after': {
            borderBottom: `1px solid ${LIGHT_GRAY}`,
          },
          '&:before': {
            borderBottom: `1px solid ${LIGHT_GRAY}`,
          },
          '&:hover:not($disabled):before': {
            borderBottom: `1px solid ${GREEN}`,
            '@media(hover:none)': {
              borderBottom: `1px solid ${GREEN}`,
            },
          },
          // green outline when focused
          '&$focused:after': {
            borderBottom: `1px solid ${GREEN}`,
          },
          '&$error:after': {
            // todo: ensure that theme.palette.error.main is the right red
            // borderBottomColor: theme.palette.error.main,
          },
          '&$disabled:before': {
            // todo: ensure disabled color is correct
            // borderBottom: `1px solid ${???}`,
          },
        },
      },
    },
  });

export default createTheme;
