import stringEntropy from 'fast-password-entropy';
import Big from 'bignumber.js';
import { get } from 'lodash';
import moment from 'moment';
import {
  INVALID_DATE,
  INVALID_ZIP_CODE,
  REQUIRED_FIELD,
  TOO_OLD,
  TOO_YOUNG,
  INVALID_PHONE_NUMBER,
  INVALID_CIDR_IP,
  INVALID_SSN,
} from '../../constants/messages';
import {
  MAX_USER_AGE,
  MIN_USER_AGE,
  YEARS_TIME_UNIT,
} from '../../constants/datetime';
import { normalization } from 'erisxkit/client';

export const required = (value) => (value ? undefined : REQUIRED_FIELD);

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const exactLength = (length) => (value) =>
  value && value.length !== length ? `Must be ${length} characters` : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const zipcode = (value) =>
  value && !/^\d{5}?$/i.test(value) ? INVALID_ZIP_CODE : undefined;

export const phoneNumber = (value) =>
  value && !/^\d{10}?$/i.test(value) ? INVALID_PHONE_NUMBER : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const cidrIP = (value) =>
  value &&
  !/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)([/][0-3][0-2]?|[/][1-2][0-9]|[/][0-9])?$/.test(
    value,
  )
    ? INVALID_CIDR_IP
    : undefined;

export const ssn = (value) =>
  value &&
  !/^(?!(.)\1+$)(?!9)(?!000)(?!666)(?!...00)(?!.....0000)(?!012345678)(?!123456789)(?!987654321)(?!098765432)[0-9]{9}/.test(
    value,
  )
    ? INVALID_SSN
    : undefined;

// validates list of comma separated emails
export const emailList = (values) => {
  if (!values) return 'Invalid email address';
  const emails = values.replaceAll(' ', '').split(',');
  const emailAmount = emails.length;
  const hasInvalidEmail = emails
    .map((e) => email(e))
    .some((result) => result !== undefined);
  if (hasInvalidEmail) {
    if (emailAmount === 1) return 'Invalid email address';
    if (emailAmount > 1) return 'At least one email provided is invalid';
  }
  return undefined;
};

export const ssnMatch = (ssn2, values) => {
  const ssn1 = values.ssn;
  return ssn2 && ssn2 !== ssn1 ? "SSNs don't match" : undefined;
};

export const passwordMatch = (value, allValues) =>
  value && value !== allValues.password ? "Passwords don't match" : undefined;

export const passwordStrength = (value) =>
  stringEntropy(value) < 60
    ? 'This password is too weak, please use a stronger password.'
    : undefined;

export const balanceExceeded = (
  value,
  allValues,
  { balances, assetType = '' },
) =>
  Big(value).gt(Big(get(balances, assetType.toLowerCase())))
    ? 'This amount exceeds your current balance.'
    : undefined;

export const availableLessFeeExceeded = (
  value,
  allValues,
  { achWithdrawalLimit, assetType = '', feeParams },
) => {
  const amount = Big(value);
  const avail = Big(achWithdrawalLimit.available);
  const feeParam = feeParams[assetType] || {};
  let fee = amount
    .times(Big(feeParam.scaledParam))
    .plus(Big(feeParam.flatParam));
  if (fee.lt(Big(feeParam.minFee))) fee = Big(0);
  return amount.plus(fee).gt(avail)
    ? 'This amount plus fee exceeds your available funds.'
    : undefined;
};

export const twoDecimalPlaces = (value) => {
  if (value % 1 !== 0) {
    return value.toString().split('.')[1].length > 2
      ? 'Please enter a dollar amount, indicating dollars and cents.'
      : undefined;
  }
  return undefined;
};

export const dobFormat = (value) =>
  !moment(value, 'YYYY-MM-DD', true).isValid() ? INVALID_DATE : undefined;
export const maxDob = (value) =>
  !moment(value).isBefore(moment().subtract(MIN_USER_AGE, YEARS_TIME_UNIT))
    ? TOO_YOUNG
    : undefined;
export const minDob = (value) =>
  !moment(value).isAfter(moment().subtract(MAX_USER_AGE, YEARS_TIME_UNIT))
    ? TOO_OLD
    : undefined;

export const maxValue = (max) => (value) =>
  value && Big(value).gt(Big(max)) ? `Value cannot exceed ${max}` : undefined;

export const minValue = (min) => (value) =>
  value && Big(value).lt(Big(min))
    ? `Value is below minimum ${min}`
    : undefined;

export const positiveNumbers = (value) => {
  const cleanMaskValue = value?.replace('$', '')?.replace(/,/g, '');
  return cleanMaskValue && !Big(cleanMaskValue).isPositive()
    ? `Value must be a positive number`
    : undefined;
};

export const transferLimitExceeded = (value, limit) =>
  Big(value).gt(Big(limit))
    ? 'This amount exceeds your available balance.'
    : undefined;

export const achWithdrawalLimitExceeded = (
  value,
  allValues,
  { achWithdrawalLimit = {} },
) => {
  const available = get(achWithdrawalLimit, 'available', 0);

  return Big(value).gt(Big(available))
    ? 'This amount exceeds your withdrawal limit.'
    : undefined;
};

export const stringToInteger = (value) => {
  const _value = normalization.numbersOnly(value);
  return new Big(_value).integerValue().toNumber();
};

export const carCgmName = (value) => {
  return value && !/^[a-zA-Z0-9]{1,12}$/i.test(value)
    ? 'Must be alphanumeric with 12 characters max. Spaces not supported.'
    : undefined;
};

export const alphanumeric = (value) => {
  return value && !/^[a-zA-Z0-9]+$/i.test(value)
    ? 'Must be alphanumeric.'
    : undefined;
};