import React, { Fragment, PureComponent } from 'react';
import { Icon, Modal, Button, Divider } from 'semantic-ui-react';
import Dropzone from './Dropzone';
import WebcamCapture from './WebcamCapture';
import { getBase64 } from '../utils/methods';

class FileUpload extends PureComponent {
  state = {
    webcam: false,
    file: '',
  };

  onFileDrop = (files) => {
    // save the file in redux
    getBase64(files[0]).then((encoded) => {
      this.setState({ file: encoded });
    });
  };

  onCapture = (image) => {
    this.setState({ file: image });
  };

  webcam = () => {
    // save the file in redux
    this.setState({ webcam: true });
  };

  renderDropzone = () =>
    this.props.dropzone && <Dropzone onDrop={this.onFileDrop} />;

  renderOr = () => {
    if (this.props.dropzone && this.props.webcam) {
      return <Divider vertical>Or</Divider>;
    }
    return '';
  };

  renderWebcam = () => {
    if (this.props.dropzone && this.props.webcam && !this.state.webcam) {
      return (
        <Button primary id="picture" onClick={this.webcam}>
          <Icon name="user" /> Take Picture
        </Button>
      );
    }
    if (this.props.webcam || this.state.webcam) {
      return <WebcamCapture onCapture={this.onCapture} />;
    }

    return '';
  };

  render = () => (
    <Fragment>
      <Modal.Content>
        <div className="flex">
          {!this.state.webcam && this.renderDropzone()}
          {!this.state.webcam && this.renderOr()}
          {this.renderWebcam()}
        </div>
      </Modal.Content>
      {this.state.file && (
        <Modal.Actions>
          <Button
            primary
            id="confirm"
            onClick={() =>
              this.props.onConfirm(this.state.file, this.props.type)
            }
          >
            <Icon name="up arrow" /> Confirm
          </Button>
        </Modal.Actions>
      )}
    </Fragment>
  );
}
export default FileUpload;
