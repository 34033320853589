import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import StepWizard from 'react-step-wizard';
import moment from 'moment';
import { Step, Icon } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import Personal from '../../components/onBoarding/Personal';
import ComplianceQuestionsContainer from './ComplianceQuestionsContainer';
import SecurityQuestionsContainer from './SecurityQuestionsContainer';
import DisclosuresAndDisclaimersContainer from './DisclosuresAndDisclaimersContainer';
import AgreementContainer from './AgreementContainer';

import ReviewPII from './ReviewPII';
import history from '../../constants/history';
import {
  getPII,
  updatePIIStore,
  isEligibleLocation,
  getLocationEligiblity,
  getLoggedInUser,
  fetchUser,
  updatePiiPromiseCreator,
  fetchUserPromiseCreator,
} from '../../reducers/userReducer';
import VerificationIndex from '../../containers/onBoarding/VerificationIndex';
import CreateFundingPasswordContainer from '../CreateFundingPasswordContainer';
import * as appStates from '../../constants/appStates';
import * as userStates from '../../constants/userStates';
import * as onBoardingSteps from '../../constants/onBoardingSteps';
import { stepsInfo, getStepIcon } from './WelcomeContainer';
import ProcessInformation from '../../components/onBoarding/ProcessInformation';
import Footer from '../../components/onBoarding/Footer';
import { FOOTER_HEIGHT, FOOTER_MARGIN } from '../../constants/layout';

const styles = {
  container: {
    paddingBottom: FOOTER_HEIGHT,
    marginBottom: FOOTER_MARGIN,
  },
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      updatePIIStore,
      isEligibleLocation,
      fetchUser,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      updatePiiPromiseCreator,
      fetchUserPromiseCreator,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  pii: getPII(state),
  user: getLoggedInUser(state),
  personalValues: _.get(state, ['form', 'personal', 'values'], {}),
  locationValues: _.get(state, ['form', 'location', 'values'], {}),
});

const getStep = (appState, userState) => {
  switch (userState) {
    case userStates.KYC:
    case userStates.RESUBMIT_KYC:
      switch (appState) {
        case appStates.COMPLIANCE:
        case appStates.COMPLIANCE_SUBMITTED:
          return onBoardingSteps.COMPLIANCE;
        case appStates.PENDING_PII:
          return onBoardingSteps.PROCESS_INFORMATION;
        case appStates.KBA1:
        case appStates.KBA2:
        case appStates.FACE_ID_IMAGES:
        case appStates.ID_IMAGES:
        case appStates.IMAGES_SUBMITTED:
        case appStates.APPROVED:
          return onBoardingSteps.VERIFICATION;
        case appStates.PENDING_PII_REVIEW:
        case appStates.PENDING_REVIEW:
        case appStates.REJECTED:
        default:
          history.push('/welcome');
          break;
      }
      break;
    case userStates.DISCLOSURES_AND_DISCLAIMERS:
    case userStates.AGREEMENT:
      return onBoardingSteps.DISCLOSURES;
    case userStates.SECURITY:
    case userStates.FIRM_SECURITY:
      return onBoardingSteps.SECURITY;
    case userStates.FUNDING:
    case userStates.FIRM_FUNDING:
    case userStates.FUNDING_KEY_STORED:
    case userStates.FUNDING_KEY_SKIPPED:
    case userStates.PENDING_ACCOUNT:
    case userStates.EMARKET_ASSOCIATED:
    case userStates.CX_ASSOCIATED:
    case userStates.CQG_ASSOCIATED:
    case userStates.PII_SUBMITTED:
    case userStates.ACCOUNT_LINKED:
      return onBoardingSteps.FUNDING;
    case userStates.COMPLIANCE_REVIEW:
    case userStates.PENDING_MARC_REVIEW:
    case userStates.PENDING_PII_REVIEW:
    case userStates.REJECTED:
      history.push('/welcome');
      break;
    case userStates.ONBOARDED:
    case userStates.MARC_APPROVED:
      history.push('/home');
      break;
    case userStates.PENDING:
    case userStates.FIRM_SETUP:
      history.push('/pending');
      break;
    default:
      break;
  }
};

const NavStep = ({ info }) => (
  <Step className={info.stepState}>
    <Icon.Group>
      <Icon name={info.icon} size="large" />
      <Icon corner="bottom right" name={getStepIcon(info)} />
    </Icon.Group>
    <Step.Content>
      <Step.Title>{info.title}</Step.Title>
    </Step.Content>
  </Step>
);

const Nav = ({ user, ...rest }) => (
  <div className="nav">
    <Step.Group unstackable>
      {stepsInfo(user.appState, user.state).map((info, index) => (
        <NavStep info={info} key={info.key} {...rest} />
      ))}
    </Step.Group>
  </div>
);

class RegistrationSteps extends Component {
  componentDidMount = () => {
    const { isEligibleLocation, pii } = this.props;
    if (pii.locationState) {
      isEligibleLocation(pii.locationState);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (!_.isEqual(nextProps.pii.locationState, this.props.pii.locationState)) {
      this.props.isEligibleLocation(nextProps.pii.locationState);
    }
  };

  updateLocation = () => {
    this.props.updatePii(this.props.locationValues);
  };

  updatePersonal = ({ nextStep }) => {
    const { personalValues, user } = this.props;
    this.props
      .updatePiiPromiseCreator({
        ...personalValues,
        country: 'US',
        termsAcceptedTime: _.get(user, 'termsAcceptedTime', undefined),
      })
      .then(() => {
        this.props.fetchUserPromiseCreator().then(() => nextStep());
      });
  };

  scrollToContainerTop = () => {
    const containerClassName = 'pushable';
    // the first container with this class is the
    // container that has the scroll mechanism
    document.getElementsByClassName(containerClassName)[0].scrollTop = 0;
  };

  render() {
    return (
      <div style={styles.container}>
        <StepWizard
          nav={<Nav user={this.props.user} />}
          initialStep={getStep(this.props.user.appState, this.props.user.state)}
          onStepChange={this.scrollToContainerTop}
          isLazyMount
        >
          <ProcessInformation />
          <Personal />
          <ReviewPII
            personalNext={this.updatePersonal}
            hashKey="review"
            initialValues={{
              ...this.props.pii,
              termsAccepted: !!_.get(this.props, ['user', 'termsAcceptedTime']),
            }}
          />
          <VerificationIndex />
          <ComplianceQuestionsContainer />
          <DisclosuresAndDisclaimersContainer />
          <SecurityQuestionsContainer />
          <CreateFundingPasswordContainer />
        </StepWizard>
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSteps);
