import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import StyledButton from '../../../common/StyledButton';

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
`;

const Register = styled(StyledButton)`
  width: 270px;
`;
export { Actions, Register };
