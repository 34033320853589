import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { hideModal, showModal } from 'erisxkit/client';
import { FUNDING_PASSWORD } from '../constants/modalTypes';

const mapDispatchToProps = {
  dispatchHideModal: hideModal,
  dispatchShowModal: showModal,
};

const ForgotFundingPasswordModal = ({
  dispatchShowModal,
  dispatchHideModal,
}) => {
  const onBack = () => {
    dispatchHideModal();
    dispatchShowModal(FUNDING_PASSWORD);
  };

  return (
    <Fragment>
      <Modal.Content>
        <p className="text-center">
          If you don&apos;t remember your funding password, contact{' '}
          <a href="mailto:digital.clientservice@cboe.com">
            digital.clientservice@cboe.com
          </a>{' '}
          <span className="nowrap">(+1 (888) 782-7481 ext. 5)</span> to reset
          it.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button primary id="confirm" onClick={onBack}>
          Back
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

ForgotFundingPasswordModal.propTypes = {
  dispatchShowModal: PropTypes.func.isRequired,
  dispatchHideModal: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ForgotFundingPasswordModal);
