import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import StyledButton from '../../common/StyledButton';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.table.footer.bg};
  display: flex;
  justify-content: space-between;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  border-top: 1px solid #0000001a;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.table.footer.text};
`;

const PaginationButton = styled(StyledButton)`
  flex-grow: 1;
`;

const Info = styled.div`
  flex: 2;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const PageJump = styled.span`
  font-size: 14px;
`;

const Input = styled.input`
  width: 50px;
  border: 1px solid #0000001a;
  background: ${colors.WHITE};
  padding: 5px 0px 7px 0px;
  margin: 0px 5px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
  text-align: center;
`;

type Props = {
  prevDisabled: boolean;
  onClickPrev: Function;
  nextDisabled: boolean;
  onClickNext: Function;
  currentPage: number;
  pageCount: number;
  defaultPage: number;
  onGoToPage: Function;
};

/*
  EMPTable8's pagination footer.
  Table type (local/server side) agnostic.
*/
const Footer = ({
  prevDisabled,
  onClickPrev,
  nextDisabled,
  onClickNext,
  currentPage,
  pageCount,
  defaultPage,
  onGoToPage,
}: Props) => {
  return (
    <Wrapper>
      <PaginationButton
        text="Previous"
        primary
        onClick={onClickPrev}
        disabled={prevDisabled}
      />
      <Info>
        <PageJump>
          Page
          <Input
            type="number"
            defaultValue={defaultPage}
            value={currentPage}
            onChange={onGoToPage}
            className="border p-1 rounded w-16"
          />
          {`of ${pageCount}`}
        </PageJump>
      </Info>
      <PaginationButton
        text="Next"
        primary
        onClick={onClickNext}
        disabled={nextDisabled}
      />
    </Wrapper>
  );
};

export default Footer;
