import get from 'lodash/get';
import { createSelector } from 'reselect';
import { fundsDesignation, NON_SEG } from 'erisxkit/client';
import ACCOUNT_ORIGINS from '../constants/accountOrigins';
import { FCM_MEMBER_TYPE } from '../constants/memberTypes';
import { getIsMarginEnabled } from './envReducer';
import { filterFundsDesignationByPermissions } from '../utils/permissions';
/** Base Selectors */
const getAccountsList = (state) => get(state, 'accountList', {});
const getUser = (state) => get(state, 'user', {});

/** Composed selectors */
// Account
const getAllAccounts = createSelector([getAccountsList], (accountList) =>
  get(accountList, 'accounts', {}),
);
const getSelectedAccountId = createSelector([getAccountsList], (accountsList) =>
  get(accountsList, 'selectedAccountId', ''),
);
const getAllAccountsList = createSelector([getAllAccounts], (allAccounts) =>
  Object.values(allAccounts),
);
// User
const getLoggedInUserMemberLinks = createSelector([getUser], (user) =>
  get(user, 'memberLinks', []),
);
const getLoggedInUserHasFcmMember = createSelector(
  [getLoggedInUserMemberLinks],
  (memberLinks) =>
    memberLinks.some((member) => [FCM_MEMBER_TYPE].includes(member.memberType)),
);
const getIsLoggedInUserFCM = createSelector(
  [getLoggedInUserHasFcmMember, getIsMarginEnabled],
  (hasFcmMember, isMarginEnabled) => hasFcmMember && isMarginEnabled,
);

const getAccountIdFromURL = () => {
  const match = /accounts\/([-A-Za-z0-9]*)/.exec(window.location.href);
  if (!match) return '';
  return match[1];
};

/* If there is no selectedAccountId, we check if current
  uri has an :account_id or if the user has a single account to default to that */
export const getActiveEMPAccount = createSelector(
  [getAllAccounts, getAllAccountsList, getSelectedAccountId],
  (accountsMap, accountsList, selectedAccountId) => {
    const idFromUrl = getAccountIdFromURL();
    // If there's no selected account id
    if (!selectedAccountId) {
      // If user is on a uri with :account_id param use that
      if (idFromUrl) return accountsMap[idFromUrl];
      // If the user is not and they have a single account, use that
      if (accountsList.length === 1) return accountsList[0];
    }
    return accountsMap[selectedAccountId];
  },
);
/* Criterion for FCM is different. If there is no selectedAccountId
   We get the first account that's a customer account */
export const getActiveFCMAccount = createSelector(
  [getSelectedAccountId, getAllAccounts, getAllAccountsList],
  (selectedAccountId = '', accountsMap = {}, accountsList = []) => {
    if (!selectedAccountId) {
      return accountsList.find(
        (acc) => acc.origin === ACCOUNT_ORIGINS.CUSTOMER,
      );
    }

    return accountsMap[selectedAccountId];
  },
);

export const getActiveAccount = (state) =>
  getIsLoggedInUserFCM(state)
    ? getActiveFCMAccount(state)
    : getActiveEMPAccount(state);

export const getOnlyAccount = createSelector(
  [getAllAccountsList],
  (accountsList) => (accountsList.length === 1 ? accountsList[0] : false),
);

export const getActiveOrOnlyAccount = createSelector(
  [getActiveAccount, getOnlyAccount],
  (activeAccount, onlyAccount) => activeAccount || onlyAccount,
);
export const getActiveAccountId = createSelector(
  [getActiveAccount],
  (account) => get(account, 'accountId', ''),
);
export const getActiveOrFirstAccount = createSelector(
  [getActiveAccount, getAllAccountsList],
  (activeAccountId, accounts) =>
    activeAccountId ? activeAccountId : get(accounts, '[0]', null),
);
export const getActiveOrFirstAccountLabel = createSelector(
  [getActiveOrFirstAccount],
  (acc) => get(acc, 'label', ''),
);

export const getActiveOrFirstAccountFirmCode = createSelector(
  [getActiveOrFirstAccount],
  (acc) => get(acc, 'firmCode', ''),
);

export const getActiveOrFirstAccountId = createSelector(
  [getActiveOrFirstAccount],
  (acc) => get(acc, 'accountId', ''),
);
export const getActiveAccountLabel = createSelector(
  [getActiveAccount],
  (account) => get(account, 'label', ''),
);
export const getActiveAccountMemberId = createSelector(
  [getActiveAccount],
  (account) => get(account, 'memberId', ''),
);

export const getActiveAccountFD = createSelector(
  [getActiveAccount],
  (account) => get(account, 'fundsDesignation', ''),
);

export const getActiveOrDefaultAccountFD = createSelector(
  [getActiveAccount],
  (account) =>
    get(account, 'fundsDesignation', fundsDesignation[NON_SEG].value),
);

export const getFundsDesignationsForActiveAccount = createSelector(
  [getActiveAccount],
  (account) => filterFundsDesignationByPermissions(account),
);
