import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal, Form } from 'semantic-ui-react';
import { showModal, hideModal } from 'erisxkit/client';
import sign from 'eris-ecdsa';
import { reduxForm, Field, getFormValues } from 'redux-form';
import get from 'lodash/get';
import StyledButton from '../../common/StyledButton';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';

import {
  addAuthorizedIPPromiseCreator,
  fetchAuthorizedIPs,
  fetchIpAuthorizationHistory,
} from '../../reducers/authorizedIPsReducer';
import {
  ADD_IP_ADDRESS_MODAL,
  FUNDING_PASSWORD,
} from '../../constants/modalTypes';
import { getCurrentAuthId, getUserMemberIds } from '../../reducers/userReducer';

const Button = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 10px auto;
  text-align: center;
  padding-top: 10px;
`;

const StyledForm = styled(Form)`
  width: 100%;
`;
StyledForm.displayName = 'StyledForm';

const ModalActions = styled(Modal.Actions)`
  background- color: ${({ theme }) => theme.body} !important;
`;

const StyledField = styled(Field)`
  label {
    color: ${({ theme }) => theme.text.header + '!important'};
  }
`;

export const ADD_IP_ADDRESS_FORM_NAME = 'addIPAddress';

const AddIPAddress = ({ valid }) => {
  const dispatch = useDispatch();
  const authId = useSelector(getCurrentAuthId);
  const memberIds = useSelector(getUserMemberIds);

  const formValues = useSelector((state) =>
    getFormValues(ADD_IP_ADDRESS_FORM_NAME)(state),
  );
  const ipAddress = get(formValues, 'ipAddress', '');
  const comment = get(formValues, 'comment', '');

  const hideModals = () => {
    dispatch(hideModal(ADD_IP_ADDRESS_MODAL));
    dispatch(hideModal(FUNDING_PASSWORD));
  };
  const onAddIPAddress = (password) => {
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const msg = [authId, ipAddress, comment, Date.now().toString()];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
    const payload = {
      ipAddress,
      comment,
      msg,
      sig,
    };
    addAuthorizedIPPromiseCreator(payload, dispatch)
      .then(() => {
        // Refetch IP Authorization History and Authorized IPs List
        dispatch(fetchAuthorizedIPs({ memberIds }));
        dispatch(fetchIpAuthorizationHistory({ memberIds }));
        hideModals();
      })
      .catch(() => hideModal());
  };

  const onContinue = () =>
    dispatch(
      showModal(FUNDING_PASSWORD, {
        submit: onAddIPAddress,
      }),
    );

  return (
    <Fragment>
      <Modal.Content scrolling>
        <Header>ADD IP ADDRESS</Header>
        <StyledForm onSubmit={onContinue}>
          <StyledField
            component={renderField}
            validate={[rules.required, rules.cidrIP]}
            name="ipAddress"
            label="IP Address:"
            placeholder="Enter a single IP address or CIDR block"
            required
          />
          <StyledField
            component={renderField}
            validate={[]}
            name="comment"
            label="Comment"
            placeholder="Add Note"
          />
        </StyledForm>
      </Modal.Content>
      <ModalActions>
        <Row>
          <Button onClick={() => dispatch(hideModal())} text="Cancel" />
          <Button
            primary
            text="Continue"
            type="submit"
            disabled={!valid}
            onClick={onContinue}
          />
        </Row>
      </ModalActions>
    </Fragment>
  );
};

AddIPAddress.defaultProps = {
  valid: false,
};

AddIPAddress.propTypes = {
  valid: PropTypes.bool,
};

const AddIPAddressModal = reduxForm({
  form: ADD_IP_ADDRESS_FORM_NAME,
})(AddIPAddress);

export default AddIPAddressModal;
