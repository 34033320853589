import React, { Component, Fragment } from 'react';
import { Modal, Button, Input, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideModal, showModal } from 'erisxkit/client';
import { trackEvent } from '../common/tracking';
import gaCategories from '../constants/ga-categories';
import { FORGOT_FUNDING_PASSWORD } from '../constants/modalTypes';

const mapDispatchToProps = {
  hideModal,
  showModal,
};

class FundingPasswordContainer extends Component {
  state = {
    loading: false,
    password: '',
  };

  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    this.setState(change);
  };

  onForgotPassword = () => {
    this.props.hideModal();
    this.props.showModal(FORGOT_FUNDING_PASSWORD);
  };

  render = () => (
    <Fragment>
      <Modal.Header>Please confirm your funding password:</Modal.Header>
      <Modal.Content>
        <Input
          name="password"
          type="password"
          fluid
          onChange={this.handleChange}
          data-cy="funding-password-input"
        />
      </Modal.Content>
      <Modal.Actions>
        <a className="bold" onClick={this.onForgotPassword}>
          Forgot funding password?
        </a>
        <Button secondary id="cancel" onClick={this.props.hideModal}>
          Cancel
        </Button>
        <Button
          primary
          id="confirm"
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={() => {
            trackEvent(gaCategories.FUNDING_PASSWORD, 'submit');
            this.setState({ loading: true }, () =>
              this.props.submit(this.state.password, this.props.payload),
            );
          }}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  );
}

FundingPasswordContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  payload: PropTypes.objectOf(PropTypes.any),
  submit: PropTypes.func.isRequired,
};

FundingPasswordContainer.defaultProps = {
  payload: {},
};

export default connect(null, mapDispatchToProps)(FundingPasswordContainer);
