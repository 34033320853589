import _ from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import KBAIndex from './KBAIndex';
import IdentificationIndex from './IdentificationIndex';
import * as appStates from '../../constants/appStates';
import { getLoggedInUser } from '../../reducers/userReducer';

const VerificationIndex = ({ appState, nextStep, goToStep }) => (
  <Fragment>
    {appState === appStates.KBA1 || appState === appStates.KBA2 ? (
      <KBAIndex />
    ) : (
      <IdentificationIndex
        appState={appState}
        parentNextStep={nextStep}
        goToStep={goToStep}
      />
    )}
  </Fragment>
);

export default connect((state) => ({
  appState: _.get(getLoggedInUser(state), 'appState', ''),
}))(VerificationIndex);
