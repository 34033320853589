import React, { Fragment } from 'react';
import { Header, Input, Form } from 'semantic-ui-react';
import BchMessage from '../../common/components/BchMessage';

const AddCryptoAddress = ({
  handleChange,
  options,
  activeAssetType,
  assetType,
}) => (
  <Fragment>
    <Header> Add Crypto Address </Header>
    <Form>
      <Form.Field>
        <Input
          placeholder="Label/Nickname"
          type="text"
          name="label"
          onChange={handleChange}
          data-cy="add-crypto-address-label"
        />
      </Form.Field>
      <Form.Dropdown
        placeholder="Currency Type"
        fluid
        selection
        options={options}
        name="assetType"
        onChange={handleChange}
        defaultSelected={activeAssetType}
        data-cy="add-crypto-address-asset-type"
      />
      <Form.Field>
        <Input
          placeholder="Address"
          type="text"
          name="address"
          onChange={handleChange}
          data-cy="add-crypto-address-address-field"
        />
      </Form.Field>
      <BchMessage
        assetType={assetType}
        message="Cboe Digital requires Bitcoin Cash Addresses to be entered in the legacy format."
      />
    </Form>
  </Fragment>
);

export default AddCryptoAddress;
