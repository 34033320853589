import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';

//* actions */
export const AUTHORIZED_IPS = 'AUTHORIZED_IPS';
export const fetchAuthorizedIPs = createRoutine(AUTHORIZED_IPS);
export const IP_AUTHORIZATION_HISTORY = 'IP_AUTHORIZATION_HISTORY';
export const fetchIpAuthorizationHistory = createRoutine(
  IP_AUTHORIZATION_HISTORY,
);
export const addAuthorizedIP = createRoutine('ADD_AUTHORIZED_IP');
export const removeAuthorizedIP = createRoutine('REMOVE_AUTHORIZED_IP');

//* Promise actions */
export const addAuthorizedIPPromiseCreator = promisifyRoutine(addAuthorizedIP);
export const removeAuthorizedIPPromiseCreator =
  promisifyRoutine(removeAuthorizedIP);

//* Initial State */
const initialState = {
  authorizedIps: [],
  ipAuthorizationHistory: [],
};

//* Reducer */
export default handleActions(
  {
    [fetchAuthorizedIPs.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchIpAuthorizationHistory.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);

//* Selectors */
export const getAuthorizedIPs = (state) =>
  get(state, 'authorizedIPs.authorizedIps', []);

export const getIPAuthorizationHistory = (state) =>
  get(state, 'authorizedIPs.ipAuthorizationHistory', []);
