import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../constants/colors';
import {
  isActiveAccountOfType,
  hasAnAccountOfType,
} from '../../reducers/accountsReducer';

const Wrapper = styled.div`
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: 0px;
  margin-right: 0px;
`;
Wrapper.displayName = 'Wrapper';

const Text = styled.p`
  && {
    color: ${colors.NAVY_LIGHT_TEXT};
    font-size: ${(props) => props.fontSize};
  }
`;
Text.displayName = 'Text';

//Account types where the disclaimer must be shown
const ACCOUNT_TYPES = ['IRA', 'IRS', 'IRL', 'IRAL', 'IRAS'];

const IraAccountDisclaimer = ({
  marginTop,
  marginBottom,
  fontSize,
  showForActiveAccount,
  showForAnyAccount,
  useAllAccounts,
}) => {
  //For sections where there isn't a specific active account (e.g. linked-accounts ) we check if at least
  //one of the accounts is IRA, IRS or IRL
  const displayCriterion = useAllAccounts
    ? showForAnyAccount
    : showForActiveAccount;
  return displayCriterion ? (
    <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
      <Text fontSize={fontSize}>DISCLAIMER</Text>
      <Text fontSize={fontSize}>
        For IRA accounts, by proceeding with a deposit or withdrawal you confirm
        that (1) neither Eris Clearing nor Eris Exchange are acting in any
        fiduciary capacity and did not advise you on the deposit or withdrawal
        activity in your account, (2) you have carefully considered IRS
        Publication 590-A, particularly regarding prohibited transactions, and
        you are aware that engaging in a prohibited transaction could result in
        tax consequences and/or penalties, and (3) you maintain all necessary
        record keeping to comply with state and federal requirements related to
        this deposit or withdrawal.
      </Text>
    </Wrapper>
  ) : null;
};

IraAccountDisclaimer.defaultProps = {
  marginTop: '20px',
  marginBottom: '20px',
  fontSize: '14px',
  showForActiveAccount: false,
  showForAnyAccount: false,
  useAllAccounts: false,
};

IraAccountDisclaimer.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  fontSize: PropTypes.string,
  showForActiveAccount: PropTypes.bool,
  showForAnyAccount: PropTypes.bool,
  useAllAccounts: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  showForActiveAccount: isActiveAccountOfType(state, ACCOUNT_TYPES),
  showForAnyAccount: hasAnAccountOfType(state, ACCOUNT_TYPES),
});

export default connect(mapStateToProps, null)(IraAccountDisclaimer);
