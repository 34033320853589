import React, { Fragment, Component } from 'react';
import { Table, Modal, Icon, Input, Popup } from 'semantic-ui-react';
import BankTransactionDetails from '../../containers/History/BankTransactionDetailsContainer';

const timeoutLength = 1500;

const externalBlockchainExplorers = {
  TBTC: 'https://testnet.smartbit.com.au/tx/',
  TETH: 'https://kovan.etherscan.io/tx/',
  TLTC: 'http://explorer.litecointools.com/tx/',
  TBCH: 'https://explorer.bitcoin.com/tbch/tx/',
  // BTC:
  // ETH:
  // LTC:
  // BCH:
};

const assetMovementTableData = (row) => [
  { key: 'Block ID', value: row.blockchainId },
  { key: 'Status', value: row.state },
  { key: 'Description', value: row.description },
  { key: 'Timestamp', value: row.time },
];

const tradesTableData = (row) => [
  { key: 'EXCH Code', value: row.contractSymbol },
  { key: 'CLG Code', value: row.contractCode || row.contractSymbol },
  { key: 'TCR ID', value: row.tcrId },
  { key: 'Fix ID', value: row.fixId },
  { key: 'Exchange Fee', value: row.exchangeFees },
  { key: 'Clearing Fee', value: row.clearingFees },
  { key: 'Status', value: row.state },
  { key: 'Description', value: row.description },
  { key: 'Timestamp', value: row.time },
  { key: 'CTI', value: row.cti },
  { key: 'Origin', value: row.origin },
];

const needsBankDetails = (row) => {
  if (row.product === 'USD' && row.state === 'posted') {
    return row.txType === 'deposit' || row.txType === 'withdrawal';
  }
  return false;
};

class TransactionInformationModal extends Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, timeoutLength);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  copyAddress = () => {
    /* Get the text field */
    const copyText = document.getElementById('address');
    /* Select the text field */
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.handleOpen();
  };

  showContent = (key, value) => {
    if (key === 'Block ID' || key === 'TCR ID') {
      return (
        <Fragment>
          <Input
            readOnly
            id="address"
            value={value}
            onClick={this.copyAddress}
          />
          <Popup
            trigger={
              <Icon link name="copy outline" onClick={this.copyAddress} />
            }
            content="Copied!"
            on="click"
            open={this.state.isOpen}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            position="bottom right"
            pointing
            inverted
          />
        </Fragment>
      );
    }
    return value;
  };

  renderBodyRow = ({ key, value }, i) => {
    if (key === 'Block ID' || key === 'TCR ID') {
      if (!value) return '';
    }
    return {
      key: key || `row-${i}`,
      cells: [
        key,
        value
          ? {
              key: 'value',
              content: this.showContent(key, value),
              textAlign: 'right',
            }
          : { content: '—', textAlign: 'right' },
      ],
    };
  };

  render = () => {
    const { data, type } = this.props;
    let tableData = {};
    if (type === 'trade') {
      tableData = tradesTableData(data);
    }
    if (type === 'asset_movement') {
      tableData = assetMovementTableData(data);
    }
    return (
      <Fragment>
        <Modal.Header> Transaction </Modal.Header>
        <Modal.Content>
          <Table
            basic="very"
            renderBodyRow={this.renderBodyRow}
            tableData={tableData}
            className="tx-details-table"
          />
          {needsBankDetails(data) ? <BankTransactionDetails {...data} /> : null}
        </Modal.Content>
      </Fragment>
    );
  };
}

export default TransactionInformationModal;
