import Big from 'bignumber.js';
import { List, Dropdown } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import { isEmpty, get } from 'lodash';
import {
  status,
  getType,
  getTimeColumn,
  TRADE_TYPES,
} from '../common/metadata/commonFields';
import { iconColorForState, iconForType, calculateBps } from '../utils/methods';

// check is a row is a spot trade
// if the type is buy or sell, AND notional exists, it's a spot trade
export const isSpotTrade = (txType, notional) =>
  !!(TRADE_TYPES.includes(txType) && notional);
const getAccountLabel = (postingSummary, accounts) => {
  const accountId =
    postingSummary.length > 0 ? postingSummary[0]?.accountId || '' : '';
  const selectedAccount = accounts?.find(
    (account) => get(account, 'accountId') === accountId,
  );
  const accountLabel =
    selectedAccount?.firmCode + '-' + selectedAccount?.accountCode;
  return (selectedAccount && accountLabel) || '';
};
// metadata is a function such that it can be mobile responsive
const accountHistoryHome = (mobile, assetTypes, filter, accounts) => [
  {
    Header: '',
    columns: [
      getTimeColumn(),
      {
        Header: 'Account',
        id: 'accountLabel',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ accountLabel = '', postingSummary = {} }) =>
          isEmpty(accountLabel)
            ? getAccountLabel(postingSummary, accounts)
            : accountLabel,
      },
      {
        Header: () => {
          let options = [
            {
              symbol: 'Show All',
              value: '',
            },
          ];
          options = options.concat(assetTypes);
          return (
            <Fragment>
              <span> Product &nbsp; &nbsp; &nbsp; &nbsp;</span>
              <Dropdown pointing text="" icon="filter">
                <Dropdown.Menu>
                  {options.map((option) => (
                    <Dropdown.Item
                      key={option.symbol}
                      text={option.symbol}
                      onClick={filter}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Fragment>
          );
        },
        id: 'product',
        headerClassName: 'pull-left',
        accessor: 'product',
      },
      getType(mobile),
      {
        Header: 'Amount',
        id: 'amount',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'amount',
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'quantity',
      },
      {
        Header: 'Price',
        id: 'price',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'price',
      },
      {
        Header: 'Notional',
        id: 'notional',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'notional',
      },
      {
        Header: 'Clearing Fee',
        id: 'clearingFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ clearingFee = '', feeType = '' }) =>
          !isEmpty(clearingFee)
            ? `${Big(clearingFee).toFixed()} ${feeType}`
            : '',
      },
      {
        Header: 'Exchange Fee',
        id: 'exchangeFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ exchangeFee = '', feeType = '' }) =>
          !isEmpty(exchangeFee)
            ? `${Big(exchangeFee).toFixed()} ${feeType}`
            : '',
      },
      {
        Header: 'Other Fees',
        id: 'otherFees',
        accessor: ({ otherFees = '', feeType = '' }) =>
          !isEmpty(otherFees) ? `${Big(otherFees).toFixed()} ${feeType}` : '',
        headerClassName: 'pull-left',
      },
      {
        Header: '% Fee',
        id: 'percentageFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          clearingFee = 0,
          deliveryFee = 0,
          exchangeFee = 0,
          notional = 0,
          txType,
        }) => {
          if (isSpotTrade(txType, notional)) {
            const totalFee = Big(clearingFee || 0)
              .plus(deliveryFee || 0)
              .plus(exchangeFee || 0)
              .toFixed();
            const percentageFee = calculateBps(totalFee, notional);
            return `${percentageFee} %`;
          }
          return '-';
        },
      },
      {
        Header: 'Total ',
        id: 'total',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          amount = 0,
          notional = 0,
          exchangeFee = 0,
          clearingFee = 0,
          otherFees = 0,
        }) =>
          Big(amount)
            .plus(Big(notional))
            .plus(Big(exchangeFee).plus(Big(clearingFee).plus(Big(otherFees))))
            .toFixed(),
      },
      {
        id: 'icon',
        accessor: ({ txType, state }) =>
          mobile ? (
            <List.Icon
              name={iconForType(txType)}
              size="large"
              color={iconColorForState(state, txType)}
            />
          ) : null,
      },
      status,
    ],
  },
];

export default accountHistoryHome;
