import React from 'react';
import Tabs from '../../common/Tabs';
import Page from '../../common/Page';
import ClearingRequests from './ClearingRequests/ClearingRequests';
import ApprovedParticipants from './ApprovedParticipants/ApprovedParticipants';

const panes = [
  {
    menuItem: 'Clearing Requests',
    render: () => <ClearingRequests />,
  },
];

const ParticipantAccountManagement = () => {
  return (
    <Page header="Participant Account Management">
      <Tabs panes={panes} />
      <ApprovedParticipants />
    </Page>
  );
};

export default ParticipantAccountManagement;
