import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { XTable, createLoadingSelector } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  subComponentMetadata,
  futuresPositionsMetadata,
} from '../../metadata/futuresPositionsMetadata';
import {
  fetchPositions,
  POSITIONS,
  getValidPositionsWithDetails,
} from '../../reducers/positionsReducer';
import {
  fetchContracts,
  getContractsBySymbol,
} from '../../reducers/contractsReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';

const mapStateToProps = (state) => ({
  contractsBySymbol: getContractsBySymbol(state),
  positionsList: getValidPositionsWithDetails(state),
  positionsLoading: createLoadingSelector([POSITIONS])(state),
  selectedAccountId: getActiveAccountId(state),
});

const mapDispatchToProps = {
  fetchPositions,
  fetchContracts,
};

class PositionsContainer extends Component {
  state = { expanded: [] };
  componentDidMount = () => {
    this.props.fetchContracts();
    if (this.props.selectedAccountId) {
      this.props.fetchPositions({
        accountId: this.props.selectedAccountId,
      });
    }
  };

  render = () => (
    <section className="main-holdings">
      <Header
        content="Futures Positions"
        subheader="See all futures positions for this account."
      />
      <XTable
        title="futures-positions"
        minRows={5}
        className="dark"
        id="futures-positions-maincomponent"
        loading={this.props.positionsLoading}
        data={this.props.positionsList}
        pageSize={this.props.positionsList.length}
        columns={futuresPositionsMetadata}
        expanded={this.state.expanded}
        showPagination={false}
        onExpandedChange={(newExpanded) => {
          this.setState({ expanded: newExpanded });
        }}
        SubComponent={({ row }) => (
          <XTable
            className="subtable no-stripe"
            id="futures-positions-subcomponent"
            title="futures-positions-subcomponent"
            data={get(row, ['_original', 'positions'], [])}
            showPagination={false}
            pageSize={get(row, ['_original', 'positions'], []).length}
            columns={subComponentMetadata}
          />
        )}
      />
    </section>
  );
}

PositionsContainer.propTypes = {
  fetchPositions: PropTypes.func.isRequired,
  positionsList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          qty: PropTypes.string,
          px: PropTypes.string,
        }),
      ),
    }),
  ),
  positionsLoading: PropTypes.bool,
};

PositionsContainer.defaultProps = {
  positionsList: [],
  positionsLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionsContainer);
