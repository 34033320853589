import { get } from 'lodash';
import auth0 from 'auth0-js';
import qs from 'qs';
import { datadogRum } from '@datadog/browser-rum';
import history from '../constants/history';
import EnvPromise from '../config/env';

const DEFAULT_SCOPE = 'openid profile email';
const TRADING_UI_SCOPE =
  'write:order read:clearing_api write:block_trade write:settlement_request';
const FCM_SCOPE =
  'read:customer_account_reference write:customer_account_reference';

const TAX_SCOPE = 'read:tax_documents write:tax_documents';

// refresh buffer for access_tokens, 30 seconds.
const REFRESH_BUFFER = 60 * 1000;
const getRedirectUri = ({redirectUri}) => {
  if (redirectUri.includes('localhost')) {
    return `http://${window.location.host}/login`;
  } else {
    return redirectUri;
  }
}

export default class Auth {
  constructor() {
    this.env = '';
    this.interval = null;
  }

  auth0 = (options) =>
    EnvPromise.then((env) => {
      this.env = env;
      return new auth0.WebAuth({
        domain: env.domain,
        clientID: env.clientId,
        redirectUri: getRedirectUri(env),
        audience: env.auth0ApiAud,
        responseType: 'token',
        scope: `${DEFAULT_SCOPE} ${TRADING_UI_SCOPE} ${FCM_SCOPE} ${TAX_SCOPE}`,
        enableLoginImpersonation: env.enableLoginImpersonation,
        ...options,
      });
    });

  handleAuthentication = () => {
    this.auth0({}).then((auth) => {
      const enableLoginImpersonation =
        get(auth, ['baseOptions', 'enableLoginImpersonation'], '') === 'true';

      // skipping auth0 state check if we're in a testing environment
      if (enableLoginImpersonation) {
        let hashStr = window.location.hash;
        hashStr = hashStr.replace(/^#?\/?/, '');
        const parsedQs = qs.parse(hashStr);
        this.setSession({
          accessToken: parsedQs.access_token,
          expiresIn: parsedQs.expires_in,
        });
        history.push('/home');
      } else {
        auth.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken) {
            this.setSession(authResult);
            this.startInterval();
            history.push('/home');
          } else if (err) {
            // Pass the error message back to Auth0
            auth.authorize({
              prompt: 'login',
              errorDescription: err.errorDescription,
            });
          }
        });
      }
    });
  };

  startInterval = () => {
    const { expiresIn } = this.getSession();
    if (expiresIn) {
      this.interval = setInterval(
        () => this.checkSession(this.env.clientID),
        this.getInterval(),
      );
    }
  };

  getInterval = () => {
    const { expiresIn } = this.getSession();
    if (expiresIn < REFRESH_BUFFER) {
      return (expiresIn * 1000) / 2;
    }
    return expiresIn * 1000 - REFRESH_BUFFER;
  };

  setSession = ({ expiresIn, accessToken }) => {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('expires_in', expiresIn);
  };

  getSession = () => {
    const accessToken = localStorage.getItem('access_token');
    const expiresAt = localStorage.getItem('expires_at');
    const expiresIn = localStorage.getItem('expires_in');

    const session = {
      accessToken,
      expiresAt,
      expiresIn,
    };

    return session;
  };

  logout = (params) => {
    // TODO this is currently inefficient that we call the /env endpoint everytime, we should rewrite this so that the auth0 object can be initialized once, and it's env settings
    EnvPromise.then((env) => {
      // Clear Access Token and ID Token from local storage
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      sessionStorage.clear();
      datadogRum.removeUser();

      // This will always redirect you back to where you need to be.
      this.auth0({}).then((auth) =>
        auth.logout({
          clientID: env.clientId,
          returnTo: getRedirectUri(env),
          ...params,
        }),
      );
    });
  };

  isAuthenticated = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const isAuthenticated = new Date().getTime() < expiresAt;
    if (!isAuthenticated) {
      datadogRum.addAction('AutoLogout', { expiresAt });
    }
    return isAuthenticated;
  };

  login = (clientID) => {
    this.auth0({ ...(clientID && { clientID }) }).then((auth) =>
      auth.authorize({ prompt: 'login' }),
    );
  };

  silent = (clientID) => {
    this.auth0({ ...(clientID && { clientID }) }).then((auth) =>
      auth.authorize({ prompt: 'none' }),
    );
  };

  checkSession = (clientID) => {
    this.auth0({ ...(clientID && { clientID }) }).then((auth) => {
      auth.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken) {
          this.setSession(authResult);
          return auth;
        }
        if (err.error === 'login_required') {
          console.log(err, 'login required');
          // auth.authorize({ prompt: 'login' });
        } else {
          this.silent(clientID);
        }
      });
    });
  };

  /**
   * @param redirectUri url to return to.
   */
  universalAuth = (redirectUri, clientId) =>
    EnvPromise.then((env) => {
      const options = {
        audience: env.auth0ApiAud,
        responseType: 'token',
        scope: `${DEFAULT_SCOPE} ${TRADING_UI_SCOPE} ${FCM_SCOPE}`,
        clientID: clientId,
        prompt: 'none',
      };
      this.auth0(options).then((auth) => {
        auth.checkSession(options, (err, authResult) => {
          if (authResult && authResult.accessToken) {
            auth.authorize({ ...options, redirectUri });
          }
          if (err) {
            // If there was an error, e.g. consent_required,
            // then send the user to the login page properly.
            datadogRum.addAction('Authorize Failed', err);
            auth.authorize({ ...options, redirectUri, prompt: 'login' });
          }
        });
      });
    });
}
