import React from 'react';
import Tabs from '../../common/Tabs';
import Page from '../../common/Page';
import BlockTradeRequest from './BlockTradeRequests';
import TradeHistory from './TradeHistory';
import { RegisterBlockTrade, Wrapper } from './BlockTradesRegistration.styles';
import styled from 'styled-components';

const ContainerButton = styled.div`
  display:grid;
  margin-bottom: 10px;
`;

const panes = [
  {
    menuItem: 'Block Trade Requests',
    render: () => <BlockTradeRequest />,
  },
  {
    menuItem: 'Trade History',
    render: () => <TradeHistory />,
  },
];

const BlockTradesRegistration = () => {
  return (
    <Page header="Block Trades Registration">
      <Wrapper>
        <ContainerButton>
          <RegisterBlockTrade />
        </ContainerButton>
        <Tabs panes={panes} />
      </Wrapper>
    </Page>
  );
};

export default BlockTradesRegistration;
