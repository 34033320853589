import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, ReducerMap } from 'redux-actions';
import { TradingParticipantManagementRiskLimits, TradingParticipantManagementRiskLimitsCompatible } from '../ts/models/TradingParticipant';
import { RootState } from '../ts/types/store';
import { createSelector } from 'reselect';

/** Action Names */
export const GET_TRADING_PARTICIPANT_RISK_LIMITS = 'GET_TRADING_PARTICIPANT_RISK_LIMITS';
export const UPDATE_TRADING_PARTICIPANT_RISK_LIMITS = 'UPDATE_TRADING_PARTICIPANT_RISK_LIMITS';
export const CREATE_TRADING_PARTICIPANT_RISK_LIMITS = 'CREATE_TRADING_PARTICIPANT_RISK_LIMITS';
//* actions */
export const fetchTradingParticipantRiskLimits = createRoutine(GET_TRADING_PARTICIPANT_RISK_LIMITS);
export const updateTradingParticipantRiskLimits = createRoutine(UPDATE_TRADING_PARTICIPANT_RISK_LIMITS);
export const createTradingParticipantRiskLimits = createRoutine(CREATE_TRADING_PARTICIPANT_RISK_LIMITS);

//* Promise actions*/
export const updateTradingParticipantRiskLimitsPromiseCreator = promisifyRoutine(updateTradingParticipantRiskLimits);
export const createTradingParticipantRiskLimitsPromiseCreator = promisifyRoutine(createTradingParticipantRiskLimits);

export type FetchTradingParticipantManagementResponse = TradingParticipantManagementRiskLimits[];

/** Initial State */
type TradingParticipantManagementState = {
  tradingParticipantRiskLimits: FetchTradingParticipantManagementResponse;
};

//const initialState: TradingParticipantManagement[] = [];
const initialState: TradingParticipantManagementState = {
  tradingParticipantRiskLimits: [],
};

type ActionPayload = TradingParticipantManagementState;

//* Reducer */
export default handleActions<TradingParticipantManagementState, ActionPayload>(
  {
    [fetchTradingParticipantRiskLimits.SUCCESS]: (state, { payload }) => {
      return ({
        ...state,
        tradingParticipantRiskLimits: payload,
      })
    }
  } as ReducerMap<TradingParticipantManagementState, TradingParticipantManagementState>,
  initialState,
);

const processDataCompatibility = (data: TradingParticipantManagementRiskLimits[]): TradingParticipantManagementRiskLimitsCompatible[] => {
  const result: TradingParticipantManagementRiskLimitsCompatible[] = [];
  Array.isArray(data) && data.forEach(item => {
    const {
      participantId,
      participantName,
      fcms,
      participantFirmCode,
    } = item;

    fcms.forEach(fcm => {
      const {
        fcmName,
        tradingParticipantRiskLimits,
        cars
      } = fcm;

      const {
        fcmMemberFirmCode,
        tradingParticipantFirmCode,
        canParticipantModifyCarRiskLimits,
        marginExposureLimit,
        enabledProducts,
        participantRiskLimits,
        lastUpdatedBy,
        updatedAt
      } = tradingParticipantRiskLimits || { canParticipantModifyCarRiskLimits: false };

      result.push({
        participantId,
        participantName,
        participantFirmCode,
        fcmName,
        fcmMemberFirmCode,
        tradingParticipantFirmCode,
        canParticipantModifyCarRiskLimits,
        cars: cars.map(car => car.customerAccountReference),
        marginExposureLimit,
        enabledProducts,
        participantRiskLimits,
        lastUpdatedBy,
        updatedAt,
      });
    });
  });

  return result;
};

//* Selectors */
export const getTradingParticipantRiskLimits = (state: RootState) =>
  get(state, ['tradingParticipantManagement', 'tradingParticipantRiskLimits'], []);

export const getTradingParticipantRiskLimitsCompatible = createSelector(
  [getTradingParticipantRiskLimits],
  (tradingParticipantRiskLimits) => processDataCompatibility(tradingParticipantRiskLimits),
);