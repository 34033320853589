import React from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, reduxForm, getFormValues, reset } from 'redux-form';
import sign from 'eris-ecdsa';
import { Form } from 'semantic-ui-react';
import { renderField, showModal, hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import head from 'lodash/head';
import styled from 'styled-components';
import { UISegment } from '../../styles/styled';
import { getSpotClearingAccounts } from '../../reducers/accountsReducer';
import StyledButton from '../../common/StyledButton';
import * as rules from '../../common/forms/rules';
import {
  NOTIFY_UNREGISTERED_USER,
  ADD_AUTHORIZED_USER,
  FUNDING_PASSWORD,
} from '../../constants/modalTypes';
import { getCurrentAuthId } from '../../reducers/userReducer';
import {
  addAuthorizedUserPromiseCreator,
  fetchInvestmentMemberPromiseCreator,
} from '../../reducers/authorizedUsersReducer';

import AccountDropdown, {
  getAccountDropdownParams,
} from '../../common/components/AccountDropdown';

const Header = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
`;
Header.displayName = 'Header';

const Segment = styled(UISegment)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2em;
  margin-left: 0px;
  margin-top: 5px;
`;
Segment.displayName = 'Segment';

const Add = styled(StyledButton)`
  width: 220px;
  font-size: 18px;
  display: block;
`;
Add.displayName = 'Add';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
Content.displayName = 'Content';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
StyledForm.displayName = 'StyledForm';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const DropDownField = styled(Field)`
  margin-bottom: 1em;
`;

const AccountTitle = styled.span`
  font-size: 0.92857143em;
  font-weight: 800;
`;

const AccountLabel = () => (
  <p>
    <AccountTitle>Clearing Account</AccountTitle>
    <span style={{ color: 'red', fontSize: '14px' }}>*</span>
  </p>
);

ButtonContainer.displayName = 'ButtonContainer';

const AddUsers = ({ valid }) => {
  const accountList = useSelector(getSpotClearingAccounts).map((account) => ({
    ...getAccountDropdownParams(account),
    value: {
      label: get(account, 'label', ''),
      accountId: get(account, 'accountId', ''),
    },
  }));
  const formValues = useSelector((state) => getFormValues('addUsers')(state));
  const authId = useSelector(getCurrentAuthId);

  const email = get(formValues, 'email', '');
  const accountId = get(formValues, 'account.accountId', '');
  const accountLabel = get(formValues, 'account.label', '');
  const termsAccepted = get(formValues, 'termsAccepted', false);

  const dispatch = useDispatch();

  const onSubmit = (password) => {
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const msg = [authId, accountId, email, Date.now().toString()];

    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
    const payload = {
      email,
      accountId,
      msg,
      sig,
    };
    addAuthorizedUserPromiseCreator(payload, dispatch).catch(() => {
      dispatch(hideModal(ADD_AUTHORIZED_USER));
      dispatch(hideModal(FUNDING_PASSWORD));
    });
  };

  const onAdd = () => {
    const result = fetchInvestmentMemberPromiseCreator({ email }, dispatch);
    result
      .then((res) => {
        // Investment member exists so can be authorized
        if (res.success) {
          dispatch(
            showModal(ADD_AUTHORIZED_USER, {
              size: 'small',
              accountLabel,
              email,
              onConfirm: () => {
                dispatch(
                  showModal(FUNDING_PASSWORD, {
                    submit: onSubmit,
                  }),
                );
                dispatch(reset('addUsers'));
              },
            }),
          );
        } else {
          // Investment member is not registered at ErisX
          dispatch(
            showModal(NOTIFY_UNREGISTERED_USER, {
              size: 'small',
              email,
            }),
          );
        }
      })
      .catch(() => {});
  };

  // Enter keypress ignores the validation of the checkbox
  // so we force the user to check it and manually click submit
  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Prevent the form from being submitted with an enter keypress
      // unless the terms have been accepted
      if (!termsAccepted) event.preventDefault();
    }
  };
  return (
    <>
      <Header>ADD AUTHORIZED USERS</Header>
      <Segment>
        <StyledForm onSubmit={onAdd} onKeyPress={onKeyPress}>
          <DropDownField
            component={AccountDropdown}
            fluid
            required
            label={<AccountLabel />}
            placeholder="Select an Account"
            name="account"
            validate={[rules.required]}
            props={{
              accounts: accountList,
              id: 'add-user-account',
            }}
          />
          <Field
            component={renderField}
            name="email"
            required
            label="Authorized User Email"
            placeholder="Enter Authorized Traders email address"
            validate={[rules.required, rules.email]}
          />
          <ButtonContainer>
            <Add
              primary
              text="Add Authorized User"
              onClick={onAdd}
              disabled={!valid}
            />
          </ButtonContainer>
        </StyledForm>
      </Segment>
    </>
  );
};

let AddUsersForm = reduxForm({
  form: 'addUsers',
})(AddUsers);

AddUsersForm = connect((state) => {
  const accounts = getSpotClearingAccounts(state);
  const defaultAcc = head(accounts);
  return accounts.length > 1
    ? null
    : {
        initialValues: {
          account: defaultAcc,
        },
      };
})(AddUsersForm);

export default AddUsersForm;
