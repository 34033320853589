import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import sign from 'eris-ecdsa';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hideModal, showModal } from 'erisxkit/client';
import { FUNDING_PASSWORD } from '../../constants/modalTypes';
import * as linkedAccountsActions from '../../reducers/linkedAccountsReducer';
import { getCurrentAuthId } from '../../reducers/userReducer';
import PlaidLink from '../../components/PlaidLink';
import EnvPromise from '../../config/env';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { trackEvent } from '../../common/tracking';
import gaCategories from '../../constants/ga-categories';

const mapStateToProps = (state) => ({
  authId: getCurrentAuthId(state),
  selectedMemberId: getSelectedMemberId(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      lookupHashIdPromiseCreator:
        linkedAccountsActions.lookupHashIdPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      showModal,
      hideModal,
      checkBankAccount: linkedAccountsActions.checkBankAccount,
    },
    dispatch,
  ),
});

class AddBankAccountPlaidContainer extends Component {
  state = {};

  componentWillMount = () => {
    EnvPromise.then(
      ({ plaidClientName, plaidEnv, plaidProduct, plaidPublicKey }) =>
        this.setState({
          plaid: {
            clientName: plaidClientName,
            env: plaidEnv,
            product: plaidProduct,
            publicKey: plaidPublicKey,
          },
        }),
    );
  };

  submit = (password) => {
    const {
      authId,
      lookupHashIdPromiseCreator,
      checkBankAccount,
      selectedMemberId,
    } = this.props;
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const { institution, publicToken } = this.state;
    this.state.accounts.map((account) => {
      // sign this with hash of account not accountId.
      lookupHashIdPromiseCreator({
        publicToken,
        accountId: account.id,
      })
        .then((successPayload) => {
          const msg = [
            authId,
            selectedMemberId,
            successPayload.hashId,
            'USD',
            Date.now().toString(),
          ];
          const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
          const payload = {
            msg,
            sig,
            memberId: selectedMemberId,
            publicToken,
            accountId: account.id,
            accountLabel: `${account.name} ${account.mask}`,
            bankName: institution.name,
          };
          trackEvent(gaCategories.PLAID, 'submit');
          checkBankAccount(payload);
        })
        .catch(() => this.props.hideModal(FUNDING_PASSWORD));
    });
  };

  plaidSuccess = (publicToken, metadata) => {
    this.props.showModal(FUNDING_PASSWORD, { submit: this.submit });

    const { institution, accounts } = metadata;
    this.setState({
      institution,
      accounts,
      publicToken,
    });
  };

  buttonClasses = (props) =>
    props.link
      ? classNames({
          bold: true,
          'pulse-button': props.glow,
        })
      : classNames({
          ui: true,
          button: true,
          fluid: props.fluid,
          primary: props.primary,
          secondary: props.secondary,
          tertiary: props.tertiary,
        });

  render = () =>
    !this.state.plaid ? (
      <Button secondary loading {...this.props} />
    ) : (
      <PlaidLink
        className={this.buttonClasses(this.props)}
        clientName={this.state.plaid.clientName}
        env={this.state.plaid.env}
        product={this.state.plaid.product}
        publicKey={this.state.plaid.publicKey}
        onSuccess={(publicToken, metadata) =>
          this.plaidSuccess(publicToken, metadata)
        }
        style={[]}
        {...this.props}
      >
        {this.props.text ? this.props.text : '+ Add or relink a bank account'}
      </PlaidLink>
    );
}

AddBankAccountPlaidContainer.propTypes = {
  fluid: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  link: PropTypes.bool,
  text: PropTypes.string,
};

AddBankAccountPlaidContainer.defaultProps = {
  fluid: false,
  primary: false,
  secondary: false,
  tertiary: false,
  link: false,
  text: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddBankAccountPlaidContainer);
