import React from 'react';
import { Form, Dropdown, Input } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';

export const renderCheckbox = ({
  input,
  label,
  readOnly,
  className,
  'data-cy': dataCy = '',
}) => (
  <Form.Checkbox
    checked={!!input.value}
    name={input.name}
    label={label}
    onChange={(e, { checked }) => input.onChange(checked)}
    readOnly={readOnly}
    className={className}
    data-cy={dataCy}
  />
);

export const renderRadio = (field) => (
  <Form.Radio
    checked={field.input.value === field.label.toLowerCase()}
    label={field.label}
    name={field.input.name}
    onChange={(e, { checked }) =>
      field.input.onChange(field.label.toLowerCase())
    }
    data-cy={field['data-cy']}
  />
);

export const renderDropdown = (field) => (
  <Dropdown
    label={field.label}
    name={field.input.name}
    onChange={(e, { value }) => field.input.onChange(value)}
    options={field.options}
    placeholder={field.placeholder}
    value={field.input.value}
    {...field}
    // hacky way to turn off autocomplete in chrome
    autoComplete={field.input.name}
    onFocus={(e) => {
      e.target.setAttribute('autocomplete', field.input.name);
    }}
  />
);

export const renderTextArea = (field) => (
  <Form.TextArea
    {...field.input}
    label={field.label}
    placeholder={field.placeholder}
    autoComplete={field.input.name}
    data-cy={field['data-cy']}
  />
);

const getError = (error, minimal) => (error && minimal ? true : error);
export const renderField = ({
  input,
  required,
  placeholder,
  meta: { touched, error, warning },
  'data-cy': dataCy = '',
  className = '',
  minimal = false,
  ...rest
}) => (
  <Form.Field data-cy={dataCy} className={className}>
    <Form.Input
      placeholder={required ? `${placeholder} *` : placeholder}
      {...input}
      error={touched && (getError(error, minimal) || warning)}
      {...rest}
      autoComplete="off"
    />
    {touched &&
      ((error && minimal && <small className="helper error">{error}</small>) ||
        (warning && !minimal && (
          <small className="helper error">{warning}</small>
        )))}
  </Form.Field>
);

export const renderDateTime = ({
  input: { value, onBlur, onChange },
  label,
  placeholder,
  isValidDate,
  meta: { touched, error, warning },
  'data-cy': dataCy = '',
  timeFormat = false,
}) => (
  <Form.Field>
    <Datetime
      onBlur={onBlur}
      className="ui input"
      inputProps={{
        placeholder,
        'data-cy': `${dataCy}`,
      }}
      defaultValue={value}
      viewDate={
        moment(value, 'YYYY-MM-DD', true).isValid()
          ? value
          : Datetime.moment().subtract(18, 'years')
      }
      timeFormat={timeFormat}
      onChange={(currentDate) => {
        // if it's a valid date
        if (currentDate._d) {
          onChange(Datetime.moment(currentDate._d).format('YYYY-MM-DD'));
        } else {
          onChange(currentDate);
        }
      }}
      isValidDate={isValidDate}
      closeOnSelect
      input
    />
    {touched &&
      ((error && <small className="helper error">{error}</small>) ||
        (warning && <small className="helper error">{warning}</small>))}
  </Form.Field>
);

export const renderFlatpickrDateTime = ({
  input: { value, onBlur, onChange, ...rest },
  label,
  placeholder,
  isValidDate,
  meta: { touched, error, warning },
  'data-cy': dataCy = '',
  mode = 'single',
}) => (
  <Form.Field>
    <label htmlFor="date">{label}</label>
    <div className="ui input fluid" style={{ height: '38px' }}>
      <Flatpickr
        placeholder={placeholder}
        {...rest}
        onChange={onChange}
        value={value}
        options={{
          mode,
        }}
      />
    </div>
    {touched &&
      ((error && <small className="helper error">{error}</small>) ||
        (warning && <small className="helper error">{warning}</small>))}
  </Form.Field>
);
