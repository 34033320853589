import { TableAction, TableActionName } from './types';
import { RowData, Table } from '@tanstack/react-table';
import exportTable from './exportTable';

const getTableActions = <T>(table: Table<T>): TableAction[] => [
  {
    name: TableActionName.Filters,
    icon: 'filter',
    tooltip: 'Filters',
    subActions: [
      {
        name: TableActionName.ClearFilters,
        text: 'Clear All Filters',
        onClick: () => {
          table.resetColumnFilters();
          table.resetSorting();
        },
      },
    ],
  },
  {
    name: TableActionName.Refetch,
    icon: 'refresh',
    tooltip: 'Refetch data',
  },
  {
    name: TableActionName.Export,
    icon: 'arrow down',
    tooltip: 'Export to CSV',
    onClick: () => exportTable(table),
  },
];

export default getTableActions;
