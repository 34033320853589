import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import React, { Component } from 'react';
import Swipeable from 'react-swipeable';
import AccountHistory from '../components/AccountHistory';
import { ONBOARDED } from '../constants/userStates';
import {
  getPath,
  fetchRecentTransactions,
  getRecentTransactions,
} from '../reducers/accountHistoryReducer';
import { getActiveBalance, ACCOUNTS } from '../reducers/accountsReducer';
import { getActiveAccountId } from '../reducers/activeAccountSelectors';
import { getActiveAccount } from '../reducers/activeAccountSelectors';
import { hasBalancesGreaterThanZero } from '../reducers/balancesReducer';
import { getAssetTypes } from '../reducers/assetTypesReducer';
import { showSidebar } from '../reducers/sidebarReducer';
import { getLoggedInUser } from '../reducers/userReducer';
import { selectFundTransfer, isViewportLessThan } from '../reducers/uiReducer';
import { VERTICAL_NAV_CONTAINER } from '../constants/sideBarTypes';
import accountHistoryMetadata from '../metadata/accountHistoryMetadata';
import accountBalancesMetadata from '../metadata/assetBalancesMetadata';
import { POLLING_INTERVAL } from '../constants/timeout';
import AssetBalancesContainer from './AssetBalancesContainer';
import AccessControl from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import { accountIsAllowedFutures } from '../utils/permissions';
import { isMobileOnly } from 'react-device-detect';

export const RECENT_TRANSACTIONS_LIMIT = 10;

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  accountId: getActiveAccountId(state),
  path: getPath(state),
  loading: createLoadingSelector(['RECENT_TRANSACTIONS'])(state),
  accountLoading: createLoadingSelector([ACCOUNTS])(state),
  assetTypes: getAssetTypes(state),
  balance: getActiveBalance(state),
  hasBalancesGreaterThanZero: hasBalancesGreaterThanZero(state),
  recentTransactions: getRecentTransactions(state),
  viewportLessThanSmall: isViewportLessThan('small', state),
  viewportLessThanMedium: isMobileOnly,
  user: getLoggedInUser(state),
});

const mapDispatchToProps = {
  fetchRecentTransactions,
  showSidebar,
  getPath,
  selectFundTransfer,
};

class AccountsHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { user, fetchRecentTransactions, accountId } = this.props;
    if (user && user.state === ONBOARDED) {
      if (accountId) {
        fetchRecentTransactions({
          accountIds: accountId,
          limit: RECENT_TRANSACTIONS_LIMIT,
        });
        this.interval = setInterval(
          () =>
            fetchRecentTransactions({
              accountIds: accountId,
              limit: RECENT_TRANSACTIONS_LIMIT,
            }),
          POLLING_INTERVAL,
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.accountId !== nextProps.accountId) {
      clearInterval(this.interval);
      this.props.fetchRecentTransactions({
        accountIds: nextProps.accountId,
        limit: RECENT_TRANSACTIONS_LIMIT,
      });
      this.interval = setInterval(
        () =>
          this.props.fetchRecentTransactions({
            accountIds: nextProps.accountId,
            limit: RECENT_TRANSACTIONS_LIMIT,
          }),
        POLLING_INTERVAL,
      );
    }
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  swipedRight = (e, deltaX, isFlick) => {
    if (isFlick && this.props.viewportLessThanMedium) {
      this.props.showSidebar(VERTICAL_NAV_CONTAINER, { direction: 'left' });
    }
  };

  filter = (e, { text }) => {
    clearInterval(this.interval);
    let assetType = text;
    if (text === 'Show All') {
      assetType = undefined;
    }
    this.props.fetchRecentTransactions({
      accountIds: this.props.accountId,
      limit: RECENT_TRANSACTIONS_LIMIT,
      assetType,
    });
    this.interval = setInterval(
      () =>
        this.props.fetchRecentTransactions({
          accountIds: this.props.accountId,
          limit: RECENT_TRANSACTIONS_LIMIT,
          assetType,
        }),
      POLLING_INTERVAL,
    );
  };

  render() {
    const metadata = accountHistoryMetadata;
    const { account = {} } = this.props;
    const { recentTransactions } = this.props;

    const data = {
      recentTransactions,
      loading: this.props.loading,
      metadata,
      path: this.props.path,
      assetTypes: this.props.assetTypes,
      assetTypesLoading: this.props.assetTypesLoading,
      balance: this.props.balance,
      hasBalancesGreaterThanZero: this.props.hasBalancesGreaterThanZero,
      viewportLessThanSmall: this.props.viewportLessThanSmall,
      filter: this.filter,
      account,
      accountId: account.accountId || '',
    };

    return (
      <Swipeable
        nodeName="section"
        disabled={!this.props.viewportLessThanMedium}
        onSwipedRight={this.swipedRight}
        trackMouse
        stopPropagation
      >
        <section className="main-holdings">
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.BALANCES}`]}
          >
            {data.accountId && (
              <AssetBalancesContainer
                account={this.props.account}
                loading={this.props.accountLoading}
                accountIds={[data.accountId]}
                key={data.accountId}
                metadata={accountBalancesMetadata({
                  showFutures: accountIsAllowedFutures(account),
                })}
                subheaderText="See the total asset balances for this account."
              />
            )}
          </AccessControl>
          <AccessControl
            allowedPermissions={[
              `${grants.READ}:${subjects.TRADES}`,
              `${grants.READ}:${subjects.MOVEMENTS}`,
            ]}
          >
            <AccountHistory {...data} />
          </AccessControl>
        </section>
      </Swipeable>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsHistoryContainer);
