import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import GenericStep from './GenericStep';
import * as rules from '../../common/forms/rules';
import ReduxFormSelect from '../../common/forms/ReduxFormSelect';
import { trackEvent } from '../../common/tracking';
import api from '../../api';
import * as urls from '../../api';
import { STATE_NOT_SUPPORTED } from '../../constants/messages';
import states from '../../constants/states';
import { formatDateValue } from '../../utils/formatters';
import {
  MIN_USER_AGE,
  MAX_USER_AGE,
  YEARS_TIME_UNIT,
} from '../../constants/datetime';
import ReduxFormTextField from '../../common/forms/ReduxFormTextField';
import ReduxFormDatePicker from '../../common/forms/ReduxFormDatePicker';
import PhoneInputComponent from '../../common/forms/PhoneInputComponent';
import SsnInputComponent from '../../common/forms/SsnInputComponent';
import SecureFieldAdornment from '../../common/forms/SecureField';
import { ONBOARDING_PAPER_MAX_WIDTH } from '../../constants/layout';
import { PII_NAME } from '../../constants/ddActionNames';

export const styles = {
  container: {
    maxWidth: ONBOARDING_PAPER_MAX_WIDTH,
    margin: '2rem',
    padding: '3rem',
    marginBottom: '5rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  highlight: {
    fontWeight: 'bold',
  },
  form: {
    marginBottom: '2rem',
  },
};
export class Personal extends Component {
  next = () => {
    const { nextStep } = this.props;
    trackEvent('AMLKYC', 'PIIstart');
    nextStep();
  };

  render() {
    const { invalid, classes } = this.props;

    // default to legal minimum age
    const maxDob = formatDateValue(
      moment().subtract(MIN_USER_AGE, YEARS_TIME_UNIT),
    );
    // default to reasonable maximum age
    const minDob = formatDateValue(
      moment().subtract(MAX_USER_AGE, YEARS_TIME_UNIT),
    );

    return (
      <div className="onboarding-container">
        <Paper className={classes.container} square="true" elevation={0}>
          <Typography variant="h4" className={classes.header}>
            Become a Member
          </Typography>
          <Typography variant="body1">
            <strong className={classes.highlight}>
              Let's start by creating your account.
            </strong>{' '}
            To get you set up as fast as possible, please make sure your details
            are accurate.
          </Typography>

          <form
            noValidate
            autoComplete="off"
            className={classes.form}
            data-dd-action-name={PII_NAME}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormTextField}
                  label="First Name"
                  type="text"
                  name="firstName"
                  validate={rules.required}
                  fullWidth
                  required
                  data-cy="ob-personal-form-first-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormTextField}
                  label="Middle Name"
                  type="text"
                  name="middleName"
                  fullWidth
                  data-cy="ob-personal-form-middle-name"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormTextField}
                  label="Last Name"
                  type="text"
                  name="lastName"
                  validate={rules.required}
                  required
                  fullWidth
                  data-cy="ob-personal-form-last-name"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={ReduxFormTextField}
                  label="Street Address"
                  type="text"
                  name="address1"
                  validate={rules.required}
                  required
                  fullWidth
                  data-cy="ob-personal-form-street-address"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={ReduxFormTextField}
                  label="Street Address 2"
                  type="text"
                  name="address2"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormTextField}
                  label="City"
                  type="text"
                  name="city"
                  validate={rules.required}
                  fullWidth
                  required
                  data-cy="ob-personal-form-city"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormSelect}
                  label="State"
                  type="text"
                  name="locationState"
                  options={states}
                  required
                  fullWidth
                  data-cy="ob-personal-form-state"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  component={ReduxFormTextField}
                  label="Zip Code"
                  type="text"
                  name="postalCode"
                  validate={[rules.required, rules.zipcode]}
                  fullWidth
                  required
                  data-cy="ob-personal-form-zip-code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={ReduxFormDatePicker}
                  label="Date of Birth (mm/dd/yyyy) *"
                  type="text"
                  name="dob"
                  validate={[
                    rules.required,
                    rules.dobFormat,
                    rules.maxDob,
                    rules.minDob,
                  ]}
                  minDate={minDob}
                  maxDate={maxDob}
                  required
                  fullWidth
                  data-cy="ob-personal-form-date-of-birth"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={ReduxFormTextField}
                  label="Phone Number"
                  type="tel"
                  name="phoneNumber"
                  validate={[rules.required, rules.phoneNumber]}
                  inputComponent={PhoneInputComponent}
                  required
                  fullWidth
                  data-cy="ob-personal-form-phone-number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={ReduxFormTextField}
                  endAdornment={SecureFieldAdornment}
                  label="Social Security Number"
                  type="password"
                  name="ssn"
                  validate={[rules.required, rules.exactLength(9), rules.ssn]}
                  inputComponent={SsnInputComponent}
                  required
                  fullWidth
                  data-cy="ob-personal-form-ssn"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={ReduxFormTextField}
                  endAdornment={SecureFieldAdornment}
                  label="Confirm Social Security Number"
                  type="password"
                  name="ssnConfirm"
                  validate={[
                    rules.required,
                    rules.ssnMatch,
                    rules.exactLength(9),
                  ]}
                  inputComponent={SsnInputComponent}
                  required
                  fullWidth
                  data-cy="ob-personal-form-ssn-repeat"
                />
              </Grid>
            </Grid>
          </form>
          <GenericStep
            nextStep={this.next}
            nextStepDisabled={invalid}
            showRequired
            data-dd-action-name="PII Continue"
            data-cy="ob-personal-form-confirm-button"
          />
        </Paper>
      </div>
    );
  }
}

// async validation for this form
// bypasses redux state as it requires
// to throw if there is an error or
// return nothing if all is good
const asyncValidate = (values) =>
  api
    .post(urls.IS_ELIGIBLE_LOCATION_API_ENDPOINT, {
      state: values.locationState,
    })
    .then((res) => {
      if (!res) {
        throw { locationState: STATE_NOT_SUPPORTED };
      }
    });

const StylizedComponent = withStyles(styles)(Personal);

const ReduxFormComponent = reduxForm({
  form: 'personal',
  destroyOnUnmount: false,
  touchOnChange: true,
  asyncValidate,
  asyncChangeFields: ['locationState', 'ssn'],
})(StylizedComponent);

export default ReduxFormComponent;
