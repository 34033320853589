import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getUserCanReadExchangeMember,
  getUserHasRequestFcmClearing,
} from '../reducers/userReducer';
import EnvPromise from '../config/env';
import {
  MarginFuturesPanel,
  getNonFCMPanels,
} from '../common/components/VerticalNavMarginFutures/MarginFuturesPanels';
import useIsFCM from './useIsFCM';
import useIsExchangeMemberEnabled from './useIsFeatureFlagEnabled/useIsExchangeMemberEnabled';
import { EnvState } from '../reducers/envReducer';

const getNonFCMPanelRoutes = (p: MarginFuturesPanel) => {
  return p.content.map((content) => content.to);
};

const HOME_NON_FCM_MARGIN_FUTURES_ROUTE = '/fcm-clearing';
const NON_FCM_MARGIN_FUTURES_ROUTES = [
  HOME_NON_FCM_MARGIN_FUTURES_ROUTE,
  ...getNonFCMPanels().flatMap((p) => getNonFCMPanelRoutes(p)),
];

export const useIsNonFCMMarginFuturesPage = () => {
  const location = useSelector((s: any) => s.router.location.pathname);
  return NON_FCM_MARGIN_FUTURES_ROUTES.includes(location);
};

export const isNonFCMMarginFuturesPage = () => {
  const location = window.location.pathname;
  return NON_FCM_MARGIN_FUTURES_ROUTES.includes(location);
};

/* Use this hook to know if the currentUser is has Request FCM Clearing permissions */
export const useHasRequestFcmClearing = () => {
  const [enableExchangeMember, setExchangeMember] = useState('');
  const hasRequestFcmClearing = useSelector(getUserHasRequestFcmClearing);

  useEffect(() => {
    EnvPromise.then((env: EnvState) => setExchangeMember(env.enableExchangeMember));
  }, []);

  return hasRequestFcmClearing && enableExchangeMember === 'true';
};

/* Use this hook to know if the currentUser has Exchange Member permissions */
export const useHasExchangeMember = () => {
  const enabled = useIsExchangeMemberEnabled();
  const isFCM = useIsFCM();
  const canReadExchangeMember = useSelector(getUserCanReadExchangeMember);
  return enabled && isFCM && canReadExchangeMember;
};
