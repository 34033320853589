import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { showModal, hideModal } from 'erisxkit/client';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { ClickAwayListener, Grow } from '@material-ui/core';
import { TradingParticipant } from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import TextButtons from '../../../../common/TextButtons';
import {
  TRADING_PARTICIPANT_LINK_CGM,
  GENERIC_MODAL,
  TRADING_PARTICIPANT_LINK_CAR,
} from '../../../../constants/modalTypes';
import { GenericModalProps } from '../../../../common/components/GenericModal';
import { getActiveAccountLabel } from '../../../../reducers/activeAccountSelectors';
import {
  deleteTradingParticipantPromise,
  fetchTradingParticipants,
} from '../../../../reducers/participantManagementReducer';

export enum ApprovedParticipantAction {
  LINK = 'link',
  UNLINK = 'unlink',
}

const StyledPaper = styled(Paper)`
  && {
    background-color: ${({ theme }) => theme.table.select.bg};
  }
`;

const Item = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.table.select.color};
    :hover {
      background-color: ${({ theme }) => theme.table.select.hover};
      color: ${({ theme }) => theme.table.accent};
    }
  }
`;

const ParticipantActions: React.FC<{
  participant: TradingParticipant;
}> = ({ participant }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const activeAccountLabel = useSelector(getActiveAccountLabel);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onLinkCGM = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CGM, {
        action: ApprovedParticipantAction.LINK,
        participant,
      }),
    );
    handleClose();
  }, [dispatch, handleClose, participant]);

  const onLinkCAR = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CAR, {
        action: ApprovedParticipantAction.LINK,
        participant,
      }),
    );
    handleClose();
  }, [dispatch, handleClose, participant]);

  const onDeleteParticipant = useCallback(async () => {
    const firmCode = participant.participantFirmCode;
    const accountLabel = activeAccountLabel;
    try {
      await deleteTradingParticipantPromise(
        {
          tradingParticipantFirmCode: firmCode,
          urlParams: {
            accountLabel,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
    } catch (error) {
      dispatch(hideModal());
    }
    dispatch(
      fetchTradingParticipants({accountLabel})
    );
  }, [dispatch, participant, activeAccountLabel]);

  const buttons = useMemo(() => {
    return [
      {
        text: 'Link',
        onClick: handleClick,
        fontSize: '14',
      },
      {
        text: 'Delete',
        onClick: () =>
          dispatch(
            showModal(GENERIC_MODAL, {
              header: 'Delete Participant',
              content: `This will delete the approved participant: ${participant.participantName}. Do you want to continue?`,
              cancelProps: {
                text: 'Cancel',
                onClick: () => dispatch(hideModal()),
              },
              confirmProps: {
                text: 'Delete',
                onClick: () => onDeleteParticipant(),
                danger: true,
              },
            } as GenericModalProps),
          ),
        fontSize: '14',
        danger: true,
      },
    ];
  }, [dispatch, onDeleteParticipant]);

  return !isEmpty(participant) ? (
    <div>
      <TextButtons buttons={buttons} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Grow in={open}>
            <StyledPaper>
              <Item onClick={onLinkCGM}>Link a CGM</Item>
              <Item onClick={onLinkCAR}>Link a CAR</Item>
            </StyledPaper>
          </Grow>
        </ClickAwayListener>
      </Popper>
    </div>
  ) : null;
};

export default ParticipantActions;
