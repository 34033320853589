import _ from 'lodash';
import React, { Fragment } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import LoadingComponent from '../../common/components/xtable/LoadingComponent';

const KBA = ({ question, handleChange, value, loading }) => {
  const answer = (text) => (
    <Form.Field>
      <Radio
        label={text}
        name="radioGroup"
        value={text}
        checked={value === text}
        onChange={handleChange}
      />
    </Form.Field>
  );

  return (
    <Fragment>
      {loading && _.isEmpty(question) ? (
        <LoadingComponent />
      ) : (
        <Form>
          {_.get(question, 'choices', []).map((choice) => answer(choice))}
        </Form>
      )}
    </Fragment>
  );
};
export default KBA;
