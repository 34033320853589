import _ from 'lodash';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import { getLatestError, clearLatestError } from 'erisxkit/client';
import PersistentCogoToast from './components/PersistentCogoToast';
import { UNEXPECTED_IDM_STATE_ERROR } from '../reducers/userReducer';

// we want some error to not show up in the toast message.
export const INVISIBLE_ERROR_TYPES = [UNEXPECTED_IDM_STATE_ERROR];

const ErrorMessage = ({ error = { statusText: '' }, clearLatestError }) => {
  const options = {
    heading: error.statusText,
    hideAfter: 5,
  };

  if (error) {
    if (INVISIBLE_ERROR_TYPES.includes(_.get(error, ['data', 'type'], ''))) {
      return null;
    }

    const match = /status code ([0-9]{3})/i.exec(
      _.get(error, ['data', 'error'], ''),
    );
    let status;

    if (typeof match === 'object') {
      status = _.get(match, '1', '');
    }

    switch (status) {
      case '401':
        cogoToast.error('Please check your password and try again.', {
          ...options,
          heading: 'Incorrect Password.',
        });
        break;
      case '403':
        cogoToast.error('You are not authorized to perform this action.', {
          ...options,
          heading: 'Unauthorized',
        });
        break;
      default:
        PersistentCogoToast.error(
          _.get(error, ['data', 'error'], 'An error occurred.'),
          { ...options },
        );
        clearLatestError();
    }
  }
  return null;
};

const mapStateToProps = (state) => ({
  error: getLatestError(state),
});

const mapDispatchToProps = {
  clearLatestError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
