import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal, Icon } from 'semantic-ui-react';
import StyledButton from '../../common/StyledButton';
import { hideModal } from 'erisxkit/client';
import colors from '../../constants/colors';

const Buttons = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
`;
const Cancel = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Remove = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  && {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  && {
    margin-bottom: 20px;
  }
`;

const RemoveDelegatedUserModal = ({
  authorizedTraderEmail,
  accountLabel,
  onRemove,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <Modal.Content scrolling>
        <Title>YOU HAVE REQUESTED TO REMOVE A USER</Title>
        <SubTitle>
          {`The user `}
          <b>{authorizedTraderEmail}</b> {`will be removed from account`}{' '}
          <b>{accountLabel}</b>
        </SubTitle>
      </Modal.Content>
      <Buttons>
        <Cancel onClick={() => dispatch(hideModal())} text="Cancel" />
        <Remove danger onClick={onRemove} text="Remove" />
      </Buttons>
    </>
  );
};

RemoveDelegatedUserModal.defaultProps = {
  accountLabel: '',
  authorizedTraderEmail: '',
  onRemove: () => {},
};

RemoveDelegatedUserModal.propTypes = {
  accountLabel: PropTypes.string,
  authorizedTraderEmail: PropTypes.string,
  onRemove: PropTypes.func,
};

export default RemoveDelegatedUserModal;
