import { Divider, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { UISegment } from '../../../styles/styled';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  & .field > .field {
    margin-bottom: 0px;
  }
  & .field > .helper {
    position: absolute;
  }

  & .helper {
    position: absolute;
  }

  & > .helper {
    position: absolute;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 2rem !important;
`;

export { Row, StyledDivider };
