import get from 'lodash/get';
import {
  DeliveryContractView,
  DeliverySummaryData,
} from '../../ts/models/Deliveries/Delivery.model';
import { DeliveryTableRowLS } from '../../ts/models/Deliveries/DeliveryTableRow.model';
import parseDeliveryAccountViews from './parseDeliveryAccountViews';

/**
 * @description Returns summary data of a given contractView
 * @param contractView - DeliveryContractView to parse
 * @returns DeliveryTableRow object corresponding to the passed contractView's summary data
 */
export const getSummaryData = (
  contractView: DeliveryContractView,
  summaryType: DeliveryTableRowLS,
  summaryData?: DeliverySummaryData,
) => {
  const {
    productSymbol,
    contractSymbol,
    maturityDate,
    deliveryType,
    accountView,
  } = contractView;

  return {
    productSymbol,
    contractSymbol,
    deliveryType,
    maturityDate,
    ls: summaryType,
    qty: get(summaryData, ['qty']),
    price: get(summaryData, ['price']),
    deliveryAsset: get(summaryData, ['deliveryAsset']),
    deliveryRequirement: get(summaryData, ['deliveryRequirement']),
    deliveryAmount: get(summaryData, ['deliveryAmount']),
    deliveryBalance: get(summaryData, ['deliveryBalance']),
    satisfied: get(summaryData, ['satisfied']),
    subRows: parseDeliveryAccountViews(accountView, contractView, summaryType),
  };
};
