import React from 'react';
import { isEmpty } from 'lodash';
import {
  Icon,
  Grid,
  GridColumn,
  GridRow,
  Segment,
  Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FiatDepositDetails = ({
  bankDetails,
  bankDetailsLoading,
  refCode,
  refCodeLoading,
}) => (
  <Segment>
    <Grid celled columns="equal">
      <GridRow>
        <GridColumn width={3} textAlign="center">
          <i className="big envelope open outline icon" />
        </GridColumn>
        <GridColumn>
          <p>
            <strong>Banking Information</strong>
          </p>
          {!isEmpty(bankDetails) ? (
            <span>
              Banking information. Please send a FED wire using the following
              information including the Reference Code.
            </span>
          ) : (
            <p>Banking information. Please select asset type to view details</p>
          )}
        </GridColumn>
      </GridRow>
    </Grid>
    {!isEmpty(bankDetails) && (
      <Table definition loading={bankDetailsLoading}>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>Routing/ABA #</Table.Cell>
            <Table.Cell width={12}>{bankDetails.routingNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account #</Table.Cell>
            <Table.Cell>{bankDetails.accountNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Recipient Name</Table.Cell>
            <Table.Cell>{bankDetails.recipientName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Recipient Address</Table.Cell>
            <Table.Cell>{bankDetails.recipientAddress}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Recipient Bank</Table.Cell>
            <Table.Cell>{bankDetails.recipientBank}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Bank Address</Table.Cell>
            <Table.Cell>{bankDetails.bankAddress}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Reference Code*</Table.Cell>
            <Table.Cell>
              {refCodeLoading ? (
                <Icon loading name="circle notch" color="grey" />
              ) : (
                refCode
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )}
  </Segment>
);

FiatDepositDetails.propTypes = {
  bankDetails: PropTypes.objectOf(PropTypes.string),
  bankDetailsLoading: PropTypes.bool,
  refCode: PropTypes.string,
  refCodeLoading: PropTypes.bool,
};

FiatDepositDetails.defaultProps = {
  bankDetails: {},
  bankDetailsLoading: false,
  refCode: '',
  refCodeLoading: false,
};

export default FiatDepositDetails;
