import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  Segment,
  Tab,
  Dropdown,
  Input,
  Label,
  Button,
  Header,
  Icon,
} from 'semantic-ui-react';
import { get } from 'lodash';
import {
  getMessage,
  BUY,
  SELL,
  transactionTypes,
} from '../../containers/OrderEntry/OrderEntryWidgetContainer';
import { userFirstTraded, userFirstFunded } from '../../common/glowRules';
import classNames from 'classnames';
import InputWithSelect from '../../common/components/InputWithSelect';
import {
  DEFAULT_QUOTE_CURRENCY,
  DEFAULT_BASE_CURRENCY,
} from '../../constants/currencies';

export const CurrencyDropdown = ({ value, onChange, currencies }) => (
  <Dropdown
    // defaultValue={DEFAULT_QUOTE_CURRENCY}
    options={currencies}
    value={value}
    onChange={onChange}
  />
);

export const buySellPanes = ({
  assetType,
  securityListOptions,
  assetChanged,
  handleChange,
  amount,
  isBuyDisabled,
  submitClicked,
  getOrderQty,
  marketUnavailable,
  side,
  user,
  currency,
  buyButtonClicked,
  handleChangeQuoteCurrency,
  quoteCurrency,
  quoteCurrencies,
  quoteCurrencyOptions,
  baseCurrencyOptions,
}) => (
  <Tab.Pane style={{ padding: 0, backgroundColor: '#15446e' }}>
    <InputWithSelect
      label="I want to buy"
      placeholder="Enter an amount"
      inputProps={{
        onChange: handleChange,
        inputValue: getOrderQty(),
        placeholder: 'Enter an amount',
      }}
      selectProps={{
        options: baseCurrencyOptions,
        defaultOption: DEFAULT_BASE_CURRENCY,
        onChangeCallback: assetChanged,
      }}
    />
    <InputWithSelect
      label="for"
      inputProps={{
        onChange: handleChange,
        placeholder: 'Enter an amount',
      }}
      selectProps={{
        options: quoteCurrencyOptions,
        defaultOption: DEFAULT_QUOTE_CURRENCY,
      }}
    />
    <Button
      style={{
        backgroundColor:
          transactionTypes[side].value === BUY ? '#2D95FF' : '#FF274B',
        color: 'white !important',
      }}
      className={classNames({
        'pulse-button':
          assetType &&
          amount &&
          !userFirstTraded(user) &&
          !buyButtonClicked &&
          userFirstFunded(user),
      })}
      fluid
      content={get(transactionTypes, [side, 'text'], '')}
      disabled={isBuyDisabled()}
      onClick={submitClicked}
    />
    <div className="vert-flex">
      {isBuyDisabled() ? (
        <>
          <small className="helper info">
            An order will be placed to {side} the selected currency{' '}
            {side === BUY ? 'with' : 'in exchange for'} {quoteCurrency} at the
            best available price. Please be advised that you may not cancel a
            trade once confirmed as executed.
          </small>
          <small>
            Please be advised that you may not cancel a trade once confirmed as
            executed.
          </small>
        </>
      ) : (
        <small className="helper info">
          {getMessage({
            side,
            orderQty: getOrderQty(),
            assetType,
            amount,
            currency,
          })}
        </small>
      )}
      {marketUnavailable && (
        <small className="helper error">
          Market currently not available. Please choose another asset.
        </small>
      )}
    </div>
  </Tab.Pane>
);

export default ({ tabChanged, activeIndex, ...restProps }) => (
  <Segment style={{ backgroundColor: '#15446e' }}>
    {isMobileOnly ? null : (
      <div className="order-entry-header">
        <Header as="h2" content="Quick Trade" />
        <Icon name="exchange" size="large" />
      </div>
    )}
    <Tab
      onTabChange={tabChanged}
      activeIndex={activeIndex}
      menu={{ secondary: true }}
      style={{ backgroundColor: '#15446e' }}
      panes={[
        {
          menuItem: 'Buy',
          render: () => buySellPanes({ side: BUY, ...restProps }),
        },
        {
          menuItem: 'Sell',
          render: () => buySellPanes({ side: SELL, ...restProps }),
        },
      ]}
    />
  </Segment>
);
