import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import {
  submitSingleSidedBlockTrade,
  submitTwoSidedBlockTrade,
  fetchBlockTradeRequests,
  fetchBlockTradeRequestsV2,
  approveBlockTrade,
  rejectBlockTrade
} from '../reducers/BlockTrades/blockTradesReducer';

export default function* watch() {
  yield takeLatest(
    submitTwoSidedBlockTrade,
    generateSaga(submitTwoSidedBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Submitting Two Sided Block Trade Registration',
      success: 'Registration Successful',
    }),
  );
  yield takeLatest(
    submitSingleSidedBlockTrade,
    generateSaga(submitSingleSidedBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Submitting Single Side Block Trade Registration',
      success: 'Registration Successful',
    }),
  );
  yield takeLatest(
    fetchBlockTradeRequests,
    generateSaga(fetchBlockTradeRequests, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    fetchBlockTradeRequestsV2,
    generateSaga(fetchBlockTradeRequestsV2, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    approveBlockTrade,
    generateSaga(approveBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Approving Block Trade Request',
      success: 'Block Trade Request Approved',
    }),
  );
  yield takeLatest(
    rejectBlockTrade,
    generateSaga(rejectBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Rejecting Block Trade Request',
      success: 'Block Trade Request Rejected',
    }),
  );
}
