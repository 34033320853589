import { fetchFcmAccountMovements } from '../reducers/fcmAccountMovementsReducer';
import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(
    fetchFcmAccountMovements,
    generateSaga(fetchFcmAccountMovements),
  );
}
