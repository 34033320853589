import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import Page from '../../common/Page';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { deliveriesMetadata } from '../../metadata/deliveriesMetadata';
import {
  fetchMarginDelivery,
  getDeliveriesTableRows,
  MARGIN_DELIVERY,
} from '../../reducers/deliveriesReducer';
import DeliveryTableRow from '../../ts/models/Deliveries/DeliveryTableRow.model';
import { FetchOptions, ServerSideFilter } from '../../common/EMPTable8/types';
import { getActiveOrFirstAccountId } from '../../reducers/activeAccountSelectors';
import { TableActionName } from '../../common/EMPTable8/TableActions/types';
import AccountSelectorFCM from '../AccountSelector';

const Deliveries = () => {
  const dispatch = useDispatch();
  const data = useSelector(getDeliveriesTableRows);
  const accountId: string = useSelector(getActiveOrFirstAccountId);

  const defaultFilter: ServerSideFilter = useMemo(
    () => ({
      attr: 'account_id',
      value: accountId,
      op: 'eq',
    }),
    [accountId],
  );

  const loading = useSelector((state) =>
    createLoadingSelector([MARGIN_DELIVERY])(state),
  );

  const fetchDeliveries = useCallback(
    (options: FetchOptions) => {
      if (accountId) {
        options?.filter?.push(defaultFilter);
        dispatch(fetchMarginDelivery({ ...options }));
      }
    },
    [defaultFilter, accountId],
  );

  const getSubRows = useCallback((row: DeliveryTableRow) => row.subRows, []);

  return (
    <Page header="Deliveries">
      <AccountSelectorFCM />
      <EMPTable8
        title="deliveriesTable"
        data={data}
        columns={deliveriesMetadata}
        fetchData={fetchDeliveries}
        getSubRows={getSubRows}
        count={data?.length || 0}
        loading={loading}
        loadingText="Fetching Deliveries"
        noDataText="No Deliveries found"
        minimumRows={5}
        paginated
        showActiveFilters
        showActions
        actionsToHide={[TableActionName.Filters]}
        dataCy="deliveries-table"
      />
    </Page>
  );
};

export default Deliveries;
