import React, { Fragment } from 'react';

const WEEKDAYS_SHORT = [
  { text: 'M', value: 1 },
  { text: 'T', value: 2 },
  { text: 'W', value: 3 },
  { text: 'T', value: 4 },
  { text: 'F', value: 5 },
];

const WeekDays = ({ label, input, weekDay, required = false }) => {
  return (
    <div>
      <label>
        {label} <span style={{ color: 'red' }}>{required ? '*' : ''}</span>
      </label>
      <div style={{ display: 'flex' }}>
        {WEEKDAYS_SHORT.map((day) => (
          <div
            style={{
              width: '37px',
              height: '25px',
              color: weekDay && weekDay === day.value ? '#002C46' : 'white',
              background:
                weekDay && weekDay === day.value ? 'white' : '#002C46',
              borderRadius: '2px',
              cursor: 'pointer',
              textAlign: 'center',
              marginRight: '3px',
            }}
          >
            <div
              style={{ marginTop: '2px' }}
              onClick={() => {
                input.onChange(day.value);
              }}
              data-cy={`week-day-${day.value}`}
            >
              {day.text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekDays;
