import React, { useEffect, useMemo } from 'react';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import { Row } from 'react-table';
import styled from 'styled-components';
import {
  fetchBlockTradeRequestsV2,
  getBlockTradeRequests,
  approveBlockTradePromise,
  rejectBlockTradePromise
} from '../../reducers/BlockTrades/blockTradesReducer';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import Page from '../../common/Page';
import blockTradeRequestsMetadata, { blockTradeRequestsConfirmMetadata } from '../../metadata/spotBlockTradeRequestsMetadata';
import { APPROVE_REJECT_BLOCK_TRADE_REQUEST } from '../../constants/modalTypes';
import { getActiveAccountLabel } from '../../reducers/activeAccountSelectors';
import { approveSpotBlockTradeRequestForm, rejectBlockTradeRequestForm } from '../BlockTradesRegistration/ApproveRejectBlockTradeRequest';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

const Container = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const SpotBlockTradeRegistration = () => {
  const dispatch = useAppDispatch();
  const blockTradeRequests: BlockTradeRequestModel[] = useAppSelector(getBlockTradeRequests);
  const activeAccountLabel = useAppSelector(getActiveAccountLabel);

  const loading = useAppSelector((state) =>
    createLoadingSelector([fetchBlockTradeRequestsV2._PREFIX])(state),
  );

  useEffect(() => {
    if (activeAccountLabel) {
      dispatch(fetchBlockTradeRequestsV2({ accountLabel: activeAccountLabel }));
    }
  }, [activeAccountLabel, dispatch]);

  const onSave = (action: string, row: BlockTradeRequestModel, values: approveSpotBlockTradeRequestForm | rejectBlockTradeRequestForm) => {
    const promiseFunction = action === 'Approve' ? approveBlockTradePromise : rejectBlockTradePromise;
    dispatch(hideModal());
    promiseFunction({ urlParams: { requestId: row.requestId }, ...values }, dispatch)
      .then(() => {
        dispatch(fetchBlockTradeRequestsV2({ accountLabel: activeAccountLabel }));
      });
  };

  const onApproveRejectClicked = (row: Row<BlockTradeRequestModel>, action: string) => {
    dispatch(
      showModal(APPROVE_REJECT_BLOCK_TRADE_REQUEST, {
        size: 'large',
        row: row?.original,
        memoizedConfirmColumns,
        action,
        isSpot: true,
        onSave: (action: string, row: BlockTradeRequestModel, values: approveSpotBlockTradeRequestForm | rejectBlockTradeRequestForm) => { onSave(action, row, values) }
      }),
    );
  }

  const memoizedColumns = useMemo(() => {
    return blockTradeRequestsMetadata(activeAccountLabel, onApproveRejectClicked);
  }, [activeAccountLabel]);

  const memoizedConfirmColumns = useMemo(() => {
    return blockTradeRequestsConfirmMetadata();
  }, [activeAccountLabel]);

  return (
    <>
      <Page header={`BlockTrade Requests`}></Page>
      <Container>
        <EMPTable8
          title="BlockTradeRequests"
          data={blockTradeRequests}
          local
          columns={memoizedColumns}
          fetchData={() => { }}
          count={blockTradeRequests?.length}
          loading={loading}
          loadingText="Fetching BlockTrade Requests"
          minimumRows={5}
          showActiveFilters
        />
      </Container>
    </>
  )
};

export default SpotBlockTradeRegistration;
