import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SelectMemberModal from '../components/SelectMemberModal';
import { hideModal } from 'erisxkit/client';
import { selectMember } from '../../reducers/membersReducer';
import history from '../../constants/history';

const mapDispatchToProps = {
  selectMember,
  hideModal,
};

class SelectMemberModalContainer extends PureComponent {
  selectMember = (memberId) => {
    const { selectMember, hideModal } = this.props;
    selectMember(memberId);
    localStorage.setItem('erisx_member', memberId);
    history.push('/home');
    hideModal();
    if (typeof this.props.selectMemberCallback === 'function') {
      this.props.selectMemberCallback(memberId);
    }
  };

  render = () => (
    <SelectMemberModal
      {...this.props}
      memberClicked={(memberId) => this.selectMember(memberId)}
    />
  );
}

export default connect(null, mapDispatchToProps)(SelectMemberModalContainer);
