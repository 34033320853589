import { css, keyframes } from 'styled-components';
import colors from '../constants/colors';

//FADE IN - FADE OUT ANIMATIONS
const fadeInKeyframes = keyframes`
    from { opacity: 0; }
    to { opacity: 1;}
`;

const fadeOutKeyframes = keyframes`
    from { opacity: 1; }
    to { opacity: 0;}
`;

export const fadeIn = css`
  animation: ${fadeInKeyframes} 0.5s forwards ease;
`;

export const fadeOut = css`
  animation: ${fadeOutKeyframes} 0.5s forwards ease;
`;

//BUTTON PULSE EFFECT
const pulseKeyframes = keyframes` 
    0% {
      box-shadow: 0 0 0 0 ${colors.GREEN}, 0 0 0 0 ${colors.GREEN};
    }
    40% {
      box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 0 ${colors.GREEN};
    }
    80% {
      box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
    }
`;

export const pulse = css`
  animation: ${pulseKeyframes} 3s infinite;
`;
