import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import EnvPromise from '../config/env';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';
import { showModal } from 'erisxkit/client';
import history from '../constants/history';
import AccessControl, { checkPermissions } from '../common/AccessControl';
import {
  grants,
  subjects,
  TRADE_PERMISSIONS,
} from '../constants/userPermissions';
import { userFirstFunded, userIsOnDepositForm } from '../common/glowRules';
import { ACCOUNT_SELECTION, ORDER_ENTRY } from '../constants/modalTypes';
import { getUserCanMakeInternalTransfers } from '../reducers/userReducer';

const fundingOptions = ({
  dispatch,
  accounts = [],
  userPermissions,
  hasInternalTransfers,
}) => [
  {
    key: 'deposit',
    icon: 'arrow down',
    text: 'Deposit',
    selected: false,
    disabled: !checkPermissions(userPermissions, [
      `${grants.CREATE}:${subjects.DEPOSITS}`,
    ]),
    onClick: () => {
      if (
        checkPermissions(userPermissions, [
          `${grants.CREATE}:${subjects.DEPOSITS}`,
        ])
      ) {
        accounts.length > 1
          ? dispatch(
              showModal(ACCOUNT_SELECTION, {
                size: 'mini',
                onAccountClick: ({ accountId }) => {
                  history.push(`/accounts/${accountId}/deposit`);
                  localStorage.setItem('erisx_account', accountId);
                },
              }),
            )
          : history.push(`/accounts/${accounts[0].accountId}/deposit`);
      }
    },
  },
  {
    key: 'withdraw',
    icon: 'arrow up',
    text: 'Withdraw',
    disabled: !checkPermissions(userPermissions, [
      `${grants.CREATE}:${subjects.WITHDRAWALS}`,
    ]),
    onClick: () => {
      if (
        checkPermissions(userPermissions, [
          `${grants.CREATE}:${subjects.WITHDRAWALS}`,
        ])
      ) {
        accounts.length > 1
          ? dispatch(
              showModal(ACCOUNT_SELECTION, {
                size: 'mini',
                onAccountClick: ({ accountId }) =>
                  history.push(`/accounts/${accountId}/withdrawal`),
              }),
            )
          : history.push(`/accounts/${accounts[0].accountId}/withdrawal`);
      }
    },
  },
  ...(hasInternalTransfers
    ? [
        {
          key: 'internal',
          icon: 'exchange',
          text: 'Internal Transfer',
          disabled: !checkPermissions(userPermissions, [
            `${grants.CREATE}:${subjects.WITHDRAWALS}`,
          ]),
          onClick: () => {
            if (
              checkPermissions(userPermissions, [
                `${grants.CREATE}:${subjects.WITHDRAWALS}`,
              ])
            ) {
              history.push(`/transfers`);
            }
          },
        },
      ]
    : []),
];

const tradingOptions = ({ tradeProps, enableTradeWidget, dispatch }) => {
  const options = [
    {
      key: 'adv_trade',
      icon: 'line graph',
      text: 'Advanced Trade',
      onClick: tradeProps.onClick,
    },
  ];
  if (enableTradeWidget === 'true') {
    const quickTrade = {
      key: 'quick_trade',
      icon: 'rocket',
      text: 'Quick Trade',
      selected: false,
      onClick: () =>
        dispatch(
          showModal(ORDER_ENTRY, {
            showAsModal: true,
            size: 'mini',
          }),
        ),
    };
    options.unshift(quickTrade);
  }
  return options;
};

const FundButton = styled(Dropdown).attrs({
  id: 'funds-actions-dropdown-button',
  'data-cy': 'funds-actions-dropdown-button',
})`
  border-radius: 50px !important;
  width: 110px;
  height: 40px;
  display: flex !important;
  justify-content: space-evenly;
  font-size: 1.1em !important;
  margin-right: 10px !important;
`;

const TradeButton = styled(Dropdown).attrs({
  id: 'trade-actions-dropdown-button',
})`
  border-radius: 50px !important;
  width: 110px;
  height: 40px;
  display: flex !important;
  justify-content: space-evenly;
  font-size: 1.1em !important;
  background-color: ${({ theme }) => theme.button.primary.bgColor} !important;
  color: ${({ theme }) => theme.button.primary.color} !important;
`;

const DropdownText = styled.div`
  margin-left: 20px;
`;

const dropdownButtons = ({
  accounts,
  tradeProps,
  dispatch,
  userPermissions,
  user,
  hasInternalTransfers,
  enableTradeWidget,
}) => [
  <AccessControl
    allowedPermissions={[
      `${grants.CREATE}:${subjects.DEPOSITS}`,
      `${grants.CREATE}:${subjects.WITHDRAWALS}`,
    ]}
    op="some"
  >
    <FundButton
      text={<DropdownText>FUND</DropdownText>}
      className={classNames({
        icon: true,
        secondary: true,
        'pulse-button': !userIsOnDepositForm() && !userFirstFunded(user),
      })}
      key="fund"
      button
      options={fundingOptions({
        dispatch,
        accounts,
        userPermissions,
        hasInternalTransfers,
      })}
      selectOnBlue={false}
    />
  </AccessControl>,
  <AccessControl allowedPermissions={TRADE_PERMISSIONS}>
    <TradeButton
      className="icon primary"
      text={<DropdownText>TRADE</DropdownText>}
      button
      options={tradingOptions({
        tradeProps,
        dispatch,
        userPermissions,
        enableTradeWidget,
      })}
    />
  </AccessControl>,
];

const DepositWithdrawalActions = ({
  accounts,
  user,
  tradeProps,
  loading,
  userPermissions,
}) => {
  const dispatch = useDispatch();
  const hasInternalTransfers = useSelector(getUserCanMakeInternalTransfers);
  const [enableTradeWidget, setEnableTradeWidget] = useState('');

  useEffect(() => {
    EnvPromise.then((env) => setEnableTradeWidget(env.enableTradeWidget));
  }, []);

  const buttons = dropdownButtons({
    accounts,
    tradeProps,
    dispatch,
    userPermissions,
    user,
    hasInternalTransfers,
    enableTradeWidget,
  });

  return !loading && accounts.length > 0 ? (
    <Fragment>
      <div
        style={{
          display: 'flex',
          gridArea: 'dw',
          margin: '3px',
          justifyContent: 'space-evenly',
        }}
      >
        {buttons}
      </div>
    </Fragment>
  ) : null;
};

DepositWithdrawalActions.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  loading: PropTypes.bool,
  tradeProps: PropTypes.shape({
    isExternal: PropTypes.bool,
    onClick: PropTypes.func,
  }),
};

DepositWithdrawalActions.defaultProps = {
  accounts: [],
  loading: false,
  tradeProps: {},
};

export default DepositWithdrawalActions;
