import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../constants/colors';

const Button = styled.p`
  display: inline;
  cursor: pointer;
  color: ${({ theme, primary, danger, color }) => {
    if (!!color) return color;
    if (danger) return theme.accentNegative;
    return theme.accent;
  }};
  opacity: ${({ disabled }) => disabled && '0.5'};
  font-size: ${(props) => `${props.fontSize}px`};
  pointer-events: ${(props) => props.disabled && 'none'};
  font-weight: ${(props) => (props.bold ? 'bold' : 500)};

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.color};
    text-decoration-thickness: 2px;
  }
`;

const TextButton = ({
  text,
  onClick,
  fontSize,
  color,
  disabled,
  bold,
  className,
  primary = false,
  danger = false,
  tabIndex = '0',
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onClick();
    }
  };

  return (
    <Button
      onClick={onClick}
      onKeyDown={handleKeyDown}
      fontSize={fontSize}
      color={color}
      disabled={disabled}
      bold={bold}
      className={className}
      primary={primary}
      danger={danger}
      tabIndex={tabIndex}
    >
      {text}
    </Button>
  );
};

TextButton.defaultProps = {
  fontSize: '17',
  color: null,
  disabled: false,
  bold: false,
  className: '',
  danger: false,
};

TextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  className: PropTypes.string,
  danger: PropTypes.bool,
};

export default TextButton;
