import first from 'lodash/first';
import {
  DeliveryAccountView,
  DeliveryContractView,
} from '../../ts/models/Deliveries/Delivery.model';
import DeliveryTableRow, {
  DeliveryTableRowLS,
} from '../../ts/models/Deliveries/DeliveryTableRow.model';
import parseDeliveryCgmViews from './parseDeliveryCgmViews';

/**
 * @description From a single DeliveryAccountView we get many DeliveryTableRow objects.
 * This function receives a list of DeliveryAccountView and returns the complete row list
 * @param accountView - List of DeliveryAccountView to parse
 * @param contractView - DeliveryContractView to wich the DeliveryAccountView belongs
 * @param summaryType - Defines if it uses long or short summary
 * @returns List of DeliveryTableRow objects corresponding to the passed list of DeliveryAccountView
 */

const parseDeliveryAccountViews = (
  accountViews: DeliveryAccountView[],
  contractView: DeliveryContractView,
  summaryType: DeliveryTableRowLS,
): DeliveryTableRow[] => {
  // Only one account is expected for this view
  const accountView = first(accountViews);
  if (!accountView) return [];
  const cgmViews = accountView.cgmView || [];
  return parseDeliveryCgmViews(
    cgmViews,
    accountView.accountLabel,
    contractView,
    summaryType,
  );
};

export default parseDeliveryAccountViews;
