enum TableCellTextTransform {
  None = 'none',
  Capitalize = 'capitalize',
  Uppercase = 'uppercase',
  Lowercase = 'lowercase',
  FullWidth = 'full-width',
  FullSizeKana = 'full-size-kana',
}

export default TableCellTextTransform;
