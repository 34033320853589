import { DeliveryContractView } from '../../ts/models/Deliveries/Delivery.model';
import DeliveryTableRow from '../../ts/models/Deliveries/DeliveryTableRow.model';
import { getSummaryData } from './getSummaryData';

/**
 * @description From a single DeliveryContractView we get many DeliveryTableRow objects.
 * A main one for the contract, and multiple others for the long/short summaries of the
 * corresponding DeliveryAccountView and DeliveryCgmView
 * @param contractView - DeliveryContractView to parse
 * @returns DeliveryTableRow object corresponding to the passed contractView
 */
const parseDeliveryContractViews = (
  contractViews: DeliveryContractView[],
): DeliveryTableRow[] => {
  const rows: DeliveryTableRow[] = [];
  contractViews.forEach((contractView) => {
    const {
      productSymbol,
      contractSymbol,
      maturityDate,
      deliveryType,
      summary: { long, short },
    } = contractView;
    // main row
    rows.push({
      productSymbol,
      contractSymbol,
      deliveryType,
      maturityDate,
      subRows: [
        // long summaries
        getSummaryData(contractView, 'long', long),
        // short summaries
        getSummaryData(contractView, 'short', short),
      ],
    });
  });

  return rows;
};

export default parseDeliveryContractViews;
