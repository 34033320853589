import React from 'react';
import GoHome from './GoHome';

const Unauthorized = () => {
  return (
    <GoHome
      header="Access Denied"
      message="You do not have the necessary permissions to access this view."
    />
  );
};

export default Unauthorized;
