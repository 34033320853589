import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';
import get from 'lodash/get';
import TableFieldTypes from '../ts/enums/TableFieldTypes.enum';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import {
  ColumnCreationOptions,
  ColumnEditOptions,
} from '../common/EMPTable8/types';
import {
  expiryLimits,
  getAvailableExpiryLimits,
  DEFAULT_EXPIRY_LIMIT_INDEX,
  EXPIRING_POSITION_LIMIT,
  getExpiryLimitLabel,
  carTypeOptions,
  getAvailableProducts,
} from '../components/PreTradeRisk/menuOptions';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { CAR, Product } from '../ts/models/CAR.model';
import TableFieldPattern from '../ts/enums/TableFieldPattern.enum';
import {
  currentProductExpiries,
  currentProductSelected,
} from '../components/PreTradeRisk/utils';
import { StyledSelectOption } from '../common/StyledSelect';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import {
  formatAccountingValue,
  formatDateTimeLocalIso8601,
} from '../utils/formatters';

const columnHelper = createColumnHelper<CAR>();

export const preTradeRiskColumns = (
  cgms: StyledSelectOption[],
  carProducts: StyledSelectOption[],
  canWrite: boolean,
) =>
  [
    columnHelper.accessor('customerAccountReference', {
      id: 'customerAccountReference',
      header: 'Customer Account Ref',
      minSize: 170,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('carType', {
      id: 'carType',
      header: 'Type',
      minSize: 65,
      size: 70,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          depthUpperBound: 0,
          options: carTypeOptions,
        },
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('selfMatchPrevention', {
      id: 'selfMatchPrevention',
      header: 'Self Match Prevention',
      minSize: 150,
      meta: {
        type: TableFieldTypes.Checkbox,
        isChecked: (row: Row<CAR>) =>
          get(row, 'original.selfMatchPrevention', false),
        align: TableCellAlignment.Center,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Checkbox,
          depthUpperBound: 0,
        },
      },
    }),
    columnHelper.accessor('defaultFuturesMaxOrderSize', {
      id: 'defaultFuturesMaxOrderSize',
      header: 'Default Max Order Size',
      minSize: 145,
      size: 145,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Input,
          depthUpperBound: 0,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor('cgmNumber', {
      id: 'cgmNumber',
      header: 'CGM',
      minSize: 75,
      enableColumnFilter: true,
      meta: {
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          options: cgms,
          depthUpperBound: 0,
        },
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.display({
      id: 'expandProducts',
      minSize: 30,
      maxSize: 30,
      meta: {
        expandOptions: {
          canExpand: true,
          depth: 0,
        },
      },
    }),
    columnHelper.accessor('productCode', {
      id: 'productCode',
      header: 'Product',
      minSize: 60,
      meta: {
        createCTA: 'Add New Product',
        addCTA: 'Add Expiry',
        deleteCTA: 'Delete',
        type: TableFieldTypes.Text,
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Select,
          options: (row: Row<CAR>, parentRows: Row<CAR>[]) => {
            if (parentRows?.length === 1) {
              const originalParent = get(parentRows, '[0].original', {});
              const selectedProducts = currentProductSelected(originalParent);
              return getAvailableProducts(carProducts, selectedProducts);
            }
            return carProducts;
          },
          placeholder: 'Select Product',
          depthUpperBound: 1,
        } as ColumnCreationOptions<CAR>,
        expandOptions: {
          canExpand: true,
          depth: 1,
        },
      },
    }),
    columnHelper.accessor('expirationMonth', {
      id: 'expirationMonth',
      header: 'Expiry Limit',
      minSize: 80,
      meta: {
        createCTA: 'Add Expiry',
        type: TableFieldTypes.Number,
        showValue: (expirationMonth: number) => {
          if (!expirationMonth && expirationMonth !== 0) return null;
          const expiryLimit = String(expirationMonth);
          if (expiryLimit === EXPIRING_POSITION_LIMIT) return 'Expiring';
          if (expiryLimit === DEFAULT_EXPIRY_LIMIT_INDEX) return 'Default';
          return getExpiryLimitLabel(expiryLimit);
        },
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Select,
          placeholder: 'Select Expiry',
          options: (row: Row<CAR>, parentRows: Row<CAR>[]) => {
            if (parentRows?.length === 2) {
              // Is an expiry-only row
              const product: Product = get(parentRows, '[1].original', {});
              const currentExpiries = currentProductExpiries(product);
              return getAvailableExpiryLimits(currentExpiries);
            }
            return expiryLimits;
          },
          depthLowerBound: 2,
        } as ColumnCreationOptions<CAR>,
        expandOptions: {
          canExpand: true,
          depth: 2,
        },
      },
    }),
    columnHelper.accessor('maxOrderSize', {
      id: 'maxOrderSize',
      header: 'Max Order Size',
      minSize: 100,
      size: 100,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          component: TableInputTypes.Input,
          canEdit: canWrite,
          depthLowerBound: 1,
          depthUpperBound: 1,
        } as ColumnEditOptions<CAR>,
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Input,
          depthLowerBound: 1,
          depthUpperBound: 1,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor('maxShortExposure', {
      id: 'maxShortExposure',
      header: 'Max Short',
      minSize: 70,
      size: 80,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          component: TableInputTypes.Input,
          canEdit: canWrite,
          depthLowerBound: 1,
        } as ColumnEditOptions<CAR>,
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Input,
          depthLowerBound: 1,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor('maxLongExposure', {
      id: 'maxLongExposure',
      header: 'Max Long',
      minSize: 70,
      size: 80,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          component: TableInputTypes.Input,
          canEdit: canWrite,
          depthLowerBound: 1,
        } as ColumnEditOptions<CAR>,
        createOptions: {
          canCreate: true,
          depthLowerBound: 1,
          component: TableInputTypes.Input,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor((row) => formatDateTimeLocalIso8601(row?.updatedAt), {
      id: 'updatedAt',
      header: 'Updated At',
      minSize: 175,
    }),
    columnHelper.accessor('lastUpdatedBy', {
      id: 'lastUpdatedBy',
      header: 'Last Updated By',
      minSize: 150,
    }),
  ] as ColumnDef<CAR>[];

export default preTradeRiskColumns;
