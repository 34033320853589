import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatFiat } from '../../utils/methods';
import { USD } from '../../constants/assetTypes';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { getOnPriceChangeStyle } from '../../components/SimpleAssetBalances';
import usePriceChange from '../../hooks/usePriceChange';

const useStyles = makeStyles({
  root: {
    background: `${colors.MEDIUM_NAVY}`,
    margin: '1rem 1rem  1rem 0rem',
    width: '100%',
    borderLeft: `5px solid ${colors.GREEN}`,
    padding: '12px 8px 12px 4px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
});

const Currency = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin: 0 0 0 5px;
  @media (max-width: 320px) {
    font-size: 15px;
  }
`;
Currency.displayName = 'Currency';

const CurrencyContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
CurrencyContainer.displayName = 'CurrencyContainer';

const PriceContainer = styled.div`
  flex: 1 1 0px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;
PriceContainer.displayName = 'PriceContainer';

const Price = styled.span`
  font-size: 18px;
  text-align: right;
  margin: 0;
  font-weight: bold;
  @media (max-width: 320px) {
    font-size: 15px;
  }
`;
Price.displayName = 'Price';

const Quantity = styled.p`
  font-size: 15px;
  text-align: right;
  flex: 1 1 0px;
`;
Quantity.displayName = 'Quantity';

const MobileCurrencyCard = ({ closingBalance, price, currency, onClick }) => {
  const classes = useStyles();
  const { symbol } = currency;
  const priceChange = usePriceChange(price);
  const priceRef = useRef();

  // remove animation from price tag in case it decreases/increases many times in a row
  const onPriceAnimationEnd = () => {
    priceRef.current.className.replaceAll('flash-price-up', '');
    priceRef.current.className.replaceAll('flash-price-down', '');
  };

  const currencyPrice = price ? formatFiat(price.toString(), USD) : '-';

  return (
    <Card
      className={classes.root}
      elevation={10}
      onClick={() => onClick(symbol)}
    >
      <CardContent className={classes.content}>
        <CurrencyContainer>
          <span className={`crypto-icon-${symbol}`} />
          <Currency>{symbol}</Currency>
        </CurrencyContainer>
        <PriceContainer>
          <Price
            className={getOnPriceChangeStyle(priceChange)}
            ref={priceRef}
            onAnimationEnd={onPriceAnimationEnd}
          >
            {currencyPrice}
          </Price>
        </PriceContainer>
        <Quantity>{`${closingBalance} ${symbol}`}</Quantity>
      </CardContent>
    </Card>
  );
};

MobileCurrencyCard.defaultProps = {
  onClick: () => {},
};

MobileCurrencyCard.propTypes = {
  closingBalance: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isFiat: PropTypes.bool.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default MobileCurrencyCard;
