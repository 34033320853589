import { call, takeLatest, put } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import {
  newOrderSingle,
  topOfBook,
  getOrder,
  partyList,
  securityList,
  marginProducts,
} from '../reducers/orderEntryReducer';
import * as urls from '../api';

function* newOrderSingleSaga({ payload }) {
  try {
    yield put(newOrderSingle.request());
    const user = yield call(urls.webApi.post, urls.NEW_ORDER_SINGLE, payload);
    yield put(newOrderSingle.success(user));
  } catch (e) {
    yield put(newOrderSingle.failure());
  }
}

function* partyListSaga() {
  try {
    const partyIds = yield call(urls.webApi.post, urls.PARTY_LIST);
    yield put(partyList.success(partyIds));
  } catch (e) {
    yield put(partyList.failure());
  }
}

function* securityListSaga() {
  try {
    const securityListData = yield call(urls.webApi.post, urls.SECURITY_LIST);
    yield put(securityList.success(securityListData));
  } catch (e) {
    yield put(securityList.failure());
  }
}

function* topOfBookSaga({ payload }) {
  try {
    yield put(topOfBook.request());
    const user = yield call(urls.webApi.post, urls.TOP_OF_BOOK, payload);
    yield put(topOfBook.success(user));
  } catch (e) {
    yield put(topOfBook.failure());
  }
}

function* getOrderSaga({ payload }) {
  try {
    yield put(getOrder.request());
    const user = yield call(urls.webApi.post, urls.GET_ORDER, payload);
    yield put(getOrder.success(user));
  } catch (e) {
    yield put(getOrder.failure());
  }
}

export default function* watchContractsSaga() {
  yield takeLatest(newOrderSingle.TRIGGER, newOrderSingleSaga);
  yield takeLatest(topOfBook, topOfBookSaga);
  yield takeLatest(getOrder, getOrderSaga);
  yield takeLatest(partyList, partyListSaga);
  yield takeLatest(securityList, securityListSaga);
  yield takeLatest(marginProducts, generateSaga(marginProducts));
}
