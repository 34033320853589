import React, { useState, useEffect, Fragment, useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Icon, Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import { PII_NAME } from '../constants/ddActionNames';
import colors from '../constants/colors';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { ORIENTATIONS } from '../reducers/uiReducer';
import Auth from '../services/Auth';
import StyledHeader from '../containers/TopNavContainer/styled';
import MemberDropdown from '../common/components/MemberDropdown';

const MIN_CHAR_LENGTH = 23;

const calcWidthForChar = (maxLengthAccountName: number) => {
  const baseLeftPadding = 45;
  const pixelPerCharacter = 7.8;
  return baseLeftPadding + pixelPerCharacter * maxLengthAccountName;
}

const getMaxLengthOfName = (currentAccount) => {
  return currentAccount?.name.length || MIN_CHAR_LENGTH;
}

const MemberSelectorWrapper = styled.div`
  grid-area: acc-sel;
  margin-right: ${(props) => !props.isMobile && '10px'};
  border-left: ${(props) =>
    !props.isMobile && `6px solid ${props.theme.accent}`};
  margin-left: ${(props) => !props.isMobile && '5px'};
  padding-left: 5px !important;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.isMobile && '50%'};
  width: ${(props) => (!props.isMobile) && `${calcWidthForChar(props.maxLengthAccountName)}px`};

  & > label {
    color: ${({ theme }) => theme.form.field.label.color};
  }
`;
MemberSelectorWrapper.displayName = 'MemberSelectorWrapper';

export const MemberSelector = styled(MemberDropdown)`
  &&& {
    border-bottom: none !important;
  }
  &&& > input {
    color: ${({ theme }) => theme.form.field.input.color} !important;
  }
  padding: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
Wrapper.displayName = 'TopNavMobileWrapper';

const NavRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightNav = styled.div`
  display: flex;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  padding: 10px 10px 0px 5px;
  background-color: ${colors.NAVY_2};
`;

const GiftIcon = styled(Icon)`
  color: ${colors.GREEN};
  align-self: center;
  margin-right: 15px !important;
`;
GiftIcon.displayName = 'GiftIcon';

const MobileUserDropdown = styled(Dropdown)`
  align-self: center;
`;
MobileUserDropdown.displayName = 'MobileUserDropdown';

const NonFCMMarginFuturesStyledHeader = styled(StyledHeader)`
  justify-content: space-between !important;
`;

const getLogoClass = (simple: boolean, small: boolean) => {
  if (small) {
    return 'small-logo';
  } else if (simple && !small) {
    return 'logo';
  }

  return '';
};

const TopNavNonFCMMarginFutures = (props) => {
  const [topNavSmall, setTopNavSmall] = useState<boolean>(
    props.viewportLessThanMedium,
  );
  const [, setTopNavExtraSmall] = useState<boolean>(
    props.viewportLessThanSmall,
  );
  const [topNavMedium, setTopNavMedium] = useState<boolean>(
    (isTablet && props.orientation === ORIENTATIONS.PORTRAIT) ||
      (props.viewportLessThanLarge && !props.viewportLessThanMedium),
  );

  useEffect(() => {
    setTopNavSmall(props.viewportLessThanMedium);
    setTopNavExtraSmall(props.viewportLessThanSmall);
    setTopNavMedium(
      (isTablet && props.orientation === ORIENTATIONS.PORTRAIT) ||
        (props.viewportLessThanLarge && !props.viewportLessThanMedium),
    );
  }, [
    props.orientation,
    props.viewportLessThanLarge,
    props.viewportLessThanMedium,
    props.viewportLessThanSmall,
  ]);

  const auth = useMemo(() => new Auth(), []);

  const options = (simpleNav: boolean) => {
    const { user, userOptionClick, loading, logout } = props;
    return [
      {
        key: 'user-details',
        content: (
          <p>
            <span className="bold" data-dd-action-name={PII_NAME}>
              {_.get(user, 'pii.firstName', user.nickname)}{' '}
              {_.get(user, 'pii.lastName')}
            </span>
            <br />
            {user.email}
          </p>
        ),
      },
      {
        key: 'margin-futures-toggle',
        text: 'Return to Spot',
        icon: 'share',
        onClick: () => userOptionClick('home'),
      },
      {
        // use lowercase string for simplenav prop to silence warnings.
        key: 'sign-out',
        text: 'Sign Out',
        icon: 'sign out',
        onClick: () => logout(auth),
        simplenav: 'true',
      },
    ]
      .filter((option) => (simpleNav || loading ? option.simplenav : true))
      .filter((option) => !option.hide);
  };

  const {
    allMembers = [],
    selectedMemberId,
    loading,
    logoClick,
    simpleNav,
    user,
  } = props;

  const trigger = (
    <Fragment>
      <img
        className={`user-picture${isMobileOnly ? '-mobile' : ''}`}
        src={_.get(user, 'auth.picture', '')}
        alt="user"
      />
      <Icon name="angle down" className={topNavSmall ? 'small' : 'large'} />
    </Fragment>
  );

  const memberList = allMembers.filter(
    (m) =>
      m.entitlements.includes('allow_futures_margin_non_fcm') &&
      m.clearingMemberType !== 'FCM',
  );
  const currentMember = memberList.find((m) => m.memberId === selectedMemberId);

  return !loading && isMobileOnly ? (
    <Wrapper>
      <NavRow>
        <div
          className="logo-container mobile small-logo"
          onClick={() => logoClick(isMobileOnly)}
        >
          <div role="presentation" className="small-logo" alt="ErisX" />
        </div>
        <RightNav>
          <MobileUserDropdown
            className="user-dropdown"
            trigger={trigger}
            options={options(simpleNav)}
            pointing="top right"
            icon={null}
          />
        </RightNav>
      </NavRow>

      <DataRow>
        {memberList.length >= 1 ? (
          <MemberSelectorWrapper isMobile={isMobileOnly}>
            <label htmlFor="member">Member</label>
            <MemberSelector
              placeholder="Please select a member."
              name="memberId"
              value={currentMember}
              onChange={(acc) => {
                props.selectMemberOnNonFCMMemberClick(acc.memberId);
              }}
              members={memberList}
              id="member-selector-mobile"
              disableUnderline
            />
          </MemberSelectorWrapper>
        ) : null}
      </DataRow>
    </Wrapper>
  ) : (
    <NonFCMMarginFuturesStyledHeader
      id="top-nav"
      className={classNames({
        'mobile extra-small': topNavSmall,
        simple: simpleNav,
        medium: topNavMedium,
      })}
      loading={loading}
    >
      <div
        className={`logo-container ${
          !getLogoClass(simpleNav, topNavSmall) && 'hide'
        }`}
      >
        <div
          role="presentation"
          className={getLogoClass(simpleNav, topNavSmall)}
          alt="ErisX"
          onClick={() => {
            if (!simpleNav) {
              logoClick(topNavSmall);
            }
          }}
        />
      </div>

      {!simpleNav && !loading && memberList.length >= 1 ? (
        <MemberSelectorWrapper maxLengthAccountName={getMaxLengthOfName(currentMember)}>
          <label htmlFor="account">Member</label>
          <MemberSelector
            placeholder="Click to select a member..."
            name="memberId"
            value={currentMember}
            onChange={(acc) => {
              props.selectMemberOnNonFCMMemberClick(acc.memberId);
            }}
            searchable
            members={memberList}
            id="member-selector-desktop"
          />
        </MemberSelectorWrapper>
      ) : null}

      {!loading && (
        <Dropdown
          className="user-dropdown"
          trigger={trigger}
          options={options(simpleNav)}
          pointing="top right"
          icon={null}
          data-cy="top-nav-user-dropdown"
        />
      )}
    </NonFCMMarginFuturesStyledHeader>
  );
};

export default TopNavNonFCMMarginFutures;
