import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import ACHDepositsMetadata from '../metadata/ACHDepositsMetadata';
import ACHDeposits from '../components/ACHDeposits';
import {
  fetchACHDeposits,
  getACHDeposits,
  createFiatDepositSchedulePromiseCreator,
  deleteFiatDepositSchedulePromiseCreator,
  clearACHDeposits,
} from '../reducers/ACHDepositsReducer';
import { formValueSelector } from 'redux-form';
import { CONFIRM_MODAL } from '../constants/modalTypes';
import { hideModal, showModal } from 'erisxkit/client';
import EditAchDeposit from '../components/FundTransfers/EditAchDeposit';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedBankAccounts,
} from '../reducers/linkedAccountsReducer';
import { bankAccountOptions } from '../components/FundTransfers/BankTransfers';
import { formatAssetTypesForFundsTransfer } from '../utils/methods';
import {
  achDepositLimit,
  achWithdrawalLimit,
  achImmediateDepositLimit,
  getAchDepositLimit,
} from '../reducers/fundTransfersReducer';
import { getFiatAssetTypes } from '../reducers/assetTypesReducer';
import { getAllAccountsList } from '../reducers/accountsReducer';
import { getActiveAccountId } from '../reducers/activeAccountSelectors';
import { getActiveAccount } from '../reducers/activeAccountSelectors';
import payMethods from '../constants/payMethods';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

const fetch = () => {};

class EditAchDepositSchedule extends Component {
  loadDeposits = () => {
    this.props.clearACHDeposits();
    this.props.accounts.forEach((x) =>
      this.props.fetchACHDeposits({ accountId: x.accountId }),
    );
    this.props.fetchLinkedMemberAssetAccounts();
  };

  componentDidMount() {
    this.loadDeposits();
  }

  fetchLimit = (accountId, assetType, fundsDesignation) => {
    this.props.achDepositLimit({ accountId, assetType, fundsDesignation });
    this.props.achWithdrawalLimit({ accountId, assetType, fundsDesignation });
  };

  onBankChange = (e, nextBankAccount) => {
    const { accountId } = this.props;
    if (nextBankAccount && nextBankAccount.id) {
      this.props.achImmediateDepositLimit({
        accountId,
        linkedMemberAssetAccountId: nextBankAccount.id,
      });
    }
  };

  enabled = (data) => {
    if (data.assetType && data.amount && data.schedule) {
      if (
        data.schedule.toLowerCase() !== 'weekly' &&
        data.schedule.toLowerCase() !== 'monthly'
      ) {
        return true;
      }

      let day;
      if (data.schedule.toLowerCase() === 'weekly') {
        day = data.weekDay;
      }
      if (data.schedule.toLowerCase() === 'monthly') {
        day = data.day;
      }

      if (day) {
        return true;
      }
      return false;
    }
    return false;
  };

  editACHDeposit = (e) => {
    this.props.showModal(CONFIRM_MODAL, {
      actionsClass: 'ach-actions',
      modalClass: 'ach-modal',
      confirmProps: {
        content: 'Confirm',
        primary: true,
        onClick: () => {
          const payload = this.props.editedFormData;
          if (!this.enabled(payload)) return;

          if (
            Number(this.props.achDepositAmountLimit.available) <
            Number(payload.amount)
          )
            return;

          let day = 0;
          if (payload.schedule.toLowerCase() === 'weekly') {
            day = payload.weekDay;
          }
          if (payload.schedule.toLowerCase() === 'monthly') {
            day = payload.day;
          }
          const data = {
            schedule: payload.schedule,
            day: day.toString(),
            amount: payload.amount,
            assetType: payload.assetType,
            fundsDesignation: payload.fundsDesignation,
            linkedMemberAssetAccountId: payload.linkedMemberAssetAccountId,
            accountId: payload.accountId,
            payMethod: payMethods.ACH,
          };
          this.props
            .createFiatDepositSchedulePromiseCreator(data)
            .then(() => {
              this.props.hideModal();
            })
            .catch((error) => {
              console.error(error);
              this.props.hideModal();
            });
        },
        size: 'big',
      },
      rejectProps: {
        content: 'Cancel',
        grey: true,
        inverted: true,
        onClick: this.props.hideModal,
        size: 'big',
      },
      content: (
        <div style={{ widh: '100%' }}>
          <p style={{ fontSize: '20px', widh: '100%', textAlign: 'center' }}>
            Edit Scheduled Deposit for {e.nickname}
          </p>
          <div>
            <EditAchDeposit
              confirmEditAchDeposit={() => {}}
              linkedBankAccounts={bankAccountOptions(
                this.props.linkedBankAccounts,
              )}
              fetchLimit={this.fetchLimit}
              editedData={e}
              currencyOptions={formatAssetTypesForFundsTransfer({
                assetTypes: this.props.assetTypes,
                skipBalancesCheck: true,
              })}
              change={this.props.change}
              initialize={this.props.v}
            ></EditAchDeposit>
          </div>
        </div>
      ),
    });
  };

  deleteACHDeposit = (e) => {
    this.props.showModal(CONFIRM_MODAL, {
      actionsClass: 'ach-actions',
      modalClass: 'ach-modal',
      confirmProps: {
        content: 'Cancel Deposit',
        negative: true,
        onClick: (payload) => {
          this.props
            .deleteFiatDepositSchedulePromiseCreator({
              accountId: e.accountId,
              linkedMemberAssetAccountId: e.linkedMemberAssetAccountId,
            })
            .then(() => {
              this.props.hideModal();
              this.loadDeposits();
            })
            .catch((error) => {
              console.error(error);
              this.props.hideModal();
            });
        },
        size: 'big',
      },
      rejectProps: {
        content: 'Keep Schedule',
        onClick: this.props.hideModal,
        grey: true,
        inverted: true,
        size: 'big',
      },
      content: (
        <div style={{ widh: '100%', textAlign: 'center' }}>
          <p>YOU HAVE REQUESTED TO CANCEL A RECURRING DEPOSIT</p>
          <p
            style={{
              marginTop: '40px',
              marginBottom: '30px',
              fontSize: '18px',
            }}
          >
            Your <b>{e.schedule}</b> scheduled deposit of <b>${e.amount}</b> to{' '}
            <b>{e.account}</b> will be removed
          </p>
        </div>
      ),
    });
  };

  render() {
    const { achDeposits } = this.props;
    return (
      <section className="main-holdings">
        <Header as="h1">
          Scheduled Deposits
          <Header.Subheader>
            List of all your ACH scheduled deposits
          </Header.Subheader>
        </Header>
        <ACHDeposits
          title={'achDeposits'}
          result={achDeposits}
          metadata={ACHDepositsMetadata(
            this.editACHDeposit,
            this.deleteACHDeposit,
          )}
          onFetchData={fetch}
          hideModal={hideModal}
          showModal={showModal}
        />
      </section>
    );
  }
}

const selector = formValueSelector('editAchDeposit');

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  achDeposits: getACHDeposits(state),
  linkedBankAccounts: getLinkedBankAccounts(state),
  achDepositAmountLimit: getAchDepositLimit(state),
  assetTypes: getFiatAssetTypes(state),
  amount: selector(state, 'amount'),
  assetType: selector(state, 'assetType'),
  accountId: getActiveAccountId(state),
  accounts: getAllAccountsList(state),
  editedFormData: {
    schedule: selector(state, 'frequency'),
    day: selector(state, 'day'),
    weekDay: selector(state, 'weekDay'),
    amount: selector(state, 'amount'),
    assetType: selector(state, 'assetType'),
    fundsDesignation: selector(state, 'fundsDesignation'),
    linkedMemberAssetAccountId: selector(state, 'linkedMemberAssetAccountId'),
    accountId: selector(state, 'accountId'),
    payMethod: payMethods.ACH,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createFiatDepositSchedulePromiseCreator,
      deleteFiatDepositSchedulePromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      fetchACHDeposits,
      clearACHDeposits,
      fetchLinkedMemberAssetAccounts,
      achDepositLimit,
      achWithdrawalLimit,
      achImmediateDepositLimit,
      hideModal,
      showModal,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAchDepositSchedule);
