import styled from 'styled-components';
import StyledButton from '../../../common/StyledButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.p`
  margin-top: 1em !important;
  font-size: 20px;
  color: ${({ theme }) => theme.ui.header};
`;

export const LinkButtton = styled(StyledButton)`
  width: 180px;
  padding: 11px 5px;
  margin-bottom: 1em;
  margin-left: 1em;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
