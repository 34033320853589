import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Icon } from 'semantic-ui-react';

const SearchFieldAdornment = (
  <InputAdornment position="end">
    <Icon disabled name="search" />
  </InputAdornment>
);

export default SearchFieldAdornment;
