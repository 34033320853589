import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchMembers,
  fetchMembersV2,
  MEMBERS,
} from '../reducers/membersReducer';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watchContractsSaga() {
  yield takeLatest(fetchMembers.TRIGGER, createSaga(fetchMembers, MEMBERS));
  yield takeLatest(
    fetchMembersV2,
    generateSaga(fetchMembersV2, {
      method: HTTPMethod.POST,
    }),
  );
}
