import {
  isWindows,
  isMacOs,
  isIOS,
  isChrome,
  isSafari,
  isFirefox,
  osName,
  isAndroid,
} from 'react-device-detect';

/*
    Supported OS - Browser combinations:

    iOS - Chrome, Safari and Brave

    OSX - Chrome, Safari, Firefox and Brave

    Windows - Chrome

    Linux - Chrome
*/

// Current version of react-detect-device doesn't support browser/os Enums so we use our own

// Operating Systems
const IOS = 'iOS';
const MACOS = 'macOS';
const WINDOWS = 'Windows';
const LINUX = 'Linux';
const ANDROID = 'Android';

const OS_TYPES = {
  IOS,
  MACOS,
  WINDOWS,
  LINUX,
};

// Browsers
const CHROME = 'Chrome';
const FF = 'Firefox';
const SAFARI = 'Safari';
const BRAVE = 'Brave';

const BROWSER_TYPES = {
  CHROME,
  FF,
  SAFARI,
  BRAVE,
};

// Map OS to supported Browser types
const SUPPORTED_BROWSERS_BY_OS = {
  [IOS]: [CHROME, SAFARI, BRAVE],
  [MACOS]: [CHROME, SAFARI, FF, BRAVE],
  [WINDOWS]: [CHROME],
  [LINUX]: [CHROME],
  [ANDROID]: [CHROME],
};

// Depending on the Browser (FF/Chrome) osName returns either Linux or Ubuntu
const isLinux = () => osName === 'Linux' || osName === 'Ubuntu';

// There's no need to check for Brave as it is detected as Chrome because it's powered by Chromium
const isValidIOSBrowser = () => isChrome || isSafari;
const isValidAndroidBrowser = () => isChrome;
const isValidMacOSBrowser = () => isChrome || isSafari || isFirefox;
const isValidWindowsBrowser = () => isChrome;
const isValidLinuxBrowser = () => isChrome;

export const isCurrentOSSupported = () =>
  isWindows || isMacOs || isLinux() || isIOS || isAndroid;

export const isCurrentBrowserSupported = () => {
  if (isWindows) return isValidWindowsBrowser();
  if (isLinux()) return isValidLinuxBrowser();
  if (isMacOs) return isValidMacOSBrowser();
  if (isIOS) return isValidIOSBrowser();
  if (isAndroid) return isValidAndroidBrowser();
  return false;
};

export const getUserBrowserOsCompatibility = () => ({
  osSupported: isCurrentOSSupported(),
  browserSupported: isCurrentBrowserSupported(),
});

export const isCurrentUserSessionUnsupported = () =>
  !isCurrentOSSupported() || !isCurrentBrowserSupported();

export const getSupportedBrowsersForCurrentOS = () => {
  if (isWindows) return SUPPORTED_BROWSERS_BY_OS[WINDOWS];
  if (isLinux()) return SUPPORTED_BROWSERS_BY_OS[LINUX];
  if (isMacOs) return SUPPORTED_BROWSERS_BY_OS[MACOS];
  if (isIOS) return SUPPORTED_BROWSERS_BY_OS[IOS];
  if (isAndroid) return SUPPORTED_BROWSERS_BY_OS[ANDROID];
  return [];
};
