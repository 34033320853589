import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import useIsFCM from './useIsFCM';
import { lightTheme, darkTheme } from '../styles/styled/themes';
import GlobalStyles from '../styles/styled/GlobalStyles';
import { useIsNonFCMMarginFuturesPage } from './useExchangeMember';

const useCurrentTheme = () => {
  const isFCM = useIsFCM();
  const isNonFCMMarginFuturesPage = useIsNonFCMMarginFuturesPage();

  return Boolean(isFCM) || isNonFCMMarginFuturesPage ? lightTheme : darkTheme;
};

export const ThemeWrapper = ({ children }: { children: ReactNode }) => {
  const theme = useCurrentTheme();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default useCurrentTheme;
