import { CellContext } from '@tanstack/react-table';
import moment from 'moment';

export const getDateObj = (val: string | undefined) => {
  if (!val) return Number.NaN;
  return moment(val).toDate();
};

export const getFormattedTimestamp = <T>(
  props: CellContext<T, number | Date>,
) => {
  const val = props?.getValue();
  if (!val) return '';
  return moment(val).format('ddd, MMM D, YYYY, h:mm:ss a');
};
