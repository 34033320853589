import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

const DefaultContent = () => (
  <div className="vert-flex drop-content">
    <Icon size="huge" name="file image outline" />
    <h2>Click to Select Files</h2>
    <p>or try dropping a file here.</p>
  </div>
);
class DropzoneWrapper extends Component {
  render = () => (
    <Dropzone
      accept={this.props.accept}
      maxSize={this.props.maxSize}
      minSize={this.props.minSize}
      onDropAccepted={this.props.onDropAccepted}
      onDropRejected={this.props.onDropRejected}
      onFileDialogCancel={() => {}}
      className="dropzone"
      multiple={this.props.multiple}
      data-cy={this.props['data-cy']}
    >
      {this.props.content || DefaultContent}
    </Dropzone>
  );
}

DropzoneWrapper.defaultProps = {
  maxSize: 12582912, // 12 megabytes
  minSize: 51200, // 400 kilobytes
  multiple: false,
};

DropzoneWrapper.propTypes = {
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

export default DropzoneWrapper;
