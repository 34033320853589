import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector, createSelectorCreator } from 'reselect';

//* Action Types */
export const ACCOUNT_SCHEDULES = 'ACCOUNT_SCHEDULES';
export const CLEAR_ACCOUNT_SCHEDULES = 'CLEAR_ACCOUNT_SCHEDULES';
export const CREATE_FIAT_DEPOSIT_SCHEDULE = 'CREATE_FIAT_DEPOSIT_SCHEDULE';
export const DELETE_FIAT_DEPOSIT_SCHEDULE = 'DELETE_FIAT_DEPOSIT_SCHEDULE';
export const fetchACHDeposits = createRoutine(ACCOUNT_SCHEDULES);
export const createFiatDepositSchedule = createRoutine(
  CREATE_FIAT_DEPOSIT_SCHEDULE,
);
export const deleteFiatDepositSchedule = createRoutine(
  DELETE_FIAT_DEPOSIT_SCHEDULE,
);

export const createFiatDepositSchedulePromiseCreator = promisifyRoutine(
  createFiatDepositSchedule,
);

export const deleteFiatDepositSchedulePromiseCreator = promisifyRoutine(
  deleteFiatDepositSchedule,
);

export default handleActions(
  {
    [fetchACHDeposits.SUCCESS]: (state, { payload }) => ({
      ...state,
      requestACHDeposits: {
        achDeposits: [...state.requestACHDeposits.achDeposits, ...payload],
        count: state.requestACHDeposits.count + payload.length,
      },
    }),
    [CLEAR_ACCOUNT_SCHEDULES]: (state) => ({
      ...state,
      requestACHDeposits: {
        achDeposits: [],
        count: 0,
      },
    }),
  },
  {
    requestACHDeposits: {
      achDeposits: [],
      count: 0,
    },
  },
);

export const getACHDeposits = (state) =>
  _.get(state, ['achDeposits', 'requestACHDeposits'], []);

export const getACHDepositList = createSelector(
  [getACHDeposits],
  (requestACHDeposits) => _.get(requestACHDeposits, ['achDeposits'], []),
);

export const getACHDepositsFromAccount = (state, accountId, bankAccountId) =>
  getACHDepositList(state).filter(
    (d) =>
      d.accountId === accountId &&
      d.linkedMemberAssetAccountId === bankAccountId,
  );

export const getScheduledACHDepositsFromAccount = (
  state,
  accountId,
  bankAccountId,
) =>
  getACHDepositsFromAccount(state, accountId, bankAccountId).filter(
    (d) => d.schedule !== 'default',
  );
export const clearACHDeposits = () => ({
  type: CLEAR_ACCOUNT_SCHEDULES,
});
