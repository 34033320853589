import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  Button,
  Modal,
  Statistic,
  Divider,
  Segment,
  Icon,
  Table,
  Header,
  Container,
} from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import BigNumber from 'bignumber.js';
import { connect } from 'react-redux';
import gaCat from '../../constants/ga-categories';
import { fetchBalances } from '../../reducers/balancesReducer';
import {
  getSelectedPartyId,
  getCurrentUserId,
} from '../../reducers/userReducer';
import {
  newOrderSinglePromise,
  topOfBook,
  getOrderPromise,
  NEW_ORDER_SINGLE,
  GET_ORDER,
} from '../../reducers/orderEntryReducer';
import {
  formatFiat,
  calculateBps,
  needMoreThanTwoDecimals,
  formatCrypto,
} from '../../utils/methods';
import InputWithCopy from './InputWithCopy';
import {
  BUY,
  getMessage,
  SELL,
  transactionTypes,
  getPrecisionFromDecimal,
} from '../../containers/OrderEntry/OrderEntryWidgetContainer';
import { pushTag, trackEvent } from '../tracking';
import { CONDITIONS_ENDPOINT, FEES_ENDPOINT } from '../../constants/endpoints';
import { getAllAccountIds } from '../../reducers/accountsReducer';
import moment from 'moment';
import { PRECISION_USD, PRECISION_CRYPTO } from '../../constants/precision';
import { DEFAULT_QUOTE_CURRENCY } from '../../constants/currencies';
import colors from '../../constants/colors';

const CANCELED = 'CANCELED';
const FILLED = 'FILLED';

const styles = {
  fees: { textAlign: 'center' },
  statistic: { padding: '10px' },
  label: { marginBottom: '5px' },
};

const mapStateToProps = (state) => ({
  newOrderSingleLoading: createLoadingSelector([NEW_ORDER_SINGLE, GET_ORDER])(
    state,
  ),
  firstPartyID: getSelectedPartyId(state),
  accountIds: getAllAccountIds(state),
  senderSubId: getCurrentUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      topOfBook,
      fetchBalances,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      newOrderSinglePromise,
      getOrderPromise,
    },
    dispatch,
  ),
});

const getPriceImprovement = (limitPrice = 0, avgPx = 0, side) => {
  if (side === BUY && limitPrice > avgPx) {
    return BigNumber(limitPrice).minus(avgPx).toFixed();
  }
  if (side === SELL && limitPrice < avgPx) {
    return BigNumber(avgPx).minus(limitPrice).toFixed();
  }
  return 0;
};

export const getTotal = (
  { avgPrice, cumQty, commission, lastQty, lastPrice, ordStatus, side },
  quoteCurrency = DEFAULT_QUOTE_CURRENCY,
) => {
  // if it's a partial fill, we can't add fees
  let truncate = PRECISION_USD;
  if (quoteCurrency !== DEFAULT_QUOTE_CURRENCY) {
    truncate = PRECISION_CRYPTO;
  }
  if (ordStatus === CANCELED || side === SELL) {
    return new BigNumber(avgPrice)
      .times(cumQty)
      .decimalPlaces(truncate)
      .toString();
  }
  return new BigNumber(avgPrice)
    .times(cumQty)
    .times(1 + commission / (lastQty * lastPrice))
    .decimalPlaces(truncate)
    .toString();
};

export class ConfirmModal extends Component {
  state = {
    view: 'initial',
    order: {},
  };

  getModalContent = (view) => {
    const {
      currency,
      amount,
      symbol,
      limitPrice,
      assetType,
      precision,
      orderQty,
      newOrderSingleLoading,
      getOrderPromise,
      firstPartyID,
      side,
      fees,
      fetchBalances,
      accountIds,
      quoteCurrency,
      senderSubId,
    } = this.props;

    const getPrecision = (amount) => {
      const _precision = getPrecisionFromDecimal(+amount);
      const _precisionSecured = _precision > precision ? precision : _precision;
      return _precisionSecured;
    };

    const getCurrentPrecision = (amount) => {
      return needMoreThanTwoDecimals(amount)
        ? getPrecision(+amount)
        : PRECISION_USD;
    };

    const truncateAmount = (amount, precision) => {
      return new BigNumber(amount).decimalPlaces(precision).toString();
    };

    const { order = {} } = this.state;
    switch (view) {
      case 'initial':
        return (
          <Fragment>
            <Statistic style={styles.statistic} size="small">
              <Statistic.Label style={styles.label}>{side}ing</Statistic.Label>
              <Statistic.Value className="mono">
                {`${orderQty} ${currency}`}
              </Statistic.Value>
            </Statistic>
            <Divider horizontal style={{ color: colors.GREEN }}>
              Estimated Value
            </Divider>
            <Statistic style={styles.statistic} size="small">
              <Statistic.Value className="mono">
                {DEFAULT_QUOTE_CURRENCY === quoteCurrency
                  ? formatFiat(amount, quoteCurrency)
                  : formatCrypto(
                      amount,
                      quoteCurrency,
                      getCurrentPrecision(amount),
                    )}
              </Statistic.Value>
              {fees && (
                <small style={styles.fees} className="helper info">
                  Includes Estimated Fees of{' '}
                  <span className="mono">
                    {formatFiat(fees, quoteCurrency)}
                  </span>
                </small>
              )}
            </Statistic>
            <Button
              fluid
              content={`${get(transactionTypes, [side, 'text'], '')} Now`}
              size="massive"
              className={side === SELL ? 'sell' : ''}
              loading={newOrderSingleLoading}
              disabled={newOrderSingleLoading}
              primary={side === BUY}
              onClick={() => {
                trackEvent(gaCat.QUICK_TRADE, side);
                this.props
                  .newOrderSinglePromise({
                    clOrdID: `${firstPartyID}-SOET-${Date.now()}`,
                    side,
                    currency,
                    symbol,
                    partyID: firstPartyID,
                    transactionTime: Date.now(),
                    orderQty: side === SELL ? orderQty : undefined,
                    ordType: 'MARKET',
                    timeInForce: 'ImmediateOrCancel',
                    cashOrderQty: side === BUY ? amount : undefined,
                    type: 'NewMarketOrderSingle',
                  })
                  .then(
                    (s) => {
                      if (
                        s.ordStatus === 'REJECTED' ||
                        s.type === 'ERROR_MESSAGE'
                      ) {
                        this.setState({
                          view: 'rejected',
                          order: s,
                        });
                      } else {
                        // update the balances since the order went through
                        // set an interval to call the balances every second for 4 seconds.
                        const BALANCES_INTERVAL = 1000;
                        const CLEAR_AFTER = 4000;
                        const id = setInterval(
                          () =>
                            fetchBalances({
                              accountIds,
                              time: moment().format(),
                            }),
                          BALANCES_INTERVAL,
                        );
                        setTimeout(() => {
                          clearInterval(id);
                        }, CLEAR_AFTER);

                        getOrderPromise({
                          partyId: firstPartyID,
                          orderID: s.orderID,
                        }).then((o) => {
                          trackEvent(gaCat.QUICK_TRADE, o.ordStatus);
                          if (
                            o.ordStatus === FILLED ||
                            o.ordStatus === CANCELED
                          ) {
                            pushTag('firstTraded');
                            this.setState({
                              view: 'success',
                              order: o,
                            });
                          } else {
                            this.setState({
                              view: 'rejected',
                              order: o,
                            });
                          }
                        });
                      }
                    },
                    (error) =>
                      this.setState({
                        view: 'rejected',
                        order: error,
                      }),
                  );
              }}
            />
            <Segment size="small">
              <small className="helper info">
                {getMessage({
                  side,
                  amount,
                  assetType,
                  orderQty,
                  currency,
                  quoteCurrency,
                })}
                <Fragment>
                  &nbsp; Transaction is subject to{' '}
                  <a href={FEES_ENDPOINT} target="_blank" rel="noreferrer">
                    fees
                  </a>{' '}
                  and{' '}
                  <a
                    href={CONDITIONS_ENDPOINT}
                    target="_blank"
                    rel="noreferrer"
                  >
                    conditions
                  </a>
                  .
                </Fragment>
              </small>
            </Segment>
          </Fragment>
        );
      case 'rejected':
        return (
          <Fragment>
            <Header as="h1" content="Order Rejected" red />
            <Divider horizontal>
              <Icon name="times circle outline red" />
            </Divider>
            <Container>
              <p>
                Sorry your order could not be processed because of the follow
                reason:
              </p>
              <Segment>{order.message}</Segment>
              <p>
                Order Reference ID:
                <InputWithCopy
                  id="clOrdId"
                  key="clOrdId"
                  className="mono outline"
                  value={order.clOrdID}
                />
              </p>
            </Container>
          </Fragment>
        );
      case 'success':
        return (
          <Fragment>
            <Header as="h1" className="text-center" content="Order Executed" />
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4">
                      You {side === BUY ? 'Bought' : 'Sold'}
                    </Header>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {order.cumQty} {order.currency}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4">Price</Header>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {DEFAULT_QUOTE_CURRENCY === quoteCurrency
                      ? formatFiat(
                          get(order, 'avgPrice', '').toString(),
                          quoteCurrency,
                        )
                      : formatCrypto(
                          get(order, 'avgPrice', '').toString(),
                          quoteCurrency,
                          getCurrentPrecision(
                            get(order, 'avgPrice', '').toString(),
                          ),
                        )}
                  </Table.Cell>
                </Table.Row>

                {order.ordStatus === FILLED && (
                  <Table.Row>
                    <Table.Cell>
                      <Header as="h4">% Fee</Header>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {calculateBps(
                        BigNumber(order.commission).times(-1),
                        BigNumber(order.lastQty)
                          .times(order.lastPrice)
                          .toFixed(),
                      )}{' '}
                      %
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    <Header as="h4">
                      <b>{order.ordStatus === CANCELED && 'Estimated '}Total</b>
                    </Header>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <b>
                      {DEFAULT_QUOTE_CURRENCY === quoteCurrency
                        ? formatFiat(getTotal(order).toString(), quoteCurrency)
                        : formatCrypto(
                            truncateAmount(
                              getTotal(order, quoteCurrency),
                              getCurrentPrecision(
                                getTotal(order, quoteCurrency),
                              ),
                            ),
                            quoteCurrency,
                            getCurrentPrecision(getTotal(order, quoteCurrency)),
                          )}
                    </b>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Segment size="small">
              <small className="helper info">
                Your order was executed. Please check your trades tab for
                detailed information of your fills.
              </small>
            </Segment>
            {getPriceImprovement(limitPrice, order.avgPrice) ? (
              <Fragment>
                <Divider horizontal>
                  <Icon style={{ color: '#00d2a5' }} name="star" />
                </Divider>
                <Container>
                  {`You have received a price improvement of ${formatFiat(
                    getPriceImprovement(limitPrice, order.avgPrice),
                    quoteCurrency,
                  )}!`}
                </Container>
              </Fragment>
            ) : null}
          </Fragment>
        );
      default:
        return null;
    }
  };

  render = () => {
    const {
      amount,
      symbol,
      limitPrice,
      assetType,
      precision,
      orderQty,
      newOrderSingleLoading,
    } = this.props;

    return (
      <Fragment>
        <Modal.Content>
          <div className="vert-flex">
            <div className="logo-container">
              <div className="small-logo" alt="ErisX" />
            </div>
            {this.getModalContent(this.state.view)}
          </div>
        </Modal.Content>
      </Fragment>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
