import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get, uniq, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import Big from 'bignumber.js';
import { extractQuoteCurrency } from './assetTypesReducer';

export const NEW_ORDER_SINGLE = 'NEW_ORDER_SINGLE';
export const GET_ORDER = 'GET_ORDER';
export const TOP_OF_BOOK = 'TOP_OF_BOOK';
export const PARTY_LIST = 'PARTY_LIST';
export const SECURITY_LIST = 'SECURITY_LIST';

export const newOrderSingle = createRoutine(NEW_ORDER_SINGLE);
export const newOrderSinglePromise = promisifyRoutine(newOrderSingle);
export const topOfBook = createRoutine(TOP_OF_BOOK);
export const topOfBookPromise = promisifyRoutine(topOfBook);
export const partyList = createRoutine(PARTY_LIST);
export const getOrder = createRoutine(GET_ORDER);
export const getOrderPromise = promisifyRoutine(getOrder);
export const securityList = createRoutine(SECURITY_LIST);
export const lastPrice = createRoutine('LAST_PRICE');
export const marginProducts = createRoutine('MARGIN_PRODUCTS');

export const removeTFromTestCoins = (assetType = '') => {
  if (assetType[0] === 'T') {
    return assetType.substring(1);
  }
  return assetType;
};

export const calcPxFromBook = (assetType = {}, book) => {
  if (assetType.isFiat) {
    // hard-code USD to one right now
    return 1;
  }
  return Big(get(book.bids[0], 'price', ''))
    .plus(get(book.offers[0], 'price', ''))
    .dividedBy(2)
    .toFixed();
};

const MAX_SPREAD = 0.02;

export const isSpreadTight = (book = {}) =>
  book.bids.length > 0 &&
  book.offers.length > 0 &&
  Big(get(book.offers[0], 'price', ''))
    .minus(get(book.offers[0], 'price', ''))
    .dividedBy(get(book.bids[0], 'price', ''))
    .lt(MAX_SPREAD);

export const formatAssetTypeToSymbol = (assetType = '') => {
  assetType = removeTFromTestCoins(assetType);

  return `${assetType}/USD`;
};

export default handleActions(
  {
    [topOfBook.SUCCESS]: (state, { payload }) => ({
      ...state,
      topOfBook: { ...state.topOfBook, [payload.symbol]: payload },
      latestPrices: {
        ...state.latestPrices,
        [payload.symbol]: isSpreadTight(payload)
          ? calcPxFromBook({}, payload)
          : get(state.latestPrices, [payload.symbol], null),
      },
    }),
    [lastPrice.SUCCESS]: (state, { payload }) => {
      // check if it exists, if it does we dont' update it.
      // this should create an object that only holds the prices we want to update
      const pricesToUpdate = payload.reduce((acc, { symbol, px }) => {
        // remove the T from test coins
        symbol = removeTFromTestCoins(symbol);
        if (!get(state.latestPrices, symbol, '')) {
          return { ...acc, [symbol]: px };
        }
        return acc;
      }, {});

      return {
        ...state,
        latestPrices: {
          ...state.latestPrices,
          ...pricesToUpdate,
        },
      };
    },
    [newOrderSingle.SUCCESS]: (state, { payload }) => ({
      ...state,
      order: payload,
    }),
    [partyList.SUCCESS]: (state, { payload }) => ({
      ...state,
      partyList: payload.partyIds,
    }),
    [securityList.SUCCESS]: (state, { payload }) => ({
      ...state,
      securityList: payload.securities,
    }),
    [marginProducts.SUCCESS]: (state, { payload }) => ({
      ...state,
      marginProducts: payload.marginProducts,
    }),
  },
  {},
);
export const getMarginProducts = (state) =>
  get(state, ['orderEntry', 'marginProducts'], []);
export const getAllTopOfBook = (state) =>
  get(state, ['orderEntry', 'topOfBook'], {});
export const getFirstPartyId = (state) =>
  get(state, ['orderEntry', 'partyList', '0'], '');
export const getAllSecurities = (state) =>
  get(state, ['orderEntry', 'securityList'], []);
export const getUSDSecurities = createSelector(getAllSecurities, (securities) =>
  securities.filter(
    (s) => extractQuoteCurrency(get(s, 'symbol', '')) === 'USD',
  ),
);
export const getSpotUSDSecurities = createSelector(getUSDSecurities, (secs) =>
  secs.filter((s) => s.securityType === null),
);

export const getSpotSecurities = createSelector(getAllSecurities, (secs) =>
  secs.filter((s) => s.securityType === null),
);

export const getQuoteCurrencies = createSelector(getAllSecurities, (secs) =>
  uniq(secs.map((sec) => extractQuoteCurrency(sec.symbol))),
);

export const getFuturesSecurities = createSelector(getAllSecurities, (secs) =>
  uniqBy(
    secs.filter((sec) => sec?.securityType === 'FUT'),
    'productCode',
  ).filter((sec) => !!get(sec, 'productCode', null)),
);

export const getCarProducts = createSelector(getMarginProducts, (secs) =>
  secs.map((sec, key) => ({
    key,
    value: get(sec, 'symbol', ''),
    text: get(sec, 'symbol', ''),
  })),
);
export const getAssetTypesByType = (state) =>
  get(state, ['assetTypes', 'assetTypesReducer'], {});

export const getLatestPrices = (state) =>
  get(state, ['orderEntry', 'latestPrices'], {});
