import { StyledSelectOption } from '../../common/StyledSelect';
import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { CGM, CGMListResponse } from '../../ts/models/CGM.model';
import { RootState } from '../../ts/types/store';
import { getParticipantList } from '../participantManagementReducer';
import { sortBy } from 'lodash';
import {
  FETCH_CGM_LIST,
  DELETE_CGM,
  LINK_CGM_TO_PARTICIPANT,
  UNLINK_CGM_TO_PARTICIPANT,
} from './cgmManagementActions';
import { TradingParticipant } from '../../ts/models/TradingParticipant/ApprovedParticipant.model';

/** Routines */
export const createCGM = createRoutine('CREATE_CGM');
export const updateCGM = createRoutine('UPDATE_CGM');
export const fetchCgmList = createRoutine(FETCH_CGM_LIST);
export const deleteCgm = createRoutine(DELETE_CGM);
export const linkCgmToParticipant = createRoutine(LINK_CGM_TO_PARTICIPANT);
export const unlinkCgmToParticipant = createRoutine(UNLINK_CGM_TO_PARTICIPANT);

/** Routines */
export const createCgmPromiseCreator = promisifyRoutine(createCGM);
export const updateCgmPromiseCreator = promisifyRoutine(updateCGM);
export const deleteCgmPromiseCreator = promisifyRoutine(deleteCgm);

/** Promisified Routines */
export const linkCgmToParticipantPromise =
  promisifyRoutine(linkCgmToParticipant);
export const unlinkCgmToParticipantPromise = promisifyRoutine(
  unlinkCgmToParticipant,
);

/** Initial State */
type CgmManagementState = {
  cgms?: CGMListResponse;
};

const initialState: CgmManagementState = {};

/** Reducer */
export default handleActions<CgmManagementState, any>(
  {
    [fetchCgmList.SUCCESS]: (state, { payload }) => ({
      ...state,
      cgms: payload,
    }),
  },
  initialState,
);

/** Selectors */
export const getCGMState = (state: RootState) =>
  get(state, 'cgmManagement', {});

export const getCGMS = createSelector(
  [getCGMState],
  (state) => get(state, 'cgms', {}) as CGMListResponse,
);

export const getCGMList = createSelector(
  [getCGMS],
  (state) => get(state, 'cgmList', []) as CGM[],
);

export const getLinkableCGMListForFCMHelper = (
  allCgms: CGM[],
  participants: TradingParticipant[],
) => {
  const allLinkedCgms = participants.reduce((prev, currentParticipant) => {
    const fcm = currentParticipant.fcms?.[0];
    if (!fcm) {
      return prev;
    }
    const linkedCgms = fcm.cgmsLinkedToParticipant.map((cgm) => cgm.cgmNumber);
    return [...prev, ...linkedCgms];
  }, [] as string[]);
  const linkableCgms = allCgms.filter(
    (cgm: CGM) => !allLinkedCgms.includes(cgm.cgmNumber),
  );
  return sortBy(linkableCgms, ['cgmNumber']);
};

export const getLinkableCGMListForFCM = createSelector(
  [getCGMList, getParticipantList],
  (allCgms, participants) =>
    getLinkableCGMListForFCMHelper(allCgms, participants),
);

export const getCgmSelectOptions = createSelector(
  [getCGMList],
  (cgms) =>
    cgms.map((e: CGM) => ({
      key: e.cgmNumber,
      value: e,
      text: e.cgmNumber,
    })) as StyledSelectOption[],
);

export const getCgmNumberSelectOptions = createSelector(
  [getLinkableCGMListForFCM],
  (cgms) =>
    cgms.map<StyledSelectOption>((cgm) => ({
      key: cgm.cgmNumber,
      value: cgm.cgmNumber,
      text: cgm.cgmNumber,
    })),
);

export const getCarTableCgmSelectOptions = createSelector(
  [getCGMList],
  (cgms) =>
    cgms.map((e: CGM) => ({
      key: e.cgmNumber,
      value: e.cgmNumber,
      text: e.cgmNumber,
    })) as StyledSelectOption[],
);

export const getCgmOmnibusAccountNumber = createSelector(
  [getCGMState],
  (cgms) => get(cgms, 'omnibusAccountNumber', ''),
);

export const getCgmOmnibusAccountId = createSelector([getCGMState], (cgms) =>
  get(cgms, 'omnibusAccountId', ''),
);
