import _ from 'lodash';
import moment from 'moment';
import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import * as actions from '../reducers/accountsReducer';
import {
  ACCOUNT_HISTORY_REQUEST,
  ACCOUNT_HISTORY_SUCCESS,
  ACCOUNT_HISTORY_FAILED,
  fetchJournalHistory,
  JOURNAL_HISTORY,
  fetchRequestHistory,
  REQUEST_HISTORY,
  fetchRecentTransactions,
  RECENT_TRANSACTIONS,
  fetchBankTransactionDetails,
  tradeJournals,
  TRADE_JOURNALS,
} from '../reducers/accountHistoryReducer';
import {
  BALANCES,
  fetchAccountsBalances,
  fetchBalances,
} from '../reducers/balancesReducer';
import api, * as urls from '../api';
import { createSaga } from '../utils/createSaga';
import { fetchPositions, POSITIONS } from '../reducers/positionsReducer';
import {
  fetchCloseoutJournals,
  CLOSEOUT_JOURNALS,
} from '../reducers/closeoutJournalsReducer';
import { calculateUsdValue } from '../utils/methods';

function* fetchAccountHistory(arg) {
  try {
    const accountHistory = yield call(
      api.post,
      urls.ACCOUNT_HISTORY_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: ACCOUNT_HISTORY_SUCCESS, accountHistory });
  } catch (e) {
    yield put({ type: ACCOUNT_HISTORY_FAILED, payload: e.response });
  }
}

function* accounts() {
  try {
    yield put(actions.fetchAccounts.request());
    const data = yield call(api.post, urls.ACCOUNTS_API_ENDPOINT);
    if (data.count !== 0 && _.get(data, 'accounts', [])) {
      yield put(actions.fetchAccounts.success(data));
    } else
      yield put(
        actions.fetchAccounts.failure({
          data: 'No accounts found for this user.',
        }),
      );
  } catch (e) {
    yield put(actions.fetchAccounts.failure(e.response));
  }
}

export const getUniqueAssetTypesFromDesignatedBalances = (balances) => {
  if (!Array.isArray(balances)) {
    return [];
  }
  return _.uniq(
    _.flatten(
      balances.map((b) => _.get(b, 'balances', []).map((d) => d.assetType)),
    ),
  );
};

function* accountsBalancesSaga(arg) {
  yield put(fetchAccountsBalances.request());
  try {
    const accountsBalances = yield call(
      api.post,
      urls.ACCOUNTS_BALANCES_API_ENDPOINT,
      arg.payload,
    );
    const balances = _.get(accountsBalances, 'balances', []);
    const balancesByAccountId = balances.reduce((obj, item) => {
      //Add AccountId props to each row.
      const balancesWithAccountId = item.balances.map((balance) => ({
        ...balance,
        accountLabel: item.accountLabel,
      }));
      obj[item.accountId] = balancesWithAccountId;
      return obj;
    }, {});
    // get last price when it's asked for
    if (_.get(arg, ['payload', 'lastPrice'], false)) {
      // get all unique asset types
      const uniqueAssetTypes =
        getUniqueAssetTypesFromDesignatedBalances(balances);
      if (uniqueAssetTypes.length >= 1) {
        const lastPrices = yield call(api.post, urls.LAST_PRICE_API_ENDPOINT, {
          symbol: uniqueAssetTypes.map((a) => `${a}/USD`),
          time: moment().format(),
        });
        Object.keys(balancesByAccountId).forEach((accountId) => {
          const details = balancesByAccountId[accountId];
          const detailsWithLastPrice = details.map((d) => {
            const lastPrice =
              _.find(lastPrices, {
                symbol: `${_.get(d, 'assetType', '')}/USD`,
              }) || {};
            return {
              totalUsdValue: calculateUsdValue({
                closingBalance: d.closingBalance,
                lastPrice: lastPrice.px,
              }),
              ...lastPrice,
              ...d,
            };
          });
          balancesByAccountId[accountId] = detailsWithLastPrice;
        });
      }
    }

    yield put(fetchAccountsBalances.success(balancesByAccountId));
  } catch (e) {
    yield put(fetchAccountsBalances.failure(e.response));
  }
}

function* bankTransactionDetailsSaga(arg) {
  yield put(fetchBankTransactionDetails.request());
  try {
    const details = yield call(
      api.post,
      urls.BANK_TRANSACTION_DETAILS_API_ENDPOINT,
      arg.payload,
    );
    yield put(
      fetchBankTransactionDetails.success({ [arg.payload.uuid]: details }),
    );
  } catch (e) {
    yield put(fetchBankTransactionDetails.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(actions.fetchAccounts.TRIGGER, accounts);
  yield takeLatest(ACCOUNT_HISTORY_REQUEST, fetchAccountHistory);
  yield takeLatest(
    fetchJournalHistory.TRIGGER,
    createSaga(fetchJournalHistory, JOURNAL_HISTORY, '', ''),
  );
  yield takeLatest(
    fetchRequestHistory.TRIGGER,
    createSaga(fetchRequestHistory, REQUEST_HISTORY),
  );
  yield takeLatest(
    fetchRecentTransactions.TRIGGER,
    createSaga(fetchRecentTransactions, RECENT_TRANSACTIONS),
  );
  yield takeEvery(fetchAccountsBalances.TRIGGER, accountsBalancesSaga);
  yield takeLatest(
    fetchBankTransactionDetails.TRIGGER,
    bankTransactionDetailsSaga,
  );
  yield takeLatest(
    fetchPositions.TRIGGER,
    createSaga(fetchPositions, POSITIONS),
  );
  yield takeEvery(fetchBalances.TRIGGER, createSaga(fetchBalances, BALANCES));
  yield takeLatest(
    tradeJournals.TRIGGER,
    createSaga(tradeJournals, TRADE_JOURNALS),
  );
  yield takeLatest(
    fetchCloseoutJournals.TRIGGER,
    createSaga(fetchCloseoutJournals, CLOSEOUT_JOURNALS, '', '', '', '', {
      transformResponse: (req, res) => ({ ...res, accountId: req.accountId }),
    }),
  );
}
