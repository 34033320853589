import React from 'react';
import PropTypes from 'prop-types';
import { XTable } from 'erisxkit/client';

const AssetBalances = ({ balances, loading, metadata }) => (
  <XTable
    title="assetBalances"
    className="dark"
    showPageSizeOptions={false}
    showPagination={false}
    data={balances}
    columns={metadata}
    loading={loading}
    minRows={3}
    noDataText="There are no balances for this account."
  />
);

AssetBalances.propTypes = {
  balances: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

AssetBalances.defaultProps = {
  balances: [],
  loading: false,
};

export default AssetBalances;
