import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled, { css, DefaultTheme, withTheme } from 'styled-components';
import get from 'lodash/get';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  .MuiSelect-icon {
    color: ${({ theme }) => theme.table.select.color};
  }
`;

const StyledItem = styled(MenuItem)<{ active: boolean; disabled: boolean }>`
  &&& {
    background-color: ${({ theme }) => theme.table.select.bg};
    color: ${({ theme }) => theme.table.select.color};
    ${({ active }) =>
      active &&
      css`
        color: ${({ theme }) => theme.accent};
        font-weight: 500;
      `}
    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
      `}

  &:hover, :focus, :focus:hover {
      background-color: ${({ theme }) => theme.table.select.hover};
      outline: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid red;
    }
  }
`;

const BaseSelect = styled(Select)<{ active: boolean; open: boolean }>`
  background-color: ${({ theme }) => theme.table.select.bg};
  border-radius: 3px;
  color: ${({ active, theme }) =>
    active ? theme.table.accent : theme.table.select.color} !important;
  height: 30px;
  padding-left: 10px;
  cursor: pointer;
  height: 25px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  border: ${({ theme, open }) =>
    open ? `1px solid ${theme.accent}` : theme.table.select.border};
  p {
    line-height: unset;
  }
  :before {
    border-bottom: none !important;
  }
  :after {
    border-bottom: none !important;
  }
`;

export type StyledSelectOption<T = void> = {
  text: string;
  value: string | object | T;
  disabled?: boolean;
  // Options with no value can have an onClick callback to trigger
  onClick?: () => void;
};

type Props = {
  placeholder?: string;
  onChange: (value: StyledSelectOption | '') => void;
  options: StyledSelectOption[];
  // Pass a value to use it as a controlled component
  value?: StyledSelectOption;
  onBlur?: (event: FocusEvent) => void;
  // Sets the color of the selected option
  active?: boolean;
  theme: DefaultTheme;
  className?: string;
  renderValue?: (value: StyledSelectOption) => JSX.Element;
  dataCy?: string;
};

const useStyles = makeStyles({
  select: {
    '& ul': {
      backgroundColor: (theme) => get(theme, 'form.field.dropdown.item.bg', ''),
      maxHeight: '15em',
      overflowY: 'auto',
    },
    '& li': {
      fontSize: 14,
    },
  },
});

const StyledSelect = ({
  placeholder,
  onChange,
  options = [],
  value,
  active = false,
  theme,
  className = '',
  renderValue,
  dataCy,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState<StyledSelectOption | ''>(
    value || '',
  );
  const classes = useStyles(theme);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleChange = (e: { target: { value?: StyledSelectOption } }) => {
    const value: StyledSelectOption | '' = e?.target?.value || '';
    // Ignore option with no value as they're used to trigger actions and not select options
    if (value === '') return;
    // Select the option
    setSelectedOption(value);
    // Invoke additional callback method, if it exists
    if (onChange) {
      onChange(value);
    }
    // Close Context menu
    handleClose();
  };

  return (
    <StyledFormControl className={className}>
      <BaseSelect
        open={open}
        data-cy={dataCy}
        onClose={handleClose}
        onOpen={handleOpen}
        value={selectedOption}
        onChange={handleChange}
        active={active}
        displayEmpty
        renderValue={(value) => {
          if (!value) return <div>{placeholder}</div>;
          if (renderValue) return renderValue(value);
          return <div>{value?.text}</div>;
        }}
        MenuProps={{
          classes: { paper: classes.select },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {placeholder && (
          <StyledItem value="" active={false} disabled>
            {placeholder}
          </StyledItem>
        )}
        {options
          .filter((option) => option.value === '' && option.onClick)
          .map((option) => (
            <StyledItem value="" active={false} onClick={option.onClick}>
              {option.text}
            </StyledItem>
          ))}
        {options
          .filter((op) => op.value !== '')
          .map((option, index) => (
            <StyledItem
              active={
                !!selectedOption?.value &&
                option.value === selectedOption?.value
              }
              key={index}
              disabled={option?.disabled || false}
              value={option}
            >
              {option.text}
            </StyledItem>
          ))}
      </BaseSelect>
    </StyledFormControl>
  );
};

export default withTheme(StyledSelect);
