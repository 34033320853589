import React from 'react';
import FundsTransfer from './FundTransfers/FundsTransfer';
import history from '../constants/history';
import AccessControl from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';

const DepositWithdrawal = (props) => {
  const oppositeAction = props.action === 'deposit' ? 'Withdrawal' : 'Deposit';
  return (
    <section className="transfer-funds-wrapper">
      <section className="transfer-funds-container">
        <FundsTransfer {...props} />
        <AccessControl
          allowedPermissions={[
            `${grants.CREATE}:${subjects.DEPOSITS}`,
            `${grants.CREATE}:${subjects.WITHDRAWALS}`,
          ]}
        >
          <p className="deposit-withdrawal-footer">
            Looking for the {oppositeAction.toLowerCase()} page? Access it here:{' '}
            <a
              className="primary"
              onClick={() => {
                history.push(
                  `/accounts/${props.accountId}/${oppositeAction.toLowerCase()}`,
                );
              }}
            >
              {oppositeAction}{' '}
            </a>
          </p>
        </AccessControl>
      </section>
    </section>
  );
};

export default DepositWithdrawal;
