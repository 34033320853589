import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector, hideModal } from 'erisxkit/client';
import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  achWithdrawalLimit,
  clearWithdrawalLimit,
  createFiatDepositRequestPromiseCreator,
} from '../../reducers/fundTransfersReducer';
import { getActiveAccount, getActiveOrDefaultAccountFD } from '../../reducers/activeAccountSelectors';
import { getFiatAssetTypes } from '../../reducers/assetTypesReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedBankAccounts,
} from '../../reducers/linkedAccountsReducer';
import { formatAssetTypesForFundsTransfer } from '../../utils/methods';
import AchWithdrawal from '../../components/FundTransfers/AchWithdrawal';
import { bankAccountOptions } from '../../components/FundTransfers/BankTransfers';

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createFiatDepositRequestPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      achWithdrawalLimit,
      clearWithdrawalLimit,
      fetchLinkedMemberAssetAccounts,
      hideModal,
    },
    dispatch,
  ),
});

const selector = formValueSelector('BankTransfers');

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  amount: selector(state, 'amount'),
  assetType: selector(state, 'assetType'),
  assetTypes: getFiatAssetTypes(state),
  fundsDesignation: selector(state, 'fundsDesignation'),
  linkedBankAccounts: getLinkedBankAccounts(state),
  accountsLoading: createLoadingSelector(['LINKED_BANK_ACCOUNTS'])(state),
  activeAccountFD: getActiveOrDefaultAccountFD(state),
});

class AchWithdrawailContainer extends Component {
  componentDidMount() {
    this.props.fetchLinkedMemberAssetAccounts();
  }

  componentWillUnmount() {
    this.props.clearWithdrawalLimit();
  }

  fetchLimit = (assetType, fundsDesignation) => {
    const { accountId } = this.props;
    this.props.achWithdrawalLimit({ accountId, assetType, fundsDesignation });
  };

  render = () => (
    <AchWithdrawal
      {...this.props}
      bankDetails={this.props.bankDetails}
      fetchLimit={this.fetchLimit}
      currencyOptions={formatAssetTypesForFundsTransfer({
        ...this.props,
        skipBalancesCheck: true,
      })}
      linkedBankAccounts={bankAccountOptions(this.props.linkedBankAccounts)}
      linkedBankAccountsLoading={this.props.accountsLoading}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AchWithdrawailContainer);
