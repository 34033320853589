import React from 'react';
import Tabs from '../../common/Tabs';
import Page from '../../common/Page';
import SpotBlockTradeRegistration from './SpotBlockTradeRegistration';
import SpotBlockTradeRegistrationHistory from './SpotBlockTradeRegistrationHistory';
import { Wrapper } from '../BlockTradesRegistration/BlockTradesRegistration.styles';

const panes = [
  {
    menuItem: 'Block Trade Requests',
    render: () => <SpotBlockTradeRegistration />,
  },
  {
    menuItem: 'Trade History',
    render: () => <SpotBlockTradeRegistrationHistory />,
  },
];

const SpotBlockTradesRegistrationIndex = () => {
  return (
    <Page header="">
      <Wrapper>
        <Tabs panes={panes} />
      </Wrapper>
    </Page>
  );
};

export default SpotBlockTradesRegistrationIndex;
