import React from 'react';
import GoHome from './GoHome';

const PageNotFound = () => {
  return (
    <GoHome
      header="Page Not Found"
      message="The page you are looking for does not exist."
    />
  );
};

export default PageNotFound;
