import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import get from 'lodash/get';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { TradingParticipantManagementReturn, TradingParticipantManagementRiskLimitsCompatible } from '../../ts/models/TradingParticipant';
import Page from '../../common/Page';
import {
  ExpandableColumn,
  TableEditState,
} from '../../common/EMPTable8/types';
import {
  getActiveOrFirstAccountLabel,
} from '../../reducers/activeAccountSelectors';
import AccountSelectorFCM from '../AccountSelector';
import styled from 'styled-components';
import {
  createTradingParticipantRiskLimitsPromiseCreator,
  fetchTradingParticipantRiskLimits,
  updateTradingParticipantRiskLimitsPromiseCreator,
  getTradingParticipantRiskLimitsCompatible
} from '../../reducers/tradingParticipantManagementReducer';
import tradingParticipantManagementMetadata from '../../metadata/tradingParticipantManagementMetadata';
import { getLoggedInUseCanWritePreTradeRisk } from '../../reducers/userReducer';
import { StyledSelectOption } from '../../common/StyledSelect';
import { getCarProducts } from '../../reducers/orderEntryReducer';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TradingParticipantManagement = () => {
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const tradingParticipantRiskLimits: TradingParticipantManagementRiskLimitsCompatible[] = useSelector(getTradingParticipantRiskLimitsCompatible);
  const accountLabel: string = useSelector(getActiveOrFirstAccountLabel);
  const carProducts: StyledSelectOption[] = useSelector(getCarProducts);
  const canWrite = useSelector(getLoggedInUseCanWritePreTradeRisk);
  const loading = useSelector((state) =>
    createLoadingSelector([fetchTradingParticipantRiskLimits._PREFIX])(state),
  );
  useEffect(() => {
    if (!firstRender.current && accountLabel) {
      memoizedFetch();
    } else {
      firstRender.current = false;
    }
  }, [accountLabel, dispatch]);

  const memoizedFetch = useCallback(
    () => {
      dispatch(
        fetchTradingParticipantRiskLimits({ accountLabel }));
    },
    [accountLabel, dispatch]
  );

  const memoizedSave = useCallback(
    (changes: TableEditState<TradingParticipantManagementReturn>) => {
      updateTradingParticipantRiskLimitsPromiseCreator(
        {
          accountLabel,
          tradingParticipantFirmCode: changes?.original?.tradingParticipantFirmCode ? get(changes, 'updated.tradingParticipantFirmCode') : get(changes, 'original.participantFirmCode'),
          marginExposureLimit: get(changes, 'updated.marginExposureLimit'),
          active: true,
          canParticipantModifyCarRiskLimits: get(changes, 'updated.canParticipantModifyCarRiskLimits'),
          productLimits: get(changes, 'updated.participantRiskLimits'),
          enabledProducts: get(changes, 'updated.participantRiskLimits', []).map(x => get(x, 'productCode')),
        },
        dispatch,
      ).then(() => { memoizedFetch(); });
    },
    [accountLabel, dispatch],
  );

  const memoizedSubRows = useCallback(
    (row) => row.participantRiskLimits || row.expiryLimits,
    [],
  );

  const memoizedExpandableColumns = useMemo(
    () =>
      [
        {
          name: 'participantRiskLimits',
          template: {
            productCode: '',
            maxShortExposure: '',
            maxLongExposure: '',
            expiryLimits: [],
          },
        },
        {
          name: 'expiryLimits',
          template: {
            expirationMonth: '',
            maxShortExposure: '',
            maxLongExposure: '',
          },
        },
      ] as ExpandableColumn[],
    [],
  );

  const memoizedColumns = useMemo(() => {
    return tradingParticipantManagementMetadata(canWrite, carProducts);
  }, [canWrite, carProducts]);

  const sortBy = useMemo(() => {
    return {
      sorting: [
        { id: 'participantName', desc: false },
        { id: 'productCode', desc: false },
      ],
    };
  }, []);

  return (
    <>
      <Page header="Trading Participant Management">
        <Header>
          <AccountSelectorFCM />
        </Header>
        <EMPTable8
          title="CarTable"
          data={tradingParticipantRiskLimits}
          local
          columns={memoizedColumns}
          fetchData={memoizedFetch}
          count={tradingParticipantRiskLimits?.length}
          getSubRows={memoizedSubRows}
          onSaveChanges={memoizedSave}
          expandableColumns={memoizedExpandableColumns}
          loading={loading}
          loadingText="Fetching CAR"
          minimumRows={5}
          showActions
          showActiveFilters
          initialState={sortBy}
        />
      </Page>
    </>
  );
};

export default TradingParticipantManagement;
