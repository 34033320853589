import get from 'lodash/get';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import FCMAccountMovement from '../ts/models/FCMAccountMovement.model';
import { createSelector } from 'reselect';

/** Action Names */
export const FETCH_FCM_ACCOUNT_MOVEMENTS = 'FCM_ACCOUNT_MOVEMENTS';

/** Actions */
export const fetchFcmAccountMovements = createRoutine(
  FETCH_FCM_ACCOUNT_MOVEMENTS,
);

type FetchFcmAccountMovementsResponse = {
  movements: FCMAccountMovement[];
  count: number;
};

/** Initial State */
type FCMAccountMovementsState = {
  movements: FCMAccountMovement[];
  count: number;
};

const initialState: FCMAccountMovementsState = {
  movements: [],
  count: 0,
};

/** Reducer */
export default handleActions<FCMAccountMovementsState, any>(
  {
    [fetchFcmAccountMovements.SUCCESS]: (
      state: FCMAccountMovementsState,
      { payload }: { payload: FetchFcmAccountMovementsResponse },
    ) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);

//* Selectors */
export const getFcmAccountMovementsState = (
  state: any,
): FCMAccountMovementsState => get(state, 'fcmAccountMovements', {});

export const getFcmAccountMovements = createSelector(
  [getFcmAccountMovementsState],
  (state) => get(state, 'movements', []) as FCMAccountMovement[],
);

export const getFcmAccountMovementsCount = createSelector(
  [getFcmAccountMovementsState],
  (state) => get(state, 'count', 0) as number,
);
