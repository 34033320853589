import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import Page from '../Page';
import EMPTable8 from '../EMPTable8/EMPTable8';
import get from 'lodash/get';
import { fcmPositionAdjustmentsMetadata } from '../../metadata/fcmPositionAdjustmentsMetadata';
import { FetchOptions, TableEditState } from '../EMPTable8/types';
import {
  POSITION_ADJUSTMENTS,
  submitPositionAdjustmentsPromiseCreator,
  fetchPositionAdjustments,
  fetchPositionAdjustmentsMetadata,
  getManualAdjustmentEndTime,
  getManualAdjustmentStartTime,
  getPositionAdjustments,
  getMorningAfterManualAdjustmentStartTime,
  getMorningAfterManualAdjustmentEndTime,
} from '../../reducers/positionsAdjustmentsReducer';
import { getActiveOrFirstAccountLabel } from '../../reducers/activeAccountSelectors';
import { PositionAdjustments } from '../../ts/models/Positions/PositionAdjustments';
import moment from 'moment';
import { TableActionName } from '../EMPTable8/TableActions/types';
import AccountSelectorFCM from '../../components/AccountSelector';

const sortPositions = (
  a: PositionAdjustments,
  b: PositionAdjustments,
): number => {
  const symbolComparison = a.productSymbol.localeCompare(b.productSymbol);

  if (symbolComparison === 0) {
    return a.expiryDate.localeCompare(b.expiryDate);
  }
  return symbolComparison;
};

const FCMPositionAdjustments = () => {
  const dispatch = useDispatch();
  const accountLabel: string = useSelector(getActiveOrFirstAccountLabel);
  /** Selectors */
  const positionAdjustments: PositionAdjustments[] = useSelector(
    getPositionAdjustments,
  );
  const loading = useSelector((state) =>
    createLoadingSelector([POSITION_ADJUSTMENTS])(state),
  );
  const defaultFetchFilter = useRef<FetchOptions | undefined>(undefined);
  const manualAdjustmentStartTime = useSelector(getManualAdjustmentStartTime);
  const manualAdjustmentEndTime = useSelector(getManualAdjustmentEndTime);
  const morningAfterManualAdjustmentStartTime = useSelector(getMorningAfterManualAdjustmentStartTime);
  const morningAfterManualAdjustmentEndTime = useSelector(getMorningAfterManualAdjustmentEndTime);
  const adjStartTimeOnly = useMemo(() => {
    return moment(manualAdjustmentStartTime).format('HH:mm');
  }, [manualAdjustmentStartTime]);
  const adjEndTimeOnly = useMemo(() => {
    return moment(manualAdjustmentEndTime).format('HH:mm');
  }, [manualAdjustmentEndTime]);
  const adjMorningAfterStartTimeOnly = useMemo(() => {
    return moment(morningAfterManualAdjustmentStartTime).format('HH:mm');
  }, [morningAfterManualAdjustmentStartTime]);
  const adjMorningAfterEndTimeOnly = useMemo(() => {
    return moment(morningAfterManualAdjustmentEndTime).format('HH:mm');
  }, [morningAfterManualAdjustmentEndTime]);
  const enableGridEditing = useMemo(
    () => {
      const now = moment();
      if (!manualAdjustmentEndTime || !morningAfterManualAdjustmentEndTime) {
        return true;
      }
      const start = moment(manualAdjustmentStartTime);
      const end = moment(manualAdjustmentEndTime);
      const startMorning = moment(morningAfterManualAdjustmentStartTime);
      const endMorning = moment(morningAfterManualAdjustmentEndTime);
      return (start < now && now < end) || (startMorning < now && now < endMorning);
    },
    [manualAdjustmentStartTime, manualAdjustmentEndTime, morningAfterManualAdjustmentStartTime, morningAfterManualAdjustmentEndTime]
  );

  /** Fetch Callback */
  const memoizedFetch = useCallback(
    (options: FetchOptions) => {
      if (!defaultFetchFilter.current) {
        defaultFetchFilter.current = options;
      }
      const accountFilter = {
        attr: 'account_label',
        op: 'eq',
        value: accountLabel,
      };
      dispatch(fetchPositionAdjustmentsMetadata());
      dispatch(
        fetchPositionAdjustments({
          ...options,
          filter: [...(options.filter || []), accountFilter],
        }),
      );
    },
    [accountLabel, dispatch],
  );

  const memoizedSave = useCallback(
    (changes: TableEditState<PositionAdjustments>) => {
      const positionAdjustment = get(changes, 'updated');
      submitPositionAdjustmentsPromiseCreator(
        {
          updates: [
            {
              trade_date: positionAdjustment.tradeDate,
              account_label: positionAdjustment.accountLabel,
              product_symbol: positionAdjustment.productSymbol,
              contract_symbol: positionAdjustment.contractSymbol,
              eod_gross_long: positionAdjustment.eodGrossLong,
              eod_gross_short: positionAdjustment.eodGrossShort,
              desired_long: positionAdjustment.desiredLong,
            },
          ],
        },
        dispatch,
      ).then(() => {
        if (defaultFetchFilter.current) {
          memoizedFetch(defaultFetchFilter.current);
        }
      });
    },
    [dispatch, memoizedFetch],
  );

  const sortedPositionAdjustments = useMemo(
    () => positionAdjustments.sort(sortPositions),
    [positionAdjustments],
  );
  return (
    <>
      <Page
        header={`Position Adjustments`}
        subHeader={`This page is used for manual updates to 
          PCS processing and will be editable after ${adjStartTimeOnly} 
          but before ${adjEndTimeOnly} for T-1 trade date evening adjustments
          and from ${adjMorningAfterStartTimeOnly} to ${adjMorningAfterEndTimeOnly}
          the next morning for T-1 trade date morning after adjustments.`}
      >
        <AccountSelectorFCM />
        <EMPTable8
          title="fcmPositionAdjustments"
          data={sortedPositionAdjustments}
          columns={fcmPositionAdjustmentsMetadata}
          fetchData={memoizedFetch}
          count={positionAdjustments?.length || 0}
          onSaveChanges={memoizedSave}
          loading={loading}
          loadingText="Fetching Position Adjustments"
          noDataText="No Positions available for Adjustment"
          minimumRows={5}
          showActiveFilters
          disableEdit={!enableGridEditing}
          showActions
          actionsToHide={[TableActionName.Filters]}
          dataCy="position-adjustments-table"
        />
      </Page>
    </>
  );
};

export default FCMPositionAdjustments;
