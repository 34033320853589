import React from 'react';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import styled from 'styled-components';

const StyledPopover = styled(Popover)<{ active: boolean }>`
  .MuiPopover-paper {
    background: ${({ theme }) => theme.table.filter.bg};
    padding: 10px;
    overflow: visible;
  }

  & input {
    color: ${({ active, theme }) =>
      active ? theme.table.accent : theme.table.input.color};
  }
`;
type Props = {
  active?: boolean;
} & PopoverProps;

const StyledPopup = ({ children, active, ...rest }: Props) => {
  return (
    <StyledPopover active={!!active} {...rest}>
      {children}
    </StyledPopover>
  );
};

export default StyledPopup;
