import React, { Fragment, useState } from 'react';
import _, { memoize } from 'lodash';
import { Button, Form, Divider } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import {
  renderField,
  renderRadio,
  renderDateTime,
} from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import { parseToDataCyAttr } from '../../utils/parseToDataCyAttr';

const memoizedMaxLength = memoize(rules.maxLength);

const componentTypeFromDescription = (
  key,
  type,
  name,
  text,
  maxLength,
  dataCy,
) => {
  switch (type) {
    case 'date':
      return (
        <Form.Field className="compliance-question">
          <label htmlFor={name}>{text}</label>
          <Field
            component={renderDateTime}
            name={name}
            timeFormat={false}
            closeOnSelect
            startDate={Date.now()}
            placeholder="mm/dd/yyyy"
            validate={[rules.required]}
            data-cy={dataCy}
          />
        </Form.Field>
      );
    case 'boolean':
      return [
        <label htmlFor={name}>{text}</label>,
        <Form.Group>
          <Field component={renderRadio} label="Yes" name={name} />
          <Field component={renderRadio} label="No" name={name} />
        </Form.Group>,
      ];
    case 'string':
    default:
      return (
        <Form.Field className="compliance-question">
          <label htmlFor={name}>{text}</label>
          <Field
            fluid
            component={renderField}
            name={name}
            placeholder="Enter your response."
            validate={[rules.required, memoizedMaxLength(maxLength)]}
            data-cy={dataCy}
          />
        </Form.Field>
      );
  }
};

const AdditionalField = ({ answer, question, dataCyPrefix }) => {
  if (!answer) {
    return null;
  }
  const { additionalFields } = question;
  const additionalQ = _.find(
    additionalFields,
    (field) =>
      _.get(field, 'answer', '').toLowerCase() === answer.toLowerCase(),
  );
  if (additionalQ) {
    return (
      <Fragment>
        <Divider />
        <div className="additional-fields">
          {_.chain(additionalQ)
            .get(['fields'])
            .keys()
            .map((key, questionNum) => {
              const { text, type, maxLength } = _.get(
                additionalQ,
                ['fields'],
                {},
              )[key];
              return (
                <Form.Field>
                  <Divider hidden />
                  {componentTypeFromDescription(
                    key,
                    type,
                    `${key}_question_id_${question.questionId}`,
                    text,
                    maxLength,
                    `${dataCyPrefix}-${questionNum + 1}`,
                  )}
                  <Divider hidden />
                </Form.Field>
              );
            })
            .value()}
        </div>
        <Divider />
      </Fragment>
    );
  }
  return null;
};

export const Question = ({ question = {}, answer, className }) => (
  <div className={className || 'questions-container'}>
    <Form.Group>
      <Form.Field>{question.question}</Form.Field>
      <Form.Field>
        {question.choices.length ? (
          question.choices.map((choice, questionNum) => (
            <Field
              name={`question_id_${question.questionId}`}
              component={renderRadio}
              label={choice}
              validate={rules.required}
              data-cy={`ob-verification-question-${
                parseInt(question.questionId) + 1
              }-${questionNum + 1}`}
            />
          ))
        ) : (
          <Field
            component={renderField}
            name={`question_id_${question.questionId}`}
            validate={rules.required}
            fluid
            placeholder="Please enter your response."
            data-cy={`ob-verification-question-${
              parseInt(question.questionId) + 1
            }-1`}
          />
        )}
      </Form.Field>
      <AdditionalField
        answer={answer}
        question={question}
        dataCyPrefix={`ob-verification-additional-field-${
          parseInt(question.questionId) + 1
        }`}
      />
    </Form.Group>
  </div>
);
export const ComplianceQuestions = ({
  next,
  loading,
  questions = [],
  invalid,
  answers,
}) => {
  const [buttonClicked, updateButtonClicked] = useState(false);

  const submitClicked = () => {
    updateButtonClicked(true);
    next();
  };
  return (
    <div className="onboarding-container">
      <div className="onboarding-content">
        <div className="onboarding-header">
          Please answer a few questions before you continue...
        </div>
        {questions.length
          ? questions.map((question) => (
              <Question
                key={question.questionId}
                question={question}
                answer={_.get(
                  answers,
                  `question_id_${question.questionId}`,
                  '',
                )}
              />
            ))
          : 'There are no questions to display.'}
        <Button
          primary
          loading={loading}
          onClick={submitClicked}
          disabled={invalid || buttonClicked}
          data-dd-action-name="Compliance Questions Submit"
          data-cy="ob-verification-questions-submit-button"
        >
          Submit & Continue
        </Button>
      </div>
    </div>
  );
};
export default reduxForm({
  form: 'compliance',
})(ComplianceQuestions);
