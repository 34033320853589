import { get } from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  createApiCredentials,
  fetchApiCredentials,
  deleteApiCredentials,
  CREATE_API_CREDENTIALS,
  API_CREDENTIALS,
  apiCredentialsPermissions,
  API_CREDENTIALS_PERMISSIONS,
  createThirdPartyApiCredentials,
} from '../reducers/apiCredentialsReducer';
import { createSaga } from '../utils/createSaga';
import api, * as urls from '../api';

function* deleteApiCredentialsSaga(arg) {
  yield put(deleteApiCredentials.request());
  try {
    const result = yield call(
      api.post,
      urls.DELETE_API_CREDENTIALS_API_ENDPOINT,
      arg.payload,
    );
    if (result) {
      yield put(
        deleteApiCredentials.success({ key: get(arg, ['payload', 'key']) }),
      );
    }
  } catch (e) {
    yield put(deleteApiCredentials.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(
    createApiCredentials.TRIGGER,
    createSaga(createApiCredentials, CREATE_API_CREDENTIALS),
  );
  yield takeLatest(
    createThirdPartyApiCredentials.TRIGGER,
    createSaga(
      createThirdPartyApiCredentials,
      createThirdPartyApiCredentials._PREFIX,
    ),
  );
  yield takeLatest(
    fetchApiCredentials.TRIGGER,
    createSaga(fetchApiCredentials, API_CREDENTIALS),
  );
  yield takeLatest(
    apiCredentialsPermissions.TRIGGER,
    createSaga(apiCredentialsPermissions, API_CREDENTIALS_PERMISSIONS),
  );
  yield takeLatest(deleteApiCredentials.TRIGGER, deleteApiCredentialsSaga);
}
