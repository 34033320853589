import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import EMPTable8 from '../common/EMPTable8/EMPTable8';
import Page from '../common/Page';
import fcmBalanceSnapshotsMetadata from '../metadata/fcmBalanceSnapshotsMetadata';
import {
  fetchFcmBalanceCycles,
  fetchFcmBalanceCycle,
  FETCH_FCM_BALANCE_CYCLE,
  getFcmBalanceCycles,
  getFCMBalanceSnapshots,
  getFCMBalanceSnapshotsCount,
} from '../reducers/fcmBalanceSnapshotsReducer';
import FCMBalanceSnapshot from '../ts/models/FCMBalanceSnapshot/FCMBalanceSnapshot.model';
import { FetchOptions } from '../common/EMPTable8/types';
import StyledSelect, { StyledSelectOption } from '../common/StyledSelect';
import DropdownOption from '../ts/types/DropdownOption.type';
import FCMBalanceCycle from '../ts/models/FCMBalanceSnapshot/FCMBalanceCycle.model';
import { fadeIn } from '../styles/effects';
import { getSelectedMemberId } from '../reducers/membersReducer';
import AccessControl from '../common/AccessControl';
import { getUserHasViewBalances } from '../reducers/userReducer';
import { TableActionName } from '../common/EMPTable8/TableActions/types';

const CycleSelect = styled(StyledSelect)`
  max-width: 250px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;

const Type = styled.span`
  font-weight: bold;
`;

const CycleInfo = styled.div`
  display: flex;
  color: #4f4f4f;
  > span {
    margin-right: 10px;
  }
`;

const FadeIn = styled.div`
  ${fadeIn}
`;

const CycleHeader = styled.span`
  font-size: 17px;
  font-weight: bold;
`;

const parseCycle = (cycle?: string) =>
  cycle ? startCase(lowerCase(cycle)) : '';

const CycleOption = ({ option }: { option: StyledSelectOption }) => {
  const cycle: FCMBalanceCycle = get(option, 'value', {}) as FCMBalanceCycle;
  return (
    <p>
      {moment(cycle.date).format('lll')} |{' '}
      <Type>{parseCycle(cycle.cycle)}</Type>
    </p>
  );
};

const SelectedCycle = () => {
  return (
    <CycleInfo>
      <CycleHeader>Cycle: </CycleHeader>
    </CycleInfo>
  );
};

const HomeCMP = () => {
  const dispatch = useDispatch();
  const memberId = useSelector(getSelectedMemberId);
  const balances: FCMBalanceSnapshot[] = useSelector(getFCMBalanceSnapshots);
  const count: number = useSelector(getFCMBalanceSnapshotsCount);
  const loading = useSelector((state: any) =>
    createLoadingSelector([FETCH_FCM_BALANCE_CYCLE])(state),
  );
  const cycles = useSelector(getFcmBalanceCycles);
  const cycleOptions: DropdownOption<FCMBalanceCycle>[] = useMemo(
    () =>
      cycles.map(
        (cycle: FCMBalanceCycle, key: number) =>
          ({
            key,
            text: `${moment(cycle.date).format('ll')} | ${parseCycle(
              cycle?.cycle,
            )}`,
            value: cycle,
          }) as DropdownOption<FCMBalanceCycle>,
      ),
    [cycles],
  );

  const [selectedCycle, setSelectedCycle] = useState<
    StyledSelectOption<FCMBalanceCycle> | undefined
  >(undefined);

  const memoizedFetch = useCallback(
    (options: FetchOptions) => {
      if (memberId) {
        dispatch(
          fetchFcmBalanceCycle({
            ...options,
            memberId,
            tradeDate: get(selectedCycle, 'value.tradeDate', undefined),
            date: get(selectedCycle, 'value.date', undefined),
          }),
        );
      }
    },
    [selectedCycle, memberId],
  );

  useEffect(() => {
    if (memberId) dispatch(fetchFcmBalanceCycles({ memberId }));
  }, [memberId]);

  return (
    <AccessControl customPermissionSelector={getUserHasViewBalances}>
      <Page header="Balance Snapshot">
        <Row>
          <SelectedCycle />
          <CycleSelect
            options={cycleOptions as StyledSelectOption[]}
            value={selectedCycle}
            placeholder="Select Cycle"
            onChange={(option: StyledSelectOption<FCMBalanceCycle>) => {
              setSelectedCycle(option);
            }}
            renderValue={(value: StyledSelectOption) => (
              <CycleOption option={value} />
            )}
          />
        </Row>
        <FadeIn>
          <EMPTable8
            title="fcmBalanceSnapshots"
            data={balances}
            columns={fcmBalanceSnapshotsMetadata}
            fetchData={memoizedFetch}
            count={count}
            loading={loading}
            loadingText="Fetching Balance Snapshots"
            noDataText="No Balance Snapshots to display"
            showActions
            showActiveFilters
            actionsToHide={[TableActionName.Filters]}
            actionOptions={{
              export: {
                filename: 'balance-snapshots',
              },
            }}
          />
        </FadeIn>
      </Page>
    </AccessControl>
  );
};

export default HomeCMP;
