import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import {
  arrayToObject,
  expungePostingRecentTransactions,
  expungePostingRequestHistory,
} from '../utils/methods';

export const ACCOUNT_HISTORY_REQUEST = 'ACCOUNT_HISTORY_REQUEST';
export const ACCOUNT_HISTORY_SUCCESS = 'ACCOUNT_HISTORY_SUCCESS';
export const ACCOUNT_HISTORY_FAILED = 'ACCOUNT_HISTORY_FAILED';

export const JOURNAL_HISTORY = 'JOURNAL_HISTORY';
export const REQUEST_HISTORY = 'REQUEST_HISTORY';
export const RECENT_TRANSACTIONS = 'RECENT_TRANSACTIONS';
export const BANK_TRANSACTION_DETAILS = 'BANK_TRANSACTION_DETAILS';

export const TRADE_JOURNALS = 'TRADE_JOURNALS';

export const fetchJournalHistory = createRoutine(JOURNAL_HISTORY);
export const fetchRequestHistory = createRoutine(REQUEST_HISTORY);
export const fetchRecentTransactions = createRoutine(RECENT_TRANSACTIONS);
export const fetchBankTransactionDetails = createRoutine(
  BANK_TRANSACTION_DETAILS,
);

export const tradeJournals = createRoutine(TRADE_JOURNALS);

export default handleActions(
  {
    [ACCOUNT_HISTORY_SUCCESS]: (state, { accountHistory }) => accountHistory,
    [fetchJournalHistory.SUCCESS]: (state, { payload }) => ({
      ...state,
      journalHistory: payload,
    }),
    [fetchRecentTransactions.SUCCESS]: (state, { payload }) => ({
      ...state,
      recentTransactions: expungePostingRecentTransactions(payload),
    }),
    [fetchBankTransactionDetails.SUCCESS]: (state, { payload }) => ({
      ...state,
      bankTransactionDetailsById: {
        ...state.bankTransactionDetailsById,
        ...payload,
      },
    }),
    [fetchRequestHistory.SUCCESS]: (state, { payload }) => ({
      ...state,
      requestHistory: expungePostingRequestHistory(payload),
    }),
    [tradeJournals.SUCCESS]: (state, { payload }) => ({
      ...state,
      tradeJournals: {
        count: payload.count,
        byId: arrayToObject(payload.tradeJournals, 'uuid'),
      },
    }),
  },
  {},
);

//* Selectors */
const getPathFromURL = () => window.location.pathname.match(/([^/]+)/g);

// TODO: get location.pathname from store; else get from URL in a non-exported function.
export const getPath = (state) =>
  _.get(state, ['location', 'pathname'], getPathFromURL());

export const getActiveAssetType = (state) => _.nth(getPath(state), 1);

export const getJournalHistory = (state) =>
  _.get(state, ['history', 'journalHistory'], {});
export const getRequestHistory = (state) =>
  _.get(state, ['history', 'requestHistory'], {});
export const getRecentTransactions = (state) =>
  _.get(state, ['history', 'recentTransactions'], []);
export const getBankTransactionDetails = (state, uuid) =>
  _.get(state, ['history', 'bankTransactionDetailsById'], {})[uuid] || {};
export const getTradeJournals = (state) =>
  _.get(state, ['history', 'tradeJournals', 'byId'], {});
export const getTradeJournalsList = createSelector(
  [getTradeJournals],
  (trades) => Object.values(trades),
);
export const getTradeJournalsCount = (state) =>
  _.get(state, ['history', 'tradeJournals', 'count'], 0);
//* Actions */
/**
 * @param {string} account - identifying the account the history is requested for.
 */
export const fetchHistory = (accountId) => ({
  type: ACCOUNT_HISTORY_REQUEST,
  payload: { accountId },
});
