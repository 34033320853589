import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import StyledButton from '../../common/StyledButton';
import { filterInputStyle } from './Filters/styled';
import DebouncedInput from '../DebouncedInput';
import TextButton from '../../common/TextButton';
import TableCellAlignment from '../../ts/enums/TableCellAlignment.enum';
import TableCellTextTransform from '../../ts/enums/TableCellTextTransform.enum';
import TableActions from './TableActions/TableActions';

export const Cell = styled.div<{
  align?: TableCellAlignment;
  textTransform?: TableCellTextTransform;
  className?: string;
}>`
  display: flex;
  padding: 0 10px;
  justify-content: ${({ align }) => {
    if (align === TableCellAlignment.Left) return 'flex-start';
    if (align === TableCellAlignment.Center) return 'center';
    if (align === TableCellAlignment.Right) return 'flex-end';
  }};
  text-transform: ${({ textTransform }) => textTransform};
  class: ${({ className }) => className};
`;

export const Table = styled.table`
  // Take width of container
  width: 100%;
  // No separation between cells
  border-spacing: 0px;
  // Row borders
  border-collapse: collapse;
  position: relative;

  & thead {
    & tr {
      color: ${({ theme }) => theme.table.header.color};
      border: 1px solid ${({ theme }) => theme.table.header.border};
      // header bg color set as important to override odd tr bg color rule
      background-color: ${({ theme }) => theme.table.header.bg};
      text-align: left;
      &:hover {
        background-color: ${({ theme }) => theme.table.header.hover};
      }

      & th {
        padding: 0px 5px;
        position: relative;
        white-space: nowrap;
        font-weight: ${({ theme }) => theme.table.header.fontWeight};
      }
    }
  }

  & tr {
    background-color: ${({ theme }) => theme.table.row.bg};
    border-bottom: 1px solid ${({ theme }) => theme.table.row.bottomBorder};
    height: 2.3em;
    color: ${({ theme }) => theme.table.row.color};
    &:hover {
      background-color: ${({ theme }) => theme.table.row.hover};
    }
  }
  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.table.row.evenRowBg};
    &:hover {
      background-color: ${({ theme }) => theme.table.row.hover};
    }
  }
`;

interface Props {
  active: boolean;
}

export const FilterableTh = styled.th<Props>`
  display: flex;
  align-items: baseline;
  color: ${({ active, theme }) => active && theme.table.accent};

  & i {
    cursor: pointer;
    margin-left: 10px;
  }
`;

export const Resizer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  padding: 0px 5px;
  width: 1px;
  background: none;
  border-right: 1px solid ${({ theme }) => theme.table.resizer};
  margin-right: 5px;
  height: 50%;
  margin-top: 7px;
`;

export const EditButton = styled.p`
  color: ${colors.GREEN};
  cursor: pointer;
`;

export const EditModeButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 15px;
`;

export const SaveChanges = styled(StyledButton)`
  width: 200px;
  margin-left: 20px;
  font-size: 17px;
`;

interface TableInputProps {
  changed: boolean;
}
export const TableInput = styled.input.attrs({
  autocomplete: 'off', // Prevent autocompletion from password and 1P popup
})<{ changed: boolean }>`
  ${filterInputStyle};
  height: 25px;
  width: 90%;
  color: ${({ changed, theme }) =>
    changed ? theme.table.accent : theme.table.input.color};
  border: 1px solid #00b100;
  &:focus {
    border: 1px solid #ff8c00;
  }
`;

export const DebouncedTableInput = styled(DebouncedInput)<TableInputProps>`
  ${filterInputStyle};
  height: 25px;
  width: 90%;
  color: ${({ changed, theme }) =>
    changed ? colors.GREEN : theme.table.input.color};
`;

export const TableSelect = styled.select<TableInputProps>`
  ${filterInputStyle};
  height: 25px;
  width: 90%;
  color: ${({ changed, theme }) =>
    changed ? colors.GREEN : theme.table.select.color};

  & option {
    background-color: ${({ theme }) => theme.table.select.color};
  }
`;

export const EditDisclaimer = styled.div`
  background-color: ${({ theme }) => theme.table.edit.disclaimer.bg};
  border: ${({ theme }) => `1px solid ${theme.table.edit.disclaimer.border}`};
  color: ${({ theme }) => theme.table.edit.disclaimer.color};
  text-align: left;
  align-self: flex-start;
  flex-basis: 70%;
  margin-right: auto;
  padding: 10px;
`;

export const Dismiss = styled(TextButton)`
  margin-left: 20px;
  color: ${({ theme }) => theme.table.edit.dismiss};
`;

const TableOverlayStyle = css`
  top: 2.3em; // tr height
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
  color: ${({ theme }) => theme.table.noDataText};
`;
export const LoadingWrapper = styled.div`
  ${TableOverlayStyle};

  .ui.loader: after {
    border-color: ${({ theme }) => theme.table.loader.spinner} transparent
      transparent;
  }
`;

export const NoDataWrapper = styled.div`
  ${TableOverlayStyle};
`;

export const QuickTools = styled(TableActions)`
  margin: 10px 0px;
  margin-left: auto;
`;

export const ToolsWrapper = styled.div<{ showFilters: boolean }>`
  display: flex;
  justify-content: ${({ showFilters }) =>
    showFilters ? 'space-between' : 'flex-end'};
  align-items: baseline;
  min-height: 38px;
  margin-top: 10px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  position: relative;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.table.scrollbar};
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.table.scrollbar};
    border-radius: 20px;
  }
  /** Hide on IE */
  /* scrollbar-width: none; */
`;

export const Th = styled.th<{
  hasSubheaders: boolean;
  align?: TableCellAlignment;
}>`
  text-align: ${({ hasSubheaders, align }) => {
    if (hasSubheaders) return TableCellAlignment.Center;
    if (align) return align;
  }};
`;
