import React from 'react';
import { hideModal } from 'erisxkit/client';
import { useDispatch } from 'react-redux';
import GenericModal from './GenericModal';

export const OFFBOARDING_MODAL_SESSION_STORAGE_KEY = 'offboardingModalShown';

const OffboardingModal: React.FC = () => {
  const dispatch = useDispatch();
  const onClose = () => {
    sessionStorage.setItem(OFFBOARDING_MODAL_SESSION_STORAGE_KEY, 'true');
    dispatch(hideModal());
  };
  const content = (
    <p>
      As of <b>July 1, 2024</b>, Cboe Digital Exchange, LLC and Cboe Clear
      Digital, LLC (collectively, “Cboe Digital”), have terminated all spot
      digital assets accounts and Member Master Agreements. Any outstanding
      digital asset balances in spot digital asset accounts were liquidated and
      converted to fiat currency, pursuant to Section 11 of the Cboe Digital
      Member Master Agreement. The remaining funds were sent to the account
      holder’s address on record. Any unclaimed funds will be escheated to the
      state. Please refer to the official press release for additional
      information:
      <a href="https://ir.cboe.com/news/news-details/2024/Cboe-Global-Markets-Realigns-Digital-Asset-Business-Leveraging-Strength-and-Expertise-in-Derivatives-and-Clearing-/default.aspx">
        {' '}
        Link.{' '}
      </a>
      <p>
        If you have any questions, please contact Cboe Digital Membership
        at:&nbsp;
        <a href="mailto:Digital.Membership@cboe.com">
          {` Digital.Membership@cboe.com`}
        </a>
      </p>
    </p>
  );
  return (
    <GenericModal
      header="Notice"
      content={content}
      confirmProps={{ onClick: onClose, text: 'Confirm' }}
    />
  );
};

export default OffboardingModal;
