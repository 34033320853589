import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import PropTypes from 'prop-types';
import BankTransfers from '../../components/FundTransfers/BankTransfers';
import {
  achWithdrawalLimit,
  clearWithdrawalLimit,
  createFiatWithdrawalRequest,
} from '../../reducers/fundTransfersReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedBankAccounts,
  checkBankAccount,
} from '../../reducers/linkedAccountsReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import { WITHDRAWAL_CONFIRM } from '../../constants/modalTypes';
import { isViewportLessThan } from '../../reducers/uiReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { getFiatAssetTypes } from '../../reducers/assetTypesReducer';
import { formatAssetTypesForFundsTransfer } from '../../utils/methods';
import { accountIsAllowedFutures } from '../../utils/permissions';
import { getActiveBalanceDetails } from '../../reducers/balancesReducer';

const mapStateToProps = (state) => ({
  linkedBankAccounts: getLinkedBankAccounts(state),
  accountsLoading: createLoadingSelector(['LINKED_BANK_ACCOUNTS'])(state),
  bankVerificationLoading: createLoadingSelector(['PLAID_VERIFY_ACCOUNT'])(
    state,
  ),
  account: getActiveAccount(state),
  assetTypes: getFiatAssetTypes(state),
  user: _.get(state, 'user', {}),
  medium: isViewportLessThan('large', state),
  small: isViewportLessThan('small', state),
  selectedMemberId: getSelectedMemberId(state),
  fundBalances: getActiveBalanceDetails(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      achWithdrawalLimit,
      clearWithdrawalLimit,
      createFiatWithdrawalRequest,
      checkBankAccount,
      fetchLinkedMemberAssetAccounts,
      showModal,
      hideModal,
    },
    dispatch,
  );

const initialState = (props) => ({});

class BankTransfersContainer extends Component {
  state = initialState(this.props);

  componentDidMount = () => {
    this.props.fetchLinkedMemberAssetAccounts();

    // if we have only one option and no funds designations, default to asset type
    if (
      !accountIsAllowedFutures(this.props.account) &&
      this.props.assetTypes.length === 1
    ) {
      this.setState({
        assetType: _.get(this.props, ['assetTypes', '0', 'symbol'], '-'),
      });
    }
  };

  componentWillUnmount() {
    this.props.clearWithdrawalLimit();
  }

  fetchLimit = (assetType, fundsDesignation) => {
    const { accountId } = this.props;
    this.props.achWithdrawalLimit({ accountId, assetType, fundsDesignation });
  };

  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    this.setState(change);
  };

  confirmWithdrawal = () => {
    let payMethod = this.props.type.transfer;
    this.props.showModal(WITHDRAWAL_CONFIRM, { type: 'fiat', payMethod });
  };

  render = () => (
    <section className="overlay-form ui text container">
      <BankTransfers
        accountCode={this.props.accountCode}
        firmCode={this.props.firmCode}
        medium={this.props.medium}
        small={this.props.small}
        balances={this.props.fundBalances}
        account={this.props.account}
        assetType={this.state.assetType}
        fetchLimit={this.fetchLimit}
        currencyOptions={formatAssetTypesForFundsTransfer(this.props)}
        hasBalancesGreaterThanZero={this.props.hasBalancesGreaterThanZero}
        handleChange={this.handleChange}
        accountsLoading={this.props.accountsLoading}
        linkedBankAccounts={this.props.linkedBankAccounts}
        plaidRequest={this.props.createRequest}
        confirmWithdrawal={this.confirmWithdrawal}
      />
    </section>
  );
}

BankTransfersContainer.propTypes = {
  balances: PropTypes.objectOf(PropTypes.string),
};

BankTransfersContainer.defaultProps = {
  balances: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankTransfersContainer);
