import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  XTable,
  createLoadingSelector,
  filteredArrayToAttrValue,
} from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  subComponentMetadata,
  closeoutJournalsMetadata,
} from '../../metadata/closeoutJournalsMetadata';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import {
  getCloseoutJournals,
  CLOSEOUT_JOURNALS,
  fetchCloseoutJournals,
  getCloseoutJournalsCount,
} from '../../reducers/closeoutJournalsReducer';

const mapStateToProps = (state) => ({
  closeoutsList: getCloseoutJournals(state),
  closeoutsCount: getCloseoutJournalsCount(state),
  closeoutsLoading: createLoadingSelector([CLOSEOUT_JOURNALS])(state),
  selectedAccountId: getActiveAccountId(state),
});

const mapDispatchToProps = {
  fetchCloseoutJournals,
};

class CloseoutsContainer extends Component {
  state = { expanded: [] };
  fetchData = (state) => {
    if (this.props.selectedAccountId) {
      let filter = [
        {
          attr: 'account_id',
          op: 'match',
          value: this.props.selectedAccountId,
        },
      ];
      if (state.filtered.length) {
        filter = filter.concat(filteredArrayToAttrValue(state.filtered));
      }
      this.props.fetchCloseoutJournals({
        limit: state.pageSize,
        offset: state.page * state.pageSize,
        filter,
        // this is used for frontend purposes
        accountId: this.props.selectedAccountId,
      });
    }
  };

  render = () => (
    <section className="main-holdings">
      <Header
        content="Closeouts"
        subheader="See all the closeouts for this account."
      />
      <XTable
        title="closeout-journals"
        minRows={5}
        className="dark"
        id="closeout-journals-maincomponent"
        loading={this.props.closeoutsLoading}
        data={this.props.closeoutsList}
        pageSize={this.props.closeoutsList.length}
        columns={closeoutJournalsMetadata}
        expanded={this.state.expanded}
        manual
        onFetchData={this.fetchData}
        pages={Math.ceil(this.props.closeoutsCount / 20)}
        showPageSizeOptions={false}
        defaultPageSize={20}
        onExpandedChange={(newExpanded) => {
          this.setState({ expanded: newExpanded });
        }}
        SubComponent={({ row }) => (
          <XTable
            className="subtable no-stripe"
            id="closeout-journals-subcomponent"
            title="closeout-journals-subcomponent"
            data={get(row, ['_original', 'closeoutItems'], [])}
            showPagination={false}
            pageSize={get(row, ['_original', 'closeoutItems'], []).length}
            columns={subComponentMetadata}
          />
        )}
      />
    </section>
  );
}

CloseoutsContainer.propTypes = {
  fetchCloseoutJournals: PropTypes.func.isRequired,
  closeoutsList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          qty: PropTypes.string,
          px: PropTypes.string,
        }),
      ),
    }),
  ),
  closeoutsLoading: PropTypes.bool,
  closeoutsCount: PropTypes.number,
};

CloseoutsContainer.defaultProps = {
  closeoutsList: [],
  closeoutsLoading: false,
  closeoutsCount: 0,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseoutsContainer);
