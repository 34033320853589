import has from 'lodash/has';
import { CAR, Product } from '../../ts/models/CAR.model';
import {
  DEFAULT_EXPIRY_LIMIT_INDEX,
  EXPIRING_POSITION_LIMIT,
} from './menuOptions';
import { ParticipantRiskLimit, TradingParticipantManagementRiskLimits } from '../../ts/models/TradingParticipant';

export const currentProductExpiries = (product: Product): string[] => {
  let expiries = product.expiryLimits.map((expiry) =>
    String(expiry.expirationMonth),
  );
  return expiries;
};

export const productHasDefaultExpiry = (product: Product): boolean =>
  product.expiryLimits.some(
    (c) => String(c?.expirationMonth) === DEFAULT_EXPIRY_LIMIT_INDEX,
  );

export const productHasExpiringExpiry = (product: Product): boolean =>
  product.expiryLimits.some(
    (c) => String(c?.expirationMonth) === EXPIRING_POSITION_LIMIT,
  );

export const currentProductSelected = (car: CAR): string[] =>
  car?.productLimits?.filter((x) => x.productCode).map((x) => x.productCode);

export const availableCarProducts = (
  carProducts,
  selectedValuesProductLimits,
) => {
  return carProducts.filter(
    (car) => !selectedValuesProductLimits?.includes(car.value),
  );
};

export const availableCarProductExpiryLimits = (
  carProductsLimits,
  selectedValuesProductLimits,
) => {
  return carProductsLimits.filter(
    (limit) => !selectedValuesProductLimits?.includes(limit.value),
  );
};

export const currentProductSelectedTradingParticipant = (participantRiskLimits: ParticipantRiskLimit[]): string[] =>
  participantRiskLimits.filter((x) => x.productCode).map((x) => x.productCode);

export const canModifyCARRiskLimits = (data: TradingParticipantManagementRiskLimits[], participantId: string) => {
  if (participantId === "" || data.length === 0) {
    return false
  } else {
    for (const participant of data) {
      if (participant?.participantId === participantId) {
        for (const fcm of participant?.fcms) {
          if (fcm?.tradingParticipantRiskLimits?.canParticipantModifyCarRiskLimits) {
            return true;
          }
        }
      }
    }
  }
  return false;
}