export const grants = {
  READ: 'read',
  CREATE: 'create',
  DELETE: 'delete',
};

export const subjects = {
  TRADES: 'trades',
  BALANCES: 'balances',
  POSITIONS: 'futuresPositions',
  TRADE_PROFILE: 'tradeProfile',
  WITHDRAWALS: 'withdrawals',
  DEPOSITS: 'deposits',
  API_KEYS: 'apiKeys',
  LINKED_MEMBER_ASSET_ACCOUNTS: 'linkedMemberAssetAccounts',
  MOVEMENTS: 'movements',
  DAILY_STATEMENTS: 'dailyStatements',
};

export const TRADE_PERMISSIONS = [`${grants.READ}:${subjects.TRADE_PROFILE}`];

export const TAX_STATEMENT_PERMISSION = 'tax_documents';

export const MEMBER_USER_PERMISIONS = {
  request_withdrawals: {
    header: 'Funding',
    text: 'Request Withdrawals',
  },
  read_movements: {
    header: 'Transactions',
    text: 'View Movements',
  },
  close_positions: {
    header: 'Positions',
    text: 'Close Positions',
  },
  read_transactions: {
    header: 'Transactions',
    text: 'View Recent Transactions',
  },
  read_daily_statements: {
    header: 'Transactions',
    text: 'EMP Statements',
  },
  destroy_linked_asset_account: {
    header: 'SSI',
    text: 'Delete SSI',
  },
  read_linked_asset_account: {
    header: 'SSI',
    text: 'View SSI',
  },
  read_trading_application_data: {
    header: 'Trading',
    text: 'Allow Trading',
  },
  create_linked_asset_account: {
    header: 'SSI',
    text: 'Add SSI',
  },
  send_deposits: {
    header: 'Funding',
    text: 'Send Deposits',
  },
  read_collateral_positions: {
    header: 'Positions',
    text: 'View Collateral Positions',
  },
  read_futures_positions: {
    header: 'Positions',
    text: 'View Futures Positions',
  },
  read_balances: {
    header: 'Transactions',
    text: 'View Balances',
  },
  read_trades: {
    header: 'Transactions',
    text: 'View Trades',
  },
  read_participant_account: {
    header: 'Margin Clearing',
    text: 'Read Participant Account',
  },
  write_participant_account: {
    header: 'Margin Clearing',
    text: 'Write Participant Account',
  },
  request_fcm_clearing: {
    header: 'Margin Clearing',
    text: 'Request FCM Clearing',
  },
  manage_block_trade_permissions: {
    header: 'Transactions',
    text: 'Manage Block Trade Permissions',
  },
};

export const API_KEY_PERMISSIONS = {
  read_clearing_api: {
    header: 'General',
    text: 'Clearing (Read Only)',
  },
  view_market_data: {
    header: 'General',
    text: 'Market Data',
  },
  write_clearing_api: {
    header: 'General',
    text: 'Funding',
  },
  submit_order: {
    header: 'General',
    text: 'Trading',
  },
  submit_block_trade: {
    header: 'General',
    text: 'Submit Block Trade',
  },
  settlement_service: {
    header: 'General',
    text: 'Settlement Service',
  },
  write_pre_trade_risk: {
    header: 'Futures Risk Management',
    text: 'Write Pre-Trade Risk',
  },
  read_pre_trade_risk: {
    header: 'Futures Risk Management',
    text: 'Read Pre-Trade Risk',
  },
  update_trade: {
    header: 'Futures Adjustments',
    text: 'Update Trade',
  },
  update_position: {
    header: 'Futures Adjustments',
    text: 'Update Positions',
  },
  request_fcm_clearing: {
    header: 'Futures Exchange Member',
    text: 'Request FCM Clearing',
  },
  write_participant_account: {
    header: 'Futures Exchange Member',
    text: 'Write Participant Account',
  },
  read_participant_account: {
    header: 'Futures Exchange Member',
    text: 'Read Participant Account',
  },
  read_tax_documents: {
    header: 'Tax',
    text: 'Read Tax Documents',
  },
  write_tax_documents: {
    header: 'Tax',
    text: 'Write Tax Documents',
  },
  onboard_member_user: {
    header: 'Intermediaries',
    text: 'Onboarding',
  },
  manage_block_trade_permissions: {
    header: 'General',
    text: 'Manage Block Trade Permissions',
  },
};
