import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import {
  collateralContracts,
  getCollateralContracts,
} from '../../reducers/contractsReducer';
import { createCollateralDepositRequest } from '../../reducers/fundTransfersReducer';
import CollateralDeposit from '../../components/FundTransfers/CollateralDeposit';
import { getSelectorAsOptions } from '../../utils/selectors';
import { filterFundsDesignationByPermissions } from '../../utils/permissions';

const mapDispatchToProps = {
  collateralContracts,
  createCollateralDepositRequest,
};

const mapStateToProps = (state) => ({
  collateralContractsLoading: createLoadingSelector(['COLLATERAL_CONTRACTS'])(
    state,
  ),
  collateralContractsOptions: getSelectorAsOptions(getCollateralContracts, {
    key: 'symbol',
    value: 'symbol',
    text: (
      opt, // v-- allow search by product, maturity date, or cusip
    ) =>
      `${get(opt, 'symbol', '')} ${moment(get(opt, 'expiryDate', '')).format('l')} (${get(opt, 'cusip', '')})`,
  })(state),
  createCollateralDepositRequestLoading: createLoadingSelector(
    'CREATE_COLLATERAL_DEPOSIT_REQUEST',
  )(state),
});

class CollateralDepositContainer extends PureComponent {
  componentDidMount = () => {
    // cotracts filtered by expiryDate >= today's date
    this.props.collateralContracts({
      filter: [
        {
          attr: 'expiration_time',
          op: 'gte', // only get contracts expiring after 5 days from now
          value: moment().add(5, 'days').format('YYYY-MM-DD'),
        },
      ],
    });
  };

  render = () => (
    <CollateralDeposit
      accountCode={this.props.accountCode}
      collateralContractsLoading={this.props.collateralContractsLoading}
      collateralContractsOptions={this.props.collateralContractsOptions}
      createCollateralDepositRequest={(payload) =>
        this.props.createCollateralDepositRequest({
          ...payload,
          accountId: this.props.accountId,
        })
      }
      createCollateralDepositRequestLoading={
        this.props.createCollateralDepositRequestLoading
      }
      firmCode={this.props.firmCode}
      fundsDesignations={filterFundsDesignationByPermissions(this.props)}
    />
  );
}

CollateralDepositContainer.propTypes = {
  collateralContracts: PropTypes.func.isRequired,
  collateralContractsLoading: PropTypes.bool,
  collateralContractsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  createCollateralDepositRequest: PropTypes.func.isRequired,
  createCollateralDepositRequestLoading: PropTypes.bool,
};

CollateralDepositContainer.defaultProps = {
  collateralContractsLoading: false,
  collateralContractsOptions: [],
  createCollateralDepositRequestLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollateralDepositContainer);
