import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Agreement from '../../components/onBoarding/GenericTerms';
import { acceptAgreement } from '../../reducers/userReducer';
import EnvPromise from '../../config/env';
import history from '../../constants/history';

const mapDispatchToProps = {
  acceptAgreement,
};

class AgreementContainer extends PureComponent {
  state = {};

  componentWillMount = () => {
    EnvPromise.then(({ agreementPdfEndpoint }) =>
      this.setState({ agreementPdfEndpoint }),
    );
  };

  confirm = () => {
    this.props.acceptAgreement();
    this.props.nextStep();
  };

  render = () => (
    <section className="onboarding-container">
      <Agreement
        header="Individual Spot Direct Clearing Member Agreement"
        file={this.state.agreementPdfEndpoint}
        fileName="Individual Spot Direct Clearing Member Agreement"
        name="memberAgreements"
        accept={this.confirm}
      />
    </section>
  );
}

AgreementContainer.propTypes = {
  acceptAgreement: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(AgreementContainer);
