import React from 'react';
import { Header, Segment, Icon, Grid } from 'semantic-ui-react';
import { Typography } from '@material-ui/core';
import Footer from '../components/onBoarding/Footer';
import colors from '../constants/colors';
import IconsBG from '../common/components/IconsBG';
import styled from 'styled-components';

const styles = {
  message: {
    textAlign: 'left',
    fontSize: '16px',
  },
  button: {
    marginTop: '2rem',
  },
  highlight: {
    fontWeight: 'bold',
  },
  header: {
    marginBottom: '1rem',
  },
  container: {
    marginBottom: '5rem',
  },
};

const SplashDiv = styled.div`
  min-height: 0;
`;

const SplashSegment = styled(Segment)`
  min-height: 0;
  max-width: 590px;
`;

export const SpotDecomPage = () => {
  return (
    <>
      <IconsBG />
      <section
        className="content-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'auto',
          backgroundColor: colors.NAVY,
        }}
      >
        <div className="onboarding-container" style={styles.container}>
          <SplashDiv className="onboarding-content ui padded segment">
            <SplashSegment
              className="onboarding-information"
              textAlign="center"
            >
              <Header>
                <div className="logo-container">
                  <div className="onboarding-logo" alt="ErisX" />
                </div>
                <Header.Content>
                  <Typography variant="h4" style={styles.header}>
                    Customer Notice
                  </Typography>
                </Header.Content>
              </Header>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={2} verticalAlign="middle">
                    <Icon name="user" color="primary" size="large" />
                  </Grid.Column>
                  <Grid.Column width={14}>
                    <p style={styles.message}>
                      Cboe Digital no longer accepts Spot Members; see{' '}
                      <a
                        href="https://www.cboedigital.com/termination-of-spot-accounts/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Customer Notice.
                      </a>
                      <br />
                      If you are an FCM or Futures Member, please email{' '}
                      <a
                        href="mailto:digital.membership@cboe.com"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        digital.membership@cboe.com
                      </a>
                      .
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SplashSegment>
          </SplashDiv>
        </div>
        <Footer />
      </section>
    </>
  );
};
