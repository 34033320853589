import styled from 'styled-components';
import StyledButton from '../../../common/StyledButton';
import { UISegment } from '../../../styles/styled';

const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 0.8em;
  & .field {
    & > small {
      position: absolute;
    }
  }
  & .field > .field {
    margin-bottom: 0px;
  }
`;
const DateFieldWrapper = styled.div`
  & .rdt {
    height: 38px;
  }
  & .ui .input {
    height: 38px;
  }
`;

const SideFieldWrapper = styled.div`
  & .ui.selection.dropdown {
    min-width: unset;
    width: 100%;
  }
`;
const Register = styled(StyledButton)`
  width: 270px;
  margin: 1rem auto 1rem auto;
`;
const Segment = styled(UISegment)`
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2) !important;
`;

export { FieldGrid, DateFieldWrapper, SideFieldWrapper, Register, Segment };
