import React, { Component } from 'react';
import { Icon, Form, Popup } from 'semantic-ui-react';

const timeoutLength = 1500;

class InputWithCopy extends Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, timeoutLength);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  copyAddress = () => {
    /* Get the text field */
    const copyText = document.getElementById(this.props.id);
    /* Select the text field */
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.handleOpen();
  };

  render = () => (
    <Form.Input
      onClick={this.copyAddress}
      fluid
      readOnly
      icon={
        <Popup
          trigger={<Icon link name="copy outline" onClick={this.copyAddress} />}
          content="Copied!"
          on="click"
          open={this.state.isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          position="bottom right"
          pointing
          inverted
        />
      }
      {...this.props}
    />
  );
}

export default InputWithCopy;
