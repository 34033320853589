import React from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

// used to indicate loading in the select form
const loader = () => <CircularProgress color="secondary" size={14} />;

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

const ReduxFormSelect = ({
  input: { value, name, onChange },
  label,
  meta: { asyncValidating, touched, error },
  options,
  fullWidth,
  required,
  'data-cy': dataCy,
}) => {
  const optionElements = isMobile
    ? options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))
    : options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ));

  // show that it's a required field to users
  const inputLabel = required ? `${label} *` : label;

  return (
    <FormControl error={touched && Boolean(error)} fullWidth={fullWidth}>
      <InputLabel htmlFor="state">{inputLabel}</InputLabel>
      <Select
        native={isMobile}
        inputProps={{
          name,
          id: name,
          'data-cy': isMobile ? dataCy : undefined,
        }}
        IconComponent={
          asyncValidating ? loader : undefined
        }
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        required={required}
        data-cy={!isMobile ? dataCy : undefined}
      >
        {optionElements}
      </Select>
      {renderFormHelper({ touched, error })}
    </FormControl>
  );
};

ReduxFormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  dataCy: PropTypes.string,
};

ReduxFormSelect.defaultProps = {
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
  fullWidth: false,
  required: false,
  options: [],
  dataCy: '',
};

export default ReduxFormSelect;
