import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { AuthorizedBlockTraders } from '../../ts/models/BlockTrades/PermissionManagement.model';
import { handleActions } from 'redux-actions';
import { RootState } from '../../ts/types/store';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';

/** Actions */
export const GET_AUTHORIZED_BLOCK_TRADERS = 'GET_AUTHORIZED_BLOCK_TRADERS';
export const ADD_AUTHORIZED_BLOCK_TRADER = 'ADD_AUTHORIZED_BLOCK_TRADER';
export const REMOVE_AUTHORIZED_BLOCK_TRADER = 'REMOVE_AUTHORIZED_BLOCK_TRADER';
export const SUBMIT_TWO_SIDED_BLOCK_TRADE = 'SUBMIT_TWO_SIDED_BLOCK_TRADE';
export const SUBMIT_SINGLE_SIDED_BLOCK_TRADE = 'SUBMIT_SINGLE_SIDED_BLOCK_TRADE';
export const GET_BLOCK_TRADE_REQUESTS = 'GET_BLOCK_TRADE_REQUESTS';
export const GET_BLOCK_TRADE_REQUESTS_V2 = 'GET_BLOCK_TRADE_REQUESTS_V2';
export const APPROVE_BLOCK_TRADE = 'APPROVE_BLOCK_TRADE';
export const REJECT_BLOCK_TRADE = 'REJECT_BLOCK_TRADE';

/** Routines */
export const fetchAuthorizedBlockTraders = createRoutine(
  GET_AUTHORIZED_BLOCK_TRADERS,
);
export const addAuthorizedBlockTrader = createRoutine(
  ADD_AUTHORIZED_BLOCK_TRADER,
);
export const removeAuthorizedBlockTrader = createRoutine(
  REMOVE_AUTHORIZED_BLOCK_TRADER,
);
export const submitTwoSidedBlockTrade = createRoutine(
  SUBMIT_TWO_SIDED_BLOCK_TRADE,
);
export const submitSingleSidedBlockTrade = createRoutine(
  SUBMIT_SINGLE_SIDED_BLOCK_TRADE,
);

export const fetchBlockTradeRequests = createRoutine(
  GET_BLOCK_TRADE_REQUESTS,
);
export const fetchBlockTradeRequestsV2 = createRoutine(
  GET_BLOCK_TRADE_REQUESTS_V2,
);
export const approveBlockTrade = createRoutine(
  APPROVE_BLOCK_TRADE,
);
export const rejectBlockTrade = createRoutine(
  REJECT_BLOCK_TRADE,
);

/** Promisified Routines */
export const removeAuthorizedBlockTraderPromise = promisifyRoutine(
  removeAuthorizedBlockTrader,
);
export const addAuthorizedBlockTraderPromise = promisifyRoutine(
  addAuthorizedBlockTrader,
);
export const submitTwoSidedBlockTradePromise = promisifyRoutine(
  submitTwoSidedBlockTrade,
);
export const submitSingleSidedBlockTradePromise = promisifyRoutine(
  submitSingleSidedBlockTrade,
);

export const approveBlockTradePromise = promisifyRoutine(
  approveBlockTrade,
);

export const rejectBlockTradePromise = promisifyRoutine(
  rejectBlockTrade,
);

/** Initial State */
type BlockTradesState = {
  authorizedBlockTraders?: AuthorizedBlockTraders;
  blockTradeRequests: BlockTradeRequestModel[];
};

const initialState: BlockTradesState = {
  authorizedBlockTraders: [],
  blockTradeRequests: [],
};

/** Reducer */
export default handleActions<BlockTradesState, any>(
  {
    [fetchAuthorizedBlockTraders.SUCCESS]: (state, { payload }) => ({
      ...state,
      authorizedBlockTraders: payload.authorizedBlockTraders,
    }),
    [fetchBlockTradeRequests.SUCCESS]: (state, { payload }) => ({
      ...state,
      blockTradeRequests: payload.blockTradeRequests,
    }),
    [fetchBlockTradeRequestsV2.SUCCESS]: (state, { payload }) => ({
      ...state,
      blockTradeRequests: payload.blockTradeRequests,
    }),
  },
  initialState,
);

/** Selectors */
export const getBlockTradesState = (state: RootState): BlockTradesState =>
  get(state, 'blockTrades', {});

export const getAuthorizedBlockTraders = createSelector(
  [getBlockTradesState],
  (state) => get(state, 'authorizedBlockTraders', []),
);

export const getAuthorizedBlockTradersForGrid = createSelector(
  [getAuthorizedBlockTraders],
  (authorizedUsers) =>
    authorizedUsers.map((au) => ({ authorizedTraderEmail: au })),
);

export const getBlockTradeRequests = createSelector(
  [getBlockTradesState],
  (state) => get(state, 'blockTradeRequests', []),
);
