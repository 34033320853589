import React from 'react';
import styled from 'styled-components';
import { Column, SortDirection } from '@tanstack/react-table';
import StyledButton from '../../StyledButton';
import { Separator } from '../../../styles/styled';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${Separator} {
    margin: 10px 0px 10px 0px;
    background-color: ${({ theme }) => theme.table.filter.separator};
  }
`;

const FilterButton = styled(StyledButton)`
  padding: 5px 5px;
  & i {
    width: unset;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  ${FilterButton} {
    border-radius: 3px;
    flex-basis: 49%;
  }
`;

type Props<T> = {
  column: Column<T>;
};

/*
  Clear and set sorting for EMPTable8's columns.
  It's agnostic to the table type (a.k.a. local / server side filtering) 
  @prop - onAsc: Callback to handle ascending sorting
  @prop - onDesc: Callback to handle desc sorting
*/
const SortActions = <T,>({ column }: Props<T>) => {
  const tableSort: SortDirection | boolean = column.getIsSorted();
  const isAsc: boolean = tableSort === 'asc';
  const isDesc: boolean = tableSort === 'desc';

  const onClickAsc = () => {
    if (isAsc) {
      return column.clearSorting();
    }
    column.toggleSorting(false);
  };

  const onClickDesc = () => {
    if (isDesc) {
      return column.clearSorting();
    }
    column.toggleSorting(true);
  };

  return (
    <Actions>
      <Buttons>
        <FilterButton
          text="ASC"
          primary={isAsc}
          onClick={onClickAsc}
          icon="long arrow alternate up"
        />
        <FilterButton
          text="DESC"
          primary={isDesc}
          onClick={onClickDesc}
          icon="long arrow alternate down"
        />
      </Buttons>
      <Separator />
    </Actions>
  );
};

export default SortActions;
