import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { showModal } from 'erisxkit/client';
import TextButtons from '../../common/TextButtons';
import {
  getCurrentUserId,
  getLoggedInUserAuth,
  getPII,
} from '../../reducers/userReducer';
import { sizes } from '../../styles/styled';
import {
  CREATE_FUNDING_PASSWORD,
  RESET_2FA_MODAL,
} from '../../constants/modalTypes';
import colors from '../../constants/colors';

const Wrapper = styled.div`
  display: flex;
`;

const Name = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.text.header};
`;

const UserPictureWrapper = styled.div`
  margin-right: 20px;
  @media (max-width: ${sizes.SMALL}) {
    display: none;
  }
`;

const UserInfo = styled.div`
  align-self: center;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
`;

const ProfileActions = () => {
  const dispatch = useDispatch();
  const auth = useSelector(getLoggedInUserAuth);
  const pii = useSelector(getPII);
  const userId = useSelector(getCurrentUserId);

  const resetFunding = () => {
    dispatch(showModal(CREATE_FUNDING_PASSWORD, { action: 'reset' }));
  };
  const resetColumnWidths = () => {
    const preferences = JSON.parse(localStorage.getItem('XTable')) || {};
    delete preferences[userId];
    localStorage.setItem('XTable', JSON.stringify(preferences));
    cogoToast.info('Column widths reset.');
  };

  const buttons = [
    { text: 'Reset 2FA', onClick: () => dispatch(showModal(RESET_2FA_MODAL)) },
    { text: 'Reset Column Widths', onClick: resetColumnWidths },
    {
      text: 'Reset Funding Password',
      onClick: resetFunding,
      danger: true,
    },
  ];

  return (
    <Wrapper>
      <UserPictureWrapper>
        <ProfileImg
          className="user-picture"
          src={auth?.picture || ''}
          alt="user"
        />
      </UserPictureWrapper>
      <UserInfo>
        <Name>{`${pii.firstName} ${pii.lastName}`}</Name>
        <div>
          <TextButtons buttons={buttons} />
        </div>
      </UserInfo>
    </Wrapper>
  );
};

export default ProfileActions;
