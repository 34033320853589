import React from 'react';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import Unauthorized from './Unauthorized';

export const checkPermissions = (
  userPermissions = [],
  allowedPermissions = [],
  op = 'every',
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  const fn = _[op];
  if (typeof fn === 'function') {
    return _[op](allowedPermissions, (p) => {
      const [permission, subject] = p.split(':');
      return _.get(userPermissions, [subject, permission], false);
    });
  }
  return false;
};

const AccessControl = ({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  op,
  customPermissionSelector,
  customPermission,
}) => {
  const additionalCheck = useSelector((state) =>
    customPermissionSelector(state),
  );
  const customAdditionalCheck = customPermission();
  const permitted =
    checkPermissions(userPermissions, allowedPermissions, op) &&
    additionalCheck &&
    customAdditionalCheck;

  if (permitted) {
    return children;
  }

  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
  op: 'every',
  customPermissionSelector: () => true, // selector that uses the store to make an additional validation if required
  customPermission: () => true,
};

// Compose AccessControl component with redux

export default connect((state) => ({
  userPermissions: state.user && state.user.uiViews,
}))(AccessControl);
