import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import { filterInputStyle } from './EMPTable8/Filters/styled';

export const StyledInput = styled.input.attrs(() => {
  autocomplete: 'off'; // Prevent autocompletion from password and 1P popup
})<{ changed: boolean }>`
  ${filterInputStyle};
  height: 25px;
  width: 90%;
  color: ${({ changed }) => (changed ? colors.GREEN : colors.WHITE)};
`;

/*
  Input with debounced OnChange callback. 
  Usage is the same as with a standard input. 
  Use only when required to prevent the onChange from trigger on every change
  @prop - value: input value
  @prop - onChange:  onchange callback to debounce
  @prop - debounce: debounce interval in ms
  @prop - active: highlight input color
*/
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  active = false,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  active?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <StyledInput
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default DebouncedInput;
