import _ from 'lodash';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { tradingApplications } from 'erisxkit/client';
import isEmpty from 'lodash/isEmpty';
import {
  submitComplianceQuestions,
  submitSecurityQuestions,
  submitFuturesComplianceQuestions,
} from './complianceQuestionsReducer';
import { arrayToObject } from '../utils/methods';
import fees, { DM_KEY } from '../constants/fees';
import { newOrderSingle } from './orderEntryReducer';
import {
  someAccountEnabledForFutures,
  hasAnAccountOfType,
  getAllAccounts,
  getAllAccountsList,
  getHasAnIRAAccount,
} from './accountsReducer';
import { getActiveOrOnlyAccount } from './activeAccountSelectors';
import { getMemberUserTypesByType } from './memberUserTypesReducer';
import {
  DIRECT_MEMBER_TYPE,
  INVESTMENT_MANAGER_MEMBER_TYPE,
  FCM_MEMBER_TYPE,
  DIRECT_MEMBER,
} from '../constants/memberTypes';
import {
  subjects,
  TAX_STATEMENT_PERMISSION,
  TRADE_PERMISSIONS,
} from '../constants/userPermissions';
import { checkPermissions } from '../common/AccessControl';
import {
  DM_OFFBOARDING_MODAL,
  TAX_STATEMENTS,
  UPGRADE_TO_FUTURES,
} from '../constants/modalTypes';
import FILTER_CRITERIA from '../constants/filterCriteria';
import { hasUserOptedInTaxStatements } from '../components/TaxEStatements/utils';
import { ONBOARDED, ONBOARDED_STATES } from '../constants/userStates';
import MEMBER_USER_PERMISSION_NAMES from '../constants/memberUserPermissions';
import API_KEY_PERMISSION_NAMES from '../constants/apiKeyPermissionNames';
import { REPORTS_STATEMENTS_REQUIRED_PERMS } from '../components/TopNavFCM';
import { getIsMarginEnabled } from './envReducer';
import {
  getActiveBalanceDetails,
  getHasAnyBalanceGreaterThanZero,
  hasBalancesGreaterThanZero,
} from './balancesReducer';
import { OFFBOARDING_MODAL_SESSION_STORAGE_KEY } from '../common/components/OffboardingModal';

//* Auth actions */
export const STORE_USER = 'STORE_USER';
export const SUBMIT_PII = 'SUBMIT_PII';
export const UPDATE_PII = 'UPDATE_PII';

// profile actions
export const GET_APPLICATION_PACKET = 'GET_APPLICATION_PACKET';

/* Actions to get clearing user */
export const fetchUser = createRoutine('USER');
export const fetchUserPromiseCreator = promisifyRoutine(fetchUser);

export const fetchAuthUser = createRoutine('AUTH_USER');
export const fetchAuthUserPromiseCreator = promisifyRoutine(fetchAuthUser);

// fetches the application packet of an onboarded user
export const getApplicationPacket = createRoutine(GET_APPLICATION_PACKET);

export const createMfTicket = createRoutine('CREATE_MF_TICKET');
export const updateAuthUser = createRoutine('UPDATE_AUTH_USER');

export const UPDATE_PII_REQUEST = 'UPDATE_PII_REQUEST';
export const UPDATE_PII_SUCCESS = 'UPDATE_PII_SUCCESS';
export const UPDATE_PII_FAILED = 'UPDATE_PII_FAILED';

export const submitPii = createRoutine(SUBMIT_PII);
export const submitPiiPromiseCreator = promisifyRoutine(submitPii);

export const updatePii = createRoutine(UPDATE_PII);
export const updatePiiPromiseCreator = promisifyRoutine(updatePii);

export const UPDATE_PII_STORE = 'UPDATE_PII_STORE';

// onboarding actions
export const PII_REQUEST = 'PII_REQUEST';
export const PII_SUCCESS = 'PII_SUCCESS';
export const PII_FAILED = 'PII_FAILED';
export const IS_ELIGIBLE_LOCATION_REQUEST = 'IS_ELIGIBLE_LOCATION_REQUEST';
export const IS_ELIGIBLE_LOCATION_SUCCESS = 'IS_ELIGIBLE_LOCATION_SUCCESS';
export const IS_ELIGIBLE_LOCATION_FAILED = 'IS_ELIGIBLE_LOCATION_FAILED';

export const acceptTermsAndConditions = createRoutine(
  'ACCEPT_TERMS_AND_CONDITIONS',
);
export const acceptAgreement = createRoutine('ACCEPT_AGREEMENT');

// Routine/Promisified routine to handle updating the user's state
export const updateTutorialState = createRoutine('UPDATE_TUTORIAL_STATE');
export const updateTutorialStatePromise = promisifyRoutine(updateTutorialState);

export const UNEXPECTED_IDM_STATE_ERROR = 'UnexpectedIdmAppStateError';

export const HIDE_FUTURES_UPGRADE_MODAL = 'HIDE_FUTURES_UPGRADE_MODAL';
export const hideFuturesForUser = createRoutine(HIDE_FUTURES_UPGRADE_MODAL);

export const START_FUTURES_UPGRADE = 'START_FUTURES_UPGRADE';
export const ACCEPT_FUTURES_AGREEMENTS = 'ACCEPT_FUTURES_AGREEMENTS';
export const startFuturesUpgrade = createRoutine(START_FUTURES_UPGRADE);
export const acceptFuturesUpgrade = createRoutine(ACCEPT_FUTURES_AGREEMENTS);

//* Initial State */
const initialState = {};

function piiReducer(state = { country: 'US' }, action) {
  const { payload = {} } = action;
  delete payload.ssn;
  return {
    ...state,
    ...payload,
  };
}

//* Reducer */
export default function userReducer(state = initialState, action) {
  const { type, payload } = action;
  const matches = /(.*)[_/](FAILED|FAILURE)/.exec(type);
  if (matches) {
    if (_.get(payload, ['data', 'type'], '') === UNEXPECTED_IDM_STATE_ERROR) {
      const message = _.get(payload, ['data', 'error']);
      const [, appState] = /Idm App is in an unexpected state:(.*)/.exec(
        message,
      );
      // the format of the error is in "Idm App is in an unexpected state: id_images"
      // we have to extract the state out, and update the user
      if (appState) {
        // update user
        return {
          ...state,
          appState: appState.trim(),
        };
      }
    }
  }

  switch (action.type) {
    case STORE_USER:
    case fetchUser.SUCCESS:
    case submitPii.SUCCESS:
    case submitComplianceQuestions.SUCCESS:
    case submitSecurityQuestions.SUCCESS:
    case updateTutorialState.SUCCESS:
    case acceptAgreement.SUCCESS:
    case startFuturesUpgrade.SUCCESS:
    case acceptFuturesUpgrade.SUCCESS:
    case submitFuturesComplianceQuestions.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PII_SUCCESS:
    case PII_SUCCESS:
    case UPDATE_PII_STORE:
      return {
        ...state,
        pii: piiReducer(state.pii, action),
      };
    case IS_ELIGIBLE_LOCATION_SUCCESS:
      return {
        ...state,
        isEligibleLocation: action.isEligible,
      };
    case fetchAuthUser.SUCCESS:
      return {
        ...state,
        auth: _.get(action.payload, '0'),
      };
    case getApplicationPacket.REQUEST:
      return {
        ...state,
        ongoingApplicationPacketRequest: true,
      };
    case getApplicationPacket.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case getApplicationPacket.FULFILL:
      return {
        ...state,
        ongoingApplicationPacketRequest: false,
      };
    case newOrderSingle.SUCCESS:
      return {
        ...state,
        firstTraded: true,
      };
    case hideFuturesForUser.SUCCESS:
      return {
        ...state,
        hideFuturesUpgradeModal: true,
      };
    default:
      return state;
  }
}

/* Selectors */
// returns the logged in user
const getUser = (state) => _.get(state, 'user', {});

export const getPII = (state) => _.get(state, ['user', 'pii'], {});
export const getLoggedInUser = createSelector([getUser], (user) => user);
export const getLoggedInUserAccountLinks = createSelector(
  [getUser],
  (user) => user.accountLinks,
);
export const getLoggedInUserState = createSelector(
  [getUser],
  (user) => user.state,
);
export const getUserHasFuturesRejected = createSelector(
  [getUser],
  (user) => user.futuresRejected || false,
);
export const getLoggedInUserMemberLinks = createSelector(
  [getUser],
  (user) => user.memberLinks || [],
);
export const getLoggedInUserTutorialState = createSelector(
  [getUser],
  (user) => user.tutorialState || false,
);
export const getLoggedInUserType = createSelector(
  [getUser],
  (user) => user.type,
);

export const getLoggedInUserPermissions = createSelector(
  [getUser],
  //Should not default to empty array - permissions set to nil represent default permission
  (user) => user?.permissions,
);
export const getLoggedInUserApiKeyPermissions = createSelector(
  [getUser],
  (user) => user.apiKeyPermissions || [],
);

export const getLoggedInUserSpotAcceptedTime = createSelector(
  [getUser],
  (user) => user.agreementAcceptedTime || '',
);

export const getLoggedInUserFuturesAcceptedTime = createSelector(
  [getUser],
  (user) => user.futuresAgreementAcceptedTime || '',
);

export const getLoggedInUserHasApiKeyPermission = (state, permission) =>
  getLoggedInUserApiKeyPermissions(state).includes(permission);

export const getLoggedInUseCanReadPreTradeRisk = (state) =>
  getLoggedInUserApiKeyPermissions(state).includes(
    API_KEY_PERMISSION_NAMES.READ_PRE_TRADE_RISK,
  );

export const getLoggedInUseCanWritePreTradeRisk = (state) =>
  getLoggedInUserApiKeyPermissions(state).includes(
    API_KEY_PERMISSION_NAMES.WRITE_PRE_TRADE_RISK,
  );

export const getLoggedInUserUiViews = createSelector(
  [getUser],
  (user) => user.uiViews || [],
);
export const getLoggedInUserMemberUserType = createSelector(
  [getUser],
  (user) => user.memberUserType || '',
);
export const getLoggedInUserAuth = createSelector(
  [getUser],
  (user) => user.auth || {},
);

export const getUserHasOnboardedForFutures = (state) => {
  const hasAcceptedCompliance = !!_.get(
    state,
    ['user', 'futuresComplianceAcceptedTime'],
    false,
  );
  const hasAcceptedAgreements = !!_.get(
    state,
    ['user', 'futuresAgreementAcceptedTime'],
    false,
  );
  return hasAcceptedCompliance && hasAcceptedAgreements;
};

export const getUserIsOnboarded = createSelector(
  [getLoggedInUserState],
  (state) => ONBOARDED_STATES.includes(state),
);

export const getUserCanCreateFuturesAccount = (state) => {
  // If accounts have not loaded, the verification for IRA users would that user is not IRA
  // So we short circuit if there are no accounts
  const accounts = getAllAccounts(state);
  if (isEmpty(accounts)) return false;
  // Don't show if the user is going through the tutorial
  const tutorialState = getLoggedInUserTutorialState(state);
  if (tutorialState === 'pending_tutorial') return false;
  const futuresRejected = getUserHasFuturesRejected(state);
  const alreadyHasFuturesAccount = someAccountEnabledForFutures(state);
  const hasAlreadyOnboardedFutures = getUserHasOnboardedForFutures(state);
  const hasIRAAccount = hasAnAccountOfType(state, ['IRA']);
  const memberLinks = getLoggedInUserMemberLinks(state);
  const hasSingleDMMember =
    memberLinks.length === 1 &&
    memberLinks[0].memberType === DIRECT_MEMBER_TYPE;
  return (
    !futuresRejected &&
    !alreadyHasFuturesAccount &&
    !hasAlreadyOnboardedFutures &&
    !hasIRAAccount &&
    hasSingleDMMember
  );
};

export const getShowUpgradeUserForFuturesModal = (state) => {
  const modalsToNotCover = [UPGRADE_TO_FUTURES];
  const modalIsShown = _.get(state, ['ui', 'modal', 'modalTypes'], []).some(
    (el) => modalsToNotCover.includes(el),
  );

  const hideFuturesUpgradeModal = _.get(
    state,
    ['user', 'hideFuturesUpgradeModal'],
    false,
  );
  const canCreateFuturesAccount = getUserCanCreateFuturesAccount(state);
  return (
    window.location.pathname === '/home' &&
    !modalIsShown &&
    !hideFuturesUpgradeModal &&
    canCreateFuturesAccount
  );
};

// returns the current user's userId
export const getCurrentUserId = (state) =>
  _.get(getLoggedInUser(state), 'userId', '');
export const getCurrentAuthId = (state) =>
  _.get(getLoggedInUser(state), 'authId', '');

export const getIsRsbixUser = createSelector(
  [getLoggedInUser],
  (user) =>
    user.defaultTradingApp ===
    _.get(
      arrayToObject(tradingApplications, 'value'),
      ['rsbix', 'value'],
      false,
    ),
);

export const getSelectedPartyId = createSelector(
  [getActiveOrOnlyAccount, getLoggedInUserAccountLinks],
  (account, accountLinks) => {
    const { accountId } = account;

    return _.find(accountLinks, { accountId })?.emarketLogin;
  },
);

export const getLocationEligiblity = (state) =>
  _.get(state, ['user', 'isEligibleLocation'], null);

// returns the fee profile to use
export const getUserFeesDesignation = (state) => {
  // currently based on the first account
  // or return an empty object by default
  const accounts = _.get(state, ['accountList', 'accounts'], {});
  const mainAccount = accounts[Object.keys(accounts)[0]];

  // return the corresponding fee
  const feesDesignation = mainAccount?.feesDesignation;

  // get fees from hardcoded map
  // returns an empty object if fees are not known
  return fees[feesDesignation] || {};
};

// get user's default trading application; default to cboedigital_trading_app
export const getDefaultTradingApp = createSelector([getLoggedInUser], (user) =>
  _.get(
    user,
    'defaultTradingApp',
    arrayToObject(tradingApplications, 'value').erisx.value,
  ),
);
/**
 * Action generator to store user information obtained from an access token.
 * @param {Object} user - The information obtained from decoding the token.
 */
export const storeUser = (user) => ({
  type: STORE_USER,
  payload: user,
});

/**
 * Action generator to check if a US state is supported.
 * Used in onboarding when a new user enters their home state.
 * @param {String} state - abbreviated state
 */
export const isEligibleLocation = (state) => ({
  type: IS_ELIGIBLE_LOCATION_REQUEST,
  state,
});

/**
 * Action generator to store user information obtained from onboarding.
 * @param {Object} pii - The information obtained from the user.
 */
export const updatePIIStore = (pii) => ({
  type: UPDATE_PII_STORE,
  payload: pii,
});

/**
 * Action generator to store user information obtained from onboarding.
 * @param {Object} pii - The information obtained from the user.
 */
export const fetchPII = () => ({
  type: PII_REQUEST,
});

/**
 * Action generators to store user acceptance of disclosures and agreement
 */
export const ACCEPT_DISCLOSURES_AND_DISCLAIMERS =
  'ACCEPT_DISCLOSURES_AND_DISCLAIMERS';
export const acceptDisclosuresAndDisclaimers = createRoutine(
  ACCEPT_DISCLOSURES_AND_DISCLAIMERS,
);
export const acceptDisclosuresAndDisclaimersPromiseCreator = promisifyRoutine(
  acceptDisclosuresAndDisclaimers,
);

export const getUserHasSingleDM = (state) => {
  const links = getLoggedInUserMemberLinks(state);
  return (
    links.length === 1 &&
    _.get(links, ['0', 'memberType'], null) === DIRECT_MEMBER_TYPE
  );
};

export const getUserCanMakeInternalTransfers = (state) => {
  const hasSingleDM = getUserHasSingleDM(state);
  const hasIRAAccount = hasAnAccountOfType(state, ['IRA']);
  const hasSingleAccount = getLoggedInUserAccountLinks(state)?.length === 1;
  const isInvestmentAdvisor =
    getLoggedInUserType(state) === INVESTMENT_MANAGER_MEMBER_TYPE;
  return (
    hasSingleDM && !hasIRAAccount && !hasSingleAccount && !isInvestmentAdvisor
  );
};

export const getIsMemberDirect = (memberId, state) => {
  const links = getLoggedInUserMemberLinks(state);
  const member = _.head(links.filter((m) => m.memberId === memberId));
  return _.get(member, 'memberType', '') === DIRECT_MEMBER_TYPE;
};

export const getLoggedInUserHasUserTradePermissions = (state) => {
  const currentUserUiViews = getLoggedInUserUiViews(state);
  return checkPermissions(currentUserUiViews, TRADE_PERMISSIONS);
};

export const getLoggedInUserHasApiKeyTradePermissions = (state) =>
  getLoggedInUserApiKeyPermissions(state).includes('submit_order');

export const getIsLoggedInUserInvestmentAdvisor = (state) =>
  getLoggedInUserType(state) === INVESTMENT_MANAGER_MEMBER_TYPE;

export const getUserHasMemberOfType = (
  state,
  types = [],
  opts = { criterion: FILTER_CRITERIA.OR },
) => {
  const memberLinks = getLoggedInUserMemberLinks(state);
  if (opts.criterion === FILTER_CRITERIA.AND) {
    return types.every((type) =>
      memberLinks.some((member) => member.memberType === type),
    );
  }
  return memberLinks.some((member) => types.includes(member.memberType));
};

export const getUserHasFcmMember = (state) =>
  getUserHasMemberOfType(state, [FCM_MEMBER_TYPE]);

export const getIsLoggedInUserFCM = (state) =>
  getUserHasMemberOfType(state, [FCM_MEMBER_TYPE]) && getIsMarginEnabled(state);

export const getUserMembersCanAuthorizeIPs = (state) =>
  getLoggedInUserMemberLinks(state).every(
    (member) => member.ipAuthorizationEnabled,
  );

export const getUserCanAuthorizeIPs = (state) => {
  // Must have only one member assigned
  // member user type should be firm admin
  const hasOneMemberAssigned = getLoggedInUserMemberLinks(state).length === 1;
  const memberUserTypes = getMemberUserTypesByType(state);
  const isFirmAdmin =
    getLoggedInUserMemberUserType(state) === memberUserTypes.FIRM_ADMIN;
  return hasOneMemberAssigned && isFirmAdmin;
};

export const getUserMemberIds = (state) =>
  getLoggedInUserMemberLinks(state).map((member) => member.memberId);

export const getUserHasEStatements = createSelector(
  [getLoggedInUserPermissions],
  (permissions) => {
    // If null then user has default permission which includes tax docs
    if (_.isNull(permissions)) return true;
    if (!permissions) return false;
    return permissions?.includes(TAX_STATEMENT_PERMISSION);
  },
);

export const getShowUserTaxStatementsModal = createSelector(
  [
    getUserHasEStatements,
    (state) => _.get(state, ['ui', 'modal', 'modalTypes'], []),
    getUserIsOnboarded,
  ],
  (hasPermission, modalsShown, isOnboarded) => {
    const isAlreadyShown = modalsShown.some(
      (modal) => modal === TAX_STATEMENTS,
    );
    const modalsToNotCover = [DM_OFFBOARDING_MODAL];
    const hide = modalsShown.some((el) => modalsToNotCover.includes(el));

    return (
      !hide &&
      isOnboarded &&
      hasPermission &&
      !hasUserOptedInTaxStatements() &&
      !isAlreadyShown
    );
  },
);

export const getUserHasRequestFcmClearing = createSelector(
  [getLoggedInUserPermissions],
  (perms) => {
    if (_.isNull(perms)) return true;
    if (!perms) return false;
    return perms?.includes('request_fcm_clearing');
  },
);
export const getFcmUserCanSeeFundingScreen = (state) =>
  getUserCanSendDeposits(state) || getUserCanRequestWithdrawals(state);

/** Member User Permissions Selectors */
export const createMemberUserPermissionSelector = (permission) =>
  createSelector([getLoggedInUserPermissions], (permissions) => {
    if (_.isNull(permissions)) return true;
    if (!permissions) return false;
    return permissions?.includes(permission);
  });
export const getUserHasViewFuturesPositions =
  createMemberUserPermissionSelector(
    MEMBER_USER_PERMISSION_NAMES.READ_FUTURES_POSITIONS,
  );
export const getUserHasViewBalances = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.READ_BALANCES,
);
export const getUserCanSendDeposits = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.SEND_DEPOSITS,
);
export const getUserCanRequestWithdrawals = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.REQUEST_WITHDRAWALS,
);
export const getUserCanViewTrades = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.READ_TRADES,
);
export const getUserCanClosePositions = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.CLOSE_POSITIONS,
);

export const getUserCanReadExchangeMember = createMemberUserPermissionSelector(
  MEMBER_USER_PERMISSION_NAMES.READ_PARTICIPANT_ACCOUNT,
);
export const getUserCanManageBlockTradePermissions =
  createMemberUserPermissionSelector(
    MEMBER_USER_PERMISSION_NAMES.MANAGE_BLOCK_TRADE_PERMISSIONS,
  );

export const getUserCanViewReportStatements = createSelector(
  [getLoggedInUserPermissions],
  (perms) => {
    if (_.isNull(perms)) return true;
    if (!perms) return false;
    return perms.some((userPerm) =>
      REPORTS_STATEMENTS_REQUIRED_PERMS.includes(userPerm),
    );
  },
);
/** Member User API Key Selectors */
export const getUserHasAtLeastOneAPIKeyPermission = createSelector(
  [getLoggedInUserApiKeyPermissions],
  (apiKeyPerms) => !isEmpty(apiKeyPerms),
);

export const getShowUserOffboardingModal = createSelector(
  [getAllAccountsList, getLoggedInUserState, getHasAnIRAAccount],
  (accounts, state, hasIRAAccount) => {
    const shownThisSession =
      sessionStorage.getItem(OFFBOARDING_MODAL_SESSION_STORAGE_KEY) === 'true';
    const hasOnboarded = state === ONBOARDED;
    const hasDMAccount = accounts.some((acc) => acc.firmCode === DM_KEY);
    const hasDirectMemberType = accounts.some(
      (acc) => acc.clearingMemberType === DIRECT_MEMBER.code,
    );
    const hasAccountWithoutSubAccount = accounts.some(
      (acc) => !acc.subAccountCode,
    );

    return (
      !shownThisSession &&
      !hasIRAAccount &&
      hasOnboarded &&
      hasDMAccount &&
      hasDirectMemberType &&
      hasAccountWithoutSubAccount
    );
  },
);
