import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal, createLoadingSelector } from 'erisxkit/client';
import styled from 'styled-components';
import StyledButton from '../../common/StyledButton';
import columns from '../../metadata/authorizedIPListMetadata';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { SubHeader } from '../../styles/styled';
import {
  ADD_IP_ADDRESS_MODAL,
  REMOVE_IP_ADDRESS_MODAL,
} from '../../constants/modalTypes';
import {
  fetchAuthorizedIPs,
  getAuthorizedIPs,
  AUTHORIZED_IPS,
} from '../../reducers/authorizedIPsReducer';
import { getUserMemberIds } from '../../reducers/userReducer';

type Props = {
  className: string;
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`;

const Button = styled(StyledButton)`
  width: 250px;
  height: 40px;
  margin-left: auto;
`;

const StyledSubHeader = styled(SubHeader)`
  color: ${({ theme }) => theme.text.header + '!important'};
`;

const AuthorizedIPsList = ({ className = '' }: Props) => {
  const dispatch = useDispatch();
  const ips = useSelector(getAuthorizedIPs);
  const memberIds = useSelector(getUserMemberIds);
  const loading = useSelector((state) =>
    createLoadingSelector([AUTHORIZED_IPS])(state),
  );

  useEffect(() => {
    dispatch(fetchAuthorizedIPs({ memberIds }));
  }, []);

  const onAddIPAddress = () => {
    dispatch(
      showModal(ADD_IP_ADDRESS_MODAL, {
        size: 'small',
      }),
    );
  };

  const onRemove = (ipAddress: string) =>
    dispatch(showModal(REMOVE_IP_ADDRESS_MODAL, { size: 'small', ipAddress }));

  return (
    <div className={className}>
      <HeaderWrapper>
        <StyledSubHeader>AUTHORIZED IPS</StyledSubHeader>
        <Button text="Add IP Address" onClick={onAddIPAddress} primary />
      </HeaderWrapper>

      <EMPTable8
        title="authorizedIPList"
        data={ips}
        columns={columns(onRemove)}
        fetchData={() => {}}
        count={ips?.length || 0}
        loading={loading}
        loadingText="Fetching Balance Details"
        noDataText={loading ? ' ' : 'No Authorized IPs found'}
        minimumRows={3}
      />
    </div>
  );
};

export default AuthorizedIPsList;
