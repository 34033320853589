import React, { Fragment } from 'react';
import { Button, Header, Popup } from 'semantic-ui-react';
import cogoToast from 'cogo-toast';
import { get } from 'lodash';
import history from '../../constants/history';

const Settings = ({ user }) => (
  <Fragment>
    <Header>UI SETTINGS</Header>
    <Popup
      trigger={
        <Button
          className="reset-columns"
          primary
          onClick={() => {
            const preferences =
              JSON.parse(localStorage.getItem('XTable')) || {};
            delete preferences[get(user, 'userId', null)];
            localStorage.setItem('XTable', JSON.stringify(preferences));
            cogoToast.info('Column widths reset.');
          }}
        >
          Reset Column Widths
        </Button>
      }
      header="Reset all column widths?"
      content="Click this button to reset all table columns to their default widths."
      on="hover"
    />
  </Fragment>
);
export default Settings;
