import React, { PureComponent } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import history from '../../constants/history';
import { renderCheckbox } from '../../common/forms/components';
import { trackEvent } from '../../common/tracking';
import { required } from '../../common/forms/rules';
import StyledButton from '../../common/StyledButton';

const createLinks = (file, fileName) => (
  <span>
    <a
      href={file}
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => window.open(file, '_blank')}
    >
      {fileName}.
    </a>
  </span>
);

const Button = styled(StyledButton)`
  margin: 0px 10px;
`;
Button.displayName = 'Button';

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

class GenericTerms extends PureComponent {
  onAccept = () => {
    const { accept, name } = this.props;
    trackEvent('Agreements', `${name}Accept`);
    accept();
  };

  onDecline = () => {
    const { name } = this.props;
    trackEvent('Agreements', `${name}Decline`);
    history.push('/welcome');
  };

  onDownload = () => {
    const { name } = this.props;
    trackEvent('Agreements', `${name}Download`);
  };

  renderFiles = () => {
    const className = this.props.className
      ? `onboarding-content terms ${this.props.className}`
      : `onboarding-content terms`;

    return (
      <div className={className}>
        <Header textAlign="center">
          {this.props.header}
          <Header.Subheader className="italic">
            {this.props.subheader ||
              `You must click accept in order to proceed`}
          </Header.Subheader>
        </Header>
        <Segment>
          {this.props.files.map(({ file, fileName, name }) => (
            <div className="bold" style={{ marginBottom: '10px' }}>
              <Field
                component={renderCheckbox}
                validate={required}
                name={name}
                label={[
                  'I acknowledge and agree to the ',
                  createLinks(file, fileName),
                ]}
                data-cy={
                  this.props.dataCy && `${this.props.dataCy}-terms-checkbox`
                }
              />
            </div>
          ))}
        </Segment>
        {this.props.disclaimer}
        <Buttons>
          {this.props.onCancel && (
            <Button
              secondary
              text="Not at this time"
              onClick={() => this.props.onCancel()}
            />
          )}
          <Button
            primary
            text={this.props.acceptText}
            onClick={this.onAccept}
            disabled={this.props.invalid}
            data-cy={`${this.props.dataCy}-terms-submit-button`}
          />
        </Buttons>
      </div>
    );
  };

  render = () => <React.Fragment>{this.renderFiles()}</React.Fragment>;
}

GenericTerms.propTypes = {
  accept: PropTypes.func.isRequired,
  header: PropTypes.string,
  file: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  acceptText: PropTypes.string,
  disclaimer: PropTypes.node,
  dataCy: PropTypes.string,
};

GenericTerms.defaultProps = {
  header: '',
  file: '',
  name: '',
  acceptText: 'Accept',
  onCancel: null,
  disclaimer: null,
  dataCy: '',
};

export default reduxForm({
  form: 'terms',
})(GenericTerms);
