import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import history from '../constants/history';
import colors from '../constants/colors';
import { sizes } from '../styles/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  @media (max-width: ${sizes.TINY}) {
    padding: 10px;
  }
`;
Wrapper.displayName = 'Wrapper';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-self: center;
`;
Content.displayName = 'Content';

const StyledHeader = styled(Header)`
  margin: 0px 0px 15px 0px !important;
`;
const SubHeader = styled.p`
  margin-bottom: 0px;
  font-size: 21px;
  color: ${({ theme }) => theme.ui.subheader};
`;

const Page = ({ className, header, subHeader, children, backButton }) => (
  <Wrapper className={className}>
    <StyledHeader size="huge" className="primary left-padded">
      {backButton && (
        <Header.Subheader
          className="back-link"
          onClick={() => history.goBack()}
        >
          <Icon name="left arrow" />
          Back
        </Header.Subheader>
      )}
      {header}
      <SubHeader>{subHeader}</SubHeader>
    </StyledHeader>
    <Content>{children}</Content>
  </Wrapper>
);

Page.defaultProps = {
  subHeader: '',
  className: '',
  backButton: false,
};

Page.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // styled-components classname
  backButton: PropTypes.bool,
};

export default Page;
