import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import history from '../constants/history';

// returns the store instance
// it can also take initialState argument when creating middlewares
const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(
      responsiveStoreEnhancer,
      applyMiddleware(routerMiddleware(history), sagaMiddleware),
    ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
