import React from 'react';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isNaN from 'lodash/isNaN';
import { ColumnCreationOptions, ColumnEditOptions } from '../common/EMPTable8/types';
import ColoredCell from '../common/EMPTable8/common/ColoredCell';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { formatAccountingValue, formatDateTimeLocalIso8601 } from '../utils/formatters';
import {
  longShortColorMap,
  satisfiedColorMap,
} from '../components/Deliveries/colorMaps';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { formatFiat } from '../utils/methods';
import { TradingParticipantManagementReturn } from '../ts/models/TradingParticipant';
import TagList from '../common/EMPTable8/common/TagList';
import TableFieldTypes from '../ts/enums/TableFieldTypes.enum';
import TableFieldPattern from '../ts/enums/TableFieldPattern.enum';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import { DEFAULT_EXPIRY_LIMIT_INDEX, EXPIRING_POSITION_LIMIT, getAvailableExpiryLimits, getAvailableProducts, getExpiryLimitLabel, expiryLimits } from '../components/PreTradeRisk/menuOptions';
import { currentProductExpiries, currentProductSelected, currentProductSelectedTradingParticipant } from '../components/PreTradeRisk/utils';
import { StyledSelectOption } from '../common/StyledSelect';
import { Product } from '../ts/models/CAR.model';
import InformationTooltip from '../common/InformationTooltip';

const columnHelper = createColumnHelper<TradingParticipantManagementReturn>();

export const tradingParticipantManagementMetadata = (canWrite: boolean, carProducts: StyledSelectOption[],) =>
  [
    columnHelper.accessor('participantName', {
      id: 'participantName',
      header: 'Trading Participant',
      minSize: 170,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('canParticipantModifyCarRiskLimits', {
      id: 'canParticipantModifyCarRiskLimits',
      header: <span>Allow TPH to set CAR limits 
        <InformationTooltip
          content={
            <React.Fragment>
              If enabled, only the TPH will be able to set and modify pre-trade risk limits on the associated CARs. The Clearing Member will still be the only one able to set and modify pre-trade risk limits at the TPH level.
            </React.Fragment>
          }
        /></span>,
      minSize: 190,
      meta: {
        type: TableFieldTypes.Checkbox,
        isChecked: (row: Row<TradingParticipantManagementReturn>) =>
          get(row, 'original.canParticipantModifyCarRiskLimits', false),
        align: TableCellAlignment.Center,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Checkbox,
          depthUpperBound: 0,
        },
      },
    }),
    columnHelper.accessor('cars', {
      id: 'cars',
      header: 'CARs',
      minSize: 100,
      cell: ({ getValue }) => {
        return <TagList values={getValue()} allowLink={true} linkToURL={'/car-management?car='}/>;
      },
    }),
    columnHelper.accessor('marginExposureLimit', {
      id: 'marginExposureLimit',
      header: 'Margin Exposure Limit',
      minSize: 145,
      size: 145,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.DECIMAL_ONLY,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Input,
          depthUpperBound: 0,
        },
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.display({
      id: 'expandProducts',
      minSize: 30,
      maxSize: 30,
      meta: {
        expandOptions: {
          canExpand: true,
          depth: 0,
        },
      },
    }),
    columnHelper.accessor('productCode', {
      id: 'productCode',
      header: 'Product',
      minSize: 60,
      meta: {
        createCTA: 'Add New Product',
        addCTA: 'Add Expiry',
        deleteCTA: 'Delete',
        type: TableFieldTypes.Text,
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Select,
          options: (row: Row<TradingParticipantManagementReturn>, parentRows: Row<TradingParticipantManagementReturn>[]) => {
            if (parentRows?.length === 1) {
              const originalParent = get(parentRows, '[0].original', {});
              const selectedProducts = currentProductSelectedTradingParticipant(originalParent?.participantRiskLimits);
              return getAvailableProducts(carProducts, selectedProducts);
            }
            return carProducts;
          },
          placeholder: 'Select Product',
          depthUpperBound: 1,
        } as ColumnCreationOptions<TradingParticipantManagementReturn>,
        expandOptions: {
          canExpand: true,
          depth: 1,
        },
      },
    }),
    columnHelper.accessor('expirationMonth', {
      id: 'expirationMonth',
      header: 'Expiry Limit',
      minSize: 80,
      meta: {
        createCTA: 'Add Expiry',
        type: TableFieldTypes.Number,
        showValue: (expirationMonth: number) => {
          if (!expirationMonth && expirationMonth !== 0) return null;
          const expiryLimit = String(expirationMonth);
          if (expiryLimit === EXPIRING_POSITION_LIMIT) return 'Expiring';
          if (expiryLimit === DEFAULT_EXPIRY_LIMIT_INDEX) return 'Default';
          return getExpiryLimitLabel(expiryLimit);
        },
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Select,
          placeholder: 'Select Expiry',
          options: (row: Row<TradingParticipantManagementReturn>, parentRows: Row<TradingParticipantManagementReturn>[]) => {
            if (parentRows?.length === 2) {
              // Is an expiry-only row
              const product: Product = get(parentRows, '[1].original', {});
              const currentExpiries = currentProductExpiries(product);
              return getAvailableExpiryLimits(currentExpiries);
            }
            return expiryLimits;
          },
          depthLowerBound: 2,
        } as ColumnCreationOptions<TradingParticipantManagementReturn>,
        expandOptions: {
          canExpand: true,
          depth: 2,
        },
      },
    }),
    columnHelper.accessor('maxShortExposure', {
      id: 'maxShortExposure',
      header: 'Max Short',
      minSize: 70,
      size: 80,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          component: TableInputTypes.Input,
          canEdit: canWrite,
          depthLowerBound: 1,
        } as ColumnEditOptions<TradingParticipantManagementReturn>,
        createOptions: {
          canCreate: true,
          component: TableInputTypes.Input,
          depthLowerBound: 1,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor('maxLongExposure', {
      id: 'maxLongExposure',
      header: 'Max Long',
      minSize: 70,
      size: 80,
      meta: {
        type: TableFieldTypes.Text,
        pattern: TableFieldPattern.INTENGERS_ONLY,
        editOptions: {
          component: TableInputTypes.Input,
          canEdit: canWrite,
          depthLowerBound: 1,
        } as ColumnEditOptions<TradingParticipantManagementReturn>,
        createOptions: {
          canCreate: true,
          depthLowerBound: 1,
          component: TableInputTypes.Input,
        },
        showValue: (val) => formatAccountingValue(val, true),
        align: TableCellAlignment.Right,
      },
    }),
    columnHelper.accessor((row) => formatDateTimeLocalIso8601(row?.updatedAt), {
      id: 'updatedAt',
      header: 'Updated At',
      minSize: 175,
    }),
    columnHelper.accessor('lastUpdatedBy', {
      id: 'lastUpdatedBy',
      header: 'Last Updated By',
      minSize: 150,
    }),
  ] as ColumnDef<TradingParticipantManagementReturn>[];

export default tradingParticipantManagementMetadata;
