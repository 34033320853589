import React, { useState } from 'react';
import styled from 'styled-components';
import { Header, Icon } from 'semantic-ui-react';
import colors from '../../constants/colors';
import history from '../../constants/history';
import { UISegment, Separator } from '../../styles/styled';
import SendEmail from './SendEmail';
import ReferralCode from './ReferralCode';

const SubHeader = styled.p`
  margin-bottom: 0px;
  font-size: 15px;
  color: ${colors.GRAY_2};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

const Segment = styled(UISegment)`
  min-width: 70%;
  max-width: 95%;
`;

const InviteFriends = () => (
  <>
    <Header size="huge" className="primary left-padded">
      <Header.Subheader className="back-link" onClick={() => history.goBack()}>
        <Icon name="left arrow" />
        Back
      </Header.Subheader>
      Invite your Friends
      <SubHeader>
        Invite your friends and you and your friends may earn a referral bonus
        upon meeting eligibility{' '}
        <a target="_blank" href="https://erisx.com/5050referral">
          requirements
        </a>
        . See{' '}
        <a
          target="_blank"
          href="https://erisx.com/5050referral/#termsconditions"
        >
          Terms and Conditions.
        </a>
      </SubHeader>
    </Header>
    <Wrapper>
      <Segment>
        <ReferralCode />
        <Separator />
        <SendEmail />
      </Segment>
    </Wrapper>
  </>
);

export default InviteFriends;
