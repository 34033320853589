import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Form } from 'semantic-ui-react';
import {
  renderDropdown,
  hideModal,
  createLoadingSelector,
} from 'erisxkit/client';
import get from 'lodash/get';
import GenericModal from '../../../../common/components/GenericModal';
import { ApprovedParticipantAction } from './ParticipantActions';
import {
  fetchCgmList,
  getCgmNumberSelectOptions,
  linkCgmToParticipantPromise,
  unlinkCgmToParticipantPromise,
} from '../../../../reducers/CGMManagement/cgmManagementReducer';
import { getCustomerAccountLabel } from '../../../../reducers/accountsReducer';
import {
  GET_TRADING_PARTICIPANTS,
  fetchTradingParticipants,
  getParticipantCGMLinkModalLoading,
  getParticipantListSelectOptions,
} from '../../../../reducers/participantManagementReducer';
import {
  TradingParticipant,
  CGMRow,
} from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import ModalDetails, { InfoField } from '../../ModalDetails';
import { RootState } from '../../../../ts/types/store';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import unset from 'lodash/unset';
import { FETCH_CGM_LIST } from '../../../../reducers/CGMManagement/cgmManagementActions';

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;

type IForm = {
  isLink: boolean;
  participant?: TradingParticipant;
};
type IProps = {
  isLink: boolean;
  participant?: TradingParticipant;
};

const LINK_FORM_NAME = 'participantLinkCGM';
const ParticipantCGMLinkForm = reduxForm<IForm, IProps>({
  form: LINK_FORM_NAME,
})(({ isLink, participant, change }) => {
  const dispatch = useAppDispatch();
  const cgmOptions = useAppSelector(getCgmNumberSelectOptions);
  const participantOptions = useAppSelector(getParticipantListSelectOptions);
  const loading = useAppSelector(getParticipantCGMLinkModalLoading);
  const loadingCGMs = useAppSelector((state) =>
    createLoadingSelector([FETCH_CGM_LIST])(state),
  );
  const loadingParticipants = useAppSelector((state) =>
    createLoadingSelector([GET_TRADING_PARTICIPANTS])(state),
  );
  const accountLabel = useAppSelector(getCustomerAccountLabel);
  useEffect(() => {
    dispatch(
      fetchTradingParticipants({ accountLabel }),
    );
  }, [accountLabel, dispatch]);

  useEffect(() => {
    // If action is link and a participant was passed,
    // initialize participant dropdown
    if (participant) {
      // Redux Form likely performs a deep comparison with options object
      // Need to remove any extra fields
      unset(participant, 'subRows');
      change('participant', participant);
    }
  }, [change, isLink, participant]);

  return (
    <StyledForm>
      <Field
        component={renderDropdown}
        placeholder="Enter name"
        name="cgmName"
        id="cgmName"
        type="text"
        required
        label="CGM"
        fluid
        options={cgmOptions}
        disabled={loading}
        search
        loading={loadingCGMs}
      />
      <Field
        component={renderDropdown}
        label="Participant"
        fluid
        required
        placeholder="Select"
        name="participant"
        id="participant"
        options={participantOptions}
        disabled={loading}
        search
        loading={loadingParticipants}
      />
    </StyledForm>
  );
});

const ParticipantCGMLinkModal: React.FC<{
  action: ApprovedParticipantAction;
  cgm: CGMRow;
  participant: TradingParticipant;
}> = ({ action, cgm, participant }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getParticipantCGMLinkModalLoading);
  const formValues = useAppSelector((state: RootState) =>
    getFormValues(LINK_FORM_NAME)(state),
  );
  const accountLabel = useAppSelector(getCustomerAccountLabel);

  const isLink = action === ApprovedParticipantAction.LINK;
  const header = `${isLink ? 'Link' : 'Un-Link'} CGM to Participant`;
  const cgmNumber = get(formValues, 'cgmName', '');
  const participantToLink: TradingParticipant = get(
    formValues,
    'participant',
    '',
  );

  useEffect(() => {
    if (isLink)
      dispatch(
        fetchCgmList({
          urlParams: { accountLabel },
        }),
      );
  }, [accountLabel, dispatch, isLink]);

  const unlinkFields = useMemo(() => {
    return [
      {
        header: 'Trading Participant',
        text: participant?.participantName || '',
      },
      { header: 'CGM', text: cgm?.cgmName || '' },
    ] as InfoField[];
  }, [cgm, participant]);

  const content = useMemo(() => {
    return isLink ? (
      <ParticipantCGMLinkForm isLink={isLink} participant={participant} />
    ) : (
      <ModalDetails
        fields={unlinkFields}
        subHeader="You are about to Unlink a CGM"
      />
    );
  }, [isLink, participant, unlinkFields]);

  const onLink = useCallback(async () => {
    try {
      await linkCgmToParticipantPromise(
        {
          tradingFirmMemberId: participantToLink.participantId,
          accountLabel,
          cgmNumber,
          urlParams: {
            accountLabel,
            cgmNumber,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
    } catch (error) {
      dispatch(hideModal());
    }
    dispatch(
      fetchTradingParticipants({accountLabel}),
    );
  }, [accountLabel, cgmNumber, dispatch, participantToLink.participantId]);

  const onUnlink = useCallback(async () => {
    try {
      await unlinkCgmToParticipantPromise(
        {
          tradingFirmMemberId: participant?.participantId,
          accountLabel,
          cgmNumber: cgm.cgmName,
          urlParams: {
            accountLabel,
            cgmNumber: cgm.cgmName,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
    } catch (error) {
      dispatch(hideModal());
    }
    dispatch(
      fetchTradingParticipants({accountLabel})
    );
  }, [accountLabel, cgm, dispatch, participant?.participantId]);

  const linkButtonDisabled = useMemo(
    () => loading || !participantToLink || !cgmNumber,
    [cgmNumber, loading, participantToLink],
  );

  const confirmProps = useMemo(
    () => ({
      text: isLink ? 'Link' : 'Unlink',
      onClick: isLink ? onLink : onUnlink,
      danger: !isLink,
      disabled: isLink ? linkButtonDisabled : false,
    }),
    [isLink, linkButtonDisabled, onLink, onUnlink],
  );

  const cancelProps = useMemo(
    () => ({
      text: 'Cancel',
      onClick: () => dispatch(hideModal()),
    }),
    [dispatch],
  );

  const loadingSelector = useMemo(
    () => (isLink ? getParticipantCGMLinkModalLoading : undefined),
    [isLink],
  );

  return (
    <GenericModal
      header={header}
      content={content}
      cancelProps={cancelProps}
      confirmProps={confirmProps}
      loadingSelector={loadingSelector}
    />
  );
};

export default ParticipantCGMLinkModal;
