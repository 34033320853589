import React from 'react';
import useIsFCM from '../hooks/useIsFCM';
import HomeCMP from './HomeCMP';
import HomeEMP from '../containers/Home';

const AppHome: React.FC = () => {
  const isFCM = useIsFCM();
  // If null then the EnvPromise has not resolved yet and we cant
  // know for sure if the user is an FCM or not.
  if (isFCM === null) return null;
  return isFCM ? <HomeCMP /> : <HomeEMP />;
};

export default AppHome;
