import { WithdrawalConfirm, hideModal } from 'erisxkit/client';
import { createFiatWithdrawalRequestPromiseCreator } from '../../reducers/fundTransfersReducer';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { getSelectedMemberId } from '../../reducers/membersReducer';

const FCMWithdrawalConfirmContainer = (props: any) => {
  const user = useSelector((state: any) => get(state, 'user'));
  const dispatch = useDispatch();
  const selectedMemberId = useSelector(getSelectedMemberId);

  const onConfirm = async (payload: any) => {
    dispatch(hideModal());
    try {
      await createFiatWithdrawalRequestPromiseCreator(payload, dispatch);
    } catch (error) {
      console.log('error on FCM fiat withdrawal');
    }
  };

  return (
    <WithdrawalConfirm
      {...props}
      user={user}
      selectedMemberId={selectedMemberId}
      onCancel={() => dispatch(hideModal())}
      onConfirm={onConfirm}
    />
  );
};

export default FCMWithdrawalConfirmContainer;
