import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import sign from 'eris-ecdsa';
import PropTypes from 'prop-types';
import { XTable } from 'erisxkit/client';
import AddCryptoAddress from '../../components/LinkedAccounts/AddCryptoAddress';
import { showModal, hideModal } from 'erisxkit/client';
import { FUNDING_PASSWORD } from '../../constants/modalTypes';
import { createLinkedCryptoAddress } from '../../reducers/linkedAccountsReducer';
import { getCryptoAssetTypesAsOptions } from '../../reducers/assetTypesReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { removeAllWhiteSpaces } from '../../../client/utils/methods';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import cryptoAddressMetadata from '../../metadata/cryptoAddressMetadata';

const Subheader = styled(Header.Subheader)`
  color: ${({ theme }) => theme.text.text};
`;

const mapStateToProps = (state) => ({
  user: get(state, 'user', {}),
  cryptoAssetTypesOptions: getCryptoAssetTypesAsOptions(state),
  selectedMemberId: getSelectedMemberId(state),
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  createLinkedCryptoAddress,
};

const addressFieldsValid = (state) =>
  !!(get(state, 'label') && get(state, 'assetType') && get(state, 'address'));

class CryptoAddressesContainer extends Component {
  state = {};

  handleChange = (e, obj) => {
    //For address, is needed to remove the white spaces
    const objState = {
      [obj.name]:
        obj.name === 'address' ? removeAllWhiteSpaces(obj.value) : obj.value,
    };
    this.setState(objState);
  };

  submit = (password) => {
    const {
      createLinkedCryptoAddress,
      selectedMemberId,
      user = {},
      hideModal,
    } = this.props;
    let privateKey = sign.privateKeyFromPassword(get(user, 'authId'), password);
    const msg = [
      user.authId,
      selectedMemberId,
      this.state.address,
      this.state.assetType,
      Date.now().toString(),
    ];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);

    const payload = {
      ...this.state,
      memberId: selectedMemberId,
      sig,
      msg,
    };
    privateKey = null;
    createLinkedCryptoAddress(payload);

    hideModal();
    this.setState({
      address: '',
      label: '',
      assetType: '',
    });
  };

  confirm = () => {
    this.props.showModal(FUNDING_PASSWORD, { submit: this.submit });
  };

  render = () => (
    <Fragment>
      <Header as="h2">Linked Crypto Addresses</Header>
      <AccessControl
        allowedPermissions={[
          `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
        ]}
      >
        <Subheader>
          Enter a label, asset type, and address where you would like your
          withdrawals sent.
        </Subheader>
        <AddCryptoAddress
          assetType={this.state.assetType}
          assetTypesOptions={this.props.cryptoAssetTypesOptions}
          loading={this.props.assetTypesLoading}
          noDataText="No crypto addresses found."
          handleChange={this.handleChange}
          confirm={this.confirm}
          valid={addressFieldsValid(this.state)}
          data={this.state}
        />
      </AccessControl>
      <EMPTable8
        title="linkedCryptoAddresses"
        local
        data={this.props.data}
        getTrProps={(row) => {
          if (get(row, ['original', 'state'], '') !== 'approved') {
            return { style: { opacity: 0.45, fontStyle: 'italic' } };
          }
          return {};
        }}
        columns={cryptoAddressMetadata}
        fetchData={() => {}}
        loading={this.props.dataLoading}
        minimumRows={3}
        paginated
        noDataText="No Crypto addresses found."
        loadingText="Fetching Crypto addresses"
      />
    </Fragment>
  );
}

CryptoAddressesContainer.propTypes = {
  cryptoAssetTypesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetTypesLoading: PropTypes.bool,
  createLinkedCryptoAddress: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataLoading: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.string).isRequired,
};

CryptoAddressesContainer.defaultProps = {
  assetTypesLoading: false,
  dataLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CryptoAddressesContainer);
