import { Action } from 'redux-actions';

const isActionPayloadOfType = <T>(
  action: Action<unknown>,
  expectedType: string,
  customTypeGuard?: (payload: unknown) => payload is T
): action is Action<T> => {
  if (action.type !== expectedType) return false;
  if (action.payload === undefined) return false;
  if (customTypeGuard) return customTypeGuard(action.payload);
  return true;
};

export default isActionPayloadOfType;
