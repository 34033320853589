import React from 'react';
import { Field, Validator } from 'redux-form';
import { renderField } from '../../../common/forms/components';
import * as rules from '../../../common/forms/rules';
import { SingleSideBlockTradeFormValues } from '../SingleSideBlockTradeForm';
import { TwoSidedBlockTradeFormValues } from '../TwoSidedBlockTradeForm';

export type SingleSideBlockTradeFormFields =
  keyof SingleSideBlockTradeFormValues;

export type TwoSidedBlockTradeFormFields = keyof TwoSidedBlockTradeFormValues;

type NestableFields =
  | 'clOrdId'
  | 'accountLabel'
  | 'exchangeMemberId'
  | 'tphId'
  | 'customerAccountRef'
  | 'clearingMemberId'
  | 'senderSubId'
  | 'custOrderCapacity'
  | 'accountType'
  | 'cgm';

export type FieldId =
  | SingleSideBlockTradeFormFields
  | TwoSidedBlockTradeFormFields
  | `buySide.${NestableFields}`
  | `sellSide.${NestableFields}`;

export const FormField = ({
  id,
  label,
  optional = false,
  validate = [],
}: {
  id: FieldId;
  label: string;
  optional?: boolean;
  validate?: Validator[];
}) => (
  <Field
    component={renderField}
    name={id}
    id={id}
    type="text"
    label={label}
    placeholder={label}
    style={{ marginBottom: '0px', height: '38px' }}
    minimal
    required={!optional}
    validate={optional ? validate : [rules.required, ...validate]}
  />
);
