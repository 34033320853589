import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XTable, showModal } from 'erisxkit/client';
import sign from 'eris-ecdsa';
import { get, head } from 'lodash';
import styled from 'styled-components';
import columns from '../../metadata/riaUsersMetadata';

import {
  REMOVE_AUTHORIZED_USER,
  FUNDING_PASSWORD,
} from '../../constants/modalTypes';
import {
  fetchAuthorizedUsers,
  selectAuthorizedUsers,
  removeAuthorizedUser,
} from '../../reducers/authorizedUsersReducer';
import { getCurrentAuthId } from '../../reducers/userReducer';
import {
  getAccountIdFromAccountLabel,
  getAllAccounts,
} from '../../reducers/accountsReducer';

const Header = styled.h3`
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
  margin-bottom: 10px;
`;
Header.displayName = 'Header';

const RIAUsersTable = () => {
  const dispatch = useDispatch();
  const userList = useSelector(selectAuthorizedUsers);
  const authId = useSelector(getCurrentAuthId);
  const accounts = useSelector(getAllAccounts);

  const getAccountIdFromAccountLabel = (label) => {
    const account = head(
      Object.values(accounts).filter((acc) => acc.label === label),
    );
    return get(account, 'accountId', '');
  };

  useEffect(() => {
    dispatch(fetchAuthorizedUsers());
  }, []);

  const onRemove = (user) => {
    const { authorizedTraderEmail, accountLabel } = user;

    const onSubmit = (password) => {
      const privateKey = sign.privateKeyFromPassword(authId, password);
      const accountId = getAccountIdFromAccountLabel(accountLabel);
      const msg = [
        authId,
        accountId,
        authorizedTraderEmail,
        Date.now().toString(),
      ];
      const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
      const payload = {
        accountLabel,
        email: authorizedTraderEmail,
        accountId,
        msg,
        sig,
      };
      dispatch(removeAuthorizedUser(payload));
    };

    dispatch(
      showModal(REMOVE_AUTHORIZED_USER, {
        authorizedTraderEmail,
        accountLabel,
        onRemove: () => {
          dispatch(
            showModal(FUNDING_PASSWORD, {
              submit: onSubmit,
            }),
          );
        },
      }),
    );
  };

  return (
    <>
      <Header>USERS</Header>
      <XTable
        title="riaUsers"
        noDataText="No Authorized users found"
        className="dark"
        showPageSizeOptions={false}
        showPagination={false}
        data={userList}
        loading={false}
        columns={columns(onRemove)}
        minRows={3}
        small={false}
      />
    </>
  );
};

export default RIAUsersTable;
