import React from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const SuccessMessage = ({
  action,
  actionDescription,
  header,
  hide,
  subheader,
}) => (
  <div className={`success-msg-wrapper${hide ? ' hide' : ''}`}>
    {/* absolutely positioned wrapper, displays over hidden form.
      wraps success message to flex it to center */}
    <section className="success-msg">
      <Header as="h2" icon textAlign="center">
        <Icon name="circle check" size="massive" />
        {header}
        <Header.Subheader>{subheader}</Header.Subheader>
      </Header>
      {action ? (
        <Button
          floated="right"
          secondary
          onClick={action}
          content={actionDescription}
        />
      ) : null}
    </section>
  </div>
);

SuccessMessage.propTypes = {
  action: PropTypes.func,
  actionDescription: PropTypes.string,
  header: PropTypes.node,
  hide: PropTypes.bool,
  subheader: PropTypes.node,
};

SuccessMessage.defaultProps = {
  action: undefined,
  actionDescription: undefined,
  header: 'Thank you!',
  hide: undefined,
  subheader: 'Your input has been submitted.',
};

export default SuccessMessage;
