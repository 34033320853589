import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import styled from 'styled-components';
import { AuthorizedIPsListModel } from '../ts/models/AuthorizedIPs/AuthorizedIPsList.model';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;

const columnHelper = createColumnHelper<AuthorizedIPsListModel>();

export const columns = (onRemove: (address: string) => void) =>
  [
    columnHelper.accessor(
      (row: AuthorizedIPsListModel) =>
        moment(row?.dateAdded).format('MMMM Do YYYY, h:mm a'),
      {
        id: 'dateAdded',
        header: 'Date Added',
        minSize: 100,
        maxSize: 210,
        meta: {
          align: TableCellAlignment.Left,
        },
      },
    ),

    columnHelper.accessor('updatedBy', {
      id: 'updatedBy',
      header: 'User',
      minSize: 100,
      maxSize: 220,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
    columnHelper.accessor('ipAddress', {
      id: 'ipAddress',
      header: 'IP Address',
      minSize: 100,
      maxSize: 300,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: 'Comment',
      minSize: 100,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Action',
      minSize: 100,
      cell: (props) => (
        <Wrapper>
          {props?.row?.original?.ipAddress && (
            <Icon
              size="small"
              name="trash"
              link
              onClick={() => onRemove(props?.row?.original?.ipAddress)}
            />
          )}
        </Wrapper>
      ),
    }),
  ] as ColumnDef<AuthorizedIPsListModel>[];

export default columns;
