import React from 'react';
import { isEmpty } from 'lodash';
import { Divider, Form, Header } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import {
  renderDropdown,
  renderTextArea,
  normalization,
  rules,
} from 'erisxkit/client';
import PropTypes from 'prop-types';
import { renderField } from '../../common/forms/components';
import SuccessMessage from '../../common/components/SuccessMessage';
import { contact } from '../../constants/contact';
import { positiveNumbers } from '../../common/forms/rules';

export const CollateralDeposit = ({
  accountCode,
  collateralContractsOptions,
  createCollateralDepositRequest,
  createCollateralDepositRequestLoading,
  firmCode,
  fundsDesignations,
  handleSubmit,
  reset,
  submitSucceeded,
  valid,
}) => (
  <section className="overlay-form">
    <Form
      loading={createCollateralDepositRequestLoading}
      className={submitSucceeded ? 'hide' : ''}
      onSubmit={handleSubmit(createCollateralDepositRequest)}
    >
      <Header as="h2" dividing>
        Collateral Deposit Details for Account{' '}
        <span className="bold">{`${
          firmCode ? `${firmCode}-` : ''
        }${accountCode}`}</span>
      </Header>
      <Field
        component={renderDropdown}
        name="contractSymbol"
        label="Collateral Type"
        options={collateralContractsOptions}
        placeholder="Search by product, maturity date, or CUSIP..."
        required
        search
        validate={[rules.required]}
      />
      <small className="italic">
        If you don&apos;t see the contract you are trying to deposit, please
        contact{' '}
        <a
          className="external"
          href={`mailto:${contact}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contact}
        </a>
        .
      </small>
      <Divider />
      <Field
        component={renderField}
        label="Notional Amount"
        name="amount"
        parse={normalization.floatsOnly}
        placeholder="Enter the notional amount of the deposit."
        required
        validate={[rules.required, positiveNumbers]}
      />
      {!isEmpty(fundsDesignations) ? (
        <Field
          component={renderDropdown}
          label="Funds Designation"
          name="fundsDesignation"
          placeholder="Select where you would like these funds to be deposited."
          required
          options={fundsDesignations}
          validate={[rules.required]}
        />
      ) : null}
      <Field
        component={renderField}
        label="Price Paid"
        name="px"
        parse={normalization.floatsOnly}
        placeholder="(optional)"
      />
      <Field
        component={renderTextArea}
        label="Comments"
        name="comment"
        placeholder="(optional)"
      />
      <Form.Button
        floated="right"
        disabled={!valid}
        loading={createCollateralDepositRequestLoading}
        primary
      >
        Submit Request
      </Form.Button>
    </Form>
    {submitSucceeded && !createCollateralDepositRequestLoading ? (
      <SuccessMessage
        action={reset}
        actionDescription="Deposit more collateral"
        header="Thank You!"
        hide={!submitSucceeded}
        subheader="Your deposit request has been submitted. Please check back for confirmation."
      />
    ) : (
      ''
    )}
  </section>
);

CollateralDeposit.propTypes = {
  accountCode: PropTypes.string,
  collateralContractsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  createCollateralDepositRequest: PropTypes.func.isRequired,
  createCollateralDepositRequestLoading: PropTypes.bool,
  firmCode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitSucceeded: PropTypes.bool,
  valid: PropTypes.bool,
};

CollateralDeposit.defaultProps = {
  accountCode: '',
  collateralContractsOptions: [],
  createCollateralDepositRequestLoading: false,
  firmCode: '',
  submitSucceeded: false,
  valid: false,
};

// TODO: redux-form that does not clear on unmount
export default reduxForm({
  form: 'collateralDeposit',
})(CollateralDeposit);
