import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import set from 'lodash/set';
import { CAR, Contract, Product } from '../../ts/models/CAR.model';

const allowedProductLimitEmptyFields = [
  'maxOrderSize',
  'maxLongExposure',
  'maxShortExposure',
];

const processProductLimit = (productLimit: Product) => {
  const processedProductLimit = {
    ...productLimit,
    expiryLimits: productLimit?.expiryLimits?.filter(
      (expiry) => !isEmpty(expiry),
    ),
  };

  //Updates the property only if it exists.
  //If the user enters an empty string, that means undefined for the property.
  for (const field of allowedProductLimitEmptyFields) {
    const fieldVal = get(processedProductLimit, field);
    if (fieldVal !== undefined && fieldVal !== '') {
      set(processedProductLimit, field, Number(fieldVal));
    } else if (fieldVal === '') {
      set(processedProductLimit, field, undefined);
    }
  }

  return processedProductLimit;
};

const parseCAR = (car: Partial<CAR>) => {
  const { productLimits } = car;
  const parsedCar = {
    ...car,
    enabledProducts: productLimits?.map(({ productCode }) => productCode),
    productLimits: productLimits
      ?.filter(
        ({ maxShortExposure, maxLongExposure, maxOrderSize, expiryLimits }) => {
          const hasMaxData =
            (!isNil(maxShortExposure) && maxShortExposure !== '') ||
            (!isNil(maxLongExposure) && maxLongExposure !== '') ||
            (!isNil(maxOrderSize) && maxOrderSize !== '');
          const hasExpiryLimits = !isEmpty(
            expiryLimits?.filter((expiry: Contract) => !isEmpty(expiry)),
          );
          return hasMaxData || hasExpiryLimits;
        },
      )
      .map(processProductLimit),
  };

  //Updates the property only if it exists.
  //If the user enters an empty string, that means undefined for defaultFuturesMaxOrderSize.
  if (
    parsedCar.defaultFuturesMaxOrderSize !== undefined &&
    parsedCar.defaultFuturesMaxOrderSize !== ''
  ) {
    parsedCar.defaultFuturesMaxOrderSize = Number(
      car.defaultFuturesMaxOrderSize,
    );
  } else if (parsedCar.defaultFuturesMaxOrderSize === '') {
    parsedCar.defaultFuturesMaxOrderSize = undefined;
  }

  return parsedCar;
};

/**
 * Takes a CAR array and compares the product_limits with the enabled_products, if there is an item in the enabled_products that it is not
 * in the product_limits, it adds the new object to the product_limits array, with the right product, in order to the table can show that item.
 * @param originalData CAR array
 * @returns the new CAR array
 */
export const FillCARProductLimitsWithEnableProducts = (originalData: CAR[]) => {
  const newData = originalData.map((entry) => {
    const updatedProductLimits = entry.productLimits.slice();
    entry.enabledProducts.forEach((productCode) => {
      const existingProduct = entry.productLimits.find(
        (productLimit) => productLimit.productCode === productCode,
      );

      if (!existingProduct) {
        updatedProductLimits.push({
          productCode,
          expiryLimits: [],
        });
      }
    });

    return {
      ...entry,
      productLimits: updatedProductLimits,
    };
  });

  return newData;
};

export default parseCAR;
