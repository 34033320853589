import React, { useEffect, useMemo } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import styled from 'styled-components';
import {
  fetchBlockTradeRequestsV2,
  getBlockTradeRequests,
} from '../../reducers/BlockTrades/blockTradesReducer';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import Page from '../../common/Page';
import { getActiveAccountLabel } from '../../reducers/activeAccountSelectors';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import blockTradeRequestsMetadataHistory from '../../metadata/spotBlockTradeRequestsMetadataHistory';

const Container = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const SpotBlockTradeRegistrationHistory = () => {
  const dispatch = useAppDispatch();
  const blockTradeRequests: BlockTradeRequestModel[] = useAppSelector(getBlockTradeRequests);
  const activeAccountLabel = useAppSelector(getActiveAccountLabel);

  const loading = useAppSelector((state) =>
    createLoadingSelector([fetchBlockTradeRequestsV2._PREFIX])(state),
  );

  useEffect(() => {
    if (activeAccountLabel) {
      dispatch(fetchBlockTradeRequestsV2({ accountLabel: activeAccountLabel, status: 'cleared'  }));
    }
  }, [activeAccountLabel, dispatch]);

  const memoizedColumns = useMemo(() => {
    return blockTradeRequestsMetadataHistory();
  }, [activeAccountLabel]);

  return (
    <>
      <Page header={`BlockTrade Requests History`}></Page>
      <Container>
        <EMPTable8
          title="BlockTradeRequestsHistory"
          data={blockTradeRequests}
          local
          columns={memoizedColumns}
          fetchData={() => { }}
          count={blockTradeRequests?.length}
          loading={loading}
          loadingText="Fetching BlockTrade Requests"
          minimumRows={5}
          showActiveFilters
        />
      </Container>
    </>
  )
};

export default SpotBlockTradeRegistrationHistory;
