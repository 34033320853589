import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import sign from 'eris-ecdsa';
import { Button, Modal, Icon } from 'semantic-ui-react';
import StyledButton from '../../common/StyledButton';
import { showModal, hideModal } from 'erisxkit/client';
import colors from '../../constants/colors';
import {
  fetchAuthorizedIPs,
  fetchIpAuthorizationHistory,
  removeAuthorizedIP,
  removeAuthorizedIPPromiseCreator,
} from '../../reducers/authorizedIPsReducer';
import {
  ADD_IP_ADDRESS_MODAL,
  FUNDING_PASSWORD,
  REMOVE_IP_ADDRESS_MODAL,
} from '../../constants/modalTypes';
import { getCurrentAuthId, getUserMemberIds } from '../../reducers/userReducer';

const Buttons = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
`;
const Cancel = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Remove = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  && {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  && {
    margin-bottom: 20px;
  }
`;

const RemoveAuthorizedIPModal = ({ ipAddress }) => {
  const dispatch = useDispatch();
  const authId = useSelector(getCurrentAuthId);
  const memberIds = useSelector(getUserMemberIds);

  const hideModals = () => {
    dispatch(hideModal(REMOVE_IP_ADDRESS_MODAL));
    dispatch(hideModal(FUNDING_PASSWORD));
  };

  const onRemoveIPAddress = (password) => {
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const msg = [authId, ipAddress, Date.now().toString()];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
    const payload = {
      ipAddress,
      msg,
      sig,
    };
    removeAuthorizedIPPromiseCreator(payload, dispatch)
      .then(() => {
        // Refetch IP Authorization History and Authorized IPs List
        dispatch(fetchAuthorizedIPs({ memberIds }));
        dispatch(fetchIpAuthorizationHistory({ memberIds }));
        hideModals();
      })
      .catch(() => hideModal());
  };

  const onRemove = () =>
    dispatch(
      showModal(FUNDING_PASSWORD, {
        submit: onRemoveIPAddress,
      }),
    );

  return (
    <>
      <Modal.Content scrolling>
        <Title>Please confirm the following action</Title>
        <SubTitle>
          <b>{ipAddress}</b> {` will be removed from authorized IPs`}
        </SubTitle>
      </Modal.Content>
      <Buttons>
        <Cancel onClick={() => dispatch(hideModal())} text="Cancel" />
        <Remove danger onClick={onRemove} text="Remove" />
      </Buttons>
    </>
  );
};

RemoveAuthorizedIPModal.defaultProps = {
  ipAddress: '',
};

RemoveAuthorizedIPModal.propTypes = {
  ipAddress: PropTypes.string,
};

export default RemoveAuthorizedIPModal;
