enum TableFilterOp {
  Contains = 'contain',
  Equals = 'eq',
  NotEquals = 'ne',
  LessThanOrEq = 'lte',
  GreaterThanOrEq = 'gte',
  GreaterThan = 'gt',
  LessThan = 'lt',
  Exists = 'exists',
  Match = 'match',
}

export default TableFilterOp;
