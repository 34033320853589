import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import moment from 'moment';
import DropdownOption from '../ts/types/DropdownOption.type';
import FCMBalanceSnapshot from '../ts/models/FCMBalanceSnapshot/FCMBalanceSnapshot.model';
import FCMBalanceCycle from '../ts/models/FCMBalanceSnapshot/FCMBalanceCycle.model';

/** Action Names */
export const FETCH_FCM_BALANCE_CYCLE = 'FCM_BALANCE_CYCLE';
export const FCM_BALANCE_CYCLES = 'FCM_BALANCE_CYCLES';

/** Actions */
export const fetchFcmBalanceCycle = createRoutine(FETCH_FCM_BALANCE_CYCLE);
export const fetchFcmBalanceCycles = createRoutine(FCM_BALANCE_CYCLES);

type FetchBalanceSnapshotsResponse = {
  balances: FCMBalanceSnapshot[];
  count: number;
};

type FetchBalanceCyclesResponse = FCMBalanceCycle[];

/** Initial State */
type FCMBalanceSnapshotState = {
  accountsBalances: FCMBalanceSnapshot[];
  balanceCycles: FCMBalanceCycle[];
};

const initialState: FCMBalanceSnapshotState = {
  accountsBalances: [],
  balanceCycles: [],
};

/** Reducer */
export default handleActions<FCMBalanceSnapshotState, any>(
  {
    [fetchFcmBalanceCycle.SUCCESS]: (
      state: FCMBalanceSnapshotState,
      { payload }: { payload: FetchBalanceSnapshotsResponse },
    ) => ({
      ...state,
      ...payload,
    }),
    [fetchFcmBalanceCycles.SUCCESS]: (
      state: FCMBalanceSnapshotState,
      { payload }: { payload: FetchBalanceCyclesResponse },
    ) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);

//* Selectors */
export const getFcmBalanceSnapshotsState = (
  state: any,
): FCMBalanceSnapshotState => get(state, 'fcmBalances', {});

export const getFCMBalanceSnapshots = createSelector(
  [getFcmBalanceSnapshotsState],
  (state) => get(state, 'accountsBalances', []),
);

export const getFCMBalanceSnapshotsCount = createSelector(
  [getFcmBalanceSnapshotsState],
  (state) => get(state, 'count', 0),
);
export const getFcmBalanceCycles = createSelector(
  [getFcmBalanceSnapshotsState],
  (state) => get(state, 'balanceCycles', []),
);

export const getFcmBalanceCycleOptions = (
  state: any,
): DropdownOption<FCMBalanceCycle>[] =>
  getFcmBalanceCycles(state).map(
    (cycle: FCMBalanceCycle, key: number) =>
      ({
        key,
        text: `${moment(cycle.date).format('ll')} | ${cycle.type}`,
        value: cycle,
      }) as DropdownOption<FCMBalanceCycle>,
  );
