import React from 'react';
import { Segment, Header, List } from 'semantic-ui-react';

const Contact = () => (
  <section className="main-holdings">
    <Header as="h1" icon="mail">
      Contact
    </Header>
    <Segment padded>
      <p>For questions relating to:</p>
      <List bulleted>
        <List.Item>
          <b>Funding</b>, contact{' '}
          <a
            href="mailto:digital.treasuryops@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            digital.treasuryops@cboe.com
          </a>
          .
        </List.Item>
        <List.Item>
          <b>Member account updates</b>, contact{' '}
          <a
            href="mailto:digital.clientservice@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            digital.clientservice@cboe.com
          </a>
          .
        </List.Item>
        <List.Item>
          <b>Cleared trade inquiries</b>, contact{' '}
          <a
            href="mailto:digital.clearingops@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            digital.clearingops@cboe.com
          </a>
          .
        </List.Item>
        <List.Item>
          Cboe Digital <b>trading platform</b>, trade queries, and executing a
          block trade, contact{' '}
          <a
            href="mailto:digital.market.operations@cboe.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            digital.market.operations@cboe.com
          </a>
          .
        </List.Item>
      </List>
    </Segment>
  </section>
);

export default Contact;
