import React, { Component, useEffect } from 'react';
import auth0 from 'auth0-js';
import EnvPromise from '../config/env';
import qs from 'qs';
import get from 'lodash/get';

const DEFAULT_SCOPE = 'openid profile email';
const TRADING_UI_SCOPE = 'write:order read:clearing_api write:block_trade';
const FCM_SCOPE =
  'read:customer_account_reference write:customer_account_reference';

const SignupRedirect = ({ params }) => {
  useEffect(() => {
    // If a user is sent an invitation link with a referral code
    // query param - we save it in local storage
    const referral = get(params, 'referral', null);
    if (referral) localStorage.setItem('referralCode', referral);
  }, []);

  EnvPromise.then((env) => {
    const auth = new auth0.WebAuth({
      domain: env.domain,
      clientID: env.clientId,
      redirectUri: env.redirectUri,
      audience: env.auth0ApiAud,
      responseType: 'token id_token',
      scope: `${DEFAULT_SCOPE} ${TRADING_UI_SCOPE} ${FCM_SCOPE}`,
      enableLoginImpersonation: env.enableLoginImpersonation,
    });
    return auth.authorize({ screen_hint: 'signup' });
  });

  // check if a referral code query param exists
  // if it does, save that into localstorage as a referral code
  // user gets to the Auth page, logs in

  return <div />;
};

export default SignupRedirect;
