import React, { Fragment } from 'react';
import { get } from 'lodash';
import { Header, Divider } from 'semantic-ui-react';
import { XTable } from 'erisxkit/client';
import AddBankAccountPlaidContainer from '../../containers/LinkedAccounts/AddBankAccountPlaidContainer';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
const BankAccounts = ({ data, metadata, loading }) => (
  <Fragment>
    <Header as="h2" content="Linked Bank Accounts" />
    <AccessControl
      allowedPermissions={[
        `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
      ]}
    >
      <Header>
        Add Bank Account
        <Header.Subheader>
          Use your bank account login credentials to link an additional account.
        </Header.Subheader>
      </Header>
      <AddBankAccountPlaidContainer tertiary />
    </AccessControl>
    <Divider hidden />
    <EMPTable8
      title="linkedBankAccounts"
      local
      data={data}
      columns={metadata}
      fetchData={() => {}}
      loading={loading}
      paginated
      minimumRows={3}
      noDataText="No Bank accounts found."
      loadingText="Fetching Bank"
      getTrProps={(row) => {
        if (get(row, ['original', 'state'], '') !== 'approved') {
          return { style: { opacity: 0.45, fontStyle: 'italic' } };
        }
        return {};
      }}
    />
  </Fragment>
);

export default BankAccounts;
