import React, { PureComponent } from 'react';
import {
  Form,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  fundsDesignation as fundsDesignationConstants,
  renderTextArea,
  rules,
  NON_SEG,
  normalization,
} from 'erisxkit/client';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { renderField } from '../../common/forms/components';
import FiatDepositDetails from './FiatDepositDetails';
import AssetTypeField from '../../common/components/AssetTypeField';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';

const DEFAULT_FD = fundsDesignationConstants[NON_SEG].value;
export class FiatDeposit extends PureComponent {
  componentDidMount = () => {
    const {
      currencyOptions,
      initialize,
      change,
      isFuturesDM,
      activeAccountFD,
    } = this.props;
    if (isFuturesDM) {
      change('assetType', 'USD');
      change('fundsDesignation', 'P');
      change('assetTypeWithFundsDesignation', 'USD/P');
      this.fetchRefCodeAndBankDetails('USD', 'P');
      return;
    }
    // select only given option by default, if applicable
    if (currencyOptions.length === 1) {
      // set default values

      const assetType = currencyOptions[0].value;
      const fundsDesignation =
        fundsDesignationConstants.find((fd) => fd.value === activeAccountFD)
          ?.value || DEFAULT_FD;
      initialize({ assetTypeWithFundsDesignation: assetType });
      change('assetType', assetType);
      change('fundsDesignation', fundsDesignation);
      this.fetchRefCodeAndBankDetails(assetType, fundsDesignation);
    }
  };

  fetchRefCodeAndBankDetails = (assetType, fundsDesignation) => {
    const { accountId } = this.props;
    if (accountId && assetType && fundsDesignation) {
      const payload = {
        accountId,
        assetType,
        fundsDesignation,
      };
      this.props.bankDetails(payload);
      this.props.referenceCode(payload);
    }
  };

  render = () => {
    const {
      accountCode,
      bankDetailsList,
      bankDetailsLoading,
      change,
      createFiatDepositRequest,
      currencyOptions,
      firmCode,
      handleSubmit,
      refCode,
      refCodeLoading,
      valid,
    } = this.props;

    return (
      <section className="overlay-form ui text container">
        <Header as="h2">
          Wire Deposit details for account{' '}
          <span className="bold">{`${firmCode ? `${firmCode}-` : ''}${accountCode}`}</span>
        </Header>
        <Form onSubmit={handleSubmit(createFiatDepositRequest)}>
          <AssetTypeField
            change={change}
            currencyOptions={currencyOptions}
            postChange={(at, fd) => this.fetchRefCodeAndBankDetails(at, fd)}
          />
          <FiatDepositDetails
            bankDetails={bankDetailsList}
            bankDetailsLoading={bankDetailsLoading}
            refCode={refCode}
            refCodeLoading={refCodeLoading}
          />
          <IraAccountDisclaimer />
        </Form>
      </section>
    );
  };
}

FiatDeposit.propTypes = {
  accountCode: PropTypes.string,
  accountsLoading: PropTypes.bool,
  assetType: PropTypes.string,
  accountId: PropTypes.string.isRequired,
  bankDetails: PropTypes.func.isRequired,
  bankDetailsList: PropTypes.objectOf(PropTypes.string),
  bankDetailsLoading: PropTypes.bool,
  change: PropTypes.func.isRequired,
  createFiatDepositRequest: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  firmCode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  linkedBankAccounts: PropTypes.arrayOf({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  linkedMemberAssetAccountId: PropTypes.string,
  fundsDesignation: PropTypes.string,
  referenceCode: PropTypes.func.isRequired,
  refCode: PropTypes.string,
  refCodeLoading: PropTypes.bool,
  valid: PropTypes.bool,
  activeAccountFD: PropTypes.string,
};

FiatDeposit.defaultProps = {
  accountCode: '',
  accountsLoading: false,
  assetType: '',
  bankDetailsList: {},
  bankDetailsLoading: false,
  currencyOptions: [],
  firmCode: '',
  linkedBankAccounts: [],
  linkedMemberAssetAccountId: '',
  fundsDesignation: '',
  refCode: '',
  refCodeLoading: false,
  valid: false,
  activeAccountFD: '',
};

const selector = formValueSelector('fiatDeposit');

const FiatDepositForm = reduxForm({
  form: 'fiatDeposit',
})(FiatDeposit);

export default connect((state) => ({
  linkedMemberAssetAccountId: selector(state, 'linkedMemberAssetAccountId'),
  assetType: selector(state, 'assetType'),
  fundsDesignation: selector(state, 'fundsDesignation'),
}))(FiatDepositForm);
