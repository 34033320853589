import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, Icon } from 'semantic-ui-react';
import {
  getSidebarActivePanels,
  toggleSidebarPanel,
} from '../../../reducers/sidebarReducer';
import {
  Content,
  MarginFuturesPanel,
  getFCMPanels,
  getNonFCMPanels,
  isPanelActive,
  validatePanelEnvPermissions,
  validatePanelPermissions,
} from './MarginFuturesPanels';
import Link from './Link';
import {
  StyledAccordion,
  StyledTitle,
  HomeWrapper,
  HomeIcon,
  HomeTitle,
  Wrapper,
  HomeNavLink,
} from './styled';
import { useIsNonFCMMarginFuturesPage } from '../../../hooks/useExchangeMember';
import {
  getLoggedInUserApiKeyPermissions,
  getLoggedInUserPermissions,
} from '../../../reducers/userReducer';
import history from '../../../constants/history';
import EnvPromise from '../../../config/env';
import EnvData from '../../../ts/types/EnvData.type';

export const isOnHomePage = () => window.location.href.includes('home');
export const isOnNonFCMHomePage = () =>
  window.location.href.includes('fcm-clearing');

const HomeLink = () => {
  return (
    <HomeWrapper>
      <HomeNavLink activeClassName="fcm-home-active" key="home" to="/home">
        <HomeIcon name="home" isSelected={isOnHomePage()} />{' '}
        <HomeTitle isSelected={isOnHomePage()}>Home</HomeTitle>
      </HomeNavLink>
    </HomeWrapper>
  );
};

const NonFCMHomeLink = () => {
  return (
    <HomeWrapper>
      <HomeNavLink
        activeClassName="fcm-home-active"
        key="fcm-clearing"
        to="/fcm-clearing"
      >
        <HomeIcon name="home" isSelected={isOnNonFCMHomePage()} />{' '}
        <HomeTitle isSelected={isOnNonFCMHomePage()}>FCM Clearing</HomeTitle>
      </HomeNavLink>
    </HomeWrapper>
  );
};

const MarginFuturesLinks = () => {
  const dispatch = useDispatch();
  const panels = useSelector(getSidebarActivePanels);
  const memberUserPerms = useSelector(getLoggedInUserPermissions);
  const apiKeyPerms = useSelector(getLoggedInUserApiKeyPermissions);
  const [activeIndexes, setActiveIndexes] = useState<number[]>(panels);
  const isMarginFuturesPage = useIsNonFCMMarginFuturesPage();
  const [envData, setEnvData] = useState<EnvData | null>(null);

  const allPanels = useMemo(() => {
    return isMarginFuturesPage ? getNonFCMPanels() : getFCMPanels();
  }, [isMarginFuturesPage]);

  const handleClick = useCallback(
    (e, { active, index }: { active: true; index: number }) => {
      // If is already active, it's closing it
      if (active) {
        dispatch(toggleSidebarPanel(activeIndexes.filter((i) => i !== index)));
        return;
      }
      // If it's not already active, add index to active indexes
      dispatch(toggleSidebarPanel([...activeIndexes, index]));
    },
    [activeIndexes, dispatch],
  );

  const isActive = useCallback(
    (panelIndex: number) => activeIndexes.includes(panelIndex),
    [activeIndexes],
  );
  useEffect(() => setActiveIndexes(panels), [panels]);

  const getNestedLink = useCallback(
    (panel: MarginFuturesPanel, index: number) =>
      panel.content ? (
        <>
          <StyledTitle
            active={isActive(index)}
            hasActiveLink={isPanelActive(panel)}
            index={index}
            onClick={handleClick}
            data-cy={panel.dataCy}
          >
            <Icon name={`angle double ${isActive(index) ? 'down' : 'right'}`} />
            {panel.title}
          </StyledTitle>
          <Accordion.Content active={isActive(index)}>
            {panel.content
              .filter((p) =>
                validatePanelPermissions(p, memberUserPerms, apiKeyPerms),
              )
              .map((c: Content) => Link(c))}
          </Accordion.Content>
        </>
      ) : null,
    [apiKeyPerms, handleClick, isActive, memberUserPerms],
  );

  const getSimpleLink = useCallback(
    (panel: MarginFuturesPanel, index: number) => {
      const link = panel.to;
      if (link !== undefined) {
        return (
          <StyledTitle
            key={panel.key}
            active={isActive(index)}
            hasActiveLink={isPanelActive(panel)}
            index={index}
            onClick={() => {
              history.push(link);
            }}
            data-cy={panel.dataCy}
          >
            <Icon name={`angle double ${isActive(index) ? 'down' : 'right'}`} />
            <div>{panel.title}</div>
          </StyledTitle>
        );
      }
      return null;
    },
    [isActive],
  );

  useEffect(() => {
    EnvPromise.then((env: EnvData) => setEnvData(env));
  }, [dispatch]);

  const filteredPanels = useMemo(
    () =>
      allPanels
        .filter((panel) =>
          validatePanelPermissions(panel, memberUserPerms, apiKeyPerms),
        )
        .filter(
          (panel) =>
            envData && validatePanelEnvPermissions(envData, panel?.envVar),
        )
        .map((panel) => {
          return {
            ...panel,
            content: panel?.content?.filter(
              (contentItem) => envData && validatePanelEnvPermissions(envData, contentItem?.envVar),
            ),
          };
        })
        .map((panel, index) => {
          if (panel.content) return getNestedLink(panel, index);
          if (panel.to !== undefined) return getSimpleLink(panel, index);
        }),
    [
      allPanels,
      apiKeyPerms,
      envData,
      getNestedLink,
      getSimpleLink,
      memberUserPerms,
    ],
  );

  return (
    <Wrapper>
      {isMarginFuturesPage ? <NonFCMHomeLink /> : <HomeLink />}
      <StyledAccordion>{filteredPanels}</StyledAccordion>
    </Wrapper>
  );
};

export default MarginFuturesLinks;
