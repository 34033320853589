import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import sign from 'eris-ecdsa';
import { Modal, Button } from 'semantic-ui-react';
import AddCryptoAddressModal from '../../components/LinkedAccounts/AddCryptoAddressModal';
import { showModal, hideModal } from 'erisxkit/client';
import { FUNDING_PASSWORD } from '../../constants/modalTypes';
import { createLinkedCryptoAddress } from '../../reducers/linkedAccountsReducer';
import { getCryptoAssetTypes } from '../../reducers/assetTypesReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import { removeAllWhiteSpaces } from '../../utils/methods';

const mapStateToProps = (state) => ({
  user: _.get(state, 'user', {}),
  assetTypes: getCryptoAssetTypes(state),
  selectedMemberId: getSelectedMemberId(state),
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  createLinkedCryptoAddress,
};

class AddLinkedAccountContainer extends Component {
  handleChange = (e, obj) => {
    this.setState({ [obj.name]: removeAllWhiteSpaces(obj.value) });
  };

  submit = (password) => {
    const {
      createLinkedCryptoAddress,
      selectedMemberId,
      user = {},
      hideModal,
    } = this.props;

    let privateKey = sign.privateKeyFromPassword(
      _.get(user, 'authId'),
      password,
    );
    const msg = [
      user.authId,
      selectedMemberId,
      this.state.address,
      this.state.assetType,
      Date.now().toString(),
    ];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);

    const payload = {
      ...this.state,
      memberId: selectedMemberId,
      sig,
      msg,
    };
    privateKey = null;
    createLinkedCryptoAddress(payload);

    hideModal();
    hideModal();
  };

  confirm = () => {
    this.props.showModal(FUNDING_PASSWORD, { submit: this.submit });
  };

  render() {
    const { assetTypes, activeAssetType } = this.props;
    const currencyOptions = assetTypes.map((assetType) => ({
      key: assetType.symbol,
      value: assetType.symbol,
      text: assetType.symbol,
    }));

    return (
      <Fragment>
        <Modal.Content>
          <AddCryptoAddressModal
            handleChange={this.handleChange}
            options={currencyOptions}
            activeAssetType={activeAssetType}
            assetType={_.get(this.state, 'assetType')}
          />
          <IraAccountDisclaimer marginBottom="0px" />
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="ui primary"
            content="Save"
            onClick={this.confirm}
            data-cy="add-crypto-address-button"
          />
        </Modal.Actions>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLinkedAccountContainer);
