export const DEFAULT_FEE_KEY = 'default';
export const DM_KEY = 'DM';
// this is how the designation is being set in the admin ui
export const DM_IRA_KEY = 'IRA DM';

export default {
  [DM_KEY]: {
    'BTC/USD': 0.002,
    'ETH/USD': 0.002,
    'LTC/USD': 0.002,
    'BCH/USD': 0.002,
    'USDC/USD': 0,
    'EST/USD': 0,
    'AAVE/USD': 0.002,
    'LINK/USD': 0.002,
    [DEFAULT_FEE_KEY]: 0.002,
  },
  [DM_IRA_KEY]: {
    'BTC/USD': 0.005,
    'ETH/USD': 0.005,
    'LTC/USD': 0.005,
    'BCH/USD': 0.005,
    'USDC/USD': 0,
    'EST/USD': 0,
    'AAVE/USD': 0.005,
    'LINK/USD': 0.005,
    [DEFAULT_FEE_KEY]: 0.005,
  },
};
