import React, { useMemo } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { StyledDivider } from './TwoSidedBlockTradeForm.styles';
import { FormField } from '../shared/FormField';
import * as rules from '../../../common/forms/rules';
import { FieldGrid, Segment } from '../shared/styles';
import FormDropdown from '../shared/FormDropdown';
import {
  ACCOUNT_TYPE_OPTIONS,
  CUSTOMER_ORDER_CAPACITY_OPTIONS,
} from '../shared/constants';
import { isFuturesBlockTradesRegistration } from '../shared/utils';
import FormDatePicker from '../shared/FormDatePicker';

export type TwoSidedBlockTradeFormValues = {
  contractSymbol: string;
  price: string;
  quantity: string;
  negotiatedTime: string;
  buySide: {
    accountLabel?: string;
    clOrdId: string;
    exchangeMemberId?: string;
    tphId?: string;
    customerAccountRef?: string;
    clearingMemberId?: string;
    senderSubId?: string;
    custOrderCapacity?: string;
    accountType?: string;
    cgm?: string;
  };
  sellSide: {
    accountLabel?: string;
    clOrdId: string;
    exchangeMemberId?: string;
    tphId?: string;
    customerAccountRef?: string;
    clearingMemberId?: string;
    senderSubId?: string;
    custOrderCapacity?: string;
    accountType?: string;
    cgm?: string;
  };
};
export const TWO_SIDED_BLOCK_TRADE_FORM_NAME = 'twoSidedBlockTrade';

type IProps = {
  onSubmit: () => void;
};

export type TwoSidedBlockTradeFormProps = InjectedFormProps<
  TwoSidedBlockTradeFormValues,
  IProps
> &
  IProps;

const TwoSidedBlockTradeForm: React.FC<
  InjectedFormProps<TwoSidedBlockTradeFormValues, IProps> & IProps
> = ({ onSubmit, handleSubmit }: TwoSidedBlockTradeFormProps) => {
  const isFutures = useMemo(() => isFuturesBlockTradesRegistration(), []);
  return (
    <Segment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldGrid>
          <FormField id="contractSymbol" label="Contract Symbol" />
          <FormField id="price" label="Price" validate={[rules.number]} />
          <FormField id="quantity" label="Quantity" validate={[rules.number]} />
          <FormDatePicker id="negotiatedTime" label="Negotiated Time" />
        </FieldGrid>
        <StyledDivider horizontal>Buy Side</StyledDivider>
        <FieldGrid>
          <FormField id="buySide.clOrdId" label="CL Ord ID" />
          {!isFutures && (
            <FormField id="buySide.accountLabel" label="Account Label" />
          )}
          {isFutures && (
            <>
              <FormField
                id="buySide.exchangeMemberId"
                label="Exchange Member ID"
              />
              <FormField id="buySide.tphId" label="TPH ID" />
              <FormField
                id="buySide.customerAccountRef"
                label="Customer Account Ref"
              />
              <FormField
                id="buySide.clearingMemberId"
                label="Clearing Member ID"
              />
            </>
          )}

          <FormField
            id="buySide.senderSubId"
            label="Sender Sub ID"
            validate={[rules.minLength(3), rules.maxLength(18)]}
          />
          {isFutures && (
            <>
              <FormDropdown
                id="buySide.custOrderCapacity"
                label="Customer Order Capacity"
                options={CUSTOMER_ORDER_CAPACITY_OPTIONS}
              />
              <FormDropdown
                id="buySide.accountType"
                label="Account Type"
                options={ACCOUNT_TYPE_OPTIONS}
              />
            </>
          )}
          <FormField id="buySide.cgm" label="CGM" optional />
        </FieldGrid>
        <StyledDivider horizontal>Sell Side</StyledDivider>
        <FieldGrid>
          {!isFutures && (
            <FormField id="sellSide.accountLabel" label="Account Label" />
          )}
          <FormField id="sellSide.clOrdId" label="CL Ord ID" />
          {isFutures && (
            <>
              <FormField
                id="sellSide.exchangeMemberId"
                label="Exchange Member ID"
              />
              <FormField id="sellSide.tphId" label="TPH ID" />
              <FormField
                id="sellSide.customerAccountRef"
                label="Customer Account Ref"
              />
              <FormField
                id="sellSide.clearingMemberId"
                label="Clearing Member ID"
              />
            </>
          )}
          <FormField
            id="sellSide.senderSubId"
            label="Sender Sub ID"
            validate={[rules.minLength(3), rules.maxLength(18)]}
          />
          {isFutures && (
            <>
              <FormDropdown
                id="sellSide.custOrderCapacity"
                label="Customer Order Capacity"
                options={CUSTOMER_ORDER_CAPACITY_OPTIONS}
              />
              <FormDropdown
                id="sellSide.accountType"
                label="Account Type"
                options={ACCOUNT_TYPE_OPTIONS}
              />
            </>
          )}
          <FormField id="sellSide.cgm" label="CGM" optional />
        </FieldGrid>
      </Form>
    </Segment>
  );
};
export default reduxForm<TwoSidedBlockTradeFormValues, IProps>({
  form: TWO_SIDED_BLOCK_TRADE_FORM_NAME,
  destroyOnUnmount: false,
})(TwoSidedBlockTradeForm);
