import { takeLatest } from 'redux-saga/effects';

import {
  fetchCar,
  createCar,
  deleteCar,
  fetchCarList,
  updateCar,
  linkCarToParticipant,
  unlinkCarToParticipant,
} from '../reducers/CARManagement/carManagementReducer';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(
    fetchCar,
    generateSaga(fetchCar, { method: HTTPMethod.GET }),
  );
  yield takeLatest(
    fetchCarList,
    generateSaga(fetchCarList, { method: HTTPMethod.GET }),
  );
  yield takeLatest(
    createCar,
    generateSaga(createCar, {
      loading: 'Submitting CAR creation request',
      success: 'CAR has been added successfully!',
    }),
  );
  yield takeLatest(
    updateCar,
    generateSaga(updateCar, {
      method: HTTPMethod.PUT,
      loading: 'Submitting CAR update request',
      success: 'CAR has been updated successfully!',
    }),
  );
  yield takeLatest(
    deleteCar,
    generateSaga(deleteCar, {
      method: HTTPMethod.DELETE,
      loading: 'Submitting CAR delete request',
      success: 'CAR has been deleted successfully!',
    }),
  );
  yield takeLatest(
    linkCarToParticipant,
    generateSaga(linkCarToParticipant, {
      method: HTTPMethod.POST,
      loading: 'Linking CAR to Participant',
      success: 'CAR has been linked successfully',
    }),
  );
  yield takeLatest(
    unlinkCarToParticipant,
    generateSaga(unlinkCarToParticipant, {
      method: HTTPMethod.POST,
      loading: 'Unlinking CAR to Participant',
      success: 'CAR has been unlinked successfully',
    }),
  );
}
