import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import KBA from '../../components/onBoarding/KBA';
import { fetchKbaQuestion } from '../../reducers/kbaReducer';
import GenericStep from '../../components/onBoarding/GenericStep';

class KBAIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.kbaNext = this.kbaNext.bind(this);
  }
  componentDidMount() {
    this.props.fetchKbaQuestion();
  }

  kbaNext() {
    this.props.fetchKbaQuestion({
      questionId: this.props.question.questionId,
      choice: this.state.value,
    });
    this.setState({
      value: '',
    });
  }

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    return (
      <div className="onboarding-container">
        <div className="onboarding-content">
          <div className="onboarding-header">
            {this.props.question.question}
          </div>
          <KBA
            question={this.props.question}
            handleChange={this.handleChange}
            value={this.state.value}
            loading={this.props.questionLoading}
          />
          <GenericStep
            nextStep={this.kbaNext}
            nextStepDisabled={!this.state.value}
            loading={this.props.questionLoading}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchKbaQuestion,
};

const mapStateToProps = (state) => ({
  question: _.get(state, 'kba'),
  questionLoading: createLoadingSelector(['KBA_QUESTION'])(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(KBAIndex);
