import React, { useState } from 'react';
import { Column, TableInstance } from '@tanstack/react-table';
import get from 'lodash/get';
import moment from 'moment';
import { Col, FilterContainer, Input, Label, StyledDateTime } from './styled';
import { ServerSideFilter } from '../types';
import FilterActions from './FilterActions';
import { TableFilterProps } from './TableFilter';

const parseInitialDate = (date: string): string => moment(date).format('L');

/** 
  @description Filters values according to a date range.
  If no lower/upper bound is set, it will filter by the only one provided
  @prop - column: column metadata which contains applied filter
  @prop - local:  change functionality if table is filtering locally
*/

const DateFilter = ({ column, local }: TableFilterProps<any>) => {
  const columnFilterValue = column.getFilterValue();
  // If there's any filter value applied, initialize dates with it
  let initialFrom = local
    ? get(columnFilterValue, '[0]', '')
    : get(columnFilterValue, '[0].value', '');
  // Format the value
  if (initialFrom) initialFrom = parseInitialDate(initialFrom);
  const [fromDate, setFromDate] = useState<string>(initialFrom);

  let initialTo = local
    ? get(columnFilterValue, '[1]', '')
    : get(columnFilterValue, '[1].value', '');

  if (initialTo) initialTo = parseInitialDate(initialTo);
  const [toDate, setToDate] = useState<string>(initialTo);

  const onApply = () => {
    const filters: ServerSideFilter[] = [];

    if (local) {
      // TO DO: Implement local Date filtering
      column.setFilterValue([
        ...(fromDate ? [moment(fromDate).toDate().toString()] : ['']),
        ...(toDate ? [moment(toDate).toDate().toString()] : ['']),
      ]);
    } else {
      // fromDate and toDate are strings which can be parsed as dates
      // so we pass the formated date value as a filter
      if (fromDate)
        filters.push({
          attr: column.id,
          value: moment(fromDate).toDate().toString(),
          op: 'gte',
        });
      if (toDate)
        filters.push({
          attr: column.id,
          value: moment(toDate).toDate().toString(),
          op: 'lte',
        });
      column.setFilterValue(filters);
    }
  };

  const onClear = () => {
    column.setFilterValue();
    setFromDate('');
    setToDate('');
  };

  return (
    <Col>
      <FilterContainer>
        <Label>From:</Label>
        <div>
          <StyledDateTime
            inputProps={{
              placeholder: 'Select a date',
              name: 'from-date',
              autoComplete: 'new-password',
            }}
            timeFormat={false}
            value={fromDate}
            // Due to a bug with date-time we must provide an input via renderInput
            // and manually force it to be cleaned when user presses 'Clean filter' button
            renderInput={(props) => (
              <div>
                <input {...props} value={fromDate || ''} />
              </div>
            )}
            onChange={(value) => {
              const date = moment(value);
              if (date.isValid()) {
                setFromDate(date.format('L'));
              }
            }}
            closeOnSelect
          />
        </div>
      </FilterContainer>
      <FilterContainer>
        <Label>To:</Label>
        <StyledDateTime
          inputProps={{
            placeholder: 'Select a date',
            name: 'to-date',
            autoComplete: 'new-password',
          }}
          timeFormat={false}
          value={toDate}
          renderInput={(props) => (
            <div>
              <input {...props} value={toDate || ''} />
            </div>
          )}
          onChange={(value) => {
            const date = moment(value);
            if (date.isValid()) {
              setToDate(date.format('L'));
            }
          }}
          closeOnSelect
        />
      </FilterContainer>
      <FilterActions onApply={onApply} onClear={onClear} />
    </Col>
  );
};

export default DateFilter;
