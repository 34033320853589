import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Divider, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import APISettings from '../../components/Profile/ApiSettings';
import {
  fetchApiCredentials,
  deleteApiCredentials,
  getApiCredentials,
  createApiCredentialsPromiseCreator,
} from '../../reducers/apiCredentialsReducer';
import { showModal, hideModal } from 'erisxkit/client';
import {
  CREATE_API_CREDENTIALS,
  CONFIRM_MODAL,
} from '../../constants/modalTypes';

const mapStateToProps = (state) => ({
  apiCredentials: getApiCredentials(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createApiCredentialsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      fetchApiCredentials,
      deleteApiCredentials,
      showModal,
      hideModal,
    },
    dispatch,
  ),
});
class APISettingsContainer extends Component {
  state = {};
  componentDidMount = () => {
    this.props.fetchApiCredentials();
  };

  handleChange = (e, obj) => {
    this.setState({ [obj.name]: obj.value });
  };

  deleteApiCredentials = ({ key, label, createdAt }) => {
    this.props.showModal(CONFIRM_MODAL, {
      headerText: 'Are you sure you want to delete this API key?',
      confirmProps: {
        content: 'Delete',
        negative: true,
        onClick: () => {
          this.props.deleteApiCredentials({ key });
          this.props.hideModal();
        },
      },
      rejectProps: {
        content: 'Cancel',
        secondary: true,
        onClick: this.props.hideModal,
      },
      content: (
        <Segment>
          <b>Label:</b> {label} <br />
          <b>Created At:</b> {createdAt}
        </Segment>
      ),
    });
  };

  createApiCredentials = () => {
    this.props.showModal(CREATE_API_CREDENTIALS);
  };

  render = () => (
    <section className="main-holdings">
      <Header as="h1">API Key Management</Header>
      <Header>
        View and create API credentials.
        <Header.Subheader
          content="To ensure the security of your account, your API Key and secret will only be shown at the time of creation.
          If you have forgotten your credentials, please create a new one."
        />
      </Header>
      <Divider />
      <APISettings
        createApiCredentials={this.createApiCredentials}
        handleChange={this.handleChange}
        label={this.state.label}
        apiCredentials={this.props.apiCredentials}
        deleteApiCredentials={this.deleteApiCredentials}
      />
    </section>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(APISettingsContainer);
