import { takeLatest } from 'redux-saga/effects';
import {
  fetchPositionAdjustments,
  submitPositionAdjustments,
  fetchPositionAdjustmentsMetadata,
} from '../reducers/positionsAdjustmentsReducer';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(
    fetchPositionAdjustments,
    generateSaga(fetchPositionAdjustments, { method: HTTPMethod.POST }),
  );
  yield takeLatest(
    fetchPositionAdjustmentsMetadata,
    generateSaga(fetchPositionAdjustmentsMetadata, { method: HTTPMethod.GET }),
  );
  yield takeLatest(
    submitPositionAdjustments,
    generateSaga(submitPositionAdjustments),
  );
}
