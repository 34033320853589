import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import { fetchTradingParticipantRiskLimits, updateTradingParticipantRiskLimits, createTradingParticipantRiskLimits } from '../reducers/tradingParticipantManagementReducer';

export default function* watch() {
  yield takeLatest(fetchTradingParticipantRiskLimits, generateSaga(fetchTradingParticipantRiskLimits));
  yield takeLatest(updateTradingParticipantRiskLimits, generateSaga(updateTradingParticipantRiskLimits, {
    loading: 'Submitting TradingParticipantRiskLimits',
    success: 'TradingParticipantRiskLimits request submitted successfully',
  }));
  yield takeLatest(createTradingParticipantRiskLimits, generateSaga(createTradingParticipantRiskLimits, {
    loading: 'Submitting TradingParticipantRiskLimits',
    success: 'TradingParticipantRiskLimits request submitted successfully',
  }));
}
