import { fork } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import fundTransfersSaga from './fundTransfersSaga';
import accountsSaga from './accountsSaga';
import achDepositsSaga from './achDepositsSaga';
import utilitiesSaga from './utilitiesSaga';
import assetTypesSaga from './assetTypesSaga';
import userSaga from './userSaga';
import kbaSaga from './kbaSaga';
import fileUploadSaga from './fileUploadSaga';
import linkedAccountsSaga from './linkedAccountsSaga';
import apiKeysSaga from './apiKeysSaga';
import contractsSaga from './contractsSaga';
import membersSaga from './membersSaga';
import orderEntrySaga from './orderEntrySaga';
import referralSaga from './referralSaga';
import authorizedUsersSaga from './authorizedUsersSaga';
import internalTransfersSaga from './internalTransfersSaga';
import authorizedIPsSaga from './authorizedIPsSaga';
import memberUserTypesSaga from './memberUserTypesSaga';
import carManagementSaga from './carManagementSaga';
import cgmManagementSaga from './cgmManagementSaga';
import tradesSaga from './tradesSaga';
import marginPositionsSaga from './marginPositionsSaga';
import deliveriesSaga from './deliveriesSaga';
import eStatementsSaga from './taxEStatementsSaga';
import positionAdjustmentsSaga from './positionAdjustmentsSaga';
import fcmBalancesSnapshotSaga from './fcmBalanceSnapshotSaga';
import fcmAccountMovementsSaga from './fcmAccountMovementsSaga';
import fcmClearingSaga from './fcmClearingSaga';
import participantManagementSaga from './participantManagementSaga';
import tradingParticipantRiskLimitsManagementSaga from './tradingParticipantRiskLimitsManagementSaga';
import permissionManagementSaga from './permissionManagementSaga';
import blockTradesSaga from './blockTradesSaga';
// Here, we register our watcher saga(s) and export as a single generator
// function (startForeman) as our root Saga.
export default function* startForeman() {
  yield fork(routinePromiseWatcherSaga);
  // Add "yield fork()" calls here with the other sagas
  yield fork(fundTransfersSaga);
  yield fork(accountsSaga);
  yield fork(utilitiesSaga);
  yield fork(assetTypesSaga);
  yield fork(userSaga);
  yield fork(kbaSaga);
  yield fork(fileUploadSaga);
  yield fork(linkedAccountsSaga);
  yield fork(apiKeysSaga);
  yield fork(contractsSaga);
  yield fork(membersSaga);
  yield fork(orderEntrySaga);
  yield fork(achDepositsSaga);
  yield fork(referralSaga);
  yield fork(authorizedUsersSaga);
  yield fork(internalTransfersSaga);
  yield fork(authorizedIPsSaga);
  yield fork(memberUserTypesSaga);
  yield fork(carManagementSaga);
  yield fork(cgmManagementSaga);
  yield fork(tradesSaga);
  yield fork(marginPositionsSaga);
  yield fork(eStatementsSaga);
  yield fork(deliveriesSaga);
  yield fork(positionAdjustmentsSaga);
  yield fork(fcmBalancesSnapshotSaga);
  yield fork(fcmAccountMovementsSaga);
  yield fork(fcmClearingSaga);
  yield fork(participantManagementSaga);
  yield fork(tradingParticipantRiskLimitsManagementSaga);
  yield fork(permissionManagementSaga);
  yield fork(blockTradesSaga);
}
