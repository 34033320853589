import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import SelectOption from '../ts/types/SelectOption.type';

/** Action Types */
export const SET_ACCOUNT_ESTATEMENT_STATUS = 'SET_ACCOUNT_ESTATEMENT_STATUS';
export const GET_ACCOUNT_ESTATEMENT_STATUS = 'GET_ACCOUNT_ESTATEMENT_STATUS';
export const GET_ALL_ACCOUNTS_ESTATEMENT_STATUS =
  'GET_ALL_ACCOUNTS_ESTATEMENT_STATUS';
export const AVAILABLE_TAX_ESTATEMENTS = 'AVAILABLE_TAX_ESTATEMENTS';
export const DOWNLOAD_TAX_ESTATEMENTS = 'DOWNLOAD_TAX_ESTATEMENTS';

/** Actions */
export const setEStatementsStatus = createRoutine(
  SET_ACCOUNT_ESTATEMENT_STATUS,
);
export const setEStatementStatusPromise =
  promisifyRoutine(setEStatementsStatus);
export const fetchEStatementsStatus = createRoutine(
  GET_ACCOUNT_ESTATEMENT_STATUS,
);
export const fetchAllAccountsEStatementsStatus = createRoutine(
  GET_ALL_ACCOUNTS_ESTATEMENT_STATUS,
);
export const fetchAvailableEStatements = createRoutine(
  AVAILABLE_TAX_ESTATEMENTS,
);
export const downloadStatements = createRoutine(DOWNLOAD_TAX_ESTATEMENTS);
export const downloadStatementsPromise = promisifyRoutine(downloadStatements);

/** Initial State */
type EStatementsState = {
  currentAccount?: boolean;
};

/** Response Types */
export type EStatementStatus = { accountId: string; taxEstatements: boolean };

type GetStatementStatusResponse = {
  taxEstatements: boolean;
};

type getAllAccountsStatementStatusResponse = {
  accounts: EStatementStatus[];
};

export type AvailableReports = { [key: number]: string[] };

type AvailableTaxStatementResponse = {
  availableTaxStatements: AvailableReports[];
};
const initialState: EStatementsState = {};

/** Reducer */
export default handleActions<EStatementsState, any>(
  {
    [fetchAvailableEStatements.SUCCESS]: (
      state: EStatementsState,
      { payload }: { payload: AvailableTaxStatementResponse },
    ) =>
      ({
        ...state,
        ...payload,
      }) as EStatementsState,
    [fetchEStatementsStatus.SUCCESS]: (
      state: EStatementsState,
      { payload }: { payload: GetStatementStatusResponse },
    ) =>
      ({
        ...state,
        currentAccount: payload.taxEstatements,
      }) as EStatementsState,
    [fetchAllAccountsEStatementsStatus.SUCCESS]: (
      state: EStatementsState,
      { payload }: { payload: getAllAccountsStatementStatusResponse },
    ) =>
      ({
        ...state,
        ...payload,
      }) as EStatementsState,
  },
  initialState,
);

//* Selectors */
export const getCurrentAccountHasEStatementsEnabled = (state: any): boolean =>
  get(state, 'taxEstatements.currentAccount', false);

export const getTaxStatements = (state: any): EStatementsState =>
  get(state, 'taxEstatements', {});

export const getAvailableTaxEStatements = createSelector(
  [getTaxStatements],
  // If there are no files BE returns an availableTaxStatements attribute set to null
  // so we default to an empty object
  (state) => get(state, 'availableTaxStatements') || [],
);

export const getAllAccountsEstatementStatus = createSelector(
  [getTaxStatements],
  (state) => get(state, 'accounts', []),
);

export const getAvailableTaxEStatementYears = createSelector(
  [getAvailableTaxEStatements],
  (taxEstatements) =>
    taxEstatements
      .reduce(
        (acc: AvailableReports[], curr: AvailableReports) => [
          ...acc,
          ...Object.keys(curr),
        ],
        [],
      )
      .map((year: string) => ({
        key: year,
        text: year,
        value: year,
      })) as SelectOption[],
);
