import { call, takeLatest, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { createSaga } from '../utils/createSaga';
import * as actions from '../reducers/linkedAccountsReducer';
import { hideModal } from 'erisxkit/client';
import api, * as urls from '../api';
import onPlaidError from '../utils/onPlaidError';

function* createLinkedBankAccount(payload) {
  try {
    const accounts = yield call(
      api.post,
      urls.CREATE_LINKED_BANK_ACCOUNT_API_ENDPOINT,
      payload,
    );
    yield put({
      type: actions.CREATE_LINKED_BANK_ACCOUNT_SUCCESS,
      payload: accounts,
    });
  } catch (e) {
    onPlaidError(e);
    yield put({
      type: actions.CREATE_LINKED_BANK_ACCOUNT_FAILED,
      payload: e.response,
    });
  }
}

function* deleteLinkedBankAccount(hashId) {
  try {
    const accounts = yield call(
      api.post,
      urls.DELETE_LINKED_BANK_ACCOUNT_API_ENDPOINT,
      hashId,
    );
    yield put({
      type: actions.DELETE_LINKED_BANK_ACCOUNT_SUCCESS,
      payload: accounts,
    });
  } catch (e) {
    onPlaidError(e);
    yield put({
      type: actions.DELETE_LINKED_BANK_ACCOUNT_FAILED,
      payload: e.response,
    });
  }
}

function* createLinkedCryptoAddress({ payload }) {
  const loadingCallback = cogoToast.loading('Adding your crypto address...', {
    hideAfter: 0,
  });
  try {
    const address = yield call(
      api.post,
      urls.CREATE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put({
      type: actions.CREATE_LINKED_CRYPTO_ADDRESS_SUCCESS,
      payload: address,
    });
    cogoToast.info(
      'Your new address has been submitted and is now pending approval.',
      { hideAfter: 7 },
    );
  } catch (e) {
    loadingCallback();
    yield put({
      type: actions.CREATE_LINKED_CRYPTO_ADDRESS_FAILED,
      payload: e.response,
    });
  }
}

function* deleteLinkedCryptoAddress() {
  try {
    const addresses = yield call(
      api.post,
      urls.DELETE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT,
    );
    yield put({
      type: actions.DELETE_LINKED_CRYPTO_ADDRESS_SUCCESS,
      payload: addresses,
    });
  } catch (e) {
    yield put({
      type: actions.DELETE_LINKED_CRYPTO_ADDRESS_FAILED,
      payload: e.response,
    });
  }
}

function* plaidVerifyAccount(arg) {
  try {
    const account = yield call(
      api.post,
      urls.PLAID_VERIFY_ACCOUNT_API_ENDPOINT,
      arg.payload,
    );
    if (account.state === 'approved') {
      cogoToast.success(
        'Your bank account has been added and linked to your account.',
        { heading: 'Bank Account Added!' },
      );
    } else if (account.state === 'pending') {
      cogoToast.info('Your account has been submitted for manual review.');
    } else {
      cogoToast.info(
        'Something went wrong, please contact support for more details.',
      );
    }
    yield put(hideModal());
    yield put({ type: actions.PLAID_VERIFY_ACCOUNT_SUCCESS, payload: account });
  } catch (e) {
    cogoToast.info('Failed to link account.');
    onPlaidError(e);
    yield put(hideModal());
    yield put({
      type: actions.PLAID_VERIFY_ACCOUNT_FAILED,
      payload: e.response,
    });
  }
}

function* lookupHashId({ payload }) {
  try {
    const hashId = yield call(
      api.post,
      urls.LOOKUP_HASH_ID_API_ENDPOINT,
      payload,
    );
    yield put(actions.lookupHashId.success(hashId));
  } catch (e) {
    yield put(actions.lookupHashId.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(
    actions.fetchLinkedMemberAssetAccounts.TRIGGER,
    createSaga(
      actions.fetchLinkedMemberAssetAccounts,
      actions.LINKED_MEMBER_ASSET_ACCOUNTS,
    ),
  );

  yield takeLatest(
    actions.CREATE_LINKED_BANK_ACCOUNT_REQUEST,
    createLinkedBankAccount,
  );
  yield takeLatest(
    actions.DELETE_LINKED_BANK_ACCOUNT_REQUEST,
    deleteLinkedBankAccount,
  );

  yield takeLatest(
    actions.CREATE_LINKED_CRYPTO_ADDRESS_REQUEST,
    createLinkedCryptoAddress,
  );
  yield takeLatest(
    actions.DELETE_LINKED_CRYPTO_ADDRESS_REQUEST,
    deleteLinkedCryptoAddress,
  );

  yield takeLatest(actions.PLAID_VERIFY_ACCOUNT_REQUEST, plaidVerifyAccount);
  yield takeLatest(actions.lookupHashId.TRIGGER, lookupHashId);
  yield takeLatest(
    actions.iraBankMsg.TRIGGER,
    createSaga(actions.iraBankMsg, actions.IRA_BANK_MSG),
  );
  yield takeLatest(
    actions.createLinkedMemberBankAccountIraMember.TRIGGER,
    createSaga(
      actions.createLinkedMemberBankAccountIraMember,
      actions.CREATE_LINKED_MEMBER_BANK_ACCOUNT_IRA_MEMBER,
    ),
  );
}
