import React from 'react';
import AccessControl from './AccessControl';
import Unauthorized from './Unauthorized';

/*
    AccessControlRoute is a wrapper on AccessControl pasing the renderUnauthorizedView prop.
    Used to validate permission on routes/views. 
*/
const AccessControlRoute = (props) => {
  return <AccessControl renderNoAccess={() => <Unauthorized />} {...props} />;
};

export default AccessControlRoute;
