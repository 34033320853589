import _ from 'lodash';

//* actions */
export const SELECT_FUND_TRANSFER = 'SELECT_FUND_TRANSFER';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const CALCULATE_RESPONSIVE_STATE =
  'redux-responsive/CALCULATE_RESPONSIVE_STATE';

//* Initial State */
const initialState = {
  transferType: 'deposit',
  asset: 'all',
};

//* Reducer */
export function selectFundTransferReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FUND_TRANSFER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const ORIENTATIONS = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};
//* Selectors */
export const getFundTransfer = (state) =>
  _.get(state.ui, 'fundTransfers', initialState);
export const getViewport = (state) => _.get(state.ui, 'viewport', {});
export const isViewportLessThan = (breakpoint, state) =>
  _.get(getViewport(state), ['lessThan', breakpoint], false);
export const getOrientation = (state) => {
  const vp = getViewport(state);
  return _.get(vp, 'orientation', 'landscape');
};
/**
 * Action generator to set a transfer method and type.
 */
export const selectFundTransfer = (payload) => ({
  type: SELECT_FUND_TRANSFER,
  payload,
});
