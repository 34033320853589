import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { errorReducer, loadingReducer, modalReducer } from 'erisxkit/client';
import { responsiveStateReducer } from 'redux-responsive';
import userReducer from './userReducer';
import membersReducer from './membersReducer';
import linkedAccountsReducer from './linkedAccountsReducer';
import fundTransfersReducer from './fundTransfersReducer';
import accountsReducer from './accountsReducer';
import utilitiesReducer from './utilitiesReducer';
import assetTypesReducer from './assetTypesReducer';
import accountHistoryReducer from './accountHistoryReducer';
import sidebarReducer from './sidebarReducer';
import { selectFundTransferReducer } from './uiReducer';
import kbaReducer from './kbaReducer';
import fileUploadReducer from './fileUploadReducer';
import {
  complianceQuestionsReducer,
  securityQuestionsReducer,
  futuresComplianceQuestionsReducer,
} from './complianceQuestionsReducer';
import balancesReducer, { simpleBalancesReducer } from './balancesReducer';
import apiCredentialReducer from './apiCredentialsReducer';
import contractsReducer from './contractsReducer';
import positions from './positionsReducer';
import closeoutJournals from './closeoutJournalsReducer';
import orderEntry from './orderEntryReducer';
import history from '../constants/history';
import ACHDepositsReducer from './ACHDepositsReducer';
import referrals from './referralCodeReducer';
import authorizedUsers from './authorizedUsersReducer';
import internalTransfersReducer from './internalTransfersReducer';
import authorizedIPsReducer from './authorizedIPsReducer';
import memberUserTypes from './memberUserTypesReducer';
import participantManagement from './participantManagementReducer';
import carManagement from './CARManagement/carManagementReducer';
import cgmManagement from './CGMManagement/cgmManagementReducer';
import tradesReducer from './tradesReducer';
import marginPositions from './marginPositionsReducer';
import taxEstatements from './taxEStatementsReducer';
import marginDeliveries from './deliveriesReducer';
import positionsAdjustments from './positionsAdjustmentsReducer';
import fcmBalances from './fcmBalanceSnapshotsReducer';
import fcmAccountMovements from './fcmAccountMovementsReducer';
import fcmClearing from './fcmClearingReducer';
import env from './envReducer';
import tradingParticipantManagement from './tradingParticipantManagementReducer';
import blockTrades from './BlockTrades/blockTradesReducer';

const rootReducer = combineReducers({
  ui: combineReducers({
    modal: modalReducer,
    sidebar: sidebarReducer,
    fundTransfers: selectFundTransferReducer,
    viewport: responsiveStateReducer,
  }),
  user: userReducer,
  linkedAccounts: linkedAccountsReducer,
  fundTransfers: fundTransfersReducer,
  router: connectRouter(history),
  accountList: accountsReducer,
  balances: simpleBalancesReducer,
  balanceDetails: balancesReducer,
  history: accountHistoryReducer,
  app: utilitiesReducer,
  assetTypes: assetTypesReducer,
  api: combineReducers({ loading: loadingReducer, error: errorReducer }),
  kba: kbaReducer,
  file: fileUploadReducer,
  form: formReducer,
  complianceQuestions: complianceQuestionsReducer,
  securityQuestions: securityQuestionsReducer,
  futuresComplianceQuestions: futuresComplianceQuestionsReducer,
  apiCredentials: apiCredentialReducer,
  contracts: contractsReducer,
  members: membersReducer,
  positions,
  closeoutJournals,
  orderEntry,
  achDeposits: ACHDepositsReducer,
  referrals,
  authorizedUsers,
  transfers: internalTransfersReducer,
  authorizedIPs: authorizedIPsReducer,
  memberUserTypes,
  participantManagement,
  carManagement,
  cgmManagement,
  trades: tradesReducer,
  marginPositions,
  taxEstatements,
  marginDeliveries,
  positionsAdjustments,
  fcmBalances,
  fcmAccountMovements,
  fcmClearing,
  env,
  tradingParticipantManagement,
  blockTrades,
});

export default rootReducer;
