import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import AccessControl from '../common/AccessControl';
import { TRADE_PERMISSIONS } from '../constants/userPermissions';

const tradingOptions = ({ tradeProps }) => [
  {
    key: 'adv_trade',
    icon: 'line graph',
    text: 'Advanced Trade',
    onClick: tradeProps.onClick,
  },
];

const TradeButton = styled(Dropdown).attrs({
  id: 'trade-actions-dropdown-button',
})`
  border-radius: 50px !important;
  width: 110px;
  height: 40px;
  display: flex !important;
  justify-content: space-evenly;
  font-size: 1.1em !important;
  background-color: ${({ theme }) => theme.button.primary.bgColor} !important;
  color: ${({ theme }) => theme.button.primary.color} !important;
`;

const DropdownText = styled.div`
  margin-left: 20px;
`;

const dropdownButtons = ({ tradeProps, dispatch }) => [
  <AccessControl allowedPermissions={TRADE_PERMISSIONS}>
    <TradeButton
      className="icon primary"
      text={<DropdownText>TRADE</DropdownText>}
      button
      options={tradingOptions({
        tradeProps,
        dispatch,
      })}
    />
  </AccessControl>,
];

const TradeActionsFCM = ({
  accounts = [],
  tradeProps = {},
  loading = false,
}: TradeActionsFCMProps) => {
  const dispatch = useDispatch();

  const buttons = dropdownButtons({
    tradeProps,
    dispatch,
  });

  return !loading && accounts.length > 0 ? (
    <Fragment>
      <div
        style={{
          display: 'flex',
          gridArea: 'dw',
          margin: '3px',
          justifyContent: 'space-evenly',
        }}
      >
        {buttons}
      </div>
    </Fragment>
  ) : null;
};

type TradeProps = {
  isExternal: boolean;
  onClick: Function;
};

type TradeActionsFCMProps = {
  accounts: any[];
  loading: boolean;
  tradeProps: TradeProps;
};

export default TradeActionsFCM;
