import React from 'react';
import Big from 'bignumber.js';
import { Icon } from 'semantic-ui-react';
import {
  status,
  getType,
  getTimeColumn,
} from '../common/metadata/commonFields';

const columns = (moreInfo) => [
  {
    Header: '',
    columns: [
      getTimeColumn(),
      {
        Header: 'Asset',
        id: 'asset',
        accessor: 'product',
        headerClassName: 'pull-left',
      },
      getType(),
      {
        Header: 'Amount',
        id: 'amount',
        accessor: 'amount',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Fee',
        id: 'otherFees',
        accessor: ({
          clearingFee = 0,
          exchangeFee = 0,
          otherFees = 0,
          feeType = '',
        }) =>
          `${Big(otherFees)
            .plus(Big(exchangeFee).plus(Big(clearingFee)))
            .toFixed()} ${feeType}`,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Total ',
        id: 'total',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          amount = 0,
          otherFees = 0,
          clearingFee = 0,
          exchangeFee = 0,
        }) =>
          Big(amount)
            .plus(Big(otherFees).plus(Big(exchangeFee).plus(Big(clearingFee))))
            .toFixed(),
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        headerClassName: 'pull-left',
      },
      status,
      {
        Header: '',
        id: 'information',
        accessor: (d) => (
          <Icon onClick={() => moreInfo(d)} link name="clipboard outline" />
        ),
        filterable: false,
        width: 35,
        resizable: false,
      },
    ],
  },
];

export default columns;
