import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import StyledButton from '../../common/StyledButton';
import { hideModal } from 'erisxkit/client';
import { getInvestmentMemberName } from '../../reducers/authorizedUsersReducer';
import EnvPromise from '../../config/env';

const Buttons = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Cancel = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Confirm = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  && {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  && {
    margin-bottom: 20px;
  }
`;

const NewDelegatedUserModal = ({ email, accountLabel, onConfirm }) => {
  const dispatch = useDispatch();
  const member = useSelector(getInvestmentMemberName);
  const [poaUrl, setPoaUrl] = useState('');

  useEffect(() => {
    EnvPromise.then((e) => setPoaUrl(e.poaDocumentUrl));
  }, []);

  return (
    <>
      <Modal.Content scrolling>
        <Title>NEW AUTHORIZED USER</Title>
        <SubTitle>
          {`You are attempting to add `} <b>{email}</b> {` from`}{' '}
          <b>{member}</b>
          {'; '}
          {` by clicking the "I ACCEPT" button is the legal equivalent of manually signing the`}
          <a href={poaUrl} target="_blank">
            {' '}
            linked{' '}
          </a>
          {`Power of Attorney`}
        </SubTitle>
      </Modal.Content>
      <Buttons>
        <Cancel onClick={() => dispatch(hideModal())} text="Cancel" />
        <Confirm primary onClick={onConfirm} text="I ACCEPT" />
      </Buttons>
    </>
  );
};

NewDelegatedUserModal.defaultProps = {
  accountLabel: '',
  email: '',
  onConfirm: () => {},
};

NewDelegatedUserModal.propTypes = {
  accountLabel: PropTypes.string,
  email: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default NewDelegatedUserModal;
