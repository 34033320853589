const MEMBER_USER_PERMISSION_NAMES = {
  REQUEST_WITHDRAWALS: 'request_withdrawals',
  READ_MOVEMENTS: 'read_movements',
  CLOSE_POSITIONS: 'close_positions',
  READ_TRANSACTIONS: 'read_transactions',
  READ_DAILY_STATEMENTS: 'read_daily_statements',
  DESTROY_LINKED_ASSET_ACCOUNT: 'destroy_linked_asset_account',
  READ_LINKED_ASSET_ACCOUNT: 'read_linked_asset_account',
  READ_TRADING_APPLICATION_DATA: 'read_trading_application_data',
  CREATE_LINKED_ASSET_ACCOUNT: 'create_linked_asset_account',
  SEND_DEPOSITS: 'send_deposits',
  READ_COLLATERAL_POSITIONS: 'read_collateral_positions',
  READ_FUTURES_POSITIONS: 'read_futures_positions',
  READ_BALANCES: 'read_balances',
  READ_TRADES: 'read_trades',
  READ_PARTICIPANT_ACCOUNT: 'read_participant_account',
  WRITE_PARTICIPANT_ACCOUNT: 'write_participant_account',
  REQUEST_FCM_CLEARING: 'request_fcm_clearing',
  MANAGE_BLOCK_TRADE_PERMISSIONS: 'manage_block_trade_permissions',
} as const;

export default MEMBER_USER_PERMISSION_NAMES;
