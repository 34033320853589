export const formatPhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const numbersOnly = (number) =>
  number ? number.replace(/[^\d]/g, '') : '';

// Allow fractional component to be represented with a decimal point
// Handles multiple periods correctly by only allowing the first one.
export const floatsOnly = (number) => {
  const scrubbed = number ? number.replace(/[^\d.]/g, '') : '';
  const [integer, ...fractional] = scrubbed.split('.');
  return `${integer}${fractional.length ? `.${fractional.join('')}` : ''}`;
};
