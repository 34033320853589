import React from 'react';
import { renderDateTime } from 'erisxkit/client';
import { DateFieldWrapper } from './styles';
import { Field, Validator } from 'redux-form';
import { renderFlatpickrDateTime } from '../../../common/forms/components';
import { FieldId } from './FormField';
import { required } from '../../../common/forms/rules';

const FormDatePicker = ({
  id,
  label,
  optional = false,
  validate = [],
}: {
  id: FieldId;
  label: string;
  optional?: boolean;
  validate?: Validator[];
}) => {
  return (
    <DateFieldWrapper className="field">
      <label>{label}</label>
      <Field
        component={renderDateTime}
        name={id}
        id={id}
        timeFormat={'MM/DD/YYYY h:mma'}
        closeOnSelect
        initialViewDate={Date.now()}
        placeholder="MM/DD/YYYY h:mma"
        validate={optional ? validate : [required, ...validate]}
        label={label}
        required
        minimal
        style={{ height: '38px' }}
      />
    </DateFieldWrapper>
  );
};

export default FormDatePicker;
