import styled, { css } from 'styled-components';
import { Accordion, Icon } from 'semantic-ui-react';
import { NavLink, withRouter } from 'react-router-dom';
import colors from '../../../constants/colors';
import React from 'react';

export const NavLinkWrapper = styled.div<{ isActiveLink: boolean }>`
  /* margin: 10px 0px 10px 15px; */

  background-color: ${({ theme }) => theme.sidenav.bg};

  ${({ isActiveLink }) =>
    isActiveLink &&
    css`
      background-color: #99f8e482;
    `};
  color: ${({ theme }) => theme.sidenav.color};
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
  padding-top: 3px;
  border-radius: 4px;
  min-height: 28px;
  display: flex;
  align-items: center;
`;

export const StyledNavLink = styled(NavLink)`
  font-size: 15px;
  box-shadow: none;
`;

export const HomeNavLink = styled(StyledNavLink)`
  font-size: 17px;

  &:hover {
    box-shadow: none;
  }
  .fcm-home-active {
    :hover {
      box-shadow: 0px 2px 0 0 ${({ theme }) => theme.sidenav.active};
    }
  }
`;

export const StyledAccordion = styled(Accordion)`
  ${StyledNavLink} {
    :hover {
      color: ${({ theme }) => theme.sidenav.color};
      box-shadow: 0px 2px 0 0 ${({ theme }) => theme.sidenav.color};
    }
  }
  .fcm-nav-link-active {
    font-weight: bold;
  }

  .content.active {
    margin: 0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .content.active > .accordion {
    margin: 0px;
  }
`;

export const StyledTitle = styled(Accordion.Title)<{ hasActiveLink: boolean }>`
  /* margin-top: 20px; */
  color: ${({ theme, hasActiveLink }) => hasActiveLink && theme.sidenav.active};
  display: flex;
`;
export const HomeTitle = styled(
  ({ isSelected, ...props }: { isSelected: boolean }) => (
    <Accordion.Title {...props} />
  ),
)`
  display: inline;
  border-bottom: none !important;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.sidenav.active : ''} !important;
  margin-bottom: 15px;
  :hover {
    box-shadow: 0px 2px 0 0
      ${({ theme, isSelected }) =>
        isSelected ? theme.sidenav.active : theme.sidenav.color};
  }
`;
HomeTitle.displayName = 'HomeLink';

export const HomeIcon = styled(({ isSelected, ...props }) => (
  <Icon {...props} />
))`
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.sidenav.active : colors.GRAY_1};
`;
HomeIcon.displayName = 'HomeIcon';

export const HomeWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  margin-left: 10px;
  margin-top: 15px;
`;
