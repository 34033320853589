import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from 'erisxkit/client';
import AcuantSelfieCapture from '../../common/AcuantSelfieCapture';
import {
  CONFIRM_PHOTO,
  SELFIE_RECOMENDATION,
} from '../../constants/modalTypes';

export const FacePhoto = ({
  nextStep,
  imagesState = {},
  goToStep,
  showModal,
  onConfirm,
  onReject,
  hideModal,
}) => {
  useEffect(() => {
    selfieRecomendations();
  }, []);

  const onConfirmFace = (image) => {
    onConfirm(image, 'face', nextStep);
  };

  const onCaptureFace = (image) => {
    showModal(CONFIRM_PHOTO, {
      type: 'face',
      image,
      onConfirm: onConfirmFace,
      onReject: hideModal,
    });
  };

  const onConfirmRecomendation = () => {
    hideModal();
  };

  const selfieRecomendations = () => {
    showModal(SELFIE_RECOMENDATION, {
      onConfirm: onConfirmRecomendation,
      dimmer: 'blurring',
    });
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding-content">
        <div className="onboarding-header" style={{ flexDirection: 'column' }}>
          Please take a selfie.
          <br />
          <small>For best results, align your face with the green oval.</small>
        </div>
        <AcuantSelfieCapture onCapture={onCaptureFace} orientation="portrait" />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  hideModal,
  showModal,
};

export default connect(null, mapDispatchToProps)(FacePhoto);
