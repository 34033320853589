import { get } from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { PositionAdjustments } from '../ts/models/Positions/PositionAdjustments';
import { createSelector } from 'reselect';

export const POSITION_ADJUSTMENTS = 'POSITION_ADJUSTMENTS';
export const SUBMIT_POSITION_ADJUSTMENTS = 'SUBMIT_POSITION_ADJUSTMENTS';
export const POSITION_ADJUSTMENTS_METADATA = 'POSITION_ADJUSTMENTS_METADATA';
/**
 * Routine to get positions adjustments.
 */
export const fetchPositionAdjustments = createRoutine(POSITION_ADJUSTMENTS);
export const submitPositionAdjustments = createRoutine(
  SUBMIT_POSITION_ADJUSTMENTS,
);
export const submitPositionAdjustmentsPromiseCreator = promisifyRoutine(
  submitPositionAdjustments,
);
export const fetchPositionAdjustmentsMetadata = createRoutine(
  POSITION_ADJUSTMENTS_METADATA,
);

export default handleActions<PositionAdjustments[], any>(
  {
    [fetchPositionAdjustments.SUCCESS]: (state, { payload }) => ({
      ...state,
      positionsAdjustments: payload,
    }),
    [fetchPositionAdjustmentsMetadata.SUCCESS]: (state, { payload }) => ({
      ...state,
      positionsAdjustmentsMetadata: payload,
    }),
  },
  [],
);

export const getPositionAdjustments = (state: any) =>
  get(state, ['positionsAdjustments', 'positionsAdjustments'], []);

export const getPositionAdjustmentsMetadata = (state: any) =>
  get(state, ['positionsAdjustments', 'positionsAdjustmentsMetadata'], {});

export const getManualAdjustmentStartTime = createSelector(
  [getPositionAdjustmentsMetadata],
  (metadata) => metadata.manualAdjStartTime,
);

export const getManualAdjustmentEndTime = createSelector(
  [getPositionAdjustmentsMetadata],
  (metadata) => metadata.manualAdjEndTime,
);

export const getMorningAfterManualAdjustmentStartTime = createSelector(
  [getPositionAdjustmentsMetadata],
  (metadata) => metadata.morningAfterManualAdjStartTime,
);

export const getMorningAfterManualAdjustmentEndTime = createSelector(
  [getPositionAdjustmentsMetadata],
  (metadata) => metadata.morningAfterManualAdjEndTime,
);
