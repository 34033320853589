import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Header, Popup, Icon } from 'semantic-ui-react';
import { Input, sizes } from '../../styles/styled';
import colors from '../../constants/colors';
import Big from 'bignumber.js';
import {
  fetchReferralCode,
  getReferralCode,
  getReferralCount,
  getCodesUsedAmount,
  getBonusBalance,
} from '../../reducers/referralCodeReducer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
`;
Container.displayName = 'Container';

const BonusBalance = styled.span`
  font-weight: bold;
  font-size: 19px;
`;
BonusBalance.displayName = 'BonusBalance';

const ReferralContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
`;
ReferralContainer.displayName = 'ReferralContainer';

const CodeLabel = styled.p`
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;
CodeLabel.displayName = 'CodeLabel';

const Row = styled.div`
  display: flex;
  align-items: stretch;
`;
Row.displayName = 'Row';

const CodeContainer = styled.div`
  margin-right: 10px;
  width: 50%;
  @media (max-width: ${sizes.MEDIUM}) {
    flex: 1;
  }
`;
CodeContainer.displayName = 'CodeContainer';

const Code = styled(Input)`
  height: 50px;
  font-size: 18px;
  text-align: center;
  width: 100%;
`;
Code.displayName = 'Code';

const Action = styled(Icon)`
  color: ${colors.WHITE};
  cursor: pointer;
  && {
    margin-right: 15px;
  }
`;

Action.displayName = 'Action';

const Actions = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-top: 10px;
  margin-left: 10px;
  @media (max-width: ${sizes.MEDIUM}) {
    flex: 0;
  }
`;
Actions.displayName = 'Actions';

const ActionLabel = styled.p`
  font-size: 12px;
  color: ${colors.NAVY_5};
  margin: 5px 0px;
  text-align: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;
ActionLabel.displayName = 'ActionLabel';

//Having a div that's between two divs with flex:1
//allows us to keep the middle div (CodeContainer) always centered
const LeftMargin = styled.div`
  flex: 1;
  @media (max-width: ${sizes.MEDIUM}) {
    flex: 0;
  }
`;

const ReferralInfo = styled.div`
  text-align: right;
  flex-grow: 1;
  align-self: flex-end;
  margin: 10px 0px 0px 0px;
  font-size: 17px;
`;
ReferralInfo.displayName = 'ReferralInfo';

const ReferralCode = () => {
  const [codeCopied, setCodeCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const dispatch = useDispatch();

  const referralCode = useSelector(getReferralCode);
  const referralCount = useSelector(getReferralCount);
  const codesUsed = useSelector(getCodesUsedAmount);
  const bonus = useSelector(getBonusBalance);

  useEffect(() => {
    dispatch(fetchReferralCode());
  }, []);

  const copyCode = () => {
    copyToClipboard(referralCode, () => {
      setLinkCopied(false);
      setCodeCopied(true);
    });
  };

  const copyLink = () => {
    const link = `${window.location.origin}/signup?referral=${referralCode}`;
    copyToClipboard(link, () => {
      setLinkCopied(true);
      setCodeCopied(false);
    });
  };

  const copyToClipboard = (text, onSuccess) => {
    navigator.clipboard.writeText(text).then(onSuccess());
  };

  const actionText = `${codeCopied ? 'Referral code' : 'Invitation link'} copied to clipboard!`;
  const showActionLabel = codeCopied || linkCopied;
  return (
    <Container>
      <ReferralContainer>
        <Row>
          <LeftMargin />
          <CodeContainer>
            <CodeLabel>Your Referral Code</CodeLabel>
            <Code value={referralCode} disabled />
            <ActionLabel show={showActionLabel}>{actionText}</ActionLabel>
          </CodeContainer>
          <Actions>
            <Popup
              trigger={
                <Icon
                  size="large"
                  name="copy"
                  onClick={copyCode}
                  className="referralCodeIcons"
                />
              }
              content="copy referral code"
            />
            <Popup
              trigger={
                <Icon
                  size="large"
                  name="linkify"
                  onClick={copyLink}
                  className="referralCodeIcons"
                />
              }
              content="copy referral link"
            />
          </Actions>
        </Row>
      </ReferralContainer>
      <ReferralInfo>
        {`Invitations sent: ${referralCount} | Codes used: ${codesUsed} | `}
        <BonusBalance>{`Earned Referrals: $ ${bonus}`}</BonusBalance>
      </ReferralInfo>
    </Container>
  );
};

export default ReferralCode;
