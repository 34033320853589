import React, { Component, Fragment } from 'react';
import Webcam from 'react-webcam';
import { datadogRum } from '@datadog/browser-rum';
import { Button, Message, Segment } from 'semantic-ui-react';
import colors from '../constants/colors';

const webcamTroubleShoot = ({ name }) => {
  switch (name) {
    case 'NotAllowedError':
      return [
        "It seems that you haven't given your browser permission to use the webcam.",
        "Click the camera blocked icon in your browser's address bar.",
      ];
    default:
      return [
        'Please make sure your webcam is connected and installed properly.',
      ];
  }
};

class WebcamCapture extends Component {
  state = {
    error: false,
    videoHeight: 0,
    videoWidth: 0,
    aspectRatio: 1,
  };
  setRef = (webcam) => {
    this.webcam = webcam;
  };

  capture = () => {
    const image = this.webcam.getScreenshot();
    this.props.onCapture(image);
  };

  render() {
    const videoConstraints = {
      facingMode: 'user',
    };

    let height = 600;
    let width = 600;
    if (this.props.orientation === 'portrait') {
      height = 350;
      width = 350;
    }

    const { error, videoHeight, videoWidth, aspectRatio } = this.state;
    const ovalStyle = {
      zIndex: 999,
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '0',
      right: '0',
      marginTop: '15px',
      height: videoHeight * 0.7,
      width: videoHeight / 1.9,
      borderRadius: '50%',
      border: `3px solid ${colors.GREEN}`,
      display: this.state.error ? 'none' : 'block',
    };

    return (
      <Fragment>
        {error && (
          <div id="webcam-error">
            <Message positive className="text-center">
              <Message.Header>
                Unfortunately we were not able to detect a camera.
              </Message.Header>
              <Message.List items={webcamTroubleShoot(error)} />
            </Message>
            <Segment>
              <p>You will need a camera to complete onboarding</p>
              <p>Are you able to continue from a mobile or another device?</p>
              <p>
                If not please contact{' '}
                <a href="mailto:digital.onboarding@cboe.com">
                  digital.onboarding@cboe.com
                </a>{' '}
                for assistance
              </p>
            </Segment>
          </div>
        )}
        <div
          key="webcam-stream-container"
          style={{
            height: videoHeight || 350,
            width: videoWidth || 350,
            alignSelf: 'center',
          }}
        >
          <img key="image-oval" id="oval" style={ovalStyle} />
          <Webcam
            key="webcam-stream"
            audio={false}
            height="100%"
            ref={this.setRef}
            screenshotFormat="image/jpeg"
            width="100%"
            screenshotWidth={800}
            onUserMedia={(s) => {
              const videoSettings = s.getVideoTracks()[0].getSettings();
              let videoHeight = videoSettings.height || 350;
              let videoWidth = videoSettings.width;
              // set default to .75 because safari on mobile doesn't support this
              const { aspectRatio = 0.75 } = videoSettings;
              // aspectRatio is width / height
              // if user has a portrait orientation
              if (aspectRatio < 1) {
                if (videoHeight > height) {
                  videoHeight = height;
                  videoWidth = height * aspectRatio;
                }
                // if width is equal to or > height
              } else if (videoWidth > width) {
                videoWidth = width;
                videoHeight = width / aspectRatio;
              }

              this.setState({
                error: false,
                videoHeight,
                videoWidth,
                aspectRatio,
              });
            }}
            onUserMediaError={(e) => {
              datadogRum.addAction('SelfieCaptureError', e);
              this.setState({
                error: e,
              });
            }}
            mirrored
            minScreenshotWidth={800}
            minScreenshotHeight={800}
            videoConstraints={videoConstraints}
            screenshotQuality={1}
          />
        </div>

        <Button
          primary
          disabled={this.state.error}
          onClick={this.capture}
          data-dd-action-name="Selfie Capture"
          data-cy={`ob-verification-capture-photo-button`}
        >
          CAPTURE PHOTO
        </Button>
      </Fragment>
    );
  }
}

export default WebcamCapture;
