import React from 'react';
import styled from 'styled-components';
import InformationTooltip from '../../common/InformationTooltip';

const Text = styled.p`
  margin-top: 10px;
  font-size: 14px;
`;
const Wrapper = styled.div`
  align-self: center;
  margin: 0px 5px;
`;

export const CarTypeInfo = () => {
  return (
    <Wrapper>
      <InformationTooltip
        content={
          <React.Fragment>
            <Text>
              <b>Net:</b> Long and Short positions will be netted down for
              Pre-Trade Risk check purposes.
            </Text>
            <Text>
              <b>Gross</b>: Long and Short positions will not be netted down for
              Pre-Trade Risk check purposes.
            </Text>
          </React.Fragment>
        }
      />
    </Wrapper>
  );
};

export const SelfMatchInfo = () => {
  return (
    <Wrapper>
      <InformationTooltip
        content={
          <React.Fragment>
            <Text>
              If Self Match Prevention is enabled, a buy and a sell order
              belonging to a given Customer Account Reference cannot match on
              the same contract. In that case, the resting order will be
              canceled and the new order will be accepted.
            </Text>
          </React.Fragment>
        }
      />
    </Wrapper>
  );
};

export const LongPositionInfo = () => {
  return (
    <Wrapper>
      <InformationTooltip
        content={
          <React.Fragment>
            <Text>
              Long position quantity - Short position quantity + Buy orders
              quantity computed per expiry and summed across all expiries for
              the selected product.
            </Text>
          </React.Fragment>
        }
      />
    </Wrapper>
  );
};

export const ShortPositionInfo = () => {
  return (
    <Wrapper>
      <InformationTooltip
        content={
          <React.Fragment>
            <Text>
              Short position quantity - Long position quantity + Sell orders
              quantity computed per expiry and summed across all expiries for
              the selected product.
            </Text>
          </React.Fragment>
        }
      />
    </Wrapper>
  );
};
