import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import TextButton from '../common/TextButton';
import styled from 'styled-components';
import { BlockTradeRequestModel } from '../ts/models/BlockTrades/BlockTradeRequest.model';

const columnHelper = createColumnHelper<BlockTradeRequestModel>();

const StyledSpan = styled.span`
  font-size: 19px;
  color: rgb(224, 224, 224);
`;

export const blockTradeRequestsMetadataHistory = () =>
  [
    columnHelper.accessor('state', {
      id: 'state',
      header: 'Status',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('product', {
      id: 'product',
      header: 'Product',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('contractSymbol', {
      id: 'contractSymbol',
      header: 'Contract Symbol',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: 'Quantity',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: 'Price',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('tradeDate', {
      id: 'tradeDate',
      header: 'Trade Date',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('validator', {
      id: 'validator',
      header: 'Validator',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('buySide.accountLabel', {
      id: 'buySide.accountLabel',
      header: 'Buy Account Label',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('buySide.clOrdId', {
      id: 'buySide.clOrdId',
      header: 'Buy ClOrdId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('buySide.senderSubId', {
      id: 'buySide.senderSubId',
      header: 'Buy Sender SubId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    
    columnHelper.accessor('sellSide.accountLabel', {
      id: 'sellSide.accountLabel',
      header: 'Sell Account Label',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('sellSide.clOrdId', {
      id: 'sellSide.clOrdId',
      header: 'Sell ClOrdId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('sellSide.senderSubId', {
      id: 'sellSide.senderSubId',
      header: 'Sell Sender SubId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('reportingSide', {
      id: 'reportingSide',
      header: 'Reporting Side',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('blockTradeId', {
      id: 'blockTradeId',
      header: 'Block Trade ID',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
  ] as ColumnDef<BlockTradeRequestModel>[];

export default blockTradeRequestsMetadataHistory;
