import {
  DeliveryLongShort,
  DeliverySatisfied,
} from '../../ts/models/Deliveries/Delivery.model';
import { ColorMap } from '../../ts/types/Color.type';

export const longShortColorMap: ColorMap<DeliveryLongShort> = {
  long: '#CFE2F3',
  short: '#F4CCCC',
};

export const satisfiedColorMap: ColorMap<DeliverySatisfied> = {
  N: '#F4CCCC',
  Y: '#D9EAD3',
};
