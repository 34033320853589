import * as React from 'react';
import { render } from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import AppContainer from './containers/AppContainer';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import history from './constants/history';

if (module.hot) {
  module.hot.accept(() => {
    if (module.hot.data?.hmrDisposePath) {
      history.push(module.hot.data.hmrDisposePath);
    }
  });
  module.hot.dispose((data) => {
    data.hmrDisposePath = window.location.pathname;
  });
}

const root = document.getElementById('root');

export const store = configureStore();

const renderApp = (App, store) => {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    root,
  );
};

renderApp(AppContainer, store);

// todo: enable hot reload without losing redux store in development
// todo: which will be seamless if we use webpack
