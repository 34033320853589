import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import Big, { ROUND_DOWN } from 'bignumber.js';
import { format, tradingApplications, bigSumFixed } from 'erisxkit/client';
import * as journalTypes from '../constants/journalTypes';
import { ALLOWED_CRYPTOS_FOR_FUTURES } from '../constants/allowedCryptosForFutures';
import {
  accountIsAllowedFutures,
  filterFundsDesignationByPermissions,
} from './permissions';
import { ONBOARDED_STATES } from '../constants/userStates';
import { PRECISION_USD } from '../constants/precision';
import { trackEvent } from '../common/tracking';

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

// given a File object, it returns a promise that resolves into a base64 string for that file.
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });

export const iconColorForState = (state) =>
  ({
    posted: 'green',
    pending: 'yellow',
    rejected: 'red',
  })[state];

export const iconForType = (type = '') => {
  if (typeof type !== 'string') {
    return '';
  }
  switch (type.toLowerCase()) {
    case journalTypes.DEPOSIT:
      return 'down arrow';
    case journalTypes.WITHDRAWAL:
      return 'up arrow';
    case 'buy':
    case 'sell':
      return 'exchange';
    default:
      return '';
  }
};

// takes given funds designations and splices them into asset type options.
export const mapAssetTypesToFundsDesignationsOptions = (
  assetTypes,
  authFundsDesignations = [],
) => {
  const mappedOptions = [];

  // For each authorized funds designation, take the product of it and each asset type.
  // Append a nice header to the top of each funds designation-typed asset.
  authFundsDesignations.forEach(({ text, value }) => {
    if (mappedOptions.length) {
      mappedOptions.push({
        as: () => <Dropdown.Divider />,
        value: '',
        key: `${value}/breaking-divider`,
      });
    }

    mappedOptions.push(
      {
        as: () => <Dropdown.Header content={text} />,
        value: '',
        key: `${value}/header`,
      },
      { as: () => <Dropdown.Divider />, value: '', key: `${value}/divider` },
    );

    assetTypes.forEach((asset) => {
      mappedOptions.push({
        description: asset.description,
        key: `${asset.symbol}/${value}`,
        text: `${asset.symbol} - ${text}`,
        value: `${asset.symbol}/${value}`,
        fundsdesignation: value,
      });
    });
  });

  return mappedOptions;
};

// takes props from funds designation forms, which include balances, asset types, and UI views,
//  then filters asset types by balances. If futures are enabled, map the remainder to
//  funds designations.
export const formatAssetTypesForFundsTransfer = ({
  balances = {},
  assetTypes = [],
  skipBalancesCheck,
  ...rest
}) => {
  // check flag to skip balances check; if not, filter existing assetTypes by current balances.
  // noBalancesCheck is used for deposits, which don't depend on the above condition.
  const assetTypesWithBalances = Object.keys(balances);

  let currencyOptions = assetTypes;

  // filter out crypto currencies not offered to futures accounts
  if (accountIsAllowedFutures(rest)) {
    currencyOptions = currencyOptions.filter(
      ({ symbol }) =>
        symbol.toLowerCase() == 'usd' ||
        ALLOWED_CRYPTOS_FOR_FUTURES.some(
          (assetSymbol) => symbol.toLowerCase() == assetSymbol,
        ),
    );
  }
  // filter out currencies failing the balance check
  if (!skipBalancesCheck) {
    currencyOptions = currencyOptions.filter(
      ({ symbol }) =>
        assetTypesWithBalances.indexOf(symbol.toLowerCase()) !== -1,
    );
  }

  // if account is allowed futures and we have options, map them to funds designations
  if (accountIsAllowedFutures(rest) && !_.isEmpty(currencyOptions)) {
    const authFundsDesignations = filterFundsDesignationByPermissions(rest);
    currencyOptions = mapAssetTypesToFundsDesignationsOptions(
      currencyOptions,
      authFundsDesignations,
    );
  } else {
    currencyOptions = currencyOptions.map((assetType) => ({
      key: assetType.symbol.toUpperCase(),
      value: assetType.symbol.toUpperCase(),
      text: assetType.symbol.toUpperCase(),
      description: assetType.description,
    }));
  }

  return currencyOptions;
};

// shorten our format calls for fiat, since it takes a lot of options
export const formatFiat = (str, currency) => {
  let formatted = str;
  try {
    formatted = format(str, { type: 'fiat', currency, truncate: 2 });
  } catch (error) {
    // in case an unsupported currency is passed in
    formatted = format(str);
  }
  return formatted;
};

export const formatCrypto = (str, currency, truncate = 18) => {
  let formatted = str;
  try {
    formatted = `${currency ? currency + ' ' : ''}${format(str, {
      type: 'crypto',
      truncate: truncate,
    })}`;
  } catch (error) {
    // in case an unsupported currency is passed in
    formatted = format(str);
  }
  return formatted;
};

// helper method to check if a given trading application uses SSO
export const usesSSO = (tradingApp) =>
  _.chain(tradingApplications)
    .find({ value: tradingApp })
    .get('useSSO')
    .value();

export const isCqg = (tradingApp) => tradingApp === 'cqg';

export const isOnboarding = (userState) =>
  !_.isEmpty(userState) && !ONBOARDED_STATES.includes(userState);

export const sumObj = (obj, obj2, keysToSkip) => {
  const sum = { ...obj };
  Object.keys(obj2).forEach((k) => {
    if (!keysToSkip.includes(k) && !_.isObject(obj2[k])) {
      sum[k] = bigSumFixed([sum[k] || 0, obj2[k]]);
    } else {
      sum[k] = obj2[k];
    }
  });
  return sum;
};

// divide first value * -1  by abs(second,) and multiply by 100
// round the resulting number up, to two decimal places
// accounts for negative fees.
export const calculateBps = (val0 = 0, val1 = 0) => {
  // round to two decimal places
  const DECIMAL_PLACES = 2;
  // always rounds up
  const ROUNDING_METHOD = 0;
  const bps = Big(val0).times(-1).dividedBy(Big(val1).abs()).times(100);
  return bps.isNaN() ? '' : bps.toFixed(DECIMAL_PLACES, ROUNDING_METHOD);
};

// have to do the `|| 0` inside Big to account for null and NaN
export const calculateUsdValue = ({
  closingBalance = '0',
  lastPrice = '0',
} = {}) =>
  Big(closingBalance || '0')
    .times(Big(lastPrice || '0'))
    .toFixed(PRECISION_USD, ROUND_DOWN);

// "Instant" pay methods now mean some of the historical requests returned as "pending" should
// actually be presented to the customer as "posting", and therefore ought to be excluded from the
// Recent Transactions and Pending Asset Movements tables, else they will seem to appear twice.
export const expungePostingRequestHistory = (payload) => {
  if (!_.isEmpty(payload)) {
    payload.history = payload.history.filter((h) => h.state !== 'posting');
    payload.count = payload.history.length;
  }
  return payload;
};

export const expungePostingRecentTransactions = (payload) => {
  if (!_.isEmpty(payload)) {
    payload = payload.filter((h) => h.state !== 'posting');
  }
  return payload;
};

export const daysOfMonth = () => {
  const limit = 28;
  const arr = [];
  for (let i = 1; i <= limit; i++) {
    arr.push({
      text: i.toString(),
      value: i,
    });
  }
  return arr;
};

export const mapDepositFrequencyToArrayValueText = (obj) =>
  Object.entries(obj).map((e) => ({ value: e[1].value, text: e[1].text }));

export const removeAllWhiteSpaces = (str) => str.replace(/\s/g, '');

export const formatBigPositiveNegative = (_number, decimals) => {
  return _number.isPositive()
    ? '+' + _number.toFixed(decimals)
    : _number.toFixed(decimals);
};

export const relDiff = (current, previous) => {
  if (
    isNaN(+current) ||
    isNaN(+previous) ||
    previous === 0 ||
    previous === '0'
  ) {
    return null;
  }
  return current - previous === 0
    ? 0
    : (100 * (current - previous)) / previous || 0;
};

export const needMoreThanTwoDecimals = (amount) => {
  return +amount > 0 && +amount < 0.01;
};

export const redirectToCapitalise = (
  auth,
  capitaliseFrontendEndpoint,
  capitaliseClientId,
) => {
  auth.universalAuth(capitaliseFrontendEndpoint, capitaliseClientId);
};

export const trade = (
  auth,
  category,
  action,
  defaultTradingApp,
  tradingFrontendEndpoint,
  clientId,
) => {
  trackEvent(category, action, '', null);
  if (usesSSO(defaultTradingApp)) {
    redirectToCapitalise(auth, tradingFrontendEndpoint, clientId);
  } else {
    window.open(tradingFrontendEndpoint, '_blank');
  }
};
