import React from 'react';
import get from 'lodash/get';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import FCMBalanceSnapshot from '../ts/models/FCMBalanceSnapshot/FCMBalanceSnapshot.model';
import { formatFiat } from '../utils/methods';
import { formatAccountingValue } from '../utils/formatters';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';

const accountDesignationOption = [
  {
    key: 1,
    value: 'Customer',
  },
  {
    key: 2,
    value: 'House',
  },
];

const columnHelper = createColumnHelper<FCMBalanceSnapshot>();

const accountDesignationValue = (accountDesignationKey: number) => {
  return (
    accountDesignationOption.find((x) => x.key === accountDesignationKey)
      ?.value || ''
  );
};

const fcmBalanceSnapshotsMetadata = [
  columnHelper.accessor('accountLabel', {
    id: 'accountLabel',
    header: 'Account',
    minSize: 100,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor(
    (row) => accountDesignationValue(get(row, 'accountDesignation', '')),
    {
      id: 'accountDesignation',
      header: 'Origin',
      meta: {
        filterType: TableFilterType.String,
      },
    },
  ),
  columnHelper.accessor('accountName', {
    id: 'accountName',
    header: 'Account Name',
    minSize: 120,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.totalAssets, 'USD'), {
    id: 'totalAssets',
    header: 'Total Assets',
    minSize: 100,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.totalRequirement, 'USD'), {
    id: 'totalRequirement',
    header: 'Total Requirement',
    minSize: 125,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.totalExcessDeficit, 'USD'), {
    id: 'totalExcess',
    header: 'Total Excess/Deficit',
    minSize: 125,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
] as ColumnDef<FCMBalanceSnapshot>[];

export default fcmBalanceSnapshotsMetadata;
