export const FOOTER_HEIGHT = '100px';
export const FOOTER_MARGIN = '1rem';
export const ONBOARDING_PAPER_MAX_WIDTH = '800px';

// todo: move to colors file
export const LIGHT_NAVY = '#205080';
export const MEDIUM_NAVY = '#0c375a';
export const DARK_NAVY = '#002c46';
export const LIGHT_GRAY = '#9696a0';
export const GREEN = '#00ebb9';
export const WHITE = '#fff';
