import _ from 'lodash';
import React, { useState, Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { StyledNav } from '../../containers/VerticalNavContainer';
import MarginFuturesLinks from './MarginFuturesLinks';

const VerticalNavMarginFutures = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    !props.viewportLessThanMedium,
  );

  useEffect(() => {
    setSidebarOpen(!props.viewportLessThanMedium);
  }, [props.viewportLessThanMedium]);

  const getActiveAccordion = (path: string): boolean =>
    _.get(props, ['location', 'pathname'], '').includes(path);

  return (
    <StyledNav
      id="vertical-nav"
      className={`${sidebarOpen ? 'open' : 'closed'}${
        getActiveAccordion('/contact') ? ' secondary-active' : ''
      }`}
    >
      <section>
        {sidebarOpen ? (
          <Fragment>
            <div className="topbar-link logo-container centered-content">
              <div
                className="logo fcm-logo"
                alt="ErisX"
                onClick={() => {
                  props.logoClick(sidebarOpen);
                }}
              />
            </div>
          </Fragment>
        ) : null}
        <MarginFuturesLinks />
      </section>
    </StyledNav>
  );
};

export default withRouter(VerticalNavMarginFutures);
