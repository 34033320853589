import React from 'react';
import styled from 'styled-components';
import Page from '../../common/Page';
import AuthorizedIPsHistory from './AuthorizedIPsHistory';
import AuthorizedIPsList from './AuthorizedIPsList';

const IPList = styled(AuthorizedIPsList)`
  margin-bottom: 40px;
`;

const AuthorizedIPs = () => (
  <Page header="Authorized IP Access" backButton>
    <IPList />
    <AuthorizedIPsHistory />
  </Page>
);

export default AuthorizedIPs;
