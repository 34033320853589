import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { hideModal } from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Step, Icon } from 'semantic-ui-react';
import {
  fetchFuturesComplianceQuestions,
  getFuturesComplianceQuestions,
  submitFuturesComplianceQuestions,
} from '../../../reducers/complianceQuestionsReducer';
import { Question } from '../ComplianceQuestions';
import StyledButton from '../../../common/StyledButton';
import { FUTURES_ONBOARDING } from '../../../constants/modalTypes';

const Header = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px !important;
  margin-bottom: 0px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 15px !important;
`;
Text.displayName = 'Text';

const Button = styled(StyledButton)`
  margin: 20px 10px;
`;
Button.displayName = 'Submit';

const FuturesQuestion = styled(Question)`
  margin-bottom: 15px;
`;
FuturesQuestion.displayName = 'FuturesQuestion';

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FuturesComplianceQuestions = ({
  valid,
  nextStep,
  initialStep,
  goToStep,
}) => {
  const questions = useSelector(getFuturesComplianceQuestions);
  const answers = useSelector((state) =>
    get(state, 'form.futuresComplianceQuestions.values', {}),
  );
  const dispatch = useDispatch();
  const onSubmit = () => {
    const payload = Object.keys(answers).map((key) => {
      const match = /question_id_([0-9]*)/.exec(key);
      return {
        questionId: `${match[1]}f`,
        choice: answers[key],
      };
    });
    dispatch(submitFuturesComplianceQuestions(payload));
    nextStep();
  };

  useEffect(() => {
    dispatch(fetchFuturesComplianceQuestions());
  }, []);

  return (
    <div id="futures-compliance-questions">
      <Header>Setting up your Futures Account</Header>
      <Text>The following questions are required by our US regulators.</Text>
      {questions && questions.length > 0 ? (
        <>
          {questions.map((question) => (
            <FuturesQuestion
              key={question.questionId}
              question={question}
              answer={get(answers, `question_id_${question.questionId}`, '')}
            />
          ))}
        </>
      ) : (
        'Loading questions...'
      )}
      <Buttons>
        <Button
          secondary
          text="Not at this time"
          onClick={() => dispatch(hideModal(FUTURES_ONBOARDING))}
        />
        <Button
          primary
          text="Submit and Continue"
          onClick={() => onSubmit()}
          disabled={isEmpty(questions) || !valid}
        />
      </Buttons>
    </div>
  );
};

export default reduxForm({
  form: 'futuresComplianceQuestions',
})(FuturesComplianceQuestions);
