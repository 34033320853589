import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import colors from '../constants/colors';

interface IconProps {
  hidden: boolean;
}
const StyledIcon = styled(Icon)<IconProps>`
  color: ${({ theme }) => theme.table.accent};
  cursor: pointer;
  display: ${({ hidden }) => (hidden ? 'none' : '')} !important;
`;

type Props = {
  isExpanded: boolean;
  toggle: Function;
  hidden?: boolean;
};

const ToggleExpand = ({ isExpanded, toggle, hidden = false }: Props) => (
  <StyledIcon
    name={`triangle ${isExpanded ? 'down' : 'right'}`}
    onClick={toggle}
    hidden={hidden}
  />
);

export default ToggleExpand;
