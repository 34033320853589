import React, { useState } from 'react';
import get from 'lodash/get';
import { Column } from '@tanstack/react-table';
import { Icon } from 'semantic-ui-react';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';
import NumberFilter from './NumberFilter';
import SortActions from './SortActions';
import TableFilterType from '../../../ts/enums/TableFilterType.enum';
import TableFilterOp from '../../../ts/enums/TableFilterOp.enum';
import StyledPopup from '../../../components/StyledPopup';

export type TableFilterProps<TGenerics> = {
  column: Column<TGenerics> & { meta: { filterType: string | undefined } }; // Tell TS the Column type has meta key
  local?: boolean;
  filterOp?: TableFilterOp;
  type?: string;
};

const getFilter = (
  column: Column<any> & { meta: { filterType: string | undefined } },
): TableFilterType => get(column, 'columnDef.meta.filterType', null);

const getFilterOp = (column: Column<any>): TableFilterOp =>
  get(column, 'columnDef.meta.filterOp', TableFilterOp.Contains);

/** 
  @description Generic EMPTable8 filter component. Renders a filter component according to column's filterType
  @prop - column: column instance for the table. Must have a meta attribute with filterType set
  @prop - local: defines if the filters will work locally or server-side.
*/
const TableFilter = <TGenerics,>({
  column,
  local,
}: TableFilterProps<TGenerics>) => {
  // Initialize filter as an empty element
  let filter = <div />;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Get the filter type from column's metadata
  const type: TableFilterType = getFilter(column);
  const filterOp: TableFilterOp = getFilterOp(column);
  let validFilterType = true;

  // Get corresponding filter component according to type
  switch (type) {
    case TableFilterType.Date:
      filter = <DateFilter column={column} local={local} />;
      break;
    case TableFilterType.String:
      filter = <TextFilter column={column} local={local} filterOp={filterOp} />;
      break;
    case TableFilterType.Number:
      filter = <NumberFilter column={column} local={local} />;
      break;
    case TableFilterType.Currency:
      filter = (
        <NumberFilter
          column={column}
          local={local}
          type={TableFilterType.Currency}
        />
      );
      break;
    default:
      validFilterType = false;
      break;
  }

  return validFilterType ? (
    <>
      <Icon name="filter" size="small" onClick={handleClick} />
      <StyledPopup
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        active={column.getIsFiltered()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <>
          <SortActions column={column} />
          {filter}
        </>
      </StyledPopup>
    </>
  ) : null;
};

export default TableFilter;
