import React from 'react';
import styled from 'styled-components';
import MemberFilesContainer from '../containers/MemberFilesContainer';
import DailyStatementsContainer from '../containers/DailyStatementsContainer';
import AccessControl from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import TaxStatements from './TaxEStatements/TaxStatements';
import { getUserHasEStatements } from '../reducers/userReducer';
import { sizes } from '../styles/styled';
import Page from '../common/Page';
import useIsFCM, { useIsNotFCM } from '../hooks/useIsFCM';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: min-content min-content;
  @media (max-width: ${sizes.MEDIUM}) {
    grid-template-columns: 100%;
    grid-template-rows: min-content;
  }
`;

const Reports = () => {
  const isFCM = useIsFCM();
  return (
    <Page header="Reports / Statements">
      <Grid>
        <MemberFilesContainer />
        {!isFCM && (
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.DAILY_STATEMENTS}`]}
          >
            <DailyStatementsContainer />
          </AccessControl>
        )}
        <AccessControl
          customPermissionSelector={getUserHasEStatements}
          customPermission={useIsNotFCM}
        >
          <TaxStatements />
        </AccessControl>
      </Grid>
    </Page>
  );
};

export default Reports;
