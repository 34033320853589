import decimalPlacesOf from './decimalPlacesOf';

export const ellipseDecimalsAfterNDigits = (numberString, digitsToKeep) => {
  if (!numberString) return null;
  const decimalDigitAmount = decimalPlacesOf(numberString);
  if (decimalDigitAmount <= digitsToKeep) return numberString;
  return `${numberString.substring(
    0,
    numberString.indexOf('.') + digitsToKeep + 1,
  )}...`;
};
