import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { Input } from '../../styles/styled';
import StyledButton from '../../common/StyledButton';
import { Icon } from 'semantic-ui-react';
import { sizes, Separator } from '../../styles/styled';
import * as rules from '../../common/forms/rules';
import {
  sendReferralCode,
  getPromotionReferrals,
  getReferralStats,
  getReferralCode,
} from '../../reducers/referralCodeReducer';
import { getCurrentUserId } from '../../reducers/userReducer';
import { Field, reduxForm, getFormValues, isInvalid, reset } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { renderField } from '../../common/forms/components';
import { get } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px 0px 10px;
`;
Container.displayName = 'Container';

const EmailHeader = styled.p`
  font-size: 18px;
  color: ${colors.GRAY_2};
  font-weight: bold;
  margin-bottom: 0px;
`;
EmailHeader.displayName = 'EmailHeader';

const EmailSubHeader = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_2};
  margin-bottom: 0px;
`;
EmailSubHeader.displayName = 'EmailSubHeader';

const Email = styled(Input)`
  height: 45px;
  width: 40%;
  max-width: 600px;
  margin-right: 20px;
  color: ${colors.GRAY_2};
  @media (max-width: ${sizes.MEDIUM}) {
    flex: 1;
  }
  border: ${(props) => props.error && `1px solid ${colors.RED_ERROR}`};
`;
Email.displayName = 'Email';

const Send = styled(StyledButton)`
  width: 150px;
  font-size: 18px;
  @media (max-width: ${sizes.LARGEST_MOBILE_SCREEN}) {
    width: 90px;
  }
`;
Send.displayName = 'Send';

const Inputs = styled.div`
  display: flex;
  margin: 20px 0px 10px 0px;
  align-items: flex-end;
  & > :nth-child(1) {
    margin-right: 15px;
  }
`;
Inputs.displayName = 'Inputs';

const ListHeaders = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
ListHeaders.displayName = 'ListHeaders';

const EmailAddress = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_2};
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 0px;
`;
EmailAddress.displayName = 'EmailAddress';

const Status = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_2};
  width: 25%;
  margin-bottom: 0px;
  text-transform: capitalize;
`;
Status.displayName = 'Status';

const Earned = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_2};
  width: 25%;
  text-align: right;
  margin-bottom: 0px;
`;
Earned.displayName = 'Earned';

const ListEntry = styled.div`
  display: flex;
  margin: 10px 0px;
`;
ListEntry.displayName = 'ListEntry';

const Error = styled.p`
  color: ${colors.RED_ERROR};
  font-size: 12px;
`;
Error.displayName = 'Error';

const Row = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 15px;
  && > :nth-child(1) {
    flex: 1;
    margin-right: 15px;
    max-width: 500px;
  }
`;
Row.displayName = 'Row';

const EmailListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
  margin-top: 15px;
  @media (max-width: ${sizes.MEDIUM}) {
    width: 100%;
  }
`;
EmailListWrapper.displayName = 'EmailListWrapper';

const ListWrapper = styled.div`
    width: 100%
    max-height: 200px;
  overflow: auto;
`;

const EmailList = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0px 0px 15px 0px;
  @media (max-width: ${sizes.MEDIUM}) {
    width: 100%;
  }
`;
EmailList.displayName = 'EmailList';

const SendEmail = () => {
  const dispatch = useDispatch();
  const formValues = useSelector((state) =>
    getFormValues('inviteFriends')(state),
  );
  const referralStats = useSelector(getReferralStats);
  const referralCode = useSelector(getReferralCode);
  const recipients = get(formValues, 'recipients', '');
  const valuesAreInvalid = useSelector((state) =>
    isInvalid('inviteFriends')(state),
  );

  const icon = <Icon name="mail icon" />;

  const onSend = () => {
    if (valuesAreInvalid) return;
    const emails = recipients
      .replaceAll(' ', '')
      .split(',')
      .filter((e) => e);
    emails.forEach((recipient) =>
      dispatch(
        sendReferralCode({
          recipient,
          contactProperties: {
            referralLink: `${window.location.origin}/signup?referral=${referralCode}`,
          },
        }),
      ),
    );
    dispatch(reset('inviteFriends'));
  };

  useEffect(() => {
    dispatch(getPromotionReferrals());
  }, []);

  return (
    <Container>
      <EmailHeader>Email</EmailHeader>
      <EmailSubHeader>
        You can enter multiple e-mail addresses separated by comma
      </EmailSubHeader>
      <Form onSubmit={onSend}>
        <Row>
          <Field
            component={renderField}
            name="recipients"
            placeholder=""
            required
            validate={[rules.required, rules.emailList]}
          />
          <Form.Button
            type="submit"
            secondary
            disabled={!recipients}
            content={
              <>
                {icon}
                <span>Send</span>
              </>
            }
            style={{ background: 'none' }}
          />
        </Row>
      </Form>
      {referralStats && referralStats.length > 0 && (
        <>
          <EmailListWrapper>
            <ListHeaders>
              <EmailAddress>Email Address</EmailAddress>
              <Status>Status</Status>
              <Earned>Earned</Earned>
            </ListHeaders>
          </EmailListWrapper>
          <Separator />
          <ListWrapper>
            <EmailList>
              {referralStats.map((referral) => (
                <ListEntry>
                  <EmailAddress>{referral.referreeEmail}</EmailAddress>
                  <Status>{referral.status}</Status>
                  <Earned>{`$ ${referral.referrerPayoutAmount}`}</Earned>
                </ListEntry>
              ))}
            </EmailList>
          </ListWrapper>
        </>
      )}
      <Separator />
    </Container>
  );
};

const SendEmailForm = reduxForm({
  form: 'inviteFriends',
})(SendEmail);

export default SendEmailForm;
