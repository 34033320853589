import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextButton from './TextButton';

const Separator = styled.span`
  margin: 0px 5px;
  font-size: 19px;
  color: ${({ theme }) => theme.ui.textButtons.separator};
`;

const TextButtons = ({ buttons, id }) => (
  <div key={`text_buttons_${id}`}>
    {buttons.map((button, i) => [
      i > 0 && <Separator key={`separator_${i}_${id}`}>|</Separator>,
      <TextButton
        key={`${button.text}_${i}_${id}`}
        text={button.text}
        onClick={button.onClick}
        color={button.color}
        fontSize={button.fontSize}
        danger={button.danger}
        disabled={button.disabled}
      />,
    ])}
  </div>
);

TextButtons.defaultProps = {
  buttons: [],
  id: '',
};

TextButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
      color: PropTypes.string,
      fontSize: PropTypes.string,
      danger: PropTypes.bool,
    }),
  ),
  id: PropTypes.string,
};

export default TextButtons;
