export default {
  NAVY: '#002c46',
  NAVY_2: '#0c375a',
  NAVY_3: '#15446e',
  NAVY_4: '#205080',
  NAVY_5: '#74BCF6',
  MEDIUM_NAVY: '#0c375a',
  NAVY_BUTTON_HOVER: '#003c60',
  NAVY_BUTTON_ACTIVE: '#004c79',
  NAVY_SECTION_SEPARATOR: '#305573',
  GREEN: '#00ebb9',
  GREEN_2: '#008000',
  GREEN_BUTTON_HOVER: '#00d2a5',
  GREEN_BUTTON_ACTIVE: '#00b891',
  GREEN_BUTTON_PRIMARY_DISABLED_BG: '#49b29d',
  GREEN_BUTTON_PRIMARY_DISABLED_TEXT: '#1e514f',
  GREEN_APPROVED: '#00bc52',
  WHITE: '#ffffff',
  GRAY_1: '#9696a0',
  GRAY_2: '#dcdce1',
  GRAY_3: '#668090',
  GRAY_4: '#F5F5F5',
  RED_ERROR: '#ff6e6e',
  NAVY_LIGHT_TEXT: '#7EC2FF',
  RED: '#ff0000',
  RED_2: '#d50908',
  RED_SELL_BUTTON: '#ff274b',
  RED_SELL_BUTTON_DISABLED: '#992848',
  RED_SELL_BUTTON_ACTIVE: '#b72a42',
  RED_SELL_BUTTON_HOVER: '#cf2b47',
  INPUT_PLACEHOLDER: '#859bad',
};
