import React, { useMemo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { ColorMap } from '../../../ts/types/Color.type';

const Cell = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || 'inherit'};
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3px;
`;

type Props<T extends string | number | symbol> = {
  colorMap: ColorMap<T>;
  value?: string;
};

const ColoredCell = <T extends string | number | symbol>({
  colorMap,
  value,
}: Props<T>) => {
  const color = useMemo(() => {
    const lower = value ? value.toLowerCase() : '';
    const key =
      Object.keys(colorMap).find((k) => k.toLowerCase() === lower) || '';
    return get(colorMap, key);
  }, [colorMap, value]);
  return <Cell color={color}>{value}</Cell>;
};

export default ColoredCell;
