import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import colors from '../../constants/colors';
import InputWithSelect from '../../common/components/InputWithSelect';
import { Select } from '@material-ui/core';

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  /* margin: 0px 0px 20px 0px; */
  opacity: ${(props) => (props.disabled ? 0.45 : 1)};
  pointer-events: ${(props) => props.disabled && 'none'};
`;
ItemContainer.displayName = 'ItemContainer';

const ItemName = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.NAVY_3};
  width: 30%;
  padding-left: 10px;
  font-size: 15px;
  font-weight: bold;
`;
ItemName.displayName = 'ItemName';

const ItemDetails = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.singleItem ? 'center' : 'space-between'};
  background-color: ${colors.NAVY_2};
  width: 70%;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.dropdown ? '0px 0px 0px 10px' : '0px 10px')};
`;
ItemDetails.displayName = 'ItemDetails';

const ItemAmountInput = styled.input`
  width: 80%;
  background: none;
  border: none;
  color: white;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
ItemAmountInput.displayName = 'ItemAmountInput';

const ItemSymbol = styled(Select)`
  /* width: 20%; */
  text-align: right;
  margin-bottom: 0px;
  font-weight: bold;
`;
ItemSymbol.displayName = 'ItemSymbol';

const SingleItem = styled.p`
  text-align: center;
`;
SingleItem.displayName = 'SingleItem';

const ItemValue = styled.p`
  /* width: 20%; */
  text-align: left;
  margin-bottom: 0px;
  font-weight: bold;
`;
ItemSymbol.displayName = 'ItemSymbol';

const Error = styled.p`
  color: ${colors.RED_ERROR};
  font-size: 12px;
  text-align: center;
`;

export const CurrencySelector = styled(Dropdown)`
  //SemanticUI has many !important lying around. We can
  //Workaround this by using '&&&' so the styled-component class is repeated
  //making it come last on the css source order. Using !important in this instance
  //is safe as it applies only to the styled-component-generated CSS class

  &&& {
    border-bottom: none !important;
  }

  &&& > .text {
    padding-top: 1px !important;
    padding-left: 0px !important;
  }
`;

const OrderEntryItem = ({
  name,
  symbol,
  input,
  single,
  value,
  onChange,
  disabled,
  dropdown,
  inputSelect,
  options,
  defaultOption,
  className,
  selectValue,
  selectOnChange,
  error,
  errorText,
  id,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(e, { name, value });
  };

  return (
    <>
      <ItemContainer disabled={disabled} className={className}>
        <ItemName>{name}</ItemName>
        <ItemDetails singleItem={single} dropdown={dropdown}>
          {input && (
            <ItemAmountInput
              name="amount"
              id={id}
              type="number"
              placeholder="Amount"
              value={value}
              onChange={handleChange}
              disabled={disabled}
            />
          )}
          {dropdown && (
            <CurrencySelector
              id={id}
              placeholder="Select asset"
              fluid
              value={value}
              selection
              options={options}
              name="assetType"
              onChange={onChange}
            />
          )}
          {!dropdown && !input && symbol && <ItemValue>{value}</ItemValue>}
          {!dropdown && symbol && (
            <ItemSymbol
              options={options}
              value={selectValue}
              onChange={(e) => selectOnChange(e, e.target)}
            >
              {options.map((o) => (
                <MenuItem key={o.key} value={o.value}>
                  {o.text}
                </MenuItem>
              ))}
            </ItemSymbol>
          )}
        </ItemDetails>
      </ItemContainer>
      {error && <Error>{errorText}</Error>}
    </>
  );
};

OrderEntryItem.defaultProps = {
  single: false,
  dropdown: false,
  onChange: null,
  options: null,
  error: false,
  errorText: '',
  id: '',
};

OrderEntryItem.propTypes = {
  name: PropTypes.string.isRequired,
  single: PropTypes.bool,
  dropdown: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  id: PropTypes.string,
};

export default OrderEntryItem;
