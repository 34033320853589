import _ from 'lodash';

const normalizeReferenceCode = (referenceCode) => {
  let dt = null;

  // Exclude destination tag from reference codes for TXRP/XRP
  if (/\?dt=/.test(referenceCode)) {
    [referenceCode, dt] = referenceCode.split('?dt=');
  }

  return [referenceCode, dt];
};

export default normalizeReferenceCode;
