import _ from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { formValueSelector } from 'redux-form';
import { arrayToObject } from '../utils/methods';
import { accountIsAllowedFutures } from '../utils/permissions';
import { getActiveAssetType } from './accountHistoryReducer';
import { getSelectedMemberId } from './membersReducer';
import {
  INTERNAL_TRANSFERS_FORM,
  TRANSFER_FROM_ACCOUNT,
} from '../constants/internalTransfersConstants';
import { getActiveAccount } from './activeAccountSelectors';
import { getActiveOrOnlyAccount } from './activeAccountSelectors';
import ACCOUNT_ORIGINS from '../constants/accountOrigins';

//* Action Types */
export const ACCOUNTS = 'ACCOUNTS';
export const fetchAccounts = createRoutine(ACCOUNTS);
export const fetchAccountsPromiseCreator = promisifyRoutine(fetchAccounts);

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
const SPOT_ACCOUNT = 'Spot';
const FUTURES_ACCOUNT = 'Futures';
const FUTURES_SPOT_ACCOUNT = 'Futures/Spot';
const SPOT_IRA_ACCOUNT = 'Spot IRA';
const FUTURES_IRA_ACCOUNT = 'Futures IRA';

function selectedAccountIdReducer(state = '', action) {
  switch (action.type) {
    case SELECT_ACCOUNT:
      return action.account;
    default:
      return state;
  }
}

function byId(state = {}, action) {
  switch (action.type) {
    case fetchAccounts.SUCCESS:
      return {
        ...state,
        ...arrayToObject(action.payload.accounts, 'accountId'),
      };
    default:
      return state;
  }
}

function count(state = 0, action) {
  switch (action.type) {
    case fetchAccounts.SUCCESS:
      return action.payload.count;
    default:
      return state;
  }
}

export default combineReducers({
  selectedAccountId: selectedAccountIdReducer,
  accounts: byId,
  count,
});

//* Selectors */
export const getAllAccounts = (state) =>
  _.get(state, ['accountList', 'accounts'], {});
export const getAllAccountIds = (state) =>
  Object.keys(_.get(state, ['accountList', 'accounts'], {}));

export const getAllAccountsCount = (state) =>
  _.get(state, ['accountList', 'count'], 0);

export const getAllAccountsList = createSelector(
  [getAllAccounts],
  (allAccounts) => Object.values(allAccounts),
);
export const getAllMemberAccountsList = createSelector(
  [getAllAccounts, getSelectedMemberId],
  (allAccounts = [], selectedMemberId) =>
    Object.values(allAccounts).filter(
      (account) => account.memberId === selectedMemberId,
    ),
);

export const getAllMemberAccountIds = createSelector(
  [getAllMemberAccountsList],
  (accounts) => accounts.map((a) => a.accountId),
);

export const someAccountEnabledForFutures = createSelector(
  [getAllAccountsList],
  (allAccounts = []) => allAccounts.some(accountIsAllowedFutures),
);

export const getHasSpotEnabledAccount = createSelector(
  [getAllAccountsList],
  (allAccounts = []) => allAccounts.some((acc) => acc.allowSpot),
);

export const getHasCollateralEnabledAccount = createSelector(
  [getAllAccountsList],
  (allAccounts = []) => allAccounts.some((acc) => acc.allowCollateral),
);

export const getAccountIdFromURL = () => {
  const match = /accounts\/([-A-Za-z0-9]*)/.exec(window.location.href);
  if (!match) return '';
  return match[1];
};

export const getSelectedAccountId = (state) =>
  _.get(state, ['accountList', 'selectedAccountId'], '');

export const hasAnAccountOfType = (state, types = []) => {
  const accounts = getAllAccounts(state);
  return Object.values(accounts).some((acc) => hasSubAccountTypes(acc, types));
};

export const getAccountDeliveryType = (state) =>
  _.get(getActiveAccount(state), 'type', '');

export const getFcmActiveAccountId = (state) =>
  _.get(getActiveAccount(state), 'accountId', '');

export const getCustomerAccount = createSelector(
  [getAllAccountsList],
  (accounts) => {
    return accounts.find((acc) => acc.origin === ACCOUNT_ORIGINS.CUSTOMER);
  },
);
export const getCustomerAccountLabel = createSelector(
  [getCustomerAccount],
  (customerAccount) => _.get(customerAccount, 'label', ''),
);
export const getActiveOrFirstAccount = createSelector(
  [getActiveAccount, getAllAccountsList],
  (activeAccountId, accounts) =>
    activeAccountId ? activeAccountId : _.get(accounts, '[0]', null),
);

export const getActiveOrFirstAccountLabel = createSelector(
  [getActiveOrFirstAccount],
  (acc) => _.get(acc, 'label', ''),
);

export const getActiveOrFirstAccountId = createSelector(
  [getActiveOrFirstAccount],
  (acc) => _.get(acc, 'accountId', ''),
);

export const getActiveAccountLabel = createSelector(
  [getActiveAccount],
  (account) => _.get(account, 'label', ''),
);

export const getActiveAccountMemberId = createSelector(
  [getActiveAccount],
  (account) => _.get(account, 'memberId', ''),
);

export const getActiveOrFirstAccountFirmCode = createSelector(
  [getActiveOrFirstAccount],
  (acc) => _.get(acc, 'firmCode', ''),
);

export const getSpotClearingAccounts = (state) =>
  Object.values(getAllAccounts(state)).filter(
    (account) =>
      account.accountType === 'clearing' &&
      account.allowSpot &&
      !account.allowFutures,
  );

export const getBalances = (state) => {
  const account = getActiveAccount(state);
  return _.get(account, 'balances', {});
};

export const getActiveBalance = createSelector(
  [getActiveAssetType, getBalances],
  (activeAssetType, balances) => {
    if (_.isEmpty(balances) || !activeAssetType) {
      return '0';
    } else if (activeAssetType === 'all') {
      // FIXME: don't just blindly add up different asset types
      return `${Object.values(balances).reduce(
        (sum, balance) => sum + balance,
      )}`;
    }
    return _.get(balances, activeAssetType.toLowerCase(), '0');
  },
);

export const selectAccount = (account) => ({
  type: SELECT_ACCOUNT,
  account,
});

export const hasSubAccountTypes = (account, types = []) => {
  const subType = _.get(account, 'subAccountCode', '');
  return types.some((type) => type === subType);
};

export const isActiveAccountOfType = (state, types = []) => {
  const activeAccount = getActiveAccount(state);
  return hasSubAccountTypes(activeAccount, types);
};

export const getSelectedMemberFuturesAccounts = (state) =>
  getAllMemberAccountsList(state).filter((acc) => acc.allowFutures);

export const getSelectedMemberSpotAccounts = (state) =>
  getAllMemberAccountsList(state).filter((acc) => acc.allowSpot);

export const getTransferrorAccounts = (state) =>
  // Only Non-IRA accounts that belong to the currently selected member id
  getAllMemberAccountsList(state).filter(
    (acc) => !hasSubAccountTypes(acc, ['IRA']),
  );

export const getTransferreeAccounts = (state) => {
  const selector = formValueSelector(INTERNAL_TRANSFERS_FORM);
  const fromAccount = selector(state, TRANSFER_FROM_ACCOUNT);
  const fromIsSpot = _.get(fromAccount, 'allowSpot', false);
  const fromIsFutures = _.get(fromAccount, 'allowFutures', false);

  if (fromIsSpot) return getSelectedMemberFuturesAccounts(state);

  if (fromIsFutures) return getSelectedMemberSpotAccounts(state);

  return [];
};

export const getAccountDescription = (account) => {
  const isIRA = hasSubAccountTypes(account, ['IRA']);
  const isSpot = _.get(account, 'allowSpot', false);
  const isFutures = accountIsAllowedFutures(account);

  if (isSpot && isFutures) {
    return FUTURES_SPOT_ACCOUNT;
  }

  if (isSpot) {
    if (isIRA) return SPOT_IRA_ACCOUNT;
    return SPOT_ACCOUNT;
  }

  if (isFutures) {
    if (isIRA) return FUTURES_IRA_ACCOUNT;
    return FUTURES_ACCOUNT;
  }
  return null;
};

export const getIsCurrentAccountFutures = (state) =>
  _.get(getActiveOrOnlyAccount(state), 'allowFutures', false);

export const getHasAnIRAAccount = (state) => hasAnAccountOfType(state, ['IRA']);
