import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ErrorPage = ({ header, subheader, icon }) => (
  <div className="vert-flex">
    <section className="mobile-error">
      <Header as="h1" icon>
        <Icon name={icon} />
        {header}
        <Header.Subheader>{subheader}</Header.Subheader>
      </Header>
    </section>
  </div>
);

ErrorPage.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ErrorPage.defaultProps = {
  icon: 'warning circle',
  header: '',
  subheader: '',
};

export default ErrorPage;
