import { call, takeLatest, put } from 'redux-saga/effects';
import { showModal } from 'erisxkit/client';
import cogoToast from 'cogo-toast';
import * as actions from '../reducers/userReducer';
import api, * as urls from '../api';
import { createSaga } from '../utils/createSaga';
import { FUTURES_ONBOARDING } from '../constants/modalTypes';

function* fetchUser() {
  try {
    yield put(actions.fetchUser.request());
    const user = yield call(api.post, urls.USER_API_ENDPOINT);
    yield put(actions.fetchUser.success(user));
  } catch (e) {
    yield put(actions.fetchUser.failure(e.response));
  }
}

function* isEligibleLocation(arg) {
  try {
    const isEligible = yield call(
      api.post,
      urls.IS_ELIGIBLE_LOCATION_API_ENDPOINT,
      { state: arg.state },
    );
    yield put({ type: actions.IS_ELIGIBLE_LOCATION_SUCCESS, isEligible });
  } catch (e) {
    yield put({
      type: actions.IS_ELIGIBLE_LOCATION_FAILED,
      payload: e.response,
    });
  }
}

function* submitPIISaga({ payload }) {
  yield put(actions.submitPii.request());

  try {
    const userSubmitPII = yield call(api.post, urls.SUBMIT_PII_API_ENDPOINT);
    yield put(actions.submitPii.success(userSubmitPII));
  } catch (e) {
    yield put(actions.submitPii.failure(e.response));
  }
}

function* fetchPII() {
  try {
    const pii = yield call(api.post, urls.PII_API_ENDPOINT);
    yield put({ type: actions.PII_SUCCESS, payload: pii });
  } catch (e) {
    yield put({ type: actions.PII_FAILED, payload: e.response });
  }
}

function* updatePii({ payload }) {
  try {
    if (!payload.termsAcceptedTime) {
      yield call(api.post, urls.ACCEPT_TERMS_AND_CONDITIONS_API_ENDPOINT);
      yield put(actions.acceptTermsAndConditions.success());
    }
    yield put(actions.updatePii.request());
    const user = yield call(api.post, urls.UPDATE_PII_API_ENDPOINT, {
      pii: payload,
    });
    yield put(actions.updatePii.success(user));
  } catch (e) {
    yield put(actions.updatePii.failure(e.response));
  }
}

function* updateTutorialState({ payload }) {
  try {
    const user = yield call(
      api.post,
      urls.UPDATE_TUTORIAL_STATE_API_ENDPOINT,
      payload,
    );
    yield put(actions.updateTutorialState.success(user));
  } catch (e) {
    yield put(actions.updateTutorialState.failure());
  }
}

// payload is { id: <userId> }
function* getApplicationPacket({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Please wait while your application packet is compiled.',
    { hideAfter: 0 },
  );
  try {
    yield put(actions.getApplicationPacket.request());
    const pdf = yield call(
      api.post,
      urls.APPLICATION_PACKET_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    cogoToast.success(
      'Application packet compiled successfully! Click button to download.',
    );
    yield put(actions.getApplicationPacket.success(pdf));
  } catch (e) {
    loadingCallback();
    cogoToast.error('Application packet failed to compile.');
    yield put(actions.getApplicationPacket.failure(e.response));
  } finally {
    yield put(actions.getApplicationPacket.fulfill());
  }
}
function* onStartFuturesUpgrade() {
  yield put(
    showModal(FUTURES_ONBOARDING, {
      size: 'small',
    }),
  );
}

export default function* watch() {
  yield takeLatest(actions.fetchUser.TRIGGER, fetchUser);
  yield takeLatest(actions.IS_ELIGIBLE_LOCATION_REQUEST, isEligibleLocation);
  yield takeLatest(actions.updatePii.TRIGGER, updatePii);
  yield takeLatest(actions.PII_REQUEST, fetchPII);
  yield takeLatest(actions.submitPii.TRIGGER, submitPIISaga);
  yield takeLatest(actions.updateTutorialState.TRIGGER, updateTutorialState);
  yield takeLatest(
    actions.fetchAuthUser.TRIGGER,
    createSaga(actions.fetchAuthUser, 'AUTH_USER'),
  );
  yield takeLatest(
    actions.createMfTicket.TRIGGER,
    createSaga(
      actions.createMfTicket,
      'CREATE_MF_TICKET',
      'Creating Enrollment Email...',
      'An enrollment email has been sent to your account.',
    ),
  );
  yield takeLatest(
    actions.updateAuthUser.TRIGGER,
    createSaga(actions.updateAuthUser, 'UPDATE_AUTH_USER'),
  );
  yield takeLatest(
    actions.acceptTermsAndConditions.TRIGGER,
    createSaga(actions.acceptTermsAndConditions, 'ACCEPT_TERMS_AND_CONDITIONS'),
  );

  yield takeLatest(actions.getApplicationPacket.TRIGGER, getApplicationPacket);
  yield takeLatest(
    actions.hideFuturesForUser.TRIGGER,
    createSaga(actions.hideFuturesForUser, actions.hideFuturesForUser._PREFIX),
  );
  yield takeLatest(
    actions.startFuturesUpgrade.TRIGGER,
    createSaga(
      actions.startFuturesUpgrade,
      actions.startFuturesUpgrade._PREFIX,
    ),
  );
  yield takeLatest(actions.startFuturesUpgrade.SUCCESS, onStartFuturesUpgrade);
  yield takeLatest(
    actions.acceptFuturesUpgrade.TRIGGER,
    createSaga(
      actions.acceptFuturesUpgrade,
      actions.acceptFuturesUpgrade._PREFIX,
    ),
  );
}
