import React from 'react';
import { NavLinkWrapper, StyledNavLink } from './styled';
import { isLinkActive, LinkUrl } from './MarginFuturesPanels';

const Link = ({
  title,
  to,
  key,
  dataCy,
}: {
  title: string;
  to: LinkUrl;
  key: string;
  dataCy?: string;
}) => (
  <NavLinkWrapper isActiveLink={isLinkActive(to)}>
    <StyledNavLink
      key={key}
      to={to}
      activeClassName="fcm-nav-link-active"
      data-cy={dataCy}
    >
      {title}
    </StyledNavLink>
  </NavLinkWrapper>
);

export default Link;
