import qs from 'qs';

const Logout = (auth) => {
  let hashStr = window.location.hash;
  let returnTo = '/';
  hashStr = hashStr.replace(/^#?\/?/, '');
  const parsedQs = qs.parse(hashStr);
  if (parsedQs.returnTo) {
    returnTo = parsedQs.returnTo;
  }

  auth.logout({
    returnTo,
  });
  return null;
};

export default Logout;
