import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
  inputOnChange,
  withFilters,
  XTable,
  showModal,
} from 'erisxkit/client';
import { Dropdown, Header } from 'semantic-ui-react';
import tradesMetadata from '../../metadata/tradesMetadata';
import {
  tradeJournals,
  TRADE_JOURNALS,
  getTradeJournalsList,
  getTradeJournalsCount,
} from '../../reducers/accountHistoryReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import * as journalTypes from '../../constants/journalTypes';
import { TRANSACTION_INFORMATION } from '../../constants/modalTypes';

const FilteredTable = withFilters(XTable);

const tradesFilters = [
  {
    component: Dropdown,
    name: 'trade_type',
    id: 'trade-type',
    defaultValue: '',
    onChange: inputOnChange,
    placeholder: 'All Trades',
    options: [
      { text: 'All Trades', value: '', key: 'all' },
      { text: 'Spot Trades', value: 'spot', key: 'spot' },
      { text: 'Futures Trades', value: 'futures', key: 'futures' },
    ],
  },
];

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  accountId: getActiveAccountId(state),
  tradesList: getTradeJournalsList(state),
  tradesCount: getTradeJournalsCount(state),
  tradesLoading: createLoadingSelector([TRADE_JOURNALS])(state),
});

const mapDispatchToProps = {
  tradeJournals,
  showModal,
};

export const TRADE_JOURNAL_TYPES = [journalTypes.SPOT];

class TradesContainer extends Component {
  fetchData = (state) => {
    let filter = [
      { attr: 'account_id', op: 'eq', value: this.props.accountId },
    ];
    if (state.filtered.length) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    this.props.tradeJournals({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      filter,
    });
  };

  moreInfo = (data) => {
    this.props.showModal(TRANSACTION_INFORMATION, {
      data,
      size: 'mini',
      type: 'trade',
    });
  };

  render = () => (
    <section className="main-holdings">
      {this.props.accountId && (
        <Fragment>
          <Header
            content="Trading Activity"
            subheader="See the total trading activity for this account."
          />
          <FilteredTable
            title="trades"
            className="dark"
            data={this.props.tradesList}
            columns={tradesMetadata(this.moreInfo)}
            loading={this.props.tradesLoading}
            minRows={5}
            small={this.props.viewportLessThanSmall}
            manual
            pages={Math.ceil(this.props.tradesCount / 20)}
            sortable={false}
            onFetchData={this.fetchData}
            showPageSizeOptions={false}
            defaultPageSize={20}
            filters={tradesFilters}
            filterComponentProps={{ inline: true, hideClearButton: true }}
          />
        </Fragment>
      )}
    </section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TradesContainer);
