import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatBigPositiveNegative, relDiff } from '../utils/methods';
import { sizes } from '../styles/styled';
import Big from 'bignumber.js';
import { Statistic } from 'semantic-ui-react';
import colors from '../constants/colors';

export const DailyChangeStatisticSpan = styled.span`
  color: ${(props) => (props.isNegative ? colors.RED : colors.GREEN_2)};
  @media (min-width: ${sizes.TINY}) {
    font-size: 1.2rem;
  }
  font-weight: bold;
  @media (max-width: ${sizes.TINY}) {
    font-size: 1rem !important;
  }
`;
export const DailyChangeStatisticMobile = styled(Statistic)`
  margin-right: auto !important;
  @media (max-width: ${sizes.TINY}) {
    margin-left: 0.8rem !important;
  }
  && .label {
    color: ${colors.GRAY_3};
    text-align: right;
    text-transform: capitalize;
    font-weight: 400;
  }
  &&& .value {
    text-align: right;
    font-size: 1.1rem !important;
  }
  &&& .previous {
    @media (min-width: ${sizes.TINY}) {
      display: inline-flex;
    }
    @media (max-width: ${sizes.TINY}) {
      display: block;
    }
  }
  &&& .previousValue {
    @media (max-width: ${sizes.TINY}) {
      margin-bottom: 0px;
    }
  }
`;

export const DailyChangeStatistic = styled(Statistic)`
  margin-top: 1.2rem ${(props) => (props.loadingBalances ? '' : '!important')};
  margin-right: auto !important;
  &&& .value {
    text-align: right;
    font-size: 1.4rem !important;
  }
  &&& .previous {
    display: inline-flex;
  }
`;

const StyledValue = styled.p`
  color: ${({ theme }) => theme.text.header + '!important'};
`;

export const DailyChange = ({ currTotalUsdValue, prevTotalUsdValue }) => {
  const difValue = formatBigPositiveNegative(
    Big(currTotalUsdValue).minus(prevTotalUsdValue),
    2,
  );
  const _relDiff = relDiff(currTotalUsdValue, prevTotalUsdValue);
  const resultBigRelDiff = new Big(_relDiff === null ? 0 : _relDiff);
  const difValuePercentage = formatBigPositiveNegative(resultBigRelDiff, 1);
  return (
    <div className="previous">
      {_relDiff !== null && (
        <>
          <StyledValue className="previousValue">{difValue}</StyledValue>
          <StyledValue className="previousPercentage">
            {' '}
            (
            <DailyChangeStatisticSpan
              isNegative={resultBigRelDiff.isNegative()}
            >
              {' '}
              {difValuePercentage}%{' '}
            </DailyChangeStatisticSpan>
            )
          </StyledValue>
        </>
      )}
    </div>
  );
};

DailyChange.propTypes = {
  currTotalUsdValue: PropTypes.string,
  prevTotalUsdValue: PropTypes.string,
};

DailyChange.defaultProps = {
  currTotalUsdValue: '0',
  prevTotalUsdValue: '0',
};
