/* eslint-disable no-unused-expressions */
import { call, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import get from 'lodash/get';
import unset from 'lodash/unset';
import { UnifiedRoutine } from 'redux-saga-routines';
import api, * as urls from '../api';
import parseUrl from './parseUrl';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

type GenerateSagaOpts = {
  loading?: string;
  success?: string;
  loadingOptions?: object;
  successOptions?: { onClick?: Function };
  method?: HTTPMethod;
  payloadOptions?: { withPage?: boolean };
  prefix?: string;
};

const getRoutinePrefix = (routine: UnifiedRoutine) => {
  const action = routine.REQUEST || '';
  const parsed = action.split('/');
  return parsed.length > 1 ? get(parsed, [0]) : '';
};

const generateSaga = (
  // Routine must be a redux-saga-routine with a _PREFIX attribute
  routine: UnifiedRoutine,
  opts: GenerateSagaOpts = {},
) =>
  function* generator({
    payload,
  }: {
    payload: {
      urlParams?: { [key: string]: string | number };
      queryParams?: { [key: string]: string | number };
      page?: number;
    };
  }) {
    let closeToast;
    const {
      loading = '',
      success = '',
      loadingOptions = {},
      successOptions = {},
      method = HTTPMethod.POST,
      payloadOptions = {},
      prefix = '',
    } = opts;

    const type = prefix || getRoutinePrefix(routine);

    if (loading) {
      closeToast = cogoToast.loading(loading, {
        ...loadingOptions,
        hideAfter: 0,
      });
    }

    try {
      yield put(routine.request());
      // Populate params if necessary
      // TODO:Check template literal typings
      const url = parseUrl(urls[`${type}_API_ENDPOINT`] || '', payload);
      // Remove queryParams and urlParams from payload
      unset(payload, 'urlParams');
      unset(payload, 'queryParams');
      // Make request with corresponding HTTP method - default to POST
      const result = yield call(api[method], url, payload);
      const returnPayload = payloadOptions.withPage
        ? { ...result, page: payload.page }
        : result;
      loading && closeToast();
      success &&
        cogoToast.success(success, {
          ...successOptions,
        });
      yield put(routine.success(returnPayload));
    } catch (e) {
      loading && closeToast();
      yield put(routine.failure(e?.response));
    }
  };

export default generateSaga;
