import isEmpty from 'lodash/isEmpty';
import { snakeCase } from 'change-case';

/**
  @desc: Receives a URL and a map of query params and their values
            and returns the formed URL
  @param - uri string 
  @param - queryParams: object that maps a query param to its value
  @returns: uri populated with query params
  e.g.:
 uri = "/api/user/1234/documents";
 queryParams = { docType: 'reliance' };
 returns /api/user/1234/documents?doc_type=reliance
*/
const populateQueryParams = (
  uri: string,
  queryParams: { [key: string]: string | number } | {},
) => {
  if (!uri || isEmpty(queryParams)) return uri;
  let newUrl = uri;
  const last = uri.slice(-1);
  // Add '?' to the end of uri
  if (last !== '?') {
    newUrl += '?';
  }

  const paramAmount = Object.keys(queryParams)?.length || 0;

  Object.entries(queryParams).forEach((queryParam, i) => {
    const [param, value] = queryParam;
    const paramNo = i + 1;
    const snakeCasedParam = snakeCase(param);
    newUrl = `${newUrl}${snakeCasedParam}=${value}${
      paramNo < paramAmount ? '&' : ''
    }`;
  });
  return newUrl;
};

export default populateQueryParams;
