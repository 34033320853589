import usePrevious from './usePrevious';
import Big from 'bignumber.js';

/*Use this hook to get the variation between to prices*/
function usePriceChange(price = '') {
  const prevPrice = usePrevious(price);
  const priceChange = Big(price).minus(prevPrice).toFixed();
  return priceChange;
}

export default usePriceChange;
