import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import Moment from 'moment';
import CryptoAssetAccount from '../ts/models/AssetAccounts/CryptoAssetAccount.model';

const columnHelper = createColumnHelper<CryptoAssetAccount>();

export const cryptoAddressMetadata = [
  columnHelper.accessor('label', {
    id: 'label',
    header: 'Label',
    minSize: 80,
  }),
  columnHelper.accessor('assetType', {
    id: 'assetType',
    header: 'Asset Type',
    minSize: 80,
  }),
  columnHelper.accessor('address', {
    id: 'address',
    header: 'Address',
    minSize: 80,
  }),
  columnHelper.accessor(
    (row) =>
      !isEmpty(row['createdAt'])
        ? (new Moment(row['createdAt']).format('LL, LTS Z') as string)
        : '',
    {
      id: 'createdAt',
      header: 'Date Added',
    },
  ),
  columnHelper.accessor(
    ({ state }) => (
      <div className={`status ${state}`}>
        {state?.charAt(0)?.toUpperCase() + state?.slice(1)}
      </div>
    ),
    {
      header: 'Status',
      id: 'status',
      maxSize: 100,
      minSize: 70,
    },
  ),
] as ColumnDef<CryptoAssetAccount>[];

export default cryptoAddressMetadata;
