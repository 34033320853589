import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Text = styled.p`
  color: ${({ theme }) => theme.text.text};
`;
Text.displayName = 'p';

const BchMessage = ({ assetType, inline, message }) =>
  /.?bch/i.test(assetType) && (
    <Text className={`bold text-center${inline && ' inline-block'}`}>
      <Icon name="info circle" />
      {message}
    </Text>
  );

BchMessage.propTypes = {
  assetType: PropTypes.string,
  message: PropTypes.string,
};

BchMessage.defaultProps = {
  assetType: '',
  message: 'Cboe Digital uses the legacy Bitcoin Cash Address format.',
};

export default BchMessage;
