import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SecurityQuestions from '../../components/onBoarding/SecurityQuestions';
import {
  fetchSecurityQuestions,
  submitSecurityQuestions,
  getSecurityQuestions,
} from '../../reducers/complianceQuestionsReducer';
import { getLoggedInUser } from '../../reducers/userReducer';
import { trackEvent } from '../../common/tracking';

class SecurityQuestionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchSecurityQuestions();
  }

  next = () => {
    const { answers } = this.props;
    trackEvent('Security', 'securitySubmitQuestions');
    this.props.submitSecurityQuestions(answers);
    this.props.nextStep();
  };

  render() {
    const { answers } = this.props;
    let { questions } = this.props;

    if (answers.length) {
      questions = questions.map((question) => {
        if (
          answers.some((answer) => answer.questionId === question.questionId)
        ) {
          return {
            ...question,
            disabled: true,
          };
        }
        return question;
      });
    }

    const props = {
      next: this.next,
      loading: false,
      questions,
    };

    return <SecurityQuestions {...props} />;
  }
}

const mapDispatchToProps = {
  fetchSecurityQuestions,
  submitSecurityQuestions,
};

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  questions: getSecurityQuestions(state),
  answers: _.get(state, ['form', 'security', 'values', 'answers'], []),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityQuestionsContainer);
