import _ from 'lodash';
import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import Moment from 'moment';
import { iconColorForState, iconForType } from '../../utils/methods';

export const status = {
  Header: 'Status',
  id: 'status',
  accessor: ({ state = '' }) => (
    <div className={`status ${state}`}>
      {state.charAt(0).toUpperCase() + state.slice(1)}
    </div>
  ),
  headerClassName: 'pull-left',
  resizable: false,
};

export const TRADE_TYPES = ['BUY', 'SELL'];
export const TRADE_DISPLAY_NAME = 'Trade';

export const getType = (mobile) => ({
  Header: 'Type',
  id: 'type',
  headerClassName: 'pull-left',
  accessor: (row) => {
    const { txType, state } = row;
    let displayType = txType;
    if (TRADE_TYPES.includes(txType)) {
      displayType = TRADE_DISPLAY_NAME;
    }
    return (
      <Fragment>
        {!mobile ? (
          <Icon
            name={iconForType(txType)}
            color={iconColorForState(state, txType)}
          />
        ) : (
          ''
        )}
        {displayType && _.capitalize(displayType)}
        {mobile ? ' of' : ''}
      </Fragment>
    );
  },
});

export const getTimeColumn = (key = 'time') => ({
  Header: 'Time',
  id: key,
  headerClassName: 'pull-left',
  minWidth: 230,
  accessor: (row) =>
    !_.isEmpty(row[key]) ? new Moment(row[key]).format('LL, LTS Z') : '',
});

export const timeFromNow = {
  Header: '',
  id: 'time',
  headerClassName: 'pull-left',
  minWidth: 230,
  accessor: (row) =>
    !_.isEmpty(row.time) ? new Moment(row.time).fromNow() : '',
  resizable: false,
};
