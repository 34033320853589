import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { AuthorizedIPsHistory } from '../ts/models/AuthorizedIPs/AuthorizedIPsHistory.model';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import TableCellTextTransform from '../ts/enums/TableCellTextTransform.enum';

const columnHelper = createColumnHelper<AuthorizedIPsHistory>();

export const columns = () =>
  [
    columnHelper.accessor(
      (row: AuthorizedIPsHistory) =>
        moment(row?.dateTime).format('MMMM Do YYYY, h:mm a'),
      {
        id: 'dateTime',
        header: 'Date/Time',
        minSize: 100,
        maxSize: 210,
        meta: {
          align: TableCellAlignment.Left,
        },
      },
    ),
    columnHelper.accessor('action', {
      id: 'action',
      header: 'Action',
      maxSize: 100,
      meta: {
        align: TableCellAlignment.Left,
        textTransform: TableCellTextTransform.Capitalize,
      },
    }),
    columnHelper.accessor('updatedBy', {
      id: 'updatedBy',
      header: 'Updated By',
      minSize: 100,
      maxSize: 300,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
    columnHelper.accessor('ipAddress', {
      id: 'ipAddress',
      header: 'IP Address',
      minSize: 100,
      maxSize: 210,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: 'Comment',
      minSize: 100,
      meta: {
        align: TableCellAlignment.Left,
      },
    }),
  ] as ColumnDef<AuthorizedIPsHistory>[];

export default columns;
