import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { get } from 'lodash';
import { combineReducers } from 'redux';

export const CREATE_API_CREDENTIALS = 'CREATE_API_CREDENTIALS';
export const CREATE_THIRD_PARTY_API_CREDENTIALS =
  'CREATE_THIRD_PARTY_API_CREDENTIALS';
export const API_CREDENTIALS = 'API_CREDENTIALS';
export const DELETE_API_CREDENTIALS = 'DELETE_API_CREDENTIALS';
export const API_CREDENTIALS_PERMISSIONS = 'API_CREDENTIALS_PERMISSIONS';

export const createApiCredentials = createRoutine(CREATE_API_CREDENTIALS);
export const createThirdPartyApiCredentials = createRoutine(
  CREATE_THIRD_PARTY_API_CREDENTIALS,
);
export const apiCredentialsPermissions = createRoutine(
  API_CREDENTIALS_PERMISSIONS,
);

export const createApiCredentialsPromiseCreator =
  promisifyRoutine(createApiCredentials);
export const createThirdPartyApiCredentialsPromiseCreator = promisifyRoutine(
  createThirdPartyApiCredentials,
);

export const fetchApiCredentials = createRoutine(API_CREDENTIALS);
export const fetchApiCredentialsPromiseCreator =
  promisifyRoutine(fetchApiCredentials);

export const deleteApiCredentials = createRoutine(DELETE_API_CREDENTIALS);

//* Reducer */
const apiCredentialsReducer = (state = [], action) => {
  switch (action.type) {
    case fetchApiCredentials.SUCCESS:
      return action.payload;
    case createApiCredentials.SUCCESS:
      return [...state, action.payload];
    case deleteApiCredentials.SUCCESS:
      return state.filter(
        (credential) => credential.key !== get(action, ['payload', 'key']),
      );
    default:
      return state;
  }
};
const getApiCredentialPermissionsReducer = (state = [], action) => {
  switch (action.type) {
    case apiCredentialsPermissions.SUCCESS:
      return action.payload.permissions;
    default:
      return state;
  }
};

export default combineReducers({
  apiCredentials: apiCredentialsReducer,
  apiCredentialPermissions: getApiCredentialPermissionsReducer,
});

export const getApiCredentials = (state) =>
  get(state, 'apiCredentials.apiCredentials', []);
export const getApiCredentialPermissions = (state) =>
  get(state, 'apiCredentials.apiCredentialPermissions', []);
export const getHasCredentialWithLabel = (state, label) =>
  getApiCredentials(state).some((c) => c.label === label);
