import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideModal } from 'erisxkit/client';
import StyledButton from '../../../common/StyledButton';
import { FUTURES_ONBOARDING } from '../../../constants/modalTypes';
import { fetchAccounts } from '../../../reducers/accountsReducer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;
Wrapper.displayName = 'FuturesCompleteWrapper';

const Header = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  text-align: center;
`;

const Continue = styled(StyledButton)`
  width: 100px;
  max-width: 150px;
  height: 40px;
  margin: 15px auto 0px auto;
`;

const FuturesOnboardingComplete = () => {
  const dispatch = useDispatch();

  const onContinue = () => {
    dispatch(hideModal(FUTURES_ONBOARDING));
    dispatch(fetchAccounts());
  };

  return (
    <Wrapper>
      <Header>THANK YOU!</Header>
      <Text>Your application is being processed.&nbsp;</Text>
      <Text>You will receive an email once your account is ready.</Text>
      <Continue text="Continue" onClick={onContinue} primary />
    </Wrapper>
  );
};

export default FuturesOnboardingComplete;
