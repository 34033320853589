import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const ReduxFormTextField = ({
  label,
  input: { name, onChange, value },
  meta: { touched, invalid, error },
  fullWidth,
  required,
  type,
  inputComponent,
  endAdornment,
  'data-cy': dataCy,
}) => {
  const InputProps = {
    inputComponent: inputComponent || undefined,
    endAdornment: endAdornment || undefined,
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        fullWidth={fullWidth}
        onChange={onChange}
        name={name}
        required={required}
        type={type}
        InputProps={InputProps}
        value={value}
        data-cy={dataCy}
      />
    </FormControl>
  );
};

ReduxFormTextField.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  inputComponent: PropTypes.node,
  dataCy: PropTypes.string,
};

ReduxFormTextField.defaultProps = {
  fullWidth: false,
  input: {
    value: '',
    onChange: () => {},
  },
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
  required: false,
  type: 'text',
  inputComponent: null,
  dataCy: '',
};

export default ReduxFormTextField;
