import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { FCMClearingResponse } from '../ts/models/FCMClearing.model';
import React from 'react';
import TextButton from '../common/TextButton';
import colors from '../constants/colors';
import styled from 'styled-components';
import { getDateObj, getFormattedTimestamp } from '../utils/dateUtils';

const columnHelper = createColumnHelper<FCMClearingResponse>();

const StatusCell = styled.div`
  display: flex;
`;

const StatusText = styled.div`
  margin-right: 10px;
`;

export const fcmClearingMetadata = (
  onRejectClicked: (fcmName: string, requestId: string) => void,
) =>
  [
    columnHelper.accessor((row) => getDateObj(row?.createdAt), {
      id: 'createdAt',
      header: 'Time',
      minSize: 100,
      enableColumnFilter: true,
      sortingFn: 'datetime',
      cell: (props) => getFormattedTimestamp(props),
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('fcmName', {
      id: 'fcmName',
      header: 'FCM',
      minSize: 60,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('comment', {
      id: 'comment',
      header: 'Comment',
      minSize: 120,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      minSize: 40,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
      cell: (props) => {
        return props.getValue() ? (
          <StatusCell>
            <StatusText>{props.getValue()}</StatusText>
            {props.getValue() === 'pending' && (
              <TextButton
                text="Reject"
                onClick={() =>
                  onRejectClicked(
                    props.row.original.fcmName,
                    props.row.original.requestId,
                  )
                }
                fontSize="14"
                color={colors.RED_ERROR}
              />
            )}
          </StatusCell>
        ) : null;
      },
    }),
    columnHelper.accessor((row) => getDateObj(row?.approvedTimestamp), {
      id: 'approvedTimestamp',
      header: 'Time Approved',
      enableColumnFilter: true,
      sortingFn: 'datetime',
      cell: (props) => getFormattedTimestamp(props),
      minSize: 90,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
  ] as ColumnDef<FCMClearingResponse>[];
