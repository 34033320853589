import React from 'react';
import { Field, Validator } from 'redux-form';
import { renderDropdown } from '../../../common/forms/components';
import * as rules from '../../../common/forms/rules';
import { FieldId } from './FormField';

export const FormDropdown = ({
  id,
  label,
  optional = false,
  validate = [],
  options = [],
}: {
  id: FieldId;
  label: string;
  optional?: boolean;
  validate?: Validator[];
  options: Array<{ text: string; value: string | number }>;
}) => (
  <div className="field">
    <label>{label}</label>
    <Field
      component={renderDropdown}
      name={id}
      id={id}
      placeholder={label}
      style={{ marginBottom: '0px', height: '38px' }}
      minimal
      required={!optional}
      validate={optional ? validate : [rules.required, ...validate]}
      options={options}
      selection
      closeOnChange
    />
  </div>
);

export default FormDropdown;
