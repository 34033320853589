import { createRoutine } from 'redux-saga-routines';
import { get } from 'lodash';
import { createById } from 'erisxkit/client';
import { combineReducers } from 'redux';

export const CONTRACTS = 'CONTRACTS';
// Routines
export const collateralContracts = createRoutine('COLLATERAL_CONTRACTS');
export const fetchContracts = createRoutine(CONTRACTS);
// Reducer

const routine = fetchContracts;
const slice = 'contracts';
const id = 'symbol';

export const collateralContractsReducer = (state = [], { type, payload }) => {
  switch (type) {
    case collateralContracts.SUCCESS:
      return payload.collateralContracts;
    default:
      return state;
  }
};

export default combineReducers({
  collateralContracts: collateralContractsReducer,
  bySymbol: createById(routine, slice, id),
});
// Selectors
export const getCollateralContracts = (state) =>
  get(state, ['contracts', 'collateralContracts'], []);
export const getContractsBySymbol = (state) =>
  get(state, ['contracts', 'bySymbol'], {});
