import { createSelector } from 'reselect';
import { handleActions, createAction } from 'redux-actions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { EnvVar } from '../ts/types/EnvData.type';

/** Constants */
// Env vars are all set to strings, not booleans
const ENV_VAR_SET = 'true';

/** Action names */
export const SET_ENV_ATTR = 'SET_ENV_ATTR';

/** Actions */
export const setEnvAttr = createAction(SET_ENV_ATTR);

/** Initial State */
export type EnvState = Record<EnvVar, string> | Record<string, never>;

const initialState: EnvState = {};

/** Reducer */
export default handleActions<EnvState, any>(
  {
    [SET_ENV_ATTR]: (state: EnvState, { payload }: { payload: EnvState }) =>
      ({
        ...state,
        ...payload,
      }) as EnvState,
  },
  initialState,
);

//* Selectors */
export const getEnvState = (state: any): EnvState => get(state, 'env', {});

export const getIsMarginEnabled = createSelector(
  [getEnvState],
  (state) => get(state, 'enableMargin', null) === ENV_VAR_SET,
);

export const getEnvPromiseResolved = createSelector(
  [getEnvState],
  (state) => !isEmpty(state),
);
