import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { EnvVar } from '../../ts/types/EnvData.type';
import EnvPromise from '../../config/env';
import { EnvState } from '../../reducers/envReducer';

/**
 * @param envVarToCheck - EnvVar whose value we want to check
 * @returns string value for that env var or null if promise has not resolved yet
 */
const useEnvVar = (envVarToCheck: EnvVar) => {
  const [envVar, setEnvVar] = useState<string | null>(null);

  useEffect(() => {
    EnvPromise.then((env: EnvState) =>
      setEnvVar(get(env, envVarToCheck, null)),
    );
  }, [envVarToCheck]);

  return envVar;
};

export default useEnvVar;
