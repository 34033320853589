import _ from 'lodash';
import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { XTable } from 'erisxkit/client';

const AssetMovements = ({
  title,
  result,
  loading,
  metadata,
  viewportLessThanSmall,
  onFetchData,
  type,
}) => (
  <Fragment>
    <Header
      content={`${type || ''} Asset Movements`}
      subheader={`See the ${type || 'total'} asset movements for this account.`}
    />
    <XTable
      title={title}
      className="dark"
      data={_.get(result, 'history', [])}
      columns={metadata}
      loading={loading}
      minRows={5}
      small={viewportLessThanSmall}
      manual
      pages={Math.ceil(_.get(result, 'count', 0) / 20)}
      sortable={false}
      onFetchData={onFetchData}
      showPageSizeOptions={false}
      defaultPageSize={20}
    />
  </Fragment>
);

export default AssetMovements;
