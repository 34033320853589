import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';

const CheckboxContainer = styled.div<{ readOnly: boolean }>`
  display: inline-block;
  vertical-align: middle;
  cursor: ${({ readOnly }) => (readOnly ? '' : 'pointer')};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : '')};
`;

// Hide checkbox visually but remain accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Check = styled.div`
  background-color: ${({ theme }) => theme.ui.checkbox.checked};
  height: 100%;
  width: 100%;
  border-radius: 2px;
`;

const Checkbox = styled.div<{ checked: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  background: ${colors.WHITE};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.accent};
  }

  ${Check} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  justify-content: center;
  align-items: center;
  padding: 2px;
  border: ${({ theme }) => theme.ui.checkbox.border};
`;

type Props = {
  /** Defines if the checkbox is checked or not*/
  checked: boolean;
  /** optional onChange handler. For read-only checkboxes is not necessary */
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  /** styled-component pseudoclass */
  className?: string;
  /** disables pointer events for the checkpoint to make it read only */
  readOnly?: boolean;
};

const StyledCheckbox = ({
  className,
  checked,
  onChange = () => {},
  readOnly = false,
}: Props) => (
  <label>
    <CheckboxContainer className={className} readOnly={readOnly}>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <Checkbox checked={checked}>
        <Check />
      </Checkbox>
    </CheckboxContainer>
  </label>
);
export default StyledCheckbox;
