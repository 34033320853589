import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, GridColumn, GridRow, Icon, Loader } from 'semantic-ui-react';
import { get, isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import Big from 'bignumber.js';
import { format } from 'erisxkit/client';
import { WITHDRAWAL } from '../../constants/journalTypes';
import { PRECISION_USD } from '../../constants/precision';
import { depositFrequency } from '../../constants/depositFrequency';
import { USD } from '../../constants/assetTypes';
import { formatFiat } from '../../utils/methods';
import AddBankAccountPlaidContainer from '../../containers/LinkedAccounts/AddBankAccountPlaidContainer';
import { grants, subjects } from '../../constants/userPermissions';
import AccessControl from '../../common/AccessControl';

const StyledGridColumn = styled(GridColumn)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  & > i {
    margin: 0px !important;
  }
`;

export const headerValue = (sum, assetType, px, isFiat) => (
  <span>
    {isFiat ? formatFiat(sum, USD) : `${format(sum)} ${assetType}`}
    {!isFiat && !(isEmpty(px) || Big(px).isLessThanOrEqualTo(0)) && (
      <span>&asymp; ${format(Big(sum).times(px).toFixed(PRECISION_USD))}</span>
    )}
  </span>
);

const Unavailable = styled.div`
  font-size: 12px;
  > span {
    font-weight: bold;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledLoader = styled.div`
  position: relative;
  height: 24px;
  .ui.active.loader {
    position: absolute;
    left: 1rem;
    top: 15px;
  }
  .ui.loader:before,
  .ui.loader:after {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const instantStatusText = (instant, linkedBankAccounts, onAddBankAccount) => {
  switch (get(instant, 'status', '')) {
    case 'used':
      return '(Daily Allowance Used)';
    case 'stale':
      return (
        <Unavailable>
          <span>Unavailable. </span>
          <AccessControl
            allowedPermissions={[
              `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
            ]}
          >
            You must&nbsp;
            <AddBankAccountPlaidContainer
              glow={false}
              linkedBankAccounts={linkedBankAccounts || []}
              link
              onClick={onAddBankAccount}
              text="add or relink a bank account"
            />
          </AccessControl>
        </Unavailable>
      );
    case 'down':
      return '(Plaid Currently Unavailable)';
    case 'disabled':
      return '(Instant ACH is not enabled for this account)';
    case 'unavailable':
      return '(Not Available)';
    case 'unsupported':
      return '(Instant ACH Not Supported)';
    default:
      return '???';
  }
};

const Details = ({
  amountLimit,
  action,
  disclaimer,
  lastPricePx,
  isFiat,
  linkedBankAccounts,
  onAddBankAccount,
  frequency,
  loadingLimits,
}) => (
  <Fragment>
    {action === WITHDRAWAL ? (
      <GridColumn>
        <Grid columns="equal">
          <Grid.Row>
            <GridColumn>
              <Stack>
                <div className="label">Account Balance</div>
                {loadingLimits ? (
                  <StyledLoader>
                    <Loader active />
                  </StyledLoader>
                ) : (
                  <div className="amount mono">
                    {headerValue(
                      amountLimit.balance,
                      amountLimit.assetType,
                      lastPricePx,
                      isFiat,
                    )}
                  </div>
                )}
              </Stack>
            </GridColumn>
            <GridColumn>
              <Stack>
                <div className="label">Available to Withdraw</div>
                {loadingLimits ? (
                  <StyledLoader>
                    <Loader active />
                  </StyledLoader>
                ) : (
                  <div className="amount mono">
                    {headerValue(
                      amountLimit.available,
                      amountLimit.assetType,
                      lastPricePx,
                      isFiat,
                    )}
                  </div>
                )}
              </Stack>
            </GridColumn>
          </Grid.Row>
        </Grid>
        {disclaimer && <div className="point">* {disclaimer}</div>}
      </GridColumn>
    ) : (
      <GridColumn>
        <Grid columns="equal">
          <Grid.Row>
            <GridColumn>
              <div className="label">
                Maximum Deposit*
                {/* {`Maximum Deposits*
                  ${new Big(amountLimit.limitPeriod).toFixed()} day period`} */}
              </div>
              <div className="amount mono">
                {headerValue(
                  amountLimit.available,
                  amountLimit.assetType,
                  lastPricePx,
                  isFiat,
                )}
              </div>
            </GridColumn>
            <GridColumn>
              <div className="label">Maximum for Instant Deposit</div>
              {!amountLimit.instant ? (
                '(Select Bank Account)'
              ) : get(amountLimit.instant, 'status', '') !== 'ok' ? (
                instantStatusText(
                  amountLimit.instant,
                  linkedBankAccounts,
                  onAddBankAccount,
                )
              ) : (
                <div className="amount mono">
                  {headerValue(
                    get(amountLimit.instant, 'available', 0),
                    USD,
                    lastPricePx,
                    isFiat,
                  )}
                </div>
              )}
            </GridColumn>
          </Grid.Row>
        </Grid>
        {disclaimer && <div className="point">* {disclaimer}</div>}
        <div className="point">
          Deposits requested before 12:00 PM Chicago time may be withdrawn from
          your bank account the same day
        </div>
      </GridColumn>
    )}
  </Fragment>
);

const LoadingText = styled.p`
  margin-bottom: 0px;
  margin-right: 10px;
`;
const AvailableFundsHeader = (props) => (
  <div className="information-grid">
    <Grid celled columns="equal">
      <GridRow>
        <StyledGridColumn width={isMobile ? 'three' : 'two'} textAlign="center">
          <Icon size="big" name="info circle" />
        </StyledGridColumn>
        <GridColumn>
          {props.requireAsset && isEmpty(props.amountLimit) ? (
            <Stack>
              <p>Please select asset type to see balance</p>
              {props.loadingLimits && (
                <div style={{ display: 'flex' }}>
                  <LoadingText>Fetching Limits...</LoadingText>
                  <StyledLoader>
                    <Loader active />
                  </StyledLoader>
                </div>
              )}
            </Stack>
          ) : (
            <Details {...props} loadingLimits={props.loadingLimits} />
          )}
        </GridColumn>
      </GridRow>
    </Grid>
  </div>
);

const propTypes = {
  action: PropTypes.string,
  amountLimit: PropTypes.objectOf(PropTypes.any),
  disclaimer: PropTypes.string,
  isFiat: PropTypes.bool,
  lastPricePx: PropTypes.string,
  linkedBankAccounts: PropTypes.arrayOf({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  onAddBankAccount: PropTypes.func,
  frequency: PropTypes.string,
  loadingLimits: PropTypes.bool,
  requireAsset: PropTypes.bool,
};

const defaultProps = {
  action: '',
  amountLimit: {},
  disclaimer: '',
  isFiat: false,
  lastPricePx: '',
  linkedBankAccounts: [],
  onAddBankAccount: () => {},
  frequency: '',
  loadingLimits: true,
  requireAsset: false,
};

AvailableFundsHeader.propTypes = propTypes;

AvailableFundsHeader.defaultProps = defaultProps;

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default AvailableFundsHeader;
