import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  DeliveryContractView,
  MarginDeliveryResponse,
} from '../ts/models/Deliveries/Delivery.model';
import parseDeliveryContractViews from '../components/Deliveries/parseDeliveryContractViews';

/** Action names */
export const MARGIN_DELIVERY = 'MARGIN_DELIVERY';

/** Actions */
export const fetchMarginDelivery = createRoutine(MARGIN_DELIVERY);

/** Initial State */
type MarginDeliveriesState = {
  contractView: DeliveryContractView[];
  count: number;
};

const initialState: MarginDeliveriesState = {
  contractView: [],
  count: 0,
};

/** Reducer */
export default handleActions<MarginDeliveriesState, any>(
  {
    [fetchMarginDelivery.SUCCESS]: (
      state: MarginDeliveriesState,
      { payload }: { payload: MarginDeliveryResponse },
    ) =>
      ({
        ...state,
        ...payload,
        count: get(payload, 'contractView', []).length,
      }) as MarginDeliveriesState,
  },
  initialState,
);

//* Selectors */
export const getMarginDeliveries = (state: any): MarginDeliveriesState =>
  get(state, 'marginDeliveries', {});

export const getDeliveriesContractViews = createSelector(
  [getMarginDeliveries],
  (state) => get(state, 'contractView', []),
);

export const getDeliveriesContractViewsCount = createSelector(
  [getMarginDeliveries],
  (state) => get(state, 'count', 0),
);

export const getDeliveriesTableRows = createSelector(
  [getDeliveriesContractViews],
  (contractViews) => parseDeliveryContractViews(contractViews),
);
