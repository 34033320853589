import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
  Grid,
  Icon,
  Segment,
  Divider,
  GridRow,
  GridColumn,
} from 'semantic-ui-react';
import { hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  createMfTicket,
  getCurrentAuthId,
  getCurrentUserId,
  getLoggedInUserAuth,
} from '../../reducers/userReducer';
import history from '../../constants/history';
import StyledButton from '../../common/StyledButton';
import TextButton from '../../common/TextButton';
import { sizes } from '../../styles/styled';

const Header = styled.h3`
  font-size: 2em;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 10px auto;
  text-align: center;
  padding-top: 10px;
`;

const Text = styled.p`
  font-size: 20px;
  margin: 2em 0px;
`;

const Reset = styled(StyledButton)`
  margin: 10px auto 10px auto;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2em 0px;

  ${Text} {
    margin: 0px;
  }
`;
const Check = styled(Icon)`
  margin-right: 20px !important;
  @media (max-width: ${sizes.SMALL}) {
    display: none !important;
  }
`;

const Reset2faModal = () => {
  const dispatch = useDispatch();
  const auth = useSelector(getLoggedInUserAuth);
  //auth id
  const userId = useSelector(getCurrentAuthId);

  return (
    <>
      <Modal.Content>
        <Header>Reset 2FA</Header>
        {get(auth, ['userMetadata', 'useMfa'], false) ? (
          <>
            <Wrapper>
              <Check name="check circle green" size="huge" />
              <Text>
                Two-Factor Authentication is enabled for your account. If you
                have a new phone and need to reset your 2FA, please{' '}
                <TextButton
                  fontSize="20"
                  text="contact support."
                  onClick={() => {
                    dispatch(hideModal());
                    history.push('/contact');
                  }}
                />
              </Text>
            </Wrapper>
          </>
        ) : (
          <>
            <Text>
              You do not have Two-Factor Authentication enabled for this
              account. Cboe Digital recommends you enable it to increase the
              security of your account.
              <br />
            </Text>
            <Text>
              <strong>
                Note: Setting up 2FA requires all future logins to enter a code
                supplied by your authenticator app.
              </strong>
            </Text>
            <Reset
              text="Enable 2FA"
              primary
              name="reset"
              onClick={() => dispatch(createMfTicket({ userId }))}
            />
          </>
        )}
      </Modal.Content>
    </>
  );
};

export default Reset2faModal;
