import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, Dropdown, Statistic } from 'semantic-ui-react';
import classNames from 'classnames';
import Skeleton from '@material-ui/lab/Skeleton';
import DepositWithdrawalActions from '../components/DepositWithdrawalActions';
import Auth from '../services/Auth';
import AccessControl, { checkPermissions } from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import gaCategories from '../constants/ga-categories';
import { formatFiat, trade, usesSSO } from '../utils/methods';
import { PII_NAME } from '../constants/ddActionNames';
import history from '../constants/history';
import colors from '../constants/colors';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { sizes } from '../styles/styled';
import { ORIENTATIONS } from '../reducers/uiReducer';
import AccountDropdown, {
  getAccountDropdownParams,
} from '../common/components/AccountDropdown';
import {
  DailyChangeStatisticMobile,
  DailyChangeStatistic,
  DailyChange,
} from './DailyChange';
import StyledHeader from '../containers/TopNavContainer/styled';

const MIN_CHAR_LENGTH = 23;

const calcWidthForChar = (maxLengthAccountName) => {
  const baseLeftPadding = 45;
  const pixelPerCharacter = 7.8;
  return baseLeftPadding + pixelPerCharacter * maxLengthAccountName;
};

export const getMaxLengthOfName = (currentAccount) => {
  return currentAccount?.name.length || MIN_CHAR_LENGTH;
};

const AccountSelectorWrapper = styled.div`
  grid-area: acc-sel;
  margin-right: ${(props) => !props.isMobile && '10px'};
  border-left: ${(props) =>
    !props.isMobile && `6px solid ${props.theme.accent}`};
  margin-left: ${(props) => !props.isMobile && '5px'};
  padding-left: 5px !important;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.isMobile && '50%'};
  width: ${(props) =>
    props.isMobile
      ? '50%'
      : `${calcWidthForChar(props.maxLengthAccountName)}px`};

  & > label {
    color: ${colors.GRAY_3};
  }
`;
AccountSelectorWrapper.displayName = 'AccountSelectorWrapper';

export const AccountSelector = styled(AccountDropdown)`
  &&& {
    border-bottom: none !important;
  }
  &&& > input {
    color: white !important;
  }
  padding: 2px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;
Wrapper.displayName = 'TopNavMobileWrapper';

const NavRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightNav = styled.div`
  display: flex;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
  padding: 10px 10px 0px 5px;
  background-color: ${colors.NAVY_2};
`;

const ActionsRow = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const GiftIcon = styled(Icon)`
  color: ${colors.GREEN};
  align-self: center;
  margin-right: 15px !important;
`;
GiftIcon.displayName = 'GiftIcon';

const MobileUserDropdown = styled(Dropdown)`
  align-self: center;
`;
MobileUserDropdown.displayName = 'MobileUserDropdown';

const MobileStats = styled(Statistic)`
  margin-top: 0px !important;
  justify-content: flex-start;
  padding-bottom: 10px;
  && .label {
    color: ${colors.GRAY_3};
    text-align: right;
    text-transform: capitalize;
    font-weight: 400;
  }
  &&& .value {
    font-size: 1.5rem !important;
    @media (max-width: ${sizes.TINY}) {
      font-size: 1.4rem !important;
    }
    @media (max-width: 320px) {
      font-size: 1rem !important;
    }
  }
`;

const getLogoClass = (simple, small) => {
  if (small) {
    return 'small-logo';
  } else if (simple && !small) {
    return 'logo';
  }

  return '';
};

class TopNav extends Component {
  state = {
    topNavSmall: this.props.viewportLessThanMedium,
    topNavExtraSmall: this.props.viewportLessThanSmall,
    topNavMedium:
      (isTablet && this.props.orientation === ORIENTATIONS.PORTRAIT) ||
      (this.props.viewportLessThanLarge && !this.props.viewportLessThanMedium),
  };

  componentDidMount = () => {
    this.auth = new Auth();
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.orientation !== this.props.orientation ||
      nextProps.viewportLessThanLarge !== this.props.viewportLessThanLarge ||
      nextProps.viewportLessThanMedium !== this.props.viewportLessThanMedium ||
      nextProps.viewportLessThanSmall !== this.props.viewportLessThanSmall
    ) {
      this.setState({
        topNavSmall: nextProps.viewportLessThanMedium,
        topNavExtraSmall: nextProps.viewportLessThanSmall,
        topNavMedium:
          (isTablet && nextProps.orientation === ORIENTATIONS.PORTRAIT) ||
          (nextProps.viewportLessThanLarge &&
            !nextProps.viewportLessThanMedium),
      });
    }
  }

  options = (simpleNav) => {
    const {
      accountId,
      enableApiCredentials,
      showModal,
      user,
      userOptionClick,
      loading,
      loadingBalances,
      logout,
    } = this.props;
    return [
      {
        key: 'user-details',
        content: (
          <p>
            <span className="bold" data-dd-action-name={PII_NAME}>
              {_.get(user, 'pii.firstName', user.nickname)}{' '}
              {_.get(user, 'pii.lastName')}
            </span>
            <br />
            {user.email}
          </p>
        ),
      },
      {
        key: 'profile',
        text: 'Profile',
        icon: 'user',
        onClick: () => userOptionClick('profile'),
      },
      {
        key: 'reports',
        text: 'Reports / Statements',
        icon: 'chart bar',
        onClick: () => userOptionClick('reports-statements'),
        hide: !checkPermissions(user.uiViews || [], [
          `${grants.READ}:${subjects.DAILY_STATEMENTS}`,
        ]),
      },
      {
        key: 'linked-accounts',
        text: 'Linked Accounts',
        icon: 'linkify',
        onClick: () => userOptionClick('linked-accounts'),
        hide: !checkPermissions(user.uiViews || [], [
          `${grants.READ}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
        ]),
      },
      {
        key: 'api-settings',
        text: 'API Settings',
        icon: 'cogs',
        onClick: () => userOptionClick('api-settings'),
        hide:
          enableApiCredentials !== 'true' ||
          !checkPermissions(user.uiViews || [], [
            `${grants.CREATE}:${subjects.API_KEYS}`,
          ]),
      },
      {
        key: 'scheduled-deposits',
        text: 'Scheduled Tasks',
        icon: 'money bill alternate outline',
        onClick: () => userOptionClick(`scheduled-deposits`),
      },
      {
        key: 'bank-information',
        text: 'Bank Information',
        icon: 'university',
        onClick: () =>
          userOptionClick(`accounts/${accountId}/bank-information`),
        hide: !accountId,
      },
      {
        key: 'authorized-access',
        text: 'Authorized Access',
        icon: 'key',
        onClick: () => userOptionClick(`authorized-access`),
      },
      {
        key: 'authorized-ips',
        text: 'Authorized IPs',
        icon: 'globe',
        hide: !this.props.canAuthorizeIPs,
        onClick: () => userOptionClick(`authorized-ips`),
      },
      {
        key: 'margin-futures-toggle',
        text: 'Margin Futures',
        icon: 'share',
        hide: !this.props.hasNonFcmMarginFuturesMembers,
        onClick: () => userOptionClick('fcm-clearing'),
      },
      {
        // use lowercase string for simplenav prop to silence warnings.
        key: 'sign-out',
        text: 'Sign Out',
        icon: 'sign out',
        onClick: logout.bind(this),
        simplenav: 'true',
      },
    ]
      .filter((option) => (simpleNav || loading ? option.simplenav : true))
      .filter((option) => !option.hide);
  };

  render() {
    const {
      allAccounts = [],
      accounts,
      accountId,
      clientId,
      loading,
      logoClick,
      simpleNav,
      tradingFrontendEndpoint,
      user,
      currTotalUsdValue,
      prevTotalUsdValue,
      loadingBalances,
      selectMemberOnAccountClick,
      userPermissions,
      activeAccountId,
    } = this.props;

    const { topNavSmall, topNavMedium } = this.state;

    this.trigger = (
      <Fragment>
        <img
          className={`user-picture${isMobileOnly ? '-mobile' : ''}`}
          src={_.get(user, 'auth.picture', '')}
          alt="user"
        />
        <Icon
          name="angle down"
          className={this.state.topNavSmall ? 'small' : 'large'}
        />
      </Fragment>
    );

    const totalLabel = activeAccountId ? 'USD Value' : 'Total USD Value';

    const accountList = allAccounts.map((account) =>
      getAccountDropdownParams(account),
    );

    const currentAccount = accountList.find((acc) => acc.id === accountId);
    return !loading && isMobileOnly ? (
      <Wrapper>
        <NavRow>
          <div
            className="logo-container mobile small-logo"
            onClick={() => logoClick(isMobileOnly)}
          >
            <div role="presentation" className="small-logo" alt="ErisX" />
          </div>
          <RightNav>
            <GiftIcon
              size="large"
              name="gift"
              onClick={() => history.push(`/referrals`)}
            />
            <MobileUserDropdown
              className="user-dropdown"
              trigger={this.trigger}
              options={this.options(simpleNav)}
              pointing="top right"
              icon={null}
            />
          </RightNav>
        </NavRow>

        <DataRow>
          {allAccounts.length > 1 ? (
            <AccountSelectorWrapper isMobile={isMobileOnly}>
              <label htmlFor="account">Account</label>
              <AccountSelector
                placeholder="Please select an account..."
                name="accountId"
                value={currentAccount}
                onChange={(acc) => {
                  this.props.selectMemberOnAccountClick(acc.id);
                  history.push(`/accounts/${acc.id}`);
                }}
                accounts={accountList}
                id="account-selector-mobile"
                disableUnderline
              />
            </AccountSelectorWrapper>
          ) : null}
          {allAccounts.length ? (
            <AccessControl
              allowedPermissions={[`${grants.READ}:${subjects.BALANCES}`]}
            >
              {this.props.selectedMemberId && (
                <MobileStats size="tiny" loading>
                  <Statistic.Label>{totalLabel}</Statistic.Label>
                  <Statistic.Value>
                    {loadingBalances ? (
                      <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height={33}
                        key="total-balances-skeleton"
                      />
                    ) : (
                      formatFiat(currTotalUsdValue, 'USD')
                    )}
                  </Statistic.Value>
                </MobileStats>
              )}
              <DailyChangeStatisticMobile
                size="tiny"
                id="total-balance-day-mobile"
                loading
                style={{ gridAreaTemplate: 'acc-total' }}
              >
                <Statistic.Label>
                  {loadingBalances || prevTotalUsdValue !== '0' ? 'Change' : ''}
                </Statistic.Label>
                <Statistic.Value style={{ textAlign: 'center' }}>
                  {loadingBalances ? (
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width="100%"
                      height={33}
                      key="total-balances-skeleton"
                    />
                  ) : (
                    <DailyChange
                      currTotalUsdValue={currTotalUsdValue}
                      prevTotalUsdValue={prevTotalUsdValue}
                    ></DailyChange>
                  )}
                </Statistic.Value>
              </DailyChangeStatisticMobile>
            </AccessControl>
          ) : null}
        </DataRow>
        <ActionsRow>
          <DepositWithdrawalActions
            user={user}
            accounts={accounts}
            accountId={accountId}
            loading={loading}
            userPermissions={userPermissions}
            tradeProps={{
              clientId,
              onClick: () => {
                trade(
                  this.auth,
                  gaCategories.TRADE,
                  'tradeButton',
                  this.props.defaultTradingApp,
                  this.props.tradingFrontendEndpoint,
                  this.props.clientId,
                );
              },
              isExternal: !usesSSO(this.props.defaultTradingApp),
              tradingFrontendEndpoint,
            }}
          />
        </ActionsRow>
      </Wrapper>
    ) : (
      <StyledHeader
        loading={loading}
        id="top-nav"
        className={classNames({
          'mobile extra-small': topNavSmall,
          simple: simpleNav,
          medium: topNavMedium,
        })}
      >
        <div
          className={`logo-container ${
            !getLogoClass(simpleNav, topNavSmall) && 'hide'
          }`}
        >
          <div
            role="presentation"
            className={getLogoClass(simpleNav, topNavSmall)}
            alt="ErisX"
            onClick={() => {
              if (!simpleNav) {
                logoClick(topNavSmall);
              }
            }}
          />
        </div>
        {!simpleNav && !loading && allAccounts.length > 1 ? (
          <AccountSelectorWrapper maxLengthAccountName={getMaxLengthOfName(currentAccount)}>
            <label htmlFor="account">Account</label>
            <AccountSelector
              placeholder="Click to select an account..."
              name="accountId"
              value={currentAccount}
              onChange={(acc) => {
                this.props.selectMemberOnAccountClick(acc.id);
                history.push(`/accounts/${acc.id}`);
              }}
              searchable
              accounts={accountList}
              id="account-selector-desktop"
            />
          </AccountSelectorWrapper>
        ) : null}
        {!simpleNav && !loading && allAccounts.length ? (
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.BALANCES}`]}
          >
            {this.props.selectedMemberId && (
              <Statistic
                size="tiny"
                id="total-balance-stat"
                loading
                style={{ gridAreaTemplate: 'acc-total' }}
              >
                <Statistic.Label>{totalLabel}</Statistic.Label>
                <Statistic.Value style={{ textAlign: 'left' }}>
                  {loadingBalances ? (
                    <Skeleton
                      variant="rect"
                      animation="wave"
                      width="100%"
                      height={33}
                      key="total-balances-skeleton"
                    />
                  ) : (
                    formatFiat(currTotalUsdValue, 'USD')
                  )}
                </Statistic.Value>
              </Statistic>
            )}
            <DailyChangeStatistic
              size="tiny"
              id="total-balance-day"
              loading
              loadingBalances={loadingBalances}
              style={{ gridAreaTemplate: 'acc-total' }}
            >
              <Statistic.Label>
                {loadingBalances ||
                (prevTotalUsdValue !== '0' && currTotalUsdValue !== NaN)
                  ? 'Change'
                  : ''}
              </Statistic.Label>
              <Statistic.Value style={{ textAlign: 'left' }}>
                {loadingBalances ? (
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width="100%"
                    height={33}
                    key="total-balances-skeleton"
                  />
                ) : (
                  <DailyChange
                    currTotalUsdValue={currTotalUsdValue}
                    prevTotalUsdValue={prevTotalUsdValue}
                  ></DailyChange>
                )}
              </Statistic.Value>
            </DailyChangeStatistic>
          </AccessControl>
        ) : null}

        {!simpleNav && (
          <DepositWithdrawalActions
            user={user}
            accounts={accounts}
            accountId={accountId}
            loading={loading}
            userPermissions={userPermissions}
            tradeProps={{
              clientId,
              onClick: () => {
                trade(
                  this.auth,
                  gaCategories.TRADE,
                  'tradeButton',
                  this.props.defaultTradingApp,
                  this.props.tradingFrontendEndpoint,
                  this.props.clientId,
                );
              },
              isExternal: !usesSSO(this.props.defaultTradingApp),
              tradingFrontendEndpoint,
            }}
          />
        )}
        {!loading && (
          <Dropdown
            className="user-dropdown"
            trigger={this.trigger}
            options={this.options(simpleNav)}
            pointing="top right"
            icon={null}
            data-cy="top-nav-user-dropdown"
          />
        )}
      </StyledHeader>
    );
  }
}

TopNav.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  accountId: PropTypes.string,
  clientId: PropTypes.string,
  defaultTradingApp: PropTypes.string,
  enableApiCredentials: PropTypes.string,
  loading: PropTypes.bool,
  logoClick: PropTypes.func,
  logout: PropTypes.func,
  showModal: PropTypes.func,
  simpleNav: PropTypes.bool,
  tradingFrontendEndpoint: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any),
  userOptionClick: PropTypes.func,
  viewportLessThanMedium: PropTypes.bool,
  viewportLessThanSmall: PropTypes.bool,
  viewportLessThanLarge: PropTypes.bool,
  orientation: PropTypes.oneOf([ORIENTATIONS.LANDSCAPE, ORIENTATIONS.PORTRAIT]),
  canAuthorizeIPs: PropTypes.bool,
};

TopNav.defaultProps = {
  accounts: [],
  accountId: '',
  clientId: '',
  defaultTradingApp: '',
  enableApiCredentials: '',
  loading: false,
  logoClick: () => {},
  logout: () => {},
  showModal: () => {},
  simpleNav: false,
  tradingFrontendEndpoint: '',
  user: {},
  userOptionClick: () => {},
  viewportLessThanMedium: false,
  viewportLessThanSmall: false,
  viewportLessThanLarge: false,
  orientation: ORIENTATIONS.LANDSCAPE,
  canAuthorizeIPs: false,
};

export default TopNav;
