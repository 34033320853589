import React from 'react';
import styled from 'styled-components';
import AccountDropdown, {
  getAccountDropdownParams,
} from '../common/components/AccountDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { getAllAccountsList, selectAccount } from '../reducers/accountsReducer';
import { selectMember } from '../reducers/membersReducer';
import { getActiveAccountId } from '../reducers/activeAccountSelectors';

const MIN_CHAR_LENGTH = 27;

const calcWidthForChar = (maxLengthAccountName: number) => {
  const baseLeftPadding = 45;
  const pixelPerCharacter = 7.85;
  return baseLeftPadding + pixelPerCharacter * maxLengthAccountName;
};

export const AccountSelectorWrapper = styled.div`
  grid-area: acc-sel;
  margin-right: ${(props) => !props.isMobile && '10px'};
  border-left: ${(props) =>
    !props.isMobile && `6px solid ${props.theme.accent}`};
  margin-left: ${(props) => !props.isMobile && '5px'};
  padding-left: 5px !important;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => props.isMobile && '50%'};
  width: ${(props) =>
    props.isMobile
      ? '50%'
      : `${calcWidthForChar(props.maxLengthAccountName)}px`};
  & > label {
    color: ${({ theme }) => theme.text.header};
  }
`;
AccountSelectorWrapper.displayName = 'AccountSelectorWrapper';

export const selectMemberOnAccountClick = (
  accountId,
  allAccounts,
  dispatch,
) => {
  const { memberId } = allAccounts.find((acc) => acc.accountId === accountId);
  dispatch(selectAccount(accountId));
  localStorage.setItem('erisx_account', accountId);
  if (memberId) {
    dispatch(selectMember(memberId));
  }
  localStorage.setItem('erisx_member', memberId);
};

export const AccountSelector = styled(AccountDropdown)`
  &&& {
    border-bottom: none !important;
  }
  &&& > input {
    color: ${({ theme }) => theme.form.field.input.color} !important;
  }
  padding: 2px;
  .MuiAutocomplete-input {
    color: ${({ theme }) => theme.text.header} !important;
  }
`;

export const BoldLabel = styled.label`
  font-weight: bold;
`;

export const getMaxLengthOfName = (currentAccount) => {
  return currentAccount?.name.length || MIN_CHAR_LENGTH;
};

const AccountSelectorFCM: React.FC = () => {
  const dispatch = useDispatch();
  const activeAccountId = useSelector(getActiveAccountId);
  const allAccounts = useSelector(getAllAccountsList);
  const accountList = allAccounts.map((account) =>
    getAccountDropdownParams(account),
  );
  const currentAccount = accountList.find((acc) => acc.id === activeAccountId);

  const userLoading = useSelector((state) =>
    createLoadingSelector(['USER', 'PII', 'ACCOUNTS'])(state),
  );
  const accountsLoading = useSelector((state) =>
    createLoadingSelector(['ACCOUNTS'])(state),
  );
  const loadingAccountSelector = accountsLoading || userLoading;

  return (
    <div>
      {!loadingAccountSelector && allAccounts?.length > 1 ? (
        <AccountSelectorWrapper
          maxLengthAccountName={getMaxLengthOfName(currentAccount)}
        >
          <BoldLabel htmlFor="account">Account</BoldLabel>
          <AccountSelector
            placeholder="Click to select an account."
            name="accountId"
            value={currentAccount}
            onChange={(acc) => {
              selectMemberOnAccountClick(acc.id, allAccounts, dispatch);
            }}
            searchable
            accounts={accountList}
            id="account-selector-desktop"
          />
        </AccountSelectorWrapper>
      ) : null}
    </div>
  );
};

export default AccountSelectorFCM;
