import React from 'react';
import styled from 'styled-components';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getAllMemberAccountsList,
  selectAccount,
} from '../../reducers/accountsReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import { isOpen } from '../../reducers/sidebarReducer';
import {
  updateTutorialStatePromise,
  getUserHasMemberOfType,
} from '../../reducers/userReducer';
import VerticalNav from '../components/VerticalNav';
import VerticalNavMarginFutures from '../components/VerticalNavMarginFutures/VerticalNavMarginFutures';
import { isViewportLessThan } from '../../reducers/uiReducer';
import { getCryptoAssetTypesAsOptionsWidget } from '../../reducers/assetTypesReducer';
import { FCM_MEMBER_TYPE } from '../../constants/memberTypes';
import useIsFCM from '../../hooks/useIsFCM';
import { isNonFCMMarginFuturesPage } from '../../hooks/useExchangeMember';

export const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.sidenav.bg};
  height: 100%;
  font-size: 17px;
  color: ${({ theme }) => theme.sidenav.color};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  // TODO: Add import of image for dark mode
  background-image: none;

  .sidebar-link {
    margin: 10px 0px;
  }

  .sidebar-link.active {
    // TODO: Figure out alignment when border appears
    /* border-left: 3px solid ${({ theme }) => theme.sidenav.active}; */
  }

  .submenu {
    box-shadow: none;
    color: ${({ theme }) => theme.sidenav.active};
  }

  .sidebar-link:hover {
    color: ${({ theme }) => theme.sidenav.active};
  }

  .sidebar-link .title {
    color: ${({ theme }) => theme.sidenav.color};
    padding-top: 5px;
  }

  .sidebar-link:hover {
    box-shadow: none;
    color: ${({ theme }) => theme.sidenav.active};
  }

  // Override CSS site styles
  a {
    color: ${({ theme }) => theme.sidenav.color};
  }
`;
StyledNav.displayName = 'StyledNav';

const mapStateToProps = (state = {}) => ({
  isCurrentUserFCMTypeMember: getUserHasMemberOfType(state, [FCM_MEMBER_TYPE]),
  accounts: getAllMemberAccountsList(state),
  open: isOpen(state),
  viewportLessThanMedium: isViewportLessThan('medium', state),
  assetTypes: getCryptoAssetTypesAsOptionsWidget(state),
  selectedAccountId: getActiveAccountId(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      updateTutorialStatePromise,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      selectAccount,
    },
    dispatch,
  ),
});

const VerticalNavContainer = (props) => {
  const enableMargin = useIsFCM();
  const isNonFCMMarginFutures = isNonFCMMarginFuturesPage();

  return (props.isCurrentUserFCMTypeMember && enableMargin) ||
    isNonFCMMarginFutures ? (
    <VerticalNavMarginFutures {...props} />
  ) : (
    <VerticalNav {...props} />
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerticalNavContainer),
);
