import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Checkbox, Header } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import { isMobileOnly } from 'react-device-detect';
import AssetBalances from '../components/AssetBalances';
import SimpleAssetBalances from '../components/SimpleAssetBalances';
import {
  getTotalBalancesList,
  fetchBalances,
  getSimpleBalancesList,
  fetchAccountsBalances,
  ACCOUNTS_BALANCES,
} from '../reducers/balancesReducer';
import { getAssetTypes } from '../reducers/assetTypesReducer';
import { showSidebar } from '../reducers/sidebarReducer';
import { ORDER_ENTRY_CONTAINER } from '../constants/sideBarTypes';
import { getLatestPrices } from '../reducers/orderEntryReducer';
import { getSelectedMemberId } from '../reducers/membersReducer';
import EnvPromise from '../config/env';

const mapStateToProps = (state, props) => ({
  balances: getTotalBalancesList(props.accountIds)(state),
  loading: createLoadingSelector([ACCOUNTS_BALANCES])(state),
  simpleBalances: getSimpleBalancesList(props.accountIds, 'curr')(state),
  prevSimpleBalances: getSimpleBalancesList(props.accountIds, 'prev')(state),
  assetTypes: getAssetTypes(state),
  prices: getLatestPrices(state),
  selectedMemberId: getSelectedMemberId(state),
});

const mapDispatchToProps = {
  fetchAccountsBalances,
  fetchBalances,
  showSidebar,
};

class AssetBalancesContainer extends Component {
  state = { checked: false, enableTradeWidget: '' };

  componentDidMount = () => {
    const { accountIds, accountLoading, fetchBalances } = this.props;
    if (accountIds.length && !accountLoading) {
      fetchBalances({ accountIds, time: moment().format() });
      fetchBalances({
        accountIds,
        time: moment().subtract(1, 'days').format(),
      });
      // lastPrice is used by the saga to get last prices of assetTypes.
    }
    EnvPromise.then((env) =>
      this.setState({ enableTradeWidget: env.enableTradeWidget }),
    );
  };

  cardOnClick = (asset) => {
    if (asset !== 'USD' && this.state.enableTradeWidget === 'true') {
      this.props.showSidebar(ORDER_ENTRY_CONTAINER, {
        direction: 'bottom',
        assetType: asset,
      });
    }
  };

  toggle = () =>
    this.setState((prevState) => ({ checked: !prevState.checked }));

  componentDidUpdate(prevProps, prevState) {
    const { accountIds, fetchAccountsBalances } = this.props;
    if (prevState.checked === false && this.state.checked) {
      fetchAccountsBalances({ accountIds, lastPrice: true });
    }
  }

  render = () => {
    const {
      account,
      balances = [],
      loading,
      metadata,
      subheaderText,
      simpleBalances,
      assetTypes,
      showSidebar,
      prevSimpleBalances,
      prices,
      accountIds,
      selectedMemberId,
    } = this.props;

    return (
      selectedMemberId && (
        <div className="asset-balances">
          {!isMobileOnly ? (
            <div className="balances-header">
              <Header
                content="Total Balances"
                subheader={this.props.subheaderText}
              />
              {accountIds.length > 0 && (
                <Checkbox
                  id="advanced-view"
                  className="advanced-view"
                  toggle
                  label="Advanced View"
                  onChange={this.toggle}
                  checked={this.state.checked}
                />
              )}
            </div>
          ) : null}
          {this.state.checked ? (
            <AssetBalances
              loading={loading && !balances.length}
              balances={balances}
              metadata={metadata}
            />
          ) : (
            <SimpleAssetBalances
              onClick={this.cardOnClick}
              loading={loading && !balances.length}
              prevBalances={prevSimpleBalances}
              balances={simpleBalances}
              metadata={metadata}
              assetTypes={assetTypes}
              prices={prices}
            />
          )}
        </div>
      )
    );
  };
}

AssetBalancesContainer.defaultProps = {
  accountIds: [],
  subheaderText: 'See the total asset balances across all your accounts.',
  balances: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetBalancesContainer);
