import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import TableFieldTypes from '../../ts/enums/TableFieldTypes.enum';
import {
  castValueToFieldType,
  getCellSelectOptions,
  getInputPropsFromMetadata,
  getParentRows,
  isExpandableColumn,
  isSubrowOfRowInEditMode,
  getSelectedOption,
} from './utils/utils';
import { getFullPathToObjectByRowId } from './utils/tableDataHandling';
import {
  ColumnCreationOptions,
  ColumnEditOptions,
  DefaultCellProps,
  ExpandableColumn,
  TableEditState,
  TableMeta,
  ColumnSelectOption,
} from './types';
import TableInputTypes from '../../ts/enums/TableInputTypes.enum';
import ExpandCell from './ExpandCell';
import NewRowCell from './NewRowCell';
import EditableCell from './EditableCell';
import StyledCheckbox from '../StyledCheckbox';

export const valueHasBeenEdited = (
  originalInputValue,
  prevInputValue,
  value,
) => {
  // For already existing rows, originalInputValue will be defined
  // If so, we consider the cell as edited if its current value is different than the original
  // separate logic for boolean values because
  // falsy values should still be considered
  if (originalInputValue === null && prevInputValue === '' && value === '')
    return false;
  if (isBoolean(originalInputValue)) {
    if (isBoolean(value) && prevInputValue !== value) {
      return true;
    } else {
      return false;
    }
  }
  //If we want to update a 0 to no value, empty string, we need to identify the type of input or data.
  if (isTruthyIncludingZero(originalInputValue)) {
    return String(value) !== String(originalInputValue);
  } else {
    if (isBoolean(value)) {
      // We can not check if value is defined (!!value) for a boolean
      // because we may consider a new value of 'false' as not edited
      if (!originalInputValue) return false;
    }
    // If there's no originalInputValue, we check that we now have a valid value
    // or that the previousInputValue is different than its initialized value
    return !!value || prevInputValue !== '';
  }
};

export const isTruthyIncludingZero = (originalInputValue: any) => {
  return (
    originalInputValue !== undefined &&
    originalInputValue !== null &&
    originalInputValue !== false
  );
};

export const isCellBeingEdited = (rowId: string, rowInEditMode: number) => {
  const rootId = get(rowId?.split('.'), [0]);
  return (
    rowInEditMode === Number(rootId) ||
    isSubrowOfRowInEditMode(rowId, rowInEditMode)
  );
};
type GetDefaultColumnParams<T> = {
  loading?: boolean;
  rowInEditMode?: number;
  expandableColumns?: ExpandableColumn[];
  rowChanges: TableEditState<Partial<T>>;
  rowInputValues: { [key: string]: any };
  isNewlyCreatedRow: (rowId: string) => boolean;
};

// Default column definitions for ALL columns.
// Sets default cell renderer when no one is specified on column metadata
// If column is editable and is in edit mode, return editable input
export const getDefaultColumn = <T,>({
  loading = false,
  rowInEditMode = -1,
  expandableColumns = [],
  rowChanges = { original: {}, updated: {} },
  rowInputValues = {},
  isNewlyCreatedRow,
}: GetDefaultColumnParams<T>): Partial<ColumnDef<T>> => ({
  enableColumnFilter: false,
  // Prevents rows added as padding from being considered for sorting
  sortUndefined: 1,
  // Makes sure all columns use our own filter fn with type safe methods
  filterFn: 'defaultFilterFn',
  cell: (params) => {
    const {
      getValue,
      row,
      column: { id, columnDef },
      cell: { id: cellId },
      table,
    } = params;

    // If it's loading or is empty row, render nothing
    if (loading || isEmpty(row?.original)) return null;

    // Make a unique key for each cell
    const { id: rowId, depth } = row;
    const key = `row_${rowId}_col_${id}_cell_${cellId}`;

    const isBeingEdited = isCellBeingEdited(rowId, rowInEditMode);
    // We render a NewRowCel cell if it's a newlyCreatedRow
    const renderNewRowCell = isNewlyCreatedRow(rowId);
    // We render EditableCell if current row id is the same as the ID of the row being edited or a child of it
    const renderEditableCell = isBeingEdited;
    // We render an empty, expandable row
    const renderExpandCell = isExpandableColumn(columnDef);

    const isExpandable: boolean = isExpandableColumn(columnDef);

    const fieldType: TableFieldTypes = get(
      columnDef,
      'meta.type',
      TableFieldTypes.Text,
    );
    // Get the value to show for this cell
    const showValue = get(columnDef, 'meta.showValue', null);
    const cellValue = getValue();
    const valueToShow = showValue ? showValue(cellValue) : cellValue;

    const originalInputValue = useMemo(() => {
      if (!isEmpty(expandableColumns)) {
        return get(
          rowChanges?.original,
          [...getFullPathToObjectByRowId(rowId, expandableColumns), id],
          null,
        );
      } else {
        return get(rowChanges?.original, [id], null);
      }
    }, [rowChanges.original, rowId, expandableColumns]);
    // Stores the previous user-provided value from the cell
    const prevInputValue = get(rowInputValues, `${cellId}`, '');
    // Current user-provided value for the cell
    const [value, setValue] = React.useState(getValue());

    let hasBeenEdited = useMemo(() => {
      return valueHasBeenEdited(originalInputValue, prevInputValue, value);
    }, [originalInputValue, prevInputValue, value]);

    // Checks if the user provided a new value for the cell
    const hasProvidedNewValue = value !== prevInputValue;

    // Call to table's update function - memoized
    const updateTableData = () => {
      let validatedValue = value;

      //If the validateInput function exists on the row, do the validations for the input field.
      if (!!get(inputProps, 'validateInput', false)) {
        const _value =
          inputProps?.validateInput && inputProps?.validateInput(row, value);

        //Set the valid value.
        validatedValue = _value;
        setValue(_value);
      }

      // Only call the update table data function if the user changed the input value
      (table.options.meta as TableMeta).updateData(
        rowId,
        cellId,
        id,
        castValueToFieldType(fieldType, validatedValue),
      );
    };

    const createOptions: ColumnCreationOptions<T> = get(
      columnDef,
      'meta.createOptions',
      null,
    );

    const editOptions: ColumnEditOptions<T> = get(
      columnDef,
      'meta.editOptions',
      null,
    );

    // Input component to use if depending if its a new cell or edited cell
    const comp = renderNewRowCell
      ? createOptions?.component
      : editOptions?.component;

    // triggers the update call only when a new value is selected
    // with a select component. All other inputs update onBlur.
    useEffect(() => {
      if (
        isBeingEdited &&
        hasBeenEdited &&
        hasProvidedNewValue && // Only call if user manually provided a new value
        (comp === TableInputTypes.Select ||
          columnDef?.meta?.type === TableFieldTypes.Checkbox)
      ) {
        hasBeenEdited = false;
        updateTableData();
      }
    }, [value, prevInputValue, originalInputValue]);

    const inputProps = getInputPropsFromMetadata(columnDef);

    // Sets the value of the input validating that it fits the pattern if there's any
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // We check if there's a user provider pattern to use
      if (get(inputProps, 'pattern', null)) {
        // If input's new value is valid, we use it. If not, we use the previous.
        setValue(e?.target?.validity?.valid ? e?.target?.value : value);
      } else {
        // If there's no pattern to follow, set new value with incoming value
        setValue(e?.target?.value);
      }
    };

    const parentRows = getParentRows(table.getRowModel(), rowId);
    const options = getCellSelectOptions(
      row,
      parentRows,
      renderNewRowCell ? createOptions?.options : editOptions?.options,
    );

    const selectedOption = getSelectedOption(options, value as string | number);

    const props: DefaultCellProps<T> = {
      columnDef,
      depth,
      key,
      name: key,
      isExpandable,
      row,
      parentRows,
      options,
      selectedOption,
      getValue,
      value,
      prevValue: prevInputValue,
      hasBeenEdited,
      updateTableData,
      onChange, // input on change
      setValue,
      inputProps,
      showValue,
    };

    const expandDepth = get(columnDef, 'meta.expandOptions.depth', 0);
    // Cell to render when current cell is of a newly created row
    if (renderNewRowCell) return <NewRowCell {...props} />;
    // Cell to render if current cell is of pre-existing row, is expandable and has no value to show
    // (aka pivot cell)
    if (renderExpandCell) {
      return (
        <ExpandCell
          key={key}
          row={row}
          getValue={getValue}
          depth={expandDepth}
          showValue={showValue}
        />
      );
    }

    // Cell to render when current cell is of an pre-existing row that's being edited
    if (renderEditableCell) return <EditableCell {...props} />;

    // Default to getValue. If that is is undefined (cell has nothing to show) return null

    // showValue functions allows to control how we display a cell's value
    if (fieldType === TableFieldTypes.Checkbox) {
      const isChecked = get(columnDef, 'meta.isChecked', null);
      return isUndefined(cellValue) ? null : (
        <StyledCheckbox readOnly checked={isChecked ? isChecked(row) : false} />
      );
    }
    return isUndefined(cellValue) ? null : valueToShow;
  },
});
