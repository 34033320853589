import { get } from 'lodash';
import { handleActions, createAction } from 'redux-actions';
import { combineReducers } from 'redux';
import { createRoutine } from 'redux-saga-routines';
import { createById } from 'erisxkit/client';
import { createSelector } from 'reselect';

export const SELECT_MEMBER = 'SELECT_MEMBER';
export const MEMBERS = 'MEMBERS';
export const MEMBERS_V2 = 'MEMBERS_V2';
export const HIDE_FUTURES_UPGRADE_MODAL = 'HIDE_FUTURES_UPGRADE_MODAL';

export const selectMember = createAction(SELECT_MEMBER);
export const fetchMembers = createRoutine(MEMBERS);
export const fetchMembersV2 = createRoutine(MEMBERS_V2);

const routine = fetchMembers;
const slice = 'members';
const id = 'memberId';

export const selectedMemberId = handleActions(
  {
    [selectMember]: (state, { payload }) => payload,
  },
  '',
);

const members = handleActions(
  {
    [fetchMembersV2.SUCCESS]: (state, { payload }) => ({
      ...state,
      members: payload.members,
    }),
  },
  {},
);

export default combineReducers({
  selectedMemberId,
  byId: createById(routine, slice, id),
  membersV2: members,
});

export const getSelectedMemberId = (state) => {
  // if selectedMemberId exists then return that
  const memberId = get(state, ['members', 'selectedMemberId'], '');
  if (memberId) {
    return memberId;
  }
  // if there's no selected member id, and there's only one member link, then return that id because that should be selected.
  const links = get(state, ['user', 'memberLinks'], []);
  if (links.length === 1) {
    return links[0].memberId;
  }
  return '';
};

export const getAllMembers = (state) => get(state, ['members', 'byId']);

export const getAllMembersV2 = (state) =>
  get(state, ['members', 'membersV2', 'members'], []);

export const getAllMemberLinks = (state) => get(state, ['user', 'memberLinks']);

export const getSelectedMember = (state) => {
  const selectedMemberId = getSelectedMemberId(state);
  const members = getAllMembersV2(state);
  return members.find((m) => m.memberId === selectedMemberId);
};

export const getActiveMemberFirmCode = (state) => {
  const selectedMember = getSelectedMember(state);
  return selectedMember?.firmCode;
};

export const hasNonFcmMarginFuturesMembers = createSelector(
  [getAllMembersV2],
  (members) => {
    return members.some(
      (m) =>
        m.entitlements.includes('allow_futures_margin_non_fcm') &&
        m.clearingMemberType !== 'FCM',
    );
  },
);
