import { get } from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { FCMClearingResponse, FCMEntity } from '../ts/models/FCMClearing.model';
import { RootState } from '../ts/types/store';
import { createSelector } from 'reselect';

/** Action Names */
export const FETCH_FCM_CLEARING = 'FCM_CLEARING';
export const FETCH_ALL_FCMS = 'FCM_CLEARING_ALL_FCM_MEMBERS';
export const CREATE_FCM_CLEARING = 'CREATE_FCM_CLEARING';
export const REJECT_FCM_CLEARING = 'REJECT_FCM_CLEARING';
export const APPROVE_FCM_CLEARING = 'APPROVE_CLEARING_REQUEST';

/** Routines */
export const fetchFcmClearing = createRoutine(FETCH_FCM_CLEARING);
export const fetchAllFcms = createRoutine(FETCH_ALL_FCMS);
export const createFcmClearing = createRoutine(CREATE_FCM_CLEARING);
export const rejectFcmClearing = createRoutine(REJECT_FCM_CLEARING);
export const approveFcmClearing = createRoutine(APPROVE_FCM_CLEARING);

/** Promisified Routines */
export const createFcmClearingPromiseCreator =
  promisifyRoutine(createFcmClearing);
export const rejectFcmClearingPromiseCreator =
  promisifyRoutine(rejectFcmClearing);
export const approveFcmClearingPromiseCreator =
  promisifyRoutine(approveFcmClearing);

/** Initial State */
type FCMClearingState = {
  fcmMemberClearingRequests: FCMClearingResponse[];
  fcmClearingAllFcms: FCMEntity[];
};

const initialState: FCMClearingState = {
  fcmMemberClearingRequests: [],
  fcmClearingAllFcms: [],
};

/** Reducer */
export default handleActions<FCMClearingState, any>(
  {
    [fetchFcmClearing.SUCCESS]: (state, { payload }) => ({
      ...state,
      fcmMemberClearingRequests: payload.fcmClearingRequests,
    }),
    [fetchAllFcms.SUCCESS]: (state, { payload }) => ({
      ...state,
      fcmClearingAllFcms: payload.fcmMembers,
    }),
  },
  initialState,
);

/** Selectors */

export const getFcmClearingState = (state: RootState): FCMClearingState =>
  get(state, 'fcmClearing', {});

export const getFcmMemberClearingRequests = createSelector(
  [getFcmClearingState],
  (state) =>
    get(state, 'fcmMemberClearingRequests', []) as FCMClearingResponse[],
);

export const getFcmClearingRequestCount = createSelector(
  [getFcmMemberClearingRequests],
  (clearingRequests) => clearingRequests.length,
);

export const getAllFcms = (state: any) => {
  return get(state, 'fcmClearing.fcmClearingAllFcms', []);
};
