import { call, takeLatest, put } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  achDepositLimit,
  achWithdrawalLimit,
  bankDetails,
  createCollateralDepositRequest,
  createFiatDepositRequest,
  REFERENCE_CODE_REQUEST,
  REFERENCE_CODE_SUCCESS,
  REFERENCE_CODE_FAILED,
  CREATE_CRYPTO_WITHDRAWAL_REQUEST,
  CREATE_FIAT_WITHDRAWAL_REQUEST,
  createCryptoWithdrawalRequest,
  createFiatWithdrawalRequest,
  achImmediateDepositLimit,
  fetchFeeParams,
} from '../reducers/fundTransfersReducer';
import api, * as urls from '../api';

function* getReferenceCode(arg) {
  try {
    const referenceCode = yield call(
      api.post,
      urls.REFERENCE_CODE_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: REFERENCE_CODE_SUCCESS, ...referenceCode });
  } catch (e) {
    const errorString = e.response.data ? e.response.data.error : null;
    const invalidStateRegex =
      /is not currently supported in the state of [A-Z]*/;
    if (invalidStateRegex.test(errorString)) {
      const failedReferenceCode = { locationStateError: errorString };
      yield put({ type: REFERENCE_CODE_FAILED, ...failedReferenceCode });
    } else {
      yield put({ type: REFERENCE_CODE_FAILED, payload: e.response });
    }
  }
}

const createFiatWithdrawalRequestSaga = createSaga(
  createFiatWithdrawalRequest,
  CREATE_FIAT_WITHDRAWAL_REQUEST,
  'Submitting withdrawal request...',
  'Your withdrawal request has been submitted.',
);

const createCryptoWithdrawalRequestSaga = createSaga(
  createCryptoWithdrawalRequest,
  CREATE_CRYPTO_WITHDRAWAL_REQUEST,
  'Submitting withdrawal request...',
  'Your withdrawal request has been submitted.',
);

const collateralDepositRequest = createSaga(
  createCollateralDepositRequest,
  'CREATE_COLLATERAL_DEPOSIT_REQUEST',
  'Submitting collateral deposit request...',
  'Your deposit request has been submitted!',
);

const fiatDepositRequest = createSaga(
  createFiatDepositRequest,
  'CREATE_FIAT_DEPOSIT_REQUEST',
  'Submitting fiat deposit request...',
);

export default function* watch() {
  yield takeLatest(
    createFiatWithdrawalRequest.TRIGGER,
    createFiatWithdrawalRequestSaga,
  );
  yield takeLatest(
    createCryptoWithdrawalRequest.TRIGGER,
    createCryptoWithdrawalRequestSaga,
  );
  yield takeLatest(REFERENCE_CODE_REQUEST, getReferenceCode);
  yield takeLatest(
    createCollateralDepositRequest.TRIGGER,
    collateralDepositRequest,
  );
  yield takeLatest(createFiatDepositRequest.TRIGGER, fiatDepositRequest);
  yield takeLatest(
    bankDetails.TRIGGER,
    createSaga(bankDetails, 'BANK_DETAILS'),
  );
  yield takeLatest(
    fetchFeeParams.TRIGGER,
    createSaga(fetchFeeParams, 'FEE_PARAMS'),
  );
  yield takeLatest(
    achWithdrawalLimit.TRIGGER,
    createSaga(achWithdrawalLimit, 'ACH_WITHDRAWAL_LIMIT'),
  );
  yield takeLatest(
    achDepositLimit.TRIGGER,
    createSaga(achDepositLimit, 'ACH_DEPOSIT_LIMIT'),
  );
  yield takeLatest(
    achImmediateDepositLimit.TRIGGER,
    createSaga(achImmediateDepositLimit, 'ACH_IMMEDIATE_DEPOSIT_LIMIT'),
  );
}
