import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import Tooltip from '@material-ui/core/Tooltip';
import { Popper, TextField } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import colors from '../../constants/colors';
import {
  getAccountDescription,
  hasSubAccountTypes,
} from '../../reducers/accountsReducer';

const Type = styled(Chip).attrs({
  className: 'account-dropdown-chip',
})`
  &&& {
    background-color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.accountDropdown.typeChip.bgSelected
        : theme.accountDropdown.typeChip.bg};
    color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.accountDropdown.typeChip.colorSelected
        : theme.accountDropdown.typeChip.color};
    font-weight: bold;
    padding-top: 2px;
    margin: 0px 5px;
  }
`;

const IRA = styled(Chip).attrs({
  className: 'account-dropdown-chip',
})`
  &&& {
    background-color: ${(props) =>
      props.isSelected ? colors.NAVY : colors.GRAY_2};
    color: ${(props) => (props.isSelected ? colors.WHITE : colors.NAVY)};
    font-weight: bold;
    padding-top: 2px;
    margin: 0px 5px;
  }
`;

const Item = styled(MenuItem)`
  display: flex;
  padding: 8px 15px 8px 15px;
  align-items: end;
  cursor: pointer;
`;

const AccountName = styled.p`
  margin-right: 5px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &&& {
    color: ${({ theme, isSelected }) =>
      isSelected
        ? theme.accountDropdown.option.accountNameColorSelected
        : theme.accountDropdown.option.accountNameColor};
  }
`;

const AccountLabel = styled.p`
  color: ${colors.GRAY_1};
  margin-right: 5px;
  margin-bottom: 0px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Selected = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClickableTextField = styled(TextField)`
  input {
    cursor: pointer;
  }
`;
const StyledPopper = styled(Popper)`
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: unset;
  }

  .MuiAutocomplete-listbox {
    padding: 0;
    width: fit-content;
    max-width: ${isMobile ? 400 : 600}px;
  }
  .MuiAutocomplete-option[data-focus='true'] {
    background-color: ${({ theme }) => theme.accountDropdown.option.bgHover};
    color: ${colors.MEDIUM_NAVY};
    .account-dropdown-chip {
      background-color: ${({ theme }) =>
        theme.accountDropdown.typeChip.bgSelected};
      color: ${({ theme }) => theme.accountDropdown.typeChip.colorSelected};
    }
  }
  .MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${({ theme }) => theme.accountDropdown.option.bgHover};
  }
  .MuiListItem-gutters {
    padding-left: 0px;
  }
  .MuiAutocomplete-option {
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: ${({ theme }) => theme.accountDropdown.option.bg};
    &:hover ${AccountName} {
      color: ${({ theme }) =>
        theme.accountDropdown.option.accountNameColorHover};
    }
  }
`;

const Placeholder = styled.div`
  color: ${colors.INPUT_PLACEHOLDER};
`;

const StyledAutocomplete = styled(Autocomplete)`
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 100%;
    &::selection {
      color: ${({ theme }) =>
        theme.accountDropdown.option.accountNameColor}!important;
    }
  }
  .MuiIconButton-root {
    color: ${({ theme }) =>
      theme.accountDropdown.option.accountNameColor}!important;
  }
`;

const useStyles = makeStyles({
  popper: {
    width: 'fit-content',
  },
  select: {
    '& li:hover': {
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
    '& .MuiListItem-root.Mui-selected': {
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
    '& .MuiListItem-root.Mui-focusVisible': {
      color: `${colors.NAVY}`,
      '& .account-dropdown-chip': {
        backgroundColor: `${colors.NAVY} !important`,
        color: `${colors.WHITE}`,
      },
    },
  },
  tooltip: {
    fontSize: '1em',
    backgroundColor: `${colors.GRAY_2}`,
    color: `${colors.NAVY}`,
    '& .MuiTooltip-arrow': {
      color: `${colors.GRAY_2}`,
    },
  },
});

const CustomPopper = (props) => (
  <StyledPopper
    {...props}
    style={{ width: 'unset' }} // Unset width that's set inline by MaterialUI
    placement="bottom-start"
  />
);

// Utility function to get all necessary params of an account
export const getAccountDropdownParams = (account) => ({
  id: get(account, 'accountId', ''),
  name: get(account, 'name', ''),
  label: get(account, 'label', ''),
  value: {
    label: get(account, 'label', ''),
    accountId: get(account, 'accountId', ''),
  },
  allowSpot: get(account, 'allowSpot', false),
  allowFutures: get(account, 'allowFutures', false),
  memberId: get(account, 'memberId', 'false'),
  subAccountCode: get(account, 'subAccountCode', ''),
  iraAcctType: get(account, 'iraAcctType', ''),
  uiViews: get(account, 'uiViews', {}),
});

const AccountDropdown = ({
  accounts,
  label,
  value,
  onChange,
  input,
  id,
  className,
  placeholder,
  searchable,
  disableUnderline,
  theme,
}) => {
  // This component can be used in two ways:
  // 1. Using redux-form. In which case we'd receive an input prop so the component is controlled via the redux-form API. A name prop must be provided too.
  // 2. Stand-alone, controlling it with value and onChange prop
  // Internal component state
  const handleChange = (acc) => onChange(acc); // External side effects
  let selectedAccount = input ? input.value : value;
  let handleOnChange = input ? input.onChange : handleChange;

  useEffect(() => {
    // Set how the internal state is handled
    if (input) {
      // redux-form
      selectedAccount = input.value;
      handleOnChange = input.onChange;
    } else {
      // Stand-alone
      selectedAccount = value;
      handleOnChange = handleChange;
    }
  }, [input, value, onChange]);

  const classes = useStyles(theme);

  // We control Tooltip and List open state so we make sure the tooltip never obstructs
  // visibility of the list
  const [showTooltip, setShowTooltip] = useState(false);
  const [showList, setShowList] = useState(false);
  const openTooltip = () => !showList && setShowTooltip(true);
  const openList = () => setShowList(true);
  const hideList = () => setShowList(false);

  return (
    <Wrapper
      className={className}
      onMouseEnter={openTooltip}
      onMouseLeave={() => setShowTooltip(false)}
      onBlur={hideList}
    >
      {label}
      {searchable ? (
        <Tooltip
          open={showTooltip}
          disableFocusListener
          classes={{ tooltip: classes.tooltip }}
          arrow
          title={selectedAccount.name == null ? '' : selectedAccount.name}
          leaveTouchDelay={400}
        >
          <StyledAutocomplete
            open={showList}
            onOpen={() => setShowTooltip(false)}
            id={id}
            disableClearable
            fullWidth
            value={selectedAccount}
            PopperComponent={CustomPopper}
            onChange={(e, acc) => {
              handleOnChange(acc);
              hideList();
            }}
            getOptionLabel={(option) => option?.name}
            options={accounts}
            noOptionsText="No accounts available"
            filterOptions={(opt, state) =>
              state?.inputValue
                ? opt.filter(
                    (acc) =>
                      acc?.name
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase()) ||
                      acc?.label
                        ?.toLowerCase()
                        .includes(state.inputValue.toLowerCase()),
                  )
                : opt
            }
            renderInput={(params) => {
              params.inputProps.type = 'search';
              return (
                <ClickableTextField
                  {...params}
                  placeholder={placeholder}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {
                      cursor: 'pointer',
                    },
                    onClick: openList,
                  }}
                />
              );
            }}
            renderValue={(selected) => (
              <Tooltip
                disableFocusListener
                classes={{ tooltip: classes.tooltip }}
                arrow
                title={selectedAccount.name == null ? '' : selectedAccount.name}
                leaveTouchDelay={400}
              >
                <Selected>{selected.name}</Selected>
              </Tooltip>
            )}
            renderOption={(acc) => {
              // Manually keep track of the selected status due to materialui not doing it
              // when select value is an object and not a string
              const isSelected = acc.label === selectedAccount.label;
              const type = getAccountDescription(acc);
              return (
                <Item
                  value={acc}
                  key={acc.label}
                  isSelected={isSelected}
                  id={`${id}-${acc.label}`}
                >
                  <AccountName isSelected={isSelected}>{acc.name}</AccountName>
                  <AccountLabel>{`(${acc.label})`}</AccountLabel>
                  {type && (
                    <Type size="small" label={type} isSelected={isSelected} />
                  )}
                  {hasSubAccountTypes(acc, ['IRA']) && !isMobile && (
                    <IRA
                      size="small"
                      label={get(acc, 'iraAcctType', '')}
                      isSelected={isSelected}
                    />
                  )}
                </Item>
              );
            }}
          />
        </Tooltip>
      ) : (
        <Select
          id={id}
          autoWidth
          disableUnderline={disableUnderline}
          value={selectedAccount}
          onChange={(e, acc) => {
            if (e?.target?.value) handleOnChange(e.target.value);
          }}
          displayEmpty
          renderValue={(selected) =>
            selected?.name ? (
              <Tooltip
                disableFocusListener
                classes={{ tooltip: classes.tooltip }}
                arrow
                title={selectedAccount.name == null ? '' : selectedAccount.name}
                leaveTouchDelay={400}
              >
                <Selected>{selected.name}</Selected>
              </Tooltip>
            ) : (
              <Placeholder>{placeholder}</Placeholder>
            )
          }
          MenuProps={{
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {accounts.map((acc) => {
            // Manually keep track of the selected status due to materialui not doing it
            // when select value is an object and not a string
            const isSelected = acc.label === selectedAccount.label;
            const type = getAccountDescription(acc);
            return (
              <Item
                value={acc}
                key={acc.label}
                isSelected={isSelected}
                id={`${id}-${acc.label}`}
              >
                <AccountName isSelected={isSelected}>{acc.name}</AccountName>
                <AccountLabel>{`(${acc.label})`}</AccountLabel>
                {type && (
                  <Type size="small" label={type} isSelected={isSelected} />
                )}
                {hasSubAccountTypes(acc, ['IRA']) && !isMobile && (
                  <IRA
                    size="small"
                    label={get(acc, 'iraAcctType', '')}
                    isSelected={isSelected}
                  />
                )}
              </Item>
            );
          })}
        </Select>
      )}
    </Wrapper>
  );
};

AccountDropdown.defaultProps = {
  accounts: [],
  input: null,
  label: null,
  value: {
    id: '',
    name: '',
    label: '',
    subAccountCode: '',
    iraAcctType: '',
    uiViews: {},
  },
  onChange: () => {},
  className: '',
  placeholder: 'Please select an Account',
  searchable: false,
  disableUnderline: false,
};

AccountDropdown.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      subAccountCode: PropTypes.string,
      iraAcctType: PropTypes.string,
      allowSpot: PropTypes.bool,
      allowFutures: PropTypes.bool,
      uiViews: PropTypes.shape({
        seg: PropTypes.shape({
          read: PropTypes.bool.isRequired,
          create: PropTypes.bool.isRequired,
        }),
        memberProperty: PropTypes.shape({
          read: PropTypes.bool.isRequired,
          create: PropTypes.bool.isRequired,
        }),
      }),
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        subAccountCode: PropTypes.string,
        iraAcctType: PropTypes.string,
        uiViews: PropTypes.shape({
          seg: PropTypes.shape({
            read: PropTypes.bool.isRequired,
            create: PropTypes.bool.isRequired,
          }),
          memberProperty: PropTypes.shape({
            read: PropTypes.bool.isRequired,
            create: PropTypes.bool.isRequired,
          }),
        }),
      }),
      PropTypes.string,
    ]),
    onChange: PropTypes.func.isRequired,
  }),
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    subAccountCode: PropTypes.string,
    iraAcctType: PropTypes.string,
  }),
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  disableUnderline: PropTypes.bool,
};

export default withTheme(AccountDropdown);
