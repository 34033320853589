import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import ClearingRequestActions from '../components/ParticipantAccountManagement/ClearingRequests/ClearingRequestActions';
import {
  ClearingRequestStatus,
  FCMClearingResponse,
} from '../ts/models/FCMClearing.model';
import { getDateObj, getFormattedTimestamp } from '../utils/dateUtils';

const columnHelper = createColumnHelper<FCMClearingResponse>();

export type ClearingRequestMetadata = FCMClearingResponse & {
  action: string;
};

const StyledStatus = styled.p<{ status: ClearingRequestStatus }>`
  color: ${({ status, theme }) => {
    if (status === 'approved') return theme.accent;
    if (status === 'rejected') return theme.accentNegative;
  }};
  font-weight: ${({ status }) => (status === 'pending' ? 400 : 'bold')};
  text-transform: capitalize;
`;

const Status: React.FC<{ status: ClearingRequestStatus }> = ({ status }) => {
  return <StyledStatus status={status}>{status}</StyledStatus>;
};

const clearingRequestsMetadata = [
  columnHelper.accessor((row) => getDateObj(row?.createdAt), {
    id: 'createdAt',
    header: 'Time',
    minSize: 100,
    enableColumnFilter: false,
    sortingFn: 'datetime',
    cell: (props) => getFormattedTimestamp(props),
  }),
  columnHelper.accessor('requestingMemberName', {
    id: 'requestingMemberName',
    header: 'Participant',
    minSize: 100,
    enableColumnFilter: false,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('comment', {
    id: 'comment',
    header: 'Comment',
    minSize: 100,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    minSize: 100,
    enableColumnFilter: false,
    cell: ({ getValue }) => {
      const status = getValue();
      return <Status status={status as ClearingRequestStatus} />;
    },
  }),
  columnHelper.display({
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => {
      const request = row?.original;
      return <ClearingRequestActions request={request} />;
    },
  }),
];

export default clearingRequestsMetadata;
