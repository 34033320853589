import {
  fetchFcmBalanceCycle,
  fetchFcmBalanceCycles,
} from '../reducers/fcmBalanceSnapshotsReducer';
import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(fetchFcmBalanceCycle, generateSaga(fetchFcmBalanceCycle));
  yield takeLatest(fetchFcmBalanceCycles, generateSaga(fetchFcmBalanceCycles));
}
