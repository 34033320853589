import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import { Header } from 'semantic-ui-react';
import { UISegment } from '../../styles/styled';

const Wrapper = styled(UISegment)`
  padding: 1.5em 1.5em;
`;

const Table = styled.table`
  border-collapse: collapse;
  color: #15446e;
  min-width: 80%;
  tr {
    height: 50px;
    width: 150px;
  }
  td:first-child {
    font-weight: bold;
    font-size: 15px;
    padding-left: 10px;
  }
  td:nth-child(2) {
    font-size: 14px;
  }
  tr:nth-child(even) {
    background-color: #f6f6f6a8;
  }
`;

const StyledChip = styled(Chip)`
  &&& {
    padding-top: 2px;
    margin: 0px 5px;
    font-size: 13px;
  }
`;

interface PermissionsMap {
  [key: string]: {
    header: string;
    text: string;
  };
}

export interface PermissionsTableProps {
  header: string;
  userPermissions: string[];
  permissionsMap: PermissionsMap;
}

export const parsePermissions = (
  userPermissions: string[] | null,
  permissionsMap: PermissionsMap,
) => {
  const permissionToGroupBy =
    userPermissions === null ? Object.keys(permissionsMap) : userPermissions;
  // First we group all userPermissions by their header
  const grouped = groupBy(permissionToGroupBy, (permission) => {
    const permissionData = permissionsMap[permission];
    return permissionData ? permissionData.header : 'Misc';
  });
  // Then we map each permission to get its own header
  const result = Object.keys(grouped).map((header) => ({
    header,
    permissions: grouped[header].map((permission) => {
      // If the permission is not included on the permissions object,
      // we show it as
      const text = get(permissionsMap, [permission, 'text'], null);
      return text || permission;
    }),
  }));
  return result;
};
const PermissionsTable = ({
  header,
  userPermissions,
  permissionsMap,
}: PermissionsTableProps) => {
  const parsedPermissions = useMemo(
    () => parsePermissions(userPermissions, permissionsMap),
    [permissionsMap, userPermissions],
  );
  return (
    <Wrapper>
      <Header as="h2">{header}</Header>
      <Table>
        {parsedPermissions.map((permissionGroup) => (
          <tr key={permissionGroup.header}>
            <td>{permissionGroup.header}</td>
            <td>
              {permissionGroup.permissions.map((p) => (
                <StyledChip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={p}
                  key={p}
                />
              ))}
            </td>
          </tr>
        ))}
      </Table>
    </Wrapper>
  );
};

export default PermissionsTable;
