import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import EMPTable8 from '../common/EMPTable8/EMPTable8';
import {
  FetchOptions,
  ServerSideFilter,
  SortFilter,
} from '../common//EMPTable8/types';
import { Trade } from '../ts/models/TRADES.model';
import {
  fetchTrades,
  getTrades,
  getTradesCount,
} from '../reducers/tradesReducer';
import { getAllAccountIds } from '../reducers/accountsReducer';
import { getActiveAccount } from '../reducers/activeAccountSelectors';
import Page from '../common/Page';
import tradesMetadata from '../metadata/TradesMetadata';
import AccountSelectorFCM from './AccountSelector';

const paramsFilterSortingOutput: tradeParamsFilteringSortingType = {
  trade_date: 'date',
  price: 'px',
  quantity: 'qty',
  cgm: 'cgm_number',
};

type tradeParamsFilteringSortingType = {
  trade_date: string;
  price: string;
  quantity: string;
  cgm: string;
};

export const customFilterSortingParams = (
  filterParams: ServerSideFilter[] | undefined,
  sortParams: SortFilter[] | undefined,
) => ({
  filter: filterParams?.map((param) => ({
    ...param,
    attr: Object.keys(paramsFilterSortingOutput).includes(param.attr)
      ? paramsFilterSortingOutput[param.attr]
      : param.attr,
  })),
  sort: sortParams?.map((param) => ({
    ...param,
    attr: Object.keys(paramsFilterSortingOutput).includes(param.attr)
      ? paramsFilterSortingOutput[param.attr]
      : param.attr,
  })),
});

const Trades = () => {
  const dispatch = useDispatch();
  const trades: Trade[] = useSelector(getTrades);
  const tradesCount: number = useSelector(getTradesCount);
  const activeAccount = useSelector(getActiveAccount);
  const accounts: string[] = useSelector(getAllAccountIds);

  const loading = useSelector((state) =>
    createLoadingSelector(['TRADES'])(state),
  );

  const memoizedMetadata = useMemo(() => {
    return tradesMetadata();
  }, []);

  const memoizedFetch = useCallback(
    (options: FetchOptions) => {
      //Default sorting desc
      if (options.sort?.length === 0) {
        options.sort.push({ attr: 'execution_time', value: 'desc' });
      }
      options.limit = 30;
      const updatedOptions = customFilterSortingParams(
        options.filter,
        options.sort,
      );
      dispatch(
        fetchTrades({
          accountId: activeAccount?.accountId ?? accounts[0],
          ...options,
          ...updatedOptions,
          offset: (options.limit || 0) * (options.page || 0),
        }),
      );
    },
    [activeAccount],
  );

  return (
    <>
      <Page header="Trades">
        <AccountSelectorFCM />
        <EMPTable8
          title="TradesTable"
          data={trades}
          columns={memoizedMetadata}
          fetchData={memoizedFetch}
          count={tradesCount}
          loading={loading}
          loadingText="Fetching Trades"
          noDataText={'No Trades could be found'}
          minimumRows={5}
          showActions
          paginated
          dataCy="trades-table"
        />
      </Page>
    </>
  );
};

export default Trades;
