import { connect } from 'react-redux';
import React, { Fragment, useEffect } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import history from '../../constants/history';
import DepositWithdrawal from '../../components/DepositWithdrawal';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import { hideSidebar } from '../../reducers/sidebarReducer';
import { getFundTransfer } from '../../reducers/uiReducer';
import {
  fetchAccountsBalances,
  getSimpleAccountBalancesByAssetType,
  hasBalancesGreaterThanZero,
} from '../../reducers/balancesReducer';

const formatPath = (str) => {
  const [, , accountId, action] = /\/(accounts)\/(.*)\/(\w+)/g.exec(str);
  return {
    action,
    accountId,
  };
};

const mapStateToProps = (state) => ({
  ...getActiveAccount(state),
  fundTransfer: getFundTransfer(state),
  hasBalancesGreaterThanZero: hasBalancesGreaterThanZero(state),
  balances: getSimpleAccountBalancesByAssetType(state),
});

const mapDispatchToProps = {
  hideSidebar,
  fetchAccountsBalances,
};

const DepositWithdrawalContainer = (props) => {
  const { action, accountId } = formatPath(
    get(props, ['location', 'pathname'], ''),
  );

  useEffect(() => {
    props.fetchAccountsBalances({ accountIds: [accountId] });
  }, []);

  return (
    <Fragment>
      <Header size="huge" className="primary left-padded">
        <Header.Subheader
          className="back-link"
          onClick={() => history.goBack()}
        >
          <Icon name="left arrow" />
          Back
        </Header.Subheader>
        {action[0].toUpperCase() + action.substring(1).toLowerCase()}
      </Header>
      <DepositWithdrawal {...props} accountId={accountId} action={action} />
    </Fragment>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DepositWithdrawalContainer),
);
