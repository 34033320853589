import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';
import BankTransfersContainer from '../../containers/FundTransfers/BankTransfersContainer';
import CollateralDepositContainer from '../../containers/FundTransfers/CollateralDepositContainer';
import CollateralWithdrawalContainer from '../../containers/FundTransfers/CollateralWithdrawalContainer';
import CryptoWithdrawalContainer from '../../containers/FundTransfers/CryptoWithdrawalContainer';
import CryptoDepositContainer from '../../containers/FundTransfers/CryptoDepositContainer';
import FiatDepositContainer from '../../containers/FundTransfers/FiatDepositContainer';
import { isViewportLessThan } from '../../reducers/uiReducer';
import { accountIsAllowedCollateral } from '../../utils/permissions';
import AchDepositContainer from '../../containers/FundTransfers/AchDepositContainer';
import EnvPromise from '../../config/env';
import {
  getIsCurrentAccountFutures,
  getAccountDeliveryType,
} from '../../reducers/accountsReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import { DEPOSIT, WITHDRAWAL } from '../../constants/journalTypes';
import { SPOT_DELIVERY } from '../../constants/deliveryTypes';
import AchWithdrawalContainer from '../../containers/FundTransfers/AchWithdrawalContainer';
import { getIsMemberDirect } from '../../reducers/userReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { cardStyle } from '../../styles/styled';

// NOTE: consider making Deposit/Withdrawal buttons dropdowns on individual accounts, and nested
//  dropdowns on home. Allow users to navigate directly to fiat, crypto and collateral.

const StyledPane = styled(Tab.Pane)`
  // Reapply styled component pseudo class so it takes priority over other styles
  && {
    ${cardStyle};
  }
`;

export const accountIsAllowedAch = (
  achEnabledAccounts,
  accountId,
  deliveryType,
) =>
  deliveryType !== SPOT_DELIVERY &&
  (!achEnabledAccounts || achEnabledAccounts.includes(accountId));

const mapStateToProps = (state) => ({
  viewportLessThanSmall: isViewportLessThan('small', state),
  activeAccountId: getActiveAccountId(state),
  isFutures: getIsCurrentAccountFutures(state),
  isCurrentMemberDirect: getIsMemberDirect(getSelectedMemberId(state), state),
  deliveryType: getAccountDeliveryType(state),
});

class FundsTransfer extends PureComponent {
  state = { activeIndex: 0, achEnabledAccounts: null };

  componentDidMount = () => {
    EnvPromise.then(({ achEnabledAccounts = null }) =>
      this.setState({
        achEnabledAccounts,
        activeIndex: this.getDefaultIndex(),
      }),
    );
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.isFutures !== this.props.isFutures) {
      this.setState({
        activeIndex: this.getDefaultIndex(),
      });
    }
  };
  setActivePane = (index) => this.setState({ activeIndex: index });

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  isFuturesDM = () => this.props.isFutures && this.props.isCurrentMemberDirect;

  // By default, Bank Wire tab is on index 1 (FiatDepositContainer)
  // ACH tab is disabled for DM Futures account so only tab that could be added would be
  // Collateral, which would be on index 2, not changing Bank Wire's tab index
  getDefaultIndex = () => (this.isFuturesDM() ? 1 : 0);

  depositPanes = () => {
    const { activeAccountId, isFutures, isCurrentMemberDirect } = this.props;
    const panes = [
      {
        menuItem: !this.props.viewportLessThanSmall
          ? 'Cryptocurrency'
          : 'Crypto',
        render: () => (
          <Tab.Pane>
            <CryptoDepositContainer {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: !this.props.viewportLessThanSmall ? 'Bank Wire' : 'Wire',
        render: () => (
          <Tab.Pane>
            <FiatDepositContainer
              {...this.props}
              isFuturesDM={this.isFuturesDM()}
            />
          </Tab.Pane>
        ),
      },
    ];

    if (
      accountIsAllowedAch(
        this.state.achEnabledAccounts,
        activeAccountId,
        this.props.deliveryType,
      ) &&
      !(isFutures && isCurrentMemberDirect)
    ) {
      panes.unshift({
        menuItem: 'ACH',
        render: () => (
          <Tab.Pane>
            <AchDepositContainer
              type={{ action: DEPOSIT, transfer: 'ACH' }}
              {...this.props}
            />
          </Tab.Pane>
        ),
      });
    }

    if (accountIsAllowedCollateral(this.props)) {
      panes.push({
        menuItem: 'Collateral',
        render: () => (
          <Tab.Pane>
            <CollateralDepositContainer {...this.props} />
          </Tab.Pane>
        ),
      });
    }

    return panes;
  };

  withdrawalPanes = () => {
    const panes = [
      {
        menuItem: !this.props.viewportLessThanSmall
          ? 'Cryptocurrency'
          : 'Crypto',
        render: () => (
          <Tab.Pane>
            <CryptoWithdrawalContainer {...this.props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: !this.props.viewportLessThanSmall ? 'Bank Wire' : 'Wire',
        render: () => (
          <Tab.Pane>
            <BankTransfersContainer
              type={{ action: this.props.action, transfer: 'FWT' }}
              {...this.props}
            />
          </Tab.Pane>
        ),
      },
    ];

    if (
      accountIsAllowedAch(
        this.state.achEnabledAccounts,
        this.props.activeAccountId,
        this.props.deliveryType,
      )
    ) {
      panes.unshift({
        menuItem: 'ACH',
        render: () => (
          <Tab.Pane>
            <AchWithdrawalContainer
              type={{ action: WITHDRAWAL, transfer: 'ACH' }}
              {...this.props}
            />
          </Tab.Pane>
        ),
      });
    }

    return panes;
  };

  panes = () =>
    this.props.action === 'deposit'
      ? this.depositPanes()
      : this.withdrawalPanes();

  render = () => (
    <Fragment>
      <StyledPane>
        <Tab
          onTabChange={this.handleTabChange}
          menu={{ secondary: true }}
          panes={this.panes()}
          activeIndex={this.state.activeIndex}
        />
      </StyledPane>
    </Fragment>
  );
}

export default connect(mapStateToProps, {})(FundsTransfer);
