import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LabeledField from '../../common/LabeledField';
import {
  getLoggedInUser,
  getLoggedInUserApiKeyPermissions,
  getLoggedInUserAuth,
  getLoggedInUserFuturesAcceptedTime,
  getLoggedInUserMemberLinks,
  getLoggedInUserPermissions,
  getLoggedInUserSpotAcceptedTime,
  getPII,
} from '../../reducers/userReducer';
import { Separator, sizes, UISegment } from '../../styles/styled';
import ProfileActions from './ProfileActions';
import {
  documentsCompletedFields,
  firstInfoRowFields,
  secondInfoRowFields,
  secondInfoRowFieldsFMC,
} from './profilePageFields';
import Accounts from '../Profile/Accounts';
import { getAllAccountsList } from '../../reducers/accountsReducer';
import Agreements from './Agreements';
import DownloadApplicationPacket from './DownloadApplicationPacket';
import useIsFCM from '../../hooks/useIsFCM';
import UserEntlitlements from './UserEntitlements';
import PermissionsTable from '../../common/components/PermissionsTable';
import {
  API_KEY_PERMISSIONS,
  MEMBER_USER_PERMISIONS,
} from '../../constants/userPermissions';

// Styled-wrapped components
const Field = styled(LabeledField)``;
const Actions = styled(ProfileActions)``;

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${Field} {
    margin: 0px 25px 25px 0px;
    min-width: 20%;
  }
`;

const Fields = ({ fields, data }) => (
  <FieldRow>
    {fields(data).map((f) => (
      <Field label={f.label} text={f.text} key={f.label} />
    ))}
  </FieldRow>
);

Fields.propTypes = {
  fields: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const AccountSegment = styled(UISegment)`
  padding: 1.5em 1.5em;
`;
const Wrapper = styled.div`
  padding: 25px 20px 20px 20px;
  width: 80%;

  ${Actions} {
    margin-bottom: 20px;
  }

  ${UISegment} {
    margin: 10px 0px 10px 0px;

    ${FieldRow}:nth-child(1) {
      margin-top: 10px;
    }

    ${Separator} {
      margin-top: 0px;
    }
  }

  @media (max-width: ${sizes.LARGE}) {
    width: unset; //80% width for high res screens
  }
`;

const AgreementsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 20px 0px;
  flex-wrap: wrap;
  @media (max-width: ${sizes.SMALL}) {
    > div {
      margin: 15px auto 0px auto;
    }
  }
`;

const ProfilePage = () => {
  const isFCM = useIsFCM();
  const auth = useSelector(getLoggedInUserAuth);
  const pii = useSelector(getPII);
  const memberLinks = useSelector(getLoggedInUserMemberLinks);
  const spotAcceptedTime = useSelector(getLoggedInUserSpotAcceptedTime);
  const futuresAcceptedTime = useSelector(getLoggedInUserFuturesAcceptedTime);
  const user = useSelector(getLoggedInUser);
  const accounts = useSelector(getAllAccountsList);
  const currentMemberUserPermissions = useSelector(getLoggedInUserPermissions);
  const currentMemberUserApiKeyPermissions = useSelector(
    getLoggedInUserApiKeyPermissions,
  );

  return (
    <Wrapper>
      <Actions />
      <AgreementsWrapper>
        <Agreements user={user} />
        <DownloadApplicationPacket user={user} />
      </AgreementsWrapper>
      <UISegment>
        <Fields
          fields={firstInfoRowFields}
          data={{
            email: auth?.email,
            createdAt: auth?.createdAt,
            firstName: pii?.firstName,
            lastName: pii?.lastName,
          }}
        />
        <Fields
          fields={isFCM ? secondInfoRowFieldsFMC : secondInfoRowFields}
          data={{ ...auth, memberLinks, ...pii }}
        />
        <Separator />
        {!isFCM && (
          <>
            <Fields
              fields={documentsCompletedFields}
              data={{ spotAcceptedTime, futuresAcceptedTime }}
            />
            <Separator />
            <UserEntlitlements />
          </>
        )}
      </UISegment>
      <AccountSegment>
        <Accounts accounts={accounts} user={user} />
      </AccountSegment>
      {isFCM && (
        <div>
          <PermissionsTable
            header="Member User Permissions"
            userPermissions={currentMemberUserPermissions}
            permissionsMap={MEMBER_USER_PERMISIONS}
          />
          <PermissionsTable
            header="API Key Permissions"
            userPermissions={currentMemberUserApiKeyPermissions}
            permissionsMap={API_KEY_PERMISSIONS}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ProfilePage;
