import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';

const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > div {
    margin: 0px 5px;
  }
`;
const Step = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${(props) =>
    props.isCurrent ? colors.GREEN : colors.GRAY_1};
  border-radius: 50%;
  display: inline-block;
`;
Step.displayName = 'Step';

export const Nav = ({ currentStep, totalSteps, ...rest }) => {
  return totalSteps ? (
    <NavWrapper>
      {[...Array(totalSteps)].map((step, i) => (
        <Step isCurrent={currentStep === i + 1} {...rest} />
      ))}
    </NavWrapper>
  ) : null;
};
