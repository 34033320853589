import * as actions from '../reducers/authorizedIPsReducer';
import { takeLatest, put } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    actions.fetchAuthorizedIPs.TRIGGER,
    createSaga(actions.fetchAuthorizedIPs, actions.fetchAuthorizedIPs._PREFIX),
  );
  yield takeLatest(
    actions.fetchIpAuthorizationHistory.TRIGGER,
    createSaga(
      actions.fetchIpAuthorizationHistory,
      actions.fetchIpAuthorizationHistory._PREFIX,
    ),
  );
  yield takeLatest(
    actions.addAuthorizedIP.TRIGGER,
    createSaga(
      actions.addAuthorizedIP,
      actions.addAuthorizedIP._PREFIX,
      'Submitting Whitelisting request...',
      'Your Whitelisting request has been submitted',
    ),
  );
  yield takeLatest(
    actions.removeAuthorizedIP.TRIGGER,
    createSaga(
      actions.removeAuthorizedIP,
      actions.removeAuthorizedIP._PREFIX,
      'Submitting removal request...',
      'Your Removal request has been submitted',
    ),
  );
}
