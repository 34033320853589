import { get, find } from 'lodash';
import { fundsDesignation, SEG, MEMBER_PROPERTY } from 'erisxkit/client';

/* Permissions helper methods */

// check if given funds designations include SEG or MEMBER_PROPERTY
export const hasFuturesDesignations = (fundsDesignations) =>
  !!find(
    fundsDesignations,
    ({ value }) =>
      value === fundsDesignation[SEG].value ||
      value === fundsDesignation[MEMBER_PROPERTY].value,
  );

export const accountIsAllowedFutures = (account) =>
  get(account, ['uiViews', fundsDesignation[SEG].key, 'create'], false) ||
  get(
    account,
    ['uiViews', fundsDesignation[MEMBER_PROPERTY].key, 'create'],
    false,
  );

export const accountIsAllowedSubExchange = (account) =>
  !!get(account, 'subExchangeEntitlements', []).length;

export const accountIsAllowedCollateral = (account) =>
  get(account, ['uiViews', 'collateral', 'create'], false);

export const filterFundsDesignationByPermissions = (account) =>
  fundsDesignation.filter((fd) =>
    get(account, ['uiViews', fd.key, 'create'], false),
  );
