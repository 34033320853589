import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const XrpMessage = ({ assetType, inline, message }) =>
  /.?xrp/i.test(assetType) && (
    <p className={`bold text-center${inline && ' inline-block'}`}>
      <Icon name="info circle" />
      {message}
    </p>
  );

XrpMessage.propTypes = {
  assetType: PropTypes.string,
  message: PropTypes.string,
};

XrpMessage.defaultProps = {
  assetType: '',
  message:
    'Cboe Digital requires deposits to include the Destination Tag. Funds sent without a destination tag may not be applied to your account.',
};

export default XrpMessage;
