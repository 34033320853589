import get from 'lodash/get';
import history from '../constants/history';

const ERROR_TYPE = 'invalid_ip';
const INVALID_IP_ERROR_MESSAGE = 'IP_ADDRESS';

const validateIP = (error) => {
  const [type, ip] = get(error, 'response.data.error', '').split(' ');
  if (type === INVALID_IP_ERROR_MESSAGE) {
    history.push(`/error?error_type=${ERROR_TYPE}&error_description=${ip}`);
  } else {
    throw error;
  }
};

export default validateIP;
