import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal, Icon } from 'semantic-ui-react';
import colors from '../../constants/colors';
import StyledButton from '../../common/StyledButton';
import { hideModal } from 'erisxkit/client';

const Check = styled(Icon)`
  color: ${colors.GREEN};
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.p`
  text-align: center;
  font-size: 17px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 15px;
  margin: 20px 0px 15px 0px;
`;

const Continue = styled(StyledButton)`
  width: 150px;
  margin-bottom: 10px;
`;

export default () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Modal.Content scrolling>
        <Col>
          <Header>Invitations Sent!</Header>
          <Check name="check circle outline" size="huge" />
          <Text>
            Your friends will receive an email invitation to join Cboe Digital
            using your referral code.
          </Text>
        </Col>
      </Modal.Content>
      <Modal.Actions>
        <Col>
          <Continue
            primary
            onClick={() => dispatch(hideModal())}
            text="Continue"
          />
        </Col>
      </Modal.Actions>
    </Fragment>
  );
};
