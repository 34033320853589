import React, { useEffect, useMemo } from 'react';
import { Row, TableGenerics } from '@tanstack/react-table';
import isArray from 'lodash/isArray';
import ToggleExpand from '../ToggleExpand';

type Props<T> = {
  row: Row<T>;
  getValue: Function;
  depth: number | number[];
  hidden?: boolean;
  showValue?: (value: string | number | object) => string | number;
};

const isValidDepth = <T,>(row: Row<T>, depth: number | number[]) => {
  if (isArray(depth)) return depth.includes(row.depth);
  return row.depth === depth;
};

const ExpandCell = <T,>({
  row,
  getValue,
  depth = 0,
  hidden = false,
  showValue,
}: Props<T>) => {
  useEffect(() => {
    if (hidden) {
      if (!row.getIsExpanded()) {
        row.toggleExpanded();
      }
    }
  }, []);

  const valid = useMemo(() => isValidDepth(row, depth), [row, depth]);

  const value = showValue ? showValue(getValue()) : getValue();

  return (
    <div
      style={
        {
          // Since rows are flattened by default,
          // we can use the row.depth property
          // and paddingLeft to visually indicate the depth
          // of the row
          // paddingLeft: `${row.depth}rem`,
        }
      }
    >
      {row.getCanExpand() && valid && (
        <ToggleExpand
          isExpanded={row.getIsExpanded()}
          toggle={row.getToggleExpandedHandler()}
          hidden={hidden}
        />
      )}
      {!hidden && value}
    </div>
  );
};

export default ExpandCell;
