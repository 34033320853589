import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Header,
  List,
  Segment,
  Button,
  Popup,
  Grid,
  GridColumn,
  GridRow,
  Divider,
  Message,
  Icon,
  Tab,
} from 'semantic-ui-react';
import _ from 'lodash';
import { isMobileSafari, isIOS, isChrome } from 'react-device-detect';
import moment from 'moment';
import { showModal } from 'erisxkit/client';
import styled from 'styled-components';
import {
  getLoggedInUser,
  createMfTicket,
  updateAuthUser,
  getApplicationPacket,
} from '../reducers/userReducer';
import { CREATE_FUNDING_PASSWORD } from '../constants/modalTypes';
import EnvPromise from '../config/env';
import { isCqg } from '../utils/methods';
import { grants, subjects } from '../constants/userPermissions';
import AccessControl from '../common/AccessControl';
import PersistentCogoToast from '../common/components/PersistentCogoToast';
import {
  DOWNLOAD_NOT_AVAILABLE_ON_CHROME_IOS,
  DOWNLOAD_NOT_AVAILABLE_ON_SAFARI_MOBILE,
} from '../constants/messages';
import { PII_NAME } from '../constants/ddActionNames';
import Accounts from '../components/Profile/Accounts';
import Settings from '../components/Profile/Settings';
import { getAllAccountsList } from '../reducers/accountsReducer';
import Security from '../components/Profile/Security';

const PiiItem = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.text.text} !important;
`;

const PiiTitle = styled.p`
  && {
    color: ${({ theme }) => theme.accent} !important;
  }
`;

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  accounts: getAllAccountsList(state),
});

const mapDispatchToProps = {
  showModal,
  updateAuthUser,
  createMfTicket,
  dispatchGetApplicationPacket: getApplicationPacket,
};

export const userItem = (label, value) => (
  <PiiItem className="pii-item">
    <PiiTitle>{label}</PiiTitle>
    <p>{value || '-'}</p>
  </PiiItem>
);

export class ProfileContainer extends PureComponent {
  state = {};

  componentWillMount = () => {
    EnvPromise.then(({ agreementPdfEndpoint }) =>
      this.setState({ agreementPdfEndpoint }),
    );
  };

  // todo: change to material ui styles
  static styles = {
    button: {
      marginBottom: '1rem',
    },
  };

  handleRequestClick = () => {
    // currently not supporting downloads on safari mobile
    if (isMobileSafari) {
      PersistentCogoToast.warn(DOWNLOAD_NOT_AVAILABLE_ON_SAFARI_MOBILE);
      return false;
    }

    // currently not supporting downloads on chrome for ios
    if (isIOS && isChrome) {
      PersistentCogoToast.warn(DOWNLOAD_NOT_AVAILABLE_ON_CHROME_IOS);
      return false;
    }

    // otherwise request the packet from the backend
    const { user = {}, dispatchGetApplicationPacket } = this.props;
    dispatchGetApplicationPacket({ id: user.userId });

    return true;
  };

  renderDownloadButton(dataUrl) {
    const fileName = 'CboeDigitalApplicationPacket.pdf';

    return (
      <Button primary download={fileName} href={dataUrl}>
        Download Application Packet
      </Button>
    );
  }

  changePassword = () => {
    this.props.showModal(CREATE_FUNDING_PASSWORD, { action: 'reset' });
  };

  render = () => {
    const {
      user = {},
      showModal,
      createMfTicket,
      updateAuthUser,
      dispatchGetApplicationPacket,
      accounts,
    } = this.props;

    const { pii = {} } = user;

    const panes = [
      {
        menuItem: 'Security',
        render: () => (
          <Security
            changePassword={this.changePassword}
            user={user}
            createMfTicket={createMfTicket}
          />
        ),
      },
      {
        menuItem: 'Settings',
        render: () => <Settings user={user} />,
      },
      {
        menuItem: 'Account(s)',
        render: () => <Accounts accounts={accounts} user={user} />,
      },
    ];

    return (
      <section className="main-holdings flex-column" id="profile">
        {/* start */}

        <Segment className="profile-segment" data-dd-action-name={PII_NAME}>
          <div className="pii-column">
            <div as="h1" className="pii-item header">{`${_.get(
              user,
              'pii.firstName',
              '',
            )} ${_.get(user, 'pii.middleName', '')} ${_.get(
              user,
              'pii.lastName',
              '',
            )}`}</div>
            <div>{user.email}</div>
            {userItem('Created At', moment(user.createdAt).format('l'))}
          </div>
          <div className="pii-column">
            {userItem('Street Address', pii.address1)}
            {userItem('State', pii.locationState)}
          </div>
          <div className="pii-column">
            {userItem('Street Address 2', pii.address2)}
            {userItem('Zip', pii.postalCode)}
          </div>
          <div className="pii-column">
            {userItem('City', pii.city)}
            {userItem('Country', pii.country)}
          </div>
        </Segment>
        <Divider />
        <Segment>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Segment>
        <Header content="Agreements:" />
        {/* end */}
        <List>
          {isCqg(user.defaultTradingApp) && (
            <Fragment>
              <AccessControl
                allowedPermissions={[
                  `${grants.READ}:${subjects.TRADE_PROFILE}`,
                ]}
              >
                <List.Item>
                  <List.Icon name="exchange" />{' '}
                  {`CQG Username: ${_.get(
                    user,
                    'cqgUsername',
                    'No CQG Username found.',
                  )}`}
                </List.Item>
                <List.Item>
                  <List.Icon name="exchange" />{' '}
                  {`CQG Trader Id: ${_.get(
                    user,
                    'cqgUserId',
                    'No CQG Trader Id found.',
                  )}`}
                </List.Item>
              </AccessControl>
            </Fragment>
          )}
          {_.has(user, 'agreementAcceptedTime') ? (
            <List.Item>
              <List.Icon name="file outline" />
              <List.Content>
                <a
                  href={this.state.agreementPdfEndpoint}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cboe Digital Member Master Agreement
                </a>
                :
                <span>
                  {' '}
                  Accepted on{' '}
                  {moment(user.agreementAcceptedTime).format('LLLL')}
                </span>
              </List.Content>
            </List.Item>
          ) : null}
        </List>

        <div style={ProfileContainer.styles.button}>
          {user.applicationPacketDataUrl ? (
            this.renderDownloadButton(user.applicationPacketDataUrl)
          ) : (
            <Button
              secondary
              onClick={this.handleRequestClick}
              disabled={user.ongoingApplicationPacketRequest}
            >
              Request Application Packet
            </Button>
          )}
        </div>
      </section>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
