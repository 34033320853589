import get from 'lodash/get';
import isObject from 'lodash/isObject';
import inRange from 'lodash/inRange';
import isFunction from 'lodash/isFunction';
import { AccessorFn, ColumnDef, Row, RowModel } from '@tanstack/react-table';
import {
  ColumnSelectOption,
  ServerSideFilter,
  TableInputProps,
  ColumnOptions,
  AccessorKey,
} from '../types';
import TableFieldTypes from '../../../ts/enums/TableFieldTypes.enum';
import { isIdChildOf } from './tableDataHandling';

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

/*
  -desc: Initialize single-valued filter inputs. Provides abstraction for local and server side tables. 
  @param - column: Current filter value, whose shape depends on local or server side filtering
  @param - local: defines wheter or not the table is using local filtering/sorting
*/
export const getFilterInitialValue = (
  column: ServerSideFilter[] | string,
  local?: boolean,
) => {
  if (local) return column;
  return get(column, '[0].value', '');
};

/*
  -desc: Initialize nth-filter of a multi-valued filter. Provides abstraction for local and server side tables.
  @param - column: Current filter value, whose shape depends on local or server side filtering
  @param - local: defines wheter or not the table is using local filtering/sorting
  @param - filterNo: index of the filter value to return (for multi-valued filters). Start from 0
*/
export const getNthFilterInitialValue = (
  column: ServerSideFilter[] | string[] | string,
  local?: boolean,
  filterNo?: number,
) => {
  if (local) return get(column, `${[filterNo]}`, '');
  return get(column, `${[filterNo]}.value`, '');
};

// Lodash' inRange is not inclusive, so we manually check for lower/upper bounds
const inRangeInclusive = (value: number, start: number, end: number) =>
  inRange(value, start, end) || value === start || value === end;

// A field may only be editable for certain row depths.
// Validate wheter or not to allow edition
export const isValidDepth = (
  depth: number,
  lowerBound: number | null,
  upperBound: number | null,
) => {
  const validLower = typeof lowerBound === 'number';
  const validUpper = typeof upperBound === 'number';
  // and if no bound is provided, field is editable for any row depth
  if (!validLower && !validUpper) return true;

  // Only lower bound provided
  if (validLower && !validUpper)
    return inRangeInclusive(depth, lowerBound, Infinity);

  // Only upper bound provided
  if (!validLower && validUpper) return inRangeInclusive(depth, 0, upperBound);

  return inRangeInclusive(depth, lowerBound || 0, upperBound || 0);
};

export const getMainRowFromId = (rowId: string): number => {
  if (!rowId) return 0; // Asume is parent
  return Number(rowId.split('.')[0]);
};

export const isNumeric = (value: any): boolean =>
  value && !Number.isNaN(Number(value));

export const castValueToFieldType = (
  type: TableFieldTypes,
  value: string | number,
) => {
  switch (type) {
    case TableFieldTypes.Number:
      return Number(value);
    case TableFieldTypes.Text:
      return String(value);
    case TableFieldTypes.Checkbox:
      return Boolean(value);
    default:
      return String(value);
  }
};

export const isRowInEditMode = (rowId: number, editRowId: number) =>
  editRowId === rowId;
export const isSubrow = (rowId: string) => rowId && rowId.includes('.');
export const isSubrowOfRowInEditMode = (rowId: string, editRowId: number) => {
  return isSubrow(rowId) && rowId[0] === String(editRowId);
};

export const isEditableColumn = <T>(columnDef: ColumnDef<T>): boolean =>
  get(columnDef, 'meta.editOptions.canEdit', false);
export const isExpandableColumn = <T>(columnDef: ColumnDef<T>): boolean =>
  get(columnDef, 'meta.expandOptions.canExpand', false);

export const getInputPropsFromMetadata = <T>(
  columnDef: ColumnDef<T>,
): TableInputProps<T> => ({
  type: get(columnDef, 'meta.type', TableFieldTypes.Text),
  pattern: get(columnDef, 'meta.pattern', undefined),
  validateInput: get(columnDef, 'meta.validateInput', undefined),
});

/** 
  -desc: User can provide a list of select options or a function that returns one. 
  This abstraction function allows to handle both cases.
  @param - column: Current filter value, whose shape depends on local or server side filtering
  @param
*/
export const getCellSelectOptions = <T>(
  currentRow: Row<T>,
  parentRows: Row<T>[],
  options?: ColumnOptions<T>,
): ColumnSelectOption[] => {
  if (isFunction(options)) return options(currentRow, parentRows);

  return options || [];
};

/**
 * - desc: A row can be a subrow of a parent row.
 *   This method returns all parent rows up to the root row
 *   @param rowModel: rowModel of the table instance
 *   @param rowId: Id of the row from which the main row is to be obtained
 */
export const getMainRowFromRowId = <T>(
  rowModel: RowModel<T>,
  rowId: string,
): Row<T> => {
  const mainId = getMainRowFromId(rowId);
  return get(rowModel, `rowsById[${mainId}]`, {});
};

export const getParentRows = <T>(
  rowModel: RowModel<T>,
  rowId: string,
): Row<T>[] => {
  const rowsById = get(rowModel, 'rowsById', {});
  const rowEntries = Object.entries(rowsById);
  const rows: Row<T>[] = [];
  rowEntries.forEach((entry) => {
    const [id, row] = entry;
    if (isIdChildOf(id, rowId)) {
      rows.push(row);
    }
  });
  return rows;
};

export const fillToMinimumRows = <T>(data: T[] = [], minimumRows: number) => {
  if (data.length < minimumRows) {
    const rowsToFill = minimumRows - data.length;
    return [...data, ...new Array(rowsToFill).fill({})] as T[];
  }
  return data;
};

/***
 * @description: Given a string or numeric value and a list of options
 *  returns the corresponding option
 * @param - options: List of options to feed a StyledSelect component
 * @param - value: current option
 */
export const getSelectedOption = (
  options: ColumnSelectOption[],
  value: string | number,
) =>
  options.find((option) => {
    if (isObject(option.value)) {
      // If option value is an object we check if any of its
      // keys has a value matching the current Value
      return Object.values(option.value)
        .map((val) => String(val))
        .includes(String(value));
    }
    return String(option.value) === String(value);
  });

/***
 * @description: Receives a ColumnDef and returns its accessor
 * @param columnDef - ColumnDef
 * @returns {AccessorKey | AccessorFn} accessor of ColumnDef
 */
export const getColumnDefAccessor = <T>(
  colDef: ColumnDef<T>,
): AccessorKey | AccessorFn<T> | undefined => {
  const accessorKey = get(colDef, 'accessorKey');
  const accessorFn = get(colDef, 'accessorFn');
  const accessor = accessorKey || accessorFn;
  return accessor || undefined;
};
