import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import StepWizard from 'react-step-wizard';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import Identification from '../../components/onBoarding/Identification';
import FileUploadId from '../../components/onBoarding/FileUploadId';
import FacePhoto from '../../components/onBoarding/FacePhoto';
import {
  FILE_UPLOAD,
  UPLOAD_REJECTED,
  TAKE_PHOTO,
} from '../../constants/modalTypes';
import {
  updateImage,
  updateImagesPromiseCreator,
  submitImagesPromiseCreator,
  images,
  idTypes,
  fetchIdTypes,
  getImagesState,
} from '../../reducers/fileUploadReducer';
import * as userStates from '../../constants/userStates';
import * as appStates from '../../constants/appStates';
import * as onBoardingSteps from '../../constants/onBoardingSteps';
import LoadingComponent from '../../common/components/xtable/LoadingComponent';
import {
  fetchUser,
  fetchUserPromiseCreator,
  getLoggedInUser,
} from '../../reducers/userReducer';
import { trackEvent } from '../../common/tracking';
import { AUTH_TOKEN } from '../../components/onBoarding/Acuant/ClassificationService';

class IdentificationIndex extends Component {
  state = {
    idType: '',
    supportingDoc: false,
  };

  wizard = null;

  componentDidMount = () => {
    this.isInitialized = false;
    this.isIntializing = false;
    this.loadScript();
    this.props.images();
    this.props.idTypes();
    this.setState({
      idType: get(
        this.props,
        ['location', 'state', 'idType', 'type'],
        'drivers_license',
      ),
      supportingDoc: get(
        this.props,
        ['location', 'state', 'supportingDoc'],
        false,
      ),
    });

    document.addEventListener('DOMContentLoaded', () => {
      console.log('dom content loaded');
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const nextAppState = get(nextProps, ['user', 'appState']);
    const curAppstate = get(this.props, ['user', 'appState']);

    if (nextAppState !== curAppstate) {
      // if the user appState changes, go to the correct step.
      if (nextAppState === appStates.FACE_ID_IMAGES) {
        this.wizard.goToStep(onBoardingSteps.FACE_ID);
      }
      if (nextAppState === appStates.ID_IMAGES) {
        this.wizard.goToStep(onBoardingSteps.IDENTIFICATION);
      }
    }
  };

  getIdTypeDetails = (type) =>
    this.props.idOptions.filter((option) => option.type === type)[0];

  rejectFile = () => {
    this.props.showModal(UPLOAD_REJECTED, { size: 'mini' });
  };

  // callback function for when upload front/back buttons are clicked
  onIdClick = (name) => {
    const webcam = name === 'webcam';
    const dropzone = name === 'dropzone';
    this.props.showModal(FILE_UPLOAD, {
      type: name,
      onConfirm: this.onConfirm,
      webcam,
      dropzone,
    });
  };

  takePhoto = (imageType, step) => {
    const { supportingDoc } = this.state;
    this.props.history.push('/capture', {
      imageType,
      idType: this.getIdTypeDetails(this.state.idType),
      step,
      supportingDoc,
    });
  };

  // callback function for when user wants to submit both pictures
  onSubmit = async () => {
    await this.props.submitImagesPromiseCreator();
    this.props.fetchUser();
  };

  getStep = () =>
    get(
      this.props,
      ['location', 'state', 'step'],
      (() => {
        if (this.props.appState === appStates.ID_IMAGES) {
          return 2;
        }
        return 1;
      })(),
    );

  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    let supportingDoc = false;
    if (obj.value === 'passport') {
      supportingDoc = true;
    }

    this.setState({
      ...change,
      supportingDoc,
    });
  };

  getIdTypeDetails = (type) =>
    this.props.idOptions.filter((option) => option.type === type)[0];

  // callback function for when user selects a picture and confirms
  uploadFile = (file, type, nextStep) => {
    // for face uploads
    const payload = {
      imageType: type,
      image: file,
    };
    if (type === 'face') {
      trackEvent('AMLKYC', 'selfieUpload');
      // for id uploads
    } else {
      trackEvent(
        'AMLKYC',
        type === 'id_front' ? 'IDuploadFront' : 'IDuploadBack',
      );
      payload.idType = this.state.idType;
    }
    this.props
      .updateImagesPromiseCreator(payload)
      .then(() => nextStep && nextStep());
    this.props.hideModal(FILE_UPLOAD);
  };

  handleSupportingDocChange = () =>
    this.setState((prevState) => ({ supportingDoc: !prevState.supportingDoc }));

  initialize = () => {
    console.log('initialize called');
    if (!this.isInitialized && !this.isIntializing) {
      this.isIntializing = true;
      console.log('initializing');

      window.AcuantJavascriptWebSdk.initialize(
        AUTH_TOKEN,
        'https://us.acas.acuant.net',
        {
          onSuccess: () => {
            console.log('initialized success');
            window.AcuantJavascriptWebSdk.startWorkers(() => {
              this.isInitialized = true;
              this.isIntializing = false;
            });
          },
          onFail: (response, text) => {
            console.error('initialized failed', response, text);

            this.isIntializing = false;
            datadogRum.addAction('AcuantSdkInitializationFailure', {
              response,
            });
          },
        },
      );
    }
  };

  loadScript = () => {
    if (window.onAcuantSdkLoaded) return;

    const sdk = document.createElement('script');
    sdk.src = 'AcuantJavascriptWebSdk.min.js';
    // sdk.async = true;
    sdk.onload = () => {
      console.log('AcuantJavascriptWebSdk loaded');
      window.loadAcuantSdk();
    };
    window.onAcuantSdkLoaded = this.initialize;
    document.head.appendChild(sdk);

    const acuantCamera = document.createElement('script');
    acuantCamera.src = 'AcuantCamera.min.js';
    acuantCamera.async = true;
    document.body.appendChild(acuantCamera);
  };

  steps = () => (
    <StepWizard
      initialStep={this.getStep()}
      isLazyMount
      instance={(instance) => (this.wizard = instance)}
    >
      <FacePhoto
        hashKey="face"
        handleChange={this.handleChange}
        onConfirm={this.uploadFile}
        imagesState={this.props.imagesState}
        loading={this.props.updateImageLoading}
      />
      <Identification
        hashKey="id"
        handleChange={this.handleChange}
        options={this.props.idOptions}
        onClick={this.onIdClick}
        imagesState={this.props.imagesState}
        selectedIdType={this.state.idType}
        loading={this.props.updateImageLoading}
      />
      <FileUploadId
        onSubmit={this.onSubmit}
        hashKey="upload"
        takePhoto={this.takePhoto}
        uploadFile={this.uploadFile}
        rejectFile={this.rejectFile}
        imagesState={this.props.imagesState}
        selectedIdType={this.getIdTypeDetails(this.state.idType)}
        loading={this.props.updateImageLoading}
        submitLoading={this.props.submitImagesLoading}
        supportingDocChange={this.handleSupportingDocChange}
        supportingDoc={this.state.supportingDoc}
      />
    </StepWizard>
  );

  render() {
    if (this.props.user.appState === appStates.COMPLIANCE) {
      this.props.goToStep(onBoardingSteps.COMPLIANCE);
    }

    if (
      this.props.user.appState === appStates.IMAGES_SUBMITTED ||
      this.props.user.appState === appStates.APPROVED ||
      this.props.user.state === userStates.PII_SUBMITTED
    ) {
      const messageText = 'Please wait while we process your application.';

      return (
        <LoadingComponent
          message={{ text: messageText }}
          action={this.props.fetchUser}
        />
      );
    }
    return <Fragment>{this.steps()}</Fragment>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      hideModal,
      showModal,
      updateImage,
      images,
      idTypes,
      fetchUser,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      fetchUserPromiseCreator,
      submitImagesPromiseCreator,
      updateImagesPromiseCreator,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  imagesState: getImagesState(state),
  idOptions: fetchIdTypes(state),
  updateImageLoading: createLoadingSelector(['UPDATE_IMAGE'])(state),
  submitImagesLoading: createLoadingSelector(['SUBMIT_IMAGES'])(state),
  user: getLoggedInUser(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(IdentificationIndex));
