import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import { pulse } from '../styles/effects';
import { getStyledButtonThemeType } from '../styles/styledButtonThemes';

const getButtonThemeProperty = (themes, themeType, property) =>
  get(themes, ['button', themeType, property], '');

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.hasIcon ? 'baseline' : 'center')};
  border: 1px solid;
  padding: 0.78571429rem 1.5rem 0.78571429rem;
  font-weight: bold;
  cursor: ${(props) => !props.disabled && 'pointer'};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  border-color: ${({ disabled, themeType, theme }) => {
    if (disabled)
      return getButtonThemeProperty(theme, themeType, 'borderColorDisabled');

    return getButtonThemeProperty(theme, themeType, 'borderColor');
  }};
  background-color: ${({ disabled, themeType, theme }) => {
    if (disabled)
      return getButtonThemeProperty(theme, themeType, 'bgColorDisabled');

    return getButtonThemeProperty(theme, themeType, 'bgColor');
  }};
  color: ${({ disabled, theme, themeType }) => {
    if (disabled)
      return getButtonThemeProperty(theme, themeType, 'colorDisabled');

    return getButtonThemeProperty(theme, themeType, 'color');
  }};

  &:hover {
    background-color: ${({ disabled, theme, themeType }) => {
      if (disabled) return;

      return getButtonThemeProperty(theme, themeType, 'bgColorHover');
    }};
  }

  &:active {
    background-color: ${({ disabled, theme, themeType }) => {
      if (disabled) return;

      return getButtonThemeProperty(theme, themeType, 'bgColorActive');
    }};
  }

  ${(props) =>
    props.pulse
      ? css`
          ${pulse}
        `
      : null};
`;
Button.displayName = 'StyledButton';

const StyledButton = ({
  className,
  onClick,
  disabled,
  type,
  primary,
  secondary,
  text,
  pulse,
  icon,
  danger,
  sell,
  'data-cy': dataCy,
}) => (
  <Button
    className={className}
    onClick={onClick}
    disabled={disabled}
    type={type}
    pulse={pulse}
    hasIcon={!!icon}
    themeType={getStyledButtonThemeType(primary, secondary, danger, sell)}
    data-cy={dataCy}
  >
    {icon && <Icon name={icon} />}
    {text}
  </Button>
);

StyledButton.defaultProps = {
  disabled: false,
  primary: false,
  secondary: false,
  danger: false,
  sell: false,
  type: 'button',
  onClick: () => {},
  icon: null,
  dataCy: '',
};

StyledButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  sell: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  dataCy: PropTypes.string,
};

export default StyledButton;
