import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import StyledButton from '../../common/StyledButton';
import {
  createFcmClearingPromiseCreator,
  fetchFcmClearing,
  getAllFcms,
} from '../../reducers/fcmClearingReducer';
import {
  FCMClearingPostRequest,
  FCMEntity,
} from '../../ts/models/FCMClearing.model';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  valid: boolean;
};

const Container = styled.div`
  padding: 15px;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  padding: 2em;
`;

const StyledField = styled(Field)`
  flex: 1 1 0;
  margin-right: 10px !important;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(StyledButton)`
  width: 150px;
`;
const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const REQUEST_FCM_CLEARING_FORM = 'requestFCMClearingForm';

const convertFcmListToFcmOptions = (fcms: FCMEntity[]) => {
  return fcms.map((f) => ({
    key: f.memberId,
    value: f.memberId,
    text: f.name,
  }));
};

const RequestFCMClearing = ({ valid }: Props) => {
  const dispatch = useDispatch();
  const values: Partial<FCMClearingPostRequest> = useSelector((state) =>
    getFormValues(REQUEST_FCM_CLEARING_FORM)(state),
  );

  const firmCode = useSelector(getActiveMemberFirmCode);
  const allFcmList = useSelector(getAllFcms);
  const fcmOptions = useMemo(() => {
    return convertFcmListToFcmOptions(allFcmList);
  }, [allFcmList]);

  const onSubmit = () => {
    createFcmClearingPromiseCreator(
      {
        ...values,
        request_id: uuidv4(),
        urlParams: { firmCode },
      },
      dispatch,
    ).then(() => {
      dispatch(fetchFcmClearing({ urlParams: { firmCode } }));
      dispatch(hideModal());
    });
  };

  return (
    <Container>
      <Header>{`Request Margin Futures Clearing Through An FCM`}</Header>
      <Modal.Content>
        <StyledForm>
          <StyledField
            component={renderDropdown}
            label="FCM"
            fluid
            required
            placeholder="Select"
            name="fcm_member_id"
            options={fcmOptions}
            validate={[rules.required]}
            minimal
            data-testid="fcm-select"
          />
          <StyledField
            component={renderField}
            placeholder="(Optional)"
            name="comment"
            id="comment"
            type="text"
            label="Comment"
            style={{ height: '38px' }}
            minimal
          />
        </StyledForm>
      </Modal.Content>
      <Actions>
        <Action text="Cancel" onClick={() => dispatch(hideModal())} />
        <Action text="Submit" onClick={onSubmit} primary disabled={!valid} />
      </Actions>
    </Container>
  );
};

export default reduxForm({
  form: REQUEST_FCM_CLEARING_FORM,
})(RequestFCMClearing);
