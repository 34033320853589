import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';
import { hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import StyledButton from '../../../common/StyledButton';
import ModalDetails, { InfoField } from '../ModalDetails';
import {
  ClearingRequestAction,
  FCMClearingResponse,
} from '../../../ts/models/FCMClearing.model';
import {
  approveFcmClearingPromiseCreator,
  fetchFcmClearing,
  rejectFcmClearingPromiseCreator,
} from '../../../reducers/fcmClearingReducer';
import { fetchTradingParticipants } from '../../../reducers/participantManagementReducer';
import { getActiveOrFirstAccountLabel } from '../../../reducers/accountsReducer';
import { useAppSelector } from '../../../hooks/useAppSelector';

const Button = styled(StyledButton)`
  min-width: 150px;
  margin: 0px 15px;
`;

const Header = styled(Modal.Header)`
  text-align: center;
  color: ${({ theme }) => theme.ui.subheader} !important;
`;
const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
`;

const ClearingRequestModal: React.FC<{
  request: FCMClearingResponse;
  action: ClearingRequestAction;
}> = ({ action, request }) => {
  const dispatch = useDispatch();
  const isApproval = useMemo(() => action === 'approve', [action]);
  const header = `${isApproval ? 'Approve' : 'Reject'} Request`;
  const subHeader = `You are about to ${action} this request`;
  const buttonText = isApproval ? 'Approve' : 'Reject';
  const firmCode = request?.fcmFirmCode;
  const onCancel = useCallback(() => dispatch(hideModal()), [dispatch]);
  const accountLabel: string = useAppSelector(getActiveOrFirstAccountLabel);

  const onClick = useCallback(async () => {
    try {
      if (isApproval) {
        await approveFcmClearingPromiseCreator(
          {
            urlParams: {
              firmCode,
              requestId: request.requestId,
            },
          },
          dispatch,
        );
        dispatch(
          fetchTradingParticipants({
            urlParams: {
              accountLabel,
            },
          }),
        );
      } else {
        await rejectFcmClearingPromiseCreator(
          {
            urlParams: {
              firmCode,
              requestId: request.requestId,
            },
          },
          dispatch,
        );
      }
      onCancel();
    } catch (e) {
      onCancel();
    }
    dispatch(
      fetchFcmClearing({
        urlParams: {
          firmCode,
        },
      }),
    );
  }, [isApproval, request, firmCode, dispatch, onCancel, accountLabel]);

  const fields = useMemo(() => {
    const participantName = get(request, 'requestingMemberName');
    const comment = get(request, 'comment');
    return [
      {
        header: 'Participant',
        text: participantName || '-',
      },
      {
        header: 'Comment',
        text: comment || '-',
      },
    ] as InfoField[];
  }, [request]);

  return (
    <>
      <Header>{header}</Header>
      <Modal.Content>
        <ModalDetails subHeader={subHeader} fields={fields} />
      </Modal.Content>
      <Actions>
        <Button secondary text="Cancel" onClick={onCancel} />
        <Button
          primary
          danger={!isApproval}
          text={buttonText}
          onClick={onClick}
        />
      </Actions>
    </>
  );
};

export default ClearingRequestModal;
