import { connect } from 'react-redux';
import React, { Component, Fragment } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import assetMovementsMetadata from '../../metadata/assetMovementsMetadata';
import AssetMovements from '../../components/History/AssetMovements';
import {
  fetchJournalHistory,
  fetchRequestHistory,
  getJournalHistory,
  getRequestHistory,
} from '../../reducers/accountHistoryReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import * as journalTypes from '../../constants/journalTypes';
import { showModal } from 'erisxkit/client';
import { TRANSACTION_INFORMATION } from '../../constants/modalTypes';

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  accountId: getActiveAccountId(state),
  journalHistory: getJournalHistory(state),
  requestHistory: getRequestHistory(state),
  journalHistoryLoading: createLoadingSelector(['JOURNAL_HISTORY'])(state),
  requestHistoryLoading: createLoadingSelector(['REQUEST_HISTORY'])(state),
});

const mapDispatchToProps = {
  fetchJournalHistory,
  fetchRequestHistory,
  showModal,
};

export const ASSET_MOVEMENTS_JOURNAL_TYPES = [
  journalTypes.DEPOSIT,
  journalTypes.WITHDRAWAL,
  journalTypes.FEE_REBATE,
  journalTypes.GENERAL,
  journalTypes.REVERSAL,
  journalTypes.DELIVERY,
  journalTypes.INTERNAL_TRANSFER,
];

class AssetMovementsContainer extends Component {
  fetchData = (state, type) => {
    let filters = {};
    if (state.filtered.length !== 0) {
      filters = state.filtered.reduce((obj, item) => {
        obj[item.id] = item.value;
        return obj;
      }, {});
    }
    const payload = {
      types: ASSET_MOVEMENTS_JOURNAL_TYPES,
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      accountIds: this.props.accountId,
      ...filters,
    };

    if (type === 'Pending') {
      this.props.fetchRequestHistory({
        ...payload,
        state: ['pending', 'rejected'],
      });
    } else {
      this.props.fetchJournalHistory(payload);
    }
  };

  moreInfo = (data) => {
    this.props.showModal(TRANSACTION_INFORMATION, {
      data,
      size: 'mini',
      type: 'asset_movement',
    });
  };

  render = () => (
    <section className="main-holdings">
      {this.props.accountId && (
        <Fragment>
          <AssetMovements
            title="pendingAndRejectedAssetMovements"
            metadata={assetMovementsMetadata(this.moreInfo)}
            onFetchData={(state) => this.fetchData(state, 'Pending')}
            loading={this.props.requestHistoryLoading}
            result={this.props.requestHistory}
            type="Pending and Rejected"
          />
          <AssetMovements
            title="assetMovements"
            metadata={assetMovementsMetadata(this.moreInfo)}
            onFetchData={(state) => this.fetchData(state, 'Confirmed')}
            loading={this.props.journalHistoryLoading}
            result={this.props.journalHistory}
            type="Confirmed"
          />
        </Fragment>
      )}
    </section>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetMovementsContainer);
