import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Segment } from 'semantic-ui-react';
import StyledButton from '../common/StyledButton';
import { hideModal, showModal } from 'erisxkit/client';
import {
  UPGRADE_TO_FUTURES,
  FUTURES_ONBOARDING,
} from '../constants/modalTypes';
import {
  hideFuturesForUser,
  startFuturesUpgrade,
} from '../reducers/userReducer';
import colors from '../constants/colors';
import { sizes } from '../styles/styled';

const Wrapper = styled(Segment)`
  display: flex;
  flex-direction: column;
  padding: 40px !important;
  background: unset !important;
  @media (max-width: ${sizes.SMALL}) {
    padding: 17px !important;
  }
`;

const Header = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
`;

const DescriptionText = styled.div`
  font-size: 16px;
  text-align: center;
  align-self: center;
  margin: 15px 0px 15px 0px;
`;

const Button = styled(StyledButton)`
  width: 30%;
  @media (max-width: ${sizes.TINY}) {
    width: unset;
  }
  max-width: 150px;
  height: 40px;
  margin: 0px 10px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
Buttons.displayName = 'Buttons';

const RemindMeLater = styled.p`
  font-size: 14px;
  color: ${colors.GREEN};
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  margin-top: 25px;
`;

const ListHeader = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 15px 0px;
  color: ${colors.GREEN};
  font-weight: bold;
`;

const List = styled.ul`
  & :last-child {
    margin-bottom: 0px;
  }
`;

const Bullet = styled.li`
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 1.5;
`;

const FuturesUpgradeModal = () => {
  const dispatch = useDispatch();
  const hide = () => dispatch(hideModal(UPGRADE_TO_FUTURES));

  const onNotNow = () => {
    dispatch(hideFuturesForUser());
    hide();
  };

  const onLetsDoIt = () => {
    hide();
    dispatch(startFuturesUpgrade());
  };

  return (
    <Wrapper>
      <Header>
        <p>UPGRADE TO A FUTURES ACCOUNT TODAY!</p>
      </Header>
      <DescriptionText>
        Just answer four questions to get approved.
      </DescriptionText>
      <ListHeader>
        Advantages of Cboe Digital Bounded futures contract:
      </ListHeader>
      <List>
        <Bullet>
          <b>Short Bitcoin with protection</b> - upper/lower bounds cap maximum
          loss for sellers/buyers.
        </Bullet>
        <Bullet>
          <b>Zero fees for futures</b> - Cboe Digital does not currently charge
          fees on futures trades. Take advantage of this opportunity while it
          lasts.
        </Bullet>
        <Bullet>
          <b>Collateral ratio</b> - collateral required vs potential profit may
          represent attractive payout.
        </Bullet>
        <Bullet>
          <b>No auto-liquidation</b> - if the market moves back in your favor,
          you&apos;re still in the trade.
        </Bullet>
      </List>
      <Buttons>
        <Button text="Not at this time" secondary onClick={() => onNotNow()} />
        <Button text="LET'S DO IT!" primary onClick={() => onLetsDoIt()} />
      </Buttons>
      <RemindMeLater onClick={() => hide()}>Remind me later</RemindMeLater>
    </Wrapper>
  );
};

export default FuturesUpgradeModal;
