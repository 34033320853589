export const PROCESS_INFORMATION = 1;
export const PERSONAL = 2;
export const REVIEW_PII = 3;
export const VERIFICATION = 4;
export const COMPLIANCE = 5;
export const DISCLOSURES = 6;
export const SECURITY = 7;
export const FUNDING = 8;

export const FACE_ID = 1;
export const IDENTIFICATION = 2;
export const FACE_UPLOAD = 3;
