import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import Page from '../Page';
import { getAllAccountsList } from '../../reducers/accountsReducer';
import { getActiveAccountLabel } from '../../reducers/activeAccountSelectors';
import {
  fetchAccountsBalances,
  getActiveBalanceDetails,
  getBalanceDetailsByAccountIdAndAssetType,
} from '../../reducers/balancesReducer';
import { UISegment } from '../../styles/styled';
import { Header, Form, Table, Icon } from 'semantic-ui-react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { renderDropdown, renderField } from '../forms/components';
import {
  balanceExceeded,
  positiveNumbers,
  required,
  twoDecimalPlaces,
  maxValue,
} from '../forms/rules';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { floatsOnly } from '../forms/normalization';
import hasMoreThanNDigits from '../../utils/hasMoreThanNDigits';
import { DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES } from '../../components/FundTransfers/constants';
import { FCM_WITHDRAWAL_CONFIRM } from '../../constants/modalTypes';
import get from 'lodash/get';
import colors from '../../constants/colors';
import {
  getDepositBankDetails,
  bankDetails,
} from '../../reducers/fundTransfersReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedAccountLabel,
  getLinkedBankAccounts,
} from '../../reducers/linkedAccountsReducer';
import payMethods from '../../constants/payMethods';
import {
  getUserCanRequestWithdrawals,
  getUserCanSendDeposits,
  getUserHasViewBalances,
} from '../../reducers/userReducer';
import WithdrawDisclaimer from './WithdrawDisclaimer';

const StyledDiv = styled.div`
  margin-bottom: 20px;
`;

const StyledTableCell = styled(Table.Cell)`
  & {
    color: ${colors.NAVY_4};
  }
`;

const maskedAccountNumber = '******';
const WireDepositInfo = ({ bankDetails, bankDetailsLoading }) => (
  <UISegment>
    <Header>
      <Icon name="university" className="small" />
      <Header.Content className="bold">Banking information</Header.Content>
      <Header.Subheader>
        Please send a FED wire using the following information including the
        Reference code
      </Header.Subheader>
    </Header>
    <Table basic="very" collapsing loading={bankDetailsLoading}>
      <Table.Body>
        <Table.Row>
          <StyledTableCell width={4}>Routing/ABA #</StyledTableCell>
          <StyledTableCell width={12}>
            {bankDetails.routingNumber}
          </StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Account #</StyledTableCell>
          <StyledTableCell>{bankDetails.accountNumber}</StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Recipient Name</StyledTableCell>
          <StyledTableCell>{bankDetails.recipientName}</StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Recipient Address</StyledTableCell>
          <StyledTableCell>{bankDetails.recipientAddress}</StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Recipient Bank</StyledTableCell>
          <StyledTableCell>{bankDetails.recipientBank}</StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Bank Address</StyledTableCell>
          <StyledTableCell>{bankDetails.bankAddress}</StyledTableCell>
        </Table.Row>
        <Table.Row>
          <StyledTableCell>Reference Code*</StyledTableCell>
          <StyledTableCell>{bankDetails.referenceCode}</StyledTableCell>
        </Table.Row>
      </Table.Body>
    </Table>
  </UISegment>
);

const FCMFunding = (props: any) => {
  const dispatch = useDispatch();
  const { valid, reset, change } = props;

  /** Selectors */
  const allAccounts = useSelector(getAllAccountsList);
  const activeAccountLabel = useSelector(getActiveAccountLabel);
  const bankAccounts = useSelector(getLinkedBankAccounts);
  const bankDetailsList = useSelector(getDepositBankDetails);
  const bankDetailsLoading = useSelector((state) =>
    createLoadingSelector(['BANK_DETAILS'])(state),
  );
  const formValues = useSelector((state) => getFormValues('fcmFunding')(state));
  const canSendDeposits = useSelector(getUserCanSendDeposits);
  const canRequestWithdrawals = useSelector(getUserCanRequestWithdrawals);
  const canViewBalances = useSelector(getUserHasViewBalances);

  const assetType = get(formValues, 'assetType');
  const fundingMethod = get(formValues, 'fundingMethod');
  const accountId = get(formValues, 'account');
  const bankAccountNumber = get(formValues, 'bankAccount');
  const amount = get(formValues, 'amount');
  const selectedAccount = useMemo(
    () => allAccounts.find((acc) => acc.accountId === accountId),
    [accountId, allAccounts],
  );
  const selectedBankAccount = useMemo(
    () => bankAccounts.find((acc) => acc.accountNumber === bankAccountNumber),
    [bankAccountNumber, bankAccounts],
  );
  const totalExcessDeficit = useSelector(
    getBalanceDetailsByAccountIdAndAssetType(selectedAccount?.accountId, 'USD'),
  );
  const limitRule = useMemo(
    () => maxValue(totalExcessDeficit),
    [totalExcessDeficit],
  );

  const fundingMethodOptions = useMemo(() => {
    const options = [];
    if (canSendDeposits)
      options.push({ value: 'Wire Deposit', text: 'Wire Deposit' });
    if (canRequestWithdrawals)
      options.push({ value: 'Wire Withdrawal', text: 'Wire Withdrawal' });
    return options;
  }, [canSendDeposits, canRequestWithdrawals]);

  useEffect(() => {
    dispatch(fetchLinkedMemberAssetAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedAccount && assetType) {
      const payload = {
        accountId: selectedAccount.accountId,
        assetType,
        fundsDesignation: selectedAccount.fundsDesignation,
      };
      dispatch(bankDetails(payload));
    }
  }, [assetType, selectedAccount, dispatch]);

  const onWithdrawClicked = () =>
    dispatch(
      showModal(FCM_WITHDRAWAL_CONFIRM, {
        type: 'fiat',
        payMethod: payMethods.FWT,
        account: selectedAccount,
        values: {
          amount,
          assetType,
          fundsDesignation: selectedAccount.fundsDesignation,
          linkedAssetAccount: selectedBankAccount,
          destinationLabel: getLinkedAccountLabel(selectedBankAccount),
        },
      }),
    );

  const assetTypeOptions = [{ value: 'USD', text: 'USD' }];

  /** Effects */

  useEffect(() => {
    // Initial Fetch of balance details if the user has read_balances permissions
    if (canViewBalances)
      dispatch(
        fetchAccountsBalances({
          accountIds: allAccounts.map((a: any) => a?.accountId),
          lastPrice: true,
        }),
      );

    //If the assetTypeOptions is just one, we select by default that one.
    if (assetTypeOptions.length === 1) {
      change('assetType', assetTypeOptions[0].value);
    }
  }, [dispatch]);

  return (
    <>
      <Page header={`Account: ${activeAccountLabel}`} subHeader="Funding">
        <UISegment>
          <Header content="Funding Source" />
          <Form>
            <StyledDiv>
              <label className="field">
                {'Choose Account'}
                <Field
                  placeholder="Select Account"
                  name="account"
                  component={renderDropdown}
                  selection
                  closeOnChange
                  required
                  options={allAccounts.map((acc: any) => ({
                    value: acc.accountId,
                    text: acc.name,
                  }))}
                  validate={[required]}
                />
              </label>
            </StyledDiv>
            <StyledDiv>
              <label className="field">
                {'Asset Type'}
                <Field
                  placeholder="Select Asset"
                  name="assetType"
                  component={renderDropdown}
                  options={assetTypeOptions}
                  selection
                  closeOnChange
                  required
                  validate={[required]}
                />
              </label>
            </StyledDiv>
            <StyledDiv>
              <label className="field">
                {'Funding Method'}
                <Field
                  placeholder="Select Funding Method"
                  name="fundingMethod"
                  component={renderDropdown}
                  options={fundingMethodOptions}
                  selection
                  closeOnChange
                  required
                  validate={[required]}
                />
              </label>
            </StyledDiv>
            {fundingMethod === 'Wire Withdrawal' ? (
              <>
                <StyledDiv>
                  <label className="field">
                    {'Bank Account'}
                    <Field
                      placeholder="Select Bank Account"
                      name="bankAccount"
                      component={renderDropdown}
                      options={bankAccounts.map((acc: any) => ({
                        value: acc.accountNumber,
                        text: `${acc.bankName} - ${acc.label} - ${maskedAccountNumber}${acc.accountNumber}`,
                      }))}
                      selection
                      closeOnChange
                      required
                      validate={[required]}
                    />
                  </label>
                </StyledDiv>
                <StyledDiv>
                  <label>
                    {'Amount'}
                    <Field
                      name="amount"
                      component={renderField}
                      parse={floatsOnly}
                      placeholder="Enter amount"
                      required
                      onChange={(event, amount) => {
                        if (
                          hasMoreThanNDigits(
                            amount,
                            DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES,
                          )
                        ) {
                          event?.preventDefault();
                        }
                      }}
                      validate={[
                        required,
                        twoDecimalPlaces,
                        positiveNumbers,
                        balanceExceeded,
                        limitRule,
                      ]}
                    />
                  </label>
                </StyledDiv>
                <WithdrawDisclaimer />

                <Form.Button
                  primary
                  content="Withdraw"
                  name="withdrawButton"
                  disabled={!valid}
                  onClick={onWithdrawClicked}
                />
              </>
            ) : fundingMethod === 'Wire Deposit' ? (
              <>
                <WireDepositInfo
                  bankDetails={bankDetailsList}
                  bankDetailsLoading={bankDetailsLoading}
                />
                <Form.Button
                  primary
                  content="Reset"
                  name="resetButton"
                  onClick={reset}
                />
              </>
            ) : null}
          </Form>
        </UISegment>
      </Page>
    </>
  );
};

const FCMFundingForm = reduxForm({
  form: 'fcmFunding',
})(FCMFunding);

export default connect((state) => ({
  balances: getActiveBalanceDetails(state),
}))(FCMFundingForm);
