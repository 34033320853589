import React from 'react';
import { getTimeColumn } from '../common/metadata/commonFields';

const columns = (deleteCredential) => [
  {
    Header: 'Label',
    id: 'label',
    accessor: 'label',
    headerClassName: 'pull-left',
  },
  {
    ...getTimeColumn('createdAt'),
    id: 'createdAt',
    Header: 'Created At',
  },
  {
    Header: '',
    id: 'delete-api-credential',
    accessor: (d) => (
      <div
        className="delete-api-credential"
        onClick={() => deleteCredential(d)}
      >
        Delete
      </div>
    ),
    filterable: false,
    width: 150,
    resizable: false,
  },
];

export default columns;
