import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Field, getFormValues, FieldArray } from 'redux-form';
import { renderField } from '../../common/forms/components';
import { DottedSeparator } from '../../styles/styled';
import TextButton from '../../common/TextButton';
import { useSelector } from 'react-redux';
import colors from '../../constants/colors';
import { ADD_CAR_FORM } from './AddCARModal';
import * as rules from '../../common/forms/rules';
import { getAvailableExpiryLimits } from './menuOptions';
import { LongPositionInfo, ShortPositionInfo } from './infoTooltips';
import { getCarProducts } from '../../reducers/orderEntryReducer';
import renderMaterialDropdown from '../../common/renderMaterialDropdown';
import { availableCarProductExpiryLimits, availableCarProducts } from './utils';

const Row = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  margin: 10px 0px;
`;

const StyledField = styled(Field)`
  flex: 1 1 0;
  max-width: 50%;
  padding-right: 10px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ProductWrapper = styled.div`
  margin: 10px 0px;
  ${DottedSeparator} {
    margin: 15px 0px;
  }
`;

const AddNew = styled(TextButton)`
  margin-top: 10px;
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const RemoveButton = styled(Icon)`
  color: ${({ theme }) => theme.ui.remove};
  margin-bottom: 2px !important;
  cursor: pointer;
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const Expiry = ({ expiry, index, onRemove, productIndex }) => {
  const formValues = useSelector((state) => getFormValues(ADD_CAR_FORM)(state));
  const isExpirySet = !isEmpty(
    get(
      formValues,
      `productLimits[${productIndex}].expiryLimits[${index}]`,
      {},
    ),
  );

  const currentlySetExpiries = get(
    formValues,
    `productLimits[${productIndex}].expiryLimits`,
    [],
  ).map((expiry) => expiry?.expirationMonth);

  const filteredLimits = useMemo(() => {
    const limits = getAvailableExpiryLimits(currentlySetExpiries);
    const currentExpiryLimit = get(
      formValues,
      `productLimits[${productIndex}].expiryLimits[${index}]`,
      {},
    );

    //Current Expiry Limit Selected should be available, in order to the user can select it.
    const selectedValuesProductExpiryLimits = currentlySetExpiries.filter(
      (x) => x !== currentExpiryLimit.expirationMonth,
    );

    return availableCarProductExpiryLimits(
      limits,
      selectedValuesProductExpiryLimits,
    );
  }, [currentlySetExpiries]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onRemove();
    }
  };

  return (
    <>
      <Row>
        <StyledField
          component={renderMaterialDropdown}
          label="Add new Expiry Limit"
          fluid
          placeholder="Select Expiry"
          name={`${expiry}.expirationMonth`}
          options={filteredLimits}
          defaultValue={get(
            formValues,
            `productLimits[${productIndex}].expiryLimits[${index}].expirationMonth`,
            '',
          )}
          className="addNewExpiryLimitDropdown"
        />
        {isExpirySet && (
          <>
            <StyledField
              component={renderField}
              placeholder="Enter Max"
              name={`${expiry}.maxLongExposure`}
              label="Max Long Exposure"
              className="expiryMaxLongExposure"
              style={{ height: '38px' }}
              minimal
              type="number"
              required
              validate={[rules.required]}
            />
            <LongPositionInfo />
            <StyledField
              component={renderField}
              placeholder="Enter Max"
              name={`${expiry}.maxShortExposure`}
              label="Max Short Exposure"
              className="expiryMaxShortExposure"
              style={{ height: '38px' }}
              minimal
              type="number"
              required
              validate={[rules.required]}
            />
            <ShortPositionInfo />
            <RemoveButton
              onClick={onRemove}
              tabIndex="0"
              onKeyDown={handleKeyDown}
              name="remove circle"
            />
          </>
        )}
      </Row>
    </>
  );
};
const ProductExpiry = ({ fields, productIndex }) => {
  useEffect(() => {
    if (isEmpty(fields)) fields.push({});
  }, []);

  return (
    <>
      {fields.map((field, index) => (
        <Expiry
          expiry={field}
          index={index}
          productIndex={productIndex}
          onRemove={() => fields.remove(index)}
        />
      ))}
      <AddNew
        text="Add new Expiry"
        name={`${productIndex}.newExpiryLink`}
        className="addNewExpiryTextBtn"
        onClick={() => fields.push({})}
        fontSize="14"
        bold
      />
    </>
  );
};

const Product = ({ product, index, onRemove, change }) => {
  const formValues = useSelector((state) => getFormValues(ADD_CAR_FORM)(state));
  const carProducts = useSelector(getCarProducts);

  const memoizedAvailableCarProducts = useMemo(() => {
    const productLimits = get(formValues, 'productLimits', []);
    const currentProductSelected = get(formValues, `productLimits[${index}]`, {
      productCode: '',
    });

    //Current Product Selected should be available in the props, in order to can select it.
    const selectedValuesProductLimits = productLimits
      .map((x) => x?.productCode)
      .filter((x) => x !== currentProductSelected.productCode);
    return availableCarProducts(carProducts, selectedValuesProductLimits);
  }, [formValues, carProducts, index]);

  const hasCurrentProduct = !isEmpty(
    get(formValues, `productLimits[${index}]`, {}),
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onRemove();
    }
  };

  return (
    <ProductWrapper>
      <Row>
        <StyledField
          component={renderMaterialDropdown}
          label="Add new Product"
          fluid
          required
          placeholder="Select"
          name={`${product}.productCode`}
          options={memoizedAvailableCarProducts}
          validate={[rules.required]}
          defaultValue={get(
            formValues,
            `productLimits[${index}].productCode`,
            '',
          )}
          className="addNewCarProduct"
        />
        {hasCurrentProduct && (
          <>
            <StyledField
              component={renderField}
              placeholder="Enter Max Order Size"
              name={`${product}.maxOrderSize`}
              id={`${product}.maxOrderSize`}
              type="number"
              label="Product Max Order Size"
              style={{ height: '38px' }}
              minimal
              parse={rules.stringToInteger}
            />
            <StyledField
              component={renderField}
              placeholder="Enter Max"
              name={`${product}.maxLongExposure`}
              id={`${product}.maxLongExposure`}
              className="productMaxLongExposure"
              type="number"
              label="Max Long Exposure"
              style={{ height: '38px' }}
              minimal
            />
            <LongPositionInfo />
            <StyledField
              component={renderField}
              placeholder="Enter Max"
              name={`${product}.maxShortExposure`}
              id={`${product}.maxShortExposure`}
              className="productMaxShortExposure"
              type="number"
              label="Max Short Exposure"
              style={{ height: '38px' }}
              minimal
            />
            <ShortPositionInfo />
            {hasCurrentProduct && (
              <RemoveButton
                onClick={onRemove}
                color={colors.RED_SELL_BUTTON}
                name="remove circle"
                tabIndex="0"
                onKeyDown={handleKeyDown}
              />
            )}
          </>
        )}
      </Row>
      {hasCurrentProduct && (
        <FieldArray
          name={`${product}.expiryLimits`}
          component={ProductExpiry}
          props={{ productIndex: index }}
        />
      )}
      <DottedSeparator />
    </ProductWrapper>
  );
};

Product.propTypes = {};

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Header = styled.p`
  font-size: 15px;
  color: ${colors.GRAY_1};
  margin: 15px 0px;
  font-weight: 500;
`;

const StyledTextButton = styled(TextButton)`
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const CarProducts = ({ fields, change }) => {
  return (
    <Wrapper>
      <Header>Products</Header>
      {fields.map((product, index) => (
        <Product
          product={product}
          index={index}
          onRemove={() => fields.remove(index)}
          change={change}
        />
      ))}
      <StyledTextButton
        text="Add new Product"
        name={`newProductLink`}
        className="addNewCarProductTextBtn"
        onClick={() => fields.push({})}
        fontSize="14"
        bold
      />
    </Wrapper>
  );
};

CarProducts.propTypes = {
  change: PropTypes.func.isRequired,
};

export default CarProducts;
