import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { Button, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import { renderCheckbox } from '../../common/forms/components';
import { required } from '../../common/forms/rules';
import {
  submitPiiPromiseCreator,
  getLoggedInUser,
} from '../../reducers/userReducer';
import * as onBoardingSteps from '../../constants/onBoardingSteps';
import EnvPromise from '../../config/env';
import { trackEvent } from '../../common/tracking';
import { PII_NAME } from '../../constants/ddActionNames';
import ReferralCodeVerifier from './ReferralCodeVerifier';

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      change,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      submitPiiPromiseCreator,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  values: {
    ..._.get(state, ['form', 'personal', 'values'], {}),
    ..._.get(state, ['user', 'pii'], {}),
  },
  user: getLoggedInUser(state),
  submitPiiLoading: createLoadingSelector(['SUBMIT_PII'])(state),
});

const piiItem = (label, value) => (
  <div className="pii-item">
    <p className="pii-title">{label}</p>
    <p>{value || ' '}</p>
  </div>
);

class ReviewPII extends Component {
  componentWillMount = () => {
    EnvPromise.then(({ termsOfUsePdfEndpoint, privacyPolicyEndpoint }) =>
      this.setState({ termsOfUsePdfEndpoint, privacyPolicyEndpoint }),
    );
  };

  onBack = () => {
    trackEvent('AMLKYC', 'PIIedit');
    this.props.goToStep(onBoardingSteps.PERSONAL);
  };

  onConfirm = () => {
    trackEvent('AMLKYC', 'PIIsubmit');
    this.props.personalNext({ nextStep: this.props.nextStep });
  };

  onTermsClicked = () => {
    const { termsOfUsePdfEndpoint } = this.state;
    trackEvent('AMLKYC', 'PIITnCreview');
    window.open(termsOfUsePdfEndpoint, '_blank');
  };

  onPrivacyClicked = () => {
    const { privacyPolicyEndpoint } = this.state;
    trackEvent('AMLKYC', 'PIIPPreview');
    window.open(privacyPolicyEndpoint, '_blank');
  };

  onHereClicked = () => {
    trackEvent('AMLKYC', 'PIIincentiveReview');
  };

  render = () => {
    const { values, submitPiiLoading = false } = this.props;
    const termsLink = (
      <span>
        <a
          href={_.get(this.state, 'termsOfUsePdfEndpoint')}
          rel="noopener noreferrer"
          target="_blank"
          onClick={this.onTermsClicked}
        >
          {' '}
          Cboe Digital Terms and Conditions
        </a>{' '}
        and
        <a
          href={_.get(this.state, 'privacyPolicyEndpoint')}
          rel="noopener noreferrer"
          target="_blank"
          onClick={this.onPrivacyClicked}
        >
          {' '}
          Privacy Policy
        </a>
        .
      </span>
    );
    if (values.dob) {
      values.dob = moment(values.dob).format('MM/DD/YYYY');
    }
    return (
      <div className="onboarding-container">
        <div className="onboarding-content ui padded segment">
          <div className="text-left">
            <a onClick={this.onBack} data-dd-action-name="Edit PII">
              <Icon name="arrow left" />
            </a>
            Back to Edit
          </div>
          <Header className="review-pii">
            <Header.Content>Confirm your personal details</Header.Content>
            <Header.Subheader>
              Once submitted, you will not be able to edit your personal
              details.
            </Header.Subheader>
          </Header>
          <div className="pii-content">
            <div className="pii-column" data-dd-action-name={PII_NAME}>
              {piiItem('First Name', values.firstName)}
              {piiItem('Street Address', values.address1)}
              {piiItem('Zip', values.postalCode)}
              {piiItem('Date of Birth', values.dob)}
            </div>
            <div className="pii-column" data-dd-action-name={PII_NAME}>
              {piiItem('Middle Name', values.middleName)}
              {piiItem('Street Address 2', values.address2)}
              {piiItem('State', values.locationState)}
              {piiItem('Phone Number', values.phoneNumber)}
            </div>
            <div className="pii-column" data-dd-action-name={PII_NAME}>
              {piiItem('Last Name', values.lastName)}
              {piiItem('City', values.city)}
              {piiItem('Country', values.country)}
              {piiItem('Social Security Number', '*********')}
            </div>
          </div>
          <Segment className="review-items text-center bold">
            <Field
              component={renderCheckbox}
              validate={required}
              name="termsAccepted"
              label={['I acknowledge and agree to the ', termsLink]}
              data-cy="ob-review-pii-terms-accepted-checkbox"
            />
          </Segment>
          <div className="flex">
            <Button
              primary
              id="confirm"
              loading={submitPiiLoading}
              onClick={this.onConfirm}
              disabled={this.props.invalid}
              data-cy="ob-review-pii-terms-submit"
              data-dd-action-name="Review PII Submit"
              content="Submit"
            />
          </div>
        </div>
      </div>
    );
  };
}

ReviewPII.propTypes = {
  change: PropTypes.func.isRequired,
  submitPII: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.String),
  invalid: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.String).isRequired,
};

ReviewPII.defaultProps = {
  values: {},
  invalid: false,
};

export default reduxForm({
  form: 'review-pii',
})(connect(mapStateToProps, mapDispatchToProps)(ReviewPII));
