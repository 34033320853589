import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment-timezone';
import { formatDateValue } from '../../utils/formatters';
import { DEFAULT_DATE_FORMAT } from '../../constants/formats';

const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return null;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

function ReduxFormDatePicker({
  input: { value, onChange },
  label,
  name,
  meta: { touched, error },
  minDate,
  maxDate,
  fullWidth,
  'data-cy': dataCy,
}) {
  const [selectedDate, setSelectedDate] = useState(() => {
    return value ? formatDateValue(value) : null;
  });

  useEffect(() => {
    if (selectedDate) {
      const date = formatDateValue(selectedDate);
      onChange(date);
    }

    if (!selectedDate && value) {
      setSelectedDate(formatDateValue(value));
    }
  });

  // this is redundant given the other message from redux form
  const invalidDateMessage = null;
  const maxDateMessage = null;
  const minDateMessage = null;

  return (
    <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils}>
      <FormControl error={touched && Boolean(error)} fullWidth={fullWidth}>
        <KeyboardDatePicker
          autoOk
          name={name}
          label={label}
          invalidDateMessage={invalidDateMessage}
          fullWidth={fullWidth}
          value={selectedDate}
          onChange={setSelectedDate}
          format={DEFAULT_DATE_FORMAT}
          minDate={minDate}
          maxDate={maxDate}
          maxDateMessage={maxDateMessage}
          minDateMessage={minDateMessage}
          inputProps={{
            inputmode: 'numeric',
          }}
          keyboardIcon={null}
          KeyboardButtonProps={{
            disabled: true,
          }}
          data-cy={dataCy}
        />
        {renderFormHelper({ touched, error })}
      </FormControl>
    </MuiPickersUtilsProvider>
  );
}

ReduxFormDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  fullWidth: PropTypes.bool,
  dataCy: PropTypes.string,
};

ReduxFormDatePicker.defaultProps = {
  fullWidth: false,
  input: {
    onChange: () => {},
    value: null,
  },
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
  dataCy: '',
};

export default ReduxFormDatePicker;
