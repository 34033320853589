import React from 'react';
import { get, sumBy } from 'lodash';
import { getTimeColumn } from '../common/metadata/commonFields';

export const futuresPositionsMetadata = [
  {
    Header: 'Product',
    accessor: 'productSymbol',
    id: 'product',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Contract',
    accessor: 'contractCode',
    id: 'contractCode',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Expiration Date',
    accessor: 'expiryDate',
    id: 'expiryDate',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Total Long',
    accessor: 'totalLong',
    id: 'totalLong',
    headerClassName: 'pull-left',
    className: 'mono',
  },
  {
    Header: 'Total Short',
    accessor: 'totalShort',
    id: 'totalShort',
    headerClassName: 'pull-left',
    className: 'mono',
  },
  {
    Header: 'Reserved OTE',
    accessor: ({ positions }) =>
      sumBy(positions, (pos) => parseInt(get(pos, 'reserveOte', 0), 10)),
    id: 'totalOTE',
    headerClassName: 'pull-left',
    resizable: false,
    className: 'mono',
  },
];

export const subComponentMetadata = [
  {
    ...getTimeColumn('et'),
    Header: 'Execution Time',
  },
  {
    Header: 'TradeID',
    accessor: 'positionId',
    id: 'positionId',
  },
  {
    Header: 'CustAcctRef',
    accessor: 'customerAccountRef',
    id: 'customerAccountRef',
  },
  {
    Header: 'ClientOrderId',
    accessor: 'clOrdId',
    id: 'clientOrderId',
  },
  {
    Header: 'Long',
    accessor: (row) => (row.qty > 0 ? Math.abs(row.qty) : ''),
    id: 'long',
    className: 'mono',
  },
  {
    Header: 'Short',
    accessor: (row) => (row.qty < 0 ? Math.abs(row.qty) : ''),
    id: 'short',
    className: 'mono',
  },
  {
    Header: 'Price',
    accessor: (subRow) => <span className="mono">{get(subRow, 'px', '')}</span>,
    id: 'px',
  },

  {
    Header: 'Reserve Margin Long',
    accessor: 'reserveMarginL',
    id: 'reserveMarginL',
    className: 'mono',
  },
  {
    Header: 'Reserve Margin Short',
    accessor: 'reserveMarginS',
    id: 'reserveMarginS',
    className: 'mono',
  },
  {
    Header: 'Reserved OTE',
    accessor: 'reserveOte',
    id: 'reserveOte',
    resizable: false,
    className: 'mono',
  },
];
