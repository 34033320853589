import get from 'lodash/get';
import { formatDateTimeLocal } from '../../utils/formatters';
import { MEMBER_TYPES_LIST } from '../../constants/memberTypes';

export const firstInfoRowFields = (data) => [
  {
    label: 'Name',
    text: `${get(data, 'firstName', '')} ${get(data, 'lastName', '')}`,
  },
  { label: 'Email Address', text: data?.email },
  { label: 'Created At', text: formatDateTimeLocal(data?.createdAt) },
];

const getClearingMemberType = (data) => {
  return {
    label: 'Clearing Member Type',
    text: get(
      MEMBER_TYPES_LIST.find(
        (memberType) =>
          memberType.type === get(data, 'memberLinks[0].memberType', ''),
      ),
      'name',
      '-',
    ),
  };
};

const getMemberName = (data) => {
  return {
    label: 'Member Name',
    text: get(data, 'memberLinks[0].memberLabel'),
  };
};

const getAddress = (data) => {
  return {
    label: 'Address',
    text: `${get(data, 'address1', '-')} ${get(data, 'address2', '')}`,
  };
};

export const secondInfoRowFields = (data) => [
  getClearingMemberType(data),
  getMemberName(data),
  getAddress(data),
];

export const secondInfoRowFieldsFMC = (data) => [
  getClearingMemberType(data),
  getMemberName(data),
];

export const documentsCompletedFields = (data) => [
  {
    label: 'Documents Complete Spot',
    text: formatDateTimeLocal(data?.spotAcceptedTime) || '-',
  },
  {
    label: 'Documents Complete Futures',
    text: formatDateTimeLocal(data?.futuresAcceptedTime) || '-',
  },
];
