import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import Big from 'bignumber.js';

//* actions */
export const fetchAuthorizedUsers = createRoutine('AUTHORIZED_TRADERS');
export const addAuthorizedUser = createRoutine(
  'ADD_CLEARING_ACCOUNT_TO_AUTHORIZED_TRADER',
);
export const removeAuthorizedUser = createRoutine(
  'REMOVE_CLEARING_ACCOUNT_FROM_AUTHORIZED_TRADER',
);
export const fetchInvestmentMember = createRoutine('INVESTMENT_MANAGER_MEMBER');

//* Promise actions*/
export const addAuthorizedUserPromiseCreator =
  promisifyRoutine(addAuthorizedUser);

export const fetchInvestmentMemberPromiseCreator = promisifyRoutine(
  fetchInvestmentMember,
);

//* Initial State */
const initialState = {
  investmentMember: {
    success: null,
    memberName: '',
  },
};

//* Reducer */
export default handleActions(
  {
    [fetchAuthorizedUsers.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [addAuthorizedUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [removeAuthorizedUser.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchInvestmentMember.SUCCESS]: (state, { payload }) => ({
      ...state,
      investmentMember: payload,
    }),
  },
  initialState,
);

//* Selectors */
export const selectAuthorizedUsers = (state) =>
  _.get(state, 'authorizedUsers.authorizedTraders', []);

export const getInvestmentMemberName = (state) =>
  _.get(state, 'authorizedUsers.investmentMember.memberName', '');
