import React, { Fragment } from 'react';
import { Message, Header, Icon } from 'semantic-ui-react';
import { QRCode } from 'react-qr-svg';
import PropTypes from 'prop-types';

const etherCoins = ['ETH', 'TETH', 'USDC', 'TEST', 'TERC', 'AAVE', 'LINK'];

const RenderQRCode = ({
  refCode,
  small,
  description,
  assetType,
  loading,
  invalidLocationStateError,
}) => {
  if (refCode) {
    return (
      <Fragment>
        <QRCode
          bgColor="#000000"
          fgColor="#ffffff"
          level="Q"
          width={small ? 256 : 300}
          value={refCode}
        />
        <Message
          className="all-caps italic"
          content={`Only send ${description ? `${description} (${assetType})` : assetType} to this address`}
        />
      </Fragment>
    );
  } else if (invalidLocationStateError && !loading) {
    return (
      <Header icon>
        <Icon.Group>
          <h5>
            <Icon name="info circle" color="grey" />
          </h5>
        </Icon.Group>
        <Header.Subheader className="deposit-notification">
          {invalidLocationStateError}
        </Header.Subheader>
      </Header>
    );
  } else if (etherCoins.includes(assetType) && !refCode && !loading) {
    return (
      <Header icon>
        <Icon.Group>
          <Icon name="chain" color="grey" />
        </Icon.Group>
        <Header.Subheader className="deposit-notification">
          Your deposit address is being generated. It will be available once
          confirmation is received from the Ethereum blockchain.
        </Header.Subheader>
        <Header.Subheader className="deposit-notification italic">
          Please check back here in a few minutes.
        </Header.Subheader>
      </Header>
    );
  } else if (!loading) {
    return '';
  }

  return '';
};

RenderQRCode.propTypes = {
  refCode: PropTypes.string,
  small: PropTypes.bool,
  description: PropTypes.string,
  assetType: PropTypes.string,
  invalidLocationStateError: PropTypes.string,
};

RenderQRCode.defaultProps = {
  refCode: null,
  small: false,
  description: '',
  assetType: '',
  invalidLocationStateError: null,
};

export default RenderQRCode;
