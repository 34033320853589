import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const EnvBanner = ({ text }) => (
  <section className="env-banner">
    <Icon name="bullhorn" />
    <span className="mono">{text}</span>
  </section>
);

EnvBanner.propTypes = {
  text: PropTypes.string,
};

EnvBanner.defaultProps = {
  text: '',
};

export default EnvBanner;
