import React from 'react';
import { Button } from 'semantic-ui-react';

const GenericStep = ({
  previousStep = null,
  nextStep,
  nextStepDisabled = false,
  loading = false,
  showRequired = false,
  'data-cy': dataCy = '',
  ...rest
}) => (
  <div className="ob-form-footer">
    <Button
      fluid
      primary
      onClick={nextStep}
      disabled={nextStepDisabled}
      loading={loading}
      className="top-button"
      data-cy={dataCy}
      {...rest}
    >
      Continue
    </Button>
    {!previousStep ? null : (
      <Button secondary fluid onClick={previousStep}>
        Back
      </Button>
    )}
    {showRequired && (
      <small className="helper info text-right">* Required</small>
    )}
  </div>
);

export default GenericStep;
