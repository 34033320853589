import Moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

export const formatDateValue = (value) => {
  return Moment(value).format('YYYY-MM-DD');
};

export const formatDateTimeValue = (value) => {
  return Moment(value).format('YYYY-MM-DD hh:mm');
};

export const formatDateTimeLocal = (time) =>
  isEmpty(time)
    ? ''
    : new Moment(time).local().format('MMMM Do YYYY, h:mm:ss a');

export const formatDateTimeLocalIso8601 = (time) =>
  isEmpty(time) ? '' : new Moment(time).local().format('YYYY-MM-DD h:mm:ss a');

const accountingValueFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 10,
});
const accountingValueFormatterNoMinFraction = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 10,
});
export const formatAccountingValue = (value, noFraction) => {
  if (value === 0 || value === '0' || !isFinite(value)) return value;
  if (!value) return '';
  if (noFraction) {
    return accountingValueFormatterNoMinFraction.format(value);
  }
  return accountingValueFormatter.format(value);
};

export const formatNoDecimals = (value) => {
  if (value === 0 || value === '0' || !isFinite(value)) return value;
  if (!value) return '';
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
};
