/* eslint-disable import/no-named-as-default */
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector, hideModal } from 'erisxkit/client';
import { formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  achDepositLimit,
  clearDepositLimit,
  createFiatDepositRequestPromiseCreator,
  getAchDepositLimit,
  achImmediateDepositLimit,
  achWithdrawalLimit,
  getEmbargoPeriod,
} from '../../reducers/fundTransfersReducer';
import { getActiveAccount, getActiveOrDefaultAccountFD } from '../../reducers/activeAccountSelectors';
import { getFiatAssetTypes } from '../../reducers/assetTypesReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedBankAccounts,
} from '../../reducers/linkedAccountsReducer';
import { fetchUser, getLoggedInUser } from '../../reducers/userReducer';
import { formatAssetTypesForFundsTransfer } from '../../utils/methods';
import history from '../../constants/history';
import AchDeposit from '../../components/FundTransfers/AchDeposit';
import payMethods from '../../constants/payMethods';
import { bankAccountOptions } from '../../components/FundTransfers/BankTransfers';
import { pushTag } from '../../common/tracking';
import {
  createFiatDepositSchedulePromiseCreator,
  fetchACHDeposits,
  getACHDepositsFromAccount,
  getScheduledACHDepositsFromAccount,
} from '../../reducers/ACHDepositsReducer';
const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createFiatDepositRequestPromiseCreator,
      createFiatDepositSchedulePromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      achDepositLimit,
      achImmediateDepositLimit,
      achWithdrawalLimit,
      clearDepositLimit,
      fetchLinkedMemberAssetAccounts,
      hideModal,
      fetchUser,
      fetchACHDeposits,
    },
    dispatch,
  ),
});

const selector = formValueSelector('fiatDeposit');

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  achDepositAmountLimit: getAchDepositLimit(state),
  embargoPeriod: getEmbargoPeriod(state),
  amount: selector(state, 'amount'),
  assetType: selector(state, 'assetType'),
  assetTypes: getFiatAssetTypes(state),
  fundsDesignation: selector(state, 'fundsDesignation'),
  isScheduled: selector(state, 'frequency') !== 'default',
  frequency: selector(state, 'frequency'),
  linkedBankAccounts: getLinkedBankAccounts(state),
  accountsLoading: createLoadingSelector(['LINKED_BANK_ACCOUNTS'])(state),
  user: getLoggedInUser(state),
  activeAccountFD: getActiveOrDefaultAccountFD(state),
});

class AchDepositContainer extends Component {
  componentDidMount = () => {
    this.props.fetchLinkedMemberAssetAccounts();
  };

  componentWillUnmount() {
    this.props.clearDepositLimit();
  }

  onBankChange = (e, nextBankAccount) => {
    const { accountId } = this.props;
    if (nextBankAccount.id) {
      this.props.achImmediateDepositLimit({
        accountId,
        linkedMemberAssetAccountId: nextBankAccount.id,
      });
    }
  };

  fetchLimit = (assetType, fundsDesignation) => {
    const { accountId } = this.props;
    this.props.achDepositLimit({ accountId });
    this.props.achWithdrawalLimit({ accountId, assetType, fundsDesignation });
  };

  render = () => (
    <AchDeposit
      {...this.props}
      createFiatDepositSchedule={(payload) => {
        let day = 0;
        if (payload.frequency === 'weekly') {
          day = payload.weekDay;
          delete payload.weekDay;
        }
        if (payload.frequency === 'monthly') {
          day = payload.day;
        }
        const data = {
          accountId: this.props.accountId,
          amount: payload.amount,
          assetType: payload.assetType,
          linkedMemberAssetAccountId: payload.linkedMemberAssetAccountId,
          schedule: payload.frequency,
          fundsDesignation: this.props.fundsDesignation,
          payMethod: payMethods.ACH,
          day: day.toString(),
        };
        this.props
          .createFiatDepositSchedulePromiseCreator(data)
          .then(() => {
            this.props.fetchUser();
            this.props.hideModal();
            pushTag('firstFunded');
            const url =
              this.props.frequency === 'default'
                ? `/accounts/${this.props.accountId}/movements`
                : '/scheduled-deposits';
            history.push(url);
          })
          .catch((error) => {
            console.error(error);
            this.props.hideModal();
          });
      }}
      bankDetails={this.props.bankDetails}
      onBankAccountChange={this.onBankChange}
      createFiatDepositRequest={(payload) => {
        this.props
          .createFiatDepositRequestPromiseCreator({
            ...payload,
            accountId: this.props.accountId,
            payMethod: payMethods.ACH,
          })
          .then(() => {
            // call user to get the updated firstFunded flag
            this.props.fetchUser();
            this.props.hideModal();
            pushTag('firstFunded');
            history.push(`/accounts/${this.props.accountId}/movements`);
          });
      }}
      fetchLimit={this.fetchLimit}
      currencyOptions={formatAssetTypesForFundsTransfer({
        ...this.props,
        skipBalancesCheck: true,
      })}
      linkedBankAccounts={bankAccountOptions(this.props.linkedBankAccounts)}
      linkedBankAccountsLoading={this.props.accountsLoading}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AchDepositContainer);
