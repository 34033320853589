import get from 'lodash/get';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

//* actions */
export const fetchMemberUserTypes = createRoutine('MEMBER_USER_TYPES');

//* Reducer */
export default handleActions(
  {
    [fetchMemberUserTypes.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

//* Selectors */
export const getMemberUserTypes = (state) =>
  Object.values(get(state, 'memberUserTypes', {}));
export const getMemberUserTypesCount = (state) =>
  getMemberUserTypes(state).length;
export const getMemberUserTypesByType = createSelector(
  [getMemberUserTypes],
  (memberUserTypes) =>
    Object.values(memberUserTypes).reduce(
      (prev, curr) => ({ ...prev, [curr.value.toUpperCase()]: curr.value }),
      {},
    ),
);
