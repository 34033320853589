import { takeLatest } from 'redux-saga/effects';
import {
  approveFcmClearing,
  createFcmClearing,
  fetchAllFcms,
  fetchFcmClearing,
  rejectFcmClearing,
} from '../reducers/fcmClearingReducer';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watch() {
  yield takeLatest(
    fetchFcmClearing,
    generateSaga(fetchFcmClearing, {
      method: HTTPMethod.GET,
    }),
  );
  yield takeLatest(
    fetchAllFcms,
    generateSaga(fetchAllFcms, {
      prefix: 'FCM_CLEARING_ALL_FCM_MEMBERS',
      method: HTTPMethod.GET,
    }),
  );
  yield takeLatest(
    createFcmClearing,
    generateSaga(createFcmClearing, {
      method: HTTPMethod.POST,
      loading: 'Submitting FCM clearing request',
      success: 'FCM clearing request submitted successfully',
    }),
  );
  yield takeLatest(
    rejectFcmClearing,
    generateSaga(rejectFcmClearing, {
      method: HTTPMethod.POST,
      loading: 'Submitting FCM clearing reject',
      success: 'FCM clearing reject submitted successfully',
    }),
  );
  yield takeLatest(
    approveFcmClearing,
    generateSaga(approveFcmClearing, {
      method: HTTPMethod.POST,
      loading: 'Approving Clearing Request...',
      success: 'Request approved successfully',
    }),
  );
}
