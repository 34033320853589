import React from 'react';
import PropTypes from 'prop-types';

const NotifBanner = ({ text, component }) => (
  <section className="notif-banner">
    {text && <span className="mono">{text}</span>}
    {component()}
  </section>
);

NotifBanner.propTypes = {
  text: PropTypes.string,
  component: PropTypes.func,
};

NotifBanner.defaultProps = {
  text: '',
  component: null,
};

export default NotifBanner;
