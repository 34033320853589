import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import colors from '../../constants/colors';
import { useSelector } from 'react-redux';
import { getLoggedInUserHasApiKeyPermission } from '../../reducers/userReducer';
import {
  getHasCollateralEnabledAccount,
  getHasSpotEnabledAccount,
  someAccountEnabledForFutures,
} from '../../reducers/accountsReducer';
import { sizes } from '../../styles/styled';

const Permission = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.text.text};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ${Permission} {
    margin-right: 15px;
    margin-bottom: 15px;
  }
`;

const Header = styled.p`
  color: ${({ theme }) => theme.text.header};
  margin-bottom: 10px;
`;

const Entitlement = styled.div`
  display: flex;
`;
const SUBMIT_BLOCK_TRADE_PERMISSION = 'submit_block_trade';

const UserEntlitlements = () => {
  const isFuturesEnabled = useSelector(someAccountEnabledForFutures);
  const isSpotEnabled = useSelector(getHasSpotEnabledAccount);
  const isCollateralEnabled = useSelector(getHasCollateralEnabledAccount);
  const isBlockTradeEnabled = useSelector((state) =>
    getLoggedInUserHasApiKeyPermission(state, SUBMIT_BLOCK_TRADE_PERMISSION),
  );

  const entitlements = [
    {
      label: 'Futures',
      isEnabled: isFuturesEnabled,
    },
    {
      label: 'Spot',
      isEnabled: isSpotEnabled,
    },
    {
      label: 'Collateral',
      isEnabled: isCollateralEnabled,
    },
    {
      label: 'Futures Block',
      isEnabled: isFuturesEnabled && isBlockTradeEnabled,
    },
    {
      label: 'Spot Block',
      isEnabled: isSpotEnabled && isBlockTradeEnabled,
    },
  ];

  return (
    <>
      <Header>Entitlements</Header>
      <Wrapper>
        {entitlements.map((e) => (
          <Entitlement key={`entitlement_${e.label}`}>
            <Icon
              color={e.isEnabled ? 'green' : 'red'}
              name={`${e.isEnabled ? 'checkmark' : 'close'}`}
            />
            <Permission>{e.label}</Permission>
          </Entitlement>
        ))}
      </Wrapper>
    </>
  );
};

export default UserEntlitlements;
