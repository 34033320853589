import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import {
  fetchBlockTradeRequestsV2,
  getBlockTradeRequests,
  approveBlockTradePromise,
  rejectBlockTradePromise
} from '../../reducers/BlockTrades/blockTradesReducer';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { hideModal } from 'erisxkit/client';
import blockTradeRequestsMetadata, { blockTradeRequestsConfirmMetadata } from '../../metadata/blockTradeRequestsMetadata';
import { fetchTradingParticipants, getParticipantList } from '../../reducers/participantManagementReducer';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';
import { APPROVE_REJECT_BLOCK_TRADE_REQUEST } from '../../constants/modalTypes';
import { Row } from 'react-table';
import { approveBlockTradeRequestForm, rejectBlockTradeRequestForm } from './ApproveRejectBlockTradeRequest';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

const BlockTradeRequest = () => {
  const firstRender = useRef(true);
  const dispatch = useAppDispatch();
  const blockTradeRequests: BlockTradeRequestModel[] = useAppSelector(getBlockTradeRequests);
  const firmCode = useAppSelector(getActiveMemberFirmCode);
  const participantList = useAppSelector(getParticipantList);

  const loading = useAppSelector((state) =>
    createLoadingSelector([fetchBlockTradeRequestsV2._PREFIX])(state),
  );

  useEffect(() => {
    if (!firstRender.current && firmCode) {
      memoizedFetch();
    } else {
      firstRender.current = false;
    }
  }, [firmCode, dispatch]);

  const onSave = (action: string, row: BlockTradeRequestModel, values: approveBlockTradeRequestForm | rejectBlockTradeRequestForm) => {
    const promiseFunction = action === 'Approve' ? approveBlockTradePromise : rejectBlockTradePromise;
    dispatch(hideModal());
    promiseFunction({ urlParams: { requestId: row.requestId }, ...values }, dispatch)
      .then(() => {
        dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
      });
  };

  const onApproveRejectClicked = (row: Row<BlockTradeRequestModel>, action: string) => {
    dispatch(
      showModal(APPROVE_REJECT_BLOCK_TRADE_REQUEST, {
        size: 'large',
        row: row?.original,
        memoizedConfirmColumns,
        action,
        onSave: (action: string, row: BlockTradeRequestModel, values: approveBlockTradeRequestForm | rejectBlockTradeRequestForm) => { onSave(action, row, values) }
      }),
    );
  }
  const memoizedColumns = useMemo(() => {
    return blockTradeRequestsMetadata(participantList, onApproveRejectClicked);
  }, [participantList]);

  const memoizedConfirmColumns = useMemo(() => {
    return blockTradeRequestsConfirmMetadata();
  }, [participantList]);

  const memoizedFetch = useCallback(() => {
    if (firmCode) {
      dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
      dispatch(fetchTradingParticipants({ tradingParticipantFirmCode: firmCode }));
    }
  }, [firmCode, dispatch]);

  return (
    <>
      <div>BlockTrade Requests</div>;
      <EMPTable8
        title="BlockTradeRequests"
        data={blockTradeRequests}
        local
        columns={memoizedColumns}
        fetchData={memoizedFetch}
        count={blockTradeRequests?.length}
        loading={loading}
        loadingText="Fetching BlockTrade Requests"
        minimumRows={5}
        showActiveFilters
        showActions
      />
    </>
  )
};

export default BlockTradeRequest;
