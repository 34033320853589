import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  collateralContracts,
  fetchContracts,
  CONTRACTS,
} from '../reducers/contractsReducer';

export default function* watchContractsSaga() {
  yield takeLatest(
    collateralContracts.TRIGGER,
    createSaga(collateralContracts, 'COLLATERAL_CONTRACTS'),
  );
  yield takeLatest(
    fetchContracts.TRIGGER,
    createSaga(fetchContracts, CONTRACTS),
  );
}
