import React from 'react';
import styled from 'styled-components';
import { Modal, Form, Icon } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import TextButton from '../../common/TextButton';
import StyledButton from '../../common/StyledButton';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { accountType, customerOrderCapacity, side } from '../BlockTrades/menuOptions';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';

type Props = {
  row: BlockTradeRequestModel;
  memoizedConfirmColumns: any;
  action: string;
  valid: boolean;
  onSave: Function;
  change: Function;
  isSpot: boolean;
};

export type approveBlockTradeRequestForm = {
  clOrdId: string;
  exchangeMemberId?: string;
  customerAccountRef?: string;
  senderSubId: number;
  custOrderCapacity: string;
  accountType: string;
  cgm: string;
};

export type approveSpotBlockTradeRequestForm = {
  clOrdId: string;
  accountLabel: string;
  senderSubId: number;
};

export type rejectBlockTradeRequestForm = {
  side: string;
};

const Container = styled.div`
  padding: 15px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const RowSpaced = styled.div`
  display: flex;
  margin-bottom: 5px;
  margin-top: 20px;
`;

const RowTable = styled.div`
  display: contents;
  div {
    margin-bottom: 0px;
  }
`;

const StyledField = styled(Field)`
  width: 50%;
  padding: 0px 10px;
  flex: 1 1 0;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(StyledButton)`
  width: 150px;
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;
const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM = 'approveRejectBlockTradeRequestForm';

const ApproveRejectBlockTradeRequest = ({
  row,
  memoizedConfirmColumns,
  action,
  valid,
  onSave,
  isSpot = false
}: Props) => {
  const dispatch = useAppDispatch();
  const approveValues: Partial<approveBlockTradeRequestForm> = useAppSelector((state) =>
    getFormValues(APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM)(state),
  );

  const rejectValues: Partial<rejectBlockTradeRequestForm> = useAppSelector((state) =>
    getFormValues(APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM)(state),
  );

  return (
    <Container>
      <Header>{`${action} Block Trade Request`}</Header>
      <Modal.Content>
        <StyledForm>
          <RowTable>
            <EMPTable8
              title="BlockTradeRequests"
              data={[row]}
              local
              columns={memoizedConfirmColumns}
              fetchData={() => { }}
              count={1}
              minimumRows={1}
            />
          </RowTable>
          {action == 'Approve' && <>
            <RowSpaced>
              <StyledField
                component={renderField}
                label="Client Order ID *"
                name="clOrdId"
                type="text"
                placeholder='Enter the Client Order Id...'
                required
                validate={[rules.required]}
              />
              <StyledField
                component={renderField}
                label="Sender SubId *"
                name="senderSubId"
                type="text"
                minLength="3"
                maxLength="18"
                required
                validate={[rules.required, rules.alphanumeric]}
                placeholder='Enter the Sender SubId...'
              />
              {!isSpot && <StyledField
                component={renderField}
                label="Exchange Member ID *"
                name="exchangeMemberId"
                type="text"
                required
                validate={[rules.required, rules.alphanumeric]}
                placeholder='Enter the Exchange Member ID...'
              />}
              {isSpot && <StyledField
                component={renderField}
                label="Account Label *"
                name="accountLabel"
                type="text"
                required
                validate={[rules.required]}
                placeholder='Enter the Account Label...'
              />
              }
            </RowSpaced>
            {!isSpot && <Row>
              <StyledField
                component={renderField}
                label="CGM"
                name="cgm"
                type="text"
                validate={[rules.alphanumeric]}
              />

              <StyledField
                component={renderField}
                label="Customer Account Reference *"
                name="customerAccountRef"
                type="text"
                required
                validate={[rules.required, rules.carCgmName]}
                placeholder='Enter the Customer Account Reference...'
              />
            </Row>}
            {!isSpot && <Row>
              <StyledField
                component={renderDropdown}
                label="Customer Order Capacity"
                name="custOrderCapacity"
                fluid
                options={customerOrderCapacity}
                required
                minimal
                validate={[rules.required]}
              />
              <StyledField
                component={renderDropdown}
                label="Account Type"
                name="accountType"
                fluid
                options={accountType}
                minimal
                required
                validate={[rules.required]}
              />
            </Row>}</>}
          {action == 'Reject' && <RowSpaced>
            <StyledField
              component={renderDropdown}
              label="Side"
              name="side"
              fluid
              options={side}
              minimal
              required
              validate={[rules.required]} />
          </RowSpaced>}
        </StyledForm>
      </Modal.Content>
      <Actions>
        <Action text="Cancel" onClick={() => dispatch(hideModal())} />
        <Action
          text="Save"
          onClick={() => onSave(action, row, action === 'Approve' ? approveValues : rejectValues)}
          danger={action == 'Reject'}
          disabled={!valid}
          data-cy="create-cgm-save-btn"
        />
      </Actions>
    </Container>
  );
};

export default reduxForm({
  form: APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM,
})(ApproveRejectBlockTradeRequest);
