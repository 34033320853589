import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserHasMemberOfType } from '../reducers/userReducer';
import { FCM_MEMBER_TYPE } from '../constants/memberTypes';
import EnvPromise from '../config/env';

/* Use this hook to know if the currentUser is FCM Type Member*/
const useIsFCM = () => {
  const dispatch = useDispatch();
  const [enableMargin, setEnableMargin] = useState<string | null>(null);
  const isCurrentUserFCMTypeMember = useSelector((state) =>
    getUserHasMemberOfType(state, [FCM_MEMBER_TYPE]),
  );

  useEffect(() => {
    EnvPromise.then((env) => setEnableMargin(env.enableMargin));
  }, [dispatch]);

  if (enableMargin === null) return null;
  if (enableMargin !== undefined) {
    return isCurrentUserFCMTypeMember && enableMargin === 'true';
  }
  return false;
};
export default useIsFCM;

export const useIsNotFCM = () => {
  const isFcm = useIsFCM();
  return !isFcm;
};
