import colors from '../constants/colors';

export const STYLED_BUTTON_PRIMARY_THEME = {
  borderColor: colors.GREEN,
  borderColorDisabled: colors.GREEN_BUTTON_PRIMARY_DISABLED_BG,
  bgColor: colors.GREEN,
  bgColorDisabled: colors.GREEN_BUTTON_PRIMARY_DISABLED_BG,
  bgColorHover: colors.GREEN_BUTTON_HOVER,
  bgColorActive: colors.GREEN_BUTTON_ACTIVE,
  color: colors.NAVY,
  colorDisabled: colors.GREEN_BUTTON_PRIMARY_DISABLED_TEXT,
};

export const STYLED_BUTTON_SECONDARY_THEME = {
  borderColor: colors.GREEN,
  borderColorDisabled: colors.GRAY_1,
  bgColor: colors.NAVY,
  bgColorDisabled: colors.GRAY_1,
  bgColorHover: colors.NAVY_BUTTON_ACTIVE,
  bgColorActive: colors.NAVY_BUTTON_HOVER,
  color: colors.GREEN,
  colorDisabled: colors.GRAY_2,
};

export const STYLED_BUTTON_DANGER_THEME = {
  borderColor: colors.RED,
  borderColorDisabled: colors.GRAY_1,
  bgColor: colors.RED,
  bgColorDisabled: colors.GRAY_1,
  bgColorHover: colors.RED_2,
  bgColorActive: colors.RED_2,
  color: colors.GRAY_2,
  colorDisabled: colors.GRAY_2,
};

export const STYLED_BUTTON_SELL_THEME = {
  borderColor: colors.RED_SELL_BUTTON,
  borderColorDisabled: colors.RED_SELL_BUTTON_DISABLED,
  bgColor: colors.RED_SELL_BUTTON,
  bgColorDisabled: colors.RED_SELL_BUTTON_DISABLED,
  bgColorHover: colors.RED_SELL_BUTTON_HOVER,
  bgColorActive: colors.RED_SELL_BUTTON_ACTIVE,
  color: colors.WHITE,
  colorDisabled: colors.GRAY_2,
};

export const getStyledButtonThemeType = (primary, secondary, danger, sell) => {
  if (sell) return 'sell';
  if (danger) return 'danger';
  if (primary) return 'primary';
  if (secondary) return 'secondary';
  return 'secondary';
};
