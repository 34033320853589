import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import {
  fetchBankTransactionDetails,
  getBankTransactionDetails,
} from '../../reducers/accountHistoryReducer';

const mapStateToProps = (state, props) => ({
  bankTransactionDetails: getBankTransactionDetails(
    state,
    _.get(props, 'uuid', ''),
  ),
});

const mapDispatchToProps = {
  fetchBankTransactionDetails,
};

const renderBodyRow = ({ key, value }, i) => ({
  key: key || `row-${i}`,
  cells: [
    key,
    value
      ? { key: 'value', content: value, textAlign: 'right' }
      : { content: 'Unknown', textAlign: 'right' },
  ],
});

const transactionDetailsData = (row) => [
  {
    key: row.txType === 'deposit' ? 'Sender' : 'Recipient',
    value: row.label || row.accountNumber,
  },
];

class BankTransactionDetailsContainer extends Component {
  componentDidMount = () => {
    this.props.uuid &&
      this.props.fetchBankTransactionDetails({ uuid: this.props.uuid });
  };

  render = () => {
    const tableData = transactionDetailsData({
      ...this.props,
      ...this.props.bankTransactionDetails,
    });
    return (
      <Table
        basic="very"
        renderBodyRow={renderBodyRow}
        tableData={tableData}
        className="bank-details-table"
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankTransactionDetailsContainer);
