/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react';
import { Card, Icon, Button, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';
import {
  fetchUser,
  getLoggedInUser,
  submitPiiPromiseCreator,
} from '../../reducers/userReducer';
import {
  PERSONAL,
  VERIFICATION,
  CREATE_FUNDING_PASSWORD,
} from '../../constants/modalTypes';
import history from '../../constants/history';
import * as states from '../../constants/appStates';
import * as userStates from '../../constants/userStates';
import * as onBoardingSteps from '../../constants/onBoardingSteps';

export const getStepState = (step, appState, userState) => {
  switch (step) {
    case 1:
      if (appState === states.PENDING_PII) {
        return 'active';
      }
      return 'done';
    case 2:
      if (appState === states.KBA1) {
        return 'active';
      }
      if (appState === states.KBA2) {
        return 'active';
      }
      if (appState === states.PENDING_PII_REVIEW) {
        return 'pending';
      }
      if (appState === states.FACE_ID_IMAGES) {
        return 'active';
      }
      if (appState === states.ID_IMAGES) {
        return 'active';
      }
      if (appState === states.IMAGES_SUBMITTED) {
        return 'pending';
      }
      if (appState === states.PENDING_REVIEW) {
        return 'pending';
      }
      if (appState === states.REJECTED) {
        return 'rejected';
      }
      if (userState === userStates.FUNDING) {
        return 'done';
      }
      if (userState === userStates.FIRM_SECURITY) {
        return 'done';
      }
      if (appState === states.APPROVED) {
        return 'done';
      }
      if (userState === userStates.DISCLOSURES_AND_DISCLAIMERS) {
        return 'done';
      }
      if (userState === userStates.AGREEMENT) {
        return 'done';
      }
      if (userState === userStates.SECURITY) {
        return 'done';
      }
      if (userState === userStates.FUNDING) {
        return 'done';
      }
      if (userState === userStates.FIRM_FUNDING) {
        return 'done';
      }
      if (userState === userStates.ONBOARDED) {
        return 'done';
      }
      if (userState === userStates.PENDING_MARC_REVIEW) {
        return 'done';
      }
      if (appState === states.COMPLIANCE) {
        return 'active';
      }
      if (appState === states.COMPLIANCE_REVIEW) {
        return 'pending';
      }
      if (appState === states.COMPLIANCE_APPROVED) {
        return 'done';
      }
      if (userState === userStates.PENDING_PII_REVIEW) {
        return 'done';
      }
      if (userState === userStates.PII_SUBMITTED) {
        return 'done';
      }
      if (userState === userStates.REJECTED) {
        return 'done';
      }
      return 'disabled';
    case 3:
      if (userState === userStates.PENDING_PII_REVIEW) {
        return 'pending';
      }
      if (userState === userStates.PENDING_MARC_REVIEW) {
        return 'pending';
      }
      if (appState === states.COMPLIANCE_APPROVED) {
        return 'pending';
      }
      if (userState === userStates.PII_SUBMITTED) {
        return 'pending';
      }
      if (userState === userStates.SECURITY) {
        return 'active';
      }
      if (
        userState === userStates.DISCLOSURES_AND_DISCLAIMERS ||
        userState === userStates.AGREEMENT
      ) {
        return 'active';
      }
      if (userState === userStates.FUNDING) {
        return 'active';
      }
      if (userState === userStates.FIRM_FUNDING) {
        return 'active';
      }
      if (userState === userStates.FIRM_SECURITY) {
        return 'active';
      }
      if (appState === userStates.MARC_REJECTED) {
        return 'rejected';
      }
      if (userState === userStates.REJECTED) {
        return 'rejected';
      }
      if (userState === userStates.ONBOARDED) {
        return 'done';
      }
      return 'disabled';
    default:
      return 'disabled';
  }
};

export const getStepIcon = (info) => {
  switch (info.stepState) {
    case 'rejected':
      return 'exclamation circle red';
    case 'done':
      return 'check circle green';
    case 'pending':
      return 'clock blue';
    default:
      return '';
  }
};

export const getStepDescription = (info) => {
  switch (info.stepState) {
    case 'rejected':
      return 'Unfortunately your application could not be approved at this time.';
    case 'done':
      return 'You are done with this step!';
    case 'pending':
      return 'Your information is currently under review. Please check back in 48 hours anytime between 7am to 7pm CT Monday - Friday.';
    case 'active':
      return 'Click here to complete remaining step(s).';
    default:
      return info.description;
  }
};

export const stepsInfo = (appState, userState) => [
  {
    key: '01',
    title: 'Personal',
    description: 'Enter your personal details.',
    icon: 'user outline',
    goTo: PERSONAL,
    stepState: getStepState(1, appState, userState),
    appState,
    userState,
    stepNumber: onBoardingSteps.PERSONAL,
  },
  {
    key: '02',
    title: 'Verification',
    description: 'Verify your identity.',
    icon: 'shield',
    goTo: VERIFICATION,
    stepState: getStepState(2, appState, userState),
    appState,
    userState,
    stepNumber: onBoardingSteps.VERIFICATION,
  },
  {
    key: '03',
    title: 'Application Approval',
    description: 'Setup your funding password.',
    icon: 'cogs',
    goTo: CREATE_FUNDING_PASSWORD,
    stepState: getStepState(3, appState, userState),
    appState,
    userState,
    stepNumber: onBoardingSteps.DISCLOSURES,
  },
];

const CardExampleCard = ({ info }) => (
  <Card
    onClick={() => info.stepState === 'active' && history.push('/register')}
    className={`onboarding-steps ${info.stepState}`}
    data-cy={`ob-verification-steps-${info.key}`}
  >
    <Card.Header>STEP {info.key}</Card.Header>
    <Icon.Group size="huge">
      <Icon name={info.icon} />
      <Icon corner="bottom right" name={getStepIcon(info)} />
    </Icon.Group>
    <Card.Content>
      <Card.Header>{info.title}</Card.Header>
      <Card.Description>{getStepDescription(info)}</Card.Description>
    </Card.Content>
  </Card>
);

const SuccessButton = () => (
  <Fragment>
    <Divider hidden />
    <Button
      className="huge"
      animated
      primary
      onClick={() => history.push('/home')}
    >
      <Button.Content visible>
        You are all done! Click here to start trading!
      </Button.Content>
      <Button.Content hidden>
        <Icon name="arrow right black" />
      </Button.Content>
    </Button>
  </Fragment>
);

const mapStateToProps = (state) => ({ user: getLoggedInUser(state) });
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchUser,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      submitPiiPromiseCreator,
    },
    dispatch,
  ),
});
class WelcomeContainer extends Component {
  render() {
    const { user } = this.props;

    if (
      user.state === userStates.PENDING ||
      user.state === userStates.FIRM_SETUP ||
      user.state === userStates.ACCOUNT_LINKED
    ) {
      history.push('/pending');
    }

    return (
      <div className="welcome-container">
        <section className="vert-flex">
          <div className="big-logo" alt="ErisX" />
          {user.state !== userStates.ONBOARDED && (
            <p>
              <i>
                To be able to trade cryptocurrencies, we require additional
                verification of your account.
              </i>
            </p>
          )}
          <div>
            {user.state === userStates.ONBOARDED && isMobileOnly && (
              <SuccessButton />
            )}
            <Card.Group>
              {stepsInfo(user.appState, user.state).map((info) => (
                <CardExampleCard info={info} key={info.key} />
              ))}
            </Card.Group>
          </div>
          {user.state === userStates.ONBOARDED && !isMobileOnly && (
            <SuccessButton />
          )}
        </section>
      </div>
    );
  }
}

const DecoratedWelcomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomeContainer);

// Permanently set displayName
DecoratedWelcomeContainer.displayName = 'WelcomeContainer';

export default DecoratedWelcomeContainer;
