import isEmpty from 'lodash/isEmpty';
import { snakeCase } from 'change-case';

/**
  @desc: Receives an parameterized uri and an 
          and object with the parameter values and 
          returns the uri with the populated values
  @param - uri: string with unpopulated parameters, delimited by ':'
  @param - urlParams: object that maps a parameter name with its value
  @returns: uri populated with paramaters
  e.g.:
 uri = "http://localhost:3002/api/v2/update_cgm_/:account_id/cgm/:cgm_id";
 urlParams = { account_id: "1234", cgm_id: "456" };
 returns http://localhost:3002/api/v2/update_cgm_/1234/cgm/456 
*/

const replaceUrlParams = (
  uri: string,
  urlParams: { [key: string]: string | number } | {},
): string => {
  if (isEmpty(urlParams)) return uri;
  let newUrl = uri;
  Object.entries(urlParams).forEach((urlParam) => {
    const [param, value] = urlParam;
    const snakeCasedParam = snakeCase(param);
    newUrl = newUrl.replace(`:${snakeCasedParam}`, String(value));
  });
  return newUrl;
};

export default replaceUrlParams;
