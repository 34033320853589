import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Segment,
  Icon,
  Loader,
  Popup,
  Header,
  Grid,
} from 'semantic-ui-react';
import { get } from 'lodash';
import {
  createLoadingSelector,
  fundsDesignation,
  NON_SEG,
} from 'erisxkit/client';
import { connect } from 'react-redux';
import RenderQRCode from './RenderQRCode';
import {
  referenceCode,
  clearReferenceCode,
} from '../../reducers/fundTransfersReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import { isViewportLessThan } from '../../reducers/uiReducer';
import BchMessage from '../../common/components/BchMessage';
import XrpMessage from '../../common/components/XrpMessage';
import EnvPromise from '../../config/env';
import normalizeReferenceCode from '../../common/ReferenceCode.js';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';

const timeoutLength = 1500;

const mapDispatchToProps = {
  referenceCode,
  clearReferenceCode,
};

const mapStateToProps = (state) => ({
  account: getActiveAccount(state),
  fundTransfers: state.fundTransfers,
  refCodeLoading: createLoadingSelector(['REFERENCE_CODE'])(state),
  viewportLessThanSmall: isViewportLessThan('small', state),
  viewportLessThanLarge: isViewportLessThan('large', state),
});

const descriptionFromAssetType = (currencyOptions = [], selected = '') =>
  get(
    currencyOptions.filter(
      (option) => option.value.toUpperCase() === selected.toUpperCase(),
    ),
    ['0', 'description'],
  ) || selected;

class CryptoDeposit extends Component {
  state = { isOpen: false };

  componentWillMount = () => {
    EnvPromise.then(({ disabledAssets }) => this.setState({ disabledAssets }));
  };

  componentDidUpdate = (prevProps) => {
    const accountId = get(this.props, ['account', 'accountId'], '');

    // if either asset type or funds designation changed, get a new reference code
    if (
      this.props.assetType !== prevProps.assetType ||
      this.props.fundsDesignation !== prevProps.fundsDesignation
    ) {
      const payload = {
        accountId,
        assetType: this.props.assetType, // v-- default to non-seg (required)
        fundsDesignation:
          this.props.fundsDesignation || fundsDesignation[NON_SEG].value,
      };

      this.props.referenceCode(payload);
    }
  };

  componentWillUnmount() {
    // Clear the reference code on unmount
    this.props.clearReferenceCode();
  }

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, timeoutLength);
  };

  // TODO: Also used in TransactionInformationModal. Should be generalized
  copyAddress = () => {
    /* Get the text field */
    const copyText = document.getElementById('destination-address');
    /* Select the text field */
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.handleOpen();
  };

  render = () => {
    const [refCode, destinationTag] = this.props.assetType
      ? normalizeReferenceCode(get(this.props.fundTransfers, 'referenceCode'))
      : ['', null];

    const invalidLocationStateError = refCode
      ? null
      : get(this.props.fundTransfers, 'errorMessage');

    const {
      accountCode,
      assetType,
      currencyOptions,
      firmCode,
      handleChange,
      assetTypesLoading,
      assetTypesFailed,
      refCodeLoading,
      viewportLessThanSmall,
    } = this.props;
    // In the context of crypto deposits, referenceCode is the deposit address.

    const invalidAssetMapping =
      assetType && this.state.disabledAssets
        ? this.state.disabledAssets[assetType.toLowerCase()]
        : undefined;

    const invalidAssetMessage = invalidAssetMapping
      ? invalidAssetMapping.disabledDepositMessage
      : '';

    const isInvalidAsset = invalidAssetMessage != '';

    return (
      <section className="overlay-form ui text container">
        <Header as="h2">
          Cryptocurrency Deposit to account{' '}
          <span className="bold">{`${
            firmCode ? `${firmCode}-` : ''
          }${accountCode}`}</span>
        </Header>
        <div className="crypto-deposit">
          <Form>
            <Form.Field
              control={Form.Dropdown}
              label="Asset Type:"
              placeholder="Select an asset type to deposit"
              defaultValue={assetType}
              fluid
              selection
              options={currencyOptions}
              name="assetType"
              onChange={handleChange}
              loading={assetTypesLoading}
              error={assetTypesFailed}
              data-cy="crypto-deposit-asset-dropdown"
            />
            {isInvalidAsset ? (
              ''
            ) : (
              <Form.Input
                onClick={this.copyAddress}
                label="Destination Address"
                id="destination-address"
                className="mono outline crypto-address"
                fluid
                disabled={!refCode || isInvalidAsset}
                readOnly
                icon={
                  <Popup
                    trigger={
                      <Icon
                        link
                        name="copy outline"
                        onClick={this.copyAddress}
                      />
                    }
                    content="Copied!"
                    on="click"
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position="bottom right"
                    pointing
                    inverted
                  />
                }
                value={refCode}
              />
            )}
            {!isInvalidAsset && destinationTag && (
              <Form.Input
                onClick={this.copyAddress}
                label="Destination Tag"
                id="destination-tag"
                className="mono outline crypto-address"
                fluid
                readOnly
                icon={
                  <Popup
                    trigger={
                      <Icon
                        link
                        name="copy outline"
                        onClick={this.copyAddress}
                      />
                    }
                    content="Copied!"
                    on="click"
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position="bottom right"
                    pointing
                    inverted
                  />
                }
                value={destinationTag}
              />
            )}
            {isInvalidAsset ? (
              <Segment>
                <h4>
                  <Icon name="info circle" />
                  {invalidAssetMessage}
                </h4>
              </Segment>
            ) : (
              <Fragment>
                <BchMessage assetType={assetType} />
                <XrpMessage assetType={assetType} />
              </Fragment>
            )}
          </Form>
          {isInvalidAsset ? (
            <Segment padded className="vert-flex" />
          ) : (
            <Segment padded className="vert-flex">
              <Loader
                active={refCodeLoading}
                content="Generating deposit address..."
              />
              <RenderQRCode
                loading={refCodeLoading}
                refCode={refCode}
                small={viewportLessThanSmall}
                description={descriptionFromAssetType(
                  currencyOptions,
                  assetType,
                )}
                assetType={assetType}
                invalidLocationStateError={invalidLocationStateError}
              />
            </Segment>
          )}
        </div>
        <IraAccountDisclaimer />
      </section>
    );
  };
}

CryptoDeposit.propTypes = {
  accountCode: PropTypes.string,
  assetType: PropTypes.string,
  assetTypesLoading: PropTypes.bool,
  assetTypesFailed: PropTypes.bool,
  currencyOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  firmCode: PropTypes.string,
  fundsDesignation: PropTypes.string,
  fundTransfers: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  refCodeLoading: PropTypes.bool,
  referenceCode: PropTypes.func.isRequired,
  viewportLessThanSmall: PropTypes.bool.isRequired,
};

CryptoDeposit.defaultProps = {
  accountCode: '',
  assetType: '',
  assetTypesLoading: false,
  assetTypesFailed: false,
  currencyOptions: [],
  firmCode: '',
  fundsDesignation: undefined,
  fundTransfers: {},
  refCodeLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CryptoDeposit);
