import React, { Component } from 'react';
import { startCase, get } from 'lodash';
import { hideModal, showModal } from 'erisxkit/client';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { updateImage } from '../../reducers/fileUploadReducer';
import { CONFIRM_PHOTO, DEFAULT_PHOTO_ERROR } from '../../constants/modalTypes';
import LoadingComponent from '../components/xtable/LoadingComponent';
import ClassificationService from '../../components/onBoarding/Acuant/ClassificationService';
import { trackEvent } from '../tracking';
import {
  MAX_TRIES_BEFORE_UPLOAD,
  MIN_GLARE,
  MIN_SHARPNESS,
} from '../../constants/pictureUploadReqs';

export const glareBlurFailed = (glare, sharpness) => {
  trackEvent('AMLKYC', 'glareBlurFailure');
  trackEvent('AMLKYC', 'glareFailure', '', glare);
  trackEvent('AMLKYC', 'blurFailure', '', sharpness);
  datadogRum.addAction('glareBlueFailure', { glare, sharpness });
};

export const classificationFailed = (side) => {
  trackEvent(
    'AMLKYC',
    `classificationFailure${side === 'id_front' ? 'IdFront' : 'IdBack'}`,
  );
};

class AcuantReactCamera extends Component {
  constructor(props) {
    super(props);
    this.detectedCount = 0;
    this.state = {
      processing: false,
      cameraStarted: false,
      times: 0,
    };
  }

  componentDidMount() {
    this.startCamera();
  }

  onCropped = (response) => {
    const { showModal, hideModal } = this.props;
    const { imageType } = this.props.location.state;

    const { uploadFile } = this;
    this.setProcessing(false);
    this.setState(
      (state) => ({
        times: state.times + 1,
        cameraStarted: false,
      }),
      () => {
        if (response) {
          // check sharpness and glare here
          if (
            (response.sharpness < MIN_SHARPNESS ||
              response.glare < MIN_GLARE) &&
            !this.userExceededMaxTries()
          ) {
            glareBlurFailed(response.glare, response.sharpness);

            showModal(DEFAULT_PHOTO_ERROR, {
              onConfirm: () => {
                hideModal();
                uploadFile(response.image.data, imageType);
                this.goBack();
              },
              onRetry: () => {
                hideModal();
                hideModal();
                this.goBack();
              },
              times: this.state.times,
            });
          } else {
            // check for when user has exceeded 2 tries
            if (
              response.sharpness < MIN_SHARPNESS ||
              response.glare < MIN_GLARE
            ) {
              glareBlurFailed(response.glare, response.sharpness);
            }
            showModal(CONFIRM_PHOTO, {
              type: imageType,
              image: response.image.data,
              onConfirm: () => {
                this.setProcessing(true);
                hideModal();
                ClassificationService(response).then((classification) => {
                  this.setProcessing(false);
                  const idType = startCase(imageType.split('_')[1]);
                  // quick fix for always firing GA event for failures
                  if (
                    get(classification, [idType, 'ClassName']) === 'Unknown'
                  ) {
                    classificationFailed(imageType);
                  }
                  if (
                    get(classification, [idType, 'ClassName']) !== 'Unknown' ||
                    this.userExceededMaxTries()
                  ) {
                    uploadFile(response.image.data, imageType);
                    this.goBack();
                  } else {
                    classificationFailed(imageType);

                    showModal(DEFAULT_PHOTO_ERROR, {
                      onConfirm: () => {
                        hideModal();
                        uploadFile(response.image.data, imageType);
                        this.goBack();
                      },
                      onRetry: () => {
                        this.props.hideModal();
                        this.startCamera();
                      },
                      times: this.state.times,
                      hideCloseButton: true,
                    });
                  }
                });
              },

              onReject: () => {
                trackEvent('AMLKYC', 'IDuploadRetry');
                hideModal();
                this.goBack();
              },
            });
          }
        } else {
          // cropping error
          // restart capture
          datadogRum.addAction('AcuantSdkFailureMobile', { response });
          this.startCamera({});
        }
      },
    );
  };

  onCaptured = () => {
    this.setProcessing(true);
  };

  setProcessing = (value) => {
    this.setState({
      processing: value,
    });
  };

  userExceededMaxTries() {
    return this.state.times > MAX_TRIES_BEFORE_UPLOAD;
  }

  uploadFile = (file) => {
    const { imageType, idType } = this.props.location.state;

    if (imageType === 'face') {
      this.props.updateImage({
        imageType,
        image: file,
      });
      // for id uploads
    } else {
      this.props.updateImage({
        imageType,
        idType: idType.type,
        image: file,
      });
    }
    this.props.hideModal(CONFIRM_PHOTO);
  };

  onFrameAvailable = (response) => {};

  startCamera = () => {
    this.setState(
      {
        cameraStarted: true,
      },
      () => {
        if (window.AcuantCameraUI) {
          if (window.AcuantCamera.isCameraSupported) {
            window.AcuantCameraUI.start(
              {
                onCaptured: this.onCaptured,
                onCropped: this.onCropped,
                onFrameAvailable: this.onFrameAvailable,
              },
              this.onError,
            );
          } else {
            window.AcuantCamera.startManualCapture(
              {
                onCaptured: this.onCaptured,
                onCropped: this.onCropped,
              },
              this.onError,
            );
          }
        }
      },
    );
  };

  goBack = () => {
    this.props.history.replace({
      ...this.props.location,
      pathname: '/register',
    });
  };

  onError = (err, code) => {
    console.error('Failed to start camera', err, code);
    window.AcuantCamera.isCameraSupported = false;
    this.goBack();
  };

  render() {
    if (this.state.processing) {
      return (
        <div className="onboarding-container">
          <div className="onboarding-information">
            <LoadingComponent message={{ text: 'Analyzing image...' }} />
          </div>
        </div>
      );
    }

    if (this.state.cameraStarted) {
      return <div id="acuant-camera" />;
    }

    return (
      <div className="onboarding-container">
        Please <a onClick={this.goBack}> &nbsp;click here&nbsp; </a> to
        continue.
      </div>
    );
  }
}

const mapDispatchToProps = {
  hideModal,
  showModal,
  updateImage,
};

export default connect(null, mapDispatchToProps)(withRouter(AcuantReactCamera));
