import { create } from 'cogo-toast';

// Create a toast message that will only close after being clicked
const createWithPersistence = (text, options) => {
  const callback = create(text, {
    ...options,
    hideAfter: 0,
    onClick: () => callback(),
  });
  return callback;
};

const success = (text, options) =>
  createWithPersistence(text, { ...options, type: 'success' });
const warn = (text, options) =>
  createWithPersistence(text, { ...options, type: 'warn' });
const error = (text, options) =>
  createWithPersistence(text, { ...options, type: 'error' });
const info = (text, options) =>
  createWithPersistence(text, { ...options, type: 'info' });
const loading = (text, options) =>
  createWithPersistence(text, { ...options, type: 'loading' });

export default {
  success,
  warn,
  error,
  info,
  loading,
  createWithPersistence,
};
