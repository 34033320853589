import _ from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { Trade } from '../ts/models/TRADES.model';

//* actions */
export const fetchTrades = createRoutine('TRADES');

//* Reducer */
export default handleActions<Trade[], any>(
  {
    [fetchTrades.SUCCESS]: (state, { payload }) => payload,
  },
  [],
);

//* Selectors */
// TODO: TYPE STATE AND ACTION PAYLOADS
export const getTrades = (state: any): Trade[] =>
  _.get(state, ['trades', 'trades'], []);

export const getTradesCount = (state: any): number =>
  _.get(state, ['trades', 'count'], 0);
