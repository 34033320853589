import React, { Fragment } from 'react';
import { Header, Icon } from 'semantic-ui-react';

export default () => (
  <Fragment>
    <Header as="h1" icon textAlign="center">
      <Icon name="lock" />
      <Header.Content>
        This content is not accessible by non-clearing members.
      </Header.Content>
      <Header.Subheader>(Sorry!)</Header.Subheader>
    </Header>
  </Fragment>
);
