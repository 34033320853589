import React from 'react';
import { map } from 'lodash';
import { Button, Form, Divider } from 'semantic-ui-react';
import { trackEvent } from '../../common/tracking';

const parsedIdOptions = (options) =>
  map(options, (id) => ({ key: id.type, text: id.name, value: id.type }));

const Identification = ({
  handleChange,
  options = [],
  goToStep,
  selectedIdType,
}) => {
  const onContinue = () => {
    trackEvent('AMLKYC', 'IDselect');
    goToStep(3);
  };
  return (
    <div className="onboarding-container">
      <div className="onboarding-content">
        <div className="onboarding-header">Identification</div>
        <div className="onboarding-header">
          Please select the type of identification you would like to upload:
        </div>
        <Form>
          <Form.Dropdown
            placeholder="Select ID type"
            defaultValue={selectedIdType || 'drivers_license'}
            fluid
            selection
            options={parsedIdOptions(options)}
            name="idType"
            onChange={handleChange}
            data-cy="onboarding-id-type-dropdown"
          />
        </Form>
        <Divider hidden />
        <div>Minimum Requirements:</div>
        <ul className="min-reqs">
          <li>Maximum File Size 4 MB</li>
          <li>Minimum Resolution (DPI) 72</li>
          <li>Required WebCam Camera 960 x 720</li>
          <li>Browsers Supported (IE8+, Safari 5+, Chrome, Mozilla 20+)</li>
          <li>Image Formats Supported: PDF, JPG, TIFF, PNG & BMP</li>
          <li>Image Color Min. 8 Bit Grayscale</li>
          <li>Image Color Depth Max of 24 bit</li>
        </ul>
        <div>
          <Button
            fluid
            primary
            name="upload_files"
            content="CONTINUE"
            onClick={onContinue}
            data-dd-action-name={`Selected Id ${selectedIdType}`}
            data-cy={`ob-verification-selected-id`}
          />
        </div>
      </div>
    </div>
  );
};

export default Identification;
