import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import sign from 'eris-ecdsa';
import stringEntropy from 'fast-password-entropy';
import { getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { hideModal } from 'erisxkit/client';
import { isEmpty, get } from 'lodash';
import {
  storeTxAuthKeyPromiseCreator,
  skipFunding,
} from '../reducers/utilitiesReducer';
import CreateFundingPassword from '../components/CreateFundingPassword';
import {
  getCurrentAuthId,
  getLoggedInUser,
  fetchUser,
  fetchUserPromiseCreator,
  getIsRsbixUser,
} from '../reducers/userReducer';
import LoadingComponent from '../common/components/xtable/LoadingComponent';
import * as userStates from '../constants/userStates';
import { isOnboarding } from '../utils/methods';

import EnvPromise from '../config/env';
import { pushTag, trackEvent } from '../common/tracking';
import {
  createLinkedMemberBankAccountIraMember,
  iraBankMsgPromiseCreator,
} from '../reducers/linkedAccountsReducer';

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      storeTxAuthKeyPromiseCreator,
      fetchUserPromiseCreator,
      iraBankMsgPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      fetchUser,
      hideModal,
      skipFunding,
      createLinkedMemberBankAccountIraMember,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  authId: getCurrentAuthId(state),
  user: getLoggedInUser(state),
  isRsbixUser: getIsRsbixUser(state),
  values: getFormValues('funding_password')(state),
});

const storePasswordErrorMessage = {
  header: 'Password was not stored, please contact support.',
  content:
    'Alternatively you can choose to skip this step.  You will always be able to set this up after you are on-boarded.',
  type: 'warning',
};

class CreateFundingPasswordContainer extends Component {
  state = {
    storeTxAuthKeyLoading: false,
    storeTxAuthKeyFailed: false,
  };

  componentWillMount = () =>
    EnvPromise.then(({ rsbixFrontendEndpoint }) =>
      this.setState({ rsbixFrontendEndpoint }),
    );

  handleSubmit = () => {
    const { values = {}, isRsbixUser } = this.props;
    if (this.props.action !== 'reset') {
      trackEvent('accountOpening', 'fundingPasswordSubmit');
      pushTag('completeOnboarding');
    }
    // make the request here so the loading icon can be shown.
    let oldPassword = values.password;
    if (this.props.action === 'reset') {
      oldPassword = values.oldPassword;
    }
    const { password } = values;
    this.setState({ storeTxAuthKeyLoading: true });
    const publicKey = sign.pubKeyBs58(this.props.authId, password);
    const msg = [this.props.authId, publicKey, 'member', Date.now().toString()];
    const sig = sign.signMsgBs58(
      JSON.stringify(msg),
      sign.privateKeyFromPassword(this.props.authId, oldPassword),
    );

    this.props.storeTxAuthKeyPromiseCreator({ msg, sig }).then(
      (successPayload) => {
        if (successPayload.state === userStates.ONBOARDED) {
          // if it's a RSBIX user, redirect them to that application
          if (isRsbixUser) {
            window.location.assign(`${this.state.rsbixFrontendEndpoint}`);
          }
        }
        if (successPayload.state === userStates.FUNDING_KEY_STORED) {
          // For IRA Members, we create their SSI automatically using the funding password that was just set up.
          // Start IRA Member Work
          this.props.iraBankMsgPromiseCreator().then(({ msg }) => {
            if (!isEmpty(msg)) {
              // add nonce to msg
              msg.push(Date.now().toString());
              // sign the message
              const sig = sign.signMsgBs58(
                JSON.stringify(msg),
                sign.privateKeyFromPassword(this.props.authId, oldPassword),
              );
              // pass the sig to the backend to create the IRA SSI
              this.props.createLinkedMemberBankAccountIraMember({ sig, msg });
            }
          });
          // End IRA Member Work

          this.setState({
            storeTxAuthKeyLoading: false,
          });
        } else {
          this.setState({
            message: storePasswordErrorMessage,
            storeTxAuthKeyLoading: false,
          });
        }
        this.props.hideModal();
      },
      (failurePayload) => {
        this.setState({
          message: storePasswordErrorMessage,
          storeTxAuthKeyLoading: false,
          storeTxAuthKeyFailed: true,
        });
      },
    );
  };

  render = () => {
    const pendingUserStates = [
      userStates.FUNDING_KEY_SKIPPED,
      userStates.FUNDING_KEY_STORED,
      userStates.PENDING_ACCOUNT,
      userStates.ACCOUNT_LINKED,
      userStates.EMARKET_ASSOCIATED,
      userStates.CQG_ASSOCIATED,
      userStates.PII_SUBMITTED,
    ];

    if (pendingUserStates.includes(this.props.user.state)) {
      return (
        <LoadingComponent
          action={this.props.fetchUser}
          message={{
            text: 'Please wait while we finish setting up your account...',
          }}
        />
      );
    }

    return (
      <CreateFundingPassword
        handleSubmit={this.handleSubmit}
        message={this.state.message}
        loading={this.state.storeTxAuthKeyLoading}
        action={this.props.action}
        skipFunding={this.props.skipFunding}
        storeTxAuthKeyFailed={this.state.storeTxAuthKeyFailed}
        entropy={stringEntropy(this.props.values.password)}
        isOnboarding={isOnboarding(get(this.props, ['user', 'state']))}
        isRsbixUser={this.props.isRsbixUser}
      />
    );
  };
}

CreateFundingPasswordContainer.propTypes = {
  storeTxAuthKeyPromiseCreator: PropTypes.func.isRequired,
  authId: PropTypes.string.isRequired,
  values: PropTypes.object,
};

CreateFundingPasswordContainer.defaultProps = {
  values: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateFundingPasswordContainer);
