import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

//* Action Types */
export const SCALE_BASE_TO_MIN_UNIT_TRIGGER = 'SCALE_BASE_TO_MIN_UNIT_TRIGGER';
export const SCALE_BASE_TO_MIN_UNIT_REQUEST = 'SCALE_BASE_TO_MIN_UNIT_REQUEST';
export const SCALE_BASE_TO_MIN_UNIT_SUCCESS = 'SCALE_BASE_TO_MIN_UNIT_SUCCESS';
export const SCALE_BASE_TO_MIN_UNIT_FAILED = 'SCALE_BASE_TO_MIN_UNIT_FAILED';
export const STORE_TX_AUTH_KEY = 'STORE_TX_AUTH_KEY';
export const SKIP_FUNDING = 'SKIP_FUNDING';

/* Actions to store tx_auth_key */
/* Action generator to send public key for funding to RPC method */
export const storeTxAuthKey = createRoutine(STORE_TX_AUTH_KEY);
export const skipFunding = createRoutine(SKIP_FUNDING);

export const storeTxAuthKeyPromiseCreator = promisifyRoutine(storeTxAuthKey);

export const exportMemberFile = createRoutine('EXPORT_MEMBER_FILE');
export const memberDailyStatement = createRoutine('MEMBER_DAILY_STATEMENT');

//* Reducer */
export default function scaleBaseToMinUnitReducer(state = {}, action) {
  switch (action.type) {
    case SCALE_BASE_TO_MIN_UNIT_SUCCESS:
      return {
        ...state,
        amountMinUnit: action.amountMinUnit,
      };
    case SCALE_BASE_TO_MIN_UNIT_REQUEST:
      return {
        ...state,
        amountMinUnit: undefined, // Make sure any old/previous value is cleared out.
      };
    default:
      return state;
  }
}

/**
 * Action generator to scale an amount to it's min unit.
 * @param {String} data - the amount and assetType.
 */
export const scaleBaseToMinUnit = (data) => ({
  type: SCALE_BASE_TO_MIN_UNIT_TRIGGER,
  data,
});
