export const DOWNLOAD_NOT_AVAILABLE_ON_SAFARI_MOBILE =
  'Download is not available on Safari Mobile. Please go to erisx.com on your desktop to download your application packet.';
export const DOWNLOAD_NOT_AVAILABLE_ON_CHROME_IOS =
  'Download is not available on Chrome for iOS. Please go to erisx.com on your desktop to download your application packet.';
export const STATE_NOT_SUPPORTED =
  'Sorry! Your state is not supported at this time.';
export const REQUIRED_FIELD = 'This field is required.';
export const INVALID_ZIP_CODE = 'Invalid zip code.';
export const INVALID_PHONE_NUMBER = 'Invalid phone number.';

export const INVALID_DATE =
  'Invalid format. Please enter date in format mm/dd/yyyy.';
export const TOO_YOUNG = 'You must be 18 years of age or older to sign up.';
export const TOO_OLD = 'You must be 120 years of age or younger to sign up.';
export const INVALID_CIDR_IP = 'Invalid CIDR IP.';
export const INVALID_SSN = 'Invalid SSN.';
