import React, { useMemo, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Button, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  fundsDesignation,
  NON_SEG,
  renderDropdown,
  createLoadingSelector,
} from 'erisxkit/client';
import styled from 'styled-components';
import AddBankAccountPlaidContainer from '../../containers/LinkedAccounts/AddBankAccountPlaidContainer';
import { getLinkedAccountLabel } from '../../reducers/linkedAccountsReducer';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import { floatsOnly } from '../../common/forms/normalization';
import AssetTypeField from '../../common/components/AssetTypeField';
import {
  ACH_WITHDRAWAL_LIMIT,
  getAchWithdrawalLimit,
} from '../../reducers/fundTransfersReducer';
import AvailableFundsHeader from './AvailableFundsHeader';
import { WITHDRAWAL } from '../../constants/journalTypes';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import hasMoreThanNDigits from '../../utils/hasMoreThanNDigits';
import { DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES } from './constants';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import colors from '../../constants/colors';
import EnvPromise from '../../config/env';
import WithdrawDisclaimer from '../../common/components/WithdrawDisclaimer';
import { getActiveOrDefaultAccountFD } from '../../reducers/activeAccountSelectors';

const PlaidDisclaimer = styled.p`
  color: ${colors.RED_ERROR};
  font-size: 14px;
  margin: 10px 0px;
`;

export const bankAccountOptions = (linkedBankAccounts = []) => {
  return linkedBankAccounts.map((account) => ({
    key: account.hashId,
    value: {
      id: account.id,
      hashId: account.hashId,
      isPlaidAccount: get(account, 'isPlaidAccount', false),
    },
    text: getLinkedAccountLabel(account),
  }));
};

let BankTransfers = ({
  achWithdrawalLimit,
  accountCode,
  assetType,
  assetTypeWithFundsDesignation,
  change,
  currencyOptions,
  fetchLimit,
  firmCode,
  transfer,
  hasBalancesGreaterThanZero,
  initialize,
  confirmWithdrawal,
  linkedBankAccounts,
  accountsLoading,
  valid,
  linkedBankAccount,
  handleSubmit,
  handleChange,
  type,
  loadingLimits,
  activeAccountFD,
}) => {
  const [enablePlaidWireWithdrawal, setEnablePlaidWireWithdrawal] =
    useState('');

  // select only given option by default, if applicable
  // only run if we don't have anything selected for asset type, which we should by default.
  // we need this condition to prevent running on every re-render.
  useEffect(() => {
    if (
      currencyOptions.length === 1 &&
      isEmpty(assetTypeWithFundsDesignation)
    ) {
      initialize({ assetTypeWithFundsDesignation: currencyOptions[0].value });
      change('assetType', currencyOptions[0].value);
      change('fundsDesignation', activeAccountFD);
      fetchLimit(currencyOptions[0].value, activeAccountFD);
    }
  }, [
    activeAccountFD,
    assetTypeWithFundsDesignation,
    change,
    currencyOptions,
    fetchLimit,
    initialize,
  ]);

  useEffect(() => {
    EnvPromise.then((env) =>
      setEnablePlaidWireWithdrawal(env.enableWireWithdrawalsForPlaid),
    );
  }, []);

  const selectedAccountIsPlaid = useMemo(
    () => get(linkedBankAccount, 'isPlaidAccount', false),
    [linkedBankAccount],
  );

  const plaidWireWithdrawalSubmitDisabled = useMemo(
    () =>
      // If feature flag enable wire withdrawals is set then submit should not be disabled
      enablePlaidWireWithdrawal === 'true' ? false : selectedAccountIsPlaid,
    [enablePlaidWireWithdrawal, selectedAccountIsPlaid],
  );

  return (
    <React.Fragment>
      <Header as="h2">
        {'Wire Withdrawal from account '}{' '}
        <span className="bold">{`${
          firmCode ? `${firmCode}-` : ''
        }${accountCode}`}</span>
        <Header.Subheader>
          <AvailableFundsHeader
            action={WITHDRAWAL}
            amountLimit={achWithdrawalLimit}
            isFiat
            loadingLimits={loadingLimits}
          />
        </Header.Subheader>
      </Header>
      <Form onSubmit={handleSubmit(confirmWithdrawal)}>
        <Form.Field>
          <AssetTypeField
            change={change}
            postChange={fetchLimit}
            currencyOptions={currencyOptions}
            handleChange={handleChange}
            id="assetTypeWithFundsDesignation"
          />
        </Form.Field>
        <Form.Field>
          <Field
            label="Bank Account:"
            component={renderDropdown}
            loading={accountsLoading}
            placeholder={
              !isEmpty(linkedBankAccounts)
                ? 'Select bank account to withdraw to'
                : 'No bank accounts linked. Please add one below.'
            }
            fluid
            selection
            disabled={
              isEmpty(linkedBankAccounts) || !hasBalancesGreaterThanZero
            }
            options={bankAccountOptions(linkedBankAccounts)}
            name="linkedBankAccount"
            validate={rules.required}
          />
          <AccessControl
            allowedPermissions={[
              `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
            ]}
          >
            <AddBankAccountPlaidContainer link />
            {selectedAccountIsPlaid && (
              <PlaidDisclaimer>
                Please contact{' '}
                <a
                  href="mailto:digital.treasuryops@cboe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`Cboe Digital Treasury `}
                </a>{' '}
                to initiate a wire withdrawal using the selected bank account.
              </PlaidDisclaimer>
            )}
          </AccessControl>
        </Form.Field>
        <Form.Field>
          <Field
            component={renderField}
            label="Amount:"
            placeholder="Enter the amount you would like to withdraw"
            name="amount"
            parse={floatsOnly}
            required
            disabled={loadingLimits}
            onChange={(event, amount) => {
              if (
                hasMoreThanNDigits(amount, DEPOSIT_WITHDRAW_MAX_DECIMAL_PLACES)
              ) {
                event.preventDefault();
              }
            }}
            validate={[
              rules.required,
              rules.balanceExceeded,
              rules.twoDecimalPlaces,
              rules.positiveNumbers,
              rules.achWithdrawalLimitExceeded,
            ]}
          />
        </Form.Field>
        <WithdrawDisclaimer />
        <IraAccountDisclaimer />
        <div className="ach-confirm">
          <Button
            primary
            disabled={
              !valid || plaidWireWithdrawalSubmitDisabled || loadingLimits
            }
          >
            Submit Withdrawal
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

BankTransfers.propTypes = {
  accountsLoading: PropTypes.bool,
  achWithdrawalLimit: PropTypes.objectOf(PropTypes.any),
  assetType: PropTypes.string,
  assetTypeWithFundsDesignation: PropTypes.string,
  change: PropTypes.func.isRequired,
  confirmWithdrawal: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.object),
  fetchLimit: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasBalancesGreaterThanZero: PropTypes.bool,
  initialize: PropTypes.func.isRequired,
  linkedBankAccount: PropTypes.string,
  linkedBankAccounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  transfer: PropTypes.string,
  valid: PropTypes.bool.isRequired,
  loadingLimits: PropTypes.bool,
  activeAccountFD: PropTypes.string,
};

BankTransfers.defaultProps = {
  accountsLoading: false,
  achWithdrawalLimit: {},
  assetType: '',
  assetTypeWithFundsDesignation: '',
  currencyOptions: [],
  fetchLimit: () => {},
  hasBalancesGreaterThanZero: false,
  linkedBankAccount: '',
  linkedBankAccounts: [],
  transfer: '',
  loadingLimits: true,
  activeAccountFD: '',
};

BankTransfers = reduxForm({
  form: 'BankTransfers',
})(BankTransfers);

const selector = formValueSelector('BankTransfers');

export default connect((state) => ({
  linkedBankAccount: selector(state, 'linkedBankAccount'),
  achWithdrawalLimit: getAchWithdrawalLimit(state),
  amount: selector(state, 'amount'),
  assetType: selector(state, 'assetType'),
  assetTypeWithFundsDesignation: selector(
    state,
    'assetTypeWithFundsDesignation',
  ),
  loadingLimits: createLoadingSelector([ACH_WITHDRAWAL_LIMIT])(state),
  activeAccountFD: getActiveOrDefaultAccountFD(state),
}))(BankTransfers);
