import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import marginPositionsMetadata from '../../metadata/marginPositionsMetadata';
import {
  fetchPositionSummary,
  POSITION_SUMMARY,
  getMarginPositionsCount,
  getMarginPositions,
} from '../../reducers/marginPositionsReducer';
import EMPTable8 from '../EMPTable8/EMPTable8';
import Page from '../Page';
import { ExpandableColumn } from '../EMPTable8/types';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import { TableActionName } from '../EMPTable8/TableActions/types';
import AccountSelectorFCM from '../../components/AccountSelector';

const MarginPositions = () => {
  const firstRender = useRef(true);
  const dispatch = useDispatch();

  const loading = useSelector((state) =>
    createLoadingSelector([POSITION_SUMMARY])(state),
  );
  const count = useSelector(getMarginPositionsCount);
  const data = useSelector(getMarginPositions);
  const activeAccountId = useSelector(getActiveAccountId);

  const memoizedExpandableColumns = useMemo(
    () =>
      [
        {
          name: 'accountView',
          template: {},
        },
        {
          name: 'cgmView',
          template: {},
        },
      ] as ExpandableColumn[],
    [],
  );

  const memoizedSubRows = useCallback((row) => {
    return row.accountView || row.cgmView;
  }, []);

  useEffect(() => {
    if (!firstRender.current && activeAccountId) {
      memoizedFetch();
    } else {
      firstRender.current = false;
    }
  }, [activeAccountId, dispatch]);

  const memoizedFetch = useCallback(() => {
    dispatch(fetchPositionSummary({ accountId: activeAccountId }));
  }, [activeAccountId, dispatch]);

  const sortBy = useMemo(() => {
    return {
      sorting: [
        { id: 'productCode', desc: false },
        { id: 'maturityDate', desc: false },
        { id: 'cgm', desc: false },
      ],
    };
  }, []);

  return (
    <>
      <Page header="Margin Positions">
        <AccountSelectorFCM />
        <EMPTable8
          title="marginPositions"
          data={data}
          local
          columns={marginPositionsMetadata()}
          fetchData={memoizedFetch}
          count={count}
          getSubRows={memoizedSubRows}
          expandableColumns={memoizedExpandableColumns}
          loading={loading}
          loadingText="Fetching Margin Positions"
          noDataText="No Positions to show"
          minimumRows={5}
          showActions
          showActiveFilters
          actionsToHide={[TableActionName.Filters]}
          actionOptions={{
            export: {
              filename: 'margin-positions-export',
            },
          }}
          dataCy="margin-positions-table"
          initialState={sortBy}
        />
      </Page>
    </>
  );
};

export default MarginPositions;
