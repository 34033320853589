import axios from 'axios';

const dataURLToBlob = (canvasDataURL) => {
  const binary = atob(canvasDataURL.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpg' });
};

export const AUTH_TOKEN =
  'bGFycnkuYmVuZWRla0BlcmlzeC5jb206blI5V3BtSEYjTGZRJGU=';

const client = (() =>
  axios.create({
    baseURL: 'https://services.assureid.net',
  }))();

const request = function (options, store) {
  const onSuccess = function (response) {
    console.debug('Request Successful!', response);
    return response.data;
  };

  const onError = function (error) {
    return Promise.reject(error.response || error.message);
  };
  options.headers = {
    Authorization: `Basic ${AUTH_TOKEN}`,
  };

  return client(options).then(onSuccess).catch(onError);
};

export default async (response) =>
  request({
    url: '/AssureIDService/Document/Instance',
    method: 'POST',
    data: {
      AuthenticationSensitivity: 0,
      ClassificationMode: 0,
      Device: {
        HasContactlessChipReader: false,
        HasMagneticStripeReader: false,
        SerialNumber: 'JavaScriptWebSDK 11.0.0',
        Type: {
          Manufacturer: 'xxx',
          Model: 'xxx',
          SensorType: 3,
        },
      },
      ImageCroppingExpectedSize: 0,
      ImageCroppingMode: 0,
      ManualDocumentType: null,
      ProcessMode: 0,
      SubscriptionId: '674d9168-dd86-4843-973e-146d76b82d6b',
    },
  }).then((instanceId) =>
    request({
      url: `/AssureIDService/Document/${instanceId}/Image?side=0&light=0&metrics=true`,
      method: 'POST',
      data: dataURLToBlob(response.image.data),
    }).then(
      () =>
        request({
          url: `/AssureIDService/Document/${instanceId}/Classification`,
          method: 'GET',
        }).then((succ) => succ.ClassificationDetails),
      (fail) => fail,
    ),
  );
