import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Accordion } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import history from '../../constants/history';
import { accountIsAllowedFutures } from '../../utils/permissions';
import AccessControl from '../AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import EnvPromise from '../../config/env';
import OrderEntryWidgetContainer from '../../containers/OrderEntry/OrderEntryWidgetContainer';
import {
  getAccountDescription,
  hasSubAccountTypes,
} from '../../reducers/accountsReducer';
import colors from '../../constants/colors';
import useBlockTradesEnabled from '../../hooks/useIsFeatureFlagEnabled/useIsBlockTradesEnabled';
import { getUserCanManageBlockTradePermissions } from '../../reducers/userReducer';

const blockTradeManagementPages = ['spot-permission-management', 'spot-block-trade-registration'];

export const isOnHomePage = () => window.location.href.includes('home');
const isBlockTradeManagementPage = () =>
  blockTradeManagementPages.some((page) => window.location.href.includes(page));

const HomeLink = styled(Accordion.Title)`
  &&& {
    display: inline;
    border-bottom: none !important;
  }
`;
HomeLink.displayName = 'HomeLink';

const HomeIcon = styled(Icon)`
  color: ${(props) => (props.isSelected ? colors.GREEN : colors.GRAY_1)};
`;
HomeIcon.displayName = 'HomeIcon';

const Account = styled(Accordion.Title)`
  &&& {
    display: inline;
    border-bottom: none !important;
  }
`;
Account.displayName = 'Account';

const AccountType = styled.span`
  color: ${(props) => (props.isSelected ? colors.GRAY_2 : colors.GRAY_1)};
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
`;
AccountType.displayName = 'AccountType';

const BlockTradeManagementAccordionTitle = styled(Accordion.Title)`
  &&&& {
    padding: 0;
  }
`;

export class VerticalNav extends Component {
  state = {
    sidebarOpen: !this.props.viewportLessThanMedium,
    enableTradeWidget: 'false',
    enableFuturesUpgrade: 'false',
  };

  componentWillMount = () =>
    EnvPromise.then(({ enableTradeWidget, enableFuturesUpgrade }) =>
      this.setState({ enableTradeWidget, enableFuturesUpgrade }),
    );

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.viewportLessThanMedium !== this.props.viewportLessThanMedium
    ) {
      this.setState({
        sidebarOpen: !nextProps.viewportLessThanMedium,
      });
    }
  }

  onSetSidebarOpen = () => {
    this.setState({ sidebarOpen: true });
  };

  getActiveAccordion = (path, id) =>
    _.get(this.props, ['location', 'pathname'], '').includes(path) ||
    id === this.props.selectedAccountId;

  handleClick = (e, titleProps, accountId) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    if (accountId) {
      localStorage.setItem('erisx_account', accountId);
      this.props.selectAccount(accountId);
    }
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render = () => (
    <nav
      id="vertical-nav"
      className={`${this.state.sidebarOpen ? 'open' : 'closed'}${
        this.getActiveAccordion('/contact') ? ' secondary-active' : ''
      }`}
    >
      <section
        // TODO: add chevron to show that primary menu is scrollable
        className="primary-nav"
      >
        {this.state.sidebarOpen ? (
          <Fragment>
            <div className="topbar-link logo-container centered-content">
              <div
                className="logo dm-logo"
                alt="ErisX"
                onClick={() => {
                  this.props.logoClick(this.state.topNavSmall);
                }}
              />
            </div>
          </Fragment>
        ) : null}
        <NavLink
          activeClassName="active"
          className="sidebar-link"
          key="home"
          to="/home"
          onClick={() => {
            localStorage.removeItem('erisx_account');
          }}
          isActive={() => isOnHomePage()}
        >
          <HomeIcon name="home" isSelected={isOnHomePage()} />{' '}
          <HomeLink dataCy="home-main-dropdown-button">Home</HomeLink>
        </NavLink>

        <AccessControl
          customPermission={useBlockTradesEnabled}
          customPermissionSelector={getUserCanManageBlockTradePermissions}
        >
          <NavLink
            isActive={() => isBlockTradeManagementPage()}
            activeClassName="active"
            key={'block-trade-management'}
            to={'/spot-permission-management' || '/'}
            className="sidebar-link"
          >
            <Accordion>
              <BlockTradeManagementAccordionTitle>
                Block Trade Management
              </BlockTradeManagementAccordionTitle>
              <Accordion.Content active={isBlockTradeManagementPage()}>
                <div className="vert-flex">
                  <NavLink
                    activeClassName="active"
                    key={`spot-permission-management`}
                    to={`/spot-permission-management` || '/'}
                    className="submenu"
                  >
                    Permission Management
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    key={`spot-block-trade-registration`}
                    to={`/spot-block-trade-registration` || '/'}
                    className="submenu"
                  >
                    Block Trade Registration
                  </NavLink>
                </div>
              </Accordion.Content>
            </Accordion>
          </NavLink>
        </AccessControl>

        <AccessControl
          allowedPermissions={[
            `${grants.READ}:${subjects.TRADES}`,
            `${grants.READ}:${subjects.MOVEMENTS}`,
            `${grants.READ}:${subjects.POSITIONS}`,
          ]}
          op="some"
        >
          {this.props.accounts.map((option, index) => (
            <NavLink
              isActive={(match) =>
                !isOnHomePage() &&
                !isBlockTradeManagementPage() &&
                (match || option.accountId === this.props.selectedAccountId)
              }
              activeClassName="active"
              key={option.accountId}
              to={`/accounts/${option.accountId}` || '/'}
              className="sidebar-link"
            >
              <Accordion vertical>
                <Account
                  active={this.getActiveAccordion(
                    `/accounts/${option.accountId}` || '/',
                    option.accountId,
                  )}
                  index={index}
                  key={option.accountId}
                  onClick={(e, titleProps) =>
                    this.handleClick(e, titleProps, option.accountId)
                  }
                  className={`sidebar-account-${index}`}
                >
                  {option.label || option.accountId}
                  <AccountType
                    isSelected={
                      this.props.selectedAccountId === option.accountId
                    }
                  >
                    {getAccountDescription(option)}
                  </AccountType>
                </Account>
                <Accordion.Content
                  active={this.getActiveAccordion(
                    `/accounts/${option.accountId}` || '/',
                    option.accountId,
                  )}
                >
                  <div className="vert-flex">
                    <AccessControl
                      allowedPermissions={[`${grants.READ}:${subjects.TRADES}`]}
                    >
                      <NavLink
                        activeClassName="active"
                        key={`${option.accountId}-trades`}
                        to={`/accounts/${option.accountId}/trades` || '/'}
                        className="submenu"
                      >
                        Trades
                      </NavLink>
                    </AccessControl>
                    <AccessControl
                      allowedPermissions={[
                        `${grants.READ}:${subjects.MOVEMENTS}`,
                      ]}
                    >
                      <NavLink
                        activeClassName="active"
                        key={`${option.accountId}-movements`}
                        to={`/accounts/${option.accountId}/movements` || '/'}
                        className="submenu"
                      >
                        Asset Movements
                      </NavLink>
                    </AccessControl>
                    {accountIsAllowedFutures(option) && (
                      <AccessControl
                        allowedPermissions={[
                          `${grants.READ}:${subjects.POSITIONS}`,
                        ]}
                      >
                        <NavLink
                          activeClassName="active"
                          key={`${option.accountId}-positions`}
                          to={`/accounts/${option.accountId}/positions` || '/'}
                          className="submenu"
                        >
                          Positions
                        </NavLink>
                      </AccessControl>
                    )}
                    {accountIsAllowedFutures(option) && (
                      <AccessControl
                        allowedPermissions={[
                          `${grants.READ}:${subjects.TRADES}`,
                        ]}
                      >
                        <NavLink
                          activeClassName="active"
                          key={`${option.accountId}-closeouts`}
                          to={`/accounts/${option.accountId}/closeouts` || '/'}
                          className="submenu"
                        >
                          Closeouts
                        </NavLink>
                      </AccessControl>
                    )}
                  </div>
                </Accordion.Content>
              </Accordion>
            </NavLink>
          ))}
        </AccessControl>
        {this.state.enableTradeWidget === 'true' && (
          <AccessControl
            allowedPermissions={[`${grants.READ}:${subjects.TRADE_PROFILE}`]}
          >
            <OrderEntryWidgetContainer />
          </AccessControl>
        )}
      </section>

      <section
        className={`secondary-nav ${
          this.getActiveAccordion('/contact') ? 'active' : ''
        }`}
      >
        <NavLink
          activeClassName="active"
          to="/contact"
          className="sidebar-link"
        >
          <Accordion vertical>
            <Accordion.Title
              active={this.getActiveAccordion('/contact')}
              className="help"
              onClick={this.handleClick}
              index="help"
            >
              <Icon name="question circle" /> Help
            </Accordion.Title>
            <Accordion.Content active={this.getActiveAccordion('/contact')}>
              <div className="vert-flex">
                <NavLink
                  activeClassName="active"
                  to="/contact"
                  className="submenu"
                  key="contact"
                >
                  Contact
                </NavLink>
                <a
                  className="submenu"
                  onClick={() =>
                    this.props
                      .updateTutorialStatePromise({ state: 'pending_tutorial' })
                      .then(() => {
                        localStorage.removeItem('erisx_account');
                        history.push('/home');
                      })
                  }
                >
                  On-Screen Tour
                </a>
              </div>
            </Accordion.Content>
          </Accordion>
        </NavLink>
      </section>
    </nav>
  );
}

VerticalNav.propTypes = {
  tradingFrontendEndpoint: PropTypes.string,
};

VerticalNav.defaultProps = {
  tradingFrontendEndpoint: '',
};

export default withRouter(VerticalNav);
