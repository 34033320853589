import _ from 'lodash';
import { createRoutine } from 'redux-saga-routines';

export const COMPLIANCE_QUESTIONS = 'COMPLIANCE_QUESTIONS';
export const SUBMIT_COMPLIANCE_QUESTIONS = 'SUBMIT_COMPLIANCE_QUESTIONS';

export const SECURITY_QUESTIONS = 'SECURITY_QUESTIONS';
export const SUBMIT_SECURITY_QUESTIONS = 'SUBMIT_SECURITY_QUESTIONS';

export const FUTURES_COMPLIANCE_QUESTIONS = 'FUTURES_COMPLIANCE_QUESTIONS';
export const SUBMIT_FUTURES_COMPLIANCE_QUESTIONS =
  'SUBMIT_FUTURES_COMPLIANCE_QUESTIONS';

export const fetchComplianceQuestions = createRoutine(COMPLIANCE_QUESTIONS);
export const submitComplianceQuestions = createRoutine(
  SUBMIT_COMPLIANCE_QUESTIONS,
);
export const fetchSecurityQuestions = createRoutine(SECURITY_QUESTIONS);
export const submitSecurityQuestions = createRoutine(SUBMIT_SECURITY_QUESTIONS);
export const fetchFuturesComplianceQuestions = createRoutine(
  FUTURES_COMPLIANCE_QUESTIONS,
);
export const submitFuturesComplianceQuestions = createRoutine(
  SUBMIT_FUTURES_COMPLIANCE_QUESTIONS,
);

//* Reducer */
export function complianceQuestionsReducer(state = [], action) {
  switch (action.type) {
    case fetchComplianceQuestions.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function securityQuestionsReducer(state = [], action) {
  switch (action.type) {
    case fetchSecurityQuestions.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function futuresComplianceQuestionsReducer(state = [], action) {
  switch (action.type) {
    case fetchFuturesComplianceQuestions.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getComplianceQuestions = (state) =>
  _.get(state, 'complianceQuestions', []);
export const getSecurityQuestions = (state) =>
  _.get(state, 'securityQuestions', []);
export const getFuturesComplianceQuestions = (state) =>
  _.get(state, 'futuresComplianceQuestions', []);
