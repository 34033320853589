import React, { useEffect, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  fundsDesignation as fundsDesignationConstants,
  renderDropdown,
  normalization,
  NON_SEG,
} from 'erisxkit/client';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import * as rules from '../../common/forms/rules';
import { renderField } from '../../common/forms/components';
import AssetTypeField from '../../common/components/AssetTypeField';
import WeekDays from './WeekDays';
import {
  daysOfMonth,
  mapDepositFrequencyToArrayValueText,
} from '../../utils/methods';
import { depositFrequency } from '../../constants/depositFrequency';
import { getAchDepositLimit } from '../../reducers/fundTransfersReducer';
import { getActiveOrDefaultAccountFD } from '../../reducers/activeAccountSelectors';

const EditAchDeposit = ({
  change,
  currencyOptions,
  fetchLimit,
  frequency,
  weekDay,
  initialize,
  editedData,
  achDepositAmountLimit,
  activeAccountFD,
}) => {
  const limitRule = useMemo(
    () =>
      achDepositAmountLimit && achDepositAmountLimit.available
        ? rules.maxValue(achDepositAmountLimit.available)
        : undefined,
    [achDepositAmountLimit],
  );

  useEffect(() => {
    // select only edited option, if applicable
    if (currencyOptions.length === 1) {
      // set default values
      const assetType = currencyOptions[0].value;

      initialize({ assetTypeWithFundsDesignation: assetType });
      change('assetType', assetType);
      change('fundsDesignation', activeAccountFD);
      fetchLimit(editedData.accountId, assetType, activeAccountFD);
    }

    change('amount', editedData.amount);
    change('linkedMemberAssetAccountId', editedData.linkedMemberAssetAccountId);
    change('frequency', editedData.schedule.toLowerCase());
    change('accountId', editedData.accountId);

    if (editedData.schedule.toLowerCase() === depositFrequency.weekly.value) {
      change('weekDay', Number(editedData.day));
    }
    if (editedData.schedule.toLowerCase() === depositFrequency.monthly.value) {
      change('day', Number(editedData.day));
    }
  }, []);

  return (
    <div>
      <Form>
        <div className="max-width-flex">
          <Form.Field className="firstDiv">
            <AssetTypeField
              change={change}
              currencyOptions={currencyOptions}
              postChange={fetchLimit}
            />
          </Form.Field>
          <Form.Field className="lastDiv">
            {limitRule && (
              <Field
                component={renderField}
                label="Amount:"
                name="amount"
                placeholder="Enter the amount you would like to deposit"
                parse={normalization.floatsOnly}
                required
                style={{ height: '43px' }}
                validate={[rules.required, limitRule, rules.positiveNumbers]}
              />
            )}
          </Form.Field>
        </div>
        <div className="info-bottom">
          <div className="max-width-flex">
            <Form.Field className="firstDiv">
              <Field
                component={renderDropdown}
                label="Frequency:"
                fluid
                required
                placeholder=""
                name="frequency"
                options={mapDepositFrequencyToArrayValueText(
                  depositFrequency,
                ).slice(1)}
                validate={rules.required}
              />
              <label className="info-bottom-label">
                {(frequency === depositFrequency.bimonthly.value ||
                  frequency === depositFrequency.monthly.value) && (
                  <span>
                    Deposits scheduled on weekends or public holidays will be
                    proccessed the next bussiness day
                  </span>
                )}
                {frequency === depositFrequency.weekly.value && (
                  <span>
                    Deposits scheduled on a public holiday will be proccessed
                    the next bussiness day
                  </span>
                )}
              </label>
            </Form.Field>
            <Form.Field className="lastDiv">
              {(frequency && frequency === depositFrequency.weekly.value && (
                <Form.Field>
                  <Field
                    component={WeekDays}
                    name="weekDay"
                    weekDay={weekDay}
                    label="Select day of the week:"
                    validate={rules.required}
                    required={true}
                  />
                </Form.Field>
              )) ||
                (frequency === depositFrequency.monthly.value && (
                  <Field
                    component={renderDropdown}
                    label="Day:"
                    name="day"
                    placeholder=""
                    required
                    options={daysOfMonth()}
                    style={{ height: '38px' }}
                  />
                ))}
            </Form.Field>
          </div>
        </div>
      </Form>
    </div>
  );
};

EditAchDeposit.propTypes = {
  achDepositAmountLimit: PropTypes.objectOf(PropTypes.any),
  change: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  fetchLimit: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  editedData: PropTypes.shape({
    accountId: PropTypes.string,
    amount: PropTypes.number,
    schedule: PropTypes.string,
    linkedMemberAssetAccountId: PropTypes.string,
    day: PropTypes.number,
  }),
  activeAccountFD: PropTypes.string
};

const selector = formValueSelector('editAchDeposit');

const EditAchDepositForm = reduxForm({
  form: 'editAchDeposit',
})(EditAchDeposit);

export default connect((state) => ({
  linkedMemberAssetAccountId: selector(state, 'linkedMemberAssetAccountId'),
  assetType: selector(state, 'assetType'),
  fundsDesignation: selector(state, 'fundsDesignation'),
  amount: selector(state, 'amount'),
  frequency: selector(state, 'frequency'),
  weekDay: selector(state, 'weekDay'),
  achDepositAmountLimit: getAchDepositLimit(state),
  activeAccountFD: getActiveOrDefaultAccountFD(state),
}))(EditAchDepositForm);
