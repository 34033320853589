import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import DisclosuresAndDisclaimers from '../../components/onBoarding/GenericTerms';
import {
  acceptDisclosuresAndDisclaimersPromiseCreator,
  acceptAgreement,
  getLoggedInUser,
  acceptTermsAndConditions,
} from '../../reducers/userReducer';
import EnvPromise from '../../config/env';

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      acceptAgreement,
      acceptTermsAndConditions,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      acceptDisclosuresAndDisclaimersPromiseCreator,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
});

class DisclosuresAndDisclaimersContainer extends PureComponent {
  state = {};

  componentWillMount = () => {
    EnvPromise.then(
      ({
        disclosuresAndDisclaimersPdfEndpoint,
        agreementPdfEndpoint,
        termsOfUsePdfEndpoint,
        privacyPolicyEndpoint,
      }) =>
        this.setState({
          disclosuresAndDisclaimersPdfEndpoint,
          agreementPdfEndpoint,
          termsOfUsePdfEndpoint,
          privacyPolicyEndpoint,
        }),
    );
  };

  confirm = () => {
    this.props.acceptTermsAndConditions();
    this.props
      .acceptDisclosuresAndDisclaimersPromiseCreator()
      .then((success) => {
        this.props.acceptAgreement();
        this.props.nextStep();
      });
  };

  render = () => (
    <section className="onboarding-container">
      <DisclosuresAndDisclaimers
        files={[
          ...(!this.props.user.termsAcceptedTime
            ? [
                {
                  file: this.state.termsOfUsePdfEndpoint,
                  fileName: 'Cboe Digital Terms and Conditions',
                  name: 'termsOfUse',
                },
                {
                  file: this.state.privacyPolicyEndpoint,
                  fileName: 'Privacy Policy',
                  name: 'privacyPolicy',
                },
              ]
            : []),
          {
            file: this.state.disclosuresAndDisclaimersPdfEndpoint,
            fileName: 'Digital Currency Risk Disclosures',
            name: 'disclosures',
          },
          {
            file: this.state.agreementPdfEndpoint,
            fileName: 'Cboe Digital Member Master Agreement',
            name: 'memberAgreements',
          },
        ]}
        header="Disclosures and Disclaimers"
        accept={this.confirm}
        dataCy={'ob-disclosures-disclaimers'}
      />
    </section>
  );
}

DisclosuresAndDisclaimersContainer.propTypes = {
  acceptDisclosuresAndDisclaimers: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisclosuresAndDisclaimersContainer);
