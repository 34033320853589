import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Segment, Header, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { checkPermissions } from '../common/AccessControl';
import { grants } from '../constants/userPermissions';
import { UISegment } from '../styles/styled';
import useIsFCM from '../hooks/useIsFCM';
import {
  ACCOUNT_ID_TYPE_FIELD_NAME,
  DATE_TYPE_FIELD_NAME,
  REPORT_TYPE_FIELD_NAME,
} from '../containers/MemberFilesContainer';

const MEMBER_FILES_REPORT_NAMES = {
  BALANCES: 'balances',
  BALANCE_SETTLEMENTS: 'balances_settlement',
};

const SINGLE_DATE_REPORTS = [
  MEMBER_FILES_REPORT_NAMES.BALANCES,
  MEMBER_FILES_REPORT_NAMES.BALANCE_SETTLEMENTS,
];

const spotReportTypes = [
  {
    text: 'Balances',
    value: MEMBER_FILES_REPORT_NAMES.BALANCES,
    permission: 'balances',
  },
  {
    text: 'Balance Settlements',
    value: MEMBER_FILES_REPORT_NAMES.BALANCE_SETTLEMENTS,
    permission: 'balances',
  },
  {
    text: 'Movements',
    value: 'movements',
    permission: 'movements',
  },
  {
    text: 'Spot Trades',
    value: 'trades',
    permission: 'trades',
  },
];

const futuresReportTypes = [
  {
    text: 'Futures Trades',
    value: 'futures_trades',
    permission: 'trades',
  },
  {
    text: 'Closeouts',
    value: 'closeouts',
    permission: 'trades',
  },
  {
    text: 'Open Positions',
    value: 'futures_positions',
    permission: 'futuresPositions',
  },
];

const FCM_REPORTS = [
  {
    text: 'Asset Movements',
    value: 'asset_movements',
    permission: 'movements',
  },
  {
    text: 'Account Summary',
    value: 'account_summary',
    permission: 'balances',
  },
  {
    text: 'Contract Data',
    value: 'contract_data',
    permission: null,
  },
  {
    text: 'Position Summary',
    value: 'position_summary',
    permission: 'futuresPositions',
  },
  {
    text: 'Settlements',
    value: 'settlements',
    permission: null,
  },
  {
    text: 'Standing Requirements',
    value: 'standing_requirements',
    permission: 'balances',
  },
  {
    text: 'Trade Register',
    value: 'trade_register',
    permission: 'trades',
  },
  {
    text: 'FCM Generate Statements',
    value: 'fcm_generate_statements',
    permission: null
  }
];

const getFcmOptions = (userPermissions) =>
  FCM_REPORTS.filter(
    (r) =>
      r.permission === null ||
      checkPermissions(userPermissions, [`${grants.READ}:${r.permission}`]),
  );

const reportTypes = ({ userPermissions, enabledForFutures }) => {
  // you need to return an empty array if false so the spread operator works
  const futureReports = enabledForFutures ? futuresReportTypes : [];
  const allReports = [...spotReportTypes, ...futureReports];
  return allReports.filter((r) =>
    checkPermissions(userPermissions, [`${grants.READ}:${r.permission}`]),
  );
};

const StyledSegment = styled(UISegment)`
  flex-grow: 1;
`;

const MemberFiles = ({
  accountsOptions,
  date,
  endDate,
  loading,
  onChange,
  handleDateChange,
  handleExport,
  enabledForFutures,
  valid,
  userPermissions,
  report,
  accountId,
}) => {
  const isFCM = useIsFCM();
  const options = isFCM
    ? getFcmOptions(userPermissions)
    : reportTypes({ enabledForFutures, userPermissions });

  const useSingleDate = SINGLE_DATE_REPORTS.includes(report) || isFCM;
  const isBalanceSettlements =
    report === MEMBER_FILES_REPORT_NAMES.BALANCE_SETTLEMENTS;

  return (
    <StyledSegment>
      <Header
        content="Reports"
        subheader="Generate and download reports for any or all accounts."
      />
      <Form>
        <Form.Group widths="2">
          <Form.Dropdown
            label="Report Type"
            placeholder="Select a report type"
            name={REPORT_TYPE_FIELD_NAME}
            selection
            search
            closeOnChange
            onChange={onChange}
            options={options}
          />
          {!isBalanceSettlements && (
            <Form.Dropdown
              clearable
              closeOnChange
              label="Account"
              name={ACCOUNT_ID_TYPE_FIELD_NAME}
              onChange={onChange}
              options={[{ key: 'all', text: 'All Accounts' }].concat(
                accountsOptions,
              )}
              placeholder="All Accounts"
              selection
              search
              value={accountId}
            />
          )}
        </Form.Group>
        {useSingleDate ? (
          <Form.Field>
            <label htmlFor="date">Date</label>
            <div className="ui input fluid">
              <Flatpickr
                options={{
                  altInput: true,
                  altFormat: 'm/d/Y',
                  // Add one day to current date. Used instead of FlatPickr's own
                  //  fp_incr prototype, which modifies the builtin Date type (bad practice).
                  maxDate: moment().add(1, 'days').toDate(),
                  mode: 'single',
                }}
                placeholder="Date"
                name={DATE_TYPE_FIELD_NAME}
                id={DATE_TYPE_FIELD_NAME}
                onChange={handleDateChange}
                value={date}
              />
            </div>
          </Form.Field>
        ) : (
          <Form.Field>
            <label htmlFor="date">Date Range</label>
            <div className="ui input fluid">
              <Flatpickr
                options={{
                  altInput: true,
                  altFormat:
                    date === endDate || (date && !endDate)
                      ? 'm/d/Y to m/d/Y'
                      : 'm/d/Y',
                  // Add one day to current date. Used instead of FlatPickr's own
                  //  fp_incr prototype, which modifies the builtin Date type (bad practice).
                  maxDate: moment().add(1, 'days').toDate(),
                  mode: 'range',
                }}
                placeholder="From Date &rarr; To Date"
                name={DATE_TYPE_FIELD_NAME}
                id={DATE_TYPE_FIELD_NAME}
                onChange={handleDateChange}
              />
            </div>
          </Form.Field>
        )}
        <Form.Button
          secondary
          content="Export File"
          name="csvButton"
          onClick={handleExport}
          disabled={!valid || loading}
          loading={loading}
        />
      </Form>
    </StyledSegment>
  );
};

MemberFiles.propTypes = {
  accountsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  date: PropTypes.string,
  endDate: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  enabledForFutures: PropTypes.bool,
  report: PropTypes.string,
  accountId: PropTypes.string,
};

MemberFiles.defaultProps = {
  accounts: [],
  date: '',
  endDate: '',
  enabledForFutures: false,
  loading: false,
  report: '',
  accountId: '',
};

export default MemberFiles;
