import React from 'react';
import { get } from 'lodash';
import { getTimeColumn } from '../common/metadata/commonFields';

export const closeoutJournalsMetadata = [
  {
    Header: 'Closeout ID',
    accessor: 'closeoutId',
    id: 'closeoutId',
  },
  {
    ...getTimeColumn('time'),
    Header: 'Date/Time',
  },
  {
    Header: 'Product',
    accessor: 'productSymbol',
    id: 'product',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Contract',
    accessor: 'contractSymbol',
    id: 'contractSymbol',
    headerClassName: 'pull-left',
  },
  {
    Header: 'QTY',
    accessor: 'qty',
    id: 'qty',
  },
  {
    Header: 'Realized',
    id: 'realized',
    accessor: 'realized',
  },
];

export const subComponentMetadata = [
  {
    Header: 'Position ID',
    accessor: 'positionId',
    id: 'positionId',
  },
  {
    Header: 'Side',
    accessor: 'side',
    id: 'side',
  },
  {
    Header: 'Trade Type',
    accessor: 'trdType',
    id: 'trdType',
  },
  {
    Header: 'Quantity',
    accessor: 'qty',
    id: 'qty',
  },
  {
    Header: 'Price',
    accessor: (subRow) => <span className="mono">{get(subRow, 'px', '')}</span>,
    id: 'px',
  },
  {
    Header: 'Notional',
    accessor: (subRow) => (
      <span className="mono">{get(subRow, 'notional', '')}</span>
    ),
    id: 'notional',
  },
  {
    Header: 'Trade Report ID',
    accessor: 'tradeReportId',
    id: 'tradeReportId',
  },
  {
    Header: 'Trade ID',
    accessor: 'positionId',
    id: 'positionId',
  },
  {
    Header: 'ClientOrderId',
    accessor: 'clOrdId',
    id: 'clOrdId',
  },
  {
    Header: 'CustAcctRef',
    accessor: 'customerAccountRef',
    id: 'customerAccountRef',
  },
];
