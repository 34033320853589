import { fetchPositionSummary } from '../reducers/marginPositionsReducer';
import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchPositionSummary.TRIGGER,
    createSaga(fetchPositionSummary, fetchPositionSummary._PREFIX),
  );
}
