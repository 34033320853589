import axios from 'axios';
import applyConverters from 'axios-case-converter';
import history from './constants/history';
import TIMEOUT from './constants/timeout';
import validateIP from './utils/validateIP';

export const CREATE_CRYPTO_WITHDRAWAL_REQUEST_API_ENDPOINT =
  '/api/create_crypto_withdrawal_request';
export const CREATE_FIAT_WITHDRAWAL_REQUEST_API_ENDPOINT =
  '/api/create_fiat_withdrawal_request';
export const CREATE_FIAT_DEPOSIT_REQUEST_API_ENDPOINT =
  '/api/create_fiat_deposit_request';
export const BALANCES_API_ENDPOINT = '/api/balances';
export const ACCOUNTS_API_ENDPOINT = '/api/accounts';
export const ACCOUNT_HISTORY_API_ENDPOINT = '/api/history';
export const ASSET_TYPES_API_ENDPOINT = '/api/asset_types';
export const LAST_PRICE_API_ENDPOINT = '/api/last_price';
export const REFERENCE_CODE_API_ENDPOINT = '/api/reference_code';
export const ACH_WITHDRAWAL_LIMIT_API_ENDPOINT = '/api/withdrawal_limit';
export const ACH_DEPOSIT_LIMIT_API_ENDPOINT = '/api/deposit_limit';
export const ACH_IMMEDIATE_DEPOSIT_LIMIT_API_ENDPOINT = '/api/ach_immediate';
export const BANK_DETAILS_API_ENDPOINT = '/api/bank_details';
export const FEE_PARAMS_API_ENDPOINT = '/api/fee_params';
export const SCALE_BASE_TO_MIN_UNIT_API_ENDPOINT =
  '/api/scale_base_to_min_unit';
export const STORE_TX_AUTH_KEY_API_ENDPOINT = '/api/store_tx_auth_key';
export const SKIP_FUNDING_API_ENDPOINT = '/api/skip_funding';
export const MEMBERS_API_ENDPOINT = '/api/members';
export const POSITIONS_API_ENDPOINT = '/api/positions';
export const CLOSEOUT_JOURNALS_API_ENDPOINT = '/api/closeout_journals';
export const IRA_BANK_MSG_API_ENDPOINT = '/api/ira_bank_msg';
export const CREATE_LINKED_MEMBER_BANK_ACCOUNT_IRA_MEMBER_API_ENDPOINT =
  '/api/create_linked_member_bank_account_ira_member';

export const USER_API_ENDPOINT = '/api/user';
export const AUTH_USER_API_ENDPOINT = '/api/auth_user';
export const UPDATE_AUTH_USER_API_ENDPOINT = '/api/update_auth_user';
export const APPLICATION_PACKET_API_ENDPOINT = '/api/application_packet';

export const CREATE_MF_TICKET_API_ENDPOINT = '/api/create_mf_ticket';

export const IS_ELIGIBLE_LOCATION_API_ENDPOINT = '/api/is_eligible_location';
export const UPDATE_PII_API_ENDPOINT = '/api/update_pii';
export const SUBMIT_PII_API_ENDPOINT = '/api/submit_pii';
export const PII_API_ENDPOINT = '/api/pii';
export const KBA_QUESTION_API_ENDPOINT = '/api/kba_question';
export const UPDATE_TUTORIAL_STATE_API_ENDPOINT = '/api/update_tutorial_state';

export const COMPLIANCE_QUESTIONS_API_ENDPOINT = '/api/compliance_questions';
export const SUBMIT_COMPLIANCE_QUESTIONS_API_ENDPOINT =
  '/api/submit_compliance_questions';

export const SECURITY_QUESTIONS_API_ENDPOINT = '/api/security_questions';
export const SUBMIT_SECURITY_QUESTIONS_API_ENDPOINT =
  '/api/submit_security_questions';

export const UPDATE_IMAGE_API_ENDPOINT = '/api/update_image';
export const SUBMIT_IMAGES_API_ENDPOINT = '/api/submit_images';
export const IMAGES_API_ENDPOINT = '/api/images';
export const ID_TYPES_API_ENDPOINT = '/api/id_types';
export const ACCEPT_TERMS_AND_CONDITIONS_API_ENDPOINT =
  '/api/accept_terms_and_conditions';
export const ACCEPT_DISCLOSURES_AND_DISCLAIMERS_API_ENDPOINT =
  '/api/accept_disclosures_and_disclaimers';
export const ACCEPT_AGREEMENT_API_ENDPOINT = '/api/accept_agreement';

export const PLAID_VERIFY_ACCOUNT_API_ENDPOINT =
  '/api/plaid/create_linked_member_bank_account_plaid';

export const LINKED_MEMBER_ASSET_ACCOUNTS_API_ENDPOINT =
  '/api/linked_member_asset_accounts';
export const CREATE_LINKED_BANK_ACCOUNT_API_ENDPOINT =
  '/api/create_linked_bank_account';
export const DELETE_LINKED_BANK_ACCOUNT_API_ENDPOINT =
  '/api/delete_linked_bank_account';

export const CREATE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT =
  '/api/create_linked_member_crypto_address';
export const DELETE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT =
  '/api/delete_linked_crypto_address';
export const LOOKUP_HASH_ID_API_ENDPOINT = '/api/lookup_hash_id';

export const EXPORT_MEMBER_FILE_API_ENDPOINT = '/api/export_member_file';
export const MEMBER_DAILY_STATEMENT_API_ENDPOINT =
  '/api/member_daily_statement';

export const JOURNAL_HISTORY_API_ENDPOINT = '/api/journal_history';
export const REQUEST_HISTORY_API_ENDPOINT = '/api/request_history';
export const RECENT_TRANSACTIONS_API_ENDPOINT = '/api/recent_transactions';
export const BANK_TRANSACTION_DETAILS_API_ENDPOINT =
  '/api/bank_transaction_details';

export const BALANCE_DETAILS_API_ENDPOINT = '/api/balance_details';
export const ACCOUNTS_BALANCES_API_ENDPOINT = '/api/accounts_balances';

export const TRADE_JOURNALS_API_ENDPOINT = '/api/trade_journals';

export const COLLATERAL_CONTRACTS_API_ENDPOINT = '/api/collateral_contracts';
export const CONTRACTS_API_ENDPOINT = '/api/contracts';
export const CREATE_COLLATERAL_DEPOSIT_REQUEST_API_ENDPOINT =
  '/api/create_collateral_deposit_request';

export const CREATE_API_CREDENTIALS_API_ENDPOINT =
  '/api/create_api_credentials';
export const CREATE_THIRD_PARTY_API_CREDENTIALS_API_ENDPOINT =
  '/api/create_third_party_api_credentials';
export const API_CREDENTIALS_PERMISSIONS_API_ENDPOINT =
  '/api/api_credentials_permissions';
export const API_CREDENTIALS_API_ENDPOINT = '/api/api_credentials';
export const DELETE_API_CREDENTIALS_API_ENDPOINT =
  '/api/delete_api_credentials';

export const ACCOUNT_SCHEDULES_API_ENDPOINT = '/api/account_schedules';
export const CREATE_FIAT_DEPOSIT_SCHEDULE_API_ENDPOINT =
  '/api/create_fiat_deposit_schedule';
export const DELETE_FIAT_DEPOSIT_SCHEDULE_API_ENDPOINT =
  '/api/delete_fiat_deposit_schedule';

export const NEW_ORDER_SINGLE = '/api/new_order_single';
export const TOP_OF_BOOK = '/api/top_of_book';
export const GET_ORDER = '/api/get_order';
export const PARTY_LIST = '/api/party_list';
export const SECURITY_LIST = '/api/security_list';
export const MARGIN_PRODUCTS_API_ENDPOINT = '/api/margin_products';
export const FEES_API_ENDPOINT = '/api/fees';
export const GENERATE_REFERRAL_CODE_API_ENDPOINT =
  '/api/generate_referral_code';
export const SET_REFERRED_BY_CODE_API_ENDPOINT = '/api/set_referred_by_code';
export const SEND_REFERRAL_CODE_API_ENDPOINT = '/api/send_referral_code';
export const PROMOTION_REFERRALS_API_ENDPOINT = '/api/promotion_referrals';
export const ADD_CLEARING_ACCOUNT_TO_AUTHORIZED_TRADER_API_ENDPOINT =
  '/api/add_clearing_account_to_authorized_trader';
export const REMOVE_CLEARING_ACCOUNT_FROM_AUTHORIZED_TRADER_API_ENDPOINT =
  '/api/remove_clearing_account_from_authorized_trader';
export const AUTHORIZED_TRADERS_API_ENDPOINT = '/api/authorized_traders';
export const INVESTMENT_MANAGER_MEMBER_API_ENDPOINT =
  '/api/investment_manager_member';
export const HIDE_FUTURES_UPGRADE_MODAL_API_ENDPOINT =
  '/api/hide_futures_upgrade_modal';
export const FUTURES_COMPLIANCE_QUESTIONS_API_ENDPOINT =
  '/api/futures_compliance_questions';
export const SUBMIT_FUTURES_COMPLIANCE_QUESTIONS_API_ENDPOINT =
  '/api/submit_futures_compliance_questions';
export const START_FUTURES_UPGRADE_API_ENDPOINT = '/api/start_futures_upgrade';
export const ACCEPT_FUTURES_AGREEMENTS_API_ENDPOINT =
  '/api/accept_futures_agreements';
export const CREATE_MEMBER_TRANSFER_REQUEST_API_ENDPOINT =
  '/api/create_member_transfer_request';
export const AUTHORIZED_IPS_API_ENDPOINT = '/api/authorized_ips';
export const IP_AUTHORIZATION_HISTORY_API_ENDPOINT =
  '/api/ip_authorization_history';
export const REMOVE_AUTHORIZED_IP_API_ENDPOINT = '/api/remove_authorized_ip';
export const ADD_AUTHORIZED_IP_API_ENDPOINT = '/api/add_authorized_ip';
export const MEMBER_USER_TYPES_API_ENDPOINT = '/api/member_user_types';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_LIST_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference';
export const CREATE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference';
export const UPDATE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const DELETE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const LINK_CAR_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const UNLINK_CAR_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference/unlink_participant';

export const GET_CGM_LIST_API_ENDPOINT = '/api/v2/:account_label/cgm';
export const CREATE_CGM_API_ENDPOINT = '/api/v2/:account_label/cgm';
export const UPDATE_CGM_API_ENDPOINT =
  '/api/v2/accounts/:account_id/cgm/:cgm_id';
export const DELETE_CGM_API_ENDPOINT = '/api/v2/:account_label/cgm/:cgm_number';
export const LINK_CGM_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/cgm/:cgm_number';
export const UNLINK_CGM_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/cgm/:cgm_number/unlink_participant';

export const TRADES_API_ENDPOINT = '/api/trades_fcm';
export const POSITION_SUMMARY_API_ENDPOINT = '/api/position_summary';
export const AVAILABLE_TAX_ESTATEMENTS_API_ENDPOINT =
  '/api/v2/available_tax_statements/:account_id';
export const SET_ACCOUNT_ESTATEMENT_STATUS_API_ENDPOINT =
  '/api/v2/update_tax_estatements';
export const GET_ACCOUNT_ESTATEMENT_STATUS_API_ENDPOINT =
  '/api/v2/:account_id/estatements';
export const GET_ALL_ACCOUNTS_ESTATEMENT_STATUS_API_ENDPOINT =
  '/api/v2/estatements';
export const DOWNLOAD_TAX_ESTATEMENTS_API_ENDPOINT =
  '/api/v2/download_tax_statement/:account_id/:year/:form_name';
export const MARGIN_DELIVERY_API_ENDPOINT = '/api/margin_delivery';

export const POSITION_ADJUSTMENTS_API_ENDPOINT =
  '/api/v2/get_positions_for_manual_adjustment';
export const SUBMIT_POSITION_ADJUSTMENTS_API_ENDPOINT =
  '/api/v2/submit_position_adjustments';
export const POSITION_ADJUSTMENTS_METADATA_API_ENDPOINT =
  '/api/v2/get_positions_for_manual_adjustment_metadata';
export const FCM_BALANCES_SNAPSHOTS_API_ENDPOINT =
  '/api/fcm_balances_snapshots';
export const FCM_BALANCE_CYCLE_API_ENDPOINT = '/api/fcm_balance_cycle';
export const FCM_BALANCE_CYCLES_API_ENDPOINT = '/api/fcm_balance_cycles';
export const FCM_ACCOUNT_MOVEMENTS_API_ENDPOINT = '/api/fcm_account_movements';

export const FCM_CLEARING_API_ENDPOINT = '/api/v2/:firm_code/fcm_clearing';
export const FCM_CLEARING_ALL_FCM_MEMBERS_API_ENDPOINT = '/api/v2/fcm/all';
export const CREATE_FCM_CLEARING_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing';
export const REJECT_FCM_CLEARING_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing/reject/:request_id';
export const APPROVE_CLEARING_REQUEST_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing/approve/:request_id';
export const MEMBERS_V2_API_ENDPOINT = '/api/v2/members';
export const GET_TRADING_PARTICIPANTS_API_ENDPOINT = 'api/v2/participants';
export const DELETE_TRADING_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/delete_trading_participant_links';

export const GET_TRADING_PARTICIPANT_RISK_LIMITS_API_ENDPOINT =
  '/api/get_trading_participant_risk_limits';
export const UPDATE_TRADING_PARTICIPANT_RISK_LIMITS_API_ENDPOINT =
  '/api/update_trading_participant_risk_limits';
export const CREATE_TRADING_PARTICIPANT_RISK_LIMITS_API_ENDPOINT =
  '/api/create_trading_participant_risk_limits';

export const ENABLE_BLOCK_TRADES_API_ENDPOINT =
  '/api/v2/enable_block_trades/participant';
export const DISABLE_BLOCK_TRADES_API_ENDPOINT =
  '/api/v2/disable_block_trades/participant';
export const GET_AUTHORIZED_BLOCK_TRADERS_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_traders';
export const ADD_AUTHORIZED_BLOCK_TRADER_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_trader/:authorized_trader_email';
export const REMOVE_AUTHORIZED_BLOCK_TRADER_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_trader/:removed_trader_email';
export const SUBMIT_TWO_SIDED_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/two_sided_block_trade_internal';
export const SUBMIT_SINGLE_SIDED_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/single_sided_block_trade_internal';

export const GET_BLOCK_TRADE_REQUESTS_API_ENDPOINT =
  '/api/v1/block_trade_requests';
export const GET_BLOCK_TRADE_REQUESTS_V2_API_ENDPOINT =
  '/api/v2/block_trade_requests';

export const APPROVE_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/approve_block_trade/:request_id';
export const REJECT_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/reject_block_trade/:request_id';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: TIMEOUT,
};

// create instance with default options.
// applyConverters will:
// Converts outgoing data params object keys into snake_case
// Converts incoming data object keys into camelCase
// Converts outgoing headers object keys into Header-Case
// Converts incoming headers object keys into camelCase
const instance = applyConverters(axios.create(defaultOptions));
const webInstance = axios.create(defaultOptions);
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

webInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // cannot use auth.logout() here as api is a dependency of Auth itself.
      // directly calling the implementation of auth.logout();
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      history.replace('/');
    }
    return Promise.reject(error);
  },
);

const api = {
  post: (url, payload) =>
    instance
      .post(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      }),
  get: (url) =>
    instance
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      }),
  put: (url, payload) =>
    instance
      .put(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      }),
  delete: (url, payload) =>
    instance
      .delete(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      }),
};

export const webApi = {
  post: (url, payload) =>
    webInstance
      .post(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      }),
  get: (url) => {
    webInstance
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        validateIP(error);
      });
  },
};

export default api;
