import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { getCardData } from '../../components/SimpleAssetBalances';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import { BALANCES } from '../../reducers/balancesReducer';
import Skeleton from '@material-ui/lab/Skeleton';
import CurrencyCard from './CurrencyCard';
import { showSidebar } from '../../reducers/sidebarReducer';
import { ORDER_ENTRY_CONTAINER } from '../../constants/sideBarTypes';

import { ORDER_ENTRY } from '../../constants/modalTypes';

const useStyles = makeStyles({
  skeleton: {
    margin: '10px',
  },
});

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
Wrapper.displayName = 'Wrapper';

const CurrencyCards = ({
  balances,
  prices,
  assetTypes,
  loading,
  showModal,
}) => {
  const classes = useStyles();

  const onCardClick = (asset, index) => {
    if (asset !== 'USD') {
      showModal(ORDER_ENTRY, {
        assetType: asset,
        activeIndex: index,
        showAsModal: true,
        size: 'mini',
      });
    }
  };

  return (
    <Wrapper>
      {loading &&
        assetTypes.map((type) => (
          <Skeleton
            className={classes.skeleton}
            variant="rect"
            animation="wave"
            width={220}
            height={120}
            key={type.symbol}
          />
        ))}
      {!loading &&
        balances.map((b) => (
          <CurrencyCard
            {...getCardData(b, prices, assetTypes)}
            key={b.assetType}
            onClick={onCardClick}
          />
        ))}
    </Wrapper>
  );
};

CurrencyCards.defaultProps = {
  showSidebar: () => {},
};

CurrencyCards.propTypes = {
  balances: PropTypes.arrayOf(
    PropTypes.shape({
      assetType: PropTypes.string.isRequired,
      closingBalance: PropTypes.string.isRequired,
      lastPrice: PropTypes.string.isRequired,
      usdValue: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  prices: PropTypes.shape({
    'BCH/USD': PropTypes.string.isRequired,
    'BTC/USD': PropTypes.string.isRequired,
    'EST/USD': PropTypes.string.isRequired,
    'ETH/USD': PropTypes.string.isRequired,
    'LTC/USD': PropTypes.string.isRequired,
    'TXRP/USD': PropTypes.string,
    'USD/USD': PropTypes.string.isRequired,
    'XRP/USD': PropTypes.string.isRequired,
  }).isRequired,
  assetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isFiat: PropTypes.bool.isRequired,
    }).isRequired,
  ),
  showSidebar: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: createLoadingSelector([BALANCES])(state),
});

const mapDispatchToProps = {
  showSidebar,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyCards);
