import React from 'react';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import {
  Table,
  RowData,
  ColumnFiltersState,
  ColumnFilter,
} from '@tanstack/react-table';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import colors from '../../../constants/colors';
import { fadeIn } from '../../../styles/effects';
import { ServerSideFilter } from '../types';

const Wrapper = styled.div`
  display: flex;
  /* overflow-x: scroll; */
`;
const Filter = styled(Chip)`
  &&& {
    background-color: ${colors.GRAY_2};
    color: ${colors.NAVY};
    font-weight: bold;
    padding-top: 2px;
    margin: 0px 5px;
    ${fadeIn};
    .MuiChip-deleteIcon {
      color: ${colors.NAVY}!important;
      margin-bottom: 2px;
    }
  }
`;

type Props<T> = {
  table: Table<T>;
  /** If the table uses local filtering, filter format is different*/
  local: boolean;
};

const getValueFromFilter = (
  value: ColumnFilter | ServerSideFilter,
  local: boolean,
) => (local ? value : get(value, 'value', ''));

const AppliedFilters = <T,>({ table, local }: Props<T>) => {
  const removeFilter = (filter: ColumnFilter) => {
    const filters = get(table.getState(), 'columnFilters', []);
    table.setColumnFilters(filters.filter((f) => f.id !== filter.id));
  };
  const filters: ColumnFiltersState = table.getState().columnFilters;
  // Receives a filter value and returns it parsed according to its type
  const parseFilterValue = (value: string | number) => {
    // If value is a number or a string that corresponds to a number e.g. '200412'
    // we return the value as is, because it could be parsed as a valid date when its not

    if (!Number.isNaN(Number(value))) return value;

    const date = moment(value);
    if (date.isValid()) {
      return date.format('L');
    }
    return value;
  };

  const getFilterLabel = (filter: ColumnFilter, local): string => {
    // get and format the name of the filtered attribute
    const id = startCase(filter.id);
    // get comma-separated list of parsed filtering values
    let values: unknown | object = get(filter, 'value', []);
    // If using local filtering, value is not an array. Convert it to one.
    if (!isArray(values)) values = [values];

    values = (values as ColumnFilter[])
      .map((f: ColumnFilter) =>
        parseFilterValue(getValueFromFilter(f, local) as string | number),
      )
      .join(',');

    return `${id}: ${values}`;
  };

  return (
    <Wrapper>
      {filters.map((filter) => (
        <Filter
          size="small"
          key={`applied_filter_${filter.id}`}
          label={getFilterLabel(filter, local)}
          onDelete={() => removeFilter(filter)}
        />
      ))}
    </Wrapper>
  );
};

export default AppliedFilters;
