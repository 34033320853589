const API_KEY_PERMISSION_NAMES = {
  WRITE_PRE_TRADE_RISK: 'write_pre_trade_risk',
  READ_CLEARING_API: 'read_clearing_api',
  READ_TAX_DOCUMENTS: 'read_tax_documents',
  SUBMIT_BLOCK_TRADE: 'submit_block_trade',
  WRITE_TAX_DOCUMENTS: 'write_tax_documents',
  VIEW_MARKET_DATA: 'view_market_data',
  SUBMIT_ORDER: 'submit_order',
  ONBOARD_MEMBER_USER: 'onboard_member_user',
  READ_PRE_TRADE_RISK: 'read_pre_trade_risk',
  WRITE_CLEARING_API: 'write_clearing_api',
  SETTLEMENT_SERVICE: 'settlement_service',
  UPDATE_TRADE: 'update_trade',
  UPDATE_POSITION: 'update_position',
  READ_PARTICIPANT_ACCOUNT: 'read_participant_account',
  WRITE_PARTICIPANT_ACCOUNT: 'write_participant_account',
  REQUEST_FCM_CLEARING: 'request_fcm_clearing',
} as const;
export default API_KEY_PERMISSION_NAMES;
