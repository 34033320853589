import React, { useState } from 'react';
import get from 'lodash/get';
import FilterActions from './FilterActions';
import { Col, Input, Label } from './styled';
import { TableFilterProps } from './TableFilter';
import { getFilterInitialValue } from '../utils/utils';

/*
  Filters text values by 'contains' criterion
  @prop - column: column metadata which contains applied filter
  @prop - local:  change functionality if table is filtering locally
  @prop - filterOp:  filterOp value
*/
const TextFilter = ({ column, local, filterOp }: TableFilterProps<any>) => {
  const columnFilterValue = column.getFilterValue();
  const initialValue = getFilterInitialValue(columnFilterValue, local);
  const [value, setValue] = useState<string | number>(initialValue);

  const onApply = () => {
    const filter = local ? value : [{ attr: column.id, value, op: filterOp }];
    column.setFilterValue(filter);
  };

  const onClear = () => {
    column.setFilterValue();
    setValue('');
  };

  return (
    <Col>
      <Label>Filter by:</Label>
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e?.target?.value)}
        placeholder="Search..."
      />
      <FilterActions onApply={onApply} onClear={onClear} />
    </Col>
  );
};

export default TextFilter;
