import _ from 'lodash';
import React, { Fragment } from 'react';
import { XTable } from 'erisxkit/client';

const ACHDeposits = ({ title, result, metadata, onFetchData }) => (
  <Fragment>
    <XTable
      title={title}
      className="dark"
      data={_.get(result, 'achDeposits', [])}
      columns={metadata}
      minRows={5}
      manual
      pages={Math.ceil(_.get(result, 'count', 0) / 20)}
      sortable={false}
      onFetchData={onFetchData}
      showPageSizeOptions={false}
      showPagination={false}
      defaultPageSize={20}
    />
  </Fragment>
);
export default ACHDeposits;
