import React from 'react';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import colors from '../../constants/colors';
import { headerValue } from '../FundTransfers/AvailableFundsHeader';

const Wrapper = styled.div`
  display: flex;
  background-color: ${colors.NAVY_3};
  border: 1px solid ${colors.NAVY_LIGHT_TEXT};
  margin-right: auto;
`;

const Info = styled.div`
  border-right: 1px solid ${colors.NAVY_LIGHT_TEXT};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const InfoIcon = styled(Icon)`
  color: ${colors.NAVY_LIGHT_TEXT};
`;

const Funds = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 0px;
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  color: ${colors.NAVY_LIGHT_TEXT};
  font-size: 15px;
  margin-bottom: 0px;
`;

const Available = styled.p`
  display: flex;
  flex-direction: column;
  color: ${colors.NAVY_LIGHT_TEXT};
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const AvailableTransferFunds = ({ amountLimit }) => {
  const loading = useSelector((state) =>
    createLoadingSelector(['ACH_WITHDRAWAL_LIMIT'])(state),
  );
  const text = loading
    ? 'Fetching balance...'
    : headerValue(amountLimit.available, amountLimit.assetType, '', true);

  return (
    <Wrapper>
      <Info>
        <InfoIcon size="big" name="info circle" />
      </Info>
      <Funds>
        <Description>Available to Transfer*</Description>
        <Available>{text}</Available>
        <Description>
          *Funds that were deposited via ACH may not be available for withdrawal
        </Description>
      </Funds>
    </Wrapper>
  );
};

AvailableTransferFunds.defaultProps = {
  amountLimit: {
    balance: '0',
    available: '0',
    assetType: 'USD',
  },
};

AvailableTransferFunds.propTypes = {
  amountLimit: PropTypes.shape({
    balance: PropTypes.string,
    available: PropTypes.string,
    assetType: PropTypes.string,
  }),
};

export default AvailableTransferFunds;
