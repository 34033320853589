import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import colors from '../../constants/colors';
import TextField from '@material-ui/core/TextField';
import StyledButton from '../../common/StyledButton';
import { fadeIn } from '../../styles/effects';
import {
  setReferralCode,
  getCodeVerificationError,
  getReferredByCode,
} from '../../reducers/referralCodeReducer';
import { createErrorMessageSelector } from 'erisxkit/client';
import { isEmpty } from 'lodash';
import { getCurrentUserId } from '../../reducers/userReducer';
import { Icon } from 'semantic-ui-react';

const Wrapper = styled.div`
  margin: 0px 0px 20px 0px;
  padding: 20px 25px 20px 25px;
  border-top: 1px solid ${colors.NAVY_SECTION_SEPARATOR};
  border-bottom: 1px solid ${colors.NAVY_SECTION_SEPARATOR};
`;
Wrapper.displayName = 'ReferralWrapper';

const TextContainer = styled.div`
  cursor: pointer;
  display: flex;
`;
TextContainer.displayName = 'TextContainer';

const Text = styled.p`
  color: ${colors.WHITE};
  margin-bottom: 0px;
`;
Text.displayName = 'Text';

const Verified = styled(Text)`
  ${fadeIn};
  margin-bottom: 22px;
  margin-left: 10px;
`;
Verified.displayName = 'Verified';

const CTA = styled.span`
  color: ${colors.GREEN};
  margin-left: 10px;
`;
Text.displayName = 'Text';

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0px;
  justify-content: center;
  ${fadeIn};
`;
Container.displayName = 'Container';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
`;

const Input = styled.input`
  background-color: ${colors.NAVY};
  border-radius: 3px;
  border: ${(props) =>
    props.error ? `1px solid ${colors.RED_ERROR}` : 'none'};
  color: ${colors.GREEN};
  height: 30px;
  padding-left: 10px;
  margin-right: 15px;
`;

const Label = styled.p`
  color: ${colors.WHITE};
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 15px;
`;

const Apply = styled(StyledButton)`
  height: 30px;
  width: 83px;
`;
Apply.displayName = 'Apply';

const Error = styled.p`
  color: ${colors.RED_ERROR};
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
`;
Error.displayName = 'Error';

const Inputs = styled.div`
  display: flex;
`;

const VerificationSucess = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 5px;
`;

const ReferralCodeVerifier = () => {
  const [verifyMode, setVerifyMode] = useState(false);
  const [referredByCode, setReferredByCode] = useState('');
  const actionText = verifyMode ? 'Verify' : 'Enter';
  const enterVerifyMode = () => setVerifyMode(true);

  const dispatch = useDispatch();
  const errorCode = useSelector((state) =>
    createErrorMessageSelector(['SET_REFERRED_BY_CODE'])(state),
  );
  const appliedReferralCode = useSelector(getReferredByCode);
  const userId = useSelector(getCurrentUserId);
  const error = !isEmpty(errorCode);
  const onChange = (e) => setReferredByCode(e.target.value);
  const isVerified = !error && !!appliedReferralCode;
  const onVerify = () => dispatch(setReferralCode({ referredByCode }));

  useEffect(() => {
    // If the user has clicked on a referral link, they'll have
    // a referral code on their localstorage
    const referralFromLink = localStorage.getItem('referralCode');
    if (referralFromLink) {
      dispatch(setReferralCode({ referredByCode: referralFromLink }));
      setVerifyMode(true);
      setReferredByCode(referralFromLink);
    }
  }, []);

  return (
    <Wrapper>
      <TextContainer onClick={enterVerifyMode}>
        <Text>Do you have a referral code?</Text>
        <CTA>{`${actionText} your code here.`}</CTA>
      </TextContainer>
      {verifyMode && (
        <Container>
          <InputWrapper>
            <Label>Referral Code</Label>
            <Inputs>
              <Input
                error={error}
                helperText="error"
                value={referredByCode}
                onChange={onChange}
                disabled={isVerified}
              />
              {!isVerified && (
                <Apply
                  text="Apply"
                  secondary
                  error={error}
                  onClick={onVerify}
                />
              )}
              {isVerified && (
                <VerificationSucess>
                  <Icon name="check" />
                  <Verified>Verified!</Verified>
                </VerificationSucess>
              )}
            </Inputs>
            {error && <Error>Invalid Code</Error>}
          </InputWrapper>
        </Container>
      )}
      <TextContainer>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.erisx.com/promotions"
        >
          Terms and Conditions apply.
        </a>
      </TextContainer>
    </Wrapper>
  );
};

export default ReferralCodeVerifier;
