import React from 'react';
import { Header, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const TooltipWrapper = styled.section`
  border-radius: 0;
  background-color: #15446ed0;
  padding: 1rem 1rem;
  min-width: 300px;
`;

const CloseButton = styled.i`
  align-self: flex-start;
  margin: 0.75rem 0.5rem 0 0;
`;

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
`;

const SkipLink = styled.a`
  align-self: flex-end;
`;

const TooltipButtonWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const XTooltip = ({
  continuous,
  index,
  size,
  step,
  skipProps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}) => (
  <TooltipWrapper {...tooltipProps}>
    <HeaderWrapper>
      {step.title && <Header as="h1">{step.title}</Header>}
      <CloseButton {...closeProps} className="link icon close" />
    </HeaderWrapper>
    {step.content}
    <TooltipButtonWrapper>
      {
        <SkipLink {...skipProps} className="ui">
          Skip
        </SkipLink>
      }
      <div className="main-actions">
        {index > 0 && <Button {...backProps} content="Back" secondary />}
        {continuous && (
          <Button
            {...primaryProps}
            content={`${primaryProps.title}${continuous ? ` (${index + 1}/${size})` : ''}`}
            primary
          />
        )}
      </div>
    </TooltipButtonWrapper>
  </TooltipWrapper>
);

export default XTooltip;
