import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

const ConfirmModal = ({
  headerText = '',
  confirmProps,
  rejectProps,
  content = null,
  actionsClass,
  modalClass = '',
  loadingSelector = () => false,
}) => {
  const loading = useSelector((state) => loadingSelector(state));
  return (
    <Fragment className={modalClass}>
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions className={actionsClass || ''}>
        <Button {...rejectProps} />
        <Button
          {...confirmProps}
          disabled={confirmProps?.disabled || loading}
        />
      </Modal.Actions>
    </Fragment>
  );
};

export default ConfirmModal;
