import _, { startCase, get } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from 'erisxkit/client';
import { Button, Radio, Divider, Icon, Segment, Form } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import { CONFIRM_PHOTO, DEFAULT_PHOTO_ERROR } from '../../constants/modalTypes';
import LoadingComponent from '../../common/components/xtable/LoadingComponent';
import { getBase64 } from '../../utils/methods';
import Dropzone from '../../common/Dropzone';
import { trackEvent } from '../../common/tracking';
import ClassificationService from './Acuant/ClassificationService';
import {
  MAX_TRIES_BEFORE_UPLOAD,
  MIN_GLARE,
  MIN_SHARPNESS,
} from '../../constants/pictureUploadReqs';
import {
  classificationFailed,
  glareBlurFailed,
} from '../../common/containers/AcuantReactCamera';

const onFileDropAccepted = (files, key, uploadFile) => {
  getBase64(files[0]).then((encoded) => {
    uploadFile(encoded, key);
  });
};

const submitDisabled = (imagesState, selectedIdType, supportingDoc) => {
  if (!selectedIdType.hasBackSide) {
    return (
      !imagesState.idFront ||
      (supportingDoc ? !imagesState.supportingDoc : false)
    );
  }
  return (
    !(imagesState.idBack && imagesState.idFront) ||
    (supportingDoc ? !imagesState.supportingDoc : false)
  );
};

const getText = () => {
  if (isMobile) {
    return 'Click to Upload Documents';
  }
  return 'Drag & Drop or Click to Upload';
};

const content = (header, imagesState, image) => (
  <div
    className={imagesState[image] ? 'flex uploaded-area' : 'flex upload-area'}
  >
    <div className="vert-flex drop-content">
      <div className="title">{header}</div>
      <div className="desc">{getText()}</div>
    </div>
    {imagesState[image] && (
      <Icon
        name={imagesState[image] ? 'green check circle' : 'upload'}
        link
        size="large"
        title={imagesState[image] ? 'Try Again' : 'Upload Document'}
        data-cy="ob-verification-file-upload"
      />
    )}
  </div>
);

const isPassport = (selectedIdType) =>
  _.get(selectedIdType, 'type', '') === 'passport';

export class FileUploadId extends Component {
  state = {
    cameraOpen: false,
    processing: false,
    buttonClicked: false,
    times: 0,
  };

  getIcon = (uploaded, name) => {
    const { takePhoto } = this.props;
    return (
      <Icon
        name={uploaded ? 'green circle check' : 'camera'}
        link={!isMobile}
        onClick={() => {
          isMobile && AcuantCamera.isCameraSupported
            ? this.takeMobilePhoto(name)
            : this.startCamera({ isManualCapture: false, type: name });
        }}
        size="large"
        title={uploaded ? 'Try Again' : 'Take Photo'}
      />
    );
  };

  onCaptured = (response) => {
    this.setProcessing(true);
  };

  onCropped = (response, type) => {
    const { uploadFile } = this.props;
    this.setProcessing(false);
    this.setState(
      (state) => ({
        times: state.times + 1,
        cameraStarted: false,
      }),
      () => {
        if (response) {
          // check sharpness and glare here
          if (
            (response.sharpness < MIN_SHARPNESS ||
              response.glare < MIN_GLARE) &&
            !this.userExceededMaxTries()
          ) {
            glareBlurFailed(response.glare, response.sharpness);

            this.props.showModal(DEFAULT_PHOTO_ERROR, {
              onConfirm: () => {
                this.props.hideModal();
                uploadFile(response.image.data, type);
              },
              onRetry: () => {
                this.props.hideModal();
                this.props.hideModal();
              },
              times: this.state.times,
            });
          } else {
            // check for when user has exceeded 2 tries
            if (
              response.sharpness < MIN_SHARPNESS ||
              response.glare < MIN_GLARE
            ) {
              glareBlurFailed(response.glare, response.sharpness);
            }
            this.props.showModal(CONFIRM_PHOTO, {
              type,
              image: response.image.data,
              onConfirm: () => {
                this.setProcessing(true);
                this.props.hideModal();
                ClassificationService(response).then((classification) => {
                  this.setProcessing(false);
                  const idType = startCase(type.split('_')[1]);
                  // quick fix for always firing GA event for failures
                  if (
                    get(classification, [idType, 'ClassName']) === 'Unknown'
                  ) {
                    classificationFailed(type);
                  }
                  if (
                    get(classification, [idType, 'ClassName']) !== 'Unknown' ||
                    this.userExceededMaxTries()
                  ) {
                    uploadFile(response.image.data, type);
                  } else {
                    classificationFailed(type);

                    this.props.showModal(DEFAULT_PHOTO_ERROR, {
                      onConfirm: () => {
                        this.props.hideModal();
                        uploadFile(response.image.data, type);
                      },
                      onRetry: () => {
                        this.props.hideModal();
                        this.props.hideModal();
                      },
                      times: this.state.times,
                    });
                  }
                });
              },
              onReject: () => {
                trackEvent('AMLKYC', 'IDuploadRetry');
                this.props.hideModal();
              },
            });
          }
        } else {
          // cropping error
          // restart capture
          this.startCamera({ isManualCapture: true, type });
        }
      },
    );
  };

  onFrameAvailable = (response) => {};

  setProcessing = (value) => {
    this.setState({
      processing: value,
    });
  };

  userExceededMaxTries() {
    return this.state.times > MAX_TRIES_BEFORE_UPLOAD;
  }

  times = (value) => {
    this.setState({
      times: value,
    });
  };

  startCamera = ({ isManualCapture, type }) => {
    if (window.AcuantCameraUI) {
      if (window.AcuantCamera.isCameraSupported && !isManualCapture) {
        this.setState({ cameraOpen: true });
        window.AcuantCameraUI.start(
          {
            onCaptured: this.onCaptured,
            onCropped: (response) => this.onCropped(response, type),
            onFrameAvailable: this.onFrameAvailable,
          },
          this.onError,
        );
      } else {
        window.AcuantCamera.startManualCapture(
          {
            onCaptured: this.onCaptured,
            onCropped: (response) => this.onCropped(response, type),
          },
          this.onError,
        );
      }
    }
  };

  onError = (err, code) => {
    console.error('Failed to upload docs', err, code);
    this.setState({ cameraOpen: false });
  };

  onSubmitClicked = () => {
    this.setState({
      buttonClicked: true,
    });
    trackEvent('AMLKYC', 'IDuploadSubmit');
    this.props.onSubmit();
  };

  onBack = () => {
    const { goToStep } = this.props;
    trackEvent('AMLKYC', 'IDuploadPriorSteps');
    goToStep(2);
  };

  takeMobilePhoto = (name) => {
    window.AcuantCamera.isCameraSupported
      ? this.props.takePhoto(name, 3)
      : this.startCamera({ isManualCapture: true, type: name });
  };

  render() {
    const {
      selectedIdType = {},
      goToStep,
      imagesState = {},
      rejectFile,
      submitLoading,
      supportingDocChange,
      supportingDoc,
      uploadFile,
    } = this.props;
    const { processing, cameraOpen } = this.state;
    return (
      <div className="onboarding-container">
        {processing ? (
          <LoadingComponent message={{ text: 'Analyzing image...' }} />
        ) : (
          <div className="onboarding-content">
            <div className="onboarding-header">Identification</div>
            <div>
              <div className="onboarding-header">
                Upload Your {selectedIdType.name}:
              </div>
              <div className="onboarding-desc">
                Please upload pictures of your selected ID in JPEG or PNG
                format. <br /> (min size: 50KB, max size: 4MB)
              </div>
            </div>
            <Divider hidden />
            {isMobile ? (
              <div
                className={
                  imagesState.idFront
                    ? 'flex uploaded-area'
                    : 'flex upload-area'
                }
                data-cy={`ob-verification-upload-photo-front`}
              >
                <label
                  className="vert-flex drop-content"
                  onClick={() => {
                    this.takeMobilePhoto('id_front');
                  }}
                >
                  <p className="buttonBgText">UPLOAD FRONT OF ID</p>
                </label>
                {isMobile && this.getIcon(imagesState.idFront, 'id_front')}
              </div>
            ) : (
              <Dropzone
                accept={['image/jpeg', 'image/png']}
                multiple={false}
                onDropAccepted={(files) =>
                  onFileDropAccepted(files, 'id_front', uploadFile)
                }
                onDropRejected={(files) => {
                  console.log('drop rejected with files', files);
                  rejectFile(files[0]);
                }}
                content={() =>
                  content('UPLOAD FRONT OF ID', imagesState, 'idFront')
                }
                data-cy={`ob-verification-upload-photo-front`}
              />
            )}
            {selectedIdType.hasBackSide &&
              imagesState.idFront &&
              (isMobile ? (
                <div
                  className={
                    imagesState.idBack
                      ? 'flex uploaded-area'
                      : 'flex upload-area'
                  }
                  data-cy={`ob-verification-upload-photo-back`}
                >
                  <label
                    className="vert-flex drop-content"
                    onClick={() => {
                      this.takeMobilePhoto('id_back');
                    }}
                  >
                    <p className="buttonBgText">UPLOAD BACK OF ID</p>
                  </label>
                  {this.getIcon(imagesState.idBack, 'id_back')}
                </div>
              ) : (
                <Dropzone
                  accept={['image/jpeg', 'image/png']}
                  multiple={false}
                  onDropAccepted={(files) =>
                    onFileDropAccepted(files, 'id_back', uploadFile)
                  }
                  onDropRejected={(files) => rejectFile(files[0])}
                  content={() =>
                    content('UPLOAD BACK OF ID', imagesState, 'idBack')
                  }
                  data-cy={`ob-verification-upload-photo-back`}
                />
              ))}
            <Fragment>
              {!isPassport(selectedIdType) &&
                imagesState.idBack &&
                imagesState.idFront && (
                  <Segment>
                    <div>
                      Do you reside at the address that is on your
                      government-issued ID?
                      <Form.Group data-cy="ob-verification-address-verification-checkboxes">
                        <Radio
                          style={{ marginTop: '5px', marginBottom: '5px' }}
                          className={
                            'radio-addn-doc' &&
                            (!supportingDoc
                              ? 'radio-addn-doc-border-active'
                              : 'radio-addn-doc-border')
                          }
                          label="Yes, I reside at the same address"
                          name="additional_doc"
                          onChange={supportingDocChange}
                          checked={!supportingDoc}
                        />
                        <br />
                        <Radio
                          style={{ marginTop: '5px', marginBottom: '5px' }}
                          className={
                            'radio-addn-doc' &&
                            (supportingDoc
                              ? 'radio-addn-doc-border-active'
                              : 'radio-addn-doc-border')
                          }
                          label="No, I reside at a different address"
                          name="additional_doc"
                          onChange={supportingDocChange}
                          checked={supportingDoc}
                        />
                      </Form.Group>
                      {supportingDoc && <Divider />}
                    </div>
                  </Segment>
                )}
              {supportingDoc &&
                imagesState.idFront &&
                (selectedIdType.hasBackSide ? imagesState.idBack : true) && (
                  <Segment attached>
                    <div className="reqs-address">
                      Please provide a copy of any of the following supported
                      documents. The document must display your name, current
                      residential address, and must be dated within 90 days of
                      the date of submission. Please ensure you provide a
                      full-page image or scan of the document.
                      <br />
                      <ul className="min-reqs">
                        <li>
                          Utility Bill (electric, water, refuse, telephone
                          land/cell, cable or gas)
                        </li>
                        <li>Bank Statement</li>
                        <li>Payroll Statement</li>
                        <li>Mortgage Statement</li>
                        <li>Insurance Statement (homeowner’s or renter’s)</li>
                      </ul>
                      <Dropzone
                        multiple={false}
                        onDropAccepted={(files) =>
                          onFileDropAccepted(
                            files,
                            'supporting_doc',
                            uploadFile,
                          )
                        }
                        onDropRejected={(files) => rejectFile(files[0])}
                        content={() =>
                          content(
                            'UPLOAD DOCUMENT',
                            imagesState,
                            'supportingDoc',
                          )
                        }
                        data-cy="ob-verification-document-copy"
                      />
                    </div>
                  </Segment>
                )}
            </Fragment>

            <Button
              fluid
              primary
              name="dropzone"
              content="SUBMIT IMAGES"
              className="top-button"
              disabled={
                submitDisabled(imagesState, selectedIdType, supportingDoc) ||
                this.state.buttonClicked
              }
              onClick={this.onSubmitClicked}
              loading={submitLoading}
              data-dd-action-name="Submit Images"
              data-cy="ob-verification-submit-id-images"
            />
            <Button
              fluid
              secondary
              name="dropzone"
              content="BACK"
              onClick={this.onBack}
              data-dd-action-name="Back from ID Image Upload"
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  hideModal,
  showModal,
};

export default connect(null, mapDispatchToProps)(FileUploadId);
