import React from 'react';
import PropTypes from 'prop-types';
import { isMobileSafari, isIOS, isChrome } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import {
  DOWNLOAD_NOT_AVAILABLE_ON_CHROME_IOS,
  DOWNLOAD_NOT_AVAILABLE_ON_SAFARI_MOBILE,
} from '../../constants/messages';
import PersistentCogoToast from '../../common/components/PersistentCogoToast';
import { getApplicationPacket } from '../../reducers/userReducer';
import styled from 'styled-components';
import { isActiveAccountOfType } from '../../reducers/accountsReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import useIsFCM from '../../hooks/useIsFCM';

const EntityAgreementTextBlock = styled.p`
  margin: 0;
`;

const Download = ({ url }) => {
  const fileName = 'CboeDigitalApplicationPacket.pdf';
  return (
    <Button primary download={fileName} href={url}>
      Download Application Packet
    </Button>
  );
};

const EntityAgreementText = () => (
  <div>
    <EntityAgreementTextBlock>
      Your signed agreement is available upon request to{' '}
      <a href="mailto:digital.membership@cboe.com">
        digital.membership@cboe.com
      </a>
      .
    </EntityAgreementTextBlock>
    <EntityAgreementTextBlock>
      Please note that any updates to the Cboe Digital Member Master Agreement
      will be noticed to you via email.
    </EntityAgreementTextBlock>
    <EntityAgreementTextBlock>
      Current versions of the Cboe Digital Member Master Agreement (Entity) can
      be found{' '}
      <a href="https://www.cboedigital.com/regulation/membership/">here</a>.
    </EntityAgreementTextBlock>
  </div>
);

const DownloadApplicationPacket = ({ user }) => {
  const dispatch = useDispatch();
  const activeAccount = useSelector(getActiveAccount);
  const isActiveAccountOfTypeIraLSG = useSelector((state) =>
    isActiveAccountOfType(state, ['IRAL', 'IRAS', 'IRAG']),
  );
  const isFCM = useIsFCM();

  const handleRequestClick = () => {
    // currently not supporting downloads on safari mobile
    if (isMobileSafari) {
      PersistentCogoToast.warn(DOWNLOAD_NOT_AVAILABLE_ON_SAFARI_MOBILE);
      return false;
    }

    // currently not supporting downloads on chrome for ios
    if (isIOS && isChrome) {
      PersistentCogoToast.warn(DOWNLOAD_NOT_AVAILABLE_ON_CHROME_IOS);
      return false;
    }

    // otherwise request the packet from the backend
    dispatch(getApplicationPacket({ id: user?.userId }));

    return true;
  };

  if (isFCM) {
    return null;
  }

  return activeAccount?.firmCode === 'DM' && !isActiveAccountOfTypeIraLSG ? (
    <div>
      {user.applicationPacketDataUrl ? (
        <Download url={user.applicationPacketDataUrl} />
      ) : (
        <Button
          secondary
          onClick={handleRequestClick}
          disabled={user?.ongoingApplicationPacketRequest}
        >
          Request Application Packet
        </Button>
      )}
    </div>
  ) : (
    <EntityAgreementText />
  );
};

DownloadApplicationPacket.defaultProps = {
  user: {
    userId: '',
    applicationPacketDataUrl: '',
    ongoingApplicationPacketRequest: '',
  },
};

DownloadApplicationPacket.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string,
    applicationPacketDataUrl: PropTypes.string,
    ongoingApplicationPacketRequest: PropTypes.string,
  }),
};

export default DownloadApplicationPacket;
