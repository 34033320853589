import _ from 'lodash';

// get any selector mapped to: { key, value, text, description }
export const getSelectorAsOptions = (selector, option) => (state) =>
  _.map(selector(state), (o) => {
    if (!_.isObjectLike(o)) {
      return { key: o, value: o, text: o };
    }

    // for each item from selector, map options. fall back to given string.
    // if we get a function, pass the given value and handle it there.
    return _.mapValues(option, (opt) => {
      if (typeof opt !== 'function') {
        return _.get(o, [opt], opt);
      }
      return opt(o);
    });
  });
