import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Header, Divider } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import BankAccounts from '../../components/LinkedAccounts/BankAccounts';
import CryptoAddressesContainer from './CryptoAddressesContainer';
import * as linkedAccountsActions from '../../reducers/linkedAccountsReducer';
import { getCurrentAuthId } from '../../reducers/userReducer';
import {
  getCryptoAssetTypes,
  fetchAssetTypes,
} from '../../reducers/assetTypesReducer';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import bankAccountsMetadata from '../../metadata/bankAccountsMetadata';

const mapStateToProps = (state) => ({
  linkedCryptoAddresses: linkedAccountsActions.getLinkedCryptoAddresses(state),
  linkedBankAccounts: linkedAccountsActions.getLinkedBankAccounts(state),
  assetTypes: getCryptoAssetTypes(state),
  linkedMemberAssetAccountsLoading: createLoadingSelector([
    'LINKED_MEMBER_ASSET_ACCOUNTS',
  ])(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
  authId: getCurrentAuthId(state),
  selectedMemberId: getSelectedMemberId(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      lookupHashIdPromiseCreator:
        linkedAccountsActions.lookupHashIdPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      fetchAssetTypes,
      checkBankAccount: linkedAccountsActions.checkBankAccount,
      fetchLinkedMemberAssetAccounts:
        linkedAccountsActions.fetchLinkedMemberAssetAccounts,
    },
    dispatch,
  ),
});

class LinkedAccountsContainer extends Component {
  componentDidMount = () => {
    this.props.fetchLinkedMemberAssetAccounts({
      filter: [
        { attr: 'member_id', op: 'eq', value: this.props.selectedMemberId },
      ],
    });
  };

  render = () => (
    <section className="main-holdings">
      <Header as="h1">Manage Accounts and Addresses</Header>
      <Header>
        View and add bank accounts and crypto addresses.
        <Header.Subheader content="To ensure the security of your account, new accounts and addresses will be pending until approved by the Cboe Digital treasury team." />
      </Header>
      <Divider />
      <BankAccounts
        data={this.props.linkedBankAccounts}
        metadata={bankAccountsMetadata}
        loading={this.props.linkedMemberAssetAccountsLoading}
      />
      <Divider />
      <CryptoAddressesContainer
        data={this.props.linkedCryptoAddresses}
        dataLoading={this.props.linkedMemberAssetAccountsLoading}
        assetTypes={this.props.assetTypes}
        assetTypesLoading={this.props.assetTypesLoading}
      />

      <IraAccountDisclaimer marginBottom="40px" useAllAccounts />
    </section>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedAccountsContainer);
