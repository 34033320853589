import styled from 'styled-components';
import AddNewBlockTradeButton from './AddNewBlockTradeButton';

export const RegisterBlockTrade = styled(AddNewBlockTradeButton)`
  justify-self: end;
`;

export const Wrapper = styled.div`
  display: table-column;
  grid-template-columns: 1fr;
  row-gap: 1em;
`;
