import React from 'react';
import { StrictTabProps, Tab } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledTab = styled(Tab)`
  .ui.menu {
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.tabs.bg};
    color: ${({ theme }) => theme.tabs.text};
    box-shadow: 1px 2px 4px -2px rgba(0, 0, 0, 0.2);
  }
  // pointing arrow
  .ui.menu .active.item::after {
    width: 0.57142857em;
    background-color: ${({ theme }) => theme.tabs.bgActive};
    left: 50%;
    border-bottom: 1px solid ${({ theme }) => theme.tabs.border};
    border-right: 1px solid ${({ theme }) => theme.tabs.border};
  }
  .ui.pointing.menu .active.item:hover:after {
    background-color: ${({ theme }) => theme.tabs.bg};
  }
  // Tab separator
  .ui.menu .item:before {
    background-color: ${({ theme }) => theme.tabs.tabSeparator};
  }
  // Tab Hover
  .ui.menu a.item:hover,
  .ui.secondary.menu a.item:hover {
    color: ${({ theme }) => theme.tabs.text};
  }
  .ui.menu .item {
    color: ${({ theme }) => theme.tabs.text};
  }
  // Active tab
  .ui.menu .active.item,
  .ui.secondary.menu .active.item {
    background-color: ${({ theme }) => theme.tabs.bgActive} !important;
  }

  a:hover {
    box-shadow: 0px 2px 0 0 ${({ theme }) => theme.tabs.hoverAccent};
  }
`;

const Tabs = ({
  panes,
  onTabChange,
  activeIndex,
}: StrictTabProps) => {
  return (
    <StyledTab
      menu={{ pointing: true }}
      panes={panes}
      onTabChange={onTabChange}
      activeIndex={activeIndex}
    />
  );
};

export default Tabs;
