import React, { Fragment } from 'react';
import _ from 'lodash';
import {
  Button,
  Header,
  Grid,
  Icon,
  Segment,
  Divider,
  GridRow,
  GridColumn,
} from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
import history from '../../constants/history';

const Security = ({ changePassword, user, createMfTicket }) => (
  <Fragment>
    <Segment>
      <Header>MULTI-FACTOR AUTHENTICATION</Header>
      <Divider />

      {_.get(user, ['auth', 'userMetadata', 'useMfa'], false) ? (
        <Fragment>
          <Icon name="check circle green" />
          Two-Factor Authentication is enabled for your account. If you have a
          new phone and need to reset your 2FA, please{' '}
          <a
            onClick={() => {
              history.push('/contact');
            }}
          >
            contact support
          </a>
          .
        </Fragment>
      ) : (
        <Fragment>
          <div className="information-grid">
            <Grid celled columns="equal">
              <GridRow>
                <GridColumn
                  width={isMobile ? 'three' : 'two'}
                  textAlign="center"
                >
                  <Icon size="big" name="bell" />
                </GridColumn>
                <GridColumn>
                  You do not have Two-Factor Authentication enabled for this
                  account. Cboe Digital recommends you enable it to increase the
                  security of your account.
                  <br />
                  <strong>
                    Note: Setting up 2FA requires all future logins to enter a
                    code supplied by your authenticator app.
                  </strong>
                </GridColumn>
              </GridRow>
            </Grid>
          </div>

          <Button
            primary
            name="reset"
            onClick={() => createMfTicket({ userId: user.authId })}
          >
            Enable 2FA
          </Button>
          <br />
        </Fragment>
      )}
    </Segment>
    <Segment>
      <Header>FUNDING PASSWORD</Header>
      <Button color="red" name="reset" onClick={changePassword}>
        Change Funding Password
      </Button>
    </Segment>
  </Fragment>
);
export default Security;
