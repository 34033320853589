import React from 'react';
import { connect } from 'react-redux';
import { hideModal, getAllModalTypes, getAllArgs } from 'erisxkit/client';
import { Modal } from 'semantic-ui-react';

/** Modal Components */
import WithdrawConfirmContainer from '../containers/FundTransfers/WithdrawalConfirmContainer';
import CreateFundingPasswordContainer from '../containers/CreateFundingPasswordContainer';
import FileUpload from '../common/FileUpload';
import * as modalTypes from '../constants/modalTypes';
import FundingPasswordContainer from './FundingPasswordContainer';
import AddLinkedAccountContainer from './LinkedAccounts/AddLinkedAccountContainer';
import VerificationIndex from './onBoarding/VerificationIndex';
import TransactionInformationModal from '../components/History/TransactionInformationModal';
import ConfirmPhotoModal from '../common/ConfirmPhoto';
import SelfieRecomendation from '../common/SelfieRecomendation';
import UploadRejectedModal from '../common/UploadRejectedModal';
import TakePhoto from '../common/TakePhoto';
import CreateApiKeyModal from '../containers/Profile/CreateApiKeyModalContainer';
import ConfirmModal from '../common/components/ConfirmModal';
import SelectMemberModalContainer from '../common/containers/SelectMemberModalContainer';
import TradeConfirmModal from '../common/components/TradeConfirmModal';
import ForgotFundingPasswordModal from '../common/ForgotFundingPasswordModal';
import DefaultPhotoError from '../components/onBoarding/DefaultPhotoError';
import OrderEntryWidgetContainer from './OrderEntry/OrderEntryWidgetContainer';
import EmailSentModal from '../components/InviteFriends/EmailSentModal';
import UnregisteredUserModal from '../components/DelegateAccess/UnregisteredUserModal';
import RemoveDelegatedUserModal from '../components/DelegateAccess/RemoveDelegatedUserModal';
import NewDelegatedUserModal from '../components/DelegateAccess/NewDelegatedUserModal';
import FuturesUpgradeModal from '../components/FuturesUpgradeModal';
import FuturesOnboardingModal from '../components/onBoarding/Futures/FuturesOnboardingModal';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import TransferConfirmationModal from '../components/InternalTransfers/TransferConfirmationModal';
import AddIPAddressModal from '../components/AuthorizedIPs/AddIPAddressModal';
import RemoveAuthorizedIPModal from '../components/AuthorizedIPs/RemoveAuthorizedIPModal';
import Reset2faModal from '../components/ProfilePage/Reset2faModal';
import AddCARModal from '../components/PreTradeRisk/AddCARModal';
import CreateCGM from '../components/PreTradeRisk/CreateCGM';
import TaxModal from '../components/TaxEStatements/TaxModal';
import GenericModal from '../common/components/GenericModal';
import FCMWithdrawalConfirmContainer from './FundTransfers/FCMWithdrawalConfirmContainer';
import RequestFCMClearing from '../components/FCMClearing/RequestFCMClearing';
import OffboardingModal from '../common/components/OffboardingModal';
import ClearingRequestModal from '../components/ParticipantAccountManagement/ClearingRequests/ClearingRequestModal';
import ParticipantCGMLinkModal from '../components/ParticipantAccountManagement/ApprovedParticipants/Actions/ParticipantCGMLinkModal';
import ParticipantCARLinkModal from '../components/ParticipantAccountManagement/ApprovedParticipants/Actions/ParticipantCARLinkModal';
import AddAuthorizedTrader from '../components/BlockTrades/AddAuthorizedTrader';
import RegisterBlockTradeModal from '../components/BlockTradesRegistration/RegisterBlockTradeModal';
import ApproveRejectBlockTradeRequest from '../components/BlockTradesRegistration/ApproveRejectBlockTradeRequest';

const MODAL_COMPONENTS = {
  [modalTypes.WITHDRAWAL_CONFIRM]: WithdrawConfirmContainer,
  [modalTypes.ADD_LINKED_ACCOUNT]: AddLinkedAccountContainer,
  [modalTypes.VERIFICATION]: VerificationIndex,
  [modalTypes.CREATE_FUNDING_PASSWORD]: CreateFundingPasswordContainer,
  [modalTypes.FORGOT_FUNDING_PASSWORD]: ForgotFundingPasswordModal,
  [modalTypes.FILE_UPLOAD]: FileUpload,
  [modalTypes.FUNDING_PASSWORD]: FundingPasswordContainer,
  [modalTypes.TRANSACTION_INFORMATION]: TransactionInformationModal,
  [modalTypes.CONFIRM_PHOTO]: ConfirmPhotoModal,
  [modalTypes.SELFIE_RECOMENDATION]: SelfieRecomendation,
  [modalTypes.UPLOAD_REJECTED]: UploadRejectedModal,
  [modalTypes.TAKE_PHOTO]: TakePhoto,
  [modalTypes.CREATE_API_CREDENTIALS]: CreateApiKeyModal,
  [modalTypes.CONFIRM_MODAL]: ConfirmModal,
  [modalTypes.MEMBER_SELECTION]: SelectMemberModalContainer,
  [modalTypes.TRADE_CONFIRM]: TradeConfirmModal,
  [modalTypes.DEFAULT_PHOTO_ERROR]: DefaultPhotoError,
  [modalTypes.ORDER_ENTRY]: OrderEntryWidgetContainer,
  [modalTypes.REFERRAL_CODE_SENT]: EmailSentModal,
  [modalTypes.NOTIFY_UNREGISTERED_USER]: UnregisteredUserModal,
  [modalTypes.REMOVE_AUTHORIZED_USER]: RemoveDelegatedUserModal,
  [modalTypes.ADD_AUTHORIZED_USER]: NewDelegatedUserModal,
  [modalTypes.UPGRADE_TO_FUTURES]: FuturesUpgradeModal,
  [modalTypes.FUTURES_ONBOARDING]: FuturesOnboardingModal,
  [modalTypes.ACCOUNT_SELECTION]: AccountSelectionContainer,
  [modalTypes.TRANSFER_CONFIRMATION_MODAL]: TransferConfirmationModal,
  [modalTypes.ADD_IP_ADDRESS_MODAL]: AddIPAddressModal,
  [modalTypes.REMOVE_IP_ADDRESS_MODAL]: RemoveAuthorizedIPModal,
  [modalTypes.RESET_2FA_MODAL]: Reset2faModal,
  [modalTypes.ADD_NEW_CAR]: AddCARModal,
  [modalTypes.ADD_NEW_CGM]: CreateCGM,
  [modalTypes.TAX_STATEMENTS]: TaxModal,
  [modalTypes.GENERIC_MODAL]: GenericModal,
  [modalTypes.FCM_WITHDRAWAL_CONFIRM]: FCMWithdrawalConfirmContainer,
  [modalTypes.REQUEST_FCM_CLEARING]: RequestFCMClearing,
  [modalTypes.DM_OFFBOARDING_MODAL]: OffboardingModal,
  [modalTypes.CLEARING_REQUEST_ACTION]: ClearingRequestModal,
  [modalTypes.TRADING_PARTICIPANT_LINK_CGM]: ParticipantCGMLinkModal,
  [modalTypes.TRADING_PARTICIPANT_LINK_CAR]: ParticipantCARLinkModal,
  [modalTypes.ADD_AUTHORIZED_TRADER]: AddAuthorizedTrader,
  [modalTypes.REGISTER_BLOCK_TRADE]: RegisterBlockTradeModal,
  [modalTypes.APPROVE_REJECT_BLOCK_TRADE_REQUEST]: ApproveRejectBlockTradeRequest,
};

const ModalContainer = ({ hideModal, allArgs = {}, modalTypes }) => {
  if (!modalTypes.length) {
    return null;
  }
  const allModals = modalTypes.map((modalType, index) => {
    const SpecificModal = MODAL_COMPONENTS[modalType];
    const args = allArgs[modalType] || {};

    return (
      <Modal
        dimmer={!args.dimmer ? false : args.dimmer || 'blurring'}
        className="content-modal"
        closeOnDimmerClick={!args.closeOnDimmerClick ? false : undefined}
        closeOnEscape={!args.closeOnEscape ? false : undefined}
        open
        onClose={() => {
          hideModal();
          if (args.handleOnCloseModal) args.handleOnCloseModal();
        }}
        closeIcon={!args.hideCloseButton}
        size={args.size}
      >
        <SpecificModal {...args} key={modalType + index} />
      </Modal>
    );
  });

  return <span>{allModals}</span>;
};

const mapStateToProps = (state) => ({
  allArgs: getAllArgs(state),
  modalTypes: getAllModalTypes(state),
});

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
