import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StepWizard from 'react-step-wizard';
import FuturesComplianceQuestions from './FuturesComplianceQuestions';
import FuturesDisclaimers from './FuturesDisclaimers';
import FuturesOnboardingComplete from './FuturesOnboardingComplete';
import { Nav } from '../../../common/SimpleNav';
import { getLoggedInUserState } from '../../../reducers/userReducer';
import {
  ONBOARDED,
  FUTURES_UPGRADE_COMPLIANCE,
  FUTURES_UPGRADE_AGREEMENTS,
} from '../../../constants/userStates';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;
Wrapper.displayName = 'FuturesOnboardingWrapper';

const getInitialStep = (state) => {
  switch (state) {
    case ONBOARDED:
    case FUTURES_UPGRADE_COMPLIANCE:
      return 1;
    case FUTURES_UPGRADE_AGREEMENTS:
      return 2;
    default:
      return 1;
  }
};

const FuturesOnboardingModal = () => {
  const state = useSelector(getLoggedInUserState);

  return (
    <Wrapper>
      <StepWizard nav={<Nav />} initialStep={getInitialStep(state)}>
        <FuturesComplianceQuestions />
        <FuturesDisclaimers />
        <FuturesOnboardingComplete />
      </StepWizard>
    </Wrapper>
  );
};

export default FuturesOnboardingModal;
