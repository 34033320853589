import _ from 'lodash';
import { LOCATION_CHANGE, CALCULATE_RESPONSIVE_STATE } from './uiReducer';

/** Action Types */
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
export const TOGGLE_SIDEBAR_PANEL = 'TOGGLE_SIDEBAR_PANEL';

/** Initial State */
const initialState = {
  sidebarContent: null,
  activePanels: [],
};

/** sidebar reducer */
export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return { sidebarContent: action.sidebarContent, args: action.args };
    case HIDE_SIDEBAR:
    case LOCATION_CHANGE:
    case CALCULATE_RESPONSIVE_STATE:
      return {
        ...state,
        ...initialState,
        activePanels: state.activePanels,
      };
    case TOGGLE_SIDEBAR_PANEL:
      return {
        ...state,
        activePanels: action.activePanels,
      };
    default:
      return state;
  }
}

/** selectors */
// get content type in our sidebar currently
export const getSidebarContent = (state) =>
  _.get(state, ['ui', 'sidebar', 'sidebarContent'], null);
export const getSidebarActivePanels = (state) =>
  _.get(state, ['ui', 'sidebar', 'activePanels'], []);

// isOpen checks to see if we have any content in our sidebar,
//  then returns a boolean
export const isOpen = (state) => !!getSidebarContent(state);

/**
 * Action generator to show a sidebar based on a sidebarContent.
 * @param {String} sidebarContent - The name of component of the content to be shown in sidebar.
 */
export const showSidebar = (sidebarContent, args = {}) => ({
  type: SHOW_SIDEBAR,
  sidebarContent,
  args,
});

export const toggleSidebarPanel = (activePanels) => ({
  type: TOGGLE_SIDEBAR_PANEL,
  activePanels,
});

export const updateSidebarActivePanels = (sidebarContent, args = {}) => ({
  type: SHOW_SIDEBAR,
  sidebarContent,
  args,
});

/**
 * Action generator to hide the sidebar.
 */
export const hideSidebar = () => ({
  type: HIDE_SIDEBAR,
});
