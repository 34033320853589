export const DIRECT_MEMBER_TYPE = 'direct';
export const FIRM_MEMBER_TYPE = 'firm';
export const VENDOR_MEMBER_TYPE = 'vendor';
export const EXECUTION_BROKER_MEMBER_TYPE = 'execution_broker';
export const INVESTMENT_MANAGER_MEMBER_TYPE = 'investment_manager';
export const FCM_MEMBER_TYPE = 'fcm';
export const INTERMEDIARY_MEMBER_TYPE = 'intermediary';

export const INVESTMENT_ADVISOR_MEMBER = {
  name: 'Investment Advisor',
  type: INVESTMENT_MANAGER_MEMBER_TYPE,
  code: 'IA',
};
export const DIRECT_MEMBER = {
  name: 'Direct Member',
  type: DIRECT_MEMBER_TYPE,
  code: 'IN',
};
export const FIRM_MEMBER = {
  name: 'Firm Member',
  type: FIRM_MEMBER_TYPE,
  code: 'EN',
};
export const VENDOR_MEMBER = {
  name: 'Vendor Member',
  type: VENDOR_MEMBER_TYPE,
  code: 'VM',
};
export const EXECUTION_BROKER_MEMBE = {
  name: 'Executing Broker',
  type: EXECUTION_BROKER_MEMBER_TYPE,
  code: 'EB',
};
export const INTERMEDIARY = {
  name: 'Intermediary',
  type: INTERMEDIARY_MEMBER_TYPE,
  code: 'IM',
};
export const FCM = { name: 'FCM', type: FCM_MEMBER_TYPE, code: 'FCM' };

export const MEMBER_TYPES_LIST = [
  INVESTMENT_ADVISOR_MEMBER,
  DIRECT_MEMBER,
  FIRM_MEMBER,
  VENDOR_MEMBER,
  EXECUTION_BROKER_MEMBE,
  INTERMEDIARY,
  FCM,
];
