import React, { Fragment } from 'react';
import { isEmpty, get, find } from 'lodash';
import { connect } from 'react-redux';
import { Segment, Form, Button, Label, Icon, Header } from 'semantic-ui-react';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Big from 'bignumber.js';
import moment from 'moment';
import {
  format,
  fundsDesignation,
  NON_SEG,
  renderDropdown,
} from 'erisxkit/client';
import hasMoreThanNDigits from '../../utils/hasMoreThanNDigits';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import { floatsOnly } from '../../common/forms/normalization';
import { isViewportLessThan } from '../../reducers/uiReducer';
import { getLinkedAccountLabel } from '../../reducers/linkedAccountsReducer';
import { lastPrice } from '../../reducers/assetTypesReducer';
import {
  PRECISION_CRYPTO_APPROX,
  PRECISION_USD,
} from '../../constants/precision';
import AssetTypeField from '../../common/components/AssetTypeField';
import BchMessage from '../../common/components/BchMessage';
import AvailableFundsHeader from './AvailableFundsHeader';
import { WITHDRAWAL } from '../../constants/journalTypes';
import { formatFiat } from '../../utils/methods';
import IraAccountDisclaimer from '../../common/components/IraUserDisclaimer';
import AccessControl from '../../common/AccessControl';
import { grants, subjects } from '../../constants/userPermissions';
import WithdrawDisclaimer from '../../common/components/WithdrawDisclaimer';
import { getActiveOrDefaultAccountFD } from '../../reducers/activeAccountSelectors';

const addressOptionsByAssetType = (
  linkedCryptoAddresses = [],
  selectedAssetType,
) =>
  linkedCryptoAddresses
    .filter(
      ({ assetType }) =>
        assetType.toUpperCase() === selectedAssetType.toUpperCase(),
    )
    .map((address) => ({
      key: address.id,
      value: address.id,
      text: getLinkedAccountLabel(address),
    }));

let CryptoWithdrawal = ({
  accountCode,
  achWithdrawalLimit,
  feeParams,
  assetTypeWithFundsDesignation,
  currencyOptions,
  fetchLimit,
  fetchFeeParams,
  firmCode,
  linkedCryptoAddresses,
  linkedCryptoAddressesLoading,
  confirmWithdrawal,
  destinationId,
  addNew,
  assetTypes,
  assetType,
  invalid,
  handleSubmit,
  lastPrices = [],
  disabledAssets,
  change,
  initialize,
  loadingLimits,
  activeAccountFD,
}) => {
  // select only given option by default, if applicable
  // only run if we don't have anything selected for asset type, which we should by default.
  // we need this condition to prevent running on every re-render.
  if (currencyOptions.length === 1 && isEmpty(assetTypeWithFundsDesignation)) {
    initialize({ assetTypeWithFundsDesignation: currencyOptions[0].value });
    change('assetType', currencyOptions[0].value);
    change('fundsDesignation', activeAccountFD);
    fetchLimit(currencyOptions[0].value, activeAccountFD);
    fetchFeeParams(currencyOptions[0].value);
  }

  const invalidAssetMapping =
    assetType && disabledAssets
      ? disabledAssets[assetType.toLowerCase()]
      : undefined;

  const invalidAssetMessage = invalidAssetMapping
    ? invalidAssetMapping.disabledWithdrawalMessage
    : '';

  const isInvalidAsset = invalidAssetMessage != '';
  const lastPricePx = get(lastPrices, [`${assetType}/USD`, 'px'], 0);

  return (
    <Fragment>
      <section className="overlay-form ui text container">
        <Header as="h2">
          {'Cryptocurrency Withdrawal from account '}{' '}
          <span className="bold">{`${
            firmCode ? `${firmCode}-` : ''
          }${accountCode}`}</span>
          <Header.Subheader>
            <AvailableFundsHeader
              amountLimit={achWithdrawalLimit}
              loadingLimits={loadingLimits}
              action={WITHDRAWAL}
              lastPricePx={lastPricePx}
              requireAsset
            />
          </Header.Subheader>
        </Header>
        <Form onSubmit={handleSubmit(confirmWithdrawal)}>
          <Form.Field>
            <AssetTypeField
              change={change}
              currencyOptions={currencyOptions}
              handleChange={() => {}}
              disabled={loadingLimits}
              postChange={(at, fd) => {
                if (at) {
                  lastPrice({ symbol: `${at}/USD`, time: moment().format() });
                  fetchLimit(at, fd);
                  fetchFeeParams(at);
                }
              }}
              placeholder="Select an asset type to withdraw"
              data-cy="crypto-withdrawal-asset-type-field"
            />
          </Form.Field>
          <Form.Field>
            <Field
              required
              component={renderDropdown}
              label="Destination Address"
              data-cy="crypto-withdrawal-address-dropdown"
              placeholder={
                assetType &&
                isEmpty(
                  addressOptionsByAssetType(linkedCryptoAddresses, assetType),
                ) &&
                !linkedCryptoAddressesLoading
                  ? `No crypto addresses found for ${assetType}. Please link one below.`
                  : 'Destination Address'
              }
              fluid
              selection
              loading={linkedCryptoAddressesLoading}
              disabled={
                !assetType ||
                isEmpty(
                  addressOptionsByAssetType(linkedCryptoAddresses, assetType),
                )
              }
              name="destinationId"
              options={addressOptionsByAssetType(
                linkedCryptoAddresses,
                assetType,
              )}
              validate={rules.required}
            />
          </Form.Field>
          <Form.Field>
            {/* FIXME: Quick hack to stop form from submitting */}
            <AccessControl
              allowedPermissions={[
                `${grants.CREATE}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
              ]}
            >
              <a
                className="bold"
                name="addNewAddress"
                onClick={() => addNew(assetType)}
                data-cy="add-crypto-address-withdraw"
              >
                Add a new crypto address
              </a>
            </AccessControl>
          </Form.Field>
          <Form.Group widths="equal">
            <Field
              fluid
              className="mono"
              component={renderField}
              placeholder="Amount to withdraw"
              name="amount"
              parse={floatsOnly}
              disabled={!destinationId || !assetType || isInvalidAsset}
              validate={[
                rules.required,
                rules.availableLessFeeExceeded,
                rules.positiveNumbers,
              ]}
              labelPosition="right"
              onChange={(e, value) => {
                if (
                  hasMoreThanNDigits(
                    value,
                    get(
                      find(assetTypes, { symbol: assetType }),
                      'precision',
                      16,
                    ),
                  )
                ) {
                  e.preventDefault();
                  return;
                }

                const crypto = Big(value || '0');
                let fiat = crypto.times(Big(lastPricePx));
                change(
                  'notional_amount',
                  formatFiat(fiat.toFixed(PRECISION_USD), 'USD'),
                );
                const feeParam = feeParams[assetType];

                if (crypto.eq(Big(0)) || isEmpty(feeParam)) {
                  change('fee', 'Withdrawal fee');
                  change('notional_fee', 'Notional fee');
                  return;
                }
                let fee = crypto
                  .times(Big(feeParam.scaledParam))
                  .plus(feeParam.flatParam);
                if (fee.lt(Big(feeParam.minFee))) fee = Big(0);
                change('fee', fee.toFixed(parseInt(feeParam.maxDigits)));
                fiat = fee.times(Big(lastPricePx));
                change(
                  'notional_fee',
                  formatFiat(fiat.toFixed(PRECISION_USD), 'USD'),
                );
              }}
            />
            <Label circular size="massive">
              &asymp;
            </Label>
            <Field
              fluid
              disabled
              className="mono"
              component={renderField}
              placeholder="Notional amount"
              name="notional_amount"
              parse={floatsOnly}
              label={{ basic: true, content: 'USD' }} // FIXME: don't hardcode USD!
              labelPosition="right"
              validate={[rules.positiveNumbers]}
              onChange={(e, value) => {
                let fiat = Big(value || '0');
                const crypto = fiat.dividedBy(Big(lastPricePx));
                const feeParam = feeParams[assetType];
                const maxDigits = isEmpty(feeParam)
                  ? PRECISION_CRYPTO_APPROX
                  : parseInt(feeParam.maxDigits);
                change('amount', crypto.toFixed(maxDigits));
                if (crypto.eq(Big(0)) || isEmpty(feeParam)) {
                  change('fee', 'Withdrawal fee');
                  change('notional_fee', 'Notional fee');
                  return;
                }
                let fee = crypto
                  .times(Big(feeParam.scaledParam))
                  .plus(feeParam.flatParam);
                if (fee.lt(Big(feeParam.minFee))) fee = Big(0);
                change('fee', fee.toFixed(maxDigits));
                fiat = fee.times(Big(lastPricePx));
                change(
                  'notional_fee',
                  formatFiat(fiat.toFixed(PRECISION_USD), 'USD'),
                );
              }}
            />
          </Form.Group>
          <Label circular size="massive">
            +
          </Label>
          <Form.Group widths="equal">
            <Field
              className="mono"
              component={renderField}
              placeholder="Withdrawal fee"
              name="fee"
              readOnly
              disabled={!destinationId || !assetType || isInvalidAsset}
            />
            <Label circular size="massive">
              &asymp;
            </Label>
            <Field
              className="mono"
              component={renderField}
              placeholder="Notional fee"
              name="notional_fee"
              readOnly
              label={{ basic: true, content: 'USD' }} // FIXME: don't hardcode USD!
              labelPosition="right"
              disabled={
                !destinationId ||
                !assetType ||
                Big(lastPricePx).isLessThanOrEqualTo(0) ||
                isInvalidAsset
              }
            />
          </Form.Group>
          <WithdrawDisclaimer />
          <IraAccountDisclaimer />
          {isInvalidAsset ? (
            <Segment>
              <h4>
                <Icon name="info circle" />
                {invalidAssetMessage}
              </h4>
            </Segment>
          ) : (
            <div className="ach-confirm">
              <Button
                primary
                disabled={invalid}
                data-cy="crypto-withdrawal-submit"
              >
                Submit Withdrawal
              </Button>
            </div>
          )}
          <BchMessage assetType={assetType} />
        </Form>
      </section>
    </Fragment>
  );
};

CryptoWithdrawal.propTypes = {
  assetType: PropTypes.string,
  assetTypeWithFundsDesignation: PropTypes.string,
  amount: PropTypes.string,
  balances: PropTypes.arrayOf(PropTypes.object),
  lastPrice: PropTypes.string,
  linkedCryptoAddresses: PropTypes.arrayOf(PropTypes.object),
  linkedCryptoAddressesLoading: PropTypes.bool,
  fetchLimit: PropTypes.func,
  fetchFeeParams: PropTypes.func,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  disabledAssets: PropTypes.object,
  loadingLimits: PropTypes.bool,
  activeAccountFD: PropTypes.string,
};

CryptoWithdrawal.defaultProps = {
  assetType: '',
  assetTypeWithFundsDesignation: '',
  amount: '',
  balances: [],
  fetchLimit: () => {},
  fetchFeeParams: () => {},
  lastPrice: PropTypes.string,
  linkedCryptoAddresses: [],
  linkedCryptoAddressesLoading: false,
  medium: false,
  small: false,
  disabledAssets: {},
  loadingLimits: false,
  activeAccountFD: '',
};

CryptoWithdrawal = reduxForm({
  form: 'cryptoWithdrawal',
})(CryptoWithdrawal);

const selector = formValueSelector('cryptoWithdrawal');

const mapStateToProps = (state) => ({
  assetType: selector(state, 'assetType'),
  assetTypeWithFundsDesignation: selector(
    state,
    'assetTypeWithFundsDesignation',
  ),
  destinationId: selector(state, 'destinationId'),
  amount: selector(state, 'amount'),
  medium: isViewportLessThan('large', state),
  small: isViewportLessThan('medium', state),
  notional: selector(state, 'notional'),
  activeAccountFD: getActiveOrDefaultAccountFD(state),
});

const mapDispatchToProps = {
  lastPrice,
  change,
};

export default connect(mapStateToProps, mapDispatchToProps)(CryptoWithdrawal);
