import styled from 'styled-components';

const StyledHeader = styled.header<{ loading: boolean }>`
  background-color: ${({ theme, loading }) =>
    loading ? theme.body : theme.topnav.bg};
  box-shadow: ${({ theme, loading }) => !loading && theme.topnav.boxShadow};
  height: 60px;
`;

export default StyledHeader;
