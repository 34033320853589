import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { ColumnMetadata } from '../common/EMPTable8/types';
import {
  ContractView,
  PositionSummary,
} from '../ts/models/Positions/PositionSummary';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MetaData } from './../common/EMPTable8/types';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { formatAccountingValue } from '../utils/formatters';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { formatFiat } from '../utils/methods';

const parseNet = (long: string | number, short: string | number) => {
  const value = long - short;
  return isNaN() ? '' : value;
};

const columnHelper = createColumnHelper<ContractView>();

const marginPositionsMetadata = (): ColumnDef<ContractView>[] => [
  columnHelper.group({
    id: 'group1',
    header: '',
    columns: [
      columnHelper.display({
        id: 'expandProducts',
        size: 20,
        meta: {
          expandOptions: {
            canExpand: true,
            depth: [0, 1],
          },
        },
      }),
      columnHelper.accessor('productCode', {
        id: 'productCode',
        header: 'Product Code',
        minSize: 100,
      }),
      columnHelper.accessor('contractCode', {
        id: 'contractCode',
        header: 'Contract Code',
        minSize: 100,
      }),
      columnHelper.accessor('maturityDate', {
        id: 'maturityDate',
        header: 'Maturity Date',
        minSize: 115,
        meta: {
          filterType: TableFilterType.Date,
        },
      }),
      columnHelper.accessor('accountLabel', {
        id: 'accountLabel',
        header: 'Account',
        minSize: 85,
        meta: {
          filterType: TableFilterType.String,
        },
      }),
      columnHelper.accessor('cgmNumber', {
        id: 'cgm',
        header: 'CGM',
        minSize: 65,
        meta: {
          filterType: TableFilterType.String,
        },
      }),
    ],
  }),

  // start of day gross
  columnHelper.group({
    id: 'startOfDayGross',
    header: 'Start of Day (Gross)',
    columns: [
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(
            get(row, 'summary.startOfDayGross.long', ''),
            true,
          ),
        {
          id: 'sodGrossLong',
          header: 'Long',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Gross (Long)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(
            get(row, 'summary.startOfDayGross.short', ''),
            true,
          ),
        {
          id: 'sodGrossShort',
          header: 'Short',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Gross (Short)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          const long = get(row, 'summary.startOfDayGross.long', null);
          const short = get(row, 'summary.startOfDayGross.short', null);
          const net = parseNet(long, short);
          return formatAccountingValue(net, true);
        },
        {
          id: 'sodGrossNet',
          header: 'Net',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Gross (Net)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
    ],
  }),
  columnHelper.group({
    id: 'startOfDayNet',
    header: 'Start of Day (Net)',
    columns: [
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(
            get(row, 'summary.startOfDayNet.long', ''),
            true,
          ),
        {
          id: 'sodNetLong',
          header: 'Long',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Net (Long)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(
            get(row, 'summary.startOfDayNet.short', ''),
            true,
          ),
        {
          id: 'sodNetShort',
          header: 'Short',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Net (Short)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          const long = get(row, 'summary.startOfDayNet.long', null);
          const short = get(row, 'summary.startOfDayNet.short', null);
          const net = parseNet(long, short);
          return formatAccountingValue(net, true);
        },
        {
          id: 'sodNetNet',
          header: 'Net',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Start Of Day Net (Net)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
    ],
  }),
  columnHelper.group({
    id: 'topDay',
    header: 'Top of Day',
    columns: [
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(get(row, 'summary.topDay.long', ''), true),
        {
          id: 'topDayLong',
          header: 'Long',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Top of Day (Long)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(get(row, 'summary.topDay.short', ''), true),
        {
          id: 'topDayShort',
          header: 'Short',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Top of Day (Short)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          const long = get(row, 'summary.topDay.long', null);
          const short = get(row, 'summary.topDay.short', null);
          const net = parseNet(long, short);
          return formatAccountingValue(net, true);
        },
        {
          id: 'topDayNet',
          header: 'Net',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Top of Day (Net)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
    ],
  }),
  columnHelper.group({
    id: 'final',
    header: 'Final',
    columns: [
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(get(row, 'summary.final.long', ''), true),
        {
          id: 'finalLong',
          header: 'Long',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Final (Long)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) =>
          formatAccountingValue(get(row, 'summary.final.short', ''), true),
        {
          id: 'finalShort',
          header: 'Short',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Final (Short)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor(
        (row) => {
          const long = get(row, 'summary.final.long', null);
          const short = get(row, 'summary.final.short', null);
          const net = parseNet(long, short);
          return formatAccountingValue(net, true);
        },
        {
          id: 'finalNet',
          header: 'Net',
          maxSize: 100,
          minSize: 50,
          meta: {
            exportHeader: 'Final (Net)',
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
    ],
  }),
  columnHelper.group({
    id: 'group2',
    header: '',
    columns: [
      columnHelper.accessor(
        (row) => formatFiat(get(row, 'summary.variationMargin', ''), 'USD'),
        {
          id: 'variationMargin',
          header: 'Variation Margin',
          minSize: 130,
          meta: {
            filterType: TableFilterType.Number,
            align: TableCellAlignment.Right,
            className: 'mono',
          },
        },
      ),
      columnHelper.accessor((row) => get(row, 'summary.assetType', ''), {
        id: 'assetType',
        header: 'Asset Type',
        minSize: 100,
        meta: {
          filterType: TableFilterType.String,
        },
      }),
    ],
  }),
];

export default marginPositionsMetadata;
