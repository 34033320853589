import React from 'react';
import { Icon } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import colors from '../constants/colors';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  content: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
};

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.infoTooltip} !important;
`;

const useStyles = makeStyles({
  tooltip: {
    fontSize: '1em',
    backgroundColor: `${colors.GRAY_2}`,
    color: `${colors.NAVY}`,
    '& .MuiTooltip-arrow': {
      color: `${colors.GRAY_2}`,
    },
  },
});

const InformationTooltip = ({ content }: Props) => {
  const classes = useStyles();

  return (
    <Tooltip
      disableFocusListener
      arrow
      title={content}
      leaveTouchDelay={400}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledIcon name="info circle" inverted />
    </Tooltip>
  );
};

export default InformationTooltip;
