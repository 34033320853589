import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import { XTable } from 'erisxkit/client';

const AccountHistory = ({
  recentTransactions = [],
  loading,
  metadata,
  assetTypes,
  assetTypesLoading,
  viewportLessThanSmall,
  filter,
  accountId,
  account,
  accounts,
}) => (
  <Fragment>
    <Header
      content="Recent Transactions"
      subheader="See the recent activity of this account."
    />
    <XTable
      title="accountHistory"
      className="dark"
      showPageSizeOptions={false}
      showPagination={false}
      data={recentTransactions}
      columns={metadata(viewportLessThanSmall, assetTypes, filter, accounts)}
      loading={loading}
      minRows={5}
      small={viewportLessThanSmall}
    />
  </Fragment>
);

export default AccountHistory;
