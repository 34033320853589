import React from 'react';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import { NavLink } from 'react-router-dom';

const StyledChip = styled(Chip)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  display: flex;
`;

type Props = {
  values?: string[];
  allowLink?: boolean;
  linkToURL?: string;
};

const TagList = ({ values = [], allowLink = false, linkToURL = '' }: Props) => {
  return (
    <Wrapper>
      {values.map((value) => (
        allowLink ?
          <NavLink
            target="_blank"
            className="flex"
            key={value}
            to={`${linkToURL}${value}`}
          >
            <StyledChip size="small" label={value} key={value} />
          </NavLink>
          :
          <StyledChip size="small" label={value} key={value} />
      ))}
    </Wrapper>
  );
};

export default TagList;
