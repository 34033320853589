import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography } from '@material-ui/core';
import { hideModal } from 'erisxkit/client';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchFieldAdornment from '../../common/forms/SearchField';
import colors from '../../constants/colors';
import {
  getAllAccountsList,
  selectAccount,
} from '../../reducers/accountsReducer';
import history from '../../constants/history';

const cardStyles = makeStyles({
  root: {
    background: `${colors.MEDIUM_NAVY}`,
    width: '100%',
    height: '100%',
    padding: '0px 5px',
    color: 'unset',
    borderLeft: `5px solid ${colors.GREEN}`,
    '&:last-child': {
      paddingBottom: '0px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const contentStyles = makeStyles({
  root: {
    padding: '0px',
    width: '100%',
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  header: {
    marginBottom: '.8rem',
  },
});

const CardContainer = styled.div`
  width: 90%;
  height: 100px;
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  transition: all 0.2s;
  @media (max-width: 490px) {
    width: 100%;
  }
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    color: #4ccead;
    transform: scale(1) translateY(2 px);
    box-shadow:
      0 1px 0 0 #4ccead,
      0 0 0 1px #4ccead;
    border: none;
    font-weight: 400;
    transform: scale(1);
  }
`;
CardContainer.displayName = 'CardContainer';

const AccountName = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin: 0px;
`;
const AccountLabel = styled.p`
  text-align: left;
  margin: 0px;
  color: ${colors.GRAY_1};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;
Wrapper.displayName = 'Wrapper';

const SearchField = styled(TextField)`
  width: 80%;
  margin-left: 10px !important;
`;
SearchField.displayName = 'SearchField';

const AccountCard = ({ card, content, account, dispatch, onAccountClick }) => (
  <CardContainer
    onClick={() => {
      dispatch(hideModal());
      dispatch(selectAccount(account.accountId));
      onAccountClick({ accountId: account.accountId });
    }}
  >
    <Card className={card.root} elevation={10}>
      <CardContent className={content.root}>
        <AccountName>{account.name}</AccountName>
        <AccountLabel>{account.label}</AccountLabel>
      </CardContent>
    </Card>
  </CardContainer>
);

export default ({ onAccountClick }) => {
  const allAccounts = useSelector(getAllAccountsList);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const card = cardStyles();
  const content = contentStyles();

  let filteredAccounts = allAccounts;

  if (searchTerm) {
    filteredAccounts = allAccounts.filter(
      (acc) =>
        acc.label?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        acc.name?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  return (
    <Wrapper key="account_selection_wrapper">
      <Typography
        key="account_selection_title"
        variant="h5"
        className={content.header}
      >
        Select an account to continue...
      </Typography>
      <SearchField
        key="account_selection_search_field"
        label="Search Accounts"
        type="text"
        endAdornment={SearchFieldAdornment}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        autofocus
        defaultValue={searchTerm}
      />
      {filteredAccounts.map((acc) => (
        <AccountCard
          onAccountClick={onAccountClick}
          key={acc.label}
          card={card}
          content={content}
          account={acc}
          dispatch={dispatch}
        />
      ))}
    </Wrapper>
  );
};
