import React, { useState } from 'react';
import { getNthFilterInitialValue } from '../utils/utils';
import { Col, FilterContainer, Input, Label } from './styled';
import FilterActions from './FilterActions';
import { TableFilterProps } from './TableFilter';
import { ServerSideFilter } from '../types';
import TableFilterType from '../../../ts/enums/TableFilterType.enum';

/*
  Filters values according to a numeric range.
  If no lower/upper bound is set, it will filter by the only one provided
  @prop - column: column metadata which contains applied filter
  @prop - local:  change functionality if table is filtering locally
*/
const NumberFilter = ({
  column,
  local,
  type = TableFilterType.Number,
}: TableFilterProps<any>) => {
  const columnFilterValue = column.getFilterValue();
  // State for min input
  const initialMin = getNthFilterInitialValue(columnFilterValue, local, 0);

  const [min, setMin] = useState<number | string>(initialMin);
  // State for max input
  const initialMax = getNthFilterInitialValue(columnFilterValue, local, 1);
  const [max, setMax] = useState<number | string>(initialMax);

  const onApply = () => {
    // Short circuit if the user did not provide any filter
    if (!min && min !== 0 && !max && max !== 0) return;

    if (local) {
      column.setFilterValue([min, max]);
    } else {
      const filters: ServerSideFilter[] = [];
      if (min) filters.push({ attr: column.id, value: min, op: 'gte' });
      if (max) filters.push({ attr: column.id, value: max, op: 'lte' });
      column.setFilterValue(filters);
    }
  };

  const onClear = () => {
    column.setFilterValue();
    setMin('');
    setMax('');
  };

  const parse = (value: string) => {
    if (type === TableFilterType.Currency) return parseFloat(value);
    else return parseInt(value, 10);
  };

  return (
    <Col>
      <FilterContainer>
        <Label>Min:</Label>
        <div>
          <Input
            type="number"
            autoComplete="new-password"
            value={min}
            onChange={(e) => setMin(parse(e?.target?.value))}
            placeholder="Enter a number"
            name="min-value"
          />
        </div>
      </FilterContainer>
      <FilterContainer>
        <Label>Max:</Label>
        <div>
          <Input
            type="number"
            autoComplete="new-password"
            value={max}
            onChange={(e) => setMax(parse(e?.target?.value))}
            placeholder="Enter a number"
            name="max-value"
          />
        </div>
        <FilterActions onApply={onApply} onClear={onClear} />
      </FilterContainer>
    </Col>
  );
};

export default NumberFilter;
