import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { createLoadingSelector } from 'erisxkit/client';
import moment from 'moment';
import { exportMemberFile } from '../reducers/utilitiesReducer';
import MemberFiles from '../components/MemberFiles';
import {
  someAccountEnabledForFutures,
  getAllAccountsList,
} from '../reducers/accountsReducer';
import { getSelectorAsOptions } from '../utils/selectors';

const mapDispatchToProps = {
  exportMemberFile,
};

const mapStateToProps = (state) => ({
  accountsOptions: getSelectorAsOptions(getAllAccountsList, {
    key: 'accountId',
    text: ({ accountCode, firmCode }) => `${firmCode}-${accountCode}`,
    description: 'name',
    value: 'accountId',
  })(state),
  fileExportLoading: createLoadingSelector(['EXPORT_MEMBER_FILE'])(state),
  enabledForFutures: someAccountEnabledForFutures(state),
  userPermissions: state.user && state.user.uiViews,
});

export const REPORT_TYPE_FIELD_NAME = 'report';
export const DATE_TYPE_FIELD_NAME = 'date';
export const END_DATE_TYPE_FIELD_NAME = 'endDate';
export const ACCOUNT_ID_TYPE_FIELD_NAME = 'accountId';

class MemberFilesContainer extends Component {
  state = {};

  shouldComponentUpdate = (nextProps, nextState) => {
    if (isEqual(this.props, nextProps) && isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  };

  onChange = (e, { name, value }) => {
    if (name === REPORT_TYPE_FIELD_NAME) {
      // Since not all reports allow for date ranges/multiple account ids
      // When changing report type we restore those options to default
      this.setState({
        [name]: value,
        accountId: undefined,
        endDate: undefined,
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleDateChange = ([date, endDate]) => {
    this.setState({
      date: moment(date).format('YYYY-MM-DD'),
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    });
  };

  // return promise for when member file is fetched
  handleExport = () => this.props.exportMemberFile(this.state);

  render = () => {
    return (
      <MemberFiles
        accountsOptions={this.props.accountsOptions}
        date={this.state.date}
        endDate={this.state.endDate}
        handleDateChange={this.handleDateChange}
        handleExport={this.handleExport}
        onChange={this.onChange}
        loading={this.props.fileExportLoading}
        enabledForFutures={this.props.enabledForFutures}
        valid={this.state.date && this.state.report}
        userPermissions={this.props.userPermissions}
        report={this.state.report}
        accountId={this.state.accountId}
      />
    );
  };
}

MemberFilesContainer.propTypes = {
  enabledForFutures: PropTypes.bool.isRequired,
  exportMemberFile: PropTypes.func.isRequired,
  fileExportLoading: PropTypes.bool,
};

MemberFilesContainer.defaultProps = {
  fileExportLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberFilesContainer);
