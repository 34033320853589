import React, { useCallback } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { showModal, hideModal, createLoadingSelector } from 'erisxkit/client';
import { TradingParticipant } from '../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import StyledCheckbox from '../../../common/StyledCheckbox';
import { GENERIC_MODAL } from '../../../constants/modalTypes';
import { GenericModalProps } from '../../../common/components/GenericModal';
import {
  disableBlockTradesPromise,
  enableBlockTradesPromise,
  fetchTradingParticipants,
} from '../../../reducers/participantManagementReducer';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getActiveAccountLabel } from '../../../reducers/activeAccountSelectors';
import { getActiveMemberFirmCode } from '../../../reducers/membersReducer';

const Checkbox = styled(StyledCheckbox)`
  /* margin-left: auto;
  margin-right: auto; */
`;

const BlockTradeCheckbox: React.FC<{ participant: TradingParticipant }> = ({
  participant,
}) => {
  const dispatch = useDispatch();
  const activeAccountLabel = useAppSelector(getActiveAccountLabel);
  const fcmFirmCode = useAppSelector(getActiveMemberFirmCode);
  const tradingParticipantFirmCode = participant?.participantFirmCode;

  const onSubmit = useCallback(
    async (isAllowing: boolean) => {
      try {
        if (isAllowing) {
          await enableBlockTradesPromise(
            {
              fcmFirmCode,
              tradingParticipantFirmCode,
            },
            dispatch,
          );
        } else {
          await disableBlockTradesPromise(
            {
              fcmFirmCode,
              tradingParticipantFirmCode,
            },
            dispatch,
          );
        }
        dispatch(hideModal());
      } catch (error) {
        dispatch(hideModal());
      }
      dispatch(
        fetchTradingParticipants({accountLabel: activeAccountLabel }),
      );
    },
    [activeAccountLabel, dispatch, fcmFirmCode, tradingParticipantFirmCode],
  );

  const onClick = useCallback(() => {
    // If it's already true it's disallowing. If it's false it's allowing
    const isAllowing = !participant?.fcms?.[0]?.participantEnabledForBlockTrades;
    const action = isAllowing ? 'Allow' : 'Disallow';
    const header = `${action} Block Trades`;
    const content = (
      <span>
        {`You are about to ${
          isAllowing ? 'allow' : 'disallow'
        } Block Trades for `}
        participant: <b>{participant?.participantName}</b>
      </span>
    );

    dispatch(
      showModal(GENERIC_MODAL, {
        header,
        content,
        cancelProps: {
          text: 'Cancel',
          onClick: () => dispatch(hideModal()),
        },
        confirmProps: {
          text: action,
          onClick: () => onSubmit(isAllowing),
        },
      } as GenericModalProps),
    );
  }, [dispatch, onSubmit, participant]);

  return isEmpty(participant) ? null : (
    <Checkbox checked={participant?.fcms?.[0]?.participantEnabledForBlockTrades} onChange={onClick} />
  );
};

export default BlockTradeCheckbox;
