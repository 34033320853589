import _ from 'lodash';

import React, { Component } from 'react';
import { WithdrawalConfirm } from 'erisxkit/client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';

import { hideModal } from 'erisxkit/client';
import {
  createFiatWithdrawalRequestPromiseCreator,
  createCryptoWithdrawalRequest,
  getWithdrawalDetails,
} from '../../reducers/fundTransfersReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import history from '../../constants/history';

class WithdrawalConfirmContainer extends Component {
  onConfirm = (payload) => {
    this.props.hideModal();
    switch (this.props.type) {
      case 'fiat':
        this.props
          .createFiatWithdrawalRequestPromiseCreator(payload)
          .then(() => {
            history.push(`/accounts/${this.props.account.accountId}/movements`);
          });
        break;
      case 'crypto':
        this.props.createCryptoWithdrawalRequest(payload);
        break;
    }
  };

  render() {
    return (
      <WithdrawalConfirm
        {...this.props}
        onCancel={this.props.hideModal}
        onConfirm={this.onConfirm}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      hideModal,
      createCryptoWithdrawalRequest,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      createFiatWithdrawalRequestPromiseCreator,
    },
    dispatch,
  ),
});

const mapStateToProps = (state, ownProps) => ({
  account: getActiveAccount(state),
  values: getWithdrawalDetails(state, ownProps),
  amountMinUnit: _.get(state.app, 'amountMinUnit'),
  user: _.get(state, 'user', {}),
  selectedMemberId: getSelectedMemberId(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithdrawalConfirmContainer);
