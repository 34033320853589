import styled, { css } from 'styled-components';
import colors from '../constants/colors';

export const sizes = {
  TINY: '600px',
  SMALL: '900px',
  MEDIUM: '1200px',
  LARGE: '1800px',
  GIGANTIC: '2560px', //* Exact device width for QHD+ /
  LARGEST_MOBILE_SCREEN: '768px',
};

export const uiSegment = css`
  margin: 1.2em 0.6em;
  padding: 1em 1em;
  background-color: ${colors.MEDIUM_NAVY};
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.segment.separator};
`;
Separator.displayName = 'Separator';

export const DottedSeparator = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${colors.NAVY_SECTION_SEPARATOR};
`;
Separator.displayName = 'Separator';

export const cardStyle = css`
  border-radius: 5px !important;
  background-color: ${({ theme }) => theme.ui.segment.bg};
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%) !important;
`;

export const UISegment = styled.div`
  margin: 1.2em 0.6em;
  padding: 1em 1em;
  ${cardStyle};
  ${Separator} {
    margin: 20px 0px;
  }
`;
UISegment.displayName = 'UISegment';

export const UISection = styled.section`
  ${cardStyle};
`;

export const Input = styled.input`
  background-color: ${colors.NAVY};
  border-radius: 3px;
  border: ${(props) =>
    props.error ? `1px solid ${colors.RED_ERROR}` : 'none'};
  color: ${colors.GREEN};
  height: 30px;
  padding-left: 10px;
`;

export const SubHeader = styled.h3`
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: auto;
  align-self: end;
  color: ${({ theme }) => theme.text.subheader} !important;
`;
