import _ from 'lodash';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchACHDeposits,
  ACCOUNT_SCHEDULES,
  CREATE_FIAT_DEPOSIT_SCHEDULE,
  DELETE_FIAT_DEPOSIT_SCHEDULE,
  createFiatDepositSchedule,
  deleteFiatDepositSchedule,
} from '../reducers/ACHDepositsReducer';

export default function* watch() {
  yield takeEvery(
    fetchACHDeposits.TRIGGER,
    createSaga(fetchACHDeposits, ACCOUNT_SCHEDULES),
  );
  yield takeLatest(
    createFiatDepositSchedule.TRIGGER,
    createSaga(
      createFiatDepositSchedule,
      CREATE_FIAT_DEPOSIT_SCHEDULE,
      'Adding a new Deposit Schedule...',
    ),
  );
  yield takeLatest(
    deleteFiatDepositSchedule.TRIGGER,
    createSaga(
      deleteFiatDepositSchedule,
      DELETE_FIAT_DEPOSIT_SCHEDULE,
      'Deleting Deposit Schedule...',
    ),
  );
}
