import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Table, Header, Icon } from 'semantic-ui-react';

const BankInformation = ({ bankDetails, bankDetailsLoading }) => (
  <Segment padded>
    <Header>
      <Icon name="university" className="small" />
      <Header.Content className="bold">
        Cboe Digital {bankDetails.recipientBank} account
      </Header.Content>
    </Header>
    <Segment>
      <Table basic="very" collapsing loading={bankDetailsLoading}>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="bold" width={4}>
              Routing/ABA #
            </Table.Cell>
            <Table.Cell width={12}>{bankDetails.routingNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">Account #</Table.Cell>
            <Table.Cell>{bankDetails.accountNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">Recipient Name</Table.Cell>
            <Table.Cell>{bankDetails.recipientName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">Recipient Address</Table.Cell>
            <Table.Cell>{bankDetails.recipientAddress}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">Recipient Bank</Table.Cell>
            <Table.Cell>{bankDetails.recipientBank}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">Bank Address</Table.Cell>
            <Table.Cell>{bankDetails.bankAddress}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  </Segment>
);

BankInformation.propTypes = {
  bankDetails: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  bankDetailsLoading: PropTypes.bool,
};

BankInformation.defaultProps = {
  bankDetails: [],
  bankDetailsLoading: false,
};

export default BankInformation;
