import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Header, Form, Icon, Checkbox } from 'semantic-ui-react';
import { Field, reduxForm, getFormValues, reset } from 'redux-form';
import {
  renderDropdown,
  showModal,
  createLoadingSelector,
} from 'erisxkit/client';
import { connect } from 'react-redux';
import { UISegment } from '../../styles/styled';
import { required } from '../../common/forms/rules';
import { TAX_STATEMENTS } from '../../constants/modalTypes';
import { getAllAccountsList } from '../../reducers/accountsReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import Account from '../../ts/models/Account.model';
import {
  fetchAvailableEStatements,
  getCurrentAccountHasEStatementsEnabled,
  fetchEStatementsStatus,
  setEStatementStatusPromise,
  getAvailableTaxEStatementYears,
  getAvailableTaxEStatements,
  downloadStatementsPromise,
  downloadStatements,
  AVAILABLE_TAX_ESTATEMENTS,
  AvailableReports,
} from '../../reducers/taxEStatementsReducer';
import downloadPDF from '../../utils/downloadPDF';

const StyledField = styled(Field)`
  width: 49%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterRow = styled(Row)`
  margin-bottom: 15px;
`;

const Actions = styled.div`
  display: flex;
  align-items: baseline;
`;

const Settings = styled(Icon)`
  margin-left: 20px !important;
  cursor: pointer;
`;

const Export = styled(Form.Button)`
  margin-top: 15px;
`;

type Props = {};

type TaxStatementsFormState = {
  range?: string;
  account?: string;
  type?: string;
};

const TAX_STATEMENTS_FORM = 'taxStatementsReports';

const TaxStatements = (props: Props) => {
  const dispatch = useDispatch();
  const account: Account = useSelector(getActiveAccount);
  const allAccounts: Account[] = useSelector(getAllAccountsList);
  const availableYears = useSelector(getAvailableTaxEStatementYears);

  const loadingAvailableStatements = useSelector((state) =>
    createLoadingSelector([AVAILABLE_TAX_ESTATEMENTS])(state),
  );
  const availableStatements: AvailableReports[] = useSelector(
    getAvailableTaxEStatements,
  );

  const values: TaxStatementsFormState = useSelector((state) =>
    getFormValues(TAX_STATEMENTS_FORM)(state),
  );

  const hasEStatementsEnabled = useSelector(
    getCurrentAccountHasEStatementsEnabled,
  );

  const reportTypes = useMemo(() => {
    if (values?.range) {
      const year = values.range;
      const reports = availableStatements.find((report) =>
        Object.keys(report).includes(year),
      );
      return get(reports, [`${year}`], []).map((file: string) => ({
        key: file,
        text: file,
        value: file,
      }));
    }
    return [];
  }, [values?.range]);

  const onSubmit = () => {};
  const openSettings = () =>
    dispatch(showModal(TAX_STATEMENTS, { showAccounts: true }));

  const getActiveAccountTaxStatus = () =>
    dispatch(
      fetchEStatementsStatus({
        urlParams: {
          accountId: account.accountId,
        },
      }),
    );

  const toggleEStatementStatus = (newStatus?: boolean) => {
    if (account)
      setEStatementStatusPromise(
        {
          accounts: [
            {
              accountId: account.accountId,
              taxEstatements: newStatus,
            },
          ],
        },
        dispatch,
      ).then(() => getActiveAccountTaxStatus());
  };

  const accountOptions = useMemo(
    () =>
      allAccounts.map((account) => ({
        value: get(account, 'accountId'),
        text: get(account, 'label'),
        key: get(account, 'accountId'),
      })),
    [allAccounts],
  );

  const handleExport = useCallback(() => {
    downloadStatementsPromise(
      {
        urlParams: {
          accountId: values.account,
          year: values.range,
          formName: values.type,
        },
      },
      dispatch,
    ).then((r) => {
      const doc = r?.taxEstatements;
      if (doc) downloadPDF(doc, `${values.type}`);
    });
  }, [values]);

  useEffect(() => {
    if (account) {
      getActiveAccountTaxStatus();
    }
  }, [account]);

  const isExportEnabled = useMemo(
    () =>
      !!get(values, 'range', null) &&
      !!get(values, 'account', null) &&
      !!get(values, 'type', null),
    [values],
  );

  return (
    <UISegment>
      <Row>
        <Header
          content="Tax e-Statements"
          subheader="Retrieve your tax statements online."
        />
        <Actions>
          {!!account && (
            <Checkbox
              id="toggle-estatements"
              className="advanced-view"
              toggle
              onChange={(e, v) => toggleEStatementStatus(get(v, 'checked'))}
              checked={hasEStatementsEnabled}
            />
          )}
          <Settings name="setting" size="big" onClick={openSettings} />
        </Actions>
      </Row>
      <Form onSubmit={onSubmit}></Form>
      <FilterRow>
        <StyledField
          component={renderDropdown}
          label="Report Type:"
          fluid
          required
          placeholder="Select a report Type"
          name="type"
          options={reportTypes}
          validate={required}
          disabled={
            !values?.account || !values?.range || loadingAvailableStatements
          }
          noResultsMessage="No results message"
        />
        <StyledField
          component={renderDropdown}
          label="Account:"
          fluid
          required
          placeholder="All accounts"
          name="account"
          options={accountOptions}
          validate={required}
          onChange={(accountId) => {
            dispatch(
              fetchAvailableEStatements({
                urlParams: {
                  accountId,
                },
              }),
            );
          }}
        />
      </FilterRow>
      <Field
        component={renderDropdown}
        label="Year Range:"
        fluid
        required
        placeholder="Enter range"
        name="range"
        options={availableYears}
        validate={required}
        disabled={
          !values?.account ||
          loadingAvailableStatements ||
          isEmpty(availableYears)
        }
      />
      <Export
        secondary
        content="Export Statement"
        name="exportButton"
        onClick={handleExport}
        disabled={!isExportEnabled}
      />
    </UISegment>
  );
};

const TaxStatementsForm = reduxForm({
  form: TAX_STATEMENTS_FORM,
})(TaxStatements);

export default TaxStatementsForm;
