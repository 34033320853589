import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { showModal, hideModal } from 'erisxkit/client';
import { GENERIC_MODAL, ADD_NEW_CGM } from '../constants/modalTypes';
import ModalContainer from '../containers/ModalContainer';
import { GenericModalProps } from '../common/components/GenericModal';
import {
  IRA_ACCOUNTS_ENDPOINT,
  INTERMEDIARIES_ENDPOINT,
} from '../constants/endpoints';

const MainDiv = styled.div`
  background-color: #002c46;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  height: auto;
  background-color: white;
  border-radius: 5px;
  width: 400px;
  height: 540px;
  margin: auto;
  text-align: center;
`;

const Header = styled.div`
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  color: #2d333a;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const BoldText = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #2d333a;
  text-align: center;
  margin-top: 70px;
`;

const Text = styled.p`
  width: 266px;
  margin: auto;
  color: #75757a;
  font-weight: bold;
`;

const IRAButton = styled(Button)`
  width: 60%;
  margin: auto !important;
  margin-top: 24px !important;
  display: block !important;
`;

const Link = styled.p`
  margin-top: 18px;
  color: #007bad;
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

const BotomText = styled.span`
  margin-top: 80px;
  color: black;
  display: inline-block;
`;

const StyledLink = styled(NavLink)`
  font-size: 15px;
  box-shadow: none;
  color: #007bad
  &:hover {
    cursor: pointer;
    box-shadow: none;
    color: #007bad
  }
`;

const StyledLinkBlack = styled(NavLink)`
  font-size: 15px;
  box-shadow: none;
  font-weight: normal;
  color: #333c4d;
  &:hover {
    cursor: pointer;
    box-shadow: none;
    color: #333c4d;
  }
`;

const Message = styled.div`
  width: ${(props) => (props.isMobile ? '100%' : '500px')};
  margin: auto;
  text-align: center;
`;
const StyledALink = styled.a`
  font-size: 15px;
  box-shadow: none;
  color: #007bad
  &:hover {
    cursor: pointer;
    box-shadow: none;
    color: #007bad
 }
`;

type Props = {
  className: string;
};

const ConfirmSignup = ({ className = '' }: Props) => {
  const dispatch = useDispatch();

  const showMessage = () => {
    dispatch(
      showModal(GENERIC_MODAL, {
        size: 'small',
        content: (
          <div>
            <div className="logo-container centered-content ">
              <div className="logo dm-logo" alt="ErisX" />
            </div>
            <Message isMobile={isMobile}>
              We have paused our direct individual (non-IRA) member onboarding
              at this time. We are working with our intermediary partners to
              enhance the individual trading and investing experience. Please
              check back{' '}
              <a target="_blank" href={INTERMEDIARIES_ENDPOINT}>
                here
              </a>{' '}
              for updates.
            </Message>
          </div>
        ),
        confirmProps: {
          text: 'Learn More',
          onClick: () => {
            window.location.href = 'https://www.cboedigital.com/intermediaries';
          },
        },
        marginBottom: '20px',
        loadingSelector: () => {},
      } as GenericModalProps),
    );
  };

  return (
    <MainDiv className={className}>
      <ModalContainer />
      <Container>
        <div className="logo-container centered-content ">
          <div className="logo fcm-logo" alt="ErisX" onClick={() => {}} />
        </div>
        <Header>Welcome</Header>
        <BoldText>IRA MEMBER SIGN UP</BoldText>
        <Text>
          You must have an open IRA account with one of
          <StyledALink target="_blank" href={IRA_ACCOUNTS_ENDPOINT}>
            {' '}
            these{' '}
          </StyledALink>
          providers in order to sign up
        </Text>
        <IRAButton primary id="confirm">
          <StyledLinkBlack to="/signup">I have an IRA account</StyledLinkBlack>
        </IRAButton>
        <Link onClick={showMessage}>I don't have an IRA account</Link>
        <BotomText>Aready have an Cboe account?</BotomText>{' '}
        <StyledLink
          activeClassName="active"
          className="sidebar-link"
          key="login"
          to="/login"
        >
          Log in
        </StyledLink>
      </Container>
    </MainDiv>
  );
};

export default ConfirmSignup;
