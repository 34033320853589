import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, ReducerMap } from 'redux-actions';
import { CarOption } from '../../ts/models/CAROption.model';
import { CAR } from '../../ts/models/CAR.model';
import { StyledSelectOption } from '../../common/StyledSelect';
import { RootState } from '../../ts/types/store';
import { getParticipantList } from '../participantManagementReducer';
import { sortBy } from 'lodash';
import {
  GET_CUSTOMER_ACCOUNT_REFERENCE,
  DELETE_CUSTOMER_ACCOUNT_REFERENCE,
  UPDATE_CUSTOMER_ACCOUNT_REFERENCE,
  LINK_CAR_TO_PARTICIPANT,
  UNLINK_CAR_TO_PARTICIPANT,
} from './carManagementActions';
import { TradingParticipant } from '../../ts/models/TradingParticipant/ApprovedParticipant.model';

//* actions */
export const fetchCar = createRoutine(GET_CUSTOMER_ACCOUNT_REFERENCE);
export const fetchCarList = createRoutine(
  'GET_CUSTOMER_ACCOUNT_REFERENCE_LIST',
);
export const createCar = createRoutine('CREATE_CUSTOMER_ACCOUNT_REFERENCE');
export const deleteCar = createRoutine(DELETE_CUSTOMER_ACCOUNT_REFERENCE);
export const updateCar = createRoutine(UPDATE_CUSTOMER_ACCOUNT_REFERENCE);
export const linkCarToParticipant = createRoutine(LINK_CAR_TO_PARTICIPANT);
export const unlinkCarToParticipant = createRoutine(UNLINK_CAR_TO_PARTICIPANT);

//* Promise actions*/
export const createCarPromiseCreator = promisifyRoutine(createCar);
export const updateCarPromiseCreator = promisifyRoutine(updateCar);
export const deleteCarPromiseCreator = promisifyRoutine(deleteCar);
export const linkCarToParticipantPromise =
  promisifyRoutine(linkCarToParticipant);
export const unlinkCarToParticipantPromise = promisifyRoutine(
  unlinkCarToParticipant,
);

/** Response Types*/
type FetchCarResponse = CAR;
type FetchCarListResponse = CarOption[];

//* Initial State */
type CarManagementState = {
  cars: CAR[];
  carList: CAR[];
};

const initialState: CarManagementState = {
  cars: [],
  carList: [],
};

//* Reducer */
export default handleActions<CarManagementState, any>(
  {
    [fetchCar.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarResponse },
    ) => ({
      ...state,
      cars: [payload],
    }),
    [fetchCarList.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarListResponse },
    ) => ({
      ...state,
      carList: payload,
    }),
    [createCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [updateCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [linkCarToParticipant.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  } as ReducerMap<CarManagementState, CarManagementState>,
  initialState,
);

//* Selectors */
export const getPreTradeRiskState = (state: RootState) =>
  get(state, 'carManagement', {});

export const getCars = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'cars', []),
);
export const getCarList = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'carList', []),
);

export const getLinkableCARListForFCMHelper = (
  allCars: CAR[],
  participants: TradingParticipant[],
) => {
  const allLinkedCars = participants.reduce((prev, currentParticipant) => {
    const fcm = currentParticipant.fcms?.[0];
    if (!fcm) {
      return prev;
    }
    const linkedCars = fcm.cgmsLinkedToParticipant.reduce(
      (cgmBuilder, currentCgm) => {
        const carsLinkedToCgm = currentCgm.cars.map(
          (car) => car.customerAccountReference,
        );
        return [...cgmBuilder, ...carsLinkedToCgm];
      },
      [] as string[],
    );
    const linkedNakedCars = fcm.carsLinkedToParticipant.map(
      (car) => car.customerAccountReference,
    );
    return [...prev, ...linkedCars, ...linkedNakedCars];
  }, [] as string[]);
  const linkableCars = allCars.filter(
    (car: CAR) => !allLinkedCars.includes(car.customerAccountReference),
  );
  return sortBy(linkableCars, ['customerAccountReference']);
};

export const getLinkableCARListForFCM = createSelector(
  [getCarList, getParticipantList],
  (allCars, participants) =>
    getLinkableCARListForFCMHelper(allCars, participants),
);

export const getCarListSelectOptions = createSelector(
  [getLinkableCARListForFCM],
  (cars) =>
    cars.map((car: CAR) => ({
      key: car.customerAccountReference,
      value: car.customerAccountReference,
      text: car.customerAccountReference,
    })) as StyledSelectOption[],
);
