import React from 'react';
import isUndefined from 'lodash/isUndefined';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import ColoredCell from '../common/EMPTable8/common/ColoredCell';
import {
  longShortColorMap,
  satisfiedColorMap,
} from '../components/Deliveries/colorMaps';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import DeliveryTableRow from '../ts/models/Deliveries/DeliveryTableRow.model';
import ExpandCell from '../common/EMPTable8/ExpandCell';
import { formatAccountingValue } from '../utils/formatters';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { formatFiat } from '../utils/methods';

const columnHelper = createColumnHelper<DeliveryTableRow>();

export const deliveriesMetadata = [
  columnHelper.accessor('productSymbol', {
    id: 'productSymbol',
    header: 'Product Code',
    minSize: 120,
    meta: {
      filterType: TableFilterType.String,
    },
    cell: (props) => {
      const product = props?.row?.original?.productSymbol ?? '';
      return props?.row?.depth > 0 ? null : product;
    },
  }),
  columnHelper.accessor('contractSymbol', {
    id: 'contractSymbol',
    header: 'Contract Code',
    minSize: 125,
    meta: {
      filterType: TableFilterType.String,
    },
    cell: (props) => {
      const contract = props?.row?.original?.contractSymbol ?? '';
      return props?.row?.depth > 0 ? null : contract;
    },
  }),
  columnHelper.accessor('maturityDate', {
    id: 'maturityDate',
    header: 'Maturity Date',
    minSize: 120,
    meta: {
      filterType: TableFilterType.Date,
    },
  }),
  columnHelper.accessor('deliveryType', {
    id: 'deliveryType',
    header: 'Delivery Type',
    minSize: 120,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('ls', {
    id: 'ls',
    header: 'L/S',
    minSize: 60,
    meta: {
      filterType: TableFilterType.String,
      expandOptions: {
        canExpand: true,
        depth: 0,
      },
    },
    cell: (props) =>
      !props?.row?.original?.ls ? (
        <ExpandCell
          key={`row_${props?.row?.id}_col_${props?.column?.id}_cell_${props?.cell?.id}`}
          row={props?.row}
          getValue={props.getValue}
          depth={0}
          showValue={undefined}
        />
      ) : (
        <ColoredCell
          value={props?.row?.original?.ls}
          colorMap={longShortColorMap}
        />
      ),
  }),
  columnHelper.accessor('account', {
    id: 'account',
    header: 'Account',
    minSize: 65,
    meta: {
      expandOptions: {
        canExpand: true,
        depth: 1,
      },
    },
  }),
  columnHelper.accessor('cgm', {
    id: 'cgm',
    header: 'CGM',
    minSize: 65,
    meta: {
      filterType: TableFilterType.String,
      expandOptions: {
        canExpand: true,
        depth: 2,
      },
    },
  }),
  columnHelper.accessor('deliveryAcct', {
    id: 'deliveryAcct',
    header: 'DelAcct',
    minSize: 90,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor((row) => formatAccountingValue(row.qty, true), {
    id: 'qty',
    header: 'Quantity',
    minSize: 90,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.price, 'USD'), {
    id: 'price',
    header: 'Price',
    minSize: 80,
    meta: {
      filterType: TableFilterType.Currency,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor('deliveryAsset', {
    id: 'deliveryAsset',
    header: 'DelAsset',
    minSize: 90,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.deliveryRequirement, 'USD'), {
    id: 'delReq',
    header: 'DelReq',
    minSize: 90,
    meta: {
      filterType: TableFilterType.Currency,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatAccountingValue(row.deliveryAmount), {
    id: 'deliveryAmount',
    header: 'DelAmt',
    minSize: 90,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatAccountingValue(row.deliveryBalance), {
    id: 'deliveryBalance',
    header: 'DelBal',
    minSize: 90,
    meta: {
      filterType: TableFilterType.Number,
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor('satisfied', {
    id: 'satisfied',
    header: 'Satisfied',
    minSize: 80,
    maxSize: 90,
    meta: {
      filterType: TableFilterType.Boolean,
    },
    cell: (props) => {
      const satisfied = props?.row?.original?.satisfied;
      return isUndefined(satisfied) ? null : (
        <ColoredCell
          value={satisfied ? 'Y' : 'N'}
          colorMap={satisfiedColorMap}
        />
      );
    },
  }),
] as ColumnDef<DeliveryTableRow>[];
