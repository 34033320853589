import { takeLatest } from 'redux-saga/effects';
import { createRoutine } from 'redux-saga-routines';
import { createSaga } from '../utils/createSaga';
import HTTP_METHODS from '../constants/httpMethods';
import {
  fetchAvailableEStatements,
  setEStatementsStatus,
  fetchEStatementsStatus,
  fetchAllAccountsEStatementsStatus,
  downloadStatements,
} from '../reducers/taxEStatementsReducer';

export default function* watch() {
  yield takeLatest(
    setEStatementsStatus.TRIGGER,
    createSaga(
      setEStatementsStatus,
      setEStatementsStatus._PREFIX,
      'Setting Tax e-Statement status',
      'Tax e-Statements status set',
      '',
      '',
      {
        method: HTTP_METHODS.POST,
      },
    ),
  );
  yield takeLatest(
    fetchAvailableEStatements.TRIGGER,
    createSaga(
      fetchAvailableEStatements,
      fetchAvailableEStatements._PREFIX,
      'Fetching available statements',
      '',
      '',
      '',
      {
        method: HTTP_METHODS.GET,
      },
    ),
  );
  yield takeLatest(
    fetchAllAccountsEStatementsStatus.TRIGGER,
    createSaga(
      fetchAllAccountsEStatementsStatus,
      fetchAllAccountsEStatementsStatus._PREFIX,
      '',
      '',
      '',
      '',
      {
        method: HTTP_METHODS.GET,
      },
    ),
  );
  yield takeLatest(
    fetchEStatementsStatus.TRIGGER,
    createSaga(
      fetchEStatementsStatus,
      fetchEStatementsStatus._PREFIX,
      '',
      '',
      '',
      '',
      {
        method: HTTP_METHODS.GET,
      },
    ),
  );
  yield takeLatest(
    downloadStatements.TRIGGER,
    createSaga(
      downloadStatements,
      downloadStatements._PREFIX,
      'Downloading Tax e-Statement',
      'Tax e-Statement downloaded',
      '',
      '',
      {
        method: HTTP_METHODS.GET,
      },
    ),
  );
}
