import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import {
  getAllLinkedMemberAssetAccounts,
  getLinkedAccountLabel,
} from './linkedAccountsReducer';

//* Actions */
export const CREATE_CRYPTO_WITHDRAWAL_REQUEST =
  'CREATE_CRYPTO_WITHDRAWAL_REQUEST';

export const CREATE_FIAT_WITHDRAWAL_REQUEST = 'CREATE_FIAT_WITHDRAWAL_REQUEST';

export const REFERENCE_CODE_REQUEST = 'REFERENCE_CODE_REQUEST';
export const REFERENCE_CODE_SUCCESS = 'REFERENCE_CODE_SUCCESS';
export const REFERENCE_CODE_FAILED = 'REFERENCE_CODE_FAILED';
export const CLEAR_REFERENCE_CODE = 'CLEAR_REFERENCE_CODE';
export const CONFIRM_TRANSFER_DETAILS = 'CONFIRM_TRANSFER_DETAILS';
export const ACH_WITHDRAWAL_LIMIT = 'ACH_WITHDRAWAL_LIMIT';

export const createFiatDepositRequest = createRoutine(
  'CREATE_FIAT_DEPOSIT_REQUEST',
);
export const createCollateralDepositRequest = createRoutine(
  'CREATE_COLLATERAL_DEPOSIT_REQUEST',
);

export const createFiatDepositRequestPromiseCreator = promisifyRoutine(
  createFiatDepositRequest,
);

export const bankDetails = createRoutine('BANK_DETAILS');

// JCF TODO: _achWithdrawalLimit_ is a misnomer, _withdrawalLimit_ is more accurate
export const achWithdrawalLimit = createRoutine(ACH_WITHDRAWAL_LIMIT);
export const CLEAR_WITHDRAWAL_LIMIT = 'CLEAR_WITHDRAWAL_LIMIT';

export const achDepositLimit = createRoutine('ACH_DEPOSIT_LIMIT');
export const CLEAR_DEPOSIT_LIMIT = 'CLEAR_DEPOSIT_LIMIT';

export const achImmediateDepositLimit = createRoutine(
  'ACH_IMMEDIATE_DEPOSIT_LIMIT',
);
export const fetchFeeParams = createRoutine('FEE_PARAMS');

//* Reducer */
// Refactor this into reducing boilerplate (higher order function)
export default function fundsTransfersRequest(state = {}, action) {
  switch (action.type) {
    case CONFIRM_TRANSFER_DETAILS:
      return {
        ...state,
        ...action.transferDetails,
      };
    case REFERENCE_CODE_REQUEST:
    case CLEAR_REFERENCE_CODE:
      return {
        ...state,
        referenceCode: '',
        bankDetails: {},
      };
    case REFERENCE_CODE_SUCCESS:
      return {
        ...state,
        referenceCode: action.referenceCode,
        errorMessage: null,
      };
    case REFERENCE_CODE_FAILED:
      return {
        ...state,
        errorMessage: action.locationStateError,
      };
    case bankDetails.SUCCESS:
      return {
        ...state,
        bankDetails: action.payload,
      };
    case achWithdrawalLimit.SUCCESS:
      return {
        ...state,
        achWithdrawalLimit: action.payload,
      };
    case achDepositLimit.SUCCESS:
      return {
        ...state,
        achDepositLimit: action.payload,
      };
    case achImmediateDepositLimit.SUCCESS:
      return {
        ...state,
        achDepositLimit: {
          ...state.achDepositLimit,
          instant: action.payload,
        },
      };
    case fetchFeeParams.SUCCESS:
      return {
        ...state,
        feeParams: {
          ...state.feeParams,
          [action.payload.assetType]: action.payload,
        },
      };
    case CLEAR_WITHDRAWAL_LIMIT:
      return {
        ...state,
        achWithdrawalLimit: {},
      };
    case CLEAR_DEPOSIT_LIMIT:
      return {
        ...state,
        achDepositLimit: {},
      };
    default:
      return state;
  }
}

//* Selectors */
export const getDepositReferenceCode = (state) =>
  _.get(state, ['fundTransfers', 'referenceCode'], '');
export const getDepositBankDetails = (state) =>
  _.get(state, ['fundTransfers', 'bankDetails'], {});
export const getAchWithdrawalLimit = (state) =>
  _.get(state, ['fundTransfers', 'achWithdrawalLimit'], {});
export const getAchDepositLimit = (state) =>
  _.get(state, ['fundTransfers', 'achDepositLimit'], {});
export const getFeeParams = (state) =>
  _.get(state, ['fundTransfers', 'feeParams'], {});

export const getFiatWithdrawalForm = (state) =>
  _.get(state, ['form', 'BankTransfers', 'values'], {});
export const getFiatWithdrawalDetails = createSelector(
  [getFiatWithdrawalForm, getAllLinkedMemberAssetAccounts],
  (bankFormDetails, assetAccounts) => {
    const linkedAssetAccount = _.find(assetAccounts, {
      id: _.get(bankFormDetails, ['linkedBankAccount', 'id']),
    });
    const destination = _.get(linkedAssetAccount, 'hashid', '');
    const destinationLabel = getLinkedAccountLabel(linkedAssetAccount);
    return {
      ...bankFormDetails,
      destination,
      destinationLabel,
      linkedAssetAccount,
    };
  },
);

export const getCryptoWithdrawalForm = (state) =>
  _.get(state, ['form', 'cryptoWithdrawal', 'values'], {});
export const getCryptoWithdrawalDetails = createSelector(
  [getCryptoWithdrawalForm, getAllLinkedMemberAssetAccounts],
  (cryptoDetails, assetAccounts) => {
    const linkedAssetAccount = _.find(assetAccounts, {
      id: cryptoDetails.destinationId,
    });
    const destination = _.get(linkedAssetAccount, 'address', '');
    const destinationLabel = getLinkedAccountLabel(linkedAssetAccount);
    return {
      ...cryptoDetails,
      destination,
      destinationLabel,
      linkedAssetAccount,
    };
  },
);

export const getEmbargoPeriod = createSelector(
  getAchWithdrawalLimit,
  (limits) => _.get(limits, 'embargoPeriod', '30'),
);

// This produces a selector that takes two arguments. The 2nd argument
// provides a type prop - currently supports 'crypto' or 'fiat'. Will
// return the appropriate withdrawal details based on the given type.
// See https://ngrx.io/guide/store/selectors#using-selectors-with-props
// for more info about selectors that receive a props argument.
export const getWithdrawalDetails = createSelector(
  [
    getCryptoWithdrawalDetails,
    getFiatWithdrawalDetails,
    (_, props) => props.type,
  ],
  (crypto, fiat, type) => _.get({ crypto, fiat }, type, {}),
);

/**
 * Action generator to create a new withdrawal request.
 * @param {Object} payload - The details of the withdrawal request.
 */
export const createCryptoWithdrawalRequest = createRoutine(
  CREATE_CRYPTO_WITHDRAWAL_REQUEST,
);

/**
 * Action generator to create a withdrawal request for fiat currency.
 * @param {Object} payload - The details of the deposit or withdrawal request.
 */
export const createFiatWithdrawalRequest = createRoutine(
  CREATE_FIAT_WITHDRAWAL_REQUEST,
);
export const createFiatWithdrawalRequestPromiseCreator = promisifyRoutine(
  createFiatWithdrawalRequest,
);

/**
 * Action generator to get a deposit address.
 * @param {Object} payload - accountId and assetType.
 */
export const referenceCode = (payload) => ({
  type: REFERENCE_CODE_REQUEST,
  payload,
});

export const clearReferenceCode = () => ({
  type: CLEAR_REFERENCE_CODE,
});

export const clearDepositLimit = () => ({
  type: CLEAR_DEPOSIT_LIMIT,
});

export const clearWithdrawalLimit = () => ({
  type: CLEAR_WITHDRAWAL_LIMIT,
});

/**
 * Action generator to confirm transfer details.
 * @param {Object} transferDetails - The details of the transfer.
 */
export const confirmTransferDetails = (transferDetails) => ({
  type: CONFIRM_TRANSFER_DETAILS,
  transferDetails,
});
