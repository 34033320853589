import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleAction } from 'redux-actions';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  arrayToObject,
  mapAssetTypesToFundsDesignationsOptions,
} from '../utils/methods';
import { hasFuturesDesignations } from '../utils/permissions';
import { getFundsDesignationsForActiveAccount } from './activeAccountSelectors';
import { getActiveAccount } from './activeAccountSelectors';
import { getSpotSecurities } from './orderEntryReducer';
import { INTERNAL_TRANSFER_ASSET_TYPES } from '../constants/internalTransfersConstants';
import { ALLOWED_CRYPTOS_FOR_FUTURES } from '../constants/allowedCryptosForFutures';
import { accountIsAllowedFutures } from '../utils/permissions';

//* Action Types */
export const lastPrice = createRoutine('LAST_PRICE');
export const fetchAssetTypes = createRoutine('ASSET_TYPES');
export const fetchAssetTypesPromiseCreator = promisifyRoutine(fetchAssetTypes);

//* Initial State */
const initialState = {};

//* Reducers */
export function assetTypesReducer(state = initialState, action) {
  switch (action.type) {
    case fetchAssetTypes.SUCCESS:
      return arrayToObject(action.payload, 'symbol');
    default:
      return state;
  }
}

// Get last prices, store by quantity type (i.e. qty/px)
// Doesn't spread state since we always want a new price
const lastPriceReducer = handleAction(
  lastPrice.SUCCESS,
  (state, { payload }) => ({ ...state, ...arrayToObject(payload, 'symbol') }),
  {},
);

export default combineReducers({
  assetTypesReducer,
  lastPrice: lastPriceReducer,
});

//* Selectors */
export const getAssetTypesByType = (state) =>
  _.get(state, ['assetTypes', 'assetTypesReducer'], {});
export const getAssetTypes = (state) =>
  Object.values(getAssetTypesByType(state));
export const getAssetTypesCount = (state) => getAssetTypes(state).length;
export const getCryptoAssetTypes = createSelector(
  [getAssetTypesByType],
  (assetTypes) => Object.values(assetTypes).filter((type) => !type.isFiat),
);

export const getCryptoAssetTypesWithFiltering = createSelector(
  [getAssetTypesByType, getActiveAccount],
  (assetTypes, account) => {
    if (accountIsAllowedFutures(account)) {
      return Object.values(assetTypes).filter(
        (type) =>
          !type.isFiat &&
          ALLOWED_CRYPTOS_FOR_FUTURES.some(
            (symbol) => type.symbol.toLowerCase() == symbol,
          ),
      );
    } else {
      return Object.values(assetTypes).filter((type) => !type.isFiat);
    }
  },
);

export const getFiatAssetTypes = createSelector(
  [getAssetTypesByType],
  (assetTypes) => Object.values(assetTypes).filter((type) => type.isFiat),
);

export const getAssetOptionsWithFundsDesignation = (selector) =>
  createSelector(
    [selector, getFundsDesignationsForActiveAccount],
    (assetTypes, filteredFundsDesignations) => {
      // only perform mapping if account is allowed for futures and we can
      // map funds designations to human-readable descriptions
      if (!hasFuturesDesignations(filteredFundsDesignations)) {
        return assetTypes.map((asset) => ({
          description: asset.description,
          key: asset.symbol,
          text: asset.symbol,
          value: asset.symbol,
        }));
      }
      return mapAssetTypesToFundsDesignationsOptions(
        assetTypes,
        filteredFundsDesignations,
      );
    },
  );

// format asset types as an array of options
export const getAssetTypesAsOptions = (state) =>
  getAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: asset.description,
    description: asset.symbol,
    isfiat: asset.isFiat ? 'true' : undefined, // custom prop to store isFiat bool
  }));

// format only crypto types as an array of options
export const getCryptoAssetTypesAsOptions = (state) =>
  getCryptoAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: asset.description,
    description: asset.symbol,
  }));

export const getCryptoAssetTypesAsOptionsWidget = (state) =>
  getCryptoAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: _.get(asset, 'description', '').replace('Test', ''),
    description: asset.symbol,
  }));
// assumes symbol consists of base/quote
export const extractQuoteCurrency = (symbol = '/') => symbol.split('/')[1];
export const extractBaseCurrency = (symbol = '/') => symbol.split('/')[0];

export const getSecuritiesListAsOptionsWidget = (state) => {
  const options = [];

  getSpotSecurities(state).forEach((security) => {
    // extract the quote currency for each security
    const quoteCurrency = extractQuoteCurrency(security.symbol);
    const baseCurrency = extractBaseCurrency(security.symbol);

    options.push({
      key: security.symbol,
      value: security.symbol,
      text: security.currency,
      // description: quoteCurrency,
      quoteCurrency,
      baseCurrency,
    });
  });

  // order by base currency
  return _.orderBy(options, 'value');
};

export const getSpotBaseCurrenciesAsOptions = (state) => {
  const options = [];

  getSpotSecurities(state).forEach((security) => {
    // extract the base currency for each security
    const baseCurrency = extractBaseCurrency(security.symbol);
    options.push({
      key: baseCurrency,
      value: baseCurrency,
      text: baseCurrency,
    });
  });

  // order by base currency
  return _.uniqBy(_.orderBy(options, 'value'), 'value');
};
export const getSpotQuoteCurrenciesAsOptions = (state) => {
  const options = [];

  getSpotSecurities(state).forEach((security) => {
    // extract the base currency for each security
    const quoteCurrency = extractQuoteCurrency(security.symbol);
    options.push({
      key: quoteCurrency,
      value: quoteCurrency,
      text: quoteCurrency,
    });
  });

  // order by base currency
  return _.uniqBy(_.orderBy(options, 'value'), 'value');
};

export const getLastPrice = (state) =>
  _.get(state, ['assetTypes', 'lastPrice'], {});

export const getInternalTransferAssets = (state) =>
  getAssetTypes(state)
    .filter((type) => INTERNAL_TRANSFER_ASSET_TYPES.includes(type.symbol))
    .map((type) => ({
      key: _.get(type, 'symbol', ''),
      text: _.get(type, 'symbol', ''),
      label: _.get(type, 'symbol', ''),
      value: _.get(type, 'symbol', ''),
    }));
