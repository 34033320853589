import React from 'react';
import { Header } from 'semantic-ui-react';

export default () => (
  <div className="onboarding-container">
    <div className="onboarding-content borderless center">
      <Header as="h3" icon textAlign="center">
        <div className="big-logo" />
        <Header.Content>
          Please wait while we finish setting up your account.
        </Header.Content>
      </Header>
    </div>
  </div>
);
