import { debounce } from 'redux-saga/effects';
import { fetchMemberUserTypes } from '../reducers/memberUserTypesReducer';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield debounce(
    1000,
    fetchMemberUserTypes.TRIGGER,
    createSaga(fetchMemberUserTypes, fetchMemberUserTypes._PREFIX),
  );
}
