import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { removeTFromTestCoins } from '../../reducers/orderEntryReducer';
import Big from 'bignumber.js';
import Skeleton from '@material-ui/lab/Skeleton';
import { uiSegment } from '../../styles/styled';
import MobileCurrencyCard from './MobileCurrencyCard';
import { BALANCES } from '../../reducers/balancesReducer';
import { createLoadingSelector } from 'erisxkit/client';
import colors from '../../constants/colors';
import { getCardData } from '../../components/SimpleAssetBalances';

const Wrapper = styled.div`
  ${uiSegment}
`;
Wrapper.displayName = 'CardsWrapper';

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  font-weight: 600;
  color: ${colors.GREEN};
  border-bottom: 2px solid ${colors.GREEN};
  padding: 0 15px;
  margin-bottom: 5px;
  & > p {
    margin-bottom: 10px;
  }
`;
Tabs.displayName = 'Tabs';

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
CardsContainer.displayName = 'CardsContainer';

const LoadingSkeletonContainer = styled.div`
  & > span {
    margin: 20px 0;
  }
`;
LoadingSkeletonContainer.displayName = 'LoadingSkeletonContainer';

const MobileCurrencyCards = ({
  balances,
  prices,
  assetTypes,
  loading,
  onClick,
}) => {
  return (
    <Wrapper>
      <Tabs>
        <p>ASSET</p>
        <p>PRICE</p>
        <p>QTY</p>
      </Tabs>
      {loading && (
        <LoadingSkeletonContainer>
          {assetTypes.map((type) => (
            <Skeleton
              variant="rect"
              animation="wave"
              width="100%"
              height={56}
              key={type.symbol}
            />
          ))}
        </LoadingSkeletonContainer>
      )}
      {!loading && (
        <CardsContainer>
          {balances.map((b) => (
            <MobileCurrencyCard
              {...getCardData(b, prices, assetTypes)}
              onClick={onClick}
              key={b.assetType}
            />
          ))}
        </CardsContainer>
      )}
    </Wrapper>
  );
};

MobileCurrencyCards.defaultProps = {
  onClick: () => {},
};

MobileCurrencyCards.propTypes = {
  balances: PropTypes.arrayOf(
    PropTypes.shape({
      assetType: PropTypes.string.isRequired,
      closingBalance: PropTypes.string.isRequired,
      lastPrice: PropTypes.string.isRequired,
      usdValue: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  prices: PropTypes.shape({
    'BCH/USD': PropTypes.string.isRequired,
    'BTC/USD': PropTypes.string.isRequired,
    'EST/USD': PropTypes.string.isRequired,
    'ETH/USD': PropTypes.string.isRequired,
    'LTC/USD': PropTypes.string.isRequired,
    'TXRP/USD': PropTypes.string,
    'USD/USD': PropTypes.string.isRequired,
    'XRP/USD': PropTypes.string.isRequired,
  }).isRequired,
  assetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isFiat: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: createLoadingSelector([BALANCES])(state),
});

export default connect(mapStateToProps, null)(MobileCurrencyCards);
