import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import sign from 'eris-ecdsa';
import { get } from 'lodash';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';

import CreateApiKeyModal from '../../components/Profile/CreateApiKeyModal';
import {
  apiCredentialsPermissions as fetchApiCredentialsPermissions,
  API_CREDENTIALS_PERMISSIONS,
  getApiCredentialPermissions,
  createApiCredentialsPromiseCreator,
  CREATE_API_CREDENTIALS,
} from '../../reducers/apiCredentialsReducer';
import EnvPromise from '../../config/env';
import { FUNDING_PASSWORD } from '../../constants/modalTypes';
import { getLoggedInUser } from '../../reducers/userReducer';

const mapStateToProps = (state) => ({
  apiCredentialsPermissions: getApiCredentialPermissions(state),
  apiCredentialsPermissionsLoading: createLoadingSelector([
    API_CREDENTIALS_PERMISSIONS,
  ])(state),
  creatingCredential: createLoadingSelector([CREATE_API_CREDENTIALS])(state),
  formValues: getFormValues('create-api-key')(state),
  user: getLoggedInUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchApiCredentialsPermissions,
      showModal,
      hideModal,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      createApiCredentialsPromiseCreator,
    },
    dispatch,
  ),
});

export const apiKeyPermissioning = (apiKeyPermissions, enabledPermissions) => {
  const enabledPermissionsList = enabledPermissions.split(',');
  // if apiKeyPermissions is null, not empty array, return the full array of credentials
  if (!apiKeyPermissions) {
    return enabledPermissionsList;
  }
  return enabledPermissionsList.filter((p) => apiKeyPermissions.includes(p));
};

class CreateApiKeyModalContainer extends Component {
  state = {
    credentials: null,
    enabledClearingApiCredentials: [],
  };
  componentDidMount = () => {
    EnvPromise.then(({ enabledClearingApiCredentials = '' }) =>
      this.setState({
        enabledClearingApiCredentials: apiKeyPermissioning(
          get(this.props, ['user', 'apiKeyPermissions'], null),
          enabledClearingApiCredentials,
        ),
      }),
    );
    this.props.fetchApiCredentialsPermissions();
  };
  generateHeader = () =>
    this.state.credentials
      ? 'Your API Key and Secret have been generated.'
      : 'Please enter a label and select the permissions for this key.';
  generateKey = (pass) => {
    const { hideModal, user, createApiCredentialsPromiseCreator, formValues } =
      this.props;
    hideModal(FUNDING_PASSWORD);
    const args = { ...formValues } || {};
    const authId = get(user, 'authId');
    const privateKey = sign.privateKeyFromPassword(authId, pass);

    if (formValues.permissions) {
      args.permissions = Object.keys(formValues.permissions).reduce(
        (arr, p) => {
          formValues.permissions[p] && arr.push(p);
          return arr;
        },
        [],
      );
    }

    const nonce = moment.now().toString();
    const msg = [authId, args.label, args.permissions.toString(), nonce];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);

    createApiCredentialsPromiseCreator({ ...args, sig, nonce }).then(
      (successPayload) => {
        const payload = successPayload;
        payload.permissions = successPayload.permissions.reduce(
          (acc, permission) => {
            acc[permission] = true;
            return acc;
          },
          {},
        );
        this.setState({
          credentials: payload,
        });
      },
    );
  };

  confirmFundingPassword = () => {
    this.props.showModal(FUNDING_PASSWORD, { submit: this.generateKey });
  };

  render = () => (
    <CreateApiKeyModal
      apiCredentialsPermissions={this.props.apiCredentialsPermissions}
      apiCredentialsPermissionsLoading={
        this.props.apiCredentialsPermissionsLoading
      }
      permissions={this.props.permissions}
      generateKey={this.confirmFundingPassword}
      credentials={this.state.credentials}
      initialValues={this.state.credentials}
      loading={this.props.creatingCredential}
      header={this.generateHeader()}
      enabledClearingApiCredentials={
        this.state.enabledClearingApiCredentials || []
      }
      formValues={this.props.formValues}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateApiKeyModalContainer);
