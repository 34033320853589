import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import Page from '../Page';
import EMPTable8 from '../EMPTable8/EMPTable8';
import { fcmBalanceDetailsMetadata } from '../../metadata/fcmBalanceDetailsMetadata';
import { FetchOptions } from '../EMPTable8/types';
import { getAllAccountsList } from '../../reducers/accountsReducer';
import {
  ACCOUNTS_BALANCES,
  fetchAccountsBalances,
  getTotalBalancesAllAccounts,
} from '../../reducers/balancesReducer';
import { BalanceDetail } from '../../ts/models/Treasury/BalanceDetail.model';
import { TableActionName } from '../EMPTable8/TableActions/types';
import useIsCdfEnabled from '../../hooks/useIsFeatureFlagEnabled/useIsCdfEnabled';

const FCMBalanceDetails = () => {
  const dispatch = useDispatch();
  const cdfEnabled = useIsCdfEnabled();
  /** Selectors */
  const allAccounts = useSelector(getAllAccountsList);
  const accountIds = allAccounts.map((item) => item.accountId);
  const data: BalanceDetail[] = useSelector((state) =>
    getTotalBalancesAllAccounts(accountIds)(state),
  );
  const loading = useSelector((state) =>
    createLoadingSelector([ACCOUNTS_BALANCES])(state),
  );

  /** Fetch Callback */
  const memoizedFetch = useCallback(
    (options: FetchOptions) => {
      dispatch(
        fetchAccountsBalances({
          accountIds,
          lastPrice: true,
          ...options,
        }),
      );
    },
    [accountIds, dispatch],
  );

  const columns = useMemo(
    () => fcmBalanceDetailsMetadata(cdfEnabled),
    [cdfEnabled],
  );
  return (
    <>
      <Page header={`Balance Details`}>
        <EMPTable8
          title="fcmBalanceDetails"
          data={data}
          columns={columns}
          fetchData={memoizedFetch}
          count={data?.length || 0}
          loading={loading}
          loadingText="Fetching Balance Details"
          noDataText="No Balances to show"
          minimumRows={5}
          local
          showActions
          actionsToHide={[TableActionName.Filters]}
          dataCy="balance-details-table"
        />
      </Page>
    </>
  );
};

export default FCMBalanceDetails;
