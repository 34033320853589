import React, { useCallback } from 'react';
import styled from 'styled-components';
import { showModal } from 'erisxkit/client';
import StyledButton from '../../../common/StyledButton';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { REGISTER_BLOCK_TRADE } from '../../../constants/modalTypes';

const Button = styled(StyledButton)`
  width: 250px;
  height: 40px;
`;

interface Props {
  className?: string;
}
const AddNewBlockTradeButton = ({ className }: Props) => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    dispatch(
      showModal(REGISTER_BLOCK_TRADE, {
        size: 'large',
      }),
    );
  }, [dispatch]);

  return (
    <Button
      className={className}
      primary
      text="Register New Block Trade"
      onClick={onClick}
    />
  );
};

export default AddNewBlockTradeButton;
