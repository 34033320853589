import Big from 'bignumber.js';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { getTimeColumn } from '../common/metadata/commonFields';
import { formatFiat, calculateBps } from '../utils/methods';

const columns = (moreInfo) => [
  {
    Header: '',
    columns: [
      {
        Header: '',
        id: 'information',
        accessor: (d) => (
          <Icon onClick={() => moreInfo(d)} link name="clipboard outline" />
        ),
        filterable: false,
        width: 35,
        resizable: false,
      },
      getTimeColumn(),
      {
        Header: 'Trade Type',
        id: 'tradeType',
        accessor: 'tradeType',
      },
      {
        Header: 'Submitter',
        id: 'submitter',
        accessor: 'submitter',
      },
      {
        Header: 'Product',
        id: 'productSymbol',
        accessor: 'productSymbol',
        headerClassName: 'pull-left',
        width: 100,
      },
      {
        Header: 'Contract',
        id: 'contractSymbol',
        accessor: 'contractSymbol',
        headerClassName: 'pull-left',
        width: 125,
      },
      {
        Header: 'Expiration Date',
        id: 'expirationDate',
        accessor: ({ expirationDate }) =>
          moment(expirationDate).format('M/D/YYYY'),
        headerClassName: 'pull-left',
      },
      {
        Header: 'Side',
        id: 'side',
        accessor: 'side',
        headerClassName: 'pull-left',
        width: 60,
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        accessor: 'qty',
        headerClassName: 'pull-left',
        width: 80,
      },
      {
        Header: 'Price',
        id: 'price',
        className: 'mono',
        accessor: ({ px, pxType }) =>
          pxType === 'USD' ? formatFiat(px, pxType) : px,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Notional',
        id: 'notional',
        className: 'mono',
        accessor: ({ notional, pxType }) =>
          pxType === 'USD'
            ? formatFiat(notional, 'USD')
            : `${notional} ${pxType}`,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Total Fee',
        id: 'fee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          clearingFees = 0,
          deliveryFee = 0,
          exchangeFees = 0,
          feeType = '',
        }) => {
          const totalFee = Big(clearingFees || 0)
            .plus(deliveryFee || 0)
            .plus(exchangeFees || 0)
            .toFixed();
          return feeType === 'USD'
            ? formatFiat(totalFee, feeType)
            : `${totalFee} ${feeType}`;
        },
      },
      {
        Header: '% Fee',
        id: 'percentageFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          clearingFees = 0,
          deliveryFee = 0,
          exchangeFees = 0,
          notional = 0,
        }) => {
          const totalFee = Big(clearingFees || 0)
            .plus(deliveryFee || 0)
            .plus(exchangeFees || 0)
            .toFixed();
          const percentageFee = calculateBps(totalFee, notional);
          return `${percentageFee} %`;
        },
      },
      {
        Header: 'Total',
        id: 'total',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          notional = 0,
          pxType,
          clearingFees = 0,
          deliveryFee = 0,
          exchangeFees = 0,
        }) => {
          const total = Big(notional)
            .plus(clearingFees || 0)
            .plus(deliveryFee || 0)
            .plus(exchangeFees || 0)
            .toFixed();
          return pxType === 'USD'
            ? formatFiat(total, pxType)
            : `${total} ${pxType}`;
        },
      },
      {
        Header: 'ClOrdID',
        id: 'clOrdId',
        accessor: 'clOrdId',
        headerClassName: 'pull-left',
      },
      {
        Header: 'Trade ID',
        id: 'tradeId',
        className: 'mono',
        accessor: ({ tradeId }) => <span title={tradeId}>{tradeId}</span>,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Aggressor',
        id: 'aggressor',
        accessor: 'aggressor',
        headerClassName: 'pull-left',
        width: 90,
      },
      {
        Header: 'CustAcctRef',
        id: 'customerAccountRef',
        accessor: 'customerAccountRef',
        headerClassName: 'pull-left',
      },
    ],
  },
];

export default columns;
