import React, { Fragment } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';

const SelfieRecomendation = ({ onConfirm }) => {
  const [step, setStep] = React.useState(1);
  const nextStep = () => {
    setStep(2);
  };
  return (
    <Fragment>
      <div>
        <Modal.Content>
          {step === 1 ? (
            <>
              <div className="onboarding-header modal-recomendation-header">
                Next up, we'll need you to take a selfie
              </div>
              <div>
                <div
                  className=""
                  className="selfie-recomendation-container-img"
                >
                  <div className="selfie-recomendation-img" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="onboarding-header modal-recomendation-header">
                Before you take a selfie, please follow these tips:
              </div>

              <div className="selfie-recomendation">
                <p style={{ textAlign: 'center' }}>
                  For best quality images, we recommend that you continue
                  onboarding from a mobile device on{' '}
                  <a href="https://clearing.erisx.com/">clearing.erisx.com</a>
                </p>
                <div className="fade-in i01">
                  <div className="circle-number">
                    <p>1</p>
                  </div>
                  <p>
                    The room is brightly lit and minimize any light sources
                    behind your head
                  </p>
                </div>
                <div className="fade-in i02">
                  <div className="circle-number">
                    <p>2</p>
                  </div>
                  <p>Your face is clearly visible</p>
                </div>
                <div className="fade-in i03">
                  <div className="circle-number">
                    <p>3</p>
                  </div>
                  <p>Remove glasses or anything that obscures your face</p>
                </div>
                <div className="fade-in i04">
                  <div className="circle-number">
                    <p>4</p>
                  </div>
                  <p>Frame your face within the green oval</p>
                </div>
              </div>
            </>
          )}
        </Modal.Content>
        <Modal.Actions className="modal-recomendation">
          {step === 1 && (
            <Button
              className="first-button-recomendation"
              data-dd-action-name="Selfie Recommendation Continue 1"
              data-cy={`ob-verification-selfie-recommendation-continue-1`}
              primary
              onClick={() => nextStep()}
              content="Continue"
            />
          )}
          {step === 2 && (
            <Button
              className="fade-in i05"
              data-dd-action-name="Selfie Recommendation Continue 2"
              data-cy={`ob-verification-selfie-recommendation-continue-2`}
              primary
              onClick={() => onConfirm()}
              content="Continue"
            />
          )}
        </Modal.Actions>
      </div>
    </Fragment>
  );
};

export default SelfieRecomendation;
