import { createColumnHelper } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { formatAccountingValue, formatDateValue } from '../utils/formatters';
import { formatFiat } from '../utils/methods';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import { Trade } from '../ts/models/TRADES.model';
import TableFilterType from '../ts/enums/TableFilterType.enum';

const columnHelper = createColumnHelper<Trade>();

const dateFormatCheck = (date: Date): string =>
  !isEmpty(date) ? formatDateValue(date) : '';

const TradesMetadata = () => [
  columnHelper.accessor((row) => dateFormatCheck(row.tradeDate), {
    id: 'tradeDate',
    header: 'Trade Date',
    enableColumnFilter: true,
    minSize: 110,
    meta: {
      align: TableCellAlignment.Left,
      filterType: TableFilterType.Date,
    },
  }),
  columnHelper.accessor(
    (row) =>
      row?.executionTime
        ? moment.utc(row?.executionTime).local().format('YYYY-MM-DD HH:mm:ss')
        : '',
    {
      id: 'executionTime',
      header: 'Execution Time',
      meta: {
        align: TableCellAlignment.Left,
        filterType: TableFilterType.Date,
      },
      enableColumnFilter: true,
    },
  ),
  columnHelper.accessor('tradeId', {
    id: 'tradeId',
    header: 'Trade ID',
    enableColumnFilter: true,
    minSize: 100,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('productSymbol', {
    id: 'productSymbol',
    header: 'Product',
    minSize: 90,
    meta: {
      align: TableCellAlignment.Left,
      filterType: TableFilterType.String,
    },
    enableColumnFilter: true,
  }),
  columnHelper.accessor('contractSymbol', {
    id: 'contractSymbol',
    header: 'Contract',
    minSize: 90,
    enableColumnFilter: true,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('account', {
    id: 'account',
    header: 'Account',
    enableColumnFilter: true,
    minSize: 90,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('tradeType', {
    id: 'tradeType',
    header: 'Trade Type',
    enableColumnFilter: true,
    minSize: 110,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('side', {
    id: 'side',
    header: 'Side',
    enableColumnFilter: true,
    minSize: 70,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor((row) => formatAccountingValue(row.quantity, true), {
    id: 'quantity',
    header: 'Quantity',
    enableColumnFilter: true,
    minSize: 90,
    meta: {
      align: TableCellAlignment.Right,
      filterType: TableFilterType.Currency,
      className: 'mono',
    },
  }),
  columnHelper.accessor((row) => formatFiat(row.price, 'USD'), {
    id: 'price',
    header: 'Price',
    enableColumnFilter: true,
    minSize: 70,
    meta: {
      align: TableCellAlignment.Right,
      filterType: TableFilterType.Currency,
      className: 'mono',
    },
  }),
  columnHelper.accessor('cgm', {
    id: 'cgm',
    header: 'CGM',
    enableColumnFilter: true,
    minSize: 70,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('car', {
    id: 'car',
    header: 'CAR',
    enableColumnFilter: true,
    minSize: 70,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('cti', {
    id: 'cti',
    header: 'CTI',
    enableColumnFilter: true,
    minSize: 60,
    meta: {
      align: TableCellAlignment.Right,
      filterType: TableFilterType.Number,
      className: 'mono',
    },
  }),
  columnHelper.accessor('origin', {
    id: 'origin',
    header: 'Origin',
    enableColumnFilter: true,
    minSize: 80,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('aggressor', {
    id: 'aggressor',
    header: 'Aggressor',
    enableColumnFilter: true,
    minSize: 100,
    meta: {
      align: TableCellAlignment.Center,
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('secondaryExecId', {
    id: 'secondaryExecId',
    header: 'Spread ID',
    enableColumnFilter: true,
    minSize: 100,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('submitter', {
    id: 'submitter',
    header: 'Submitter',
    enableColumnFilter: true,
    minSize: 100,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
];

export default TradesMetadata;
