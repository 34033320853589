import React, { Fragment, Component } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import WebcamCapture from './WebcamCapture';

class TakePhoto extends Component {
  state = {};
  onCapture = (image) => {
    this.setState({
      image,
    });
  };

  onReject = () => {
    this.setState({
      image: '',
    });
  };

  render = () => (
    <Fragment>
      <Modal.Header>
        {this.props.idType &&
          `Please take a photo of the ${this.props.imageType === 'id_front' ? 'front' : 'back'} of your ${this.props.idType.name}.`}
      </Modal.Header>
      <Modal.Content image={!!this.state.image} scrolling>
        {this.state.image ? (
          <Image
            id="webcam-confirm"
            wrapped
            size="medium"
            src={this.state.image}
            alt="Id"
          />
        ) : (
          <WebcamCapture onCapture={this.onCapture} orientation="portrait" />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          disabled={!this.state.image}
          secondary
          onClick={this.onReject}
          content="Try Again"
        />
        <Button
          disabled={!this.state.image}
          primary
          onClick={() => this.props.onConfirm(this.state.image)}
          content="Upload & Continue"
        />
      </Modal.Actions>
    </Fragment>
  );
}
export default TakePhoto;
