export const WITHDRAWAL_CONFIRM = 'WITHDRAWAL_CONFIRM';
export const CRYPTO_DEPOSIT_CONFIRM = 'CRYPTO_DEPOSIT_CONFIRM';
export const DEPOSIT_WITHDRAWAL = 'DEPOSIT_WITHDRAWAL';
export const PERSONAL = 'PERSONAL';
export const IDENTIFICATION = 'IDENTIFICATION';
export const VERIFICATION = 'VERIFICATION';
export const CREATE_FUNDING_PASSWORD = 'CREATE_FUNDING_PASSWORD';
export const FORGOT_FUNDING_PASSWORD = 'FORGOT_FUNDING_PASSWORD';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const ADD_LINKED_ACCOUNT = 'ADD_LINKED_ACCOUNT';
export const ACCOUNT_MANUAL_CONFIRM = 'ACCOUNT_MANUAL_CONFIRM';
export const FUNDING_PASSWORD = 'FUNDING_PASSWORD';
export const TRANSACTION_INFORMATION = 'TRANSACTION_INFORMATION';
export const CONFIRM_PHOTO = 'CONFIRM_PHOTO';
export const SELFIE_RECOMENDATION = 'SELFIE_RECOMENDATION';
export const TAKE_PHOTO = 'TAKE_PHOTO';
export const UPLOAD_REJECTED = 'UPLOAD_REJECTED';
export const CREATE_API_CREDENTIALS = 'CREATE_API_CREDENTIALS';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const MEMBER_SELECTION = 'MEMBER_SELECTION';
export const TRADE_CONFIRM = 'TRADE_CONFIRM';
export const DEFAULT_PHOTO_ERROR = 'DEFAULT_PHOTO_ERROR';
export const ORDER_ENTRY = 'ORDER_ENTRY';
export const REFERRAL_CODE_SENT = 'REFERRAL_CODE_SENT';
export const NOTIFY_UNREGISTERED_USER = 'NOTIFY_UNREGISTERED_USER';
export const REMOVE_AUTHORIZED_USER = 'REMOVE_AUTHORIZED_USER';
export const ADD_AUTHORIZED_USER = 'ADD_AUTHORIZED_USER';
export const UPGRADE_TO_FUTURES = 'UPGRADE_TO_FUTURES';
export const FUTURES_ONBOARDING = 'FUTURES_ONBOARDING';
export const ACCOUNT_SELECTION = 'ACCOUNT_SELECTION';
export const TRANSFER_CONFIRMATION_MODAL = 'TRANSFER_CONFIRMATION_MODAL';
export const ADD_IP_ADDRESS_MODAL = 'ADD_IP_ADDRESS_MODAL';
export const REMOVE_IP_ADDRESS_MODAL = 'REMOVE_IP_ADDRESS_MODAL';
export const RESET_2FA_MODAL = 'RESET_2FA_MODAL';
export const ADD_NEW_CAR = 'ADD_NEW_CAR';
export const ADD_NEW_CGM = 'ADD_NEW_CGM';
export const TAX_STATEMENTS = 'TAX_STATEMENTS';
export const GENERIC_MODAL = 'GENERIC_MODAL';
export const FCM_WITHDRAWAL_CONFIRM = 'FCM_WITHDRAWAL_CONFIRM';
export const REQUEST_FCM_CLEARING = 'REQUEST_FCM_CLEARING';
export const DM_OFFBOARDING_MODAL = 'DM_OFFBOARDING_MODAL';
export const CLEARING_REQUEST_ACTION = 'CLEARING_REQUEST_ACTION';
export const APPROVED_PARTICIPANT_LINK = 'APPROVED_PARTICIPANT_LINK';
export const TRADING_PARTICIPANT_LINK_CGM = 'TRADING_PARTICIPANT_LINK_CGM';
export const TRADING_PARTICIPANT_LINK_CAR = 'TRADING_PARTICIPANT_LINK_CAR';
export const ADD_AUTHORIZED_TRADER = 'ADD_AUTHORIZED_TRADER';
export const REGISTER_BLOCK_TRADE = 'REGISTER_BLOCK_TRADE';
export const APPROVE_REJECT_BLOCK_TRADE_REQUEST = 'APPROVE_REJECT_BLOCK_TRADE_REQUEST';
