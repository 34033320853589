import { get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { getActiveAccountId } from './activeAccountSelectors';

export const CLOSEOUT_JOURNALS = 'CLOSEOUT_JOURNALS';
/**
 * Routine to get contract closeouts.
 */
export const fetchCloseoutJournals = createRoutine(CLOSEOUT_JOURNALS);

export default handleActions(
  {
    [fetchCloseoutJournals.SUCCESS]: (state, { payload }) => ({
      count: payload.count,
      byId: {
        ...state.byId,
        [payload.accountId]: payload.closeoutJournals,
      },
    }),
  },
  { byId: {}, count: 0 },
);

export const getCloseoutJournals = (state) =>
  get(state, ['closeoutJournals', 'byId', getActiveAccountId(state)], []);
export const getCloseoutJournalsCount = (state) =>
  get(state, ['closeoutJournals', 'count'], '');
