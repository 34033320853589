import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Header, Segment, Icon, Grid } from 'semantic-ui-react';
import { pushTag, trackEvent } from '../../common/tracking';
import * as onBoardingSteps from '../../constants/onBoardingSteps';
import { Typography } from '@material-ui/core';

const styles = {
  message: {
    textAlign: 'left',
  },
  button: {
    marginTop: '2rem',
  },
  highlight: {
    fontWeight: 'bold',
  },
  header: {
    marginBottom: '1rem',
  },
  container: {
    marginBottom: '5rem',
  },
};

function renderMobilePreferredMessage() {
  // this message is only shown to users on desktop
  if (isMobile) {
    return null;
  }

  return (
    <Grid.Row>
      <Grid.Column width={2} verticalAlign="middle">
        <Icon name="mobile alternate" color="primary" size="large" />
      </Grid.Column>
      <Grid.Column width={14}>
        <p style={styles.message}>
          <strong style={styles.highlight}>Mobile Preferred.</strong> For the
          quickest onboarding experience, we recommend continuing your
          onboarding on{' '}
          <a href="https://clearing.erisx.com">clearing.erisx.com</a> from your
          mobile device.
        </p>
      </Grid.Column>
    </Grid.Row>
  );
}

function renderButton(onClick) {
  // todo: show different button for mobile
  return (
    <Button
      primary
      onClick={onClick}
      style={styles.button}
      data-dd-action-name="Get Started Button"
      data-cy="get-registration-started-button"
    >
      Get Started
    </Button>
  );
}

const ProcessInformation = ({ goToStep }) => {
  const onContinue = () => {
    pushTag('startOnboarding');
    trackEvent('SignUp', 'startContinue');
    goToStep(onBoardingSteps.PERSONAL);
  };
  const onEmail = () => {
    trackEvent('SignUp', 'startEntity');
  };

  return (
    <div className="onboarding-container" style={styles.container}>
      <div className="onboarding-content ui padded segment">
        <Segment className="onboarding-information" textAlign="center">
          <Header>
            <div className="logo-container">
              <div className="onboarding-logo" alt="ErisX" />
            </div>
            <Header.Content>
              <Typography variant="h4" style={styles.header}>
                Open a Cryptocurrency Trading Account
              </Typography>
            </Header.Content>
          </Header>
          <Grid columns={2}>
            {renderMobilePreferredMessage()}
            <Grid.Row>
              <Grid.Column width={2} verticalAlign="middle">
                <Icon name="user" color="primary" size="large" />
              </Grid.Column>
              <Grid.Column width={14}>
                <p style={styles.message}>
                  <strong style={styles.highlight}>
                    Designed for Individuals.
                  </strong>{' '}
                  This onboarding process is intended for individuals. If you
                  wish to open an account as an entity please contact{' '}
                  <a
                    href="mailto:digital.onboarding@cboe.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={onEmail}
                  >
                    digital.onboarding@cboe.com
                  </a>
                  .
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2} verticalAlign="middle">
                <Icon name="id card" color="primary" size="large" />
              </Grid.Column>
              <Grid.Column width={14}>
                <p style={styles.message}>
                  <strong style={styles.highlight}>Fast Verification.</strong>{' '}
                  Be up and running in minutes with remote verification. Federal
                  law requires all financial institutions to conduct identity
                  verification and providing accurate information will help us
                  open your account faster.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2} verticalAlign="middle">
                <Icon name="lock" color="primary" size="large" />
              </Grid.Column>
              <Grid.Column width={14}>
                <p style={styles.message}>
                  <strong style={styles.highlight}>Safe and Secure.</strong> All
                  your information will be safely stored following strict
                  standards and security features as employed by banks and other
                  financial institutions.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {renderButton(onContinue)}
        </Segment>
      </div>
    </div>
  );
};

export default ProcessInformation;
