import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComplianceQuestions from '../../components/onBoarding/ComplianceQuestions';
import {
  fetchComplianceQuestions,
  submitComplianceQuestions,
  getComplianceQuestions,
} from '../../reducers/complianceQuestionsReducer';
import { getLoggedInUser, fetchUser } from '../../reducers/userReducer';
import * as userStates from '../../constants/userStates';
import * as appStates from '../../constants/appStates';
import * as onBoardingSteps from '../../constants/onBoardingSteps';

import LoadingComponent from '../../common/components/xtable/LoadingComponent';
import { trackEvent } from '../../common/tracking';

class ComplianceQuestionsContainer extends Component {
  state = {};

  componentDidMount = () => {
    this.props.fetchComplianceQuestions();
  };

  next = () => {
    const { answers, nextStep } = this.props;
    const payload = Object.keys(answers).map((key) => {
      const match = /question_id_([0-9]*)/.exec(key);
      return {
        questionId: match[1],
        choice: answers[key],
      };
    });
    trackEvent('AMLKYC', 'complianceSubmitQuestions');
    this.props.submitComplianceQuestions(payload);
    if (nextStep) {
      nextStep();
    }
  };

  render = () => {
    const props = {
      next: this.next,
      loading: false,
      questions: this.props.questions,
      answers: this.props.answers,
    };
    if (
      this.props.user.state === userStates.KYC &&
      this.props.user.appState === appStates.PENDING_PII
    ) {
      this.props.goToStep(onBoardingSteps.PERSONAL);
    }
    return <ComplianceQuestions {...props} />;
  };
}

const mapDispatchToProps = {
  fetchComplianceQuestions,
  submitComplianceQuestions,
  fetchUser,
};

const mapStateToProps = (state) => ({
  user: getLoggedInUser(state),
  questions: getComplianceQuestions(state),
  answers: _.get(state, ['form', 'compliance', 'values'], {}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplianceQuestionsContainer);
