import { call, takeLatest, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import * as actions from '../reducers/fileUploadReducer';
import api, * as urls from '../api';
import { fetchUser } from '../reducers/userReducer';

function* updateImage(arg) {
  const loadingCallback = cogoToast.loading(
    'Please wait while your photo is being uploaded.',
    { hideAfter: 0 },
  );
  try {
    yield put(actions.updateImage.request());
    const payload = yield call(
      api.post,
      urls.UPDATE_IMAGE_API_ENDPOINT,
      arg.payload,
    );
    loadingCallback();
    cogoToast.success('Photo uploaded successfully!');
    yield put(actions.updateImage.success(payload));
  } catch (e) {
    loadingCallback();
    yield put(actions.updateImage.failure(e.response));
  }
}

function* submitIdImages() {
  try {
    yield put(actions.submitImages.request());
    const payload = yield call(api.post, urls.SUBMIT_IMAGES_API_ENDPOINT);
    yield put(fetchUser.success(payload));
    yield put(actions.submitImages.success(payload));
  } catch (e) {
    yield put(actions.submitImages.failure(e.response));
  }
}

function* images() {
  try {
    const imageState = yield call(api.post, urls.IMAGES_API_ENDPOINT);
    yield put({ type: actions.IMAGES_SUCCESS, payload: imageState });
  } catch (e) {
    yield put({ type: actions.IMAGES_FAILED, payload: e.response });
  }
}

function* idTypes() {
  try {
    const imageState = yield call(api.post, urls.ID_TYPES_API_ENDPOINT);
    yield put({ type: actions.ID_TYPES_SUCCESS, payload: imageState });
  } catch (e) {
    yield put({ type: actions.ID_TYPES_FAILED, payload: e.response });
  }
}

export default function* watch() {
  yield takeLatest(actions.updateImage.TRIGGER, updateImage);
  yield takeLatest(actions.submitImages.TRIGGER, submitIdImages);
  yield takeLatest(actions.IMAGES_REQUEST, images);
  yield takeLatest(actions.ID_TYPES_REQUEST, idTypes);
}
