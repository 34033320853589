import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import get from 'lodash/get';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import {
  TradingParticipantManagementRiskLimits,
  TradingParticipantManagementRiskLimitsCompatible
} from '../../ts/models/TradingParticipant';
import Page from '../../common/Page';
import {
  ExpandableColumn, TableEditState,
} from '../../common/EMPTable8/types';
import AccountSelectorFCM from '../AccountSelector';
import styled from 'styled-components';
import {
  fetchTradingParticipantRiskLimits,
  getTradingParticipantRiskLimits,
  getTradingParticipantRiskLimitsCompatible
} from '../../reducers/tradingParticipantManagementReducer';
import { getLoggedInUseCanWritePreTradeRisk } from '../../reducers/userReducer';
import { marginProducts } from '../../reducers/orderEntryReducer';
import { StyledSelectOption } from '../../common/StyledSelect';
import tradingParticipantManagementNonFCMMetadata from '../../metadata/tradingParticipantManagementNonFCMMetadata';
import { Dropdown } from 'semantic-ui-react';
import parseCAR from './parseCAR';
import { updateCarPromiseCreator } from '../../reducers/CARManagement/carManagementReducer';
import { CAR } from '../../ts/models/CAR.model';
import { getCarProducts } from '../../reducers/orderEntryReducer';
import CAR_EDIT_DISCLAIMER from './contants';
import { CarOption } from '../../ts/models/CAROption.model';
import { getFormValues } from 'redux-form';
import { SELECT_CAR_FORM } from './PreTradeRiskActions';
import preTradeRiskNonFCMColumns from '../../metadata/preTradeRiskNonFCMMetadata';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 20px;
`;

const DivFCM = styled.div`
  width: 250px;
  margin-top: 30px!important;
`;

const DDLHeader = styled.p`
  margin: 0;
  color: black;
`;

const TradingParticipantManagementNonFCM = () => {
  const [selectedFCMName, setSelectedFCMName] = useState('');
  const dispatch = useDispatch();
  const tradingParticipantRiskLimits: TradingParticipantManagementRiskLimitsCompatible[] = useSelector(getTradingParticipantRiskLimitsCompatible);
  const firmCode = useSelector(getActiveMemberFirmCode);
  const tradingParticipantFCM: TradingParticipantManagementRiskLimits[] = useSelector(getTradingParticipantRiskLimits);
  const canWrite = useSelector(getLoggedInUseCanWritePreTradeRisk);
  const loading = useSelector((state) =>
    createLoadingSelector([fetchTradingParticipantRiskLimits._PREFIX])(state),
  );

  const carProducts: StyledSelectOption[] = useSelector(getCarProducts);

  useEffect(() => {
    memoizedFetch();
  }, [firmCode, dispatch]);

  useEffect(() => {
    if (tradingParticipantFCM.length > 0 && tradingParticipantFCM[0]?.fcms.length === 1) {
      setSelectedFCMName(tradingParticipantFCM[0]?.fcms[0].fcmName)
    }
  }, [tradingParticipantFCM, dispatch]);
  
  const memoizedFetch = useCallback(
    () => {
      if (firmCode) {
        dispatch(fetchTradingParticipantRiskLimits({ tradingParticipantFirmCode: firmCode }));
        dispatch(marginProducts());
      }
    },
    [firmCode, dispatch]
  );

  const memoizedSubRows = useCallback(
    (row) => row.participantRiskLimits || row.expiryLimits,
    [],
  );

  const handleChange = (e, obj) => {
    setSelectedFCMName(obj.value);
  };

  const memoizedExpandableColumns = useMemo(
    () =>
      [
        {
          name: 'participantRiskLimits',
          template: {
            productCode: '',
            maxShortExposure: '',
            maxLongExposure: '',
            expiryLimits: [],
          },
        },
        {
          name: 'expiryLimits',
          template: {
            expirationMonth: '',
            maxShortExposure: '',
            maxLongExposure: '',
          },
        },
      ] as ExpandableColumn[],
    [],
  );

  const memoizedCARSubRows = useCallback(
    (row) => row.productLimits || row.expiryLimits,
    [],
  );

  const memoizedCARExpandableColumns = useMemo(
    () =>
      [
        {
          name: 'productLimits',
          template: {
            productCode: '',
            maxShortExposure: '',
            maxLongExposure: '',
            expiryLimits: [],
          },
        },
        {
          name: 'expiryLimits',
          template: {
            expirationMonth: '',
            maxShortExposure: '',
            maxLongExposure: '',
          },
        },
      ] as ExpandableColumn[],
    [],
  );

  const formValues = useSelector((state) =>
    getFormValues(SELECT_CAR_FORM)(state),
  );

  const selectedCAR: CarOption = get(formValues, 'selectedCAR', null);

  const memoizedColumns = useMemo(() => {
    return tradingParticipantManagementNonFCMMetadata();
  }, [canWrite]);

  const memoizedCARColumns = useMemo(() => {
    return preTradeRiskNonFCMColumns(carProducts, canWrite);
  }, [carProducts, canWrite]);

  const sortBy = useMemo(() => {
    return {
      sorting: [
        { id: 'participantName', desc: false },
        { id: 'productCode', desc: false },
      ],
    };
  }, []);

  const sortCARBy = useMemo(() => {
    return {
      sorting: [
        { id: 'customerAccountReference', desc: false },
        { id: 'productCode', desc: false },
      ],
    };
  }, []);

  const filteredRiskLimits = useMemo(() => {
    return tradingParticipantRiskLimits.filter(x => x.fcmName === selectedFCMName);
  }, [tradingParticipantRiskLimits, selectedFCMName]);

  const filteredFCM = useMemo(() => {
    return tradingParticipantFCM[0]?.fcms.find(x => x.fcmName === selectedFCMName)
  }, [tradingParticipantFCM, selectedFCMName]);


  const memoizedSave = useCallback(
    (changes: TableEditState<CAR>) => {
      const customerAccountReference = get(
        changes,
        'updated.customerAccountReference',
        '',
      );
      updateCarPromiseCreator(
        {
          urlParams: {
            accountLabel: get(changes, 'updated.fcmAccountLabel', ''),
            customerAccountReference,
          },
          ...parseCAR(changes.updated),
        },
        dispatch,
      ).then(() =>
        memoizedFetch(),
      );
    },
    [dispatch],
  );

  return (
    <>
      <Page header="Trading Participant Management">
        <Header>
          <AccountSelectorFCM />
        </Header>
        <DivFCM>
          <DDLHeader>FCM</DDLHeader>
          <StyledDropdown
            selection
            defaultValue={selectedFCMName}
            value={selectedFCMName}
            options={(tradingParticipantFCM.length > 0 && tradingParticipantFCM[0]?.fcms || []).map((fcm) => ({
              key: fcm.fcmName,
              value: fcm.fcmName,
              text: fcm.fcmName,
            }))}
            name="selectedFCM"
            onChange={handleChange}
            label="FCM"
          />
        </DivFCM>
        <EMPTable8
          title="PaticipantRiskLimits"
          data={filteredRiskLimits}
          local
          columns={memoizedColumns}
          fetchData={() => { }}
          count={filteredRiskLimits?.length}
          getSubRows={memoizedSubRows}
          expandableColumns={memoizedExpandableColumns}
          loading={loading}
          loadingText="Fetching CAR"
          minimumRows={5}
          showActiveFilters
          initialState={sortBy}
        />
        <EMPTable8
          title="CarTable"
          data={filteredFCM?.cars || []}
          local
          columns={memoizedCARColumns}
          fetchData={() => { }}
          count={(filteredFCM?.cars || []).length}
          getSubRows={memoizedCARSubRows}
          onSaveChanges={memoizedSave}
          expandableColumns={memoizedCARExpandableColumns}
          editDisclaimer={CAR_EDIT_DISCLAIMER}
          loading={loading}
          loadingText="Fetching CAR"
          noDataText={
            selectedCAR
              ? 'No CAR could be found'
              : 'Select a Customer Account Ref'
          }
          minimumRows={5}
          showActions
          showActiveFilters
          isRowEditable={() => {
            return (!canWrite || !filteredFCM?.tradingParticipantRiskLimits.canParticipantModifyCarRiskLimits)
          }
          }
          initialState={sortCARBy}
        />
      </Page>
    </>
  );
};

export default TradingParticipantManagementNonFCM;
