import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'semantic-ui-react';

const UploadRejectedModal = ({ error }) => (
  <Modal.Content>
    <div className="vert-flex">
      <Icon name="exclamation" size="big" />
      <p className="text-center">
        {error}
        <br />
        Please try again.
      </p>
    </div>
  </Modal.Content>
);

UploadRejectedModal.propTypes = {
  error: PropTypes.string,
};

UploadRejectedModal.defaultProps = {
  error:
    'The picture you selected does not meet the requirements (min size: 50KB, max size: 4MB).',
};

export default UploadRejectedModal;
