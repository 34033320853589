import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 20,
  },
});

class InputWithSelect extends React.Component {
  state = {
    selectedOption: this.props.selectProps?.defaultOption || '',
  };

  handleChange = (event, callback) => {
    this.setState({ [event.target.name]: event.target.value });
    callback(event, event.target);
  };

  createEndAdornment = ({ options = [], onChangeCallback, ...rest }) => (
    <InputAdornment position="end">
      <Select
        value={this.state.selectedOption}
        onChange={(e) => this.handleChange(e, onChangeCallback)}
        name="selectedOption"
        {...rest}
      >
        {options.map((o) => (
          <MenuItem key={o.key} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </InputAdornment>
  );

  render() {
    const {
      inputProps = {},
      selectProps = {},
      placeholder,
      classes,
      onChange,
      ...rest
    } = this.props;

    return (
      <div>
        <TextField
          value={inputProps.value}
          onChange={(e) => inputProps.onChange(e, e.target)}
          InputProps={{
            endAdornment: this.createEndAdornment(selectProps),
          }}
          placeholder={inputProps.placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      </div>
    );
  }
}
InputWithSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InputWithSelect);
