import { useRef, useEffect } from 'react';
/*Use this hook when trying to reference a previous value of an attribute
As in prevProps on a class-based component */
function usePrevious(value) {
  const ref = useRef();
  //Stores value variable in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); //refreshes if value is changed
  return ref.current; //returns the actual previous value
}
export default usePrevious;
