import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Big from 'bignumber.js';
import PropTypes from 'prop-types';
import { removeTFromTestCoins } from '../reducers/orderEntryReducer';
import { isMobileOnly } from 'react-device-detect';
import MobileCurrencyCards from '../common/components/MobileCurrencyCards';
import CurrencyCards from '../common/components/CurrencyCards';

export const getOnPriceChangeStyle = (priceChange) => {
  if (Big(priceChange).isZero()) return ''; // in BigJs 0 is considered positive
  if (Big(priceChange).isPositive()) return 'flash-price-up';
  if (Big(priceChange).isNegative()) return 'flash-price-down';
};

export const getCardData = (balance, prices, assetTypes) => {
  let { assetType, closingBalance } = balance;
  assetType = removeTFromTestCoins(assetType);
  const price = prices[`${assetType}/USD`];
  const usdValue = Big(balance.closingBalance).times(price).toFixed();
  const currency = assetTypes.find((a) => a.symbol === balance.assetType);
  return { assetType, closingBalance, price, currency, usdValue };
};

const AssetBalances = ({ balances, assetTypes, onClick, prices }) =>
  isMobileOnly ? (
    <MobileCurrencyCards
      balances={balances}
      prices={prices}
      assetTypes={assetTypes}
      onClick={onClick}
    />
  ) : (
    <CurrencyCards
      balances={balances}
      prices={prices}
      assetTypes={assetTypes}
    />
  );

AssetBalances.propTypes = {
  balances: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

AssetBalances.defaultProps = {
  balances: [],
  loading: false,
};

export default AssetBalances;
