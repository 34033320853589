import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ColumnOptions } from '../common/EMPTable8/types';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import { CGM } from '../ts/models/CGM.model';
import TagList from '../common/EMPTable8/common/TagList';
import TableFilterType from '../ts/enums/TableFilterType.enum';

const columnHelper = createColumnHelper<CGM>();

export const cgmManagementMetadata = (canWrite: boolean) =>
  [
    columnHelper.accessor('cgmNumber', {
      id: 'cgmNumber',
      header: 'CGM Code',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        align: TableCellAlignment.Left,
        editOptions: {
          canEdit: false,
          component: TableInputTypes.Input,
        },
        filterType: TableFilterType.String,
      },
    }),
    columnHelper.accessor('cars', {
      id: 'cars',
      header: 'Associated CARs',
      minSize: 100,
      cell: ({ getValue }) => {
        return <TagList values={getValue()} />;
      },
    }),
    columnHelper.accessor('cgmType', {
      id: 'cgmType',
      header: 'Type',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
        align: TableCellAlignment.Left,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          options: [
            { text: 'Net', value: 'NET', key: 'net' },
            { text: 'Gross', value: 'GROSS', key: 'gross' },
          ],
        },
      },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: 'Description',
      minSize: 100,
      meta: {
        align: TableCellAlignment.Left,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Input,
        },
      },
    }),
  ] as ColumnDef<CGM>[];

export default cgmManagementMetadata;
