import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Statistic } from 'semantic-ui-react';
import { format, createLoadingSelector } from 'erisxkit/client';
import Big from 'bignumber.js';
import { formValueSelector } from 'redux-form';
import {
  clearACHDeposits,
  getScheduledACHDepositsFromAccount,
} from '../../reducers/ACHDepositsReducer';
import { getActiveAccount } from '../../reducers/activeAccountSelectors';
import colors from '../../constants/colors';

const selector = formValueSelector('fiatDeposit');
const Warning = styled.p`
  color: ${colors.RED_ERROR};
`;
export default ({
  type,
  amount,
  accountName,
  instantly,
  embargoPeriod,
  scheduled,
}) => {
  const dispatch = useDispatch();

  const scheduledDeposits = useSelector((state) =>
    getScheduledACHDepositsFromAccount(
      state,
      getActiveAccount(state)?.accountId,
      selector(state, 'linkedBankAccount')?.id,
    ),
  );

  const scheduledDepositsLoading = useSelector((state) =>
    createLoadingSelector(['ACCOUNT_SCHEDULES'])(state),
  );

  useEffect(
    () => () => {
      if (scheduled) {
        dispatch(clearACHDeposits());
      }
    },
    [],
  );

  const amountIsLessThanInstant = Big(amount).isLessThanOrEqualTo(
    Big(instantly),
  );

  return (
    <div className="ach-confirm-wrapper">
      <Statistic>
        <Statistic.Label className="ach-confirm-label">
          You have requested to {type}
        </Statistic.Label>
        <Statistic.Value>
          {format(amount, { type: 'fiat', currency: 'USD' })}
        </Statistic.Value>
      </Statistic>
      from the following bank account: {accountName}
      <div className="ach-confirm-note">
        {amountIsLessThanInstant
          ? 'Your deposit will be instantly applied to your Cboe Digital trading account.'
          : 'Your deposit will be available when your bank has processed the request (usually within 3 business days).'}
        <br />
        {scheduledDepositsLoading && (
          <>
            <br />
            <p>
              If you already have a scheduled deposit for this bank account it
              will be overwritten by this new request.
            </p>
            <p>Checking for previously scheduled deposits...</p>
          </>
        )}
        {scheduled &&
          !scheduledDepositsLoading &&
          scheduledDeposits?.length > 0 && (
            <>
              <br />
              <Warning>
                You already have an existing scheduled deposit from this bank
                account. It will be replaced with this request.
              </Warning>
            </>
          )}
        <br /> However, bank processing may take up to 5 business days. Please
        maintain sufficient funds in your bank account to cover this deposit,
        otherwise you may be charged additional fees. These funds will be
        available for withdrawal in {embargoPeriod} days.
      </div>
    </div>
  );
};
