import { takeLatest } from 'redux-saga/effects';
import { lastPrice, fetchAssetTypes } from '../reducers/assetTypesReducer';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchAssetTypes.TRIGGER,
    createSaga(fetchAssetTypes, 'ASSET_TYPES'),
  );
  yield takeLatest(lastPrice.TRIGGER, createSaga(lastPrice, 'LAST_PRICE'));
}
