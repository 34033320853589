import { sumBy, get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { getActiveAccountId } from './activeAccountSelectors';
import { getContractsBySymbol } from './contractsReducer';
import { createSelector } from 'reselect';

export const POSITIONS = 'POSITIONS';
/**
 * Routine to get contract positions.
 */
export const fetchPositions = createRoutine(POSITIONS);

export const addTotalsAndContractsToPositions = (contracts) =>
  contracts.map((contract) => {
    const positionsWithContract = contract.positions.map((position) => ({
      ...position,
      contractSymbol: contract.contractSymbol,
    }));
    const totalLong = sumBy(contract.positions, (pos) => {
      if (pos.qty > 0) {
        return Math.abs(pos.qty);
      }
      return 0;
    });

    const totalShort = sumBy(contract.positions, (pos) => {
      if (pos.qty < 0) {
        return Math.abs(pos.qty);
      }
      return 0;
    });

    return {
      ...contract,
      positions: positionsWithContract,
      totalLong,
      totalShort,
    };
  });

export default handleActions(
  {
    [fetchPositions.SUCCESS]: (state, { payload }) => ({
      byId: {
        ...state.byId,
        [payload.accountId]: addTotalsAndContractsToPositions(
          payload.positions,
        ),
      },
    }),
  },
  {},
);

export const getPositions = (state) =>
  get(state, ['positions', 'byId', getActiveAccountId(state)], []);
// filter out contracts that have no positions
export const getValidPositions = (state) =>
  getPositions(state).filter((contract) => contract.positions.length);

export const getValidPositionsWithDetails = createSelector(
  [getContractsBySymbol, getValidPositions],
  (contracts, positions) =>
    positions.map((position) => ({
      ...get(contracts, position.contractSymbol),
      ...position,
    })),
);
