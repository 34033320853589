import React, { useMemo } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';

const Label = styled.label`
  color: ${({ theme }) => theme.form.field.label.color};
  margin-bottom: 10px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormControlStyles = styled(FormControl)`
  width: 100%;
`;

const SelectStyles = styled(Select)`
  .MuiSelect-icon {
    color: ${({ theme }) => theme.form.field.dropdown.color};
  }

  .MuiInputBase-input {
    color: ${({ theme, hasValue }) =>
      hasValue
        ? theme.form.field.dropdown.color
        : theme.form.field.input.placeholder};
  }
`;

const ItemStyles = styled(MenuItem)`
  color: ${({ theme }) => theme.ui.labeledField.field};
  && {
    background-color: ${({ theme }) => theme.form.field.dropdown.item.bg};
    color: ${({ theme }) => theme.form.field.dropdown.color};
  }
`;

const useStyles = makeStyles({
  select: {
    '& ul': {
      backgroundColor: (theme) => get(theme, 'form.field.dropdown.item.bg', ''),
      maxHeight: '15em',
      overflowY: 'scroll',
    },
    '& li': {
      fontSize: 14,
    },
  },
});

const INITIAL_VALUE = 'PLACEHOLDER';

export const renderMaterialDropdown = ({
  label,
  input,
  options,
  theme,
  placeholder = 'Select an option',
  className = '',
  defaultValue = '',
}) => {
  const classes = useStyles(theme);
  const [selectedOption, setSelectedOption] = React.useState(INITIAL_VALUE);

  const hasValue = useMemo(
    () => selectedOption !== INITIAL_VALUE,
    [selectedOption],
  );

  React.useEffect(() => {
    if (defaultValue.length > 0) {
      const _selectedOption = options.find((x) => x.value === defaultValue);
      input.onChange(_selectedOption?.value);
      setSelectedOption(_selectedOption);
    }
  }, []);

  return (
    <Wrapper className={`field ${className}`}>
      <Label>{label}</Label>
      <FormControlStyles>
        <SelectStyles
          value={selectedOption}
          hasValue={hasValue}
          renderValue={(value) => {
            if (value === INITIAL_VALUE) return placeholder;
            if (value) return value?.text;
          }}
          onChange={(e) => {
            const option = get(e, 'target.value', '');
            input.onChange(option?.value);
            setSelectedOption(option);
          }}
          MenuProps={{
            classes: { paper: classes.select },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((option, i) => (
            <ItemStyles value={option} key={option.key}>
              {option.text}
            </ItemStyles>
          ))}
        </SelectStyles>
      </FormControlStyles>{' '}
    </Wrapper>
  );
};

export default withTheme(renderMaterialDropdown);
