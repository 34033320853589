import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import get from 'lodash/get';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;

const Header = styled.p`
  font-size: 12px;
  font-weight: bold;
`;

const Trash = styled(Icon)`
  font-size: 12px;
  font-weight: bold;
`;

const columns = (onRemove) => [
  {
    Header: () => <Header>Clearing Account</Header>,
    id: 'account-id',
    accessor: 'accountLabel',
    headerClassName: 'pull-left',
  },
  {
    Header: () => <Header>Clearing Account Name</Header>,
    id: 'accountName',
    accessor: 'accountName',
    headerClassName: 'pull-left',
    width: 220,
  },
  {
    Header: () => <Header>Authorized User</Header>,
    id: 'authorizedTraderEmail',
    accessor: 'authorizedTraderEmail',
    headerClassName: 'pull-left',
    width: 220,
  },
  {
    Header: () => <Header>Authorized User Firm</Header>,
    id: 'authorizedMember',
    accessor: 'authorizedMember',
    headerClassName: 'pull-left',
    width: 220,
  },
  {
    Header: () => <Header>Action</Header>,
    id: 'action',
    headerClassName: 'center',
    Cell: (props) => (
      <Wrapper>
        <Icon
          size="small"
          name="trash"
          link
          onClick={() => onRemove(get(props, 'original', {}))}
        />
      </Wrapper>
    ),
  },
];

export default columns;
