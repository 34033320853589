import moment from 'moment';
import { FilterFn, Row, filterFns } from '@tanstack/react-table';
import TableFilterType from '../../../ts/enums/TableFilterType.enum';

export const dateFilterFn = <T>(row: Row<T>, columnId: string, value: any) => {
  const date: Date = new Date(row.getValue(columnId));
  const [start, end] = value;
  //If one filter defined and date is null filter it
  const startDate = moment(start).toDate();
  const endDate = moment(end).toDate();

  if ((start || end) && !date) return false;
  if (start && !end) {
    return date.getTime() >= startDate.getTime();
  } else if (!start && end) {
    return date.getTime() <= endDate.getTime();
  } else if (start && end) {
    return (
      date.getTime() >= startDate.getTime() &&
      date.getTime() <= endDate.getTime()
    );
  } else return true;
};

export const currencyToNumber = (val: string) =>
  val ? Number(val.replace(/[^0-9.-]+/g, '')) : NaN;

const validRange = (value: number | '') => value || value === 0;

export const isInRange = (
  value: number,
  min: number | '',
  max: number | '',
) => {
  if (validRange(min) && validRange(max)) {
    return value >= min && value <= max;
  }
  if (validRange(min) && !validRange(max)) {
    return value >= min;
  }
  if (!validRange(min) && validRange(max)) {
    return value <= max;
  }

  return false;
};

export const inNumberRange = <T>(
  row: Row<T>,
  columnId: string,
  filterValue: any,
) => {
  let [min, max] = filterValue;

  // Since it's a currency, the column value will be a String
  const rowValue = row.getValue<number>(columnId);
  if (!rowValue || Number.isNaN(rowValue)) return false;
  return isInRange(rowValue, min, max);
};

export const currencyRange = <T>(
  row: Row<T>,
  columnId: string,
  filterValue: any,
) => {
  let [min, max] = filterValue;
  // Since it's a currency, the column value will be a String
  const rowValue = row.getValue<string>(columnId);
  const numberValue = currencyToNumber(rowValue);
  // Filter out undefined or non numeric values
  if (!rowValue || Number.isNaN(numberValue)) return false;
  return isInRange(numberValue, min, max);
};

export const typeSafeFilterFn = <T>(
  filterType: string,
  row: Row<T>,
  columnId: string,
  filterValue: any,
): boolean => {
  switch (filterType) {
    case TableFilterType.String:
      return filterFns.equalsString(row, columnId, filterValue, () => {});
    case TableFilterType.Number:
      return inNumberRange(row, columnId, filterValue);

    case TableFilterType.Date:
      return dateFilterFn(row, columnId, filterValue);

    case TableFilterType.Currency:
      return currencyRange(row, columnId, filterValue);

    default:
      // Returning true includes a row within current filter.
      // However, if there's no TableFilterProvided, no filter will be displayed
      // Making the column unfilterable
      return true;
  }
};
