import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

const removeKeyAndSecret = (url) => {
  const urlSplit = url.split(/[?&| ]+/);
  const paramsSplit = (params) => params.split('=');
  let newUrl = '?';
  /* eslint-disable no-plusplus */
  for (let i = 0; i < urlSplit.length; i++) {
    const params = paramsSplit(urlSplit[i]);
    if (
      params[0] !== 'apiKey' &&
      params[0] !== 'secret' &&
      urlSplit[i].length
    ) {
      newUrl += urlSplit[i];
    }
  }
  return newUrl;
};

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

export const initGTM = (gtmId) => {
  TagManager.initialize({
    gtmId,
  });
};

export const pageView = () => {
  ReactGA.pageview(
    window.location.pathname + removeKeyAndSecret(window.location.search),
  );
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const trackEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const pushTag = (event) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
    },
  });
};
