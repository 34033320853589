import { get, set } from 'lodash';
import { call, takeLatest, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import fileDownload from 'js-file-download';
import {
  storeTxAuthKey,
  SCALE_BASE_TO_MIN_UNIT_TRIGGER,
  SCALE_BASE_TO_MIN_UNIT_REQUEST,
  SCALE_BASE_TO_MIN_UNIT_SUCCESS,
  SCALE_BASE_TO_MIN_UNIT_FAILED,
  exportMemberFile,
  memberDailyStatement,
  skipFunding,
} from '../reducers/utilitiesReducer';
import {
  fetchUser,
  acceptDisclosuresAndDisclaimers,
  acceptAgreement,
} from '../reducers/userReducer';

import api, * as urls from '../api';

function* scaleBaseToMinUnit(arg) {
  try {
    yield put({ type: SCALE_BASE_TO_MIN_UNIT_REQUEST });
    const amountMinUnit = yield call(
      api.post,
      urls.SCALE_BASE_TO_MIN_UNIT_API_ENDPOINT,
      arg.data,
    );
    yield put({ type: SCALE_BASE_TO_MIN_UNIT_SUCCESS, amountMinUnit });
  } catch (e) {
    yield put({ type: SCALE_BASE_TO_MIN_UNIT_FAILED, payload: e.response });
  }
}

function* storeTxAuthKeyFunc(arg) {
  const loadingCallback = cogoToast.loading(
    'Attempting to create your password.',
    { hideAfter: 0 },
  );
  try {
    yield put(storeTxAuthKey.request());
    const user = yield call(
      api.post,
      urls.STORE_TX_AUTH_KEY_API_ENDPOINT,
      arg.payload,
    );
    loadingCallback();
    cogoToast.success('Funding password created successfully.');
    yield put(fetchUser.success(user));
    yield put(storeTxAuthKey.success(user));
  } catch (e) {
    loadingCallback();
    yield put(storeTxAuthKey.failure(e.response));
  }
}

function* exportMemberFileRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Generating file...', {
    hideAfter: 0,
  });
  try {
    yield put(exportMemberFile.request());
    const { csv, pdf } = yield call(
      api.post,
      urls.EXPORT_MEMBER_FILE_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(exportMemberFile.success());
    cogoToast.success('File exported! Downloading...');

    const report = get(payload, 'report', '');
    const date = get(payload, 'date', '');

    if (pdf !== undefined) {
      // Use js-file-download logic, but with our own MIME type with base64 string.
      // Essentially temporarily creates a link, clicks it, and destroys the node.
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdf}`;

      // set filename
      link.setAttribute('download', `${report.toUpperCase()}-${date.replace(/-/g, '')}`);

      // open without modifying current session
      link.setAttribute('target', '_blank');

      // add link to body, click it, and destroy it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // capitalize all report types, prefix MOVEMENTS and
      //  BALANCES with ASSETS_, and remove hyphens in date
      yield call(
        fileDownload(
          csv,
          `${
            report === 'movements' || report === 'balances' ? 'ASSET_' : ''
          }${report.toUpperCase()}-${date.replace(/-/g, '')}.csv`,
        ),
      );
    }
  } catch (e) {
    loadingCallback();
    yield put(exportMemberFile.failure(e.response));
  }
}

function* memberDailyStatementRequest({ payload = {} }) {
  const loadingCallback = cogoToast.loading('Fetching statement...', {
    hideAfter: 0,
  });
  try {
    yield put(memberDailyStatement.request());
    const response = yield call(
      api.post,
      urls.MEMBER_DAILY_STATEMENT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    if (get(response, 'dailyStatement')) {
      // response with daily statement received, success
      yield put(memberDailyStatement.success());
      cogoToast.success('Statement fetched. Downloading...');

      // Use js-file-download logic, but with our own MIME type with base64 string.
      // Essentially temporarily creates a link, clicks it, and destroys the node.
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${get(response, 'dailyStatement', '')}`;

      // set filename
      link.setAttribute('download', `statement-${payload.date}.pdf`);

      // open without modifying current session
      link.setAttribute('target', '_blank');

      // add link to body, click it, and destroy it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // payload is sensitive, destroy it
      set(response, 'dailyStatement', null);
    } else {
      // response did not have a daily statement, so there was an error
      yield put(memberDailyStatement.success(get(response, 'error')));
      cogoToast.warn(get(response, 'error'), { hideAfter: 7 });
    }
  } catch (e) {
    loadingCallback();
    yield put(memberDailyStatement.failure(e.response));
  }
}

function* acceptDisclosuresAndDisclaimersRequest() {
  try {
    yield put(acceptDisclosuresAndDisclaimers.request());
    yield call(api.post, urls.ACCEPT_DISCLOSURES_AND_DISCLAIMERS_API_ENDPOINT);
    yield put(acceptDisclosuresAndDisclaimers.success());
  } catch (e) {
    yield put(acceptDisclosuresAndDisclaimers.failure(e.response));
  }
}

function* acceptAgreementRequest() {
  try {
    yield put(acceptAgreement.request());
    const user = yield call(api.post, urls.ACCEPT_AGREEMENT_API_ENDPOINT);
    yield put(acceptAgreement.success(user));
  } catch (e) {
    yield put(acceptAgreement.failure(e.response));
  }
}

function* skipFundingSaga() {
  try {
    const user = yield call(api.post, urls.SKIP_FUNDING_API_ENDPOINT);
    yield put(fetchUser.success(user));
    yield put(skipFunding.success());
  } catch (e) {
    yield put(skipFunding.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(SCALE_BASE_TO_MIN_UNIT_TRIGGER, scaleBaseToMinUnit);
  yield takeLatest(exportMemberFile.TRIGGER, exportMemberFileRequest);
  yield takeLatest(memberDailyStatement.TRIGGER, memberDailyStatementRequest);
  yield takeLatest(storeTxAuthKey.TRIGGER, storeTxAuthKeyFunc);
  yield takeLatest(
    acceptDisclosuresAndDisclaimers.TRIGGER,
    acceptDisclosuresAndDisclaimersRequest,
  );
  yield takeLatest(acceptAgreement.TRIGGER, acceptAgreementRequest);
  yield takeLatest(skipFunding.TRIGGER, skipFundingSaga);
}
