import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';

const Label = styled.p`
  color: ${({ theme }) => theme.ui.labeledField.label};
  margin-bottom: 10px;
`;

const Item = styled.p`
  color: ${({ theme }) => theme.ui.labeledField.field};
`;

const LabeledField = ({
  label,
  text,
  className,
}: {
  label: string;
  text: string;
  className: string;
}) => (
  <div className={className}>
    <Label>{label}</Label>
    <Item>{text}</Item>
  </div>
);

export default LabeledField;
