import { createGlobalStyle } from 'styled-components';
import colors from '../../constants/colors';

const GlobalStyles = createGlobalStyle`
  html, body, #root {
  }
  html {
   
  }
  body {
    height: 100%;
    margin: 0px;
    box-sizing: border-box;
  }


  /*-------------------
      Vertical Nav
  --------------------*/ 
   #vertical-nav {
   height: ${({ theme }) => theme.sidenav.height};
   background-color: ${({ theme }) => theme.sidenav.bg};
  }

  #total-balance-stat {
    border-left: 6px solid ${({ theme }) => theme.accent};
  }

  /*-------------------
        Sidebar
  --------------------*/ 
  .content-sidebar, .content-sidebar *:not(input):not(.text):not(span):not(.icon):not(.item):not(button) {
    background-color: ${({ theme }) => theme.ui.sidebar.bg} !important;
  }

  .content-sidebar, .content-sidebar * {
    
  }

  /*-------------------
      Semantic UI
  --------------------*/ 

  .ui {
    color: ${({ theme }) => theme.ui.header}
  }

  .ui.segment {
    background: ${({ theme }) => theme.ui.segment.bg}
  }

  // Form field label
  .ui.form .field > label {
    color: ${({ theme }) => theme.form.field.label.color};
  }

  // Statistic
  .ui.statistics .statistic > .label {
    color: ${({ theme }) => theme.form.field.label.color};
  }
  .ui.statistics .statistic > .value {
    color: ${({ theme }) => theme.form.field.label.color} !important;
  }

  // Input  
  .ui.input > input {
    color: ${({ theme }) => theme.form.field.input.color} !important;
  }

  .ui.input input:focus, .ui.textarea textarea:focus, .field textarea:focus{
    border-color: ${({ theme }) =>
      theme.form.field.input.activeUnderline} !important;
  }

  .onboarding-field .ui.input{
     & input {
       color: ${({ theme }) => theme.form.field.input.color} !important;
     }
  }

  .ui.active.search.dropdown input.search:focus + .text {
    color: ${({ theme }) => theme.form.field.input.color} !important;
    opacity: 0.7; 
  }

  // Placeholder color for all browsers
  .ui.input > input::-webkit-input-placeholder {
    color:  ${({ theme }) => theme.form.field.input.placeholder};
  }
  .ui.input > input::-moz-placeholder {
    color:  ${({ theme }) => theme.form.field.input.placeholder};
  }
  .ui.input > input:-ms-input-placeholder {
    color:  ${({ theme }) => theme.form.field.input.placeholder};
  }

  .ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text
  {
    color: ${({ theme }) => theme.form.field.input.color};
  }

  .ui.dropdown div.default.text {
    opacity: 100%;
  }

  .ui.dropdown .menu {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%); 
  }

  .ui.dropdown .menu > .item {
    background-color: ${({ theme }) => theme.userDropdown.menu.bg};
  }
  .ui.top.pointing.dropdown > .left.menu:after, .ui.top.right.pointing.dropdown > .menu:after {
    background-color: inherit;
  }
  .ui.toggle.checkbox .box, .ui.toggle.checkbox label {
    color: ${({ theme }) => theme.form.field.label.color};
  }

  // Dropdown menu items
  .ui.selection.dropdown .menu > .item {
    background-color: ${({ theme }) => theme.form.field.dropdown.item.bg};
  }
  
  .ui.selection.dropdown {
    color: ${({ theme }) => theme.form.field.dropdown.color};
  }
  .ui.selection.dropdown.onboarding-field {
    color:  ${({ theme }) => theme.form.field.dropdown.color};
  }

  .ui.selection.dropdown .menu > .item:hover {
    background-color: ${({ theme }) =>
      theme.form.field.dropdown.item.hover} !important;
  }

  .ui.selection.dropdown .menu > .item .text {
    color: ${({ theme }) => theme.form.field.dropdown.item.color};
  }
  .ui.search.selection.dropdown > input.search {
    color:  ${({ theme }) => theme.form.field.input.color};
  }

  // Modal background overlay
  .ui.dimmer {
    background-color: ${({ theme }) => theme.modal.dimmer};
  }

  .ui.modal {
    background-color: ${({ theme }) => theme.modal.bg};
  }

  .ui.modal > .content {
    background-color: ${({ theme }) => theme.modal.bg};
  }

  .ui.modal > .actions {
    background-color: ${({ theme }) => theme.modal.bg};
  }

  .ui.modal > .header {
    background-color: ${({ theme }) => theme.modal.bg};
    color: ${({ theme }) => theme.text.header};
  }
  
  // Buttons
  // Primary
  .ui.primary.button {
    color: ${({ theme }) => theme.button.primary.color};
    background-color: ${({ theme }) => theme.button.primary.bgColor};
  }
  .ui.primary.button:hover{
    color: ${({ theme }) => theme.button.primary.color};
    background-color: ${({ theme }) => theme.button.primary.bgColorHover};

  }
  .ui.primary.button:active{
    color: ${({ theme }) => theme.button.primary.color};
    background-color: ${({ theme }) => theme.button.primary.bgColorActive};

  }
  .ui.primary.button:focus{
    color: ${({ theme }) => theme.button.primary.color};
  }
  // Secondary
  .ui.secondary.button {
    color: ${({ theme }) => theme.button.secondary.color};
    border: ${({ theme }) => `1px solid ${theme.button.secondary.borderColor}`};
    background-color: ${({ theme }) => theme.button.secondary.bgColor};
  }
  .ui.secondary.button:hover{
    color: ${({ theme }) => theme.button.secondary.color};
    background-color: ${({ theme }) => theme.button.secondary.bgColorHover};

  }
  .ui.secondary.button:active{
    color: ${({ theme }) => theme.button.secondary.color};
    background-color: ${({ theme }) => theme.button.secondary.bgColorActive};

  }
  .ui.secondary.button:focus{
    color: ${({ theme }) => theme.button.secondary.color};
  }
  // Side nav
  #vertical-nav .content.active {
    background-color: ${({ theme }) => theme.sidenav.bg};
  }

  /*-------------------
          Links
  --------------------*/
  a {
    color: ${({ theme }) => theme.link.color};
    box-shadow: ${({ theme }) => theme.link.underline};
  }
  a:hover {
    color: ${({ theme }) => theme.link.colorHover};
    box-shadow:  ${({ theme }) => theme.link.hoverUnderline};
  }
  a:active {
    color: ${({ theme }) => theme.link.colorActive};
    box-shadow: ${({ theme }) => theme.link.activeUnderline};
  }

  a.primary {
    color:${({ theme }) => theme.link.colorPrimary};
  }

  a.primary:hover {
    color: ${({ theme }) => theme.link.primaryHover};
  }


  /*-------------------
     Onboarding steps
  --------------------*/
  .ui.steps .step {
    color: ${({ theme }) => theme.onboarding.steps.color};
  }
  // Active step title
  .ui.steps .step.active .title {
    color: ${({ theme }) => theme.onboarding.steps.colorActive};
  }
  .ui.steps .active.step .icon{
    color: ${({ theme }) => theme.onboarding.steps.colorActive};
  }

  .onboarding-content {
      border: ${({ theme }) => theme.onboarding.content.border}
  }

`;

export default GlobalStyles;
