import { get, isEqual } from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { showModal, hideModal, createLoadingSelector } from 'erisxkit/client';
import {
  getDefaultTradingApp,
  getLoggedInUser,
  getLoggedInUserPermissions,
  getUserCanAuthorizeIPs,
  getUserHasMemberOfType,
  getUserHasViewBalances,
} from '../../reducers/userReducer';
import {
  showSidebar,
  hideSidebar,
  getSidebarContent,
} from '../../reducers/sidebarReducer';
import {
  getAllMemberAccountsList,
  getAllAccountsList,
  selectAccount,
} from '../../reducers/accountsReducer';
import { getActiveAccountId } from '../../reducers/activeAccountSelectors';
import TopNav from '../../components/TopNav';
import TopNavFCM from '../../components/TopNavFCM';
import history from '../../constants/history';
import { VERTICAL_NAV_CONTAINER } from '../../constants/sideBarTypes';
import { getOrientation, isViewportLessThan } from '../../reducers/uiReducer';
import EnvPromise from '../../config/env';
import {
  getTotalUsdValue,
  getPrevTotalUsdValue,
  fetchBalances,
  BALANCES,
} from '../../reducers/balancesReducer';
import { fetchApiCredentialsPromiseCreator } from '../../reducers/apiCredentialsReducer';
import {
  getAllMembersV2,
  getSelectedMemberId,
  hasNonFcmMarginFuturesMembers,
  selectMember,
} from '../../reducers/membersReducer';
import { FCM_MEMBER_TYPE } from '../../constants/memberTypes';
import { isNonFCMMarginFuturesPage } from '../../hooks/useExchangeMember';
import TopNavNonFCMMarginFutures from '../../components/TopNavNonFCMMarginFutures';
import MEMBER_USER_PERMISSION_NAMES from '../../constants/memberUserPermissions';

const mapStateToProps = (state, props) => ({
  isCurrentUserFCMTypeMember: getUserHasMemberOfType(state, [FCM_MEMBER_TYPE]),
  allAccounts: getAllAccountsList(state),
  accounts: getAllMemberAccountsList(state),
  accountId: getActiveAccountId(state),
  defaultTradingApp: getDefaultTradingApp(state),
  user: getLoggedInUser(state),
  sidebarContent: getSidebarContent(state),
  viewportLessThanLarge: isViewportLessThan('large', state),
  viewportLessThanMedium: isViewportLessThan('medium', state) || isMobileOnly,
  viewportLessThanSmall: isViewportLessThan('small', state),
  currTotalUsdValue: getTotalUsdValue(
    props.activeAccountId || props.accountIds,
    'curr',
  )(state),
  prevTotalUsdValue: getPrevTotalUsdValue(
    props.activeAccountId || props.accountIds,
  )(state),
  loadingBalances: createLoadingSelector([BALANCES])(state),
  userPermissions: state.user && state.user.uiViews,
  memberUserPermissions: getLoggedInUserPermissions(state),
  orientation: getOrientation(state),
  canAuthorizeIPs: getUserCanAuthorizeIPs(state),
  selectedMemberId: getSelectedMemberId(state),
  isNonFCMMarginFuturesPage: isNonFCMMarginFuturesPage(),
  allMembers: getAllMembersV2(state),
  hasNonFcmMarginFuturesMembers: hasNonFcmMarginFuturesMembers(state),
  canViewBalances: getUserHasViewBalances(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators({ fetchApiCredentialsPromiseCreator }, dispatch),
  ...bindActionCreators(
    {
      showSidebar,
      hideSidebar,
      showModal,
      hideModal,
      fetchBalances,
      selectMember,
      selectAccount,
    },
    dispatch,
  ),
});

class TopNavContainer extends Component {
  componentDidMount = () => {
    const { accountIds, fetchBalances } = this.props;
    //We dont need to call Balances endpoint on TopNavContainer, if the users doesnt have read_balances permissions.
    if (accountIds && accountIds.length && this.props.canViewBalances) {
      fetchBalances({ accountIds, time: moment().format() });
    }
    EnvPromise.then((env) => {
      const { defaultTradingApp } = this.props;
      this.setState({
        clientId: get(env, `${defaultTradingApp}ClientId`),
        tradingFrontendEndpoint: get(
          env,
          `${defaultTradingApp}FrontendEndpoint`,
          '',
        ),
        enableMargin: get(env, 'enableMargin', false),
      });
    });
  };

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.accountIds, this.props.accountIds)) {
      const { accountIds, fetchBalances } = this.props;
      //We dont need to call Balances endpoint on TopNavContainer, if the users doesnt have read_balances permissions.
      if (accountIds && accountIds.length && this.props.canViewBalances) {
        fetchBalances({ accountIds, time: moment().format() });
      }
    }
  };

  userOptionClick(route) {
    history.push(`/${route}`);
  }

  logout() {
    this.props.auth.logout();
  }

  logoutFCM(auth) {
    auth.logout();
  }

  logoClick = (mobile) => {
    if (!mobile) {
      history.push('/home');
    } else if (
      this.props.sidebarContent &&
      this.props.sidebarContent !== VERTICAL_NAV_CONTAINER
    ) {
      this.props.hideSidebar();
      this.props.showSidebar(VERTICAL_NAV_CONTAINER, { direction: 'left' });
    } else if (!this.props.sidebarContent) {
      this.props.showSidebar(VERTICAL_NAV_CONTAINER, { direction: 'left' });
    } else {
      this.props.hideSidebar();
    }
  };

  selectMemberOnAccountClick = (accountId) => {
    const { selectMember, allAccounts } = this.props;
    const { memberId } = allAccounts.find((acc) => acc.accountId === accountId);
    this.props.selectAccount(accountId);
    localStorage.setItem('erisx_account', accountId);
    if (memberId) {
      this.props.selectMember(memberId);
    }
    localStorage.setItem('erisx_member', memberId);
  };

  selectMemberOnNonFCMMemberClick = (memberId) => {
    if (memberId) {
      this.props.selectMember(memberId);
    }
    localStorage.setItem('erisx_member', memberId);
  };

  render = () => {
    if (this.props.isNonFCMMarginFuturesPage) {
      return (
        <TopNavNonFCMMarginFutures
          accountId={this.props.accountId}
          logout={this.logoutFCM}
          logoClick={this.logoClick}
          userOptionClick={this.userOptionClick}
          simpleNav={this.props.simpleNav}
          enableApiCredentials={this.props.enableApiCredentials}
          selectMemberOnNonFCMMemberClick={this.selectMemberOnNonFCMMemberClick}
          {...this.props}
          {...this.state}
        />
      );
    }
    return this.props.isCurrentUserFCMTypeMember &&
      this.state?.enableMargin === 'true' ? (
      <TopNavFCM
        accountId={this.props.accountId}
        logout={this.logoutFCM}
        logoClick={this.logoClick}
        userOptionClick={this.userOptionClick}
        simpleNav={this.props.simpleNav}
        enableApiCredentials={this.props.enableApiCredentials}
        selectMemberOnAccountClick={this.selectMemberOnAccountClick}
        {...this.props}
        {...this.state}
      />
    ) : (
      <TopNav
        accountId={this.props.accountId}
        logout={this.logout}
        logoClick={this.logoClick}
        userOptionClick={this.userOptionClick}
        simpleNav={this.props.simpleNav}
        enableApiCredentials={this.props.enableApiCredentials}
        selectMemberOnAccountClick={this.selectMemberOnAccountClick}
        {...this.props}
        {...this.state}
      />
    );
  };
}

TopNavContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showSidebar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavContainer);
