import React, { useRef, useEffect } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import AccountHistory from '../components/AccountHistory';
import { ONBOARDED } from '../constants/userStates';
import {
  fetchRecentTransactions,
  getRecentTransactions,
  RECENT_TRANSACTIONS,
} from '../reducers/accountHistoryReducer';
import {
  getAllAccountsList,
  getAllAccountIds,
} from '../reducers/accountsReducer';
import { getAssetTypes } from '../reducers/assetTypesReducer';
import { getLoggedInUser } from '../reducers/userReducer';
import { isViewportLessThan } from '../reducers/uiReducer';
import accountHistoryHomeMetadata from '../metadata/accountHistoryHomeMetadata';
import { POLLING_INTERVAL } from '../constants/timeout';
import AccessControl from '../common/AccessControl';
import { grants, subjects } from '../constants/userPermissions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedMemberId } from '../reducers/membersReducer';

export const RECENT_TRANSACTIONS_LIMIT = 10;

const AccountsHomeHistoryContainer = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([RECENT_TRANSACTIONS])(state),
  );
  const assetTypes = useSelector(getAssetTypes);
  const recentTransactions = useSelector(getRecentTransactions);
  const viewportLessThanSmall = useSelector((state) =>
    isViewportLessThan('small', state),
  );
  const user = useSelector(getLoggedInUser);
  const accounts = useSelector(getAllAccountsList);
  const accountsIds = useSelector(getAllAccountIds);
  const selectedMemberId = useSelector(getSelectedMemberId);
  let intervalId = useRef(null);

  useEffect(() => {
    if (user && user.state === ONBOARDED) {
      if (accountsIds.length > 0) {
        dispatch(
          fetchRecentTransactions({
            accountIds: accountsIds,
            limit: RECENT_TRANSACTIONS_LIMIT,
          }),
        );
        intervalId.current = setInterval(
          () =>
            dispatch(
              fetchRecentTransactions({
                accountIds: accountsIds,
                limit: RECENT_TRANSACTIONS_LIMIT,
              }),
            ),
          POLLING_INTERVAL,
        );
      }
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const filter = (e, { text }) => {
    clearInterval(intervalId.current);
    let assetType = text;
    if (text === 'Show All') {
      assetType = undefined;
    }
    dispatch(
      fetchRecentTransactions({
        accountIds: accountsIds,
        limit: RECENT_TRANSACTIONS_LIMIT,
        assetType,
      }),
    );
    intervalId.current = setInterval(
      () =>
        dispatch(
          fetchRecentTransactions({
            accountIds: accountsIds,
            limit: RECENT_TRANSACTIONS_LIMIT,
            assetType,
          }),
        ),
      POLLING_INTERVAL,
    );
  };

  const metadata = accountHistoryHomeMetadata;
  const data = {
    recentTransactions,
    loading,
    metadata,
    assetTypes,
    viewportLessThanSmall,
    filter,
    accounts,
  };

  return (
    <section className="main-holdings">
      {selectedMemberId && (
        <AccessControl
          allowedPermissions={[
            `${grants.READ}:${subjects.TRADES}`,
            `${grants.READ}:${subjects.MOVEMENTS}`,
          ]}
        >
          <AccountHistory {...data} />
        </AccessControl>
      )}
    </section>
  );
};

export default AccountsHomeHistoryContainer;
